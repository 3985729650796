import React from 'react';

const CXRadio = ({label, options=[], className, name, register}) => {
    return (
        <div className={'cx-radio-group' + ' ' + className??''}>
            <div className="cx-radio-group__title">{label}</div>
            <div className="cx-radio-group__options">
                {
                    options.map((o, i) => {
                        return (
                            <div className="cx-radio-group__options__item" key={`${o.label}-${i}`}>
                                <input id={`${o.label}-${i}`} type="radio" value={o.value} {...register(name)} hidden/>
                                <label htmlFor={`${o.label}-${i}`}>{o.label}</label>
                            </div>
                        )
                    })
                }            
            </div>
        </div>
    );
}

export default CXRadio;
