import React from "react";
class Boolean extends React.Component {   

    render() {
        let st  = this.props.status; 

        return (
                st
                ? <i className="fa fa-check" style={{"color": "#03dac6", "marginInline": "auto", "fontSize": "130%"}}></i>
                : <i className="fa fa-times" style={{"color": "#fd5d93", "marginInline": "auto", "fontSize": "130%"}}></i>
        )
    }
}
export default Boolean
