import React, { Component } from "react";
import { connect } from 'react-redux';
import {campaignActions} from '../../../actions';
import {Card, CardBody, CardHeader} from "../../../components/Card/card";
import PublishDraftItemsForm from './publishDraftItemsForm'
import _ from 'lodash';
import NotificationAlert from "react-notification-alert";
import analytics from "../../../helpers/analytics";

class reviewAndPublish extends Component {

    constructor (props){
        super(props);
        this.state={
            loading:false,
            isPopupOpened:false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.fetchToPublishErr && !_.isEqual(this.props.fetchToPublishErr, prevProps.fetchToPublishErr)) {
            this.alertMsg('Fetching the lists failed, try again later');
            this.props.fetchToPublishErrEvent(undefined);
        }
        if(this.props.allPublishMsg && !_.isEqual(this.props.allPublishMsg, prevProps.allPublishMsg)){
            this.alertMsg(this.props.allPublishMsg.message, 'fa fa-check', 'success');
            this.props.publishAllEvent();
        }
        if(this.props.allPublishErr && !_.isEqual(this.props.allPublishErr, prevProps.allPublishErr)){
            this.alertMsg(this.props.allPublishErr.message);
            this.props.publishAllErrEvent();
        }
    }

    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    listToPublish = async () => {
        // calls the action to change the state of the store
        this.setState({ loading: true });
        await this.props.fetchToPublish();
        this.setState({ loading: false });
    }

    publishAllBtnRender = () => {
        return (
            <div className="cx-action-buttons">
                <button
                    className="my-0 btn cx-focus-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({isPopupOpened: true});
                        this.listToPublish();
                        analytics.logEvent('Campaign','Review and publish');
                    }}
                >
                    Review and publish
                </button>
            </div>
        );
    }

    closePopup = () => {
        this.setState({isPopupOpened: false})
    }

    popupRender = () => {
        return (
            <div className={`cx-popup${(this.state.isPopupOpened)? ' open' : ''}`}>
                <Card width={'40%'}>
                    <CardHeader>
                        Review Draft Items
                        <span className={"close-btn"} onClick={()=>{this.closePopup()}}>
                            <i className={"fa fa-times"}></i>
                        </span>
                    </CardHeader>
                    <CardBody>
                        <PublishDraftItemsForm loading={this.state.loading} closePopup={this.closePopup}/>
                    </CardBody>
                </Card>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                {this.publishAllBtnRender()}
                {this.popupRender()}
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        fetchToPublishErr: (state.campaigns && state.campaigns.toPublishListErr) || null,
        allPublishMsg: (state.campaigns && state.campaigns.allPublishMsg) || null,
        allPublishErr: (state.campaigns && state.campaigns.allPublishErr) || null,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchToPublish: () => dispatch(campaignActions.fetchToPublish()),
        fetchToPublishErrEvent: (data) => dispatch(campaignActions.fetchToPublishErr(data)),
        publishAllErrEvent: () => dispatch(campaignActions.publishAllEventErr(undefined)),
        publishAllEvent: () => dispatch(campaignActions.publishAllEvent(undefined)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(reviewAndPublish);