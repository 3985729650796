import React from 'react'
import {
  Card,
  CardBody, CardTitle,
  Row
} from "reactstrap";
import Throbber from "../../components/Throbber.jsx";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mainHelper from "../../../helpers/main";
import Tooltip from '../../../components/Tooltips/Tooltip';
import { authorsActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data-for-charts.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

require('highcharts/modules/networkgraph')(Highcharts);
const helpers = new mainHelper();

const colorset = helpers.cxColorSet;

// Change how the network graph works
Highcharts.addEvent(
  Highcharts.seriesTypes.networkgraph,
  'afterSetOptions',
  function (e) {
    let nodes = {};
    e.options.data.forEach(function (link) {
      let author = link.from;
      let tag = link.to;
      let authorRadius = link.fromRadius;
      let authorArticleCount = link.fromNb;
      let tagRadius = link.toRadius;
      let tagOccurances = link.toNb;

      nodes[author] = {
        id: author,
        color: colorset[8],
        text: `<p style='color: #000' ><strong>${author}</strong></p><br/><p style='color: #000' >Published <strong>${authorArticleCount}</strong> articles</p>`,
        marker: {
          radius: authorRadius
        },
      };
      nodes[tag] = {
        id: tag,
        color: colorset[2],
        text: '<p  style=\'color: #000\' >' + tag + '</p><br/><p style=\'color: #000\' ><strong>' + (tagOccurances * 100 / authorArticleCount).toFixed(2) + '%</strong> (Appeared in ' + tagOccurances + ' out of ' + authorArticleCount + ' articles)</p>',
        marker: {
          radius: tagRadius
        }
      };
    });
    e.options.nodes = Object.keys(nodes).map(function (id) {
      return nodes[id];
    });
  }
);

class AuthorsNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loader: false
    };
  }


  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(this.props.pageFilters, this.props.dateFilter);
      this.setState({ loader: false });
    }
  }
 

  transformValue = (val, maxUiValue, maxValue) => {
    return Math.log10(val) * maxUiValue / Math.log10(maxValue);
  }


  getData = () => {
    if (!this.props.tags) return [];

    const maxUiValue = 16;
    let maxValue = Math.max.apply(Math, this.props.tags.map(function (o) { return o.published_posts; }));

    let constructData = [];
    const size_weight_tags = 1.2;
    const size_weight_authors = 1.5;
    for (let i in this.props.tags) {
      let authorData = this.props.tags[i];
      let author = authorData.author;
      let postCount = (authorData.published_posts * size_weight_authors);
      let tags = authorData.data;
      let innerData = [];
      for (let j in tags) {
        let authorTag = tags[j];
        let tag = authorTag.name;
        let occurrenceCount = (authorTag.value * size_weight_tags);
        innerData.push({
          from: author,
          to: helpers.sliceString(tag, 15),
          fromRadius: this.transformValue(postCount, maxUiValue, maxValue),
          toRadius: this.transformValue(occurrenceCount, maxUiValue, maxValue),
          fromNb: authorData.published_posts,
          toNb: authorTag.value
        });
      }
      constructData.push({ data: innerData });
    }

    return constructData;
  }


  render() {
    return (
      <Card>
        <CardBody className={'network-graph'} style={{ "height": "500px" }}>
          <Row className={"justify-content-between mx-0"}>
            <CardTitle>Authors - Tags</CardTitle>
            <Tooltip text={"This card displays the top tags authors write about. The size of each bubble corresponds to the number of articles written about the tag by the corresponding author."}></Tooltip>
          </Row>
          {(!this.state.loader && this.props.tags) ?
            <HighchartsReact
              containerProps={{ className: "highchartscss" }}
              highcharts={Highcharts}
              allowChartUpdate={true}
              immutable={true}
              options={{
                chart: {
                  type: 'networkgraph',
                  height: '400',
                  backgroundColor: 'transparent',
                  style: {
                    fontFamily: 'Tajawal'
                  }
                },
                title: {
                  text: '',
                  style: {
                    color: '#fff'
                  }
                },
                tooltip: {
                  enabled: true,
                  useHTML: true,
                  formatter: function () {
                    return this.point.text;
                  }
                },
                plotOptions: {
                  series: {
                    marker: {
                      symbol: 'circle'
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        textShadow: false,
                        textOutline: false,
                        color: '#f5f5f5',
                        fontWeight: 'normal'
                      }
                    }
                  },
                  networkgraph: {
                    keys: ['from', 'to', 'fromRadius', 'toRadius', 'fromNb', 'toNb'],
                    layoutAlgorithm: {
                      enableSimulation: true,
                      friction: -0.95
                    },
                    dataLabels: {
                      enabled: true,
                      linkFormat: ''
                    }
                  }
                },
                credits: false,
                series: this.getData()
              }}
            />
            :
            <Row className={"justify-content-center align-items-center"} style={{ 'minHeight': '150px' }}>
              <Throbber />
            </Row>
          }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: state.filter && state.filter.date || null,
    pageFilters: state.authorsInsight && state.authorsInsight.pageFilters || null,
    tags: (state.authorsInsight && state.authorsInsight.tags) || null,
  }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {

    fetchData: (filter, dateFilter) => dispatch(authorsActions.fetchTags(filter, dateFilter)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AuthorsNetwork);