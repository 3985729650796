import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { campaignPerformanceActions, mapActions } from "../../../actions";
import _ from 'lodash';
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

const helper = new mainHelper();

class Map extends Component {
    constructor(props){
        super(props);
        this.state = {
            loader: false,
        }
    }

    /*
    * componentDidMount
    */
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({loader: true});
        if(!this.props.mapData){
            await this.props.fetchMapData();
        }
        await this.props.locationFetch();
        this.setState({loader: false});
    }

    getData = () => {
        // get the `this.props.activeSubtab` eg. clicks
        // and return the data in the following format [[countryCode, clicks], [countryCode, clicks]]
        if(!this.props.locationData.length) return [];
        let res = [];
        for(let i in this.props.locationData) {
            const locationD = this.props.locationData[i];
            if(locationD[this.props.activeSubtab]){
                res.push([locationD.countryCode.toLowerCase(), locationD[this.props.activeSubtab]]);
            }
        }
        return res;
    }

    formatNumber = (subtab, value)=> {
        switch(subtab){
            case 'clicks':
            case 'views':
            case 'impressions':
                return helper.formatNumber(value);
            case 'amountSpent':
                return '$' + value.toLocaleString();
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-performance-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    key={this.props.activeSubtab + '-' + this.props.selectedType.typeid + '-' + this.props.selectedType.type + '-' + this.props.selectedType.campaignId}
                    highcharts={Highcharts}
                    containerProps={{ className: "highchartscss" }}
                    constructorType={'mapChart'}
                    options={
                        {
                            exporting: {
                                enabled: true,
                                buttons: {
                                    contextButton: {
                                        menuItems: [
                                            'viewFullscreen',
                                            'separator',
                                            'downloadPDF'
                                        ]
                                    }
                                },
                            },
                            title: {
                                text: ''
                            },
                            subtitle: {
                                text: _.startCase(this.props.activeSubtab) + ' By Location',
                                style: {
                                    color: '#9899A6'
                                },
                            },
                            chart:{
                                backgroundColor: 'transparent',
                                style: {
                                    fontFamily: 'Poppins'
                                }
                            },
                            mapNavigation: {
                                enabled: true
                            },
                            credits: {
                                enabled: false
                            },
                            colorAxis: {
                                min: 0,
                                stops: [
                                    [0, '#BBE6F9'],
                                    [1, '#4090B4']
                                ],
                                labels: {
                                    style: {
                                        color: '#9899A6'
                                    }
                                }
                            },
                            tooltip: {
                                pointFormatter: function() {
                                    return this.properties['name'].split(',')[0] + " - " + self.formatNumber(self.props.activeSubtab, this.options.value);
                                }
                            },
                            legend: {
                                enabled: true,
                                layout: 'vertical',
                                align: 'left',
                                verticalAlign: 'bottom'
                            },
                            series: [{
                                mapData: this.props.mapData,
                                name: _.startCase(this.props.activeSubtab),
                                data: this.getData()
                            }]
                        }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        locationData: (state.campaignPerformance && state.campaignPerformance.locationData)? state.campaignPerformance.locationData: [],
        activeSubtab: state.slidedrawer && state.slidedrawer.subtab,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType,
        mapData: (state.map && state.map.mapData)? state.map.mapData : null
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        locationFetch: () => dispatch(campaignPerformanceActions.fetchTypeLocation()),
        fetchMapData: () => dispatch(mapActions.fetchMapData())
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(Map);