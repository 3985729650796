import api from "../../api/contentGrouping";


// CRUD APIs
export const getContentGList = async () => {
    return api.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const readContentG = async (id) => {
    return api.read(id)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const createContentG = async (data) => {
    return api.create(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const updateContentG = async (data) => {
    return api.update(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const deleteContentG = async (id) => {
    return api.delete({ids:[id]})
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}



// elastic search APIs
export const getAppDomains = async () => {
    return api.getAppDomains()
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getAllClassesMapping = async () => {
    return api.getAllClassesMapping()
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const ES_searchKeywords = async (domain, classMapping, text) => {
    return api.ES_searchKeywords(domain, classMapping, text)
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            throw (error);
        });
}
export const ES_searchClasses = async (domain, classMapping, text) => {
    return api.ES_searchClasses(domain, classMapping, text)
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            throw (error);
        });
}


// utility


export const refetchList = (qc) => {
    return qc.invalidateQueries(["content-grouping-list"]);
}
