import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    campaignActions,
    drawerActions,
    campaignSelectionActions,
    adsetActions,
    adActions,
    tableActions
} from '../../../actions';
import ReactTable from "react-table";
import Switch from "react-bootstrap-switch";
import Delivery from "./Delivery"
import analytics from "../../../helpers/analytics";

import {
    Label,
    FormGroup,
    Input
} from "reactstrap";
import mainHelper from "../../../helpers/main";
import Confirm from "../../components/confirmationBox";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

const helper = new mainHelper();

class CampaignTable extends Component {
    constructor(props) {
        super(props);
        // table defaults (effects the UI changes)
        this.state = {
            pages: null,
            loading: true,
            sorted: [{ id: 'campaignId', desc: true }],
        };
        this.notify = {
            duplicate: {
                title: 'Are you sure you want to duplicate?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    await this.props.duplicateCampaign({ ids: [data.typeid] });
                    // refresh table
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Duplicate',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount(page) {
         this.getCampaignListData();
    }

    refreshData = () =>{
        this.props.fetchCampaigns();
        this.props.adsetReloadList();
        this.props.adReloadList();
    }

    activations = async  (original) => {
        await this.props.activateCampaign(original);
        //this.props.fetchCampaigns();
        this.refreshData();
    }

    tableCols = () => {
        return [
            {
                filterable: false,
                id: "checkbox",
                accessor: "",
                Header: x => {
                    return (
                        <FormGroup check className={`${(this.props.hideSelectAll)? 'd-none': ''}`}>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                    checked={this.props.selectAll === 1}
                                    onChange={() => this.props.toggleAll(this.props.campaignList)}
                                    ref={input => {
                                        if (input) {
                                            input.indeterminate = this.props.selectAll === 2;
                                        }
                                    }}
                                />
                                <span className="form-check-sign campaign-form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                    checked={this.props.selected[original.id] === true}
                                    onChange={() => this.props.toggleSelection(original.id, original.id)}
                                />
                                <span className="form-check-sign campaign-form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                filterable: false,
                Header: "",
                accessor: "",
                width: 50,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <div className={`cx-input-container cx-switch-container`}>
                            <Switch
                                key={"row-"+original.id}
                                offColor=""
                                offText=""
                                onColor=""
                                onText=""
                                onChange={(e) => { console.log(e); this.activations(original) }}
                                defaultValue={original.isTurnedOn}
                            />
                        </div>
                    );
                }
            },
            {
                Header: "Name",
                accessor: "name",
                filterMethod: (filter, row) => row.name.toLowerCase().includes(filter?.value?.toLowerCase()),
                Cell: ({ original }) => {
                    return <div className={'d-flex flex-column'}>
                        <div className={"cx-table-name-styling campaign"}><a onClick={(e) => { this.filterAndNavigate(original, e) }} href={'#'} title={original.name}>{original.name}</a></div>
                        <div className={"table-actions-icons"}>
                            <a href={'#'} onClick={(e) => { this.rowActions('edit', original, e) }}>Edit</a>&nbsp;&nbsp;
                            <a href={'#'} onClick={(e) => { this.rowActions('duplicate', original, e) }}>Duplicate</a>&nbsp;&nbsp;
                            <a href={'#'} onClick={(e) => { this.rowActions('report', original, e) }}>View Charts</a>
                        </div>
                    </div>;
                },
                width: 480
            },
            {
                Header: "Delivery",
                accessor: "delivery",
                Cell: ({original}) => {
                    return <Delivery delivery={original.delivery} />                       
                },
                filterMethod: (filter, row) => filter.value === "all" ? true : row.delivery.toLowerCase() === filter?.value?.toLowerCase(),
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                      <option value="In draft">In draft</option>
                      <option value="active">active</option>
                      <option value="inactive">inactive</option>
                      <option value="rejected">rejected</option>
                      <option value="pending delivery">pending delivery</option>
                      <option value="in review">in review</option>
                      <option value="rejected">rejected</option>
                      <option value="completed">completed</option>
                      <option value="deleted">deleted</option>
                    </select>
                  ),
            },
            // {
            //     Header: "Budget",
            //     accessor: "totalBudget",
            //     Cell: ({ original }) => {
            //         //return <span>{helper.formatCXNumber(original.impressions)}</span>
            //         return "Using ad set budget"
            //     }
            // },
            {
                Header: "Impressions",
                accessor: "currentImpressions",
                Cell: ({ original }) => {
                    return <span className="number-align-right">{helper.formatCXNumber(original.currentImpressions)}</span>
                }
            },
            {
                Header: "Clicks",
                accessor: "currentClicks",
                Cell: ({ original }) => {
                    return <span className="number-align-right">{helper.formatCXNumber(original.currentClicks)}</span>
                }
            },
            {
                Header: "CTR",
                accessor: "ctr",
                Cell: ({ original }) => {
                    return (
                      <span className="number-align-right">{original.ctr}</span>
                    );
                },
            },
            {
                Header: "Spent",
                accessor: "currentSpentBudget",
                Cell: ({ original }) => {
                    return <span className="number-align-right">${helper.formatCXNumber(original.currentSpentBudget)}</span>
                }
            },
        ];
    }

    filterAndNavigate = (original, e) => {
        if(e) e.preventDefault();
        if(!this.props.selected[original.id]){
            this.props.toggleSelection(original.id, original.id);
        }
        this.props.activateTab('adset');
    }

    getCampaignListData = async (page = '') => {
        this.setState({ loading: true });
        // calls the action to change the state of the store
        await this.props.fetchCampaigns(page);
        this.setState({ loading: false });
    }

    duplicateCampaignAction = (campaignId) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: 'duplicate', data: {typeid: campaignId} });
        }
        return false;
    }
    /*
     * Row actions of a table
     */
    rowActions = (action, obj, event) => {
        switch(action){
            default:
                this.openDrawer([{ typeid: obj.id, type: 'campaign',campaignId:obj.id }], event);
                this.props.setDrawerMode(action); // edit or report
                analytics.logEvent('Campaign','Campaign Table ' + action);
                // this.props.setDrawerMode(action, {type: 'campaign', typeid:  obj.id, campaignId: obj.id}); // edit or report
                break;
            case 'duplicate':
                this.duplicateCampaignAction(obj.id);
                analytics.logEvent('Campaign','Campaign Table ' + action);
                break;
        }
    }
    openDrawer = ( selectedData, event = null) => {
        if (event) event.preventDefault();
        this.props.setDrawerVisible(true, selectedData);
    }
    render() {
        return (
            <>
                <ReactTable
                    filterable={true}
                    noDataText=''
                    NoDataComponent={NoData}
                    defaultSorting={this.state.sorted}
                    sortable={true}
                    minRows={0}
                    data={this.props.campaignList}
                    resizable={false}
                    columns={this.tableCols()}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    const hideSelectAll = (
        state.campaignSelections &&
        state.campaignSelections.selected_arr &&
        (
            (state.campaignSelections.selected_arr.campaign && state.campaignSelections.selected_arr.campaign.length) ||
            (state.campaignSelections.selected_arr.adset && state.campaignSelections.selected_arr.adset.length) ||
            (state.campaignSelections.selected_arr.ad && state.campaignSelections.selected_arr.ad.length)
        )
    ) || false;

    return {
        hideSelectAll,
        campaignList: (state.campaigns && state.campaigns.campaignList) ? state.campaigns.campaignList : [],
        selectAll: (state.campaignSelections && state.campaignSelections.selectAll && state.campaignSelections.selectAll.campaign) ? state.campaignSelections.selectAll.campaign : 0,
        selected: (state.campaignSelections && state.campaignSelections.selected && state.campaignSelections.selected.campaign) ? state.campaignSelections.selected.campaign : {},
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setDrawerMode: (mode='edit') => dispatch(drawerActions.setDrawerMode(mode)),
        fetchCampaigns: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
        toggleSelection: (id, cid) => dispatch(campaignSelectionActions.toggleSelection('campaign', id, cid)),
        toggleAll: (obj) => dispatch(campaignSelectionActions.toggleAll('campaign', obj)),
        activateCampaign: (obj) => dispatch(campaignActions.activateCampaign(obj)),
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
        duplicateCampaign: (campaign) => dispatch(campaignActions.duplicateCampaign(campaign)),
        adsetReloadList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
        adReloadList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
        activateTab: (tab) => dispatch(tableActions.activateTab(tab)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(CampaignTable);