// import {UPLOAD_FILE} from "../constants/appConstant";
import {UPLOAD_PERCENT} from "../constants/appConstant";
export default function (state = {} , action) {
    switch(action.type) {
        // case UPLOAD_FILE:
        //     return { ...state, [action.payload.name]: action.payload.value };
        case UPLOAD_PERCENT:
            return { ...state, uploadPercent: action.payload };
        default:
            return state;
    }
}