import React, { useState } from "react";
import ReactTable from "react-table";
import {FormGroup, Input, Label} from "reactstrap";
import Boolean from "../../components/Boolean.jsx"
import noData from "../../../assets/img/no-data.svg"
import useUserStore from "./UserStore";
import CxLoader from "../../components/cxLoader"
import { fetchList, deleteUser } from "./userAPI";
import { useQuery, useMutation } from "react-query";
import { CXConfirm } from "../../../CXComponents";


const TableList = () =>  {

    const [sortingConfig, setSortingConfig] = useState([{ id: 'id', desc: true }]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const togglePopup = useUserStore(state=>state.togglePopup);
    const setItemToLoad = useUserStore(state=>state.setItemToLoad);
    const setActionType = useUserStore(state=>state.setActionType);
    const selectedRows = useUserStore(state=>state.selectedRows);
    const toggleSelectCheckBox = useUserStore(state=>state.toggleSelectCheckBox);


    const {isLoading, data, isFetching, refetch} = useQuery(['user-list'], fetchList, { refetchOnWindowFocus: false });
    const deleteUserQuery = useMutation(userID=>{
        return deleteUser(userID);
    });

    const pageSize = 10;

    const NoData = () => {
        return <img className="cx-no-data-svg" src={noData}/>
    }

    const rowActions = async (action = 'edit', obj, event) => {
        switch (action) {
            case "delete":
                setIsDeletePopupOpen(true);
                setItemToDelete(obj.id);
                break;
            case 'edit':
                setItemToLoad(obj.id);
                setActionType("edit");
                togglePopup();
                break;
        }
    }

    const tableCols = () => {
        return [
            {filterable:false,
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                    checked={selectedRows.includes(original.id)}
                                    onChange={() => toggleSelectCheckBox(original.id)}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                Header: "User",
                accessor: "name",
                Cell: ({ original }) => {
                    return(
                        <>
                        <div className={'d-flex'}>
                            <div className={'d-flex flex-column'}>
                                <div className={"cx-table-name-styling"}> <a href={'#'} onClick={(e) => { e.preventDefault(); rowActions('edit', original, e) }}>{original.name}</a></div>
                                <div className={"table-actions-icons"}>
                                    <a href={'#'} onClick={(e) => { e.preventDefault(); rowActions('edit', original, e) }}>Edit</a>&nbsp;&nbsp;
                                    <a href={'#'} onClick={(e) => { e.preventDefault(); rowActions('delete', original, e) }}>Delete</a>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                        </>
                    ) 
                }
                //width: 250
            }
            ,            
            {
                Header: "Campaign Review Required?",
                accessor: "isReviewRequired",
                Cell: ({ original }) => {
                    return <Boolean  status={original['isReviewRequired']} />;
                }
                , 
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row.isReviewRequired ;
                    }
                    return !row.isReviewRequired ;
                  },               
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">Show All</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>   
            }
            // ,
            // {
            //     Header: "Creation Date",
            //     accessor: "creationDate ",
            //     Cell: ({ original }) => {
            //         return <span>{new Date(original.creationDate).toLocaleDateString()}</span>
            //     }
            // }           
        ];
    }
   

    const renderTable = () =>
        <ReactTable
            filterable={true}
            defaultSorting={sortingConfig}
            sortable={true}
            showPageSizeOptions={false}
            minRows={0}
            data={data}
            resizable={false}
            columns={tableCols()}
            defaultPageSize={pageSize}
            showPaginationBottom={true}
            className="-highlight cx-reactive-table"
            NoDataComponent={NoData}
        />

    return (
        <>          
            {
                (isLoading || isFetching)
                ? <CxLoader minHeight="400px"/>
                : renderTable()
            }
            {
                isDeletePopupOpen && 
                <CXConfirm 
                    {...{
                        width: '400px',
                        title: "Delete User",
                        subtitle: "Are you sure you want to permanently delete this user?",
                        confirmText: "Delete",
                        cancelText: "Cancel",
                        confirmAction: async ()=>{
                            setIsDeletePopupOpen(false);
                            await deleteUserQuery.mutateAsync([itemToDelete]);
                            await refetch();
                        },
                        cancelAction: ()=>{
                            setIsDeletePopupOpen(false);
                        }
                    }} 
                />
            }
            
        </>
    );
}

export default TableList;