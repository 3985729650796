import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();
const helper = new main();

const newsletterPostsPerformance = {
    getNewsletterPostsPerformance: async () => {
        const url = apiUrl + `newsletter-posts-performance`;
        // return auth.request.post(url, { data: JSON.stringify({ fromDate: "2023-01-01", toDate: "2023-03-06" }) });

        return {
            data: {
                "data": [
                    {
                        "date_published": "2023-03-20T07:00:44Z",
                        "thumb": "https://lh3.googleusercontent.com/8uUYO4XE1Jt5vcDWxgoVmA_73f-GrdgLTs7KVUpGvIdUQXQaHuF1Fkc_grDyvmEJLG0d97CmJXjnqO36pBAKeMhDsAnMgfpw84uIUA_6UGiM3w",
                        "clicks": 0,
                        "impressions": 5,
                        "title": "سكان مدينة تركية استيقظوا ليجدوا أنفسهم في البحر \"صور\"",
                        "url": "https://www.albayan.ae/varieties/2023-03-20-1.4634977"
                    },
                    {
                        "date_published": "2023-03-20T21:50:20Z",
                        "thumb": "https://lh3.googleusercontent.com/5S_UVPKJX6hrASB54WP0FmYXpP-GN2gpjObEoep0VdQD0z3jNMxnSdUrIu6PUIgN8yfwv6tXWP2-lmqCaaXvsWi7ix_PdYhSFM-kF3XK8FgF",
                        "clicks": 0,
                        "impressions": 4,
                        "title": "مواد غذائية تحميك من خطر الإصابة بهشاشة العظام",
                        "url": "https://www.albayan.ae/health/life/2023-03-21-1.4635482"
                    },
                    {
                        "date_published": "2023-03-23T11:07:29Z",
                        "thumb": "https://lh3.googleusercontent.com/p2CpBTcyJSeSmlXNhGtsvbV_FjxxsIEDOGSv9z9VG24iHfs1wZXovqUvaBK6LxJOiKZ3BQrZ91qI0F9VO0c7ON1cBqke8GLy2UczSTmz7in3Kg",
                        "clicks": 0,
                        "impressions": 4,
                        "title": "وفاة «أبلة فضيلة» أشهر مقدمي برامج الأطفال",
                        "url": "https://www.albayan.ae/culture-art/celebrities/2023-03-23-1.4636831"
                    },
                    {
                        "date_published": "2023-03-19T07:53:41Z",
                        "thumb": "https://lh3.googleusercontent.com/rJTo100x5ivvNAZnEKJngzXSBK6fC_r4Ty5oA2AhL6H6u5QBjlv9Mkt36-Oeurz7hyGimWPaBZeydLgM_C6AimSeuTt0z4Sluyo7sDewjmHH0w",
                        "clicks": 0,
                        "impressions": 4,
                        "title": "دراسة يابانية.. الأجهزة الذكية لها تأثير محدود في نمو الأطفال",
                        "url": "https://www.albayan.ae/technology/2023-03-19-1.4634474"
                    },
                    {
                        "date_published": "2023-03-19T08:41:08Z",
                        "thumb": "https://lh3.googleusercontent.com/ZjcV31PY1yX42g8fRxmrafHxBV6njPyaGb2Tsl9W2pVP-zc1_qOW74xuzS42e1lMxZPu57A5Bo05vlN62FU1GNvBzADi5an6CKf1B3YToJ3CiQ",
                        "clicks": 0,
                        "impressions": 3,
                        "title": "المحكمة الدستورية الكويتية تبطل مجلس الأمة",
                        "url": "https://www.albayan.ae/world/arab/2023-03-19-1.4634484"
                    },
                    {
                        "date_published": "2023-03-21T19:54:25Z",
                        "thumb": "https://lh3.googleusercontent.com/DfAwKys5nNo36V8Ga7lt6JSiCkTt3wWmvzPaPSv5nquKCVMx73q_9VbvOBeTkgwf9CrhPXG-XhhiT4pnVnbF18vTkrQvvyICtZw3EkTAu3EKeY4",
                        "clicks": 0,
                        "impressions": 3,
                        "title": "هذه الدول أعلنت الخميس أول أيام شهر رمضان",
                        "url": "https://www.albayan.ae/varieties/2023-03-21-1.4635916"
                    },
                    {
                        "date_published": "2023-03-20T16:00:46Z",
                        "thumb": "https://lh3.googleusercontent.com/TDRdf4A1VpSs0m1Nk08hlRHxtIEW5At_9cVzmT7nYN5uXtwQJreuvg5TNMPqVXJX11zpLvx48S6Wm6hCBoAB3FqwcoVwohC9fmXIpAgJgR9QdWM",
                        "clicks": 0,
                        "impressions": 3,
                        "title": "كيف تحضر جسمك لاستقبال شهر رمضان؟",
                        "url": "https://www.albayan.ae/health/life/2023-03-20-1.4635179"
                    }
                ],
                "api": "/newsletter-posts-performance"
            }
        }
    },
    getNewsletterPerformance: async () => {
        const url = apiUrl + `newsletter-widget-performance`;
        // return auth.request.post(url, { data: JSON.stringify({ fromDate: "2023-01-01", toDate: "2023-03-06" }) });

        return {
            data: {
                "data": [
                    {
                        "date": "2023-03-24",
                        "widget_id": "5181750439313408",
                        "service": "email",
                        "impressions": 88,
                        "appdomain": "albayan.ae",
                        "clicks": 28,
                        "widget_name": "albayan Email Newsletter widget"
                    },
                    {
                        "date": "2023-03-27",
                        "widget_id": "5181750439313408",
                        "service": "email",
                        "impressions": 92,
                        "appdomain": "albayan.ae",
                        "clicks": 32,
                        "widget_name": "albayan Email Newsletter widget"
                    },
                    {
                        "date": "2023-03-28",
                        "widget_id": "5181750439313408",
                        "service": "email",
                        "impressions": 73,
                        "appdomain": "albayan.ae",
                        "clicks": 28,
                        "widget_name": "albayan Email Newsletter widget"
                    },
                    {
                        "date": "2023-03-29",
                        "widget_id": "5181750439313408",
                        "service": "email",
                        "impressions": 116,
                        "appdomain": "albayan.ae",
                        "clicks": 36,
                        "widget_name": "albayan Email Newsletter widget"
                    },
                    {
                        "date": "2023-03-30",
                        "widget_id": "5181750439313408",
                        "service": "email",
                        "impressions": 41,
                        "appdomain": "albayan.ae",
                        "clicks": 22,
                        "widget_name": "albayan Email Newsletter widget"
                    }
                ],
                "api": "/newsletter-widget-performance"
            }
        }
    },
    getNewsletterPerformanceTotals: async () => {
        const url = apiUrl + `newsletter-performance-totals`;
        // return auth.request.post(url, { data: JSON.stringify({ fromDate: "2023-01-01", toDate: "2023-03-06" }) });

        return {
            data: {
                "data": [
                    {
                        "impressions": 410,
                        "clicks": 146
                    }
                ],
                "api": "/newsletter-performance-totals"
            }
        }
    },
}
export const utitlityApi = {

}

export default newsletterPostsPerformance;