import React from "react";
import {formValueSelector} from "redux-form";
import {uploadActions} from "../../actions";
import {connect} from "react-redux";

class UploaderC  extends React.Component{
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            loader: false,
            loaded: false
        }
    }
    onChange = async (e) => {
        const file = e.target.files[0];
        console.log(file)
        // dispatch event to upload an image
        this.props.uploadImage(this.props.formName, this.props.input.name, file, this.props.adId);
        if(!this.state.loader){
            this.setState({loader:true});
        }
    }

    removeUpload = (e) => {
        e.preventDefault();
        this.props.removeUpload(this.props.formName, this.props.input.name);
        this.setState({ loaded: false });
    }

    handleImageLoaded = () => {
        this.setState({ loaded: true });
    }

    renderImage = () => {
        if(this.state.loader)
            this.setState({loader:false});
        return (
            <div className={"cx-uploaded-image-preview"}>
                <img
                    src={this.props.uploadedImage}
                    className={"cx-uploaded-image-img"}
                    onLoad={this.handleImageLoaded.bind(this)}
                    alt="uploaded"
                />
                {(this.state.loaded)? <button className={"cx-uploaded-image-remove"} onClick={(e)=>{this.removeUpload(e)}}><i className="fas fa-times"></i></button> : ''}
            </div>
        );
    }

    render(){
        const { input: { value } } = this.props;
        const {input, label, className, meta : { touched, error, warning } , showMsg=false, icon, placeholder, accept} = this.props;  //whatever props you send to the component from redux-form Field
        return(
            <div className={`cx-uploader-container cx-input-container ${(this.state.loader)? 'file-loading' : ''} ${(className)? className : ''}`}>
                {(!label) ? '' : <div className={"cx-input-label"}> <label>{label}</label> </div>}
                {!(this.props.uploadedImage)?
                    // upload an image
                    <div className={"cx-input-icon-container cx-uploader-icon-container"}>
                        <div className={`cx-clone-uploader-icon-container ${touched && ((error && 'error') || (warning && 'warning') || '')}`}>
                            <i className={(icon)? icon: '' }></i>
                            <span className={`input-like ${(icon)? 'with-icon' : ''}`}>
                                {(this.state.loader)? 'Uploading ('+ this.props.uploadPercent +'%)' : (placeholder)? placeholder :''}
                            </span>
                        </div>
                        {touched && ((error && <div className={`error input-error ${(showMsg)? 'show-msg': ''}`}>{error}</div>) || (warning && <div className={`warning input-warning ${(showMsg)? 'show-msg': ''}`}>{warning}</div>))}
                        <input
                            className={`cx-input-icon-input`}
                            type={"file"}
                            accept={accept}
                            onChange={this.onChange}
                        />
                    </div>
                : this.renderImage() }
            </div>
        )
    }
}






const uploaderMapStateToProps = (state, ownProps) => {
    return {
        uploadedImage: formValueSelector(ownProps.formName)(state, ownProps.input.name),
        uploadPercent: (state.upload && state.upload.uploadPercent)? state.upload.uploadPercent : 0
    }
};
const uploaderMapDispatchToProps = (dispatch)  => ({
    uploadImage: (form, fieldName, file, adId) => dispatch(uploadActions.uploadImage(form, fieldName, file, adId)),
    removeUpload: (form, fieldName) => dispatch(uploadActions.removeUpload(form, fieldName)),
});

export default connect(uploaderMapStateToProps, uploaderMapDispatchToProps)(UploaderC);