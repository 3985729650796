import React from 'react'
import {
    Card,
    CardBody,
    Row,
    CardTitle
} from "reactstrap";
import ReactTable from "react-table";

import postsApi from '../../../api/posts';
import Tooltip from "../../../components/Tooltips/Tooltip";
import {postsActions} from "../../../actions";
import {connect} from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
    return <img className="cx-no-data-svg" style={{width:'300px'}} src={noData}/>
};

class TopPostsEngagement extends React.Component {
    constructor(props) {
        super(props);
        this.postsApi = new postsApi();
        this.pagingData = this.pagingData.bind(this);
        this.state = {
            data: [],
            pageSize: 5,
            pages: null,
            loading: true,
            sorted: [{ id: 'cx_timespent', desc: true }]
        };
        this.cols = [
            {
                Header: "Post Title",
                accessor: "title",
                Cell: ({ original }) => {
                    return this.printArticle(original);
                }
            },
            {
                Header: (props) => <Tooltip text={"This is the average time spent on your article."} placement={"bottom"}><span>Timespent</span></Tooltip>,
                accessor: "timespent",
                Cell: ({ original }) => {
                    return parseInt(original.timespent) + ' sec';
                }, width: 110
            },

            {
                Header: (props) => <Tooltip text={"This is the average time spent by users who arrived at the article through CX"} placement={"bottom"}><span>CX Timespent</span></Tooltip>,
                accessor: "cx_timespent",
                Cell: ({ original }) => {
                    return (
                        <div>
                            <span>{parseInt(original.cx_timespent) + ' sec '}</span>
                            <span> {this.upOrDown(parseInt(original.cx_impact))}</span>
                        </div>
                    )
                },
                width: 160
            }
        ];
    }
    upOrDown = (numb, switchIt) => {
        numb = numb * 1;
        let color = 'green';
        if (switchIt) {
            color = 'red';
        }
        let sign = '+';
        let nav = 'up';
        if (numb < 0) {
            color = 'red';
            if (switchIt) {
                color = 'green';
            }
            sign = '';
            nav = 'down';
        }
        return <span style={{ color: color, 'fontWeight': 600, 'marginLeft': '10px' }}> {sign + numb}% <i className={"fa fa-arrow-" + nav} /> </span>
    }

    truncate = (text, nb) => {
        if (text.length > (nb * 1)) return text.substring(0, (nb * 1)) + '...';
        return text;
    }

    printArticle = (o) => {
        function setDate(date) {
            if (!date) return '';
            let d = new Date(date);
            return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
        }
        return <div className={"d-flex align-items-center"}>
            <div className={'img-container mr-3'}>
                <img src={o.thumb + '=w50-h40-c'} width={50} alt=""/>
            </div>
            <div style={{ 'fontFamily': 'Tahoma' }}>
            <a target="_blank" title={o.title} className={'d-block'} href={(o.url)? o.url : '#'} style={{ fontSize: '105%' }}>
            {this.truncate(o.title, 30)}
                </a>
                {/* <Link style={{ fontSize: '105%' }} title={o.title} className={'d-block'} to={'#'}>{this.truncate(o.title, 30)}</Link> */}
                <div className={"d-flex mt-1"}>
                    <span className={"mx-1"}>{setDate(o.datePublished)}</span>
                    <span className={"mx-1"}>{o.author}</span>
                    {/* <span className={"mx-1"}>{o.section}</span> */}
                </div>
            </div>
        </div>;
    }

   
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || 
        this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter))) {
            this.fetchData();
        }
    }
    fetchData = async () => {
       if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.getData(this.state.sorted, 5, 1);
            this.setState({ loader: false });
        }
    }

    getData = async (sort,size, page) => {
        if (this.props.dateFilter) {
        let firstSort = "desc";
        let finalOrder = "cx_timespent";

        if (sort && sort.length) {
            firstSort = sort[0].desc ? "desc" : "asc";
            finalOrder = sort[0].id;
        }
        await this.props.fetchData({ orderBy: finalOrder, sort: firstSort, size: size, page: page },
        this.props.pageFilters, this.props.dateFilter);

        if (this.props.cxEngagement && this.props.cxEngagement.length > 0) {
        const pages = (this.props.cxEngagement)? Math.ceil(this.props.cxEngagement[0].row_count / size): 0;
        this.setState({ pages: pages});
        }

        this.setState({ loader: false});
        }
    }

    pagingData(state, instance) {
        this.setState({ loader: true });
        this.getData(state.sorted, state.pageSize, state.page ? state.page + 1 : 1);
    }

    render() {
        const { pages } = this.state;
        return (
            <Card id="postsTableEngagement">
                <CardBody style={{ "minHeight": "380px" }}>
                    <Row className={"cx-card-title justify-content-between mx-0"}>
                        <CardTitle>Engagement by CX</CardTitle>
                        <Tooltip text={"This card demonstrates the added time spent by users who arrived at the articles through CX in comparison to the average time spent by users who arrived at the article organically."} />
                    </Row>
                    {this.state.loader ? <CxLoader /> : ''}
                    <ReactTable
                        NoDataComponent={NoData}
                        defaultSorting={this.state.sorted}
                        showPageSizeOptions={false}
                        manual={true}
                        minRows={0}
                        data={this.props.cxEngagement}
                        resizable={false}
                        columns={this.cols}
                        onFetchData={this.pagingData}
                        defaultPageSize={5}
                        showPaginationBottom={true}
                        className="-striped -highlight"
                        // loading={this.state.loading}
                        pages={pages}
                    />
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.postsInsight && state.postsInsight.pageFilters || null,
        cxEngagement: (state.postsInsight && state.postsInsight.cxEngagement) || [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (params,filter,dateFilter) => dispatch(postsActions.fetchEngagement(params,filter,dateFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopPostsEngagement);

