import React from "react";
import AdminLayout from "layouts/Admin/Admin.jsx";
// import RTLLayout from "layouts/RTL/RTL.jsx";

import mainHelper from "./helpers/main";
import routesConf from "routes.js";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import auth from "./api/auth";
import Pages from "./layouts/Auth/Auth";

const helpers = new mainHelper();
const authApi = new auth();

class App extends React.Component {
  componentWillReceiveProps() {
    this.routeChange();
  }

  componentWillMount() {
    this.routeChange();
  }

  userLogged = () => {
    return helpers.getAccessToken() ? true : false;
  };

  redirectUrl = () => {
    return helpers.getDefaultRoute();
  };

  routeChange = () => {
    authApi.refreshTokenHelper();
  };

  render() {
    let redirectUrl = this.redirectUrl();
    return (
      <Switch>
        <Route path="/auth" render={(props) => <Pages {...props} />} />
        { this.userLogged()
          ? (
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          )
          : (
            <Redirect to="/auth/login" />
          )
        }
        {redirectUrl ? <Redirect to={redirectUrl} /> : <></>}
      </Switch>
    );
  }
}

export default withRouter(App);
