import api,{utilityApi} from "../../api/digestReportSetting";
import {utitlityApi} from "../../api/articleBoost";

export const getDigestReportSettingList = async () => {
    return api.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const readDigestReportSetting = async (id) => {
    return api.read(id)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const createDigestReportSetting = async (data) => {
    return api.create(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const updateDigestReportSetting = async (data) => {
    return api.update(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const deleteDigestReportSetting = async (id) => {
    return api.delete({ids:[id]})
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getPublishers = async () => {
    return utilityApi.getPublishers()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const loadPublisher = async () => {
    return utitlityApi.getPublisherByOrganization()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
};

export const getDigestReportCharts = async () => {
    return utilityApi.getDigestReportCharts()
        .then(response => {
            if(response && response.data && response.data.data)
                return  response.data.data;
        })
        .catch(error => {
            throw(error);
        });

}
export const refetchList = (qc) => {
    return qc.invalidateQueries(["digest-report-setting-list"]);
}