import { EditorView, basicSetup } from "codemirror";
import {keymap} from "@codemirror/view"
import { javascript } from "@codemirror/lang-javascript";
import { xml } from "@codemirror/lang-xml";
import { css } from "@codemirror/lang-css";
import { json } from "@codemirror/lang-json";
import React from "react";
import { oneDark } from '@codemirror/theme-one-dark';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';

export default function useCodeMirror(value, lang, setState) {

    switch(lang.toLowerCase()){
      case "html": lang=xml; break;
      case "javascript": lang=javascript; break;
      case "css": lang=css; break;
      case "json": lang=json; break;
      default: lang=xml;
    }

    const [element, setElement] = React.useState();
  
    const ref = React.useCallback((node) => {
      if (!node) return;
  
      setElement(node);
    }, []);
  
    React.useEffect(() => {
      if (!element) return;
  
      const view = new EditorView({
        doc: value,
        extensions: [
          basicSetup,
          keymap.of([defaultKeymap, indentWithTab]),
          lang(),
          oneDark,
          EditorView.updateListener.of(update => {
            if(update?.startState?.doc?.text) setState(update?.startState?.doc?.text?.join("\n"))
          })
        ],
        parent: element
      });
  
      return () => view?.destroy();
    }, [element]);
  
    return { ref };
  }