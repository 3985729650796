import {
    CLASSIFICATION_ACTIVE_TAB,
    CLASSIFICATION_TABS,
    CLASSIFICATION_SUMMARY,
    CLASSIFICATION_PERFORMANCE,
    CLASSIFICATION_TABLE,
    CLASSIFICATION_PUBLISHED_ARTICLES,
    CLASSIFICATION_PERFORMANCE_FILTER,
    CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED
} from "../constants/appConstant";

export default function (state = {}, action) {
    switch (action.type) {
        case CLASSIFICATION_PERFORMANCE_FILTER:
            return { ...state, pageFilterList: action.payload };
        case CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED:
            return { ...state, pageFilters: action.payload };
        case CLASSIFICATION_ACTIVE_TAB:
            return { ...state, activetab: action.payload };
        case CLASSIFICATION_TABS:
            return { ...state, tabs : action.payload };
        case CLASSIFICATION_SUMMARY:
            return { ...state, summary : action.payload };
        case CLASSIFICATION_PERFORMANCE:
            return { ...state, performance : action.payload };
        case CLASSIFICATION_TABLE:
            return { ...state, table : action.payload };
        case CLASSIFICATION_PUBLISHED_ARTICLES:
            return { ...state, publishedArticles : action.payload };
        default:
            return state;
    }
}