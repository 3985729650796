import React, { Component } from "react";
import { connect } from 'react-redux';
import {popupActions, auditActions, tableActions} from '../../../actions';
import ReactTable from "react-table";
import Confirm from "../../components/confirmationBox";
import {FormGroup, Input, Label} from "reactstrap";
import Boolean from "../../components/Boolean.jsx"

class tableList extends Component {
    constructor(props) {
        super(props);
        // table defaults (effects the UI changes)
        
        this.state = {
            pageSize: 50,
            pages: null,
            loading: false,
            sorted: [{ id: 'creationDate', desc: true }],
        };

        this.notify = {
            delete: {
                title: 'Are you sure you want to delete the selected audits?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x*1;
                    });
                    await this.props.deleteItem({ ids: data });
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: `Delete`,
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount(page) {
        this.refreshData();
    }

    refreshData = async() =>{
        this.setState({ loading: true });
        await this.props.fetchList();
        this.setState({ loading: false });
    }

    tableCols = () => {
        return [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selected[original.id] === true}
                                       onChange={() => this.props.toggleSelection(original.id)}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                Header: "Audit",
                accessor: "description",
                Cell: ({ original }) => {
                    return <div className={'d-flex'}>
                        <div className={'d-flex flex-column'}>
                            <div className={"cx-table-name-styling"}> {original.description}</div>
                                               </div>
                    </div>
                }
                //width: 250
            } ,
            {
                Header: "User",
                accessor: "userName ",
                Cell: ({ original }) => {
                    return <span>{original.userName}</span>
                }
            },
            {
                Header: "Organization",
                accessor: "organizationName",
                Cell: ({ original }) => {
                    return <span>{original.organizationName}</span>
                }
            },
            {
                Header: "Entity",
                accessor: "entityType",
                Cell: ({ original }) => {
                    return <span>{original.entityType}</span>
                }
            },
            {
                Header: "IP",
                accessor: "userIpAddress",
                Cell: ({ original }) => {
                    return <span>{original.userIpAddress}</span>
                }
            },
            {
                Header: "Creation Date",
                accessor: "creationDate",
                Cell: ({ original }) => {
                    return <span>{new Date(original.creationDate).toLocaleDateString()}</span>
                }
            }           
        ];
    }

    /*
     * Row actions of a table
     */
    rowActions = async (action = 'delete', obj, event) => {
        switch (action) {
            case "delete":
                if (this.refs.confirmRef){
                    return this.refs.confirmRef.confirmOptionActions({ type: action, data: [obj.id] });
                }
                break;
            case 'edit':
                await this.props.loadItem(obj.id);
                this.props.togglePopup(true);
                this.props.setAuditActionType(action);
                break;
        }

    }

    render() {
        return (
            <>
                <ReactTable
                    defaultSorting={this.state.sorted}
                    sortable={true}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.tableList}
                    resizable={false}
                    columns={this.tableCols()}
                    defaultPageSize={this.state.pageSize}
                    showPaginationBottom={true}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    return {
        selected: (
            state.tabTable &&
            state.tabTable.tableSelections &&
            state.tabTable.tableSelections.audit
        )? state.tabTable.tableSelections.audit : {},
        tableList: (state.audit && state.audit.List) ? state.audit.List : [],
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchList: (type) => dispatch(auditActions.getList(200,1)),
        deleteItem: (ids) => dispatch(auditActions.deleteItem(ids)),
        loadItem: (id) => dispatch(auditActions.getItem({id})),
        setAuditActionType: (type) =>dispatch (auditActions.setAuditActionType(type)),
        toggleSelection: (id) => dispatch(tableActions.toggleSelection('audit', id)),
        togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(tableList);