import _auth from "./auth";
import apiUrl from "../helpers/global";
import main from "../helpers/main";

const auth = new _auth();
const helper = new main();

const digestReportSettingApi = {
    getList : async () => {
        const url = apiUrl + `/digest-report-setting-list`;
        return auth.request.post(url);
    },
    read : async (id) => {
        const url = apiUrl + `/digest-report-setting-read`;
        return auth.request.post(url, { data: JSON.stringify({id}) });
    },
    create : async (data) => {
        const url = apiUrl + `/digest-report-setting-create`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    update : async (data) => {
        const url = apiUrl + `/digest-report-setting-update`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    delete : async (data) => {
        const url = apiUrl + `/digest-report-setting-delete`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    }
}

export const utilityApi = {
    getPublishers : async () => {
        const url = apiUrl + `/publisher-list`;
        return auth.request.post(url);
    },
    getPublisherByOrganization : async () => {
        const url = apiUrl + `/publisher-read-by-organization`;
        return auth.request.post(url);
    },
    getDigestReportCharts : async () => {
        return auth.request.post(apiUrl + `/static`, { data: JSON.stringify({ name: "digestReportCharts" }) });
    }


}

export default digestReportSettingApi;