import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    adActions,
    adsetActions,
    campaignActions,
    campaignSelectionActions,
    drawerActions,
    popupActions
} from "../../../actions";
import Confirm from "../../components/confirmationBox";
import analytics from "../../../helpers/analytics";
class ActionBtns extends Component {
    constructor(props) {
        super(props);
        this.notify = {
            delete: {
                title: 'Are you sure you want to delete the selected?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x * 1;
                    });
                    if (this.props.activeTabList == "ad")
                        await this.props.deleteAd({ ids: data })
                    else if (this.props.activeTabList == "adset")
                        await this.props.deleteAdset({ ids: data })
                    else if (this.props.activeTabList == "campaign")
                        await this.props.deleteCampaign({ ids: data })

                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Delete',
                cancelBtnTxt: 'Cancel'
            },
            duplicate: {
                title: 'Are you sure you want to duplicate the selected?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x * 1;
                    });
                    if (this.props.activeTabList == "ad")
                        await this.props.duplicateAd({ ids: data })
                    else if (this.props.activeTabList == "adset")
                        await this.props.duplicateAdset({ ids: data })
                    else if (this.props.activeTabList == "campaign")
                        await this.props.duplicateCampaign({ ids: data })

                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: `Duplicate`,
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    refreshData = () => {
        this.props.reloadCampaignList();
        this.props.reloadAdsetList();
        this.props.reloadAdList();
        this.props.toggleAllOff(this.props.activeTabList);
    }

    openDrawer = (selectedData, event = null) => {
        if (event) event.preventDefault();
        this.props.setDrawerVisible(true, selectedData);
    }

    preventdefault = (event) => {
        if (event) event.preventDefault();
    }

    confirmOptionActions = (action) => {
        if (this.refs.confirmRef) {
            const selections = this.getSelections();
            return this.refs.confirmRef.confirmOptionActions({ type: action, data: selections.ids });
        }
        return false;
    }

    getSelections = () => {
        switch (this.props.activeTabList) {
            case 'campaign':
                if (this.props.campaignSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.campaignSelections
                    };
                }
                break;
            case 'adset':
                if (this.props.adsetSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.adsetSelections
                    };
                }
                break;
            case 'ad':
                if (this.props.adSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.adSelections
                    };
                }
                break;
        }
        return false;
    }

    actionToggle = (e, action = "edit") => {
        const selections = this.getSelections();
        const selected = selections.ids.map(c => ({ typeid: c * 1, type: selections.type, campaignId: this.props[`${selections.type}SelectionsMap`][c * 1] }));
        this.openDrawer(selected, e);
        this.props.setDrawerMode(action);
    }

    render() {
        const disabled = !this.getSelections();
        return (
            <div className={"header-btn-container"}>
                <button
                    className="btn cx-focus-btn"
                    onClick={() => {
                        analytics.logEvent('Campaign', this.props.activeTabList + ' Toolbar Create')
                        this.props.togglePopup(true, this.props.activeTabList)
                    }}
                >
                    <i className="fa fa-plus"></i>Create
                </button>
                <button
                    className="btn"
                    onClick={(e) => {
                        analytics.logEvent('Campaign', this.props.activeTabList + ' Toolbar Edit');
                        this.actionToggle(e, 'edit')
                    }}
                    disabled={disabled}
                >
                    <i className="fa fa-pencil-alt"></i>Edit
                </button>
                <button
                    className="btn"
                    onClick={(e) => {
                        this.preventdefault(e);
                        analytics.logEvent('Campaign', this.props.activeTabList + ' Toolbar Duplicate');
                        this.confirmOptionActions('duplicate')
                    }}
                    disabled={disabled}
                >
                    <i className="fa fa-clone"></i>Duplicate
                </button>
                <button
                    className="btn"
                    onClick={(e) => {
                        this.preventdefault(e);
                        analytics.logEvent('Campaign', this.props.activeTabList + ' Toolbar Delete');

                        this.confirmOptionActions('delete')
                    }}
                    disabled={disabled}
                >
                    <i className="fa fa-trash"></i>Delete
                </button>
                <button
                    className="btn"
                    onClick={(e) => {
                        analytics.logEvent('Campaign', this.props.activeTabList + ' Toolbar Report');
                        this.actionToggle(e, 'report')
                    }}
                    disabled={disabled}
                >
                    <i className="fa fa-chart-pie"></i>View Charts
                </button>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </div>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    const selections = state.campaignSelections && state.campaignSelections.selected_arr;
    const selectionsMap = state.campaignSelections && state.campaignSelections.selectionMap;
    return {
        campaignSelectionsMap: (selectionsMap) ? (selectionsMap.campaign) ? selectionsMap.campaign : {} : {},
        adsetSelectionsMap: (selectionsMap) ? (selectionsMap.adset) ? selectionsMap.adset : {} : {},
        adSelectionsMap: (selectionsMap) ? (selections.ad) ? selectionsMap.ad : {} : {},
        campaignSelections: (selections) ? (selections.campaign) ? selections.campaign : [] : [],
        adsetSelections: (selections) ? (selections.adset) ? selections.adset : [] : [],
        adSelections: (selections) ? (selections.ad) ? selections.ad : [] : [],
        activeTabList: state.tabTable && state.tabTable.activeTab
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setDrawerMode: (mode = 'edit') => dispatch(drawerActions.setDrawerMode(mode)),
        togglePopup: (action, tab) => dispatch(popupActions.togglePopup(action, tab)),
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
        reloadCampaignList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
        reloadAdsetList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
        reloadAdList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
        duplicateAd: (item) => dispatch(adActions.duplicateAd(item)),
        duplicateAdset: (item) => dispatch(adsetActions.duplicateAdset(item)),
        duplicateCampaign: (campaign) => dispatch(campaignActions.duplicateCampaign(campaign)),
        deleteCampaign: (item) => dispatch(campaignActions.deleteCampaign(item)),
        deleteAdset: (item) => dispatch(adsetActions.deleteAdset(item)),
        deleteAd: (item) => dispatch(adActions.deleteAd(item)),
        toggleAllOff: (type) => dispatch(campaignSelectionActions.toggleAllOff(type))
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(ActionBtns);