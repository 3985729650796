import React, { Component } from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import ActionBtns from ".//actionBtns";
import RoleTable from "./tableList";
import RoleForm from "./popupForm";
import _ from "lodash";
import { roleActions } from "../../../actions";
import Popup from "../../components/popup";

class RoleList  extends Component {

    constructor(props) {
        super(props);
        this.props.getPermissionList();
        this.state={
            changeDefaultRole: 0,
        }
    }

    componentDidUpdate(prevProps) {
        // Create 
        if (this.props.createErr && !_.isEqual(this.props.createErr, prevProps.createErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.createErrEvent();
        }
        if (this.props.create && !_.isEqual(this.props.create, prevProps.create)) {
            this.alertMsg('Role Created Successfully', 'fa fa-check', 'success');
            this.props.createEvent();
        }
        // Update 
        if (this.props.updateErr && !_.isEqual(this.props.updateErr, prevProps.updateErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.updateErrEvent();
        }
        if (this.props.update && !_.isEqual(this.props.update, prevProps.update)) {
            this.alertMsg('Role Updated Successfully', 'fa fa-check', 'success');
            this.props.updateEvent();
        }
        // delete
        if (this.props.deleteErr && !_.isEqual(this.props.deleteErr, prevProps.deleteErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.deleteErrEvent();
        }
        if (this.props.delete && !_.isEqual(this.props.delete, prevProps.delete)) {
            this.alertMsg('Role Deleted Successfully', 'fa fa-check', 'success');
            this.props.deleteEvent();
        }
    }

    alertMsg = (msg, icon = 'tim-icons icon-simple-remove', type = 'danger') => {
        this.refs.notificationAlert.notificationAlert({
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    render() {
        return (
            <>
                <Popup title={"Role"} width={'35%'}>
                 <RoleForm />                
                </Popup>

                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; Roles</h4>
                    </div>
                    <div className={"page-reactive-table"}>                    
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-role-'}>
                                    <RoleTable />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        actionType: (state.role && state.role.actionType)? state.role.actionType : 'create',
        Item: state.role && state.role.Item,
        createErr: (state.role && state.role.createErr) || null,
        create: (state.role && state.role.create) || null,
        updateErr: (state.role && state.role.updateErr) || null,
        update: (state.role && state.role.update) || null,
        deleteErr: (state.role && state.role.deleteErr) || null,
        delete: (state.role && state.role.delete) || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent:() => dispatch(roleActions.createEvent(undefined)),
        createErrEvent: () => dispatch(roleActions.createErrEvent(undefined)),
        updateErrEvent: () => dispatch(roleActions.updateErrEvent(undefined)),
        updateEvent: () => dispatch(roleActions.updateErrEvent(undefined)),
        deleteErrEvent: () => dispatch(roleActions.deleteErrEvent(undefined)),
        deleteEvent: () => dispatch(roleActions.deleteEvent(undefined)),
        getPermissionList: () => dispatch(roleActions.getPermissionList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);