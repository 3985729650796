import React, { useState } from "react";
import CxLoader from "../../components/cxLoader";

function NetworkMonitor() {
    const [isLoading, setIsLoading] = useState(true)

    const renderReport = () => {
        return(
            <> {isLoading
                ? <CxLoader minHeight="90vh"/>
                : <></>}
            <iframe
                id="network-monitor-iframe"
                width="85%"
                height="8000px"
                src="https://datastudio.google.com/embed/reporting/a5474b19-df03-4901-944b-8d2396fd7d4e/page/p_0oqn85w7xc"
                frameBorder="0"
                style={{display:'block', marginTop: '2rem', marginInline:'auto', border:0}}
                allowFullScreen
                onLoad={()=>{setIsLoading(false)}}
            ></iframe>
            </>
        )
    }

    return (
        <div className="content cx-page-networkMonitor">
            <div>
                <h4 style={{ "marginBottom": "20px" }}><i className="fa fa-cog"></i>&nbsp; Campaign Monitor</h4>
            </div>
            {renderReport()}
        </div>
    );
}

export default NetworkMonitor;
