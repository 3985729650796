import React, { Component } from "react";
import { connect } from 'react-redux';
import {campaignSelectionActions, tableActions} from '../../../actions';

class CampTabs extends Component {
    constructor(props) {
        super(props);
        this.props.activateTab();
    }

    removeSelections = (type) => {
        switch(type) {
            case 'campaign':
                this.props.toggleAllOff(type);
                break;
            case 'adset':
                this.props.toggleAllOff(type);
                break;
            case 'ad':
                this.props.toggleAllOff(type);
                break;
        }
    }

    renderSelectText = (type) => {
        const campaign = this.props.campaignSelections.length;
        const adset = this.props.adsetSelections.length;

        switch (type) {
            case 'campaign':
                return false;
            case 'adset':
                const plural = (campaign && campaign > 1)? 's': '';
                return (campaign)? 'for ' + campaign + ' campaign' + plural : false;
            case 'ad':
                const pluralAdset = (adset && adset > 1)? 's': '';
                const pluralCampaign = (campaign && campaign > 1)? 's': '';
                return (adset)? 'for ' + adset + ' adset' + pluralAdset : (campaign)? 'for ' + campaign + ' campaign' + pluralCampaign : false;
        }
    }

    tabSelections = (type) => {
        const typeLength = this.props[type+'Selections'].length;
        if(typeLength) {
            return (
                <span className={'cx-tab-remove-selected'} onClick={(e)=>{e.stopPropagation(); this.removeSelections(type);}}>
                    {typeLength} selected <i className={"far fa-times-circle"}></i>
                </span>
            )
        }
        return this.renderSelectText(type);
    }

    render() {
        return (
            <div className={"reactive-tabs"}>
                <div
                    id={"campaigns-tab"}
                    className={`${(this.props.activeTabList === 'campaign') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.activateTab('campaign') }}
                >
                    <i className={"fa fa-bullhorn"}></i> Campaigns {this.tabSelections('campaign')}
                </div>
                <div
                    id={"adsets-tab"}
                    className={`${(this.props.activeTabList === 'adset') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.activateTab('adset') }}
                >
                    <i className={"fa fa-images"}></i> Adsets {this.tabSelections('adset')}
                </div>
                <div
                    id={"ads-tab"}
                    className={`${(this.props.activeTabList === 'ad') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.activateTab('ad') }}
                >
                    <i className={"fa fa-image"}></i> Ads {this.tabSelections('ad')}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selections =  state.campaignSelections && state.campaignSelections.selected_arr;
    return {
        activeTabList: state.tabTable && state.tabTable.activeTab,
        campaignSelections: (selections)? (selections.campaign)? selections.campaign : [] : [],
        adsetSelections: (selections)? (selections.adset)? selections.adset : [] : [],
        adSelections: (selections)? (selections.ad)? selections.ad : [] : [],
        campaignList: (state.campaigns && state.campaigns.campaignList) ? state.campaigns.campaignList : [],
        adsetList: (state.adsets && state.adsets.adsetList)? state.adsets.adsetList: [],
        adList: (state.adsets && state.adsets.adList)? state.adsets.adList: [],
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        activateTab: (tab) => dispatch(tableActions.activateTab(tab)),
        toggleAllOff: (type) => dispatch(campaignSelectionActions.toggleAllOff(type))
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(CampTabs);