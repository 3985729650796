import React from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import mainHelper from "../../../helpers/main";
import Tooltip from "../../../components/Tooltips/Tooltip";
import { postsActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data-for-charts.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

let helpers = new mainHelper();

class PageviewsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      (this.props.dateFilter &&
        !_.isEqual(this.props.dateFilter, prevProps.dateFilter))
    ) {
      this.fetchData();
    }
  }
  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(this.props.pageFilters, this.props.dateFilter);
      this.setState({ loader: false });
    }
  };

  getPageviewsTooltip(data) {
    return (
      helpers.formatDecimal(data.cx_pageviews_percentage) +
      "%: " +
      data.cx_pageviews.toLocaleString() +
      " out of " +
      data.total_pageviews.toLocaleString() +
      " pageviews"
    );
  }

  getFilterTooltip() {
    // let txt = '';
    // var filterData = this.props.metaFilters;

    // for (let keyFilterType in filterData) {
    //     let keyFilterData = filterData[keyFilterType];
    //     if (!keyFilterData.length) continue;
    //     if (txt !== '') txt += '  -  ';
    //     txt += keyFilterType + ':';
    //     for (let index in keyFilterData) {
    //         let item = keyFilterData[index];
    //         txt += item.value;
    //         if (keyFilterData.length - 1 !== index * 1) txt += ',';
    //     }
    // }
    // if (txt)
    //     return txt;
    return "Please choose filter..";
  }

  getTimespentTooltip(data) {
    return (
      helpers.formatDecimal(data.cx_timespent_percentage) +
      "%: " +
      helpers.formatTime(data.cx_timespent_seconds) +
      " out of " +
      helpers.formatTime(data.total_timespent_seconds)
    );
  }

  render() {
    const { summary } = this.props;
    return (
      <Card>
        <CardBody style={{ height: "380px" }}>
          {this.state.loader ? <CxLoader /> : ""}
          {summary && Object.keys(summary).length ? (
            <>
              <Row className={"cx-card-title justify-content-between mx-0"}>
                <CardTitle>Summary</CardTitle>
                <Tooltip
                  text={
                    "This card summarises the impact CX has had on your Pageviews and time spent on site"
                  }
                ></Tooltip>
              </Row>
              <Row>
                <Col>
                  <div className="card-description">
                    CognativeX brought&nbsp;
                    <Tooltip text={this.getPageviewsTooltip(summary)} >
                      <span
                        className="text-warning"y
                        style={{ fontSize: "1.3rem" }}
                      >
                        {helpers.formatCXNumber(summary.cx_pageviews)}
                      </span>
                    </Tooltip>
                    <span>&nbsp;of page views across all articles</span>
                    <Tooltip text={this.getFilterTooltip()}>
                      <a href="/#" onClick={(e) => e.preventDefault()}>
                        &nbsp;for the selected filters
                      </a>
                    </Tooltip>
                    <span>
                      &nbsp;during the
                      <Tooltip
                        text={
                          this.props.dateFilter.startDate +
                          "  -  " +
                          this.props.dateFilter.endDate
                        }
                      >
                        <a href="/#" onClick={(e) => e.preventDefault()}>
                          &nbsp;selected period
                        </a>
                      </Tooltip>
                      .
                    </span>
                  </div>

                  <hr
                    style={{ borderTop: "1px solid rgba(255, 255, 255, 0.1)" }}
                  />
                  <div className="card-description">
                    CognativeX added&nbsp;
                    <Tooltip text={this.getTimespentTooltip(summary)}>
                      <span
                        className="text-warning"
                        style={{ fontSize: "1.3rem" }}
                      >
                        {parseInt(summary.cx_timespent_seconds / 3600)} hours
                      </span>
                    </Tooltip>
                    &nbsp;to the overall time spent.
                  </div>
                  <hr
                    style={{ borderTop: "1px solid rgba(255, 255, 255, 0.1)" }}
                  />

                  <Row className={"mt-4 mx-0"}>
                    <Col lg={12} className="">
                      <Row>
                        <Col className={"px-1 py-2"} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip
                                text={"Number of posts that were recommended"}
                              >
                                <span> Recommended Posts </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {helpers.formatNumber(summary.post_impressions)}
                            </p>
                          </div>
                        </Col>
                        <Col className={"px-1 py-2"} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip text={"Number of posts that were seen"}>
                                <span> Viewed Posts </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {helpers.formatNumber(summary.post_views)}
                            </p>
                          </div>
                        </Col>
                        <Col className={"px-1 py-2"} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip
                                text={"Number of posts that were clicked"}
                              >
                                <span> Clicked Posts </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {helpers.formatNumber(summary.post_clicks)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={"mx-0"}>
                    <Col lg={12} className="">
                      <Row>
                        <Col className={"px-1 "} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip
                                text={
                                  "Number of users reached with recommendations"
                                }
                              >
                                <span> Distinct Users Reached</span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {helpers.formatNumber(summary.distinct_users)}
                            </p>
                          </div>
                        </Col>
                        <Col className={"px-1"} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip text={"Number of articles published"}>
                                <span> Published Posts </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {helpers.formatNumber(summary.published_posts)}
                            </p>
                          </div>
                        </Col>
                        <Col className={"px-1"} lg={4}>
                          <div className={"px-1 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip text={"Number of integrated widgets"}>
                                <span> Active Widgets </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {summary.widget_count
                                ? helpers.formatNumber(summary.widget_count)
                                : "-"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <NoData />
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: (state.filter && state.filter.date) || null,
    pageFilters: (state.postsInsight && state.postsInsight.pageFilters) || null,
    summary: (state.postsInsight && state.postsInsight.summary) || null,
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (filter, dateFilter) =>
      dispatch(postsActions.fetchSummary(filter, dateFilter)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(PageviewsSummary);
