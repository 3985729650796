import {
    WIDGET_PERFORMANCE_FILTER,
    WIDGET_PERFORMANCE_FILTERS_SELECTED,
    WIDGET_PERFORMANCE_SUMMARY,
    WIDGET_PERFORMANCE_BY_DEVICE,
    WIDGET_PERFORMANCE_BY_TIME,
    WIDGET_PERFORMANCE_BY_LOCATION,
    WIDGET_PERFORMANCE_TOP_POSTS,
    WIDGET_PERFORMANCE_TOP_WIDGETS
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case WIDGET_PERFORMANCE_FILTER:
            return { ...state, pageFilterList: action.payload };
        case WIDGET_PERFORMANCE_FILTERS_SELECTED:
            return { ...state, pageFilters: action.payload };
        case WIDGET_PERFORMANCE_SUMMARY:
            return { ...state, summary: action.payload };
        case WIDGET_PERFORMANCE_BY_DEVICE:
            return { ...state, devicesData: action.payload };
        case WIDGET_PERFORMANCE_BY_TIME:
            return { ...state, timeGraphData: action.payload };
        case WIDGET_PERFORMANCE_BY_LOCATION:
            return { ...state, locationGraphData: action.payload };
        case WIDGET_PERFORMANCE_TOP_POSTS:
            return { ...state, topPostsData: action.payload };
        case WIDGET_PERFORMANCE_TOP_WIDGETS:
            return { ...state, topWidgetsData: action.payload };
        default:
            return state;
    }
}