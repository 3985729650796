import React, { Component } from "react";
import { connect } from 'react-redux';
import {popupActions, recommendationActions, tableActions} from '../../../actions';
import ReactTable from "react-table";
import Confirm from "../../components/confirmationBox";
import {FormGroup, Input, Label} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import noData from "../../../assets/img/no-data.svg"

const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

class tableList extends Component {
    constructor(props) {
        super(props);
        // table defaults (effects the UI changes)
        this.state = {
            pageSize: 10,
            pages: null,
            loading: false,
            sorted: [{ id: 'id', desc: true }],
        };
        this.notify = {
            delete: {
                title: 'Are you sure you want to delete the selected configs?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x*1;
                    });
                    await this.props.deleteItem({ ids: data });
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: `Delete`,
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    componentDidMount(page) {
        this.refreshData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.errorFetchList && prevProps.errorFetchList != this.props.errorFetchList){
            this.props.removeListErr();
            this.setState({ loading: false });
            this.alertMsg('Error fetching the list, try again later');
        }
    }

    refreshData = async() =>{
        this.setState({ loading: true });
        await this.props.fetchList();
        this.setState({ loading: false });
    }

    tableCols = () => {
        return [
            {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selected[original.id] === true}
                                       onChange={() => this.props.toggleSelection(original.id)}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                Header: "Class Mapping",
                accessor: "classMapping",
                Cell: ({ original }) => {
                    return <div className={'d-flex'}>
                        <div className={'d-flex flex-column'}>
                            <div className={"cx-table-name-styling"}> <a href={'#'} onClick={(e) => { e.preventDefault(); this.rowActions('edit', original, e) }}>{original.classMapping}</a></div>
                            <div className={"table-actions-icons"}>
                                <a href={'#'} onClick={(e) => { e.preventDefault(); this.rowActions('edit', original, e) }}>Edit</a>&nbsp;&nbsp;
                                <a href={'#'} onClick={(e) => { e.preventDefault(); this.rowActions('delete', original, e) }}>Delete</a>&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                },
                width: 250
            },
            {
                Header: "Class Values",
                accessor: "classValueList",
                Cell: ({ original }) => {
                    return <span>{(original.classValueList.length)? original.classValueList.join(' - ') : '-' }</span>
                }
            },
            {
                Header: "Freshness (days)",
                accessor: "freshnessDays ",
                Cell: ({ original }) => {
                    return <span>{original.freshnessDays}</span>
                }
            }
        ];
    }

    /*
     * Row actions of a table
     */
    rowActions = async (action = 'delete', obj, event) => {
        switch (action) {
            case "delete":
                if (this.refs.confirmRef){
                    return this.refs.confirmRef.confirmOptionActions({ type: action, data: [obj.id] });
                }
                break;
            case 'edit':
                await this.props.loadItem(obj.id);
                this.props.togglePopup(true);
                this.props.setRecommendationActionType(action);
                break;
        }

    }

    render() {
        return (
            <>
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <ReactTable
                    NoDataComponent={NoData}
                    defaultSorting={this.state.sorted}
                    sortable={true}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.tableList}
                    resizable={false}
                    columns={this.tableCols()}
                    defaultPageSize={this.state.pageSize}
                    showPaginationBottom={true}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    return {
        selected: (
            state.tabTable &&
            state.tabTable.tableSelections &&
            state.tabTable.tableSelections.recommendationConfig
        )? state.tabTable.tableSelections.recommendationConfig : {},
        tableList: (state.recommendation && state.recommendation.configList) ? state.recommendation.configList : [],
        errorFetchList: (state.recommendation &&  state.recommendation.configListErr)? state.recommendation.configListErr : null
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        removeListErr : () => dispatch(recommendationActions.configListErrEvent(undefined)),
        fetchList: (type) => dispatch(recommendationActions.getConfigList()),
        deleteItem: (ids) => dispatch(recommendationActions.deleteConfig(ids)),
        loadItem: (id) => dispatch(recommendationActions.getConfigItem({id})),
        setRecommendationActionType: (type) =>dispatch (recommendationActions.setRecommendationActionType(type)),
        toggleSelection: (id) => dispatch(tableActions.toggleSelection('recommendationConfig', id)),
        togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(tableList);