import React from "react";
import { useFieldArray } from "react-hook-form";
import SchemaFieldItem from "./SchemaFieldItem"
import { useWatch } from "react-hook-form";

const SchemaField = ({ control, register, mappingsList }) => {


    const { fields, append, remove, insert, update } = useFieldArray({
        control,
        name: "outline",
    });

    const watch = useWatch({ control, name: "outline" });

    const testWatch = useWatch({ control, name: `outline.${0}.category`,})

    const addSchemaItem = () => {
        append({
            logic: "Trending",
            mapping: "category",
            category: "Default",
            allowBoosting: false,
        });
    };

    const clearAll = () => {
        // will remove all fields when no index provided
        remove();
    };

    const duplicateField = (index) => {
        insert(index + 1, {
            logic: watch[index].logic,
            mapping: watch[index].mapping,
            category: watch[index].category,
            allowBoosting: watch[index].allowBoosting,
        });
    };

    return (
        <div className="schema-field-container">
            <div className="add-schema">
                <button
                    type="button"
                    className="cx-btn"
                    onClick={addSchemaItem}
                >
                    <i className="fa fa-plus"></i>
                </button>
                {fields.length ? (
                    <span>
                        This widget has <span>{fields.length}</span> item
                        {fields.length > 1 ? "s." : "."}
                        <span onClick={clearAll}> Clear All </span>
                    </span>
                ) : (
                    <></>
                )}
            </div>
            <br />
            <div className="schema-field-items">
                {fields.map((field, index) => {
                    return (
                        <SchemaFieldItem 
                          {...{
                            key: field.id,
                            duplicateField,
                            index,
                            control,
                            register,
                            remove,
                            update,
                            mappingsList,
                            field
                          }}

                        />
                    );
                })}
            </div>
        </div>
    );
};

export default SchemaField;
