import React, {useState, useEffect} from 'react';
import CxLoader from '../components/cxLoader';
import { useQuery } from "react-query";
import useArticleBoostingStore from "./articleBoostingStore";
import ArticleBoostingCard from "./components/articleBoostingCard";
import {getList,loadPublisher,getWidgets} from './articleBoostAPI';
import ArticleBoostingForm from "./articleBoostingForm";
import PopupNew from "../components/popup_new";
import {getSegments} from "../Segmentation/segmentAPI";
import {getContentGList} from "../ContentGrouping/contentGroupingAPI";



function ArticleBoosting(){
    const {isLoading, isFetching, data} = useQuery(["article-boosting-list"], ()=> {
        return getList();
    }, {refetchOnWindowFocus: false});

    const getPublisherData = useQuery(["publisher-data"], () => {
        return loadPublisher();
    },{refetchOnWindowFocus: false});

    const getWidgetsQuery = useQuery(["widgets-list"], () => {
        return getWidgets()
    }, {refetchOnWindowFocus: false});

    const getSegmentsQuery = useQuery(["segments-list"], ()=>{
        return getSegments();
    }, { refetchOnWindowFocus: false });
    const getContentGroup = useQuery(["content-grouping-list"], ()=>{
        return getContentGList();
    }, { refetchOnWindowFocus: false });

    const isOpen = useArticleBoostingStore(state => state.isOpen);
    const setIsOpen = useArticleBoostingStore(state => state.setIsOpen);
    const setActionType = useArticleBoostingStore(state => state.setActionType);
    const actionType = useArticleBoostingStore(state => state.actionType);


    return(
        <>
            <div className="content">
                <div>
                    <h4 style={{ "marginBottom": "20px" }}><i className="fa fa-bell"></i>&nbsp; Notification</h4>
                    <br />
                    <br />
                    <div className="cx-btn btn-blue-gradient" onClick={()=>{setActionType('create'); setIsOpen(true)}}>
                        <i className="fa fa-plus"></i> Create Notification
                    </div>
                    <br />
                </div>
                {
                    (!isLoading && !isFetching && !getPublisherData.isLoading && !getWidgetsQuery.isLoading &&!getSegmentsQuery.isLoading && !getSegmentsQuery.isFetching && !getContentGroup.isLoading && !getContentGroup.isFetching)
                        ? <div className="boostedPostList">

                            {
                                data &&
                                data.map((notification, i)=>{
                                    const postObject = JSON.parse(notification.post);
                                    return ( ""
                                        // <ArticleBoostingCard
                                        //     key={i}
                                        //     id={notification.id}
                                        //     title={postObject.title}
                                        //     duration={notification.duration}
                                        //     isActive={notification.isActive}
                                        //     cogThumb={postObject.cogThumb}
                                        // />
                                    )
                                })
                            }
                            <PopupNew title={(actionType === 'create' ? "Create" : "Edit") + " Notification"} width={'40%'} isPopupOpen={isOpen} togglePopup={()=>setIsOpen(false)}>
                                <ArticleBoostingForm publisherData={getPublisherData.data} widgetsList={getWidgetsQuery.data} segments={getSegmentsQuery.data} contentGroups = {getContentGroup.data} />
                            </PopupNew>
                        </div>
                        : <CxLoader minHeight="80vh"/>
                }
            </div>
        </>
    );
}

export default ArticleBoosting;