import {
    SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE,
    FETCH_CAMPAIGN_CATEGORY_MAPPINGS,
    FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM,
    UPDATE_CAMPAIGN_CATEGORY_MAPPING,
    UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING,
    CREATE_CAMPAIGN_CATEGORY_MAPPING,
    CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING,
    DELETE_CAMPAIGN_CATEGORY_MAPPING,
    DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING,
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_CAMPAIGN_CATEGORY_MAPPINGS:
            return { ...state, List: action.payload };
        case FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM:
            return { ...state,Item: action.payload };
        case UPDATE_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, update: action.payload };
        case UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, updateErr: action.payload };
        case CREATE_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, create: action.payload };
        case CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, createErr: action.payload };
        case DELETE_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, deleteItem: action.payload };
        case DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING:
            return { ...state, deleteErr: action.payload };       
        default:
            return state;
    }
}