import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import logo from "assets/img/logo.png";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

// import routes from "routes.js";
import routesConf from "routes.js";

let routes = routesConf["routes"];

class Pages extends React.Component {

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views); 
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
            window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getFullPageName = (routes) => {
    let pageName = this.getActiveRoute(routes);
    switch (pageName) {
      case "Pricing":
        return "pricing-page";
      case "Login":
        return "login-page";
      case "Register":
        return "register-page";
      case "Lock Screen":
        return "lock-page";
      default:
        return "Default Brand Text";
    }
  };

  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }

  render() {
    console.log(this.getRoutes(routes))
    return (
      <>
        <AuthNavbar brandText={this.getActiveRoute(routes)} />
        <img
          src={logo}
          alt={"logo"}
          style={{
            width: "60px",
            padding: "20px 0px 0px 20px",
          }}
        />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className={"full-page " + this.getFullPageName(routes)}>
            <Switch>{this.getRoutes(routes)}</Switch>
  
            <Footer fluid />
          </div>
        </div>
      </>
    );
  }
}

export const CheckExist = () => {
  useEffect(() => {
    const json = localStorage.getItem("new_token");
    if (!json) {
      window.location = "/auth/login";
    }
    const data = JSON.parse(json);
    if (Date.now() > data.ep) {
      window.localStorage.clear();

      window.location = "/auth/login";
    }

    const mouse = window.addEventListener("mousemove", () => {
      const json = JSON.parse(localStorage.getItem("new_token"));
      json.ep = Date.now() + 1000 * 60 * 15;
      localStorage.setItem("new_token", JSON.stringify(json));
    });

    const invertal = setInterval(() => {
      const json = JSON.parse(localStorage.getItem("new_token"));

      if (json) {
        if (Date.now() > json.ep) {
          window.localStorage.clear();

          window.location = "/auth/login";
        }
      } else {
        clearInterval(invertal);
      }
    }, 10000);

    return () => {
      clearInterval(invertal);
      mouse.removeEventListener("mousemove", () => {});
    };
  }, []);

  return <div></div>;
};

export default Pages;
