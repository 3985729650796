import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Row, Col} from 'reactstrap'

const WidgetScripts = ({widgets, domain}) => {


    const [activeTab, setActiveTab] = useState("wordpress");


    const trackingScriptTemplate = "<!-- Header Scripts (please insert the below scripts in the head tag) -->\n<script>\n\t(function (s, l, d, a) {\n\t\tvar h = d.location.protocol, i = l + \"-\" + s, td = new Date(),\n\t\t\tdt = td.getFullYear() + '-' + (td.getMonth() + 1) + '-' + td.getDate();\n\t\tf = d.getElementsByTagName(s)[0],\n\t\t\te = d.getElementById(i), u = \"static.cognativex.com\";\n\t\tif (e) return;\n\t\te = d.createElement(s); e.id = i; e.async = true;\n\t\te.src = h + \"//\" + u + \"/\" + l + \"/cn.js?v=\" + dt; e.setAttribute('data-domain', a);\n\t\tf.parentNode.insertBefore(e, f);\n\t})(\"script\", \"cognativex\", document, \"@DOMAIN@\");\n</script>";

    const renderWidgetsTemplate = `<script>
        window.COGNATIVEX = window.COGNATIVEX || {};
        window.COGNATIVEX.widgets = window.COGNATIVEX.widgets || [];
        window.COGNATIVEX.widgets.push(function () {
            window.COGNATIVEX.renderWidgets([@WIDGET_IDS@]);
        });
</script>`;

    function getScript(){
        const ids = [];
        for(let i = 0 ; i < widgets.length ; i++){
            ids.push(widgets[i].id)
        }
        
        return trackingScriptTemplate.replace('@DOMAIN@', domain || 'domainExample')
                +"\n"
                + renderWidgetsTemplate.replace('@WIDGET_IDS@', ids.map(id=>`"${id}"`).join(','));
    }

    function getDivs(){
        if(widgets.length === 0) return "No Widgets";
        let out = '<!-- Please each of these divs where you want our widgets to appear in the body of your website -->';
        for(let i = 0 ; i < widgets.length ; i++){
            out += `
<!-- Cognativex ${widgets[i].type} widget div ${widgets[i].type === 'popup' ? "(You can insert this div anywhere in your body and it will appear as a popup in the bottom left corner) " : ''}-->
<div class="cognativex-widget" id="cognativex-widget-${widgets[i].id}"></div>\n`;
        }
        return out;
    }


    function getWordpressIntegration(){
        const ids = JSON.stringify(widgets.map(w=>w.id));
        let types = '';
        for(let i = 0 ; i < widgets.length ; i++){
            types += `# ${widgets[i].type} widget: ${widgets[i].id}\n`;
        }

        return `
Domain: ${domain}
WidgetIds: ${ids.substring(1, ids.length-1)}

${types}

Link to the CognativeX wordpress plugin: https://wordpress.org/plugins/cognativex
`
    }

    const downloadTxtFile = (text, type) => {
        const element = document.createElement("a");
        const file = new Blob([text],    
                    {type: 'text/plain;charset=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = domain.split('.')[0]+"_"+type+".txt";
        document.body.appendChild(element);
        element.click();
        element.remove();
    }

    async function downloadImageFile(src, name) {
        const imageURL = URL.createObjectURL(src)
        const link = document.createElement('a')
        link.href = imageURL
        link.download = name;
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    function getMetadata(){
        return `<!-- Meta Data -->
<script id="cognativex-metadata" type="text/cognativex">
{
    "type": "article",
    "postid": "123",
    "title": "My Article Title",
    "url": "http://${domain}/article_url",
    "keywords": "keyword 1, keyword 2, keyword 3",
    "thumbnail": "http://${domain}/thumbnail.jpg",
    "video_duration": 384,
    "word_count": 324,
    "lang": "en",
    "published_time": "2008-09-15T15:53:00+05:00",
    "description": "Article text without HTML",
    "author": "My Author",
    "classes":[{"key":"class1","mapping":"category","value":"Business"},{"key":"class2","mapping":"sub-category","value":"FinTech"},{"key":"class3","mapping":"brand","value":"Revolut, Apple, Amazon"}],
}
</script>
<!-- The metadata script is responsible for collecting metadata information about the content. The variables collected are properties that give our Artificial Intelligence the ability to identify, understand, and present the articles back to your users. The below script is an example implementation.
Check cognativex.com/integration for more details -->
`;
    }


    return (
        <>
        
                <div className={"reactive-tabs publisher-form-tabs"}>
                  <div
                    className={`reactive-tab ${
                      activeTab === "wordpress" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("wordpress")}
                  >
                    <i className={"fa fa-list"}></i> &nbsp; <p>Wordpress Integration</p>
                  </div>
                  <div
                    className={`reactive-tab ${
                      activeTab === "manual" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("manual")}
                  >
                    <i className={"fa fa-gear"}></i> &nbsp; <p>Manual Integration</p>
                  </div>
                </div>
 
                <div
                className={`${
                    activeTab === "wordpress"
                    ? "pubisher-widgetScripts-wordpress"
                    : "d-none"
                }`}
                >
                    <div>
                        <label>For wordpress integration:</label>
                        <div className=''>

                            <SyntaxHighlighter
                                language="markdown"
                                style={a11yDark}
                                wrapLines={true}
                                customStyle={{
                                    marginBottom: 0,
                                    fontSize: '13px',
                                }}
                            >
                                {getWordpressIntegration()}
                            </SyntaxHighlighter>
                            <div className='display-flex' style={{gap: '1rem', marginTop: '.4rem'}}>
                                <CopyToClipboard text={getWordpressIntegration()} onCopy={() => alert("Copied successfully")}>
                                    <a href={"#"} className={"cx-js-script-copy"} onClick={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <i className={"fa fa-clone"}/> Copy
                                    </a>
                                </CopyToClipboard>
                                <span style={{cursor: 'pointer', color: '#9ad720'}} className={"cx-js-script-copy"} onClick={() => {
                                        downloadTxtFile(getWordpressIntegration(), "wordpress-integration")
                                }}>
                                    <i className={"fa fa-download"}/> Download File
                                </span>
                            </div>
                            <br/>
                            <label>Screenshot Example:</label>
                            <img src="https://i.ibb.co/dMCxQyq/worperess-Plugin-Helper.png" alt="plugin_screenshot"/>
                        </div>
                    </div>
                
                </div>

                <div
                className={`${
                    activeTab === "manual"
                    ? ""
                    : "d-none"
                }`}
                >
                    <div>
                        <label>Header Scripts (for normal integration):</label>
                        <SyntaxHighlighter
                            language="html"
                            style={a11yDark}
                            wrapLines={true}
                            customStyle={{
                                marginBottom: 0,
                                fontSize: '13px',
                            }}
                        >
                            {getScript()}
                        </SyntaxHighlighter>
                        <div className='display-flex' style={{gap: '1rem', marginTop: '.4rem'}}>
                            <CopyToClipboard text={getScript()} onCopy={() => alert("Copied successfully")}>
                                <a href={"#"} className={"cx-js-script-copy"} onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                    <i className={"fa fa-clone"}/> Copy Scripts
                                </a>
                            </CopyToClipboard>
                            <span style={{cursor: 'pointer', color: '#9ad720'}} className={"cx-js-script-copy"} onClick={() => {
                                    downloadTxtFile(getScript(), "header-script")
                            }}>
                                <i className={"fa fa-download"}/> Download File
                            </span>
                        </div>
                        <br />
                        <label>Body Divs (for normal integration):</label>
                        <SyntaxHighlighter
                            language="html"
                            style={a11yDark}
                            wrapLines={true}
                            customStyle={{
                                marginBottom: 0,
                                fontSize: '13px',
                            }}
                        >
                            {getDivs()}
                        </SyntaxHighlighter>
                        <div className='display-flex' style={{gap: '1rem', marginTop: '.4rem'}}>
                            <CopyToClipboard text={getDivs()} onCopy={() => alert("Copied successfully")}>
                                <a href={"#"} className={"cx-js-script-copy"} onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                    <i className={"fa fa-clone"}/> Copy Divs
                                </a>
                            </CopyToClipboard>
                            <span style={{cursor: 'pointer', color: '#9ad720'}} className={"cx-js-script-copy"} onClick={() => {
                                    downloadTxtFile(getDivs(), "body-divs")
                            }}>
                                <i className={"fa fa-download"}/> Download File
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div>
                        <label>JSON Metadata:</label>
                        <SyntaxHighlighter
                            language="html"
                            style={a11yDark}
                            wrapLines={true}
                            customStyle={{
                                marginBottom: 0,
                                fontSize: '13px',
                            }}
                        >
                            {getMetadata()}
                        </SyntaxHighlighter>
                        <div className='display-flex' style={{gap: '1rem', marginTop: '.4rem'}}>
                            <CopyToClipboard text={getMetadata()} onCopy={() => alert("Copied successfully")}>
                                <a href={"#"} className={"cx-js-script-copy"} onClick={(e) => {
                                    e.preventDefault();
                                }}>
                                    <i className={"fa fa-clone"}/> Copy Metada
                                </a>
                            </CopyToClipboard>
                            <span style={{cursor: 'pointer', color: '#9ad720'}} className={"cx-js-script-copy"} onClick={() => {
                                    downloadTxtFile(getMetadata(), "metadata")
                            }}>
                                <i className={"fa fa-download"}/> Download File
                            </span>
                        </div>
                    </div>
                </div>







        </>
    );
}

export default WidgetScripts;
