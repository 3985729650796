import React, { Component } from "react";
import { campaignPerformanceActions, drawerActions } from "../../../actions";
import { connect } from "react-redux";
import {Card, CardBody} from "../../../components/Card/card";
import LineChart from "./reportLineChart";
import Map from "./reportMap";
import BarChart from "./reportBarChart";
import mainHelper from "../../../helpers/main";

const helper = new mainHelper();

class DrawerReport extends Component {

    /*
    * componentDidMount
    */
    componentDidMount() {
        const arr = ['performance', 'location', 'devices'];
        const defaultTab = (arr.indexOf(this.props.activeTab) > -1)? this.props.activeTab : 'performance';
        const defaultSubtab = this.props.activeSubtab || 'clicks';
        this.props.setReportTab(defaultTab);
        this.props.setReportSubtab(defaultSubtab);
        this.props.fetchTypeTotals();
    }

    renderReportTabs = () => {
        return (
            <div className={"reactive-tabs"}>
                <div
                    id={"performance-tab"}
                    className={`${(this.props.activeTab === 'performance') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.setReportTab('performance') }}
                >
                    Performance
                </div>
                <div
                    id={"location-tab"}
                    className={`${(this.props.activeTab === 'location') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.setReportTab('location') }}
                >
                    Location
                </div>
                <div
                    id={"devices-tab"}
                    className={`${(this.props.activeTab === 'devices') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.setReportTab('devices') }}
                >
                    Devices
                </div>
            </div>
        );
    }

    renderReportSubTabs = () => {
        return  (
            <div className={'reactive-subtabs'}>
                <div
                    id="clicks-subtab"
                    className={`${(this.props.activeSubtab === 'clicks') ? 'active' : ''} reactive-subtab`}
                    onClick={e => { this.props.setReportSubtab('clicks') }}
                >
                    Clicks <span className={'reactive-subtab-summary'}>{this.props.performanceTotals && helper.formatNumber(this.props.performanceTotals['clicksTotal'])}</span>
                </div>
                <div
                    id="impressions-subtab"
                    className={`${(this.props.activeSubtab === 'impressions') ? 'active' : ''} reactive-subtab`}
                    onClick={e => { this.props.setReportSubtab('impressions') }}
                >
                    Impressions <span className={'reactive-subtab-summary'}>{this.props.performanceTotals && this.props.performanceTotals['impressionsTotal'] && helper.formatNumber(this.props.performanceTotals['impressionsTotal'])}</span>
                </div>
                <div
                    id="amountspent-subtab"
                    className={`${(this.props.activeSubtab === 'amountSpent') ? 'active' : ''} reactive-subtab`}
                    onClick={e => { this.props.setReportSubtab('amountSpent') }}
                >
                    Amount Spent <span className={'reactive-subtab-summary'}>${this.props.performanceTotals && this.props.performanceTotals['amountSpentTotal'] && this.props.performanceTotals['amountSpentTotal'].toLocaleString()}</span>
                </div>
                <div
                    id="ctr-subtab"
                    className={`${(this.props.activeSubtab === 'ctr') ? 'active' : ''} reactive-subtab`}
                    onClick={e => { this.props.setReportSubtab('ctr') }}
                >
                    CTR <span className={'reactive-subtab-summary'}>{this.props.performanceTotals && this.props.performanceTotals['ctrAverage'] && this.props.performanceTotals["ctrAverage"].toFixed(2).toLocaleString()}%</span>
                </div>
                <div
                    id="views-subtab"
                    className={`${(this.props.activeSubtab === 'views') ? 'active' : ''} reactive-subtab`}
                    onClick={e => { this.props.setReportSubtab('views') }}
                >
                    Views <span className={'reactive-subtab-summary'}>{this.props.performanceTotals && this.props.performanceTotals['viewsTotal'] && helper.formatNumber(this.props.performanceTotals['viewsTotal'])}</span>
                </div>
            </div>
        );
    }

    renderGraphType = () => {
        switch(this.props.activeTab){
            default:
                return false;
            case 'performance':
                return <LineChart />;
            case 'location':
                return <Map />;
            case 'devices':
                return <BarChart />;
        }
    }

    renderReportBody = () =>{
        return (
            <div className={"cx-campaign-performance-body"}>
                <div className={"cx-campaign-performance-subtabs"}>
                    {this.renderReportSubTabs()}
                </div>
                <div className={"cx-campaign-performance-subtabs-container"}>
                    {this.renderGraphType()}
                </div>
            </div>
        );
    }

    render() {
        return (
           <div className={"cx-drawer-inner-tabs cx-campaign-performance"}>
               <Card>
                   <CardBody>
                       {this.renderReportTabs()}
                       {this.renderReportBody()}
                   </CardBody>
               </Card>
           </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        activeTab: (state.slidedrawer && state.slidedrawer.tab) || null,
        activeSubtab: (state.slidedrawer && state.slidedrawer.subtab) || null,
        performanceTotals: (state.campaignPerformance && state.campaignPerformance.performanceTotals)? state.campaignPerformance.performanceTotals: {},
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setReportTab: (tab) => dispatch(drawerActions.setReportTab(tab)),
        setReportSubtab: (subtab) => dispatch(drawerActions.setReportSubtab(subtab)),
        fetchTypeTotals: () => dispatch(campaignPerformanceActions.fetchTypeTotals())
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(DrawerReport);