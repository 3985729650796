import {
    SET_AUDIT_ACTION_TYPE,
    FETCH_AUDITS,
    FETCH_PERMISSIONS,
    FETCH_AUDIT_ITEM,
    UPDATE_AUDIT,
    UPDATE_ERR_AUDIT,
    CREATE_AUDIT,
    CREATE_ERR_AUDIT,
    DELETE_AUDIT,
    DELETE_ERR_AUDIT

} from "../constants/appConstant";
import api from '../api/audit';

/*
 * action creators
 */
export const setRoleActionType   = (data) => ({ type: SET_AUDIT_ACTION_TYPE, payload: data});
const getListEvent = (data) => ({ type: FETCH_AUDITS, payload: data});
const getPermissionListEvent = (data) => ({ type: FETCH_PERMISSIONS, payload: data});


export const getItemEvent = (data) => ({ type: FETCH_AUDIT_ITEM, payload: data });
export const updateEvent = (updated) => ({ type: UPDATE_AUDIT, payload: updated });
export const updateErrEvent = (err) => ({ type: UPDATE_ERR_AUDIT, payload: err });
export const createEvent = (created) => ({ type: CREATE_AUDIT, payload: created });
export const createErrEvent = (err) => ({ type: CREATE_ERR_AUDIT, payload: err });
export const deleteEvent = (deleted) => ({ type: DELETE_AUDIT, payload: deleted });
export const deleteErrEvent = (err) => ({ type: DELETE_ERR_AUDIT, payload: err });


export const getList = (size,page) => {
    return (dispatch) => {
        return api.getList(size,page)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getListEvent(res))
            })
            .catch(error => {
                dispatch(getListEvent(null))
                throw (error);
            });
    };
};

export const getItem = (data) => {
    return (dispatch) => {
        return api.getItem(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getItemEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const update = (data) => {
    return (dispatch) => {
        return api.updateItem(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(updateEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(updateErrEvent('Error'));
                throw (error);
            });
    };
}

export const create = (data) => {
    return (dispatch) => {
        return api.create(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(createEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(createErrEvent('Error'));
                throw (error);
            });
    };
}

export const deleteItem = (data) => {
    return (dispatch) => {
        return api.delete(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(deleteEvent(res))
                dispatch(deleteErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(deleteErrEvent('Error'));
                throw (error);
            });
    };
}
