import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useEmailNewsletterStore from "../emailNewsletterStore";
import {
    readEmailNewsletter,
    createEmailNewsletter,
    updateEmailNewsletter,
    refetchList,
    uploadImage,
} from "../emailNewsletterAPI";
import CxLoader from "../../components/cxLoader";
import { useForm, useWatch } from "react-hook-form";
import {
    CXInputField,
    CXSelectField,
    CXCheckbox,
    CXRadio,
    CXConfirm,
} from "CXComponents";
import { deleteEmailNewsletter } from "../emailNewsletterAPI";
import { Row, Col } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CCard, CRow, CCardBody, CCardTitle, CCardText } from "@coreui/react";

const EmailNewsletterForm = ({ data = {}, itemToLoad, publisherList }) => {
    const qc = useQueryClient();

    const actionType = useEmailNewsletterStore((state) => state.actionType);
    const setIsOpen = useEmailNewsletterStore((state) => state.setIsOpen);
    const [isDeleteEmailNewsletterOpen, setIsDeleteEmailNewsletterOpen] =
        useState(false);

    const deleteEmailNewsletterQuery = useMutation(() => {
        return deleteEmailNewsletter(itemToLoad);
    });

    const confirmEmailNewsletterDelete = () => {
        setIsDeleteEmailNewsletterOpen(true);
    };

    const [activeTab, setActiveTab] = useState("Configuration");

    const imageInputRef = useRef("imageInputRef");

    const schema = yup
        .object({
            mainUrl: yup.string().url().required(),
            headingText: yup.string().required(),
            descriptionText: yup.string().required(),
            contactUsEmail: yup.string().email().required(),
            title: yup.string().required(),
            scheduledHour: yup
                .number()
                .typeError("Please specify a number between 0 and 24")
                .min(0)
                .max(24)
                .required(),
        })
        .required();

    const getDefaultValue = (data) => {
        if (actionType === "create") return data;
        const mapped = { ...data };
        const scheduleJson = JSON.parse(mapped.schedule);
        mapped.scheduleForm = scheduleJson.type;
        mapped.scheduledHour = parseInt(scheduleJson.hour);
        mapped.scheduledDayInWeek = scheduleJson.day;
        mapped.scheduledDayInMonth = scheduleJson.day;
        return mapped;
    };
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValue(data),
    });

    const create = useMutation((data) => {
        return createEmailNewsletter(data);
    });
    const update = useMutation((data) => {
        return updateEmailNewsletter(data);
    });
    const uplaodImageQuery = useMutation((data) => {
        return uploadImage(data);
    });

    const scheduleFormRadioButton = useWatch({
        control,
        name: "scheduleForm",
    });

    const daysDropDownData = [
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" },
    ];

    const triggersType = [
        { label: "Number of Articles Engaged with", value: "numberOfArticlesEngagedWith" },
        { label: "Location arrived from", value: "locationArrivedFrom" },
        { label: "Referral arrived from", value: "referralArrivedFrom" },
        { label: "Article Scroll Depth", value: "articleScrollDepth" },
        { label:"Device accessed from", value: "deviceAccessedFrom"},
        { label: "Number of Visits", value: "numberOfVisits" },

    ]

    const onSubmit = async (formValues) => {
        const mapped = { ...formValues };
        mapped.schedule = JSON.stringify({
            type: mapped.scheduleForm,
            day:
                mapped.scheduleForm === "weekly"
                    ? mapped.scheduledDayInWeek + ""
                    : mapped.scheduleForm === "monthly"
                    ? mapped.scheduledDayInMonth + ""
                    : 0,
            hour: mapped.scheduledHour + "",
        });

        mapped.triggerValue = formValues.triggerValue;
        mapped.triggerType = formValues.triggerType.value;

        if (actionType === "edit") {
            await update.mutateAsync(mapped);
            qc.invalidateQueries(["newsletter-service-read", itemToLoad]);
        } else {
            mapped.publisherId = formValues.publisherId.value;
            await create.mutateAsync(mapped);
        }
        refetchList(qc);
        setIsOpen(false);
    };

    const cancelButton = () => {
        setIsOpen(false);
    };

    const doesTabIncludeErrors = (tabName) => {
        switch (tabName) {
            case "Configuration":
                return (
                    errors.publisherId ||
                    errors.mainUrl ||
                    errors.contactUsEmail ||
                    errors.descriptionText ||
                    errors.headingText
                );
            case "Schedule":
                return (
                    errors.scheduledHour ||
                    (scheduleFormRadioButton === "weekly" &&
                        errors.scheduledDayInWeek) ||
                    (scheduleFormRadioButton === "monthly" &&
                        errors.scheduledDayInMonth)
                );
            default:
                return false;
        }
    };

    const renderConfigForm = () => {
        return (
            <>
                {actionType === "create" ? (
                    <>
                        <CXSelectField
                            {...{
                                control,
                                label: "Publisher *",
                                required: true,
                                name: "publisherId",
                                options: publisherList.map((pub) => ({
                                    value: pub.id,
                                    label: pub.domain,
                                })),
                                defaultDisplay: "Select Publisher",
                            }}
                        />
                        <br />
                    </>
                ) : (
                    ""
                )}

                <CXInputField
                    label="Main URL"
                    name="mainUrl"
                    register={register}
                    errorMsg={errors?.mainUrl?.message}
                />
                <br />
                <CXInputField
                    label="Title"
                    name="title"
                    register={register}
                    errorMsg={errors?.title?.message}
                />
                <br />

                <div
                    className="image-upload-newsletter-container"
                    style={{ alignItems: "center" }}
                >
                    <input
                        type="file"
                        ref={imageInputRef}
                        onChange={async (e) => {
                            e.persist();
                            const upload = async (data) => {
                                const imageUrl =
                                    await uplaodImageQuery.mutateAsync(data);
                                setValue("imageUrl", imageUrl);
                                e.target.focus();
                            };
                            await upload(e.target.files[0]);
                        }}
                        accept=".gif,.jpg,.jpeg,.png,.gif,.webp, .bmp, .tiff, .ico"
                        hidden
                    />
                    <div className="image-upload-newsletter">
                        {uplaodImageQuery.isLoading ? (
                            <CxLoader inPlace="true" />
                        ) : (
                            <>
                                <button
                                    className="cx-btn"
                                    type="button"
                                    onClick={() => {
                                        imageInputRef.current.click();
                                    }}
                                >
                                    {data?.imageUrl || uplaodImageQuery.data
                                        ? "Change "
                                        : "Upload "}
                                    Publisher Logo
                                </button>
                                <div
                                    className="flex"
                                    style={{
                                        height: "150px",
                                        justifyContent: "center",
                                    }}
                                >
                                    {uplaodImageQuery.data || data?.imageUrl ? (
                                        <img
                                            style={{ height: "100%" }}
                                            src={
                                                uplaodImageQuery.data ||
                                                data?.imageUrl
                                            }
                                            alt="uplaoded"
                                        />
                                    ) : (
                                        <i className="fa fa-image"></i>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    {errors?.imageUrl?.message ? (
                        <span className="errorMsg">
                            {errors?.imageUrl?.message}
                        </span>
                    ) : (
                        ""
                    )}
                </div>

                <br />
                <CXInputField
                    label="Heading Text"
                    name="headingText"
                    register={register}
                    errorMsg={errors?.headingText?.message}
                />
                <br />
                <CXInputField
                    label="Description Text"
                    name="descriptionText"
                    register={register}
                    errorMsg={errors?.descriptionText?.message}
                />
                <br />
                <CXInputField
                    label="Contact Us Email"
                    name="contactUsEmail"
                    register={register}
                    errorMsg={errors?.contactUsEmail?.message}
                />
                <br />
                <CXSelectField
                    {...{
                        control,
                        label: "Trigger Type",
                        required: true,
                        name: "triggerType",
                        options: triggersType.map((trigger) => ({
                            value: trigger.value,
                            label: trigger.label,
                        })),
                        defaultDisplay: "Select Trigger Type",
                    }}
                />
                <br />
                <CXInputField
                    label="Trigger Value"
                    name="triggerValue"
                    register={register}
                    errorMsg={errors?.contactUsEmail?.message}
                />

                <CXCheckbox
                    label="Show Email Collector"
                    name="isWidgetActive"
                    register={register}
                    errorMsg={errors?.isWidgetActive?.message}
                />

                <CXCheckbox
                    label="Active Newsletter Email"
                    name="isActive"
                    register={register}
                    errorMsg={errors?.isActive?.message}
                />

                <br />
                <div className={"cx-action-buttons"}>
                    <button
                        className={
                            "btn cx-focus-btn" +
                            (uplaodImageQuery.isLoading ? "disabled" : "")
                        }
                    >
                        Save
                    </button>
                    {create.isLoading || update.isLoading ? (
                        <CxLoader inPlace={true} />
                    ) : (
                        ""
                    )}
                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                    {actionType === "edit" ? (
                        <button
                            type="button"
                            className="cx-btn delete-newsletter-btn"
                            onClick={confirmEmailNewsletterDelete}
                        >
                            Delete
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    };

    const timeSchedule = () => {
        return (
            <>
                <CXRadio
                    label="Send Newsletter:"
                    name="scheduleForm"
                    className="transform-down-3"
                    options={[
                        { label: "Daily", value: "daily" },
                        { label: "Weekly", value: "weekly" },
                        { label: "Monthly", value: "monthly" },
                    ]}
                    register={register}
                    errorMsg={errors?.readPostIdFromUrl?.message}
                />
                <br />
                <div style={{ display: "flex", gap: "10px" }}>
                    {scheduleFormRadioButton === "weekly" && (
                        <CCard className="coreui-cx-card">
                            <CCardBody>
                                <h4>Week Days</h4>
                                <CCardText style={{ opacity: 0.5 }}>
                                    Choose in what day of the week you want to
                                    send the newsletter:
                                </CCardText>
                                <CXRadio
                                    name="scheduledDayInWeek"
                                    className="weekdays-chips-radio"
                                    options={daysDropDownData}
                                    register={register}
                                    errorMsg={
                                        errors?.scheduledDayInWeek?.message
                                    }
                                />
                            </CCardBody>
                        </CCard>
                    )}
                    {scheduleFormRadioButton === "monthly" && (
                        <CCard className="coreui-cx-card">
                            <CCardBody>
                                <h4>Month Days</h4>
                                <CCardText style={{ opacity: 0.5 }}>
                                    Choose in what day of the month you want to
                                    send the newsletter:
                                </CCardText>
                                <CXInputField
                                    name="scheduledDayInMonth"
                                    placeholder={"From 1 to 31 "}
                                    register={register}
                                    errorMsg={
                                        errors?.scheduledDayInMonth?.message
                                    }
                                    type="number"
                                />
                            </CCardBody>
                        </CCard>
                    )}
                    <CCard className="coreui-cx-card">
                        <CCardBody>
                            <h4>Time (UTC)</h4>
                            <CCardText style={{ opacity: 0.5 }}>
                                Choose at what hour you want to send the
                                newsletter:
                            </CCardText>
                            <CXInputField
                                name="scheduledHour"
                                placeholder="Number From 0 to 23 (0 being 12 midnight, and 23 being 11pm)"
                                register={register}
                                errorMsg={errors?.scheduledHour?.message}
                                type="number"
                            />
                            <br />
                        </CCardBody>
                    </CCard>
                </div>
                <div className={"cx-action-buttons"}>
                    <button
                        className={
                            "btn cx-focus-btn" +
                            (uplaodImageQuery.isLoading ? "disabled" : "")
                        }
                    >
                        Save
                    </button>
                    {create.isLoading || update.isLoading ? (
                        <CxLoader inPlace={true} />
                    ) : (
                        ""
                    )}
                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                    {actionType === "edit" ? (
                        <button
                            type="button"
                            className="cx-btn delete-newsletter-btn"
                            onClick={confirmEmailNewsletterDelete}
                        >
                            Delete
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    };

    return (
        <div>
            <Row className={"mx-0 mb-4"}>
                <Col className={"px-0"}>
                    <div className={"reactive-tabs publisher-form-tabs"}>
                        <div
                            className={`reactive-tab ${
                                activeTab === "Configuration" ? "active" : ""
                            } ${
                                doesTabIncludeErrors("Configuration")
                                    ? "reactive-tab__error"
                                    : ""
                            }`}
                            onClick={() => setActiveTab("Configuration")}
                        >
                            <i className={"fa fa-gear"}></i> &nbsp;{" "}
                            <p>Configuration</p>
                        </div>
                        <div
                            className={`reactive-tab ${
                                activeTab === "Schedule" ? "active" : ""
                            } ${
                                doesTabIncludeErrors("Schedule")
                                    ? "reactive-tab__error"
                                    : ""
                            }`}
                            onClick={() => setActiveTab("Schedule")}
                        >
                            <i className={"fa fa-clock"}></i> &nbsp;{" "}
                            <p>Schedule</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className={"mx-0"}>
                <Col className={"px-0"}>
                    <form
                        className={"publisher-form-container"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className={`${
                                activeTab === "Configuration" ? "" : "d-none"
                            }`}
                        >
                            {renderConfigForm()}
                        </div>
                        <div
                            className={`${
                                activeTab === "Schedule" ? "" : "d-none"
                            }`}
                        >
                            {timeSchedule()}
                        </div>
                    </form>
                </Col>
            </Row>

            {isDeleteEmailNewsletterOpen && (
                <CXConfirm
                    {...{
                        title: "Delete Email Newsletter",
                        subtitle:
                            "Are you sure you want to permanently delete this email newsletter service for this publisher?",
                        confirmAction: async () => {
                            await deleteEmailNewsletterQuery.mutateAsync([
                                itemToLoad,
                            ]);
                            setIsDeleteEmailNewsletterOpen(false);
                            setIsOpen(false);
                            await qc.invalidateQueries([
                                "email-newsletter-list",
                            ]);
                        },
                        cancelAction: () => {
                            setIsDeleteEmailNewsletterOpen(false);
                        },
                        loadingState: deleteEmailNewsletterQuery.isLoading,
                    }}
                />
            )}
        </div>
    );
};

const LoadData = (props) => {
    const itemToLoad = useEmailNewsletterStore((state) => state.itemToLoad);
    const actionType = useEmailNewsletterStore((state) => state.actionType);
    const readEmailNewsletterQuery = useQuery(
        ["newsletter-service-read", itemToLoad],
        () => {
            if (actionType === "edit") return readEmailNewsletter(itemToLoad);
        },
        { refetchOnWindowFocus: false }
    );

    return (
        <>
            {readEmailNewsletterQuery.isLoading ? (
                <CxLoader minHeight="80vh" />
            ) : actionType === "edit" ? (
                <EmailNewsletterForm
                    publisherList={props.publisherList}
                    data={readEmailNewsletterQuery.data}
                    itemToLoad={itemToLoad}
                />
            ) : (
                <EmailNewsletterForm publisherList={props.publisherList} />
            )}
        </>
    );
};

export default LoadData;
