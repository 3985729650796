import React from 'react';
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import { widgetPerformanceActions } from "../../../actions";
import { connect } from "react-redux";
import { CardTitle } from "reactstrap";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data.svg"

const NoData = () => {
  return <img className="cx-no-data-svg" style={{width:'300px'}} src={noData}/>
};


let helpers = new mainHelper();

class TopWidgetsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.cols = [
            {
                Header: "ID",
                accessor: "widget_id",
                Cell: ({ original }) => {
                    return <span>{original.widget_id}</span>;
                }
            },
            {
                Header: "Name",
                accessor: "widget_title",
                Cell: ({ original }) => {
                    return <span>{original.widget_title}</span>;
                }
            },

            {
                Header: "Impressions",
                accessor: "impressions",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.impressions, 1000000)}</span>
                }
            },
            {
                Header: "Views",
                accessor: "views",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.views, 1000000)}</span>
                }
            },
            {
                Header: "Clicks",
                accessor: "clicks",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.clicks, 1000000)}</span>
                }
            },
            {
                Header: "CTR",
                accessor: "ctr",
                Cell: ({ original }) => {
                    return <span className="cx-number">{original.ctr && original.ctr.toFixed(2)}%</span>
                }
            }
            // ,
            // {
            //     Header: "Earnings",
            //     accessor: "earnings",
            //     Cell: ({ original }) => {
            //         return <span className="cx-number">${original.earnings}</span>
            //     }
            // }
        ];
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
            this.fetchData();
        }
    }
    componentWillMount = () => {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.props.fetchTopWidgets(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }

    render() {
        return (
            <div className={'cx-widget-performance-top-posts'}>
                {this.state.loader ? <CxLoader /> : ''}
                <div className={"px-2 py-3 cx-card-title d-flex justify-content-between"}>
                    <CardTitle className={"my-0 cx-white-color"}>Top Widgets</CardTitle>
                    {/*<Tooltip text={"This card displays the top widgets by CogantiveX with their contribution."}></Tooltip>*/}
                </div>
                <ReactTable
                    noDataText={'No Widgets'}
                    NoDataComponent={NoData}
                    sortable={false}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.topWidgetsData}
                    resizable={false}
                    columns={this.cols}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        topWidgetsData: (state.widgetPerformance && state.widgetPerformance.topWidgetsData) ? state.widgetPerformance.topWidgetsData : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTopWidgets: (filter, dateFilter) => dispatch(widgetPerformanceActions.fetchTopWidgets(filter, dateFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopWidgetsTable);
