import axios from 'axios';
import querystring from 'querystring';
import mainHelper from '../helpers/main';
import apiUrl from '../helpers/global';
import _ from 'lodash';

let helper = new mainHelper();

const client = (token = null) => {

  let addAuth = (defaultOptions, options) => {
    let config = _.cloneDeep(options);
    // let config = JSON.parse(JSON.stringify(options));
    if (config && config.headers) {
      config.headers.Authorization = JSON.parse(JSON.stringify(defaultOptions)).headers.Authorization;
    } else {
      config['headers'] = defaultOptions.headers;
    }
    return config;
  }

  const defaultOptions = {
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    },
  };

  return {
    get: (url, options = {}) => { return axios.get(url, addAuth(defaultOptions, options)) },
    post: (url, body, options = {}) => {
      url = url.replace('?organization', '');
      body = (body && body.data)? querystring.stringify({ organization: helper.getOrganization(), data: body && body.data }): querystring.stringify({ organization: helper.getOrganization()});
      return axios.post(url, body, addAuth(defaultOptions, options))
    },    
    upload: (url, body, options = {}) => {
      //body = querystring.stringify({ organization: helper.getOrganization(), data: body && body.data });
      return axios.post(url, body, addAuth(defaultOptions, options))
    },
    put: (url, data, options = {}) => { return axios.put(url, data, addAuth(defaultOptions, options)) },
    delete: (url, options = {}) => { return axios.delete(url, addAuth(defaultOptions, options)) },
  };
};

/* Helper Class
* To request campaign details and save other campaign details.
* */
class auth {

  constructor(props) {
    this.baseUrl = apiUrl;
    this.token = helper.getAccessToken();
    this.refresh_token = helper.getRefreshToken();
    this.request = client(this.token);

    this.roles = [{ "label": "advertiser", "value": "advertiser" }, { "label": "publisher", "value": "publisher" }, { "label": "mediaplanner", "value": "media-planner" }];

    if (helper.checkExpiry(helper.getTokenExpiredAt())) {
      this.refreshToken();
    } else {
      this.addAccessTokenToHeader();
    }

    // axios.interceptors.response.use((response) => { return response }, (err) => {
    //   if (err && err.response && err.response.status === 400){
    //     return Promise.reject(err);
    //   }
    //   if (err && err.response && err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest) {
    //     console.log("error interceptors:")
    //     console.error(err);
    //     return new Promise((resolve, reject) => {
    //       this.refreshToken(() => {
    //         err.response.config.__isRetryRequest = true;
    //         err.response.config.headers.Authorization = `Brearer ${this.token}`;
    //         axios(err.response.config).then(resolve, reject);
    //       }, () => {
    //         reject();
    //       });
    //     });
    //   }
    //   return Promise.reject(err);
    // });
  } 

  refreshTokenHelper = () => {
    if (helper.checkExpiry(helper.getTokenExpiredAt())) {
      this.refreshToken();
    }
  }

  addAccessTokenToHeader = () => {
    if (helper.getAccessToken()) {
      this.request = client(helper.getAccessToken());
    }
  }

  // refreshToken = async (failedRequest) => {
  refreshToken = async (success = () => { }, error = () => { }, final = () => { }) => {
    let data = querystring.stringify({
      data: JSON.stringify({
        refreshToken: helper.getRefreshToken(),
        organization: helper.getOrganizationId()
      })
    });

    return await axios.post(this.baseUrl + `/refresh-token?v=` + (new Date().getTime()), data).then((tokenRefreshResponse) => {
      this.resetAuth(tokenRefreshResponse.data);
      this.addAccessTokenToHeader();
      (success)();
    }).catch((err) => {
      (error)(err);
      this.logout('/auth/login');
    }).finally(() => {
      (final)();
    });
  }

  logout = (redirect) => {
    window.localStorage.removeItem('user');
    helper.mainInfo.reset();
    this.request = null;
    if (redirect) window.location.href = redirect;
  }

  /* Helper method
  *  Login
  * */
  login = async (data) => {
    return await axios.post(this.baseUrl + `/auth`, querystring.stringify({ data: JSON.stringify(data) }));
  }

  /* Helper method
  *  Login
  * */
  register = async (data) => {
    return this.request.post(this.baseUrl + `/register`, querystring.stringify({ data: JSON.stringify(data) }));
  }

  changeUserPassword = async (data) => {
    return this.request.post(this.baseUrl + `/change-password`, { data: JSON.stringify(data) });
  }

  resetAuth = (auth) => {
    console.log('resetting organization');
    if (auth && auth.accessToken) {
      localStorage.user = JSON.stringify(auth);
      var organizations = auth.organizations;


      // if (!localStorage.organization && organizations && organizations.length > 0){
      //   // localStorage.organization = JSON.stringify(organizations[0]);
      //
      //   const cx = organizations.filter(o=>o.name.includes('cognativex')) || [];
      //   if(cx && cx.length >0)
      //   localStorage.organization = JSON.stringify(cx[0]);
      // }

      if (true || (!localStorage.organization && organizations && organizations.length > 0))
        localStorage.organization = JSON.stringify(organizations.find(o => (o.name === 'demo.cognativex.com')) || organizations[0]);

      this.addAccessTokenToHeader();
    }
  }

}

export default auth;
