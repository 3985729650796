import React, {useState} from 'react';
import useDigestReportSettingStore from "../DigestReportSettingStore";
import {deleteDigestReportSetting} from "../DigestReportSettingAPI";
import {useMutation, useQueryClient} from "react-query";
import {CXConfirm} from "CXComponents";

const DigestReportingCard = ({title, status, id, schedule}) => {
    const [isDeletedDigestReportSetting, setIsDeletedDigestReportSettingOpen] = useState(false);
    const setItemToLoad = useDigestReportSettingStore((state) => state.setItemToLoad);
    const setIsOpen = useDigestReportSettingStore((state) => state.setIsOpen);
    const setActionType = useDigestReportSettingStore((state) => state.setActionType);

    const deleteDigestReportCard = useMutation(() => {
        return deleteDigestReportSetting(id);
    })

    const confirmDigestReportSettingDelete = () => {
        setIsDeletedDigestReportSettingOpen(true);
    }

    const qc = useQueryClient();
    const openDigestReportSettingForm = (e) => {
        setActionType("edit");
        setIsOpen(true);
        setItemToLoad(id);
    }

    const daysOfWeek = new Map(Object.entries({
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
    }));

    return (
        <>
            <div className="segment-card-container">
                <div className="segment-card-container__topRight" onClick={confirmDigestReportSettingDelete}>
                    <i title='Status' className={`segment-card-container__topRight__status ${status && ('cx-status-' + status)}`}></i>
                    <i className={`segment-card-container__topRight__delete-btn fa fa-trash`}></i>
                </div>
                <div onClick={openDigestReportSettingForm}>
                    <div className="segment-card-container__title">
                        {title}
                    </div>
                    <br />
                    <div className="segment-card-container__dimensions" >
                        {(schedule.type === "daily" &&
                            <h5>The email report will be sent daily at {schedule.hour} </h5>
                        )}
                        {(schedule.type === "weekly" &&
                            <h5>The email report will be sent each {daysOfWeek.get(schedule.day)}</h5>
                        )}
                        {(schedule.type === "monthly" &&
                            <h5>the email report will be sent in the {schedule.day} th of the month</h5>
                        )}
                    </div>
                </div>
            </div>
            {
                isDeletedDigestReportSetting &&
                <CXConfirm
                    {...{
                        title: "Delete Digest Report Setting",
                        subtitle: "Are you sure you want to permanently delete this Digest Report Setting for this publisher?",
                        confirmAction: async ()=>{
                            await deleteDigestReportCard.mutateAsync([id]);
                            setIsDeletedDigestReportSettingOpen(false);
                            await qc.invalidateQueries(["digest-report-setting-list"]);
                        },
                        cancelAction: ()=>{
                            setIsDeletedDigestReportSettingOpen(false);
                        },
                        loadingState: deleteDigestReportCard.isLoading
                    }}
                />
            }
        </>
    );
}

export default DigestReportingCard;