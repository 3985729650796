import React, { Component } from "react";
import Notify from './notify';

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notify: null,
        };
        this.notify = this.props.notify;
    }

    confirmOptionActions = (data) => {
        this.setState({ notify: data });
    }

    removeConfirmation = () => {
        this.setState({ notify: null });
    }

    render() {
        const notifyVar = this.state.notify;
        return (
            <>
                {
                    (notifyVar) ?
                        <Notify
                            className={this.props.className || ''}
                            title={this.notify[notifyVar.type].title}
                            onConfirm={(e) => { this.notify[notifyVar.type].confirm(notifyVar.data) }}
                            onClose={(e) => { this.notify[notifyVar.type].cancel() }}
                            successBtnTxt={this.notify[notifyVar.type].successBtnTxt}
                            cancelBtnTxt={this.notify[notifyVar.type].cancelBtnTxt}
                        />
                        : ''
                }
            </>
        );
    }
}

export default Confirm;