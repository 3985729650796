class FilterHelper {

    static getFilterSchemaForApi(filters) {
        let filterObject = {};
        if (filters && filters.length > 0) {
            for (let f in filters) {
                let item = filters[f];
                if (item.value in filterObject) {
                    filterObject[item.value] = filterObject[item.value] + "," + item.typeValue;
                } else {
                    filterObject[item.value] = item.typeValue;
                }
            }
        }
        return filterObject;
    }
}
export default FilterHelper;