import React from "react";
import CXDateRangePicker from "../components/CXDateRangePicker";
import ClassesSummary from "./components/classesSummaryCard";
import ClassesTable from "./components/classesTable";
import ClassesBubbleGraph from "./components/classesBubbleGraph";
import CxFilter from "../components/cxFilter";
import FilterTabs from "./components/filterTabs";
import { Row, Col } from "reactstrap";
import postsApi from '../../api/posts';
import mainHelper from "../../helpers/main";
import Throbber from "../components/Throbber";
import ClassesBubbleCluster from "./components/classesBubbleCluster";
import moment from 'moment';
import ReactGA from 'react-ga';
import DateFilter from "../components/dateFilter";
import { FilterBar, StartFilters } from "../../components/FilterBar/filterBar";
import AdvancedFilter from "./components/advancedFilter";
let helpers = new mainHelper();



class ClassesOverview extends React.Component {
  constructor(props) {
    super(props);
    this.classFilter = '';
    this.state = {
      queryFilterData: this.getFilter(),
      classChosen: 0
    };
    this.postsApi = new postsApi();
    ReactGA.pageview('Insights - Classification', null, helpers.getUserDetails());
  }

  getDateDomainFilter() {
    return this.dateFilter;//+ this.domainFilter ;
  }

  getFilter() {
    var filter = { classMapping: this.classFilterKey };
    return filter;
  }

  setFilter = () => {
  }

  filterClass = (byClass) => {
    // empty the filter when class changes
    this.metaFilter = '';
    // class_mapping can be ex. category - subcategory - brand
    this.classFilter = `class_mapping=${byClass.key}`;
    this.classFilterKey = byClass.key;
    // class_value_ are the keys for those classes in backend
    this.setState({
      classChosen: 1,
      classFilterKey: byClass.key
    });
  }

  renderPageContent = () => {
    return (<>
      <div className={"cx-page-filter-container mb-3"}>
        <FilterBar className={"mt-3 cx-campaign-filter-listing"}>
          <StartFilters>
            <AdvancedFilter classMapping={this.state.classFilterKey} />
          </StartFilters>
        </FilterBar>
      </div>
      <Row className={"align-items-start"}>
        <div className={"col-sm-6"}>
          <ClassesSummary classMapping={this.state.classFilterKey} />
        </div>
        <div className={"col-sm-6"}>
          <ClassesBubbleGraph classMapping={this.state.classFilterKey} />
        </div>
      </Row>
      <Row className={"align-items-start"}>
        <div className={"col-sm-6"}>
          <ClassesTable classMapping={this.state.classFilterKey} />
        </div>
        <div className={"col-sm-6"}>
          <ClassesBubbleCluster classMapping={this.state.classFilterKey} />
        </div>
      </Row>
    </>);
  }

  render() {
    return (
      <>
        <div className="content">
          <div>
            <h4 style={{ "marginBottom": "20px" }}><i className="tim-icons icon-notes"></i>&nbsp; Insights - Classifications</h4>
          </div>
          <div className={"justify-content-end d-flex"}>
            <DateFilter className={"mb-3"} />
          </div>
          <FilterTabs callback={this.filterClass} />
          {this.state.classChosen === 1 ?
            this.renderPageContent() :
            <div className={"d-flex align-items-center"}><Throbber /></div>
          }
        </div>
      </>
    );
  }
}

export default ClassesOverview;
