import React, {Component} from "react";
import {connect} from 'react-redux';
import {
    adActions,
    adsetActions,
    campaignActions,
    campaignSelectionActions,
    drawerActions,
    tableActions
} from '../../../actions';
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import {FormGroup, Input, Label} from "reactstrap";
import Switch from "react-bootstrap-switch";
import _ from 'lodash';
import Confirm from "../../components/confirmationBox";
import Delivery from "./Delivery"
import analytics from "../../../helpers/analytics";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

const helper = new mainHelper();

class AdsetTable extends Component{
    constructor(props) {
        super(props);
        // table defaults (effects the UI changes)
        this.state = {
            pages: null,
            loading: true,
            sorted: [{ id: 'adsetId', desc: true }],
            data: []
        };
        this.notify = {
            duplicate: {
                title: 'Are you sure you want to duplicate?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();

                    await this.props.duplicateAdset({ ids: [data.typeid] });
                    // refresh table
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Duplicate',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount() {
        this.getAdsetListData();
    }


    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.campaignSelections, this.props.campaignSelections) || !_.isEqual(prevProps.adsetList, this.props.adsetList)){
            // update the data if list changed or selections changed
            this.setState({data: this.filter(this.props.adsetList, this.props.campaignSelections)});
        }
    }

    openDrawer = ( selectedData, event = null) => {
        if (event) event.preventDefault();
        this.props.setDrawerVisible(true, selectedData);
    }

    activations = async  (original) => {
        await this.props.activateAdset(original);
        this.props.fetchAdsets();
        this.refreshData();
    }

    refreshData = () =>{
        this.props.campaignReloadList();
        this.props.fetchAdsets();
        this.props.adReloadList();
    }

    duplicateAdsetAction = (adsetId) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: 'duplicate', data: {typeid: adsetId} });
        }
        return false;
    }

    tableCols = () => {
        return [
            {
                filterable: false,
                id: "checkbox",
                accessor: "",
                Header: x => {
                    return (
                        <FormGroup check className={`${(this.props.hideSelectAll)? 'd-none': ''}`}>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selectAll === 1}
                                       onChange={() => this.props.toggleAll(this.props.adsetList)}
                                       ref={input => {
                                           if (input) {
                                               input.indeterminate = this.props.selectAll === 2;
                                           }
                                       }}
                                />
                                <span className="form-check-sign campaign-form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selected[original.id] === true}
                                       onChange={() => this.props.toggleSelection(original.id, original.campaignId)}
                                />
                                <span className="form-check-sign campaign-form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                filterable: false,
                Header: "",
                accessor: "",
                width: 50,
                sortable: false,
                Cell: ({ original }) => {
                    return (
                        <div className={`cx-input-container cx-switch-container`}>
                            <Switch
                                offColor=""
                                offText=""
                                onColor=""
                                onText=""
                                onChange={(e) => { this.activations(original) }}
                                defaultValue={original.isTurnedOn}
                            />
                        </div>
                    );
                }
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ original }) => {
                    return <div className={'d-flex flex-column'}>
                        <div className={"cx-table-name-styling adsets"}><a onClick={(e) => { this.filterAndNavigate(original, e) }} href={'#'} title={original.name}>{original.name}</a></div>
                        <div className={"table-actions-icons"}>
                            <a href={'#'} onClick={(e)=>{this.rowActions('edit', original, e)}}>Edit</a>&nbsp;&nbsp;
                            <a href={'#'} onClick={(e)=>{this.rowActions('duplicate', original, e)}}>Duplicate</a>&nbsp;&nbsp;
                            <a href={'#'} onClick={(e)=>{this.rowActions('report', original, e)}}>View Charts</a>
                        </div>
                    </div>;
                },
                filterMethod: (filter, row) => row.name.toLowerCase().includes(filter?.value?.toLowerCase()),
                width: 280
            },
            {
                Header: "Delivery",
                accessor: "delivery",
                Cell: ({original}) => {
                    return <Delivery delivery={original.delivery} />                       

                },
                filterMethod: (filter, row) => filter.value === "all" ? true : row.delivery.toLowerCase() === filter?.value?.toLowerCase(),
                Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="In draft">In draft</option>
                    <option value="active">active</option>
                    <option value="inactive">inactive</option>
                    <option value="rejected">rejected</option>
                    <option value="pending delivery">pending delivery</option>
                    <option value="in review">in review</option>
                    <option value="rejected">rejected</option>
                    <option value="completed">completed</option>
                    <option value="deleted">deleted</option>
                </select>
                ),
            },
            // {
            //     Header: "Campaign",
            //     accessor: "campaignName"
            // },
            {
                Header: "Budget",
                accessor: "totalBudget",
                Cell: ({ original }) => {
                    return <span className="number-align-right">${helper.formatCXNumber(original.totalBudget)}</span>
                }
            },
            {
                Header: "Impressions",
                accessor: "currentImpressions",
                Cell: ({ original }) => {
                    return <span className="number-align-right">{helper.formatCXNumber(original.currentImpressions)}</span>
                }
            },
            {
                Header: "Clicks",
                accessor: "currentClicks",
                Cell: ({ original }) => {
                    return <span className="number-align-right">{helper.formatCXNumber(original.currentClicks)}</span>
                }
            },
            {
                Header: "CTR",
                accessor: "ctr",
                Cell: ({ original }) => {
                    return (
                      <span className="number-align-right">{original.ctr}</span>
                    );
                },
            },
            {
                Header: "Spent",
                accessor: "currentSpentBudget",
                Cell: ({ original }) => {
                    return <span className="number-align-right">${helper.formatDecimal(original.currentSpentBudget)}</span>
                }
            },
            { width: 130,
                Header: "Schedule",
                 accessor: "startDate",
                Cell: ({ original }) => {
                    return <span>{ (original.startDateDetails && original.startDateDetails.isEnabled? (helper.format_date_dd_MM_yyyy(original.startDateDetails.date) + 
                        ' - '):'') + (original.endDateDetails  && original.endDateDetails.isEnabled?helper.format_date_dd_MM_yyyy(original.endDateDetails.date):'On going')}</span>
                }
            }
        ];
    }

    filterAndNavigate = (original, e) => {
        if(e) e.preventDefault();
        if(!this.props.selected[original.id]){
            this.props.toggleSelection(original.id, original.campaignId);
        }
        this.props.activateTab('ad');
    }


    getAdsetListData = async () => {

        this.setState({ loading: true });
        // calls the action to change the state of the store
        await this.props.fetchAdsets();
        this.setState({
            loading: false,
            data: this.filter(this.props.adsetList, this.props.campaignSelections)
        });
    }

    /*
     * Row actions of a table
     */
    rowActions = (action, obj, event) => {
        switch(action){
            default:
                this.openDrawer([{ typeid: obj.id, type: 'adset',campaignId:obj.campaignId }], event);
                this.props.setDrawerMode(action); // edit or report
                analytics.logEvent('Campaign','Adset Table ' + action);
                break;
            case 'duplicate':
                this.duplicateAdsetAction(obj.id);
                analytics.logEvent('Campaign','Adset Table ' + action);
                break;
        }
    }

    filter = (adsetList,  campaignList) => {
        if(!campaignList.length) return adsetList;
        let arr = [];
        for (let adset in adsetList){
            if(campaignList.indexOf(adsetList[adset]['campaignId'].toString()) > -1){
                arr.push(adsetList[adset]);
            }
        }
        return arr;
    }

    render () {
        return (
            <>
                <ReactTable
                    filterable={true}
                    noDataText=''
                    NoDataComponent={NoData}
                    defaultSorting={this.state.sorted}
                    sortable={true}
                    minRows={0}
                    data={this.state.data}
                    resizable={false}
                    columns={this.tableCols()}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    const hideSelectAll = (
        state.campaignSelections &&
        state.campaignSelections.selected_arr &&
        (
            (state.campaignSelections.selected_arr.campaign && state.campaignSelections.selected_arr.campaign.length) ||
            (state.campaignSelections.selected_arr.adset && state.campaignSelections.selected_arr.adset.length) ||
            (state.campaignSelections.selected_arr.ad && state.campaignSelections.selected_arr.ad.length)
        )
    ) || false;

    const selections =  state.campaignSelections && state.campaignSelections.selected_arr;
    return {
        hideSelectAll,
        adsetList: (state.adsets && state.adsets.adsetList)? state.adsets.adsetList: [],
        selectAll: (state.campaignSelections && state.campaignSelections.selectAll && state.campaignSelections.selectAll.adset) ? state.campaignSelections.selectAll.adset : 0,
        selected: (state.campaignSelections && state.campaignSelections.selected && state.campaignSelections.selected.adset) ? state.campaignSelections.selected.adset : {},
        campaignSelections: (selections)? (selections.campaign)? selections.campaign : [] : [],
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setDrawerMode: (mode='edit') => dispatch(drawerActions.setDrawerMode(mode)),
        fetchAdsets: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
        toggleSelection: (id, cid) => dispatch(campaignSelectionActions.toggleSelection('adset', id, cid)),
        toggleAll: (obj) => dispatch(campaignSelectionActions.toggleAll('adset', obj)),
        activateAdset: (obj) => dispatch(adsetActions.activateAdset(obj)),
        activateTab: (tab) => dispatch(tableActions.activateTab(tab)),
        duplicateAdset: (item) => dispatch(adsetActions.duplicateAdset(item)),
        campaignReloadList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
        adReloadList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr))
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AdsetTable);