import React from "react";
import {advertiserActions} from "../../../actions";
import {connect} from "react-redux";
import {Field, Form, reduxForm, reset} from "redux-form";
import {Input} from "../../components/formElts";
import CxLoader from "../../components/cxLoader";

class PixelForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formAction: this.props.formAction || 'create',
            loader:  this.props.loader || false,
            pixelForAdvertiserId: (this.props.pixelForAdvertiserId)? this.props.pixelForAdvertiserId: false
        };
        this.title = (this.props.pixelLoaded)? 'update': 'create';
        this.handleSubmit = this.props.handleSubmit;
    }

    componentDidUpdate(prevProps){
        // Handle the form action change whether create or update pixel
        if(this.props.formAction != prevProps.formAction){
            this.setState({formAction: this.props.formAction});
        }
        if(this.props.loader != prevProps.loader){
            this.setState({loader: this.props.loader});
        }
        if(this.props.pixelForAdvertiserId != prevProps.pixelForAdvertiserId){
            this.setState({pixelForAdvertiserId: this.props.pixelForAdvertiserId});
        }
    }

    submitButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSubmit)();
    }

    onSubmit = async (formVals) => {
        this.setState({loader: true});
        let mapping;
        if(this.state.formAction === 'create'){
            mapping = {...formVals, advertiserId: this.state.pixelForAdvertiserId}
        }else{
            mapping = {id: formVals.id, name: formVals.name}
        }
        await this.props.save(mapping, this.state.formAction);
        this.setState({loader: false});
        this.props.alertMsg("Successfully " + this.state.formAction+'d', 'tim-icons icon-check-2', 'success'); // create+d || update+d
        this.props.reset();
        this.closePopOver();
        this.props.refreshList();
    }

    closePopOver = (e) => {
        if (e) e.preventDefault();
        this.props.closePopOver();
    }

    render() {
        return (
            <Form
                className={"cx-pixels-form-container cx-pixels-"+this.title}
                onSubmit={()=>(false)}
            >
                <div className={'cx-pixels-form-title'}>
                    Pixel {this.title}
                </div>
                <div className={'cx-pixels-form-body'}>
                    <Field className={"cx-pixels-form-input my-2"} label={"Name *"} name={"name"} component={Input} />
                    {
                        this.state.formAction === 'create' &&
                        <Field className={"cx-pixels-form-input mb-2"} label={"Domain *"} name={"domain"} component={Input} />
                    }
                    <div className={"cx-action-buttons d-flex"}>
                        <button className={"btn cx-focus-btn"} onClick={this.submitButton}>Save</button>
                        {(this.state.loader)?<CxLoader inPlace={true} className={"mr-3"} />:''}
                        <button className={"btn cx-cancel-btn"} onClick={this.closePopOver}>Cancel</button>
                    </div>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        initialValues: ownProps.pixelLoaded || { name: '', domain: ''}
    }
}

const mapDispatchToProps = (dispatch) => ({
    save: (data, type = 'create') => dispatch(advertiserActions[type+"Pixel"](data)), // type = create || update => createPixel || updatePixel
});

function validate(form) {
    const errors = {};
    if (!form.name) {
        errors.name = "Field is required";
    }
    if (!form.domain) {
        errors.domain = "Field is required";
    }
    return errors;
}

PixelForm = reduxForm({
    form: 'PixelForm',
    enableReinitialize: true,
    validate,
})(PixelForm);

PixelForm = connect(
    mapStateToProps, mapDispatchToProps
)(PixelForm);

export default PixelForm;
