import React from "react";
import PropTypes from "prop-types";
import Filter from "./filter";
import postsApi from "../../api/posts";
import _ from "lodash";


/*
* Example to use
* 1- import this component
* 2- define the onchange filter function
*   filterChange = (queryStr) => {
*      console.log('I got the filter Query string: ', queryStr);
*   }
*
* 3- use it in the render of your component
*  <CxFilter
*   filterKeys={['author','category']}                       // required to set what you want to filter with
*   filterOnChange={this.filterChange}                       // required to get the change of the filter
*   filteredBy={'Author=lea,pia&Category=تسريحة,Jamalouki'}  // if you have a filter by default
*  />
* */

class CxFilter extends React.Component {
  constructor(props) {
    super(props);
    this.postsApi = new postsApi();
    this.state = {
      filter: {},
      filterKeys: props.filterKeys,
      filteredBy: props.filteredBy,
      defaultMsg: (props.defaultMsg) ? props.defaultMsg : 'Filter'
    };
  }

  mapToState = (data) => {
    // map the data
    let constructFilter = {};
    for (let i in data) {
      constructFilter[data[i].filterKey] = data[i].filterData;
    }
    return constructFilter;
  }

  componentWillMount() {
    this.refreshFilters();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    
    if (!_.isEqual(this.props.filterKeys, prevProps.filterKeys)) {
      console.log('cxFilter componentDidUpdate')
      this.setState({ defaultMsg: this.props.defaultMsg, filterKeys: this.props.filterKeys },()=>{this.refreshFilters(); } );
      
    }
  }
  refreshFilters = () => {
    this.postsApi.filters(
       {...this.props.mainFilter ,orderBy:'pageviews',sort:'desc',filterKeys:this.state.filterKeys}
     ).then((result) => {
      if (!(result && result.data && result.data.data && result.data.data.length)) return;
      this.setState({ filter: this.mapToState(result.data.data) });
    });
  }
  /*
  * Helper function
  * to detect the filter data values after change
  * */
  filterCallback = (filterList) => {
    this.props.filterOnChange(filterList);
  }

  render() {
    return (
      <Filter
        callback={this.filterCallback}
        data={this.state.filter}
        selected={this.state.filteredBy}
        defaultMsg={this.state.defaultMsg}
      />

    );
  }
}

CxFilter.propTypes = {
  mainFilter: PropTypes.string.isRequired,
  filterKeys: PropTypes.array.isRequired,
  filterOnChange: PropTypes.func.isRequired,
  filteredBy: PropTypes.string,
  defaultMsg: PropTypes.string
};

export default CxFilter;
