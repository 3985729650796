import {
    FETCH_AD_LIST,
    FETCH_AD_ITEM,
    DELETE_AD_ITEM,
    UPDATE_AD_ITEM,
    DUPLICATE_AD_ITEM,
    QUICK_CREATE_AD,
    UPDATE_AD_STATUS,
    PUBLISH_AD,
    PUBLISH_AD_ERROR,
    UPDATE_AD_ERROR,
    DUPLICATE_AD_ERROR,
    DELETE_AD_ERROR,
    CREATE_AD_ERROR

} from "../constants/appConstant";
import adsApi from '../api/ads';
import {reset, SubmissionError} from 'redux-form';

const api = new adsApi();
/*
 * action creators
 */
const adsEvent = (ads) => ({ type: FETCH_AD_LIST, payload: ads });
export const adEvent = (ad) => ({ type: FETCH_AD_ITEM, payload: ad });
const adUpdateEvent = (ad) => ({ type: UPDATE_AD_ITEM, payload: ad });
const adPublishEvent = (ad) => ({ type: PUBLISH_AD, payload: ad });
const adDeleteEvent = (ad) => ({ type: DELETE_AD_ITEM, payload: ad });
const adDuplicateEvent = (ad) => ({ type: DUPLICATE_AD_ITEM, payload: ad });
const adQuickCreateEvent = (ad) => ({ type: QUICK_CREATE_AD, payload: ad });
const activateAdEvent = (ad) => ({ type: UPDATE_AD_STATUS, payload: ad });
export const adPublishError = (err) => ({ type: PUBLISH_AD_ERROR, payload: err });
export const adUpdateError = (err) => ({ type: UPDATE_AD_ERROR, payload: err });
export const adDuplicateError = (err) => ({ type: DUPLICATE_AD_ERROR, payload: err });
export const adDeleteError = (err) => ({ type: DELETE_AD_ERROR, payload: err });
export const adCreateError = (err) => ({ type: CREATE_AD_ERROR, payload: err });

export const activateAd = (data) => {
    return (dispatch) => {
        return api.updateAdStatus(data)
            .then(response => {
                let res = null;
                if(response && response.data)
                    res = response.data;
               //dispatch(activateAdEvent(res))
            })
            .catch(error => {
                throw(error);
            });
    };
}

export const fetchAllAds = (queryStr) => {
    return (dispatch) => {
        return api.getAds(queryStr)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.ads;
                dispatch(adsEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const fetchAd = (id) => {
    return (dispatch) => {
        return api.getAd(id)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.ad;
                dispatch(reset('EditAd'));
                dispatch(adEvent(res))


            })
            .catch(error => {
                throw (error);
            });
    };
};

export const publishAd = (item) => {
    function printError(key, msg = 'Error') {
        switch(key){
            default:
                return {[key]: msg};
        }
    }

    return (dispatch) => {
        return api.publishAd(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.ads[0];
                dispatch(adPublishEvent(res));
                dispatch(adPublishError(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data) {
                    const errors = error.response.data.description;
                    dispatch(adPublishError(errors));
                    let errorMsgs = { _error: "Publish Ad Failed"};
                    for (let err in errors){
                        let errMsg = printError(err, errors[err]);
                        errorMsgs = {...errMsg, ...errorMsgs};
                    }
                    throw new SubmissionError(errorMsgs);
                }
                throw (error);
            });
    };
};

export const updateAd = (item) => {
    return (dispatch) => {
        return api.updateAd(item)
            .then(response => {
                console.log(response)
                let res = null;
                if (response && response.data)
                    res = response.data.ad;
                dispatch(adUpdateEvent(res))
                dispatch(adUpdateError(undefined));
            })
            .catch(error => {
                console.log(error.response.data.Message)
                dispatch(adUpdateError(error.response.data));
                throw (error);
            });
    };
};

export const deleteAd = (item) => {
    return (dispatch) => {
        return api.deleteAd(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.id;
                //dispatch(adsetDeleteEvent(res))

                dispatch(adDeleteError(undefined));
            })
            .catch(error => {
                dispatch(adDeleteError('error'));
                throw (error);
            });
    };
};

export const duplicateAd = (item) => {
    return (dispatch) => {
        return api.duplicateAd(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.ad;
                dispatch(adDuplicateEvent(res));
                dispatch(adDuplicateError(undefined));
            })
            .catch(error => {
                dispatch(adDuplicateError('error'));
                throw (error);
            });
    };
};

export const quickCreateAd = (data) => {
    return (dispatch) => {
        return api.quickCreateAd(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.ad;
                dispatch(adQuickCreateEvent(res));
                dispatch(adCreateError(undefined));
            })
            .catch(error => {
                dispatch(adCreateError('error'));
                throw (error);
            });
    };
};
