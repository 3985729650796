import React  from "react";
import { connect } from "react-redux";
import {Card, CardBody, CardHeader} from "../../../components/Card/card";
import mainHelpers from "../../../helpers/main";

let helper = new mainHelpers();
class AdsetSummary extends React.Component {

    printTypeStatus = (deliveryStatus) => {
        const getColor = helper.getStatusColor(deliveryStatus);
        return <>
            <span className={"cx-table-ico-color"} style={{backgroundColor: getColor}}></span>
            <span className={"cx-table-ico-state"} style={{color: getColor}}>{deliveryStatus}</span>
        </>;
    }

    adsetInfo = () => {
        if (this.props.item)
            return (
                <Card className={"first-child"}>
                    <CardHeader>
                        Adset Info
                    </CardHeader>
                    <CardBody>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Adset Name</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{this.props.item.name}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Brand</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{this.props.item.brand}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Delivery Status</label> </div>
                            <div className={"cx-input-icon-container"}>
                                {this.printTypeStatus(this.props.item.delivery)}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        return false;
    }

    adsetTarget  = () => {
        if (this.props.item)
            return (
                <Card>
                    <CardHeader>
                        Target
                    </CardHeader>
                    <CardBody>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Location</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>
                                    {/* add all countries */}
                                    {(this.props.item.locationDetails.isAllCountriesEnabled)? 'All countries ' : ''}
                                    {/* add regions if exist */}
                                    {
                                        (
                                            !this.props.item.locationDetails.isAllCountriesEnabled &&
                                            this.props.item.locationDetails.regions.length
                                        )? this.props.item.locationDetails.regions.join(', ')
                                        : ''
                                    }
                                    {/* add countries if exist */}
                                    {
                                        (
                                            !this.props.item.locationDetails.isAllCountriesEnabled &&
                                            this.props.item.locationDetails.locations.length
                                        )?
                                            (this.props.item.locationDetails.regions.length)?
                                                ' With ' + this.props.item.locationDetails.locations.join(', ')
                                                : this.props.item.locationDetails.locations.join(', ')
                                        : ''
                                    }
                                    {/* reject || block countries */}
                                    {
                                        (
                                            this.props.item.locationDetails.blockedLocations.length
                                        )? ' Except ' + this.props.item.locationDetails.blockedLocations.join(', ')
                                            : ''
                                    }
                                </span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Audience Categories</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{this.props.item.categoryList.join(', ')}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Device</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{this.props.item.deviceList.join(', ')}</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        return false;
    }

    adsetSchedule = () => {
        if (this.props.item)
            return (
                <Card>
                    <CardHeader>
                        Schedule
                    </CardHeader>
                    <CardBody>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Start Date</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{(this.props.item.startDateDetails.isEnabled)? this.props.item.startDateDetails.date : 'As soon as reviewed'}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>End Date</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{(this.props.item.endDateDetails.isEnabled)? this.props.item.endDateDetails.date : 'When it is stopped'}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Days</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>{(this.props.item.schedule)? this.props.item.schedule.join(', ') : ''}</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        return false;
    }

    adsetBudget = () => {
        if (this.props.item)
            return (
                <Card>
                    <CardHeader>
                        Budget
                    </CardHeader>
                    <CardBody>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Max CPC</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>${(this.props.item.maxCpc)? this.props.item.maxCpc : '0'}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Total Budget</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>${(this.props.item.totalBudget)? this.props.item.totalBudget : '0'}</span>
                            </div>
                        </div>
                        <div className={`cx-input-container`}>
                            <div className={"cx-input-label"}> <label>Daily Ad Delivery</label> </div>
                            <div className={"cx-input-icon-container"}>
                                <span>${(this.props.item.dailyBudget)? this.props.item.dailyBudget : '0'}</span>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            );
        return false;
    }

    render() {
        return (
            <div className={"cx-campaign-adset-summary"}>
                <div className={"d-flex flex-column"}>
                    {this.adsetInfo()}
                    {this.adsetTarget()}
                </div>
                <div className={"d-flex flex-column"}>
                    {this.adsetSchedule()}
                    {this.adsetBudget()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        item: (state.mediaPlanner && state.mediaPlanner.allocationAdsetItem) || null,
    }
}

// export and connect data to store
export default connect(mapStateToProps)(AdsetSummary);