import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import SurveyTypes from "./SurveyTypes";
import { CXInputField, CXRadio } from "../../../CXComponents";
import ChoicesArrayField from "./ChoicesArrayField";
import { useForm } from "react-hook-form";
import TargetingForm from "../../../components/TargetingForm"
import useCodeMirror from "hooks/useCodeMirror";
import useSurveyStore from "../surveyStore";
import {useQuery} from "react-query";
import {getSegments} from "../../Segmentation/segmentAPI";

const ratingTypes = [
    { label: "Linear Scale", value: "Linear Scale" },
    { label: "Emoji Satisfaction", value: "Linear Scale" },
    { label: "Star Rating", value: "Linear Scale" },
];

const CreateSegmentForm = ({togglePopup,segments, contentGList, defaultValues}) => {
    const { register, handleSubmit, control, getValues, setValue } = useForm({
        defaultValues: defaultValues || {
            surveyType: 'Open Ended Question',
            choices: [{
                text: ''
            }]
        }
    });

    const [activeTab, setActiveTab] = useState("surveyType");
    const [HTML, setHTML] = useState(`<div class="popup" id="surveyPopup" style="display: block;">
    <div class="popup-content">
        <span id="close-popup" style="margin-right: 750px;"><i class="fa fa-close"></i> </span>
        <div class="container">
            <h1>هل تعتبر انفصال المملكة المتحدة عن الأتحاد الاوروبي هو قرار صائب و كيف سيؤثر على المملكة؟</h1>
            <div class="interest-options">
                <label for="custom-checkbox-11" class="multi-check"> <i class="fa fa-1"></i><input type="radio" id="custom-checkbox-11" name="hobbies" class="switch__input" value="206"><span style="padding-left: 83px;">كان قرار صائب و سيؤثر بإيجابية على الوضع الاقتصادي و
                الاجتماعي للمملكة<i class="fa fa-check-circle"></i></span></label>
                <label for="custom-checkbox-12" class="multi-check"><input type="radio" id="custom-checkbox-12" name="hobbies" class="form-check" value="245206"><span style="padding-left: 170px;">كان
                قرار خاطئ و سيساهم في أنتشار البطالة و انعزال المملكة <i class="fa fa-check-circle"></i></span></label>
                <label for="custom-checkbox-13" class="multi-check"><input type="radio" id="custom-checkbox-13" name="hobbies" class="form-check" value="70961"><span>في البداية ستواجه المملكة بعض
                المشاكل و لكن المستقبل واعد بعد هذا الانفصال <i class="fa fa-check-circle"></i></span></label>
             </div>
        </div>
        <button id="submitSurvey" type="submit" class="button" style="margin-right: 20px;">أرسل</button>
    </div>
</div>`);
    const [CSS, setCSS] = useState("");
    const HTMLEditorRef = useCodeMirror(HTML, 'HTML', setHTML);
    const CSSEditorRef = useCodeMirror(CSS, 'CSS', setCSS);


    const renderSurveyTypeTab = () => {
        return (
            <div className="survey-types-tab-wrapper">
                <h3>Choose the survey type</h3>
                <SurveyTypes control={control} name="surveyType" />
            </div>
        );
    };

    const renderTargetingForm = () => {

        return <TargetingForm 
            {...{
                control,
                register,
                segments,
                contentGList
            }}
        />;
    };

    const renderContentTab = () => {
        const surveyType = getValues("surveyType");
        return (
            <Row className="mx-0">
                <Col className="px-0">
                    <CXInputField
                        register={register}
                        label="Survey Title"
                        name="title"
                        placeholder="Header title of the rendered survey"
                    />
                    <br />
                    {surveyType === "Rating Scale" ? (
                        <CXRadio
                            register={register}
                            label="Rating Type"
                            name="ratingType"
                            options={ratingTypes}
                        />
                    ) : (
                        <>
                            <CXInputField
                                register={register}
                                label="Question Text"
                                name="questionText"
                                placeholder="The question that will be displayed to the user"
                            />
                            <br />
                            {(surveyType === "Single Choice Question" ||
                                surveyType === "Multiple Choice Question") && (
                                <ChoicesArrayField
                                    control={control}
                                    name="choices"
                                    register={register}
                                />
                            )}
                        </>
                    )}
                </Col>
            </Row>
        );
    };

    const renderCodeTab = () => {
        return <>
            <label>HTML</label>
            <div ref={HTMLEditorRef.ref}></div>
            <br />
            <label>CSS</label>
            <div ref={CSSEditorRef.ref}></div>
        </>;
    }

    const renderSelectedTab = () => {
        switch (activeTab) {
            case "surveyType":
                return renderSurveyTypeTab();
            case "targeting":
                return renderTargetingForm();
            case "content":
                return renderContentTab();
                case "code":
                return renderCodeTab();
            default:
                return "";
        }
    };

    const onSubmit = (formVals) => {
        console.log(formVals);
        togglePopup();
    };

    return (
        <>
            <Row className={"mx-0 mb-4"}>
                <Col className={"px-0"}>
                    <div className={"reactive-tabs widget-form-tabs"}>
                        <div
                            className={`reactive-tab ${
                                activeTab === "surveyType" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("surveyType")}
                        >
                            <i className={"fa fa-grip"}></i>
                            <p>Type</p>
                        </div>
                        <div
                            className={`reactive-tab ${
                                activeTab === "targeting" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("targeting")}
                        >
                            <i className={"fa fa-bullseye"}></i>
                            <p>Targeting</p>
                        </div>
                        <div
                            className={`reactive-tab ${
                                activeTab === "content" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("content")}
                        >
                            <i className={"fa fa-list"}></i>
                            <p>Content</p>
                        </div>
                        <div
                            className={`reactive-tab ${
                                activeTab === "code" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("code")}
                        >
                            <i className={"fa fa-code"}></i>
                            <p>Code</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <form className="survey-form" onSubmit={handleSubmit(onSubmit)}>
                {renderSelectedTab()}
                <br />
                <br />
                <button className="cx-btn btn-blue-gradient"> Save </button>
            </form>
        </>
    );
};

export default CreateSegmentForm;
