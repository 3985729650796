import auth from './auth';
import apiUrl from '../helpers/global';
import axios from "axios";


class adsApi {
  
  
  constructor() {
    this.auth = new auth();
  }

  getAd = async (id) => {
    return this.auth.request.post(apiUrl + '/ad-read', { data: JSON.stringify({ id }) });
  }
  updateAd = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-update', { data: JSON.stringify(data) });
  }
  publishAd = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-publish', { data: JSON.stringify(data) });
  }
  deleteAd = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-delete', { data: JSON.stringify(data) });
  }
  duplicateAd = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-duplicate', { data: JSON.stringify(data) });
  }
  updateAdStatus = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-turn-on-off', { data: JSON.stringify({ id: data.id, isTurnedOn: !data.isTurnedOn }) });
  }
  quickCreateAd = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-quick-create', { data: JSON.stringify(data) });
  }
  getAds = async (queryStr) => {
    return this.auth.request.post(apiUrl + `/ad-list`);
  }

  adReportTotals = async  (data) => {
    return this.auth.request.post(apiUrl + '/ad-performance-totals', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  adReportPerformance = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-performance-by-day', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  adReportLocation = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-performance-by-location', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  adReportDevices = async (data) => {
    return this.auth.request.post(apiUrl + '/ad-performance-by-device', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }
}

export default adsApi;
