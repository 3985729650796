import React from 'react'
import {
  Card,
  CardBody,
  Row,
  Progress,
  CardTitle
} from "reactstrap";
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import Tooltip from '../../../components/Tooltips/Tooltip';
import noData from "../../../assets/img/no-data.svg"
import { classificationActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";

const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

let helpers = new mainHelper();

class ClassesTable extends React.Component {

  constructor(props) {
    super(props);
    this.setClassTitle();
    this.state = {
      data: [],
      pageSize: 7,
      pages: null,
      loading: true,
      loader: true,
      sorted: [{ id: 'cx_pageviews', desc: true }]
    };

    this.pagingData = this.pagingData.bind(this);

    this.cols = [
      {
        Header: this.classTitle,
        accessor: "author",
        Cell: ({ original }) => {
          return this.printTop(original);
        }
      },
      {
        Header: "Pageviews",
        accessor: "pageviews",
        Cell: ({ original }) => {
          return helpers.formatCXNumber(original.pageviews)
        },
        width: 100
      },
      {
        Header: "CX Pageviews",
        accessor: "cx_pageviews",
        Cell: ({ original }) => {
          return this.printProgress(original.cx_pageviews, original.pageviews, helpers.formatDecimal(original.cx_impact), original.author);
        },
        width: 200
      }
    ];
  }

  setClassTitle = () => {
    this.classTitle = helpers.capitalizeFirstLetter(this.props.classKey);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.classMapping, prevProps.classMapping) || (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter))) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.getData(this.state.sorted, 7, 1);
      this.setClassTitle();
      this.setState({ loader: false });
    }
  }


  // componentDidUpdate(prevProps) {
  //   if (JSON.stringify(this.props.queryFilterData) !== JSON.stringify(prevProps.queryFilterData)) {
  //     this.fetchData(this.state);
  //     this.setClassTitle();
  //   }
  // }

  getData = async (sort, size, page) => {

    let firstSort = "desc";
    let finalOrder = "cx_pageviews";

    if (sort && sort.length) {
      firstSort = sort[0].desc ? "desc" : "asc";
      finalOrder = sort[0].id;
    }

    await this.props.fetchData({ orderBy: finalOrder, sort: firstSort, size: size, page: page , classMapping: this.props.classMapping},
      this.props.pageFilters,this.props.dateFilter);

    if (this.props.table && this.props.table.length > 0) {
      const pages = (this.props.table && this.props.table.length) ? Math.ceil(this.props.table[0].row_count / size) : 0;
      this.setState({ pages: pages });
    }
    this.setState({ loader: false });
  }

  pagingData(state, instance) {
    this.setState({ loader: true });
    this.getData( state.sorted, state.pageSize, state.page ? state.page + 1 : 1);
  }

  printProgress = (numerator, denominator, percent, title) => {
    return <div className="progress-container" title={title}>
      <div>
        <p style={{ "fontSize": "105%" }}>{numerator.toLocaleString()} <span style={{ "color": "rgb(142, 142, 142)" }}>out of {denominator.toLocaleString()}</span></p>
      </div>
      <Progress max="100" value={percent}> <span className="progress-value">{percent}%</span> </Progress>
    </div>;
  }

  printTop = (o) => {
    return <div className={"d-flex align-items-center"}>
      <h5 className="cx-tahoma mb-0">
        <i className="fa fa-user"></i> {o.class}
      </h5>
    </div>;
  }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <Card id="classesTable">
        <CardBody style={{ "height": "500px" }}>
          <Row className={"justify-content-between mx-0"}>
            <CardTitle>Top <span className="cx-pink">{this.classTitle}</span> recommended by CX</CardTitle>
            <Tooltip text={"This card displays the tags along with the total pageviews their articles generated. You can also view the contribution of CX to the tag’s pageviews."}></Tooltip>
          </Row>
          {this.state.loader ? 
                <CxLoader />
                 : ''}
          <ReactTable
            defaultSorted={this.state.sorted}
            showPageSizeOptions={false}
            manual={true}
            minRows={0}
            data={this.props.table}
            resizable={false}
            columns={this.cols}
            onFetchData={this.pagingData}
            defaultPageSize={7}
            showPaginationBottom={true}
            className="-striped -highlight"
            // loading={loading}
            pages={pages}
            noDataText="No Data"
            NoDataComponent={NoData}
          />
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: state.filter && state.filter.date || null,
    pageFilters: state.classificationInsight && state.classificationInsight.pageFilters || null,
    table: (state.classificationInsight && state.classificationInsight.table) || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params,filter,datefilter) => dispatch(classificationActions.fetchTable(params,filter,datefilter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassesTable);

