import React from "react";
import CXDateRangePicker from "../components/CXDateRangePicker";
import AuthorsSummary from "./components/authorsSummary";
import AuthorsTable from "./components/authorsTable";
import AuthorsBubbleGraph from "./components/authorsBubbleGraph";
import CxFilter from "../components/cxFilter";
import { Row, Col } from "reactstrap";
import mainHelper from "../../helpers/main";
import AuthorsNetwork from "./components/authorsNetwork.jsx";
import moment from 'moment';
import analytics from "../../helpers/analytics";
import DateFilter from "../components/dateFilter";
import { FilterBar, StartFilters } from "../../components/FilterBar/filterBar";
import AdvancedFilter from "./components/advancedFilter";

let helpers = new mainHelper();

class AuthorsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    analytics.logPageView('Insights - Authors');
  }


  render() {
    return (
      <>
        <div className="content">
          <div>
            <h4 style={{ "marginBottom": "20px" }}><i className="tim-icons icon-notes"></i>&nbsp; Insights - Authors</h4>
          </div>
          {/* <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs> */}
          <div className={"cx-page-filter-container mb-3"}>
            <div className={"justify-content-end d-flex"}>
              <DateFilter className={"mr-3"} />
            </div>
            <FilterBar className={"mt-3 cx-campaign-filter-listing"}>
              <StartFilters>
                <AdvancedFilter />
              </StartFilters>
            </FilterBar>
          </div>
          <Row className={"align-items-start "}>
            <div className={"col-sm-6 "}>
              <AuthorsSummary />
            </div>
            <div className={"col-sm-6"}>
              <AuthorsBubbleGraph />
            </div>
          </Row>
          <Row className={"align-items-start "}>
            <div className={"col-sm-6 "}>
              <AuthorsTable />
            </div>
            <div className={"col-sm-6"}>
              <AuthorsNetwork />
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default AuthorsOverview;
