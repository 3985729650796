import React from 'react'
import {
    Card,
    CardBody,
    Row,
    CardTitle,
} from "reactstrap";
import Highcharts from 'highcharts';
import mainHelper from "../../../helpers/main";
import HighchartsReact from 'highcharts-react-official';
import Tooltip from '../../../components/Tooltips/Tooltip';
import {postsActions} from "../../../actions";
import {connect} from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data-for-charts.svg"
const NoData = () => {
    return <img className="cx-no-data-svg" src={noData}/>
};

let helpers = new mainHelper();

class PublishedPageviewsGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter))) {
            this.fetchData();
        }
    }
    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.props.fetchData(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }

    getData = () => {
        const {pageviewsGraph} = this.props;
        if (! pageviewsGraph)  return [];

        let datalist = [],
            datapublished = [],
            cxPageviews = [];

        for (let i in pageviewsGraph) {

            let item = pageviewsGraph[i],
                date = Date.parse(helpers.cxDate(item.timestamp)),
                cx_impact = helpers.formatDecimal((item.cx_pageviews * 100 / item.pageviews));

            datalist.push([date, item.pageviews - item.cx_pageviews]);
            datapublished.push([date, item.published_count, cx_impact]);
            cxPageviews.push({x:date, y: item.cx_pageviews, cx_impact: cx_impact});
        }

        return [
            {
                yAxis: 0, dataLabels: { enabled: false },
                type: 'areaspline', name: "CX Pageviews", color: '#ff8d72', data: cxPageviews, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                yAxis: 0, fillOpacity: 0.3, "dataLabels": { "enabled": false },
                type: 'areaspline', name: "Pageviews", color: '#d4d4d8', data: datalist, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                dataLabels: { enabled: true }, pointWidth: 20, borderRadius: 2, dashStyle: "ShortDash",
                pointPlacement: 0, "pointInterval": 1,
                type: 'line', name: "Published Articles", color: '#29b3e8', data: datapublished, yAxis: 1
            }
        ];
    }

    render() {
        return (
            <Card>
                <CardBody style={{ "height": "380px" }}>
                {this.state.loader ? <CxLoader /> : ''}
                    {(this.props.pageviewsGraph) ?
                        <>
                            <Row className={"cx-card-title justify-content-between mx-0"}>
                                <CardTitle>Pageviews over Time</CardTitle>
                                <Tooltip text={"This card displays the pageviews over time along with the number of articles published. You can also view the contribution of CX on the Pageviews."}></Tooltip>
                            </Row>
                            <div>
                                <HighchartsReact
                                    // containerProps={{ className: "highchartscss" }}
                                    highcharts={Highcharts}
                                    options={
                                        {

                                            "title": { "text": "" },
                                            "subtitle": { "text": "" },
                                            "exporting": { "enabled": false },
                                            "chart": { "type": "spline", "polar": false, "backgroundColor": "transparent" , "height": 318
                                            },
                                            "series": this.getData(),
                                            "plotOptions": {
                                                areaspline: {
                                                    stacking: 'normal',
                                                    lineColor: '#666666',
                                                    lineWidth: 1,
                                                    marker: {
                                                        lineWidth: 1,
                                                        lineColor: '#666666'
                                                    }
                                                }
                                            },
                                            "legend": {
                                                "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
                                                    "color": '#fff'
                                                }
                                            },
                                            "tooltip": {
                                                "shared": true,
                                                "borderWidth": 1,
                                                "formatter": function () {
                                                    return this.points.reduce(function (s, point) {
                                                        if (point.series.name === "CX Pageviews")
                                                        {
                                                            return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',') + ' <b>' + point.point.cx_impact + '%</b>';
                                                        }
                                                        return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
                                                    },'<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) +'</div>');
                                                }
                                            },
                                            "credits": { "enabled": false },
                                            "annotations": [{}],
                                            "pane": { "background": [] },
                                            "responsive": {
                                                "rules": []
                                            },
                                            "xAxis": {
                                                "type": 'datetime',
                                                "labels": {
                                                    "style": {
                                                        "color": '#aeaeae'
                                                    }
                                                },
                                                'minPadding': 0
                                            },
                                            "yAxis": [
                                                {
                                                    "title": { "text": "Pageviews" },
                                                    "labels": {
                                                        "formatter": function () {
                                                            return helpers.formatNumber(this.value);
                                                        },
                                                        "style": {
                                                            "color": '#aeaeae'
                                                        }
                                                    },
                                                    "gridLineColor": 'rgba(255,255,255,0.1)'
                                                },
                                                {
                                                    "title": { "text": "Published Articles" }, opposite: true,
                                                    "labels": {
                                                        "formatter": function () {
                                                            return helpers.formatNumber(this.value);
                                                        },
                                                        "style": {
                                                            "color": '#aeaeae'
                                                        }
                                                    },
                                                    "gridLineColor": 'rgba(255,255,255,0.1)'
                                                }
                                            ]
                                        }
                                    }
                                />
                            </div>
                        </>
                        : <NoData />
                    }
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.postsInsight && state.postsInsight.pageFilters || null,
        pageviewsGraph: (state.postsInsight && state.postsInsight.pageviewsGraph) || null,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (filter, dateFilter) => dispatch(postsActions.fetchPageviewsGraph(filter, dateFilter))
    };
};



// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(PublishedPageviewsGraph);
