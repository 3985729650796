import React from 'react';

const SurveyTypeCard = ({icon, title, onClick, selected}) => {
    return (
        <div className={`survey-type-card ${selected && 'selected'}`} onClick={() => onClick(title)}>
            <img src={icon} alt="star" />
            <h2>{title}</h2>
        </div>
    );
}

export default SurveyTypeCard;


// rating
// article comment/opinion
// questionaire
// single choice
// multiple choice
// 
