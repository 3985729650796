import React from "react";
import axios from "axios";
import PerfectScrollbar from "perfect-scrollbar";

class main {
  cxColorSet = ['#d8adad', '#f0605a', '#8b3091', '#43ba8f', '#ff1dca', '#5fd7fc', '#1e5199', '#0aaf36', '#ffb500', '#f26522'];

  cxColors = {
    black: [
      '#9899A6',
      '#47485F',
      '#32334D',
      '#1E1E2F'
    ],
    dark: [
      '#4090B4',
      '#7240B4',
      '#B47240',
      '#B4AD40',
      '#B44046',
      '#40B498'
    ],
    light: [
      '#BBE6F9',
      '#D6BBF9',
      '#F9D6BB',
      '#F9F5BB',
      '#F9BBBE',
      '#BBF9EA'
    ],
    regular: [
      '#55C0F0',
      '#9855F0',
      '#F09855',
      '#F0E655',
      '#F0555D',
      '#55F0CB'
    ]
  };

  formatNumber = (num) => {
    if (num >= 1000000000) return parseFloat(num / 1000000000).toFixed(1) + "B";  // maybe only switch if > 1000
    if (num >= 1000000) return parseFloat(num / 1000000).toFixed(1) + "M";  // maybe only switch if > 1000
    if (num > 1000) return parseFloat(num / 1000).toFixed(1) + "K";  // maybe only switch if > 1000
    return num;
  }
  formatCXNumber = (dec, formatStartingNb = null) => {
    dec = (dec)? dec : 0;
    if(formatStartingNb && dec >= formatStartingNb){
      dec = this.formatNumber(dec);
    }
    return dec.toLocaleString();
  }
  formatDecimal = (dec) => {
    if (dec)
      return dec.toFixed(2);
    return 0.00;
  }

  formatTime = (time) => {
    return parseInt(time / 3600) + ' hours';
  }

  capitalizeFirstLetter = (str) => {
    if (str)
      return str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  }

  getFilterTooltip = (metaFilters) => {
    let txt = '';
    var filterData = metaFilters;


    for (let keyFilterType in filterData) {
      let keyFilterData = filterData[keyFilterType];
      if (!keyFilterData.length) continue;
      if (txt !== '') txt += '  -  ';
      txt += keyFilterType + ':';
      for (let index in keyFilterData) {
        let item = keyFilterData[index];
        txt += item.value;
        if (keyFilterData.length - 1 !== index * 1) txt += ',';
      }
    }
    if (txt)
      return txt;
    return 'Please choose filter..'
  }
  getAccessToken = () => {
    return this.getInfo('accessToken');
  }
  getRefreshToken = () => {
    return this.getInfo('refreshToken');
  }

  getOrganizations = () => {
    return this.getInfo('organizations');
  }

  getOrganization = () => {
    return localStorage.organization;
  }

  setOrganization = (organization) => {
    if (organization && organization!="undefined")
    localStorage.organization = JSON.stringify(organization);
  }
  getOrganizationId = () => {
    if (localStorage.organization)
    {
      try{
        let organization=JSON.parse(localStorage.organization);
        if(organization) return organization.id
      }catch (e){

      }
    }
    return '';
  }
  getOrganizationName = () => {
    if (localStorage.organization)
    {
      try{
        let organization=JSON.parse(localStorage.organization);
        if(organization) return organization.name
      }catch (e) {

      }

    }
    return "";
  }
  getTokenExpiredAt = () => {
    return this.getInfo('expiredAt');
  }
  getInfo = (about) => {
    if (window.localStorage.user)
      return JSON.parse(window.localStorage.user)[about]

    return "";
  }

  setInfo = (about, data) => {

    //alert(about)
    //alert(data)
    if (window.localStorage.user && JSON.parse(window.localStorage.user)[about]) {
      let userData = JSON.parse(window.localStorage.user);
      userData[about] = data;
      window.localStorage.user = JSON.stringify(userData);
    }
  }

  helperMainInfo = {
    init: () => {
      if (!localStorage.cx_data) {
        localStorage.cx_data = JSON.stringify({
          filter_from_date: this.formatDate_yyyy_MM_dd(-7),
          filter_to_date: this.formatDate_yyyy_MM_dd(0)
        });
      }
    },
    reset: () => {
      localStorage.cx_data = JSON.stringify({
        filter_from_date: this.formatDate_yyyy_MM_dd(-7),
        filter_to_date: this.formatDate_yyyy_MM_dd(0)
      });
      return true;
    },
    set: (key, data) => {
      let arr = JSON.parse(localStorage.cx_data);
      arr[key] = data;
      localStorage.cx_data = JSON.stringify(arr);
      return true;
    },
    get: (key) => {
      let arr = JSON.parse(localStorage.cx_data);
      return arr[key];
    }
  }

  mainInfo = {
    get: (key) => {
      this.helperMainInfo.init();
      return this.helperMainInfo.get(key);
    },
    reset: () => {
      return this.helperMainInfo.reset();
    },
    set: (key, data) => {
      this.helperMainInfo.init();
      return this.helperMainInfo.set(key, data);
    }
  }

  getConfig = (about) => {
    if (window.localStorage.config && JSON.parse(window.localStorage.config)[about]) { return JSON.parse(window.localStorage.config)[about] };
    return false;
  }

  isPermission = (permission) => {
    let permissions = this.getPermissions();
    if (permissions && permissions.indexOf(permission) > -1) {
      return true;
    }
    return false;
  }

  sliceString = (str, chars) => {
    return str.length > chars ? str.substring(0, chars) + '...' : str;
  }

  checkExpiry = (date) => {
    return new Date(date) - new Date() < 0
  }

  getImageFromUrl = (url, callback) => {
    return axios.get(url, { responseType: 'arraybuffer' })
      .then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers['content-type'] });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          callback(reader.result);
        }
      });
  }

  base64ToBlob = (dataUri) => {
    let base64 = dataUri.split(',')[1];
    let mime = dataUri.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1];
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];
    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      var slice = byteChars.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  addHours = (date, h) => {
    date.setTime(date.getTime() + (h * 60 * 60 * 1000));
    return date;
  }

  cxDate = (date) => {
    let d = new Date(date);
    this.addHours(d, 2);
    return d;
  }

  format_date_dd_MM_yyyy = (sepcificDate, seperator = '/') => {
    let date = new Date(sepcificDate);
    if (!isNaN(date.getTime())) {
      // date is valid
      return date.getDate() + seperator + (date.getMonth() + 1) + seperator + date.getFullYear();
    }
    return sepcificDate;
  }

  formatDate_yyyy_MM_dd = (after, sepcificDate = null) => {
    // if when 0 means today
    // if when 1 means add 1 day so tommorrow
    let date = new Date();
    if (sepcificDate) {
      sepcificDate = sepcificDate.split('-').join('/');
      date = new Date(sepcificDate);
    }
    date.setDate(date.getDate() + after);
    return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
  }
  getDefaultDate() {
    return { startDate: this.formatDate_yyyy_MM_dd(-6), endDate: this.formatDate_yyyy_MM_dd(0) };
  }


  /* helper functions */
  getStartDate = (startDate) => {
    // there isnt a start date nor anything in local storage
    if (startDate) {
      startDate = startDate.split('-').join('/');
      this.mainInfo.set('filter_from_date', startDate);
    } else if (this.mainInfo.get('filter_from_date')) {
      startDate = this.mainInfo.get('filter_from_date');
    } else if (!startDate && !this.mainInfo.get('filter_from_date')) {
      startDate = this.formatDate_yyyy_MM_dd(-6);
    }
    return startDate;
  }

  /* helper functions */
  getEndDate = (EndDate) => {
    // there isnt a start date nor anything in local storage
    if (EndDate) {
      EndDate = EndDate.split('-').join('/');
      this.mainInfo.set('filter_to_date', EndDate);
    } else if (this.mainInfo.get('filter_to_date')) {
      EndDate = this.mainInfo.get('filter_to_date');
    } else if (!EndDate && !this.mainInfo.get('filter_to_date')) {
      EndDate = this.formatDate_yyyy_MM_dd(0);
    }
    return EndDate;
  };


  getCXDateFilter = (startDate, EndDate) => {
    startDate = this.getStartDate(startDate);
    EndDate = this.getEndDate(EndDate);
    // return '&fromtimestamp=' + this.getStartDate(startDate).split('/').join('-') + '&totimestamp=' + this.getEndDate(EndDate).split('/').join('-');

    return { fromTimestamp: this.getStartDate(startDate).split('/').join('-'), toTimestamp: this.getEndDate(EndDate).split('/').join('-') }
  }
  getCXDomainFilter = () => {
    return ''; // '&organization=' + this.getInfo('organization');
  }

  getPermissions = () => {
    var permissions = this.getInfo('permissions')
    if (permissions)
      return permissions[this.getOrganizationId()];
    return null;
  }

  getUserEmail = () => {
    return this.getInfo('email');
  }
  // getUserEmailAnalytics = () => {
  //   return this.getInfo('email').replace("@",'.');
  // }
  getUserDetails = () => {
    return this.getOrganizationName() + " | " + this.getInfo('email').replace("@",'.');
  }
  getDefaultLandingPages = () => {
    return {
      'campaign-list': '/admin/campaign-list',
      'posts-pageviews-by-day': '/admin/posts',
      'adset-read-allocation': '/admin/media-planner',
      // 'admin': '/admin/posts',
      // 'developer': '/admin/posts',
      '*': '/admin/campaign-list'
    };
  }

  getDefaultRoute = () => {
    let permissionsLP = this.getDefaultLandingPages();
    let getPermissions = this.getPermissions();
    if (getPermissions && getPermissions.length) {
      let permissionData = Object.keys(permissionsLP);
      for (let p in permissionData) {
        if (getPermissions.indexOf(permissionData[p]) > -1) {
          return permissionsLP[permissionData[p]];
        }
      }
    }
  }

  destroyScroller = (name) => {
    if (window.cxPerfectScrollbar[name] && navigator.platform.indexOf("Win") > -1) {
      window.cxPerfectScrollbar = window.cxPerfectScrollbar || {};

      window.cxPerfectScrollbar[name].ps.destroy();
      window.cxPerfectScrollbar[name].destroyed = 1;

      if (name === 'mainPanel') {
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    }
  }

  reInitScroller = (name) => {
    window.cxPerfectScrollbar = window.cxPerfectScrollbar || {};
    if (window.cxPerfectScrollbar[name])
      this.initScroller(name, window.cxPerfectScrollbar[name].ref);
  }

  initScroller = (name, ref) => {
    window.cxPerfectScrollbar = window.cxPerfectScrollbar || {};
    let ps = null;
    if (navigator.platform.indexOf("Win") > -1) {
      if (name === 'mainPanel') {
        document.documentElement.className += " perfect-scrollbar-on";
        document.documentElement.classList.remove("perfect-scrollbar-off");
      }
      ps = new PerfectScrollbar(ref);
    }
    window.cxPerfectScrollbar[name] = {
      name,
      ref,
      ps,
      destroyed: 0
    }
    return window.cxPerfectScrollbar;
  }

  scrollTop = (name) => {
    if (navigator.platform.indexOf("Win") > -1) {
      window.cxPerfectScrollbar = window.cxPerfectScrollbar || {};
      if (window.cxPerfectScrollbar[name] && window.cxPerfectScrollbar[name].ps && window.cxPerfectScrollbar[name].ps.element) {
        window.cxPerfectScrollbar[name].ps.element.scrollTop = 0;
        window.cxPerfectScrollbar[name].ps.update();
        return true;
      }
    }
    return false;
  }

  disableScrollPage = (disable) => {
    const elt = document.getElementById("cx-main-body-panel").style;
    if (navigator.platform.indexOf("Win") > -1) {
      if (disable) {
        this.scrollTop('mainPanel');
        this.destroyScroller('mainPanel');
        // add overflow hidden
        elt.overflow = 'hidden';
        elt.height = '100%';
      } else {
        this.reInitScroller('mainPanel');
        // remove overflow hidden
        elt.overflow = '';
        elt.height = '';
      }
      return;
    }
    // else if not windows
    if (disable) {
      // add overflow hidden
      elt.overflow = 'hidden';
      elt.height = '100%';
    } else {
      elt.overflow = '';
      elt.height = '';
    }
  }

  getSelectedIds = (obj) => {
    let res = [];
    if (!(obj && Object.keys(obj).length)) return res;
    for (let id in obj) {
      if (obj[id]) {
        // if true, then add to array
        res.push(id);
      }
    }
    return res;
  }

  getStatusColor = (status) => {
    if (status) status = status.toLowerCase();
    switch (status) {
      case 'in draft':
        return '#9899A6';
      case 'active':
        return '#52c7ab';
      case 'inactive':
        return '#ff858b';
      case 'rejected':
        return '#B44046';
      case 'pending delivery':
        return '#F09855';
      case 'in review':
        return '#F0E655';
      case 'completed':
        return '#55C0F0';
      case 'deleted':
        return '#B44046';
      default:
        return 'transparent';
    }
  }

   splitCamelCase = (str) => str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });


  getSelectObjectByVal = (arr = [], value = '', multiSelect = 0) => {
    let returnValue = null;
    switch (multiSelect) {
      case 'multi':
        let valueArray = [];
        for (let index in arr) {
          for (let valArrIndex in value) {
            if (arr[index]['value'] === value[valArrIndex]) {
              valueArray.push(arr[index]);
            }
          }
        }
        returnValue = valueArray;
        break;
      default:
        for (let index in arr) {
          if (arr[index]['value'] === value) {
            returnValue = arr[index];
            break;
          }
        }
        break;
    }
    return returnValue;
  }
}


export const URL_re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;


export default main;
