import {
    FETCH_MAP_DATA
} from "../constants/appConstant";

import mapDataApi from '../api/mapData';

const api = new mapDataApi();
/*
 * action creators
 */
const setMapData = (data) => ({ type: FETCH_MAP_DATA, payload: data });

export const fetchMapData = () => {
    return (dispatch) => {
        return api.getWorld()
            .then(response => {
                let res = null;
                if(response && response.data)
                    res = response.data;
                dispatch(setMapData(res))
            })
            .catch(error => {
                throw(error);
            });
    };
}