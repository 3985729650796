import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Field, reduxForm, isDirty } from "redux-form";
import { Card, CardHeader, CardBody } from "../../../components/Card/card";
import { Input, Uploader, SelectF } from "../../components/formElts";
import {
  drawerActions,
  adsetActions,
  adActions,
  campaignActions,
} from "../../../actions";
import PerfectScrollbar from "perfect-scrollbar";
import CxLoader from "../../components/cxLoader";
import Confirm from "../../components/confirmationBox";

import { SubmissionError } from 'redux-form'

const formName = "EditAd";
var ps;
class EditAd extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit;
    this.state = {
      loader: this.props.loading,
      msg: null,
      msgAnimate: false,
    };
    this.notify = {
      cancelWarning: {
        title: "Are you sure you want to leave before saving?",
        confirm: async (data) => {
          this.cancelButton(data, true);
          this.refs.confirmRef.removeConfirmation();
        },
        cancel: () => {
          this.refs.confirmRef.removeConfirmation();
        },
        successBtnTxt: "Yes",
        cancelBtnTxt: "Cancel",
      },
    };
  }

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.elementRef);
    } else {
      this.refs.elementRef.style.overflow = "auto";
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loading !== prevProps.loading) {
      // if it is in edit form and the data has been loaded then turn off the loader
      this.setState({ loader: this.props.loading });
    }

    if (
      this.props.errorUpdate &&
      JSON.stringify(this.props.errorUpdate) !==
        JSON.stringify(prevProps.errorUpdate)
    ) {
      this.printMessage(false, "Error! Ad Not updated.", "error", () => {
        this.props.adUpdateError(undefined);
      });
    }

    if (
      this.props.errorPublish &&
      JSON.stringify(this.props.errorPublish) !==
        JSON.stringify(prevProps.errorPublish)
    ) {
      this.printMessage(false, "Error! Ad Not Published.", "error", () => {
        this.props.adPublishError(undefined);
      });
    }
  }

  confirmOptionActions = (type, data) => {
    if (this.refs.confirmRef) {
      return this.refs.confirmRef.confirmOptionActions({
        type: type,
        data: data,
      });
    }
    return false;
  };

  cancelButton = (event, passToEnd = false) => {
    event.preventDefault();
    if (
      !passToEnd &&
      this.props.drawerMode === "edit" &&
      this.props.selectedType.type === "ad" &&
      this.props.isDirtyAd
    ) {
      this.confirmOptionActions("cancelWarning", event);
      return;
    }
    this.props.reset();
    this.props.toggleDrawer(false);
  };

  discardButton = (event) => {
    event.preventDefault();
    this.props.reset();
    this.props.toggleDrawer(false);
    alert("Discarded");
  };

  saveButton = (event) => {
    event.preventDefault();
    this.handleSubmit(this.onSave)();
  };

  publishButton = async (event) => {
    event.preventDefault();
    this.handleSubmit(this.onPublish)();
  };

  onPublish = async (formVals) => {
    await this.onSave(formVals);
    this.setState({ loader: true });
    await this.props.publishAd({ ids: [this.props.ad.id] });
    this.printMessage(false, "Ad Published!");
    this.props.reloadCampaignList();
    this.props.reloadAdsetList();
    this.props.reloadAdList();
  };

  printMessage = (loader, msg, type = "success", callback = () => {}) => {
    const ref = this;
    if (type == "error") msg = <span style={{ color: "#F0555D" }}>{msg}</span>;
    // Update user with the message after save
    this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
      setTimeout(() => {
        ref.setState({ msgAnimate: false }, () => {
          setTimeout(() => {
            // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
            ref.setState({ msg: null });
            callback();
          }, 200);
        });
      }, 2000);
    });
  };

  onSave = async (formVals) => {

    if(!isValidUrl(formVals.clickUrl)){
      throw new SubmissionError({ clickUrl: 'URL Not Valid', _error: 'Update Failed!' });
    }


    const ref = this;
    setTimeout(() => {
      ref.setState({ loader: true });
    }, 1);
    const mapped = { ...formVals };
    if (mapped.language) mapped.language = mapped.language.value;

    if (mapped.advertiserId) mapped.advertiserId = mapped.advertiserId.value;

    mapped.image = mapped.image ? mapped.image : "";

    await this.props.updateAd(mapped);



    // notify user that his changes were updated
    this.printMessage(false, "Changes Saved!");
    // on success reload the tree
    this.props.reloadTree();
    // and reload the three tables
    this.props.reloadCampaignList();
    this.props.reloadAdsetList();
    this.props.reloadAdList();
  };

  renderCreateAd = () => {
    return (
      <Card className="adForm">
        <CardHeader>Ad Info</CardHeader>
        <CardBody>
          <div className="cx-campaign-adset-preview">
            <div style={{ width: "70%" }}>
              <Field
                showMsg={true}
                className={"cx-edit-campaign-input"}
                label={"Name *"}
                name={"name"}
                component={Input}
              />
              <Field
                showMsg={true}
                className={"cx-edit-campaign-input"}
                label={"Title *"}
                name={"title"}
                component={Input}
              />
              <Field
                showMsg={true}
                className={"cx-edit-campaign-input"}
                label={"URL *"}
                name={"clickUrl"}
                placeholder={"Start with https:// or http://"}
                component={Input}
              />

              <Field
                showMsg={true}
                className={"cx-edit-campaign-select cx-edit-campaign-input"}
                placeholder={"-- Choose --"}
                label={"Language *"}
                name={"language"}
                component={SelectF}
                isMulti={false}
                options={this.props.languages}
              />
              <Field
                showMsg={true}
                className={"cx-edit-campaign-select cx-edit-campaign-input"}
                placeholder={"-- Choose --"}
                label={"Advertiser *"}
                name={"advertiserId"}
                component={SelectF}
                isMulti={false}
                options={this.props.advertisers}
              />
              <Field
                showMsg={true}
                adId={this.props.ad ? this.props.ad.id : ""}
                className={"cx-edit-campaign-uploader cx-edit-campaign-input"}
                label={"Image *"}
                name={"image"}
                component={Uploader}
                icon={"fas fa-upload"}
                placeholder={"Upload an image"}
                formName={formName}
              />
            </div>
            <div>
              {this.props.ad &&
                this.props.ad.image &&
                this.props.ad.title &&
                this.adBox(this.props.ad)}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };
  submitButton = (event) => {
    event.preventDefault();
  };

  adBox = (ad) => {
    return (
      <div className="cx-mp-allocation-body">
        <div className="cx-campaign-adslist ps">
          <div className="cx-campaign-adslist-container">
            <div className={"cx-adslist-box"} title={ad.title}>
              <a href={ad.clickUrl} target={"_blank"}>
                <div
                  className={"cx-adslist-box-image"}
                  style={{ backgroundImage: "url(" + ad.image + "=w236-h202)" }}
                ></div>
                <div className={"cx-adlist-box-content"}>
                  <div className={"cx-adlist-box-title"}>
                    <label>Title: </label>&nbsp;&nbsp;
                    <span className={"cx-adlist-box-title-text"}>
                      {ad.title}
                    </span>
                    &nbsp;&nbsp;<i className="fas fa-external-link-alt"></i>
                  </div>
                  <div className={"cx-adlist-box-lang"}>
                    <label>Lang: </label> <span>{ad.language}</span>
                  </div>
                  <div
                    className={"cx-adlist-box-advertiser"}
                    title={ad.advertiserName ? ad.advertiserName : " - "}
                  >
                    <label>Advertiser: </label>&nbsp;&nbsp;
                    <span className={"cx-adlist-box-advertiser-name"}>
                      {ad.advertiserName ? ad.advertiserName : " - "}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Form
          encType="multipart/form-data"
          className={`cx-edit-drawer-form cx-edit-adset-form active`}
          onSubmit={() => false}
        >
          {this.state.loader ? <CxLoader /> : ""}
          <div className={"cx-edit-drawer-container"} ref="elementRef">
            {this.renderCreateAd()}
            <div className={"cx-action-buttons"}>
              <button
                className={"btn cx-cancel-btn pull-left"}
                onClick={(e) => {
                  this.cancelButton(e);
                }}
              >
                Cancel
              </button>
              {this.props.ad && this.props.ad.isPublishable && (
                <button
                  className={"btn cx-focus-btn pull-right"}
                  onClick={(e) => {
                    this.publishButton(e);
                  }}
                >
                  Save & Publish
                </button>
              )}
              <button
                className={"btn cx-discard-btn pull-right"}
                onClick={(e) => {
                  this.saveButton(e);
                }}
              >
                Save Draft
              </button>
              <span
                className={`cx-form-msgs pull-right ${
                  this.state.msgAnimate ? "show" : ""
                }`}
              >
                {this.state.msg}
              </span>
            </div>
            <Confirm ref={"confirmRef"} notify={this.notify} />
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const formAction = state.slidedrawer.formAction;
  const ad = state.ads.ad;
  const langs = [
    { value: "en", label: "English" },
    { value: "ar", label: "Arabic" },
  ];
  const advertisers =
    (state.advertiser && state.advertiser.advertiserOp) || null;
  return {
    drawerMode: state.slidedrawer && state.slidedrawer.drawerMode,
    selectedType: state.slidedrawer && state.slidedrawer.selectedType,
    isDirtyAd: isDirty(formName)(state),
    ad: ad,
    languages: langs,
    formAction: formAction,
    allCategories: state.location.categoriesSelect,
    errorUpdate: (state.ads && state.ads.adUpdateError) || null,
    errorPublish: (state.ads && state.ads.adPublishError) || null,
    advertisers,
    initialValues: ad
      ? {
          id: ad.id,
          name: ad.name,
          title: ad.title,
          clickUrl: ad.clickUrl,
          language: langs
            ? langs.filter((l) => l.value === ad.language)[0]
            : "",
          advertiserId: advertisers
            ? advertisers.filter(
                (advertiser) => advertiser.value === ad.advertiserId
              )[0]
            : "",
          image: ad.image,
        }
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateAd: (data) => dispatch(adActions.updateAd(data)),
  publishAd: (data) => dispatch(adActions.publishAd(data)),
  toggleDrawer: (action) => dispatch(drawerActions.setDrawerVisible(action)),
  reloadTree: () => dispatch(campaignActions.fetchCampaignsTree()),
  reloadCampaignList: (queryStr) =>
    dispatch(campaignActions.fetchAllCampaigns(queryStr)),
  reloadAdsetList: (queryStr) =>
    dispatch(adsetActions.fetchAllAdsets(queryStr)),
  reloadAdList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
  adPublishError: (data) => dispatch(adActions.adPublishError(data)),
  adUpdateError: (data) => dispatch(adActions.adUpdateError(data)),
});

function isValidUrl(urlString) {
  let a  = document.createElement('a');
  a.href = urlString;  
  return (a.host && a.host != window.location.host);
  
  // if (!urlString) return false;
  // try {
  //   new URL(urlString);
  //   return true;
  // } catch (_) {
  //   return false;
  // }
}

function validate(form) {
  const errors = {};
  if (!form.name) {
    errors.name = "Ad name is required";
  }
  return errors;
}

EditAd = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
})(EditAd);

// You have to connect() to any reducers that you wish to connect to yourself
EditAd = connect(mapStateToProps, mapDispatchToProps)(EditAd);

export default EditAd;
