import React, { useState, useRef } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import { useController } from "react-hook-form";

const CXSelect = ({
    control,
    name,
    options,
    defaultDisplay = "Select",
    dislayProp,
    customOnChange = () => {},
    className,
    style,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        rules: { required: true },
    });

    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();

    //instead of passing a single ref you can pass a list of refs to listen for the click outside them
    useOutsideClick(selectRef, () => {
        setIsOpen(false);
    });

    const toggleSelect = () => {
        setIsOpen(!isOpen);
    };

    const selectValue = (value) => {
        if (customOnChange) customOnChange(value);
        onChange(value);
    };
    const getSelectedValueLabel = () => {
        if (!value) return;
        const selection = options.filter((o) => {
            return isActive(o);
        });
        if (selection.length) return selection[0].label;
    };

    const isActive = (o) => {
        if(!value) return;
        if (dislayProp && o.value && o.value[dislayProp] && value[dislayProp]) {
            return o.value[dislayProp] === value[dislayProp];
        } else {
            return value.value ? o.value === value?.value : o.value === value;
        }
    };
    return (
        <div
            className={`cx-select ${isOpen && "cx-select-open"} ${
                className ?? ""
            }`}
            onClick={toggleSelect}
            ref={selectRef}
            style={style}
        >
            <div className="cx-select__trigger">
                <span className="cx-select__placeholder">
                    {getSelectedValueLabel() || defaultDisplay}
                </span>
                <span className="cx-select__arrow"></span>
            </div>

            {isOpen && (
                <ul className="cx-select__options">
                    <span className="cx-select__separator"></span>
                    {options?.map((o, i) => {
                        return (
                            <li
                                style={{ "--i": i }}
                                className={`cx-select__option ${
                                    isActive(o) &&
                                    "cx-select__active-option"
                                }`}
                                key={i}
                                onClick={() => {
                                    selectValue(o?.value);
                                }}
                            >
                                {o?.label}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CXSelect;
