import React, { Component } from "react";
import { connect } from 'react-redux';
import {campaignSelectionActions, tableActions} from '../../../actions';

class TableTabs extends Component {
    constructor(props) {
        super(props);
        this.props.activateTab('review-allocations');
    }

    render() {
        return (
            <div className={"reactive-tabs"}>
                <div
                    id={"allocation-tab"}
                    className={`${(this.props.activeTabList === 'review-allocations') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.activateTab('review-allocations') }}
                >
                    Review Allocations
                </div>
                <div
                    id={"allocated-tab"}
                    className={`${(this.props.activeTabList === 'allocated') ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.props.activateTab('allocated') }}
                >
                    Allocated
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeTabList: state.tabTable && state.tabTable.activeTab,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        activateTab: (tab) => dispatch(tableActions.activateTab(tab)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(TableTabs);