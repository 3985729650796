import React from "react";
import {advertiserActions} from "../../../actions";
import {connect} from "react-redux";
import {Card, CardBody, CardHeader} from "../../../components/Card/card"
import NotificationAlert from "react-notification-alert";
import PixelForm from "./pixelForm";
import PixelScript from "./pixelScript";
import mainhelpers from "../../../helpers/main";
import analytics from "../../../helpers/analytics";
import ReactTable from "react-table";
import Confirm from "../../components/confirmationBox";
import noData from "../../../assets/img/no-data-for-charts.svg"

const NoData = () => {
  return <img className="cx-no-data-svg" style={{width:'100px'}} src={noData}/>
};
const helper = new mainhelpers();

class PixelList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopupOpened: (this.props.isPopupOpened)? this.props.isPopupOpened: false, // flag to open || close popup
            loader: false,
            pixelForAdvertiserId: (this.props.pixelForAdvertiserId)? this.props.pixelForAdvertiserId: false,
        };
        this.handleSubmit = this.props.handleSubmit;
        this.notify = {
            delete: {
                title: 'Are you sure you want to delete?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    await this.props.deletePixel(data.id);
                    this.alertMsg('Pixel successfully deleted', 'tim-icons icon-check-2', 'success');
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Delete',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount() {
        // on open of page fetch the list of the pixels for the popup
        if(this.state.pixelForAdvertiserId){
            this.props.getPixelsList(this.state.pixelForAdvertiserId);
        }
    }

    componentDidUpdate(prevProps){
        // Handle the open and close of the popup
        if(this.props.isPopupOpened != prevProps.isPopupOpened){
            this.setState({isPopupOpened: this.props.isPopupOpened});
        }
        // handle fetch of the pixel list populated in the popup
        if(this.props.pixelForAdvertiserId != prevProps.pixelForAdvertiserId){
            this.setState({pixelForAdvertiserId: this.props.pixelForAdvertiserId});
            // refresh the list
            if(this.props.pixelForAdvertiserId){
                this.props.getPixelsList(this.props.pixelForAdvertiserId);
            }
        }
        if(this.props.pixelUpdateErr != prevProps.pixelUpdateErr){
            this.setState({loader: false});
            this.alertMsg("Error, Could not update pixel. Try again later!");
            this.props.saveErrEvent(undefined, "update");
        }
        if(this.props.pixelCreateErr != prevProps.pixelCreateErr){
            this.setState({loader: false});
            this.alertMsg("Error, Could not create pixel. Try again later!");
            this.props.saveErrEvent(undefined, "create");
        }
        if(this.props.pixelDeleteErr != prevProps.pixelDeleteErr){
            this.setState({loader: false});
            this.alertMsg("Error, Could not delete pixel. Try again later!");
            this.props.saveErrEvent(undefined, "delete");
        }
    }

    closePopover = () => {
        this.setState({requestForm: null});
    }

    requestPixelForm = (e, item = null) => {
        if (e) e.preventDefault();
        const type = (item)? 'update': 'create';
        const id = (item)? item.id : 0;

        analytics.logEvent('Advertiser Pixel' ,'Pixel ' + type);

        const toRender = (
            <PixelForm
                pixelForAdvertiserId={this.state.pixelForAdvertiserId}
                pixelLoaded={item}
                key={type+'-'+id+'-'+'form'}
                formAction={type}
                closePopOver={this.closePopover}
                alertMsg={this.alertMsg}
                refreshList={this.refreshData}
                loader={this.state.loader}
            />
        );

        this.setState({requestForm: {render: toRender, id}});
    }

    tableCols = () => {
        return [
            {
                Header: "Name",
                accessor: "name",
                Cell: ({original}) => {
                    return <span>{original.name}</span>
                }
            },
            {
                Header: "Domain",
                accessor: "domain",
                Cell: ({original}) => {
                    return <span>{original.domain}</span>

                }
            },
            {
                Header: "Created Date",
                accessor: "creationDate",
                Cell: ({original}) => {
                    return <span>{helper.format_date_dd_MM_yyyy(original.creationDate, "/")}</span>
                }
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: ({ original }) => {
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        <a className={"mr-2"} href={'#'} onClick={(e)=>{this.rowActions('edit', original, e)}}>
                            <i className={"fa fa-pencil-alt"} />&nbsp;&nbsp;
                        </a>
                        {(this.state.requestForm && this.state.requestForm.id === original.id)? this.state.requestForm.render: ''}
                        <a className={"mr-2"} href={'#'} onClick={(e)=>{this.rowActions('delete', original, e)}}><i className={"fa fa-trash"} />&nbsp;&nbsp;</a>
                        <a className={"mr-2"} href={'#'} onClick={(e)=>{this.rowActions('script', original, e)}}><i className={"fa fa-code"} /></a>
                        {(this.state.showScript && this.state.showScript.id === original.id)? this.state.showScript.render: ''}
                    </div>;
                }
            },
        ];
    }

    rowActions = (action, obj, event = null) => {
        if(event) event.preventDefault();
        switch(action){
            default:
                break;
            case "edit":
                this.requestPixelForm(event, obj);
                break;
            case 'delete':
                this.deleteAction(obj.id);
                break;
            case 'script':

                this.setState({
                    showScript: {
                        id: obj.id,
                        render: <PixelScript
                            onClose={()=> {this.setState({showScript: null});}}
                            script={obj.script}
                            alertMsg={this.alertMsg}
                        />
                    }
                });
                break;
            // todo script

        }
    }

    deleteAction = (pixelId) => {
        analytics.logEvent('Advertiser Pixel','Pixel delete');
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: 'delete', data: {id: pixelId} });
        }
        return false;
    }

    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }


    cancelButton = (event) => {
        if (event) event.preventDefault();
        this.props.closePopup();
    }

    refreshData= ()=>{
        this.props.getPixelsList(this.state.pixelForAdvertiserId)
    }

    onClickUpdatePixel = (e, item) => {
        console.log('onClickUpdatePixel');
        if (e) e.preventDefault();
        this.setState({formAction: 'update'});
        this.props.setChosenPixel({...item, advertiserId: this.state.pixelForAdvertiserId});
    }

    popupRender = () => {
        return (
            <div className={`cx-pixel-list-popup cx-popup${(this.state.isPopupOpened)? ' open' : ''}`}>
                <div style={{width:'40%'}}>
                    <Card>
                        <CardHeader>
                            Pixels
                            <span className={"close-btn"} onClick={(e)=>{this.cancelButton(e)}}>
                                <i className={"fa fa-times"}></i>
                            </span>
                        </CardHeader>
                        <CardBody>
                            <a href={"#"} onClick={this.requestPixelForm}>
                                <i className={"fa fa-plus"} />&nbsp;&nbsp;Create&nbsp;&nbsp;
                            </a>
                            {(this.state.requestForm && this.state.requestForm.id === 0)? this.state.requestForm.render: ''}
                            <ReactTable
                                noDataText=''
                                NoDataComponent={NoData}
                                defaultSorting={[{ id: 'creationDate', desc: true }]}
                                sortable={false}
                                showPageSizeOptions={false}
                                minRows={0}
                                data={this.props.pixels}
                                resizable={false}
                                columns={this.tableCols()}
                                defaultPageSize={4}
                                showPaginationBottom={false}
                                className="-highlight cx-reactive-table"
                                loading={this.state.loading}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                {this.popupRender()}
                <div className={"cx-notification-alert"}>
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pixels: state.advertiser && state.advertiser.pixels || [],
        pixelUpdateErr: state.advertiser && state.advertiser.pixelUpdateErr || null,
        pixelCreateErr: state.advertiser && state.advertiser.pixelCreateErr || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    deletePixel: (pixelId) => dispatch(advertiserActions.deletePixel({id: pixelId * 1})),
    getPixelsList: (advertiserId) => dispatch(advertiserActions.pixelList({advertiserId: advertiserId * 1})),
    saveErrEvent: (err, type) => dispatch(advertiserActions[type+"PixelErr"](err)), // can be either -> createPixelErr || updatePixelErr
    setChosenPixel: (item) => dispatch(advertiserActions.loadPixel(item)), // can be either -> createPixelErr || updatePixelErr
});

export default connect(mapStateToProps, mapDispatchToProps)(PixelList);