import React, { Component } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

export default class extends Component {

    render() {
        return <ReactBSAlert
            customClass={`cx-notify-component ${this.props.className || ''}`}
            confirmBtnCssClass={'cx-notify-confirm cx-focus-btn'}
            cancelBtnCssClass={'cx-notify-cancel'}
            title={this.props.title}
            onConfirm={(e)=>{this.props.onConfirm(e)}}
            onCancel={(e)=>{this.props.onClose(e)}}
            confirmBtnText={this.props.successBtnTxt}
            cancelBtnText={this.props.cancelBtnTxt}
            showCancel={true}
            allowEscape={true}
            closeOnClickOutside={true}
        />;
    }
}