import React, { Component } from "react";
import { Form, Field, reduxForm, reset, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import { Input, SelectF } from '../../components/formElts';
import {
    popupActions,
    campaignCategoryMappingActions
} from "../../../actions";
import CxLoader from "../../components/cxLoader";
import mainHelper from "../../../helpers/main";
let helpers = new mainHelper();

class CampaignCategoryMappingForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;
        this.state = {
            loader: false,
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     // You can access `this.props` and `this.state` here
    //     // and check them against nextProps and nextState respectively.
    //     // return boolean(false) if you don't want the component to re-render.
    //     return false;
    // }

    onSubmit = (formVals) => {
        this.setState({ loader: true }, async () => {
            const mapped = { ...formVals };
            if (mapped.classMapping)
                mapped.classMapping = mapped.classMapping.value;
                if (mapped.campaignCategory)
                mapped.campaignCategory = mapped.campaignCategory.value;

            if (mapped.classValueList.length) {
                let classValList = [];
                for (let i in mapped.classValueList) {
                    classValList.push(mapped.classValueList[i].value);
                }
                mapped.classValueList = classValList;
            }
            if (this.props.actionType == 'create') {
                await this.props.createForm(mapped);
            } else {
                if (this.props.Item && this.props.Item.id) {
                    mapped.id = this.props.Item.id;
                    await this.props.updateForm(mapped);
                }
            }
            this.setState({ loader: false });
            this.props.reset();
            this.props.togglePopup(false);
            this.props.reloadList();
        });
    }

    cancelButton = (event) => {
        event.preventDefault();
        this.props.reset();
        this.props.removeItem();
        this.props.togglePopup(false);
    }

    submitButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSubmit)();
    }

    render() {
        return (
            <div id={'cx-campaignCategoryMapping-form-container'}>
                <Form className={"cx-campaignCategoryMapping-form-container"} onSubmit={() => (false)}>
                <Field
                        showMsg={true}
                        disabled={this.state.loader}
                        className={"cx-popup-form-layout"}
                        label={"IAB Categories"}
                        name={"campaignCategory"}
                        options={this.props.allCategories}
                        component={SelectF} />
                    <br />

                    <Field
                        showMsg={true}
                        disabled={this.state.loader}
                        className={"cx-popup-form-layout"}
                        label={"Class Mapping"}
                        name={"classMapping"}
                        options={this.props.classList}
                        component={SelectF} />
                    <br />
                    <Field
                        showMsg={true}
                        disabled={this.state.loader}
                        className={"cx-popup-form-layout"}
                        label={"Class Value List"}
                        name={"classValueList"}
                        options={(this.props.classMapping && this.props.classMapping.value) ? this.props.valueList[this.props.classMapping.value] : []}
                        isMulti={true}
                        component={SelectF} />
                    <br />
                  
                    <div className={"cx-action-buttons"}>
                        <button disabled={this.state.loader} className="btn cx-focus-btn" onClick={(e) => { this.submitButton(e) }}>
                            Save
                        </button>
                        {(this.state.loader) ? <CxLoader inPlace={true} /> : ''}
                        <button disabled={this.state.loader} className="btn" onClick={(e) => { this.cancelButton(e) }}>
                            Cancel
                        </button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const itemExist = (state.campaignCategoryMapping && state.campaignCategoryMapping.Item) ? state.campaignCategoryMapping.Item : '';
    const classList = (state.recommendation && state.recommendation.classList) ? state.recommendation.classList : [];
    const valueList = (state.recommendation && state.recommendation.valueList) ? state.recommendation.valueList : [];
    const classMappingInitial = itemExist ? helpers.getSelectObjectByVal(classList, itemExist.classMapping) : null;
    const classMappingForm = formValueSelector('CampaignCategoryMappingForm')(state, 'classMapping');




    const initialValues = (itemExist) ? {
        id: itemExist.id,
        classMapping: classMappingInitial,
        classValueList: (classMappingInitial) ? helpers.getSelectObjectByVal(valueList[itemExist.classMapping], itemExist.classValueList, 'multi') : null,
        campaignCategory: itemExist.campaignCategory ? helpers.getSelectObjectByVal(state.category.categoriesSelect, itemExist.campaignCategory) : [],
    } : null;
    return {
        test: state.testvalue,
        Item: itemExist,
        actionType: (state.campaignCategoryMapping && state.campaignCategoryMapping.actionType) ? state.campaignCategoryMapping.actionType : 'create',
        classList: classList,
        valueList: valueList,
        allCategories: state.category.categoriesSelect,
        classMapping: classMappingForm,
        initialValues,

    }
};

const mapDispatchToProps = (dispatch) => ({
    reloadList: () => dispatch(campaignCategoryMappingActions.getList()),
    removeItem: () => dispatch(campaignCategoryMappingActions.getItemEvent('undefined')),
    createForm: (data) => dispatch(campaignCategoryMappingActions.create(data)),
    updateForm: (data) => dispatch(campaignCategoryMappingActions.update(data)),
    togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
});

function validate(form) {
    const errors = {};
    if (!form.classMapping) {
        errors.classMapping = "Class mapping field is required";
    }
    if (!form.classValueList || form.classValueList.length == 0) {
        errors.classValueList = "class value list field is required";
    }
    if (!form.campaignCategory) {
        errors.campaignCategory = "campaign Category field is required";
    }
    return errors;
}


CampaignCategoryMappingForm = reduxForm({
    validate,
    form: 'CampaignCategoryMappingForm',
    enableReinitialize: true
})(CampaignCategoryMappingForm);

CampaignCategoryMappingForm = connect(
    mapStateToProps, mapDispatchToProps
)(CampaignCategoryMappingForm);

export default CampaignCategoryMappingForm;