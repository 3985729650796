import axios from 'axios';
// import querystring from "querystring";
import auth from "./auth";
import apiUrl from '../helpers/global';
import mainHelper from '../helpers/main';
import querystring from 'querystring';

let helper = new mainHelper();
/* Helper Class
* To make requesting countries and regions more flexible
* */
class audit {

    
    

    constructor(props) {
        this.details = props;
        if (window.location.hostname === 'localhost') {
            this.baseUrl = 'http://localhost:5000';
        }
        this.auth = new auth();
    }

    getList = async (size,page) => {
        const url = apiUrl + `/audit-list`;
        return this.auth.request.post(url,{ data: JSON.stringify({size,page}) });
    } 
  
  

    getItem = async (id) => {
        const url = apiUrl + `/audit-read`;
        return this.auth.request.post(url, { data: JSON.stringify(id) });
    }

   
}

export default new audit();
