import {
  DRAWER_TREE,
  DRAWER_FORM,
  DRAWER_VISIBLE,
  DRAWER_MODE,
  DRAWER_FORM_RESET,
  SET_REPORT_TAB,
  SET_REPORT_SUBTAB,
} from "../constants/appConstant";
import { campaignActions, adsetActions, adActions } from "../actions";
import { adsetAllocationItemEvent } from "./mediaPlannerActions";

/*
 * action creators
 * action can be false that is closed or opened that is true
 */
export const setDrawerVisible = (isDrawerOpened, selectedData) => {
  return {
    type: DRAWER_VISIBLE,
    payload: { isDrawerOpened, selectedData },
  };
};

export const setDrawerTree = (selectedData) => ({
  type: DRAWER_TREE,
  payload: selectedData,
});

export const setDrawerForm = (selectedType, formAction) => ({
  type: DRAWER_FORM,
  payload: selectedType,
  formAction,
});

export const resetSelectedType = (data) => {
  return { type: DRAWER_FORM_RESET, payload: data };
};

// export const setDrawerMode = (mode = 'edit', data) => ({type: DRAWER_MODE, payload: mode, data});
export const setDrawerMode = (mode = "edit") => ({
  type: DRAWER_MODE,
  payload: mode,
});

export const resetDrawerForm = () => {
  return (dispatch) => {
    dispatch(adsetAllocationItemEvent(undefined));

    dispatch(campaignActions.campaignEvent());
    dispatch(adsetActions.adsetEvent());
    dispatch(adActions.adEvent());
  };
};

export const setReportTab = (reportType = "performance") => ({
  type: SET_REPORT_TAB,
  payload: reportType,
});

export const setReportSubtab = (reportType = "clicks") => ({
  type: SET_REPORT_SUBTAB,
  payload: reportType,
});
