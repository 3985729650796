import React, { useState, useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';
import { CXPill } from "./index"
import useOutsideClick from '../hooks/useOutsideClick';


const CXSelectWPills = ({control, options, name, width, startFresh, startOpened, metric}) => {

    const { field: { onChange, value } } = useController({
        name,
        control,
        rules: { required: true },
    });
    const [isSelectOpen, setIsSelectOpen] = useState(startOpened ? true : false);
    const [pills, setPills] = useState(startFresh ? [] : (value || []));
    const [filter, setFilter] = useState("");

    const selectRef = useRef();

    useOutsideClick(selectRef, ()=>{setIsSelectOpen(false)});

    useEffect(() => {
        onChange(pills);
    }, [pills, onChange]);
    
    const alreadyChosen = (option) => {
        return pills.filter(p=>p.value===option.value).length > 0;
    }
    return (
        <div className='cx-select-w-pills-container' style={{width}} ref={selectRef}>
            <div className={`cx-select-w-pills__selectContainer ${isSelectOpen && 'cx-select-w-pills-open'}`}>
                <button
                    type='button'
                    className="cx-btn cx-select-w-pills__selectContainer__trigger"
                    onClick={()=>setIsSelectOpen(!isSelectOpen)}
                >
                    <i className="fa fa-plus"></i>
                </button>
                {
                    isSelectOpen &&
                    <div className="cx-select-w-pills__selectContainer__popup">
                        <i className="fa-sharp fa-solid fa-caret-up"></i>
                        <input type="text" value={filter} onChange={(e)=>{setFilter(e.target.value)}}/>
                        <ul>
                            {
                                options?.filter(o=>((o.label.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())>-1) && !alreadyChosen(o))).map((o, i)=>{
                                    return (
                                        <li key={i} onClick={()=>{setPills([...pills, o])}}>
                                            {o.label}
                                            {
                                                o.value.extra &&
                                                <span title={metric} >{ o.value.extra[metric]}</span>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>

            <div className="cx-select-w-pills__pillsContainer">
                {
                    pills?.map((pill, i)=>{
                        return (
                            <CXPill 
                                text={pill.label}
                                key={i}
                                close={()=>{
                                    setPills(pills.filter(p=>p.value!=pill.value))
                                }}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
}

export default CXSelectWPills;
