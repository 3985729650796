import {
  SET_WIDGET_ACTION_TYPE,
  FETCH_WIDGETS,
  FETCH_WIDGET_ITEM,
  UPDATE_WIDGET,
  UPDATE_ERR_WIDGET,
  CREATE_WIDGET,
  CREATE_ERR_WIDGET,
  DELETE_WIDGET,
  DELETE_ERR_WIDGET,
  FETCH_CLASSES_LIST_ERR,
  FETCH_CLASSES_LIST
} from "../constants/appConstant";

export default function (state = {}, action) {
  switch (action.type) {
    case SET_WIDGET_ACTION_TYPE:
      console.log(action);

      return { ...state, actionType: action.payload };
    case FETCH_WIDGETS:
      console.log("FETCH_WIDGETS");

      return { ...state, List: action.payload };
    case FETCH_WIDGET_ITEM:
      console.log("FETCH_WIDGET_ITEM");

      return { ...state, Item: action.payload };
    case UPDATE_WIDGET:
      console.log("UPDATE_WIDGET");

      return { ...state, update: action.payload };
    case UPDATE_ERR_WIDGET:
      console.log("UPDATE_ERR_WIDGET");

      return { ...state, updateErr: action.payload };
    case CREATE_WIDGET:
      console.log("CREATE_WIDGET");

      return { ...state, create: action.payload };
    case CREATE_ERR_WIDGET:
      console.log("CREATE_ERR_WIDGET");

      return { ...state, createErr: action.payload };
    case DELETE_WIDGET:
      return { ...state, delete: action.payload };
    case DELETE_ERR_WIDGET:
      return { ...state, deleteErr: action.payload };
    case FETCH_CLASSES_LIST:
      return { ...state, classList: action.payload };
    case FETCH_CLASSES_LIST_ERR:
      return { ...state, classListErr: action.payload };
    default:
      return state;
  }
}
