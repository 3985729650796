import React, {Component} from "react";
import {drawerActions, mediaPlannerActions} from "../../../actions";
import {connect} from "react-redux";
import {Card, CardBody} from "../../../components/Card/card";
import mainHelper from "../../../helpers/main";
import AdsetSummary from './adsetSummary';
import AdsList from './adsList';
import AdsetAllocationForm from './adsetAllocationForm';

const helper = new mainHelper();

class AdsetAllocationDrawerContent extends Component {

    /*
    * componentDidMount
    */
    componentDidMount() {
        this.props.setReportTab('adset-summary');
    }


    renderReportTabs = () => {
        return (
            <div className={"reactive-tabs"}>
                <div
                    id={"adset-summary-tab"}
                    className={`${(this.props.activeTab === 'adset-summary') ? 'active' : ''} reactive-tab`}
                    onClick={e => {
                        this.props.setReportTab('adset-summary')
                    }}
                >
                    Adset Summary
                </div>
                <div
                    id={"ads-list-tab"}
                    className={`${(this.props.activeTab === 'ads-list') ? 'active' : ''} reactive-tab`}
                    onClick={e => {
                        this.props.setReportTab('ads-list')
                    }}
                >
                    Ads List
                </div>
                <div
                    id={"allocation-tab"}
                    className={`${(this.props.activeTab === 'allocation') ? 'active' : ''} reactive-tab`}
                    onClick={e => {
                        this.props.setReportTab('allocation')
                    }}
                >
                    Allocation
                </div>
            </div>
        );
    }

    renderContentTabs = () => {
        switch (this.props.activeTab) {
            case 'adset-summary':
                return <AdsetSummary items={this.props.allocationAdsetItem}/>;
            case 'ads-list':
                return <AdsList items={this.props.allocationAdsetItem}/>;
            case 'allocation':
                return <AdsetAllocationForm items={this.props.allocationAdsetItem}/>;
        }
        return false;
    }

    renderReportBody = () => {
        return (
            <div className={"cx-mp-allocation-body"} style={{}}>
                {this.renderContentTabs()}
            </div>
        );
    }

    render() {
        return (
            <div className={"cx-drawer-inner-tabs cx-drawer-allocation-tabs-body"}>
                <Card>
                    <CardBody>
                        {this.renderReportTabs()}
                        {this.renderReportBody()}
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        selectedData: state.slidedrawer && state.slidedrawer.selectedData || null,
        allocationAdsetItem: (state.mediaPlanner && state.mediaPlanner.allocationAdsetItem) || null,
        activeTab: (state.slidedrawer && state.slidedrawer.tab) || null,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setReportTab: (tab) => dispatch(drawerActions.setReportTab(tab)),
        fetchItem: (id) => dispatch(mediaPlannerActions.fetchAdsetAllocationItem(id)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AdsetAllocationDrawerContent);