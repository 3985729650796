import {
    AUTHORS_SUMMARY,
    AUTHORS_PERFORMANCE,
    AUTHORS_TAGS,
    AUTHORS_TABLE,
    AUTHORS_PERFORMANCE_FILTER,
    AUTHORS_PERFORMANCE_FILTERS_SELECTED
} from "../constants/appConstant";
import api from '../api/authors';
import _ from "lodash";

// const api = new authorsApi();

/* For the Summary */
const authorsSummary = (data) => ({ type: AUTHORS_SUMMARY , payload: data});
export const fetchSummary = (filter, dateFilter) => {
    return (dispatch) => {
        return api.authorSummary(filter, dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(authorsSummary(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the Performance Graph */
const performance = (data) => ({ type: AUTHORS_PERFORMANCE , payload: data});
export const fetchPerformance = (params,filter, dateFilter) => {
    return (dispatch) => {
        return api.pageviewsBubbleGraph(params,filter, dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(performance(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the table */
const table = (data) => ({ type: AUTHORS_TABLE , payload: data});
export const fetchTable = (params,filter, dateFilter) => {
    return (dispatch) => {
        return api.authorsByCXImpact(params,filter, dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(table(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the tags graph */
const tags = (data) => ({ type: AUTHORS_TAGS , payload: data});
export const fetchTags = (filter, dateFilter) => {
    return (dispatch) => {
        return api.authorsTaxonomy(filter, dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(tags(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};




const authorFiltersList = (filters) => ({ type: AUTHORS_PERFORMANCE_FILTER, payload: filters });
export const authorFiltersSelected = (filters) => ({ type: AUTHORS_PERFORMANCE_FILTERS_SELECTED, payload: filters });
export const fetchAuthorFilters = () => {
    return (dispatch) => {
        let filterAuthor = {
            options: [],
            value: "author",
            label: "Author",
            type: "select",
            operator: [{
                value: "is",
                label: "is",
                symbol: ":"
            }],
        };        
        return api.getAllAuthors({})
            .then(response => {
                if (response && response.data && response.data.data)
                    filterAuthor.options = response.data.data.map(x => ({ label: x.author, value: x.author }));          
                dispatch(authorFiltersList([filterAuthor]));
            })
            .catch(error => {
                throw (error);
            });
    };
}
export const addAuthorFilter = (addFilter) => {
    return (dispatch, getState) => {
        if (addFilter) {
            let pageFilters;
            if (getState().authorsInsight && getState().authorsInsight.pageFilters && getState().authorsInsight.pageFilters.length) {
                const doesItExistBefore = _.findIndex(getState().authorsInsight.pageFilters, (item) => {
                    return (
                        item.value === addFilter.value &&
                        item.type === addFilter.type &&
                        _.isEqual(item.typeValue, addFilter.typeValue)
                    )
                });
                if (doesItExistBefore < 0) {
                    // added a new filter
                    pageFilters = [...getState().authorsInsight.pageFilters, addFilter];
                } else {
                    // there is a duplicate so nothing to take
                    pageFilters = [...getState().authorsInsight.pageFilters];
                }
            } else {
                // empty
                pageFilters = [addFilter];
            }
            dispatch(authorFiltersSelected(pageFilters));
        }
    };
}
export const removeAuthorFilter = (removeFilter) => {
    return (dispatch, getState) => {
        if (removeFilter) {
            let pageFilters = [];
            if (getState().authorsInsight && getState().authorsInsight.pageFilters && getState().authorsInsight.pageFilters.length) {
                // filter out the one needs to be removed
                pageFilters = getState().authorsInsight.pageFilters.filter((item) => {
                    return !(
                        item.value === removeFilter.value &&
                        item.type === removeFilter.type &&
                        _.isEqual(item.typeValue, removeFilter.typeValue)
                    )
                })
            }
            dispatch(authorFiltersSelected(pageFilters));
        }
    };
}
