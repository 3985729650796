import axios from 'axios';
// import querystring from "querystring";
import auth from "./auth";
import apiUrl from '../helpers/global';
import mainHelper from '../helpers/main';
import querystring from 'querystring';

let helper = new mainHelper();
/* Helper Class
* To make requesting countries and regions more flexible
* */
class recommendation {

    
    

    constructor(props) {
        this.details = props;
        if (window.location.hostname === 'localhost') {
            this.baseUrl = 'http://localhost:5000';
        }
        this.auth = new auth();
    }

    getConfigList = async () => {
        const url = apiUrl + `/recommendation-class-config-list`;
        return this.auth.request.post(url);
    }

    getClassList = async () => {
        const url = apiUrl + `/recommendation-classes-list`;
        return this.auth.request.post(url);
    }

    createConfig = async (data) => {
        const url = apiUrl + `/recommendation-class-config-create`;
        return this.auth.request.post(url, { data: JSON.stringify(data) })
    }

    getConfigItem = async (id) => {
        const url = apiUrl + `/recommendation-class-config-read`;
        return this.auth.request.post(url, { data: JSON.stringify(id) });
    }

    updateConfigItem = async (data) => {
        const url = apiUrl + `/recommendation-class-config-update`;
        return this.auth.request.post(url, { data: JSON.stringify(data) });
    }

    deleteConfig = async (data) => {
        const url = apiUrl + `/recommendation-class-config-delete`;
        return this.auth.request.post(url, { data: JSON.stringify(data) })
    }

    readDefaultFreshnessDays = async () => {
        const url = apiUrl + `/recommendation-freshness-read`;
        return this.auth.request.post(url);
    }

    setDefaultFreshnessDays = async (data) => {
        const url = apiUrl + `/recommendation-freshness-update`;
        return this.auth.request.post(url,{ data: JSON.stringify(data) });
    }
}

export default new recommendation();
