import React from 'react'
import Highcharts from 'highcharts'
import mainHelper from "../../../../helpers/main";
import HighchartsReact from 'highcharts-react-official'
import { useQuery } from 'react-query';
import { getContentGroupingPageviews,getContentGroupingTimeSpent } from "../contentGroupingAnalysisChartsAPI"
import '../contentGroupingAnalysis.scss'
import HC_more from 'highcharts/highcharts-more'
import {Grid} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CxLoader from "../../../components/cxLoader"; //module
HC_more(Highcharts) //init module

let helpers = new mainHelper();
const ContentGroupingPageviews = () => {
const { data, isLoading } = useQuery(['get-content-grouping-pageviews'], getContentGroupingPageviews, {
    refetchOnWindowFocus: false
});

const tsData = useQuery(['get-content-grouping-time-spent'], getContentGroupingTimeSpent,{
    refetchOnWindowFocus: false
})
const getDataBubble = () => {
    if (!data) return [];
    let contentData = {};
    for (let i = 0; i < data.length; i++) {
        const content = data[i].content;
        if (!contentData[content]) {
          contentData[content] = {
            ROA: 0,
            posts_published: 0,
          };
        }
        contentData[content].ROA += data[i].ROA;
        contentData[content].posts_published += data[i].posts_published;
      }
      return contentData;
}

const getData = () => {
    if (!data) return [];

    let content = {}, series = [];
    for (let i in data) {
        let item = data[i],
        item_content = item.content;
        if (!content[item_content]) {
            content[item_content] = [];
          }

          content[item_content].push([
            Date.parse(item.date),
            item.pageviews
          ]);

    }
    for (let j in content){
        series.push({
            yAxis: 0, dataLabels: { enabled: false },
            type: 'spline', name: j, data: content[j], dashStyle: "", marker: { enabled: false, "symbol": "circle" }
        })
    }
    return series;
}
const options1 = {
    "title": { "text": "" },
    "subtitle": { "text": "" },
    "exporting": { "enabled": false },
    "chart": {
        "type": "spline", "polar": false, "backgroundColor": "#32334d", "height": 318, "width": 1000,
    },
    "xAxis": {
        "type": 'datetime',
        "labels": {
            "style": {
                "color": '#aeaeae'
            }
        },
        'minPadding': 0
    },

    "yAxis": [
        {
            "title": { "text": "Pageviews" },
            "labels": {
                "formatter": function () {
                    return helpers.formatNumber(this.value);
                },
                "style": {
                    "color": '#aeaeae'
                }
            },
            "gridLineColor": 'rgba(255,255,255,0.1)'
        }
    ],

    "tooltip": {
        "shared": true,
        "borderWidth": 1,
        "formatter": function () {
            return this.points.reduce(function (s, point) {
                if (point.series.name === "Pageviews") {
                    return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',') + ' <b>' + point.point.cx_impact + '%</b>';
                }
                return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
            }, '<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) + '</div>');
        }
    },
    credits: {
        enabled: false
    },

    "plotOptions": {
        areaspline: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
                lineWidth: 1,
                lineColor: '#666666'
            }
        },

    },
    "legend": {
        "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
            "color": '#fff'
        }
    },

    series: getData()
}

const getDataAC = () => {
    if (!data) return [];

    let content = {}, series = [];
    for (let i in data) {
        let item = data[i],
            item_content = item.content;
        if (!content[item_content]) {
            content[item_content] = [];
        }

        content[item_content].push([
            Date.parse(item.date),
            item.posts_published
        ]);

    }
    for (let j in content){
        series.push({
            yAxis: 0, dataLabels: { enabled: false },
            type: 'spline', name: j, data: content[j], dashStyle: "", marker: { enabled: false, "symbol": "circle" }
        })
    }
    return series;
}
const options3 = {
        "title": { "text": "" },
        "subtitle": { "text": "" },
        "exporting": { "enabled": false },
        "chart": {
            "type": "spline", "polar": false, "backgroundColor": "#32334d", "height": 318, "width": 1000,
        },
        "xAxis": {
            "type": 'datetime',
            "labels": {
                "style": {
                    "color": '#aeaeae'
                }
            },
            'minPadding': 0
        },

        "yAxis": [
            {
                "title": { "text": "Published Posts" },
                "labels": {
                    "formatter": function () {
                        return helpers.formatNumber(this.value);
                    },
                    "style": {
                        "color": '#aeaeae'
                    }
                },
                "gridLineColor": 'rgba(255,255,255,0.1)'
            }
        ],

        "tooltip": {
            "shared": true,
            "borderWidth": 1,
            "formatter": function () {
                return this.points.reduce(function (s, point) {
                    if (point.series.name === "Published Posts") {
                        return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',') + ' <b>' + point.point.cx_impact + '%</b>';
                    }
                    return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
                }, '<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) + '</div>');
            }
        },
        credits: {
            enabled: false
        },

        "plotOptions": {
            areaspline: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            },

        },
        "legend": {
            "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
                "color": '#fff'
            }
        },

        series: getDataAC()
}

let contentData = getDataBubble()
let options2 = {
    "title": { "text": "" },
    "subtitle": { "text": "" },
    "exporting": { "enabled": false },
    chart: {
        type: 'bubble',
        plotBorderWidth: 0,
        zoomType: 'xy',
        backgroundColor: "#32334d",
        width: 1000
    },
    credits: {
        enabled: false
    },
    xAxis: {
        title: { text: "ROA(Return On Article)", style: {
            color: '#fff'
        }},
        gridLineWidth: 1,
        accessibility: {
            rangeDescription: 'Range: 0 to 100.'
        },
        labels: {
            style: {
                color: '#fff'
            }
        }
    },
    tooltip: {
        formatter: function () {
            return this.point.name + '<br>Published Posts: ' + Highcharts.numberFormat(this.point.y, 0) + '<br>ROA: ' + Highcharts.numberFormat(this.point.x, 2);
        }
    },
    yAxis: {
        title: { text: "Published Posts", style: {
            color: '#fff'
        } },
        startOnTick: false,
        endOnTick: false,
        accessibility: {
            rangeDescription: 'Range: 0 to 100.'
        },
        labels: {
            style: {
                color: '#fff'
            }
        }
    },
    legend: {
        layout: "horizontal", "enabled": true, "floating": false, "itemStyle": {
            color: '#fff'
        }
    },
    series: Object.keys(contentData).map(key => ({
        name: key,
        data: [{
            x: parseFloat(contentData[key]['ROA'] ?? 0),
            y: parseFloat(contentData[key]['posts_published'] ?? 0),
            name: key
        }]
    }))

}

const getTSData = () => {
    if (!tsData.data) return [];
    return tsData.data;
}
let row = getTSData();


const TSTable = () => {
    return (
        <TableContainer sx={{width: "700px", margin: 'auto', backgroundColor: '#32334d', border: 1}}
                        component={Paper}>
            <Table sx={{minWidth: 650, color: "#ffffff"}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{color: "#ffffff"}}>Content Group</TableCell>
                        <TableCell sx={{color: "#ffffff"}} align="right">Total Time Spent (min)</TableCell>
                        <TableCell sx={{color: "#ffffff"}} align="right">CX Impact (%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {row.map((row) => (
                        <TableRow
                            key={row.title}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell sx={{color: "#ffffff"}} align="left">{row.contentGroup}</TableCell>
                            <TableCell sx={{color: "#ffffff"}} align="center">{row.timeSpent}</TableCell>
                            <TableCell sx={{color: "#ffffff"}} align="center">{row.cxImpact}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


return (
    <>
        {(tsData.isLoading || tsData.isFetching || data.isLoading || data.isFetching)?
            <CxLoader minHeight="80vh"/> : <Grid container>
                <Grid item md={12} ml={13} mt={5}>
                    <h4>Page Views Count Over Time</h4>
                </Grid>
                <Grid item md={12}>
                    <HighchartsReact highcharts={Highcharts} options={options1}/>
                </Grid>

                <Grid item md={12} ml={13} mt={5}>
                    <h4>Published Posts Count Over Time</h4>
                </Grid>
                <Grid item md={12}>
                    <HighchartsReact highcharts={Highcharts} options={options3}/>
                </Grid>


                <Grid item md={12} ml={13} mt={5}>
                    <h4>ROA Analysis</h4>
                </Grid>
                <Grid item md={12}>
                    <HighchartsReact highcharts={Highcharts} options={options2}/>
                </Grid>

                <Grid item md={12} ml={13} mt={5}>
                    <h4>Time Spent and CX Impact</h4>
                </Grid>
                <Grid item md={12}>
                    <TSTable/>
                </Grid>

            </Grid>
        }
    </>
)

}
export default ContentGroupingPageviews;