import React, { useState, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useDigestReportSettingStore from "../DigestReportSettingStore";
import {
    readDigestReportSetting,
    createDigestReportSetting,
    updateDigestReportSetting,
    refetchList,
} from "../DigestReportSettingAPI";
import CxLoader from "../../components/cxLoader";
import { useForm, useWatch } from "react-hook-form";
import {
    CXInputField,
    CXSelectField,
    CXCheckbox,
    CXRadio,
    CXMultiSelect,
} from "CXComponents";
import { CCard, CCardBody, CCardText } from "@coreui/react";
import { Col, Row } from "reactstrap";
import CXPillList from "./CXPillList";

const DigestReportForm = ({
    data = {},
    itemToLoad,
    publisherList,
    chartsList,
}) => {
    const qc = useQueryClient();

    const actionType = useDigestReportSettingStore((state) => state.actionType);
    const setIsOpen = useDigestReportSettingStore((state) => state.setIsOpen);

    const [activeTab, setActiveTab] = useState("Configuration");

    const create = useMutation((data) => {
        return createDigestReportSetting(data);
    });
    const update = useMutation((data) => {
        return updateDigestReportSetting(data);
    });

    const daysDropDownData = [
        { label: "Monday", value: "monday" },
        { label: "Tuesday", value: "tuesday" },
        { label: "Wednesday", value: "wednesday" },
        { label: "Thursday", value: "thursday" },
        { label: "Friday", value: "friday" },
        { label: "Saturday", value: "saturday" },
        { label: "Sunday", value: "sunday" },
    ];
    const getDefaultValue = (data) => {
        if (actionType === "create") return data;
        const mapped = { ...data };

        mapped.scheduleForm = mapped.schedule.type;
        mapped.scheduledHour = parseInt(mapped.schedule.hour);
        mapped.scheduledDayInWeek = mapped.schedule.day;
        mapped.scheduledDayInMonth = mapped.schedule.day;

        return mapped;
    };

    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        //resolver: yupResolver(schema),
        defaultValues: getDefaultValue(data),
    });

    const scheduleFormRadioButton = useWatch({
        control,
        name: "scheduleForm",
    });

    const onSubmit = async (formValues) => {
        const mapped = { ...formValues };
        mapped.schedule = JSON.stringify({
            type: mapped.scheduleForm,
            day:
                mapped.scheduleForm === "weekly"
                    ? mapped.scheduledDayInWeek + ""
                    : mapped.scheduleForm === "monthly"
                    ? mapped.scheduledDayInMonth + ""
                    : 0,
            hour: mapped.scheduledHour + "",
        });
        mapped.charts = JSON.stringify(mapped.charts);
        mapped.emails = JSON.stringify(mapped.emails);
        console.log(mapped);
        if (actionType === "edit") {
            await update.mutateAsync(mapped);
            qc.invalidateQueries(["digest-report-setting-read", itemToLoad]);
        } else {
            mapped.publisherId = formValues.publisherId.value;
            await create.mutateAsync(mapped);
        }
        refetchList(qc);
        setIsOpen(false);
    };

    const cancelButton = () => {
        setIsOpen(false);
    };

    const doesTabIncludeErrors = (tabName) => {
        switch (tabName) {
            case "Configuration":
                return (
                    errors.publisherId ||
                    errors.mainUrl ||
                    errors.contactUsEmail ||
                    errors.descriptionText ||
                    errors.headingText
                );
            case "Schedule":
                return (
                    errors.scheduledHour ||
                    (scheduleFormRadioButton === "weekly" &&
                        errors.scheduledDayInWeek) ||
                    (scheduleFormRadioButton === "monthly" &&
                        errors.scheduledDayInMonth)
                );
            default:
                return false;
        }
    };

    const emailInputWatch = useWatch({
        control,
        name: "emailInput",
    });
    const emailListWatch = useWatch({
        control,
        name: "emails",
    });

    const addEmailToList = () => {
        if (!emailInputWatch) return;
        if (!emailListWatch) {
            setValue("emails", [emailInputWatch]);
        } else{
            if(emailListWatch.includes(emailInputWatch)) return;
            setValue("emails", [...emailListWatch, emailInputWatch]);
        }
    };

    const removeEmailFromList = (emailToRemove) => {
        setValue(
            "emails",
            emailListWatch.filter((email) => email !== emailToRemove)
        );
    };

    const renderConfigForm = () => {
        return (
            <>
                {actionType === "create" && (
                    <>
                        <CXSelectField
                            {...{
                                control,
                                label: "Publisher *",
                                required: true,
                                name: "publisherId",
                                options: publisherList.map((pub) => ({
                                    value: pub.id,
                                    label: pub.domain,
                                })),
                                defaultDisplay: "Select Publisher",
                            }}
                        />
                        <br />
                    </>
                )}

                <CXInputField
                    label="Digest Report Title"
                    name="title"
                    register={register}
                    errorMsg={errors?.title?.message}
                />
                <br />

                <br />
                <div>
                    <label>Choose What charts you want in your report</label>
                    {
                        <CXMultiSelect
                            {...{
                                control,
                                name: "charts",
                                defaultDisplay: "Select Charts",
                                options: chartsList,
                                style: { width: "100%" },
                            }}
                        />
                    }
                </div>
                <br />
                <div>
                    <div className="emailDigestInputContainer">
                        <CXInputField
                            {...{
                                register,
                                name: "emailInput",
                                placeholder: "Enter Email Address",
                            }}
                        />
                        <button
                            className="cx-btn addEmailBtn"
                            type="button"
                            onClick={addEmailToList}
                        >
                            Add Email
                        </button>
                    </div>
                    <br />
                    <CXPillList
                        {...{
                            name: "emails",
                            control,
                            remove: removeEmailFromList,
                        }}
                    />
                </div>
                <br />
                <CXCheckbox
                    label="Is Active"
                    name="isActive"
                    register={register}
                    errorMsg={errors?.isActive?.message}
                />
                <br />

                <div className={"cx-action-buttons"}>
                    <button className="btn cx-focus-btn">Save</button>
                    {create.isLoading || update.isLoading ? (
                        <CxLoader inPlace={true} />
                    ) : (
                        ""
                    )}
                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };

    const renderScheduleForm = () => (
        <>
            <CXRadio
                label="Send Newsletter:"
                name="scheduleForm"
                className="transform-down-3"
                options={[
                    { label: "Daily", value: "daily" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Monthly", value: "monthly" },
                ]}
                register={register}
                // errorMsg={errors?.readPostIdFromUrl?.message}
            />
            <br />
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "10px",
                }}
            >
                {scheduleFormRadioButton === "weekly" && (
                    <CCard className="coreui-cx-card">
                        <CCardBody>
                            <h4>Week Days</h4>
                            <CCardText style={{ opacity: 0.5 }}>
                                Choose in what day of the week you want to send
                                the newsletter:
                            </CCardText>
                            <CXRadio
                                name="scheduledDayInWeek"
                                className="weekdays-chips-radio"
                                options={daysDropDownData}
                                register={register}
                                errorMsg={errors?.scheduledDayInWeek?.message}
                            />
                        </CCardBody>
                    </CCard>
                )}
                {scheduleFormRadioButton === "monthly" && (
                    <CCard className="coreui-cx-card">
                        <CCardBody>
                            <h4>Month Days</h4>
                            <CCardText style={{ opacity: 0.5 }}>
                                Choose in what day of the month you want to send
                                the newsletter:
                            </CCardText>
                            <CXInputField
                                name="scheduledDayInMonth"
                                placeholder={"From 1 to 31 "}
                                register={register}
                                errorMsg={errors?.scheduledDayInMonth?.message}
                                type="number"
                            />
                        </CCardBody>
                    </CCard>
                )}
                <CCard className="coreui-cx-card">
                    <CCardBody>
                        <h4>Time</h4>
                        <CCardText style={{ opacity: 0.5 }}>
                            Choose at what hour you want to send the newsletter:
                        </CCardText>
                        <CXInputField
                            name="scheduledHour"
                            placeholder="Number From 0 to 23 (0 being 12 midnight, and 23 being 11pm)"
                            register={register}
                            errorMsg={errors?.scheduledHour?.message}
                            type="number"
                        />
                        <br />
                    </CCardBody>
                </CCard>
            </div>
            <br />
            <div className={"cx-action-buttons"}>
                    <button className="btn cx-focus-btn">Save</button>
                    {create.isLoading || update.isLoading ? (
                        <CxLoader inPlace={true} />
                    ) : (
                        ""
                    )}
                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                </div>
        </>
    );

    return (
        <div>
            <Row className={"mx-0 mb-4"}>
                <Col className={"px-0"}>
                    <div className={"reactive-tabs publisher-form-tabs"}>
                        <div
                            className={`reactive-tab ${
                                activeTab === "Configuration" ? "active" : ""
                            } ${
                                doesTabIncludeErrors("Schedule")
                                    ? "reactive-tab__error"
                                    : ""
                            }`}
                            onClick={() => setActiveTab("Configuration")}
                        >
                            <i className={"fa fa-gear"}></i> &nbsp;{" "}
                            <p>Configuration</p>
                        </div>
                        <div
                            className={`reactive-tab ${
                                activeTab === "Schedule" ? "active" : ""
                            } ${
                                doesTabIncludeErrors("Schedule")
                                    ? "reactive-tab__error"
                                    : ""
                            }`}
                            onClick={() => setActiveTab("Schedule")}
                        >
                            <i className={"fa fa-clock"}></i> &nbsp;{" "}
                            <p>Schedule</p>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className={"mx-0"}>
                <Col className={"px-0"}>
                    <form
                        className={"publisher-form-container"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className={`${
                                activeTab === "Configuration" ? "" : "d-none"
                            }`}
                        >
                            {renderConfigForm()}
                        </div>
                        <div
                            className={`${
                                activeTab === "Schedule" ? "" : "d-none"
                            }`}
                        >
                            {renderScheduleForm()}
                        </div>
                    </form>
                </Col>
            </Row>
        </div>
    );
};

const LoadData = (props) => {
    const itemToLoad = useDigestReportSettingStore((state) => state.itemToLoad);
    const actionType = useDigestReportSettingStore((state) => state.actionType);
    const readDigestReportSettingQuery = useQuery(
        ["digest-report-setting-read", itemToLoad],
        () => {
            if (actionType === "edit")
                return readDigestReportSetting(itemToLoad);
        },
        { refetchOnWindowFocus: false }
    );

    return (
        <>
            {readDigestReportSettingQuery.isLoading ? (
                <CxLoader minHeight="80vh" />
            ) : actionType === "edit" ? (
                <DigestReportForm
                    publisherList={props.publisherList}
                    chartsList={props.chartsList}
                    data={readDigestReportSettingQuery.data}
                    itemToLoad={itemToLoad}
                />
            ) : (
                <DigestReportForm
                    publisherList={props.publisherList}
                    chartsList={props.chartsList}
                />
            )}
        </>
    );
};

export default LoadData;
