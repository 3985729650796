import styled from "styled-components";

export const MediaPlannerReviewTargetingStyledModal = styled.div`
  background-color: #32334d;
  #table {
    height: 74vh;
    @media screen and (max-height: 900px) {
      height: 68vh;
    }
    @media screen and (max-height: 900px) {
      height: 67vh;
    }
    @media screen and (max-height: 730px) {
      height: 64vh;
    }
    @media screen and (max-height: 670px) {
      height: 60vh;
    }
    @media screen and (max-height: 575px) {
      height: 55vh;
    }
  }
  input {
    max-width: 150px;
  }
`;
export const MediaPlannerReviewTargetingStyledPopup = styled.div`
  h4 {
    margin-bottom: 5px;
    font-weight: bold;
  }
  h3 {
    margin-bottom: 5px;
  }
  #row-div {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    #row-all {
      margin: 5px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      #row-h4 {
        width: 170px;
      }
      h4 {
        font-size: 14px;
      }
      #row-div {
      }
    }
  }
  #row-body {
    display: flex;
    align-items: center;
  }
  padding: 10px;
  display: flex;

  align-items: center;
  justify-content: center;
  #title {
    padding: 10px 20px;
    position: relative;
    background-color: #4b4c6e;
    font-size: 16px;
    color: #fff;
  }

  #section {
    border-radius: 8px;

    .section {
      max-height: 60vh;
    }
  }
  #action_bottom {
    margin: 10px;
    padding: 6px;
    display: flex;
    button {
      :hover {
        background-color: green;
      }
      :focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;
