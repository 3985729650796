import React from "react";
import { Col, Row } from "reactstrap";
import ActionBtns from "./actionBtns";
import UserTable from "./tableList";
import UserForm from "./popupForm";
import PopupNew from "../../components/popup_new";
import useUserStore from "./UserStore";


const UserList = () => {

    const isPopupOpen = useUserStore((state) => state.isPopupOpen);
    const togglePopup = useUserStore((state) => state.togglePopup);

    return (
        <>
        <PopupNew title={"User"} width={'60%'} isPopupOpen={isPopupOpen} togglePopup={togglePopup}>
            <UserForm />                
        </PopupNew>

        <div className="content">
            <div>
                <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; User</h4>
            </div>
            <div className={"page-reactive-table"}>                    
                <Row className={"cx-action-buttons mx-0"}>
                    <Col className={"px-0"}>
                        <ActionBtns/>
                    </Col>
                </Row>
                <Row className={"mx-0"}>
                    <Col className={"px-0 cx-admediaplanner-review"}>
                        <div id={'cx-user-config'}>
                            <UserTable />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    );
}


export default UserList;