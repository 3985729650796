import ReactGA from 'react-ga';
import mainHelper from "./main";

let helpers = new mainHelper();
class analytics {

    static logEvent(category, action) {
        ReactGA.event({
            category: category,
            action: action,
            label: helpers.getUserDetails()
        });
    }

    static logPageView(title) {
        ReactGA.pageview(title, null, helpers.getUserDetails());
    }
}

export default analytics;