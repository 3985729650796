import auth from "./auth";
import apiUrl from "../helpers/global";

class adsetsApi {
  
  
  constructor() {
    this.auth = new auth();
  }

  getIABList = async () => {
    return this.auth.request.post(apiUrl + "/get-categories", {
      data: JSON.stringify(),
    });
  };

  updateAdsetStatus = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-turn-on-off", {
      data: JSON.stringify({ id: data.id, isTurnedOn: !data.isTurnedOn }),
    });
  };

  getAdset = async (id) => {
    return this.auth.request.post(apiUrl + "/adset-read", {
      data: JSON.stringify({ id }),
    });
  };

  updateAdset = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-update", {
      data: JSON.stringify(data),
    });
  };

  publishAdset = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-publish", {
      data: JSON.stringify(data),
    });
  };

  deleteAdset = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-delete", {
      data: JSON.stringify(data),
    });
  };

  duplicateAdset = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-duplicate", {
      data: JSON.stringify(data),
    });
  };

  quickCreateAdset = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-quick-create", {
      data: JSON.stringify(data),
    });
  };

  getAdsetList = async (queryStr) => {
    return this.auth.request.post(apiUrl + `/adset-list`);
  };

  adsetReportTotals = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-performance-totals", {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp,
      }),
    });
  };

  adsetReportPerformance = async (data) => {
    return this.auth.request.post(apiUrl + "/adset-performance-by-day", {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp,
      }),
    });
  };

  adsetReportLocation = async (data) => {
    return this.auth.request.post(
      apiUrl + "/adset-performance-by-location",
      {
        data: JSON.stringify({
          id: data.id,
          fromTimestamp: data.fromTimestamp,
          toTimestamp: data.toTimestamp,
        }),
      }
    );
  };

  adsetReportDevices = async (data) => {
    return this.auth.request.post(
      apiUrl + "/adset-performance-by-device",
      {
        data: JSON.stringify({
          id: data.id,
          fromTimestamp: data.fromTimestamp,
          toTimestamp: data.toTimestamp,
        }),
      }
    );
  };
}

export default adsetsApi;
