import React, { Component } from "react";
import { Form, Field, reduxForm, reset, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import { Input, SelectF, Checkbox } from '../../components/formElts';
import {
    popupActions,
    roleActions
} from "../../../actions";
import CxLoader from "../../components/cxLoader";
import mainHelper from "../../../helpers/main";
let helpers = new mainHelper();

class RoleForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;
        this.state = {
            loader: false,
        }
    }

    onSubmit = (formVals) => {
        this.setState({ loader: true }, async () => {
            const mapped = { ...formVals };
            if (mapped.organization)
                mapped.organizationId = mapped.organization.value;

            if (mapped.permissions) {
                mapped.permissions = mapped.permissions.map(function (item) {
                    return item.value;
                });
            }

            if (this.props.actionType == 'create') {
                await this.props.createForm(mapped);
            } else {
                if (this.props.item && this.props.item.id) {
                    mapped.id = this.props.item.id;
                    await this.props.updateForm(mapped);
                }
            }
            this.setState({ loader: false });
            this.props.reset();
            this.props.togglePopup(false);
            this.props.reloadList();
        });
    }

    cancelButton = (event) => {
        event.preventDefault();
        this.props.reset();
        this.props.removeItem();
        this.props.togglePopup(false);
    }

    submitButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSubmit)();
    }

    render() {
        return (
            <div id={'cx-role-config-form-container'}>
                <Form className={"cx-recommendation-config-form-container"} onSubmit={() => (false)}>
                    <Field
                        showMsg={true}
                        disabled={this.state.loader}
                        className={"cx-popup-form-layout"}
                        label={"Permissions"}
                        name={"permissions"}
                        isMulti={true}
                        options={this.props.allPermissions}
                        component={SelectF} />
                    <br />
                    <Field showMsg={true} disabled={this.state.loader} className={"cx-popup-form-layout"} type={'text'} label={"Role"} name={"name"} component={Input} />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={"cx-action-buttons"}>
                        <button disabled={this.state.loader} className="btn cx-focus-btn" onClick={(e) => { this.submitButton(e) }}>
                            Save
                        </button>
                        {(this.state.loader) ? <CxLoader inPlace={true} /> : ''}
                        <button disabled={this.state.loader} className="btn" onClick={(e) => { this.cancelButton(e) }}>
                            Cancel
                        </button>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const allPermissions = (state.role && state.role.Permissions) ?
        state.role.Permissions.map(function (item) {
            return { value: item, label: item };
        }) : [];
    const itemExist = (state.role && state.role.Item) ? state.role.Item : '';
    const initialValues = (itemExist) ? {
        id: itemExist.id,
        name: itemExist.name,
        permissions: itemExist.permissions ? helpers.getSelectObjectByVal(allPermissions, itemExist.permissions, 'multi') : [],
    } : null;

    return {
        item: itemExist,
        allPermissions: allPermissions,
        actionType: (state.role && state.role.actionType) ? state.role.actionType : 'create',
        initialValues,
    }
};

const mapDispatchToProps = (dispatch) => ({
    reloadList: () => dispatch(roleActions.getList()),
    removeItem: () => dispatch(roleActions.getItemEvent('undefined')),
    createForm: (data) => dispatch(roleActions.create(data)),
    updateForm: (data) => dispatch(roleActions.update(data)),
    togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
});

function validate(form) {
    const errors = {};

    if (!form.name) {
        errors.name = "Name field is required";
    }

    return errors;
}


RoleForm = reduxForm({
    validate,
    form: 'RoleForm',
    enableReinitialize: true
})(RoleForm);

RoleForm = connect(
    mapStateToProps, mapDispatchToProps
)(RoleForm);

export default RoleForm;