import React, {useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {CXPill} from "../../../CXComponents"


const CXPillList = ({name, control, displayProp, remove}) => {

    const { field: { value }} = useController({
        name,
        control,
        rules: { required: true },
    });
    const [list, setList] = useState(Array.isArray(value) ? value : [value]);

    useEffect(() => {
        setList(value)
    }, [value]);

    return (
        <div className='segmentation-dimension-pill-container'>
            {
                typeof list =="object" &&
                list.map((l, i)=>{
                        return <CXPill
                            text={(typeof l =="object" && displayProp) ? l[displayProp] : l}
                            key={i}
                            close={()=>remove(l)}
                        />
                    }
                )
            }
        </div>
    );
}

export default CXPillList;
