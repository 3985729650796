import api, {utitlityApi} from "../../api/articleBoost";


// CRUD APIs
export const getList = async () => {
    return api.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const readArticleBoost = async (id) => {
    return api.read(id)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const createArticleBoost = async (data) => {
    return api.create(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const updateArticleBoost = async (data) => {
    return api.update(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const deleteArticleBoost = async (id) => {
    return api.delete({ids:[id]})
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}


// utility
export const refetchList = (qc) => {
    return qc.invalidateQueries(["article-boosting-list"]);
}

export const getPublishers = async () => {
    return utitlityApi.getPublishers()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const loadPublisher = async () => {
    return utitlityApi.getPublisherByOrganization()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
};

export const getWidgets = async () => {
    return utitlityApi.getWidgets()
        .then(response => {
            if(response && response.data && response.data.data)
                return response.data.data
        })
        .catch(error => {
            throw (error)
        })
}