import axios from "axios";
import querystring from "querystring";
import auth from "./auth";
import apiUrl from "../helpers/global";
import mainHelper from "../helpers/main";

let helper = new mainHelper();

/* Helper Class
 * To request mediaPlanner details
 * */
class MediaPlanner {
  // baseUrl = 'https://cognativex-campaign-api.appspot.com';
  details = {};
  
  

  constructor(props) {
    this.details = props;
    this.auth = new auth();
    this.apiUrl = apiUrl;
  }

  /* Helper method
   * requests the revenue graph's details
   * */
  getRevenueGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-revenue-graph");
  };

  getPopulationGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-population-graph");
  };

  /* Helper method
   * requests the capacity graph's details
   * */
  getNetworkStatusPublishersCampaignsGraphDetails = async () => {
    return await axios.get(
      this.apiUrl + "/mp-network-status-publishers-vs-campaigns-graph"
    );
  };

  /* Helper method
   * requests the capacity graph's details
   * */
  getNetworkStatusRevenueGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-network-status-revenue-graph");
  };

  /* Helper method
   * requests the network graph's details
   * */
  getNetworkStatusViewsGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-network-status-views-graph");
  };

  /* Helper method
   * requests the network graph's details
   * */
  getNetworkStatusPopulationGraphDetails = async () => {
    return await axios.get(
      this.apiUrl + "/mp-network-status-population-graph"
    );
  };

  /* Helper method
   * requests the clicks graph's details
   * */
  getCampaignStatusClicksGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-campaign-status-clicks-graph");
  };

  /* Helper method
   * requests the clicks graph's details
   * */
  getCampaignStatusBudgetGraphDetails = async () => {
    return await axios.get(this.apiUrl + "/mp-campaign-status-budget-graph");
  };

  /* Helper method
   * requests the clicks graph's details
   * */
  getCampaignStatusGraphDetails = async (cid) => {
    let queryString = "?";
    if (cid === 0) {
      queryString += "total=true";
    } else {
      queryString += "cid=" + cid;
    }
    return await axios.get(
      this.apiUrl + "/mp-campaign-status-graph" + queryString
    );
  };

  /* Helper method
   * requests the list of campaigns that needs approval
   * */
  getCampaignsPendingApproval = async () => {
    return this.auth.request.post(
      apiUrl +
        `/mp-campaign-list?status=pending-approval&organization=${helper.getInfo(
          "organization"
        )}`
    ); //
  };
  getCampaignsActive = async () => {
    return this.auth.request.post(
      apiUrl +
        `/mp-campaign-list?status=active&organization=${helper.getInfo(
          "organization"
        )}`
    ); //
  };
  getCampaignDetails = async (id) => {
    return this.auth.request.post(
      apiUrl +
        `/mp-campaign-details?organization=${helper.getInfo("organization")}`,
      querystring.stringify({ data: JSON.stringify({ campaignId: id }) })
    ); //pending-approval
  };
  /* Helper method
   * requests the list of publishers for a campaign by its id
   * */
  getMediaPlannerConfig = async (campaignId) => {
    return this.auth.request.post(
      apiUrl +
        `/mp-config-load?organization=${helper.getInfo(
          "organization"
        )}&campaignId=` +
        campaignId
    ); //pending-approval
  };
  postApproveCampaign = async (campaignId) => {
    return this.auth.request.post(
      apiUrl +
        `/mp-campaign-approve?organization=${helper.getInfo(
          "organization"
        )}&campaignId=` +
        campaignId
    ); //pending-approval
  };
  /* Helper method
   * requests the list of campaigns that needs approval
   * */

  getPublishersList = async (id) => {
    console.log(id);
    const json = JSON.parse(localStorage.getItem("new_token"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + json["token"];

    return await axios.post(
      `${this.apiUrl}/mp-publisher-list?organization={"id":${id}}`
    );
  };

  getAdsetAllocations = async (data) => {
    return this.auth.request.post(apiUrl + `/allocate-adset-list`, {
      data: JSON.stringify(data),
    });
  };
  getAdsetAllocationItem = async (id) => {
    return this.auth.request.post(apiUrl + `/adset-read-allocation`, {
      data: JSON.stringify({ id }),
    });
  };
  updateAdsetAllocations = async (data) => {
    return this.auth.request.post(apiUrl + `/adset-update-allocation`, {
      data: JSON.stringify(data),
    });
  };

  getAdsReviewList = async (data) => {
    return this.auth.request.post(apiUrl + `/review-ad-list`, {
      data: JSON.stringify(data),
    });
  };

  acceptAdsRevision = async (data) => {
    return this.auth.request.post(apiUrl + `/ad-accept`, {
      data: JSON.stringify(data),
    });
  };

  rejectAdsRevision = async (data) => {
    return this.auth.request.post(apiUrl + `/ad-reject`, {
      data: JSON.stringify(data),
    });
  };

  /* Helper method
   * requests the list of campaigns that needs approval
   * */
  getCampaignList = async () => {
    return this.auth.request.post(
      apiUrl +
        `/mediaplanner-campaign-list?organization=${helper.getInfo(
          "organization"
        )}`
    );
  };

  /* Helper method
   *  To reject a campaign
   * */
  postCampaignReject = async (data) => {
    // data should have data = {campaignId: 123}
    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return await axios.post(
      this.apiUrl + "/mp-reject-campaign",
      querystring.stringify({ data: JSON.stringify(data) }),
      config
    );
  };

  /* Helper method
   *  To reschedule a campaign
   * */
  postCampaignReschedule = async (data) => {
    // data should have data = {campaign_id: 123}
    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return await axios.post(
      this.apiUrl + "/mp-reschedule-campaign",
      querystring.stringify({ data: JSON.stringify(data) }),
      config
    );
  };

  /* Helper method
   *  accepts and saves a campaigns configuration regarding the publishers allocations
   * */
  postCampaignPublishers = async (data, campaignId) => {
    let config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return this.auth.request.post(
      apiUrl +
        `/mp-config-update?organization=${helper.getInfo(
          "organization"
        )}&campaignId=` +
        campaignId,
      querystring.stringify({ data: JSON.stringify(data) }),
      config
    );
  };
}

export default MediaPlanner;
