import api from "../../api/survey";


export const getSurveys = async () => {
    return api.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const readSurvey = async (id) => {
    return api.getItem(id)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getDimensionsList = async () => {
    return api.getDimensionsList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const createSurvey = async (data) => {
    return api.create(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const updateSurvey = async (data) => {
    return api.update(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const deleteSurvey = async (id) => {
    return api.delete({ids:[id]})
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getUserCount = async (data) => {
    return api.query({dimensions: data})
        .then(response => {
            if (response && response.data)
                return response.data;
            else return "error"
        })
        .catch(error => {
            throw (error);
        });
}





