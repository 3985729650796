import React from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import Tooltip from "../../../components/Tooltips/Tooltip";
import mainHelper from "../../../helpers/main";
import PerfectScrollbar from "perfect-scrollbar";
import { classificationActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";

var ps;
let helpers = new mainHelper();

class ClassesSummary extends React.Component {
  constructor(props) {
    super(props);
    this.setClassTitle();
    this.initial = {
      top_recommmended_class: "No data",
    };
  }
  setClassTitle = () => {
    this.classTitle = helpers.capitalizeFirstLetter(this.props.classKey);
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.elementRef);
    } else {
      this.refs.elementRef.style.overflow = "auto";
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.classMapping, prevProps.classMapping) ||
      !_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      (this.props.dateFilter &&
        !_.isEqual(this.props.dateFilter, prevProps.dateFilter))
    ) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(
        {
          orderBy: "timestamp",
          sort: "DESC",
          classMapping: this.props.classMapping,
        },
        this.props.pageFilters,
        this.props.dateFilter
      );
      this.setClassTitle();
      this.setState({ loader: false });
    }
  };

  componentWillMount = () => {
    this.fetchData();
  };

  render() {
    const { summary } = this.props;
    return (
      <Card style={{ marginBottom: "30px", overflow: "hidden" }}>
        <div
          className="sidebar-wrapper"
          ref="elementRef"
          style={{ height: "435px" }}
        >
          <CardBody className={"mb-0"}>
            <Row className={"justify-content-between mx-0 mb-2"}>
              <CardTitle>
                <span className="cx-pink cx-white-color">
                  {this.classTitle}
                </span>{" "}
                Summary
              </CardTitle>
              <Tooltip
                text={
                  "This card summarises the performance of your top tags in the recommendation engine for your selected classification."
                }
              ></Tooltip>
            </Row>
            {this.state.loader ? <CxLoader /> : ""}
            <>
              {!summary ? (
                "No Data"
              ) : (
                <Row>
                  <Col>
                    <div className="card-description">
                      <div className="mb-2">
                        <Tooltip text={helpers.getFilterTooltip()}>
                          <a href="/#" onClick={(e) => e.preventDefault()}>
                            &nbsp;For the selected filters
                          </a>
                        </Tooltip>
                        <span>
                          &nbsp;during the
                          <Tooltip
                            text=""
                            // {this.props.date.startDate + '  -  ' + this.props.date.endDate}
                          >
                            <a href="/#" onClick={(e) => e.preventDefault()}>
                              &nbsp;selected period
                            </a>
                          </Tooltip>
                          .
                        </span>
                      </div>
                      <div style={{ padding: "0px 20px 10px 20px" }}>
                        {summary.top_recommmended_class ? (
                          <>
                            <div>
                              <i className={"fa fa-user"}></i>&nbsp; &nbsp;
                              <span
                                className="text-success font-weight-500"
                                style={{ fontSize: "1.3rem" }}
                              >
                                {summary.top_recommmended_class}
                              </span>
                              &nbsp;&nbsp;is the highest performing tag in the{" "}
                              <span className="cx-pink">{this.classTitle}</span>{" "}
                              classification. This tag appeared &nbsp;
                              <Tooltip
                                text={
                                  helpers.formatCXNumber(
                                    summary.top_recommmended_class_impressions
                                  ) + " impression"
                                }
                              >
                                <span
                                  className="text-warning"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {helpers.formatDecimal(
                                    summary.top_recommmended_class_impressions_percentage
                                  ) + "%"}
                                </span>
                              </Tooltip>{" "}
                              &nbsp; of the articles recommended.
                            </div>
                            <hr
                              style={{
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {summary.top_clicked_class ? (
                          <>
                            <div>
                              <i className={"fa fa-user"}></i>&nbsp; &nbsp;
                              <span
                                className="text-success font-weight-500"
                                style={{ fontSize: "1.3rem" }}
                              >
                                {summary.top_clicked_class}
                              </span>
                              &nbsp;&nbsp;received{" "}
                              <Tooltip
                                text={
                                  helpers.formatCXNumber(
                                    summary.top_clicked_class_clicks
                                  ) + " clicks"
                                }
                              >
                                <span
                                  className="text-warning"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {helpers.formatDecimal(
                                    summary.top_clicked_class_clicks_percentage
                                  ) + "%"}
                                </span>
                              </Tooltip>{" "}
                              &nbsp; of the clicks on recommended articles.
                            </div>
                            <hr
                              style={{
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {summary.top_engaged_class ? (
                          <>
                            <div>
                              <Tooltip
                                text={
                                  helpers.formatDecimal(
                                    summary.total_engagement_time / 3600
                                  ) + " hours"
                                }
                              >
                                <span
                                  className="text-warning"
                                  style={{ fontSize: "1.3rem" }}
                                >
                                  {helpers.formatDecimal(
                                    summary.top_engaged_class_time_percentage
                                  ) + "%"}
                                </span>
                              </Tooltip>{" "}
                              &nbsp; of the time generated by consumed CX
                              recommendations were brought by &nbsp;
                              <span
                                className="text-success font-weight-500"
                                style={{ fontSize: "1.3rem" }}
                              >
                                {summary.top_engaged_class}
                              </span>
                              &nbsp;&nbsp;is the{" "}
                              <span className="cx-pink">{this.classTitle}</span>
                            </div>
                            <hr
                              style={{
                                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <Row className={" mx-0"}>
                        <Col className={"px-2 "} lg={6}>
                          <div className={"px-2 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip
                                text={`Number of "${this.classTitle}" recommended`}
                              >
                                <span>
                                  {" "}
                                  Recommeded{" "}
                                  <span className="cx-pink">
                                    {this.classTitle}
                                  </span>{" "}
                                </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>
                              {summary.recommended_classes}
                            </p>
                          </div>
                        </Col>
                        <Col className={"px-2 "} lg={6}>
                          <div className={"px-2 py-2 cx-mini-boxes"}>
                            <h5 className={"mb-1"}>
                              <Tooltip
                                text={`Number of "${this.classTitle}" clicked on by users`}
                              >
                                <span>
                                  {" "}
                                  Clicked{" "}
                                  <span className="cx-pink">
                                    {this.classTitle}
                                  </span>
                                </span>
                              </Tooltip>
                            </h5>
                            <p className={"mb-0"}>{summary.clicked_classes}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </>
            {/*</div>*/}
          </CardBody>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: (state.filter && state.filter.date) || null,
    pageFilters:
      (state.classificationInsight &&
        state.classificationInsight.pageFilters) ||
      null,
    summary:
      (state.classificationInsight && state.classificationInsight.summary) ||
      null,
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params, filter, datefilter) =>
      dispatch(classificationActions.fetchSummary(params, filter, datefilter)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(ClassesSummary);
