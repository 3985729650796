import React from 'react';
import moment from 'moment';
import DatetimeRangePicker from "../../components/rangeDate/rangeDate";

import {
  Button
} from 'reactstrap';

class CXDateRangePicker extends React.Component {

  constructor(props) {
    super(props);
    this.handleEvent = this.handleEvent.bind(this);
    this.state = {
      startDate: (this.props.startDate)? this.props.startDate : (typeof this.props.rangesType === 'undefined' || this.props.rangesType === 'Past')? moment().subtract(6, 'days'): moment(),
      endDate: (this.props.endDate)? this.props.endDate :  (typeof this.props.rangesType === 'undefined' || this.props.rangesType === 'Past')? moment(): moment().add(6, 'days'),
      ranges: (typeof this.props.ranges === "undefined" || this.props.ranges === true)?
       ((typeof this.props.rangesType === 'undefined' || this.props.rangesType === 'Past')? {
         'Today': [moment(), moment().add(1, 'days')],
         'Yesterday': [moment().subtract(1, 'days'), moment()],
         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
         'This Month': [moment().startOf('month'), moment().endOf('month')],
         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
       }:
       {
         'Today': [moment(), moment()],
         'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
         'Next 7 Days': [moment().add(1, 'days'), moment().add(7, 'days')],
         'Next 30 Days': [moment().add(1, 'days'), moment().add(30, 'days')],
         'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
       })
        : null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.startDate !== this.props.startDate) {
      this.setState({startDate: this.props.startDate});
    }
    if (prevProps.endDate !== this.props.endDate) {
      this.setState({endDate: this.props.endDate});
    }
  }

  handleEvent(event, picker) {
    let date = {
      startDate: picker.startDate,
      endDate: picker.endDate,
    };

    this.setState(date);
    if(this.props.dateChanged){
      let startDate = date.startDate._d;
      let endDate = date.endDate._d;
      let startDateStr = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate();
      let endDateStr = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate();
      this.props.dateChanged(startDateStr, endDateStr);
    }
  }

  render() {
    let start = this.state.startDate.format('MMM D, YYYY');
    let end = this.state.endDate.format('MMM D, YYYY');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }

    let buttonStyle = { width: '100%' };

    return (
       <DatetimeRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            ranges={this.state.ranges}
            onApply={this.handleEvent}
            showDropdowns
            minDate={(this.props.minToday)? moment() : false}
          >
            <Button className="d-flex justify-content-between selected-date-range-btn" style={buttonStyle}>
              <div className={"selected-date-range-btn-icon d-flex"}>
                <i className="fa fa-calendar"/> &nbsp; {label}
              </div>
              <div>
                &nbsp;&nbsp;<i className="fa fa-caret-down"/>
              </div>
            </Button>
          </DatetimeRangePicker>
    );
  }

}

export default CXDateRangePicker;
