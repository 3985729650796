// import axios from 'axios';
import auth from "./auth";
import mainHelper from '../helpers/main';
import apiUrl from "../helpers/global";
import filterHelper from "../components/FilterBar/filterHelper"

let helper = new mainHelper();

/* Helper Class
* To make requesting countries and regions more flexible
* */
class Classes {

  
  

  constructor(props) {
    this.details = props;
    this.auth = new auth();
  }

  // getFilterSchemaForApi(filters) {
  //   return filters ? Object.assign({}, ...filters.map(x => ({ [x.value]: x.typeValue }))) : {};
  // }

  getFullParams(filters, dateFilter, init) {
    console.log(dateFilter)
    if (!init)
      init = {};
    init["fromTimestamp"] = dateFilter.start;
    init["toTimestamp"] = dateFilter.end;
    init["filters"] = filterHelper.getFilterSchemaForApi(filters);

    return {
      data: JSON.stringify(init)
    };
  }

  getClassTypes = async (data) => {
    return this.auth.request.post(apiUrl + `/classes?organization`, { data: JSON.stringify({}) });
  }

  // authors page
  classesSummary = async (params,filters,datefilter) => {
    var init = this.getFullParams(filters, datefilter, params);
    return this.auth.request.post(apiUrl + '/classes-cx-impact', init);
  }

  pageviewsBubbleGraph = async (params,filters, datefilter) => {
    var init = this.getFullParams(filters, datefilter, params);

    return  this.auth.request.post(apiUrl + `/classes-performance`, init);
  }

  getAllClasses = async (classmapping) => {
    return this.auth.request.post(apiUrl + '/classes-by-cx-pageviews-impact', {
      data: JSON.stringify({classMapping:classmapping ,"orderBy": "cx_pageviews", "sort": "desc", "size": 30, "page": 1})
    });
  }

  classesByCXImpact = async (params,filters,datefilter) => {
    var init = this.getFullParams(filters, datefilter, params);
    return  this.auth.request.post(apiUrl + '/classes-by-cx-pageviews-impact', init);
  }

  classesTaxonomy = async (params,filters,datefilter) => {
    // let newFilters = [];
    // for(let i in filters){
    //   if(filters[i].value == 'tag')
    //       filters[i].value = 'tag_name';
    //   newFilters.push(filters[i])
    // }
    var init = this.getFullParams(filters, datefilter, params);

    return this.auth.request.post(apiUrl + '/classes-taxonomy', init);
  }

}

export default new Classes();
