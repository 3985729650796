import React from 'react';
import useContentGroupingStore from '../contentGroupingStore';
import {useQuery} from 'react-query';
import {ES_searchKeywords, ES_searchClasses} from "../contentGroupingAPI";
import { CXPill } from '../../../CXComponents';
import CxLoader from '../../components/cxLoader';

const Table = ({control}) => {

    const filter = useContentGroupingStore(state=>state.filter);
    const addKeyword = useContentGroupingStore(state=>state.addKeyword);
    const selectedKeywords = useContentGroupingStore(state=>state.selectedKeywords);

    const ES_searchKeywordsQuery = useQuery(['searchKeywords', filter], ()=>{
        return ES_searchKeywords(filter.domainFilter?.label || "", filter.classFilter?.label || "", filter.textFilter || "");
    }, {refetchOnWindowFocus: false});
    const ES_searchClassesQuery = useQuery(['searchclassesvalue', filter], ()=>{
        return ES_searchClasses(filter.domainFilter?.label || "", filter.classFilter?.label || "", filter.textFilter || "");
    }, {refetchOnWindowFocus: false});



    const selectKeyword = (keyword, type) => { // the type should be in the original object later returned from the API, ask Fahes to do it
        keyword.type = type;
        addKeyword(keyword);
    }

    const isKeywordSelected = (keyword) => {
        if(!selectedKeywords) return false;
        for(let i = 0 ; i < selectedKeywords.length ; i++){
        if(
                keyword.label === selectedKeywords[i].label &&
                keyword.value === selectedKeywords[i].value
            ) return true;
        }
        return false;
    }


    const getPillHSLHeatColor = (baseHue, maxVal, value) => {
        const lightingRange = [20, 50];
        // output = output_start + ((output_end - output_start) / (input_end - input_start)) * (input - input_start)
        const hue = lightingRange[0] + ((lightingRange[1] - lightingRange[0]) / (maxVal - 0)) * (value - 0);
        return `hsl(${baseHue}, 100%, ${100-hue}%)`;
    }

    return (
        <div className="pool-results-container">
            {
                (ES_searchKeywordsQuery.isLoading || ES_searchKeywordsQuery.isFetching
                    || ES_searchClassesQuery.isLoading || ES_searchClassesQuery.isFetching)
                ? <CxLoader />
                : <>
                    {
                        (ES_searchKeywordsQuery.data.length || ES_searchClassesQuery.data.length)
                        ? <>
                            <div className='pool-results-container__pills-pool'>
                    {
                        ES_searchKeywordsQuery?.data?.map((keyword, i)=>{
                            const isSelected = isKeywordSelected(keyword);
                            return (
                                <CXPill
                                    key={i}
                                    text={keyword.label}
                                    background={getPillHSLHeatColor(360, ES_searchKeywordsQuery?.data[0].extra?.metric?.value, keyword.extra?.metric?.value)}
                                    onClick={()=>selectKeyword(keyword, "keyword")}
                                    disabled={isSelected}
                                    title={`${keyword.extra?.metric?.label}: ${keyword.extra?.metric?.value}`}
                                />
                            )
                        })
                    }
                    </div>
                    <div className='pool-results-container__pills-pool'>
                    {
                        ES_searchClassesQuery?.data?.map((class_, i)=>{
                            const isSelected = isKeywordSelected(class_);
                            return (
                                <CXPill
                                    key={i}
                                    text={class_.label}
                                    background={getPillHSLHeatColor(220, ES_searchClassesQuery?.data[0].extra?.metric?.value, class_.extra?.metric?.value)}
                                    onClick={()=>selectKeyword(class_, "class")}
                                    disabled={isSelected}
                                />
                            )
                        })  
                    }
                    </div>
                        </>
                        : <p style={{color: '#fff5'}}>
                            Your seach did not match any data.
                            <br />
                            <br />
                            Suggestions:
                            <ul>
                                <li>Make sure the filters you chose are correct</li>
                                <li>Try different filters</li>
                            </ul>

                        </p>
                    }
                    
                </>
            }
        </div>
    );
}

export default Table;
