import React from "react";
import { Col, Row } from "reactstrap";
import ActionBtns from "./components/actionBtns";
import WidgetTable from "./components/tableList";
import WidgetForm from "./components/popupForm";
import PopupNew from "views/components/popup_new";
import {useQuery} from 'react-query';
import useWidgetStore from "./widgetStore";
import { getPublishers, fetchList } from "./widgetAPI";
import CxLoader from "views/components/cxLoader";
import "./widget.scss"


const WidgetList = () => {

    const isPopupOpen = useWidgetStore((state) => state.isPopupOpen);
    const togglePopup = useWidgetStore((state) => state.togglePopup);

    const publishersList = useQuery( ["publishers-list"], getPublishers,{refetchOnWindowFocus: false});
    const widgetListQuery = useQuery(['widget-list'], fetchList, { refetchOnWindowFocus: false });
    

    return (
        <>
        <div className="content widget-page">
            <div>
                <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; Widget</h4>
            </div>
            <div className={"page-reactive-table"}>                    
                <Row className={"cx-action-buttons mx-0"}>
                    <Col className={"px-0"}>
                        {
                           publishersList.isLoading
                           ? <></>
                           : <ActionBtns /> 
                        }
                    </Col>
                </Row>
                <Row className={"mx-0"}>
                    <Col className={"px-0 cx-admediaplanner-review"}>
                        <div id={'cx-user-config'}>
                            {
                                (widgetListQuery.isLoading || widgetListQuery.isFetching || publishersList.isLoading)
                                ? <CxLoader minHeight="600px"/>
                                : <WidgetTable widgetListQuery={widgetListQuery}/>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <PopupNew title={"Widget"} width={'65%'} isPopupOpen={isPopupOpen} togglePopup={togglePopup}>
            <WidgetForm publishersList={publishersList.data}/>
        </PopupNew>
        </>
    );

}

export default WidgetList;