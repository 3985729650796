import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import { widgetPerformanceActions } from "../../../actions";
import { connect } from "react-redux";
import { CardTitle } from "reactstrap";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" style={{width:'300px'}} src={noData}/>
};
let helpers = new mainHelper();

class TopPostsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.cols = [
            {
                Header: "Post Title",
                accessor: "title",
                Cell: ({ original }) => {
                    return this.printArticle(original);
                }
            },
            {
                Header: "Impressions",
                accessor: "impressions",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.impressions, 1000000)}</span>
                }, width: 110
            },
            {
                Header: "Views",
                accessor: "views",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.views, 1000000)}</span>
                }, width: 110
            },
            {
                Header: "Clicks",
                accessor: "clicks",
                Cell: ({ original }) => {
                    return <span className="cx-number">{helpers.formatCXNumber(original.clicks, 1000000)}</span>
                }, width: 110
            }
        ];
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
            this.fetchData();
        }
    }
    componentDidMount() {
        this.fetchData();
    }


    componentWillMount = () => {
        //  this.fetchData();
    }

    truncate = (text, nb) => {
        if (text.length > (nb * 1)) return text.substring(0, (nb * 1)) + '...';
        return text;
    }
    printArticle = (o) => {
        function setDate(date) {
            if (!date) return '';
            let d = new Date(date);
            return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
        }
        return <div className={"d-flex align-items-center"}>
            <div className={'img-container mr-3'}>
                <img src={o.thumb + '=w50-h40-c'} width={50} alt="" />
            </div>
            <div style={{ 'fontFamily': 'Tahoma' }}>
                <a target="_blank" title={ReactHtmlParser(o.title)} className={'d-block'} href={(o.url) ? o.url : '#'} style={{ fontSize: '105%' }}>
                    {ReactHtmlParser(this.truncate(o.title, 30))}
                </a>
                <div className={"d-flex mt-1"}>
                    <span className={"mx-1"}>{setDate(o.datePublished)}</span>
                </div>
            </div>
        </div>;
    }

    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.props.fetchTopPosts(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }
    render() {
        return (
            <div className={'cx-widget-performance-top-posts'} style={{ minHeight: '400px' }}>
                {this.state.loader ? <CxLoader /> : ''}
                <div className={"px-2 py-3 cx-card-title d-flex justify-content-between"}>
                    <CardTitle className={"my-0 cx-white-color"}>Top Posts</CardTitle>
                    {/*<Tooltip text={"This card displays the top posts displayed in widgets by CogantiveX with their contribution."}></Tooltip>*/}
                </div>
                <ReactTable
                    noDataText=''
                    NoDataComponent={NoData}
                    sortable={false}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.topPostsData}
                    resizable={false}
                    columns={this.cols}
                    defaultPageSize={5}
                    showPaginationBottom={false}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        topPostsData: (state.widgetPerformance && state.widgetPerformance.topPostsData) ? state.widgetPerformance.topPostsData : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTopPosts: (filter, dateFilter) => dispatch(widgetPerformanceActions.fetchTopPosts(filter, dateFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopPostsTable);
