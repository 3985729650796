import React from 'react';
import ContentGroupingPageviews from './components/ContentGroupingPageviews';

const ContentGroupingCharts = () => {
    return (
        <div className="content">
            <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Content Grouping New Analysis</h4>
            <ContentGroupingPageviews />
        </div>
    )
}

export default ContentGroupingCharts;