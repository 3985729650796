import {
    FETCH_ADSET_LIST,
    FETCH_ADSET_ITEM,
    UPDATE_ADSET_ITEM,
    PUBLISH_ADSET,
    PUBLISH_ADSET_ERROR,
    UPDATE_ADSET_ERROR,
    DUPLICATE_ADSET_ERROR,
    DELETE_ADSET_ERROR,
    CREATE_ADSET_ERROR,
    FETCH_SEGMENTS,
    FETCH_IAB
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_IAB:
            return { ...state, IABList: action.payload}
        case FETCH_SEGMENTS:
            return { ...state, segmentsList: action.payload };
        case FETCH_ADSET_LIST:
            return { ...state, adsetList: action.payload };
        case FETCH_ADSET_ITEM:
            return { ...state, adset: action.payload };
        case UPDATE_ADSET_ITEM:
            return { ...state, adset: action.payload };
        case PUBLISH_ADSET:
            return { ...state, adset: action.payload };
        case PUBLISH_ADSET_ERROR:
            return { ...state, adsetPublishError: action.payload };
        case UPDATE_ADSET_ERROR:
            return { ...state, adsetUpdateError: action.payload };
        case DUPLICATE_ADSET_ERROR:
            return { ...state, adsetDuplicateError: action.payload };
        case DELETE_ADSET_ERROR:
            return { ...state, adsetDeleteError: action.payload };
        case CREATE_ADSET_ERROR:
            return { ...state, adsetCreateError: action.payload };
        // case DELETE_ADSET_ITEM:
        //     return { ...state, adset: action.payload };
        // case DUPLICATE_ADSET_ITEM:
        //         return { ...state, adset: action.payload };
        default:
            return state;
    }
}