import { LOGIN, LOGIN_ERR } from "../constants/appConstant";
import loginApi from "../api/auth";
const api = new loginApi();

/*
 * action creators
 */
const loginAction = (data) => ({ type: LOGIN, payload: data });
export const errorEvent = (err) => ({ type: LOGIN_ERR, payload: err });

//Campaign Actions
export const login = (data) => {
  return (dispatch) => {
    return api
      .login(data)
      .then((response) => {
        let res = null;
        if (response && response.data) {
          res = response.data;
          api.resetAuth(res);
         window.location.href = "/";
        }

        const data = {
          token: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          ex: new Date(response.data.expiredAt).getTime(),
          ep: Date.now() + 1000 * 60 * 1,
        };
        localStorage.setItem("new_token", JSON.stringify(data));

        dispatch(loginAction(res));
      })
      .catch((r) => {
        if (r.response && r.response.data && r.response.data.message) {
          dispatch(errorEvent(r.response.data.message));
        } else {
          dispatch(
            errorEvent("Error, try again later. Or contact us if it persists.")
          );
        }
        throw r;
      });
  };
};
