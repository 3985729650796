import create from 'zustand';

const usePublisherStore = create( (set, get) => ({
    isPopupOpen: false,
    togglePopup: () => set( state => ({isPopupOpen: !state.isPopupOpen}) ),
    itemToLoad: -1,
    setItemToLoad: (id) => set( _state => ({itemToLoad: id}) ),
    actionType: 'create',
    setActionType: (type) => set( _state => ({actionType: type}) ),
    selectedRows: [],
    toggleSelectCheckBox : (id) => {
        let rows = get().selectedRows.slice();
        const index = rows.indexOf(id);
        if(index < 0)
        rows.push(id);
        else{
            rows.splice(index, 1);
        }
        console.log(rows)
        return set(state => ({selectedRows: rows}), );
    },
    emptySelectedRows: () => set( _state => ({selectedRows: []}))
})
);


export default usePublisherStore;



