// campaignSelections campaignSelectionActions
import {
    TOGGLE_SELECTION_CAMPAIGN,
    TOGGLE_ALL_CAMPAIGN,
    SET_SELECTION_ARRAYS,
    SET_SELECTION_MAP
} from "../constants/appConstant";

/*
* Action Creators
* */

const toggleSelectionEvent = (type, selected) => ({type: TOGGLE_SELECTION_CAMPAIGN, payload: {type, selected}});
const setToggleAllValue = (type, state) => ({ type: TOGGLE_ALL_CAMPAIGN, payload: {type, state}});
const setSelections = (type, selected) => ({type: SET_SELECTION_ARRAYS, payload: {type, selected}});
const setSelectionMap = (type, selected) => ({type: SET_SELECTION_MAP, payload: {type, selected}});

export const toggleSelection = (type , id, cid) => {
    return (dispatch, getState) => {
        const allSelectedInType = Object.assign({}, getState().campaignSelections && getState().campaignSelections.selected && getState().campaignSelections.selected[type]);
        allSelectedInType[id] = !allSelectedInType[id];
        dispatch(toggleSelectionEvent(type, allSelectedInType));
        dispatch(setSelections(type, allSelectedInType));
        dispatch(setSelectionMap(type, {[id]: (allSelectedInType[id])? cid: undefined}));
    };
};

export const toggleAllOff = (type) => {
    return (dispatch) => {
        dispatch(setToggleAllValue(type, 0));
        dispatch(toggleSelectionEvent(type, {}));
        dispatch(setSelections(type, {}));
        dispatch(setSelectionMap(type, {}));
    };
};

export const toggleAll = (type, obj) => {
    return (dispatch, getState) => {
        let newSelected = {};
        let newSelectedMap = {};
        const selectAll = (
            getState().campaignSelections &&
            getState().campaignSelections.selectAll &&
            getState().campaignSelections.selectAll[type]
        )? getState().campaignSelections.selectAll[type]: 0;
        if(selectAll === 0){
            obj.forEach(x => {
                newSelected[x.id] = true;
                newSelectedMap[x.id] = x.campaignId;
            });
        }
        dispatch(setToggleAllValue(type, (selectAll === 0)? 1 : 0));
        dispatch(toggleSelectionEvent(type, newSelected));
        dispatch(setSelections(type, newSelected));
        dispatch(setSelectionMap(type, newSelectedMap));
    };
};
