import axios from 'axios';
// import querystring from "querystring";
import auth from "./auth";
import apiUrl from '../helpers/global';
import filterHelper from "../components/FilterBar/filterHelper"
import querystring from 'querystring';
// let helper = new mainHelper();
class widgetPerformance {
  
  

  constructor(props) {
    this.details = props;
    if (window.location.hostname === 'localhost') {
      this.baseUrl = 'http://localhost:5000';
    }
    this.auth = new auth();
  }

  getWidgetPerformanceFiltersList = async () => {
    let getData = [
      {
        value: "name",
        label: "Widget Name",
        type: "text",
        operator: {
          value: "contain",
          label: "contains",
          symbol: "∈"
        },
      }
    ]

    return {}
    //return await axios.get(this.baseUrl + '/filter-widget-performance');
  }
  // getFilterSchemaForApi(filters) {
  //   return filters ? Object.assign({}, ...filters.map(x => ({ [x.value]: x.typeValue }))) : {};
  // }

  getFullParams(filters, dateFilter, init) {
    console.log(dateFilter)
    if (!init)
      init = {};
    init["fromTimestamp"] = dateFilter.start;
    init["toTimestamp"] =  dateFilter.end;
    init["filters"] = filterHelper.getFilterSchemaForApi(filters);

    return {
      data: JSON.stringify(init)
    };
  }

  getWidgetSummary = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-summary`, this.getFullParams(filters, dateFilter));
  }

  getWidgetPerformanceByDevice = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-performance-by-device`, this.getFullParams(filters, dateFilter));

  }

  getWidgetPerformanceByTime = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-performance-by-day`, this.getFullParams(filters, dateFilter));
  }

  getWidgetPerformanceByLocation = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-performance-by-location`, this.getFullParams(filters, dateFilter));
  }

  getWidgetTopPosts = async (filters, dateFilter) => {
    var params = this.getFullParams(filters, dateFilter, { "orderBy": "clicks", "sort": "desc", "size": 5, "page": 1 });
    return this.auth.request.post(apiUrl + `/widget-top-posts`, params);
  }

  getWidgetTopWidgets = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-list-performance`, this.getFullParams(filters, dateFilter));
  }
  getAllWidgets = async (dateFilter) => {
    return this.auth.request.post(apiUrl + `/widget-list-performance`,{
      data: JSON.stringify({})
    });
  }
  
}

export default new widgetPerformance();
