import React from 'react';
import NewsletterWidgetPostsPerformance from './components/NewsletterPostsPerformance';
import NewsletterWidgetPerformance from './components/NewsletterWidgetPerformance';
import NewsletterPerformanceTotal from './components/NewsletterPerformanceTotal';
import {Grid} from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


const NewsletterAnalysis = () => {
    return (
        <div className="content">
            <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; New Newsletter Analysis</h4>
            <Grid container>
                <Grid item md={12} ml={8} mt={5}>
                    <NewsletterPerformanceTotal/>
                </Grid>
                <Grid item md={12} ml={13} mt={5}>
                    <h4>Email Clicks and Impressions</h4>
                    <NewsletterWidgetPerformance/>
                </Grid>
                <Grid item md={12} ml={13} mt={5}>
                    <h4>Top Clicked posts</h4>
                    <NewsletterWidgetPostsPerformance/>
                </Grid>
            </Grid>
        </div>
    );
}

export default NewsletterAnalysis;
