import {FETCH_ADVERTISER_OPTIONS,
    SET_ADVERTISER_ACTION_TYPE,
    FETCH_ADVERTISERS,
    FETCH_ADVERTISER_ITEM,
    UPDATE_ADVERTISER,
    UPDATE_ERR_ADVERTISER,
    CREATE_ADVERTISER,
    CREATE_ERR_ADVERTISER,
    DELETE_ADVERTISER,
    DELETE_ERR_ADVERTISER,
    PIXEL_LIST,
    CREATE_PIXEL,
    UPDATE_PIXEL,
    DELETE_PIXEL,
} from '../constants/appConstant';
export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ADVERTISER_OPTIONS:
            return {...state, advertiserOp: action.payload};
        case SET_ADVERTISER_ACTION_TYPE:
            return { ...state, actionType: action.payload };
        case FETCH_ADVERTISERS:
            return { ...state, List: action.payload };
        case FETCH_ADVERTISER_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_ADVERTISER:
            return { ...state, update: action.payload };
        case UPDATE_ERR_ADVERTISER:
            return { ...state, updateErr: action.payload };
        case CREATE_ADVERTISER:
            return { ...state, create: action.payload };
        case CREATE_ERR_ADVERTISER:
            return { ...state, createErr: action.payload };
        case DELETE_ADVERTISER:
            return { ...state, delete: action.payload };
        case DELETE_ERR_ADVERTISER:
            return { ...state, deleteErr: action.payload };
        case PIXEL_LIST:
            return { ...state, pixels: action.payload };
        case CREATE_PIXEL:
            return { ...state, pixelCreateErr: action.payload };
        case UPDATE_PIXEL:
            return { ...state, pixelUpdateErr: action.payload };
        case DELETE_PIXEL:
            return { ...state, pixelDeleteErr: action.payload };
        default:
            return state;
    }
}