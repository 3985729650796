import _auth from "./auth";
import apiUrl from '../helpers/global';

const auth = new _auth();

const segmentApi = {

    getList : async () => {
        const url = apiUrl + `/segments-list`;
        return auth.request.post(url);
    },
    getItem : async (id) => {
        const url = apiUrl + `/segment-read`;
        return auth.request.post(url, { data: JSON.stringify({id}) });
    },
    getDimensionsList : async () => {
        const url = apiUrl + `/dimensions-list`;
        return auth.request.post(url);
    },
    create : async (data) => {
        const url = apiUrl + `/segment-create`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    update : async (data) => {
        const url = apiUrl + `/segment-update`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    delete : async (data) => {
        const url = apiUrl + `/segment-delete`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    query : async (data) => {
        const url = apiUrl + `/query-dimension-metrics`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    }
    
}

export default segmentApi;