import React from "react";

export const FilterBar = (props) => {
    return (props.children)? <div className={`cx-filterbar-box d-flex justify-content-between ${props.className || ''}`}>{props.children}</div> : ''
}

export const StartFilters = (props) => {
    return <div className={`cx-filterbar-box-start`}>{props.children || ''}</div>;
}

export const EndFilters = (props) => {
    return <div className={`cx-filterbar-box-end`}>{props.children || ''}</div>;
}