import React, { Component } from "react";
import { connect } from "react-redux";
import {
  adActions,
  adsetActions,
  campaignActions,
  campaignSelectionActions,
  drawerActions,
} from "../../../actions";
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import { FormGroup, Input, Label } from "reactstrap";
import Switch from "react-bootstrap-switch";
import _ from "lodash";
import Confirm from "../../components/confirmationBox";
import Delivery from "./Delivery";
import analytics from "../../../helpers/analytics";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

const helper = new mainHelper();

class AdTable extends Component {
  constructor(props) {
    super(props);
    // table defaults (effects the UI changes)
    this.state = {
      pages: null,
      loading: true,
      sorted: [{ id: "adId", desc: true }],
      data: [],
    };
    this.notify = {
      duplicate: {
        title: "Are you sure you want to duplicate?",
        confirm: async (data) => {
          this.refs.confirmRef.removeConfirmation();

          await this.props.duplicateAd({ ids: [data.typeid] });
          // refresh table
          this.refreshData();
        },
        cancel: () => {
          this.refs.confirmRef.removeConfirmation();
        },
        successBtnTxt: "Duplicate",
        cancelBtnTxt: "Cancel",
      },
    };
  }

  componentDidMount() {
    this.getAdListData();
  }

  componentDidUpdate(prevProps) {
    if (
      !(
        _.isEqual(
          prevProps.campaignSelections,
          this.props.campaignSelections
        ) &&
        _.isEqual(prevProps.adsetSelections, this.props.adsetSelections) &&
        _.isEqual(prevProps.adList, this.props.adList)
      )
    ) {
      // update the data if list changed or selections changed
      this.setState({
        data: this.filter(
          this.props.adList,
          this.props.campaignSelections,
          this.props.adsetSelections
        ),
      });
    }
  }

  openDrawer = (selectedData, event = null) => {
    if (event) event.preventDefault();
    this.props.setDrawerVisible(true, selectedData);
  };

  activations = async (original) => {
    await this.props.activateAd(original);
    this.props.fetchAds();
    this.refreshData();
  };

  tableCols = () => {
    return [
      {
        filterable: false,
        id: "checkbox",
        accessor: "",
        Header: (x) => {
          return (
            <FormGroup
              check
              className={`${this.props.hideSelectAll ? "d-none" : ""}`}
            >
              <Label check className="d-inline">
                <Input
                  type="checkbox"
                  checked={this.props.selectAll === 1}
                  onChange={() => this.props.toggleAll(this.props.adList)}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = this.props.selectAll === 2;
                    }
                  }}
                />
                <span className="form-check-sign campaign-form-check-sign" />
              </Label>
            </FormGroup>
          );
        },
        Cell: ({ original }) => {
          return (
            <FormGroup check>
              <Label check className="d-inline">
                <Input
                  type="checkbox"
                  checked={this.props.selected[original.id] === true}
                  onChange={() =>
                    this.props.toggleSelection(original.id, original.campaignId)
                  }
                />
                <span className="form-check-sign campaign-form-check-sign" />
              </Label>
            </FormGroup>
          );
        },
        sortable: false,
        width: 45,
      },
      {
        filterable: false,
        Header: "",
        accessor: "",
        width: 50,
        sortable: false,
        Cell: ({ original }) => {
          return (
            <div className={`cx-input-container cx-switch-container`}>
              <Switch
                offColor=""
                offText=""
                onColor=""
                onText=""
                onChange={(e) => {
                  this.activations(original);
                }}
                defaultValue={original.isTurnedOn}
                />
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        filterMethod: (filter, row) => row.name.toLowerCase().includes(filter?.value?.toLowerCase()),
        Cell: ({ original }) => {
          return (
            <div className={"d-flex"}>
              <div className={"cx-table-image-styling"}>
                {original.image ? (
                  <img
                    src={original.image + "=w40-h23"}
                    width={40}
                    alt={original.name}
                  />
                  ) : (
                  <i className={"fa fa-image"}></i>
                  )}
              </div>
              <div className={"d-flex flex-column"}>
                <div className={"cx-table-name-styling ad"}>
                  <a
                    onClick={(e) => {
                      this.rowActions("edit", original, e);
                    }}
                    href={"#"}
                    title={original.name}
                  >
                    {original.name}
                  </a>
                </div>
                <div className={"table-actions-icons"}>
                  <a
                    href={"#"}
                    onClick={(e) => {
                      this.rowActions("edit", original, e);
                    }}
                    >
                    Edit
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={"#"}
                    onClick={(e) => {
                      this.rowActions("duplicate", original, e);
                    }}
                  >
                    Duplicate
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={"#"}
                    onClick={(e) => {
                      this.rowActions("report", original, e);
                    }}
                    >
                    View Charts
                  </a>
                </div>
              </div>
            </div>
          );
        },
        width: 380,
      },
      {
        Header: "Delivery",
        accessor: "delivery",
        Cell: ({ original }) => {
          return <Delivery delivery={original["delivery"]} />;
        },
        filterMethod: (filter, row) => filter.value === "all" ? true : row.delivery.toLowerCase() === filter?.value?.toLowerCase(),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="In draft">In draft</option>
            <option value="active">active</option>
            <option value="inactive">inactive</option>
            <option value="rejected">rejected</option>
            <option value="pending delivery">pending delivery</option>
            <option value="in review">in review</option>
            <option value="rejected">rejected</option>
            <option value="completed">completed</option>
            <option value="deleted">deleted</option>
          </select>
        ),
      },
      // {
        //     Header: "Ad Set",
      //     accessor: "adsetName",
      //     width: 150
      // },
      // {
        //     Header: "Budget",//from
        //     accessor: "totalBudget",
        //     Cell: ({ original }) => {
          //         return <span>${helper.formatCXNumber(original.totalBudget)}</span>
      //     }
      // },
      {
        Header: "Impressions",
        accessor: "currentImpressions",
        Cell: ({ original }) => {
          return (
            <span className="number-align-right">{helper.formatCXNumber(original.currentImpressions)}</span>
          );
        },

      },
      {
        Header: "Clicks",
        accessor: "currentClicks",
        Cell: ({ original }) => {
          return <span className="number-align-right">{helper.formatCXNumber(original.currentClicks)}</span>;
        },
      },
      {
        Header: "CTR",
        accessor: "ctr",
        Cell: ({ original }) => {
          return (
            <span className="number-align-right">{original.ctr}</span>
          );
        },
      },
      {
        Header: "Spent",
        accessor: "currentSpentBudget",
        Cell: ({ original }) => {
          return (
            <span className="number-align-right">${helper.formatDecimal(original.currentSpentBudget)}</span>
          );
        },
      },
      // ,
      // {
      //     Header: "Ends",
      //     Cell: ({ original }) => {
      //         return <span>Ongoing</span>
      //     }
      // }
    ];
  };

  getAdListData = async (page = "") => {
    this.setState({ loading: true });
    // calls the action to change the state of the store
    await this.props.fetchAds(page);
    this.setState({
      loading: false,
      data: this.filter(
        this.props.adList,
        this.props.campaignSelections,
        this.props.adsetSelections
      ),
    });
  };

  duplicateAdAction = (adId) => {
    if (this.refs.confirmRef) {
      return this.refs.confirmRef.confirmOptionActions({
        type: "duplicate",
        data: { typeid: adId },
      });
    }
    return false;
  };

  refreshData = () => {
    this.props.campaignReloadList();
    this.props.adsetReloadList();
    this.props.fetchAds();
  };

  /*
   * Row actions of a table
   */
  rowActions = (action, obj, event) => {
    switch (action) {
      default:
        this.openDrawer(
          [{ typeid: obj.id, type: "ad", campaignId: obj.campaignId }],
          event
        );
        this.props.setDrawerMode(action); // edit or report
        analytics.logEvent("Campaign", "Ad Table " + action);
        break;
      case "duplicate":
        this.duplicateAdAction(obj.id);
        analytics.logEvent("Campaign", "Ad Table " + action);
        break;
    }
  };

  filter = (adList, campaignList, adsetList) => {
    if (!campaignList.length && !adsetList.length) return adList;
    let res = [];
    for (let ad in adList) {
      const cmpid = adList[ad]["campaignId"].toString();
      const adsetid = adList[ad]["adsetId"].toString();
      // // Use this if you remove
      // if(adsetList.length && adsetList.indexOf(adsetid) > -1){
      //     res.push(adList[ad]);
      // }else if(campaignList.length && campaignList.indexOf(cmpid) > -1){
      //     res.push(adList[ad]);
      // }
      if (!adsetList.length || adsetList.indexOf(adsetid) > -1) {
        if (!campaignList.length || campaignList.indexOf(cmpid) > -1) {
          res.push(adList[ad]);
        }
      }
    }
    return res;
  };

  render() {
    return (
      <>
        <ReactTable
          filterable={true}
          noDataText=''
          NoDataComponent={NoData}
          defaultSorting={this.state.sorted}
          sortable={true}
          minRows={0}
          data={this.state.data}
          resizable={false}
          columns={this.tableCols()}
          className="-highlight cx-reactive-table"
          loading={this.state.loading}
        />
        <Confirm ref={"confirmRef"} notify={this.notify} />
      </>
    );
  }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
  // get the props needed for this component from the state.
  const hideSelectAll =
    (state.campaignSelections &&
      state.campaignSelections.selected_arr &&
      ((state.campaignSelections.selected_arr.campaign &&
        state.campaignSelections.selected_arr.campaign.length) ||
        (state.campaignSelections.selected_arr.adset &&
          state.campaignSelections.selected_arr.adset.length) ||
        (state.campaignSelections.selected_arr.ad &&
          state.campaignSelections.selected_arr.ad.length))) ||
    false;

  const selections =
    state.campaignSelections && state.campaignSelections.selected_arr;

  return {
    hideSelectAll,
    campaignSelections: selections
      ? selections.campaign
        ? selections.campaign
        : []
      : [],
    adsetSelections: selections
      ? selections.adset
        ? selections.adset
        : []
      : [],
    adList: state.ads && state.ads.adList ? state.ads.adList : [],
    selectAll:
      state.campaignSelections &&
      state.campaignSelections.selectAll &&
      state.campaignSelections.selectAll.ad
        ? state.campaignSelections.selectAll.ad
        : 0,
    selected:
      state.campaignSelections &&
      state.campaignSelections.selected &&
      state.campaignSelections.selected.ad
        ? state.campaignSelections.selected.ad
        : {},
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    setDrawerMode: (mode = "edit") =>
      dispatch(drawerActions.setDrawerMode(mode)),
    fetchAds: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
    setDrawerVisible: (action, selectedData) =>
      dispatch(drawerActions.setDrawerVisible(action, selectedData)),
    toggleSelection: (id, cid) =>
      dispatch(campaignSelectionActions.toggleSelection("ad", id, cid)),
    toggleAll: (obj) => dispatch(campaignSelectionActions.toggleAll("ad", obj)),
    activateAd: (obj) => dispatch(adActions.activateAd(obj)),
    duplicateAd: (item) => dispatch(adActions.duplicateAd(item)),
    campaignReloadList: (queryStr) =>
      dispatch(campaignActions.fetchAllCampaigns(queryStr)),
    adsetReloadList: (queryStr) =>
      dispatch(adsetActions.fetchAllAdsets(queryStr)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AdTable);
