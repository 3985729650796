import {
    DRAWER_FORM,
    DRAWER_FORM_RESET,
    DRAWER_MODE,
    DRAWER_TREE,
    DRAWER_VISIBLE,
    SET_REPORT_SUBTAB, SET_REPORT_TAB
} from '../constants/appConstant';
export default function (state = {}, action) {
    switch (action.type) {
        case SET_REPORT_SUBTAB:
            return { ...state, subtab: action.payload };
        case SET_REPORT_TAB:
            return { ...state, tab: action.payload };
        case DRAWER_VISIBLE:
            return {
                ...state,
                isDrawerOpened: action.payload.isDrawerOpened,
                selectedData: action.payload.selectedData
            };
        case DRAWER_TREE:
            return {
                ...state,
                selectedData: action.payload,
            };
            case DRAWER_FORM_RESET:
                if(action.payload){
                    return {
                        ...state,
                        ...action.payload
                    }
                }
                return { ...state}
            case DRAWER_FORM:
                return {
                    ...state,
                    selectedType: action.payload,
                    formAction: action.formAction
                };
            case DRAWER_MODE:
                // if(!action.action){
                return {
                    ...state,
                    drawerMode: action.payload
                };
                // }
                // return {
                //     ...state,
                //     drawerMode: action.payload,
                //     selectedData: action.payload
                // };

        default:
            return state;
    }
}