import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Field, reduxForm, reset, formValueSelector, isDirty } from "redux-form";
import { Card, CardHeader, CardBody } from '../../../components/Card/card';
import { Input, Checkbox, SelectF, Radio, Datepicker} from '../../components/formElts';
import { drawerActions, adsetActions, adActions, campaignActions, segmentActions } from "../../../actions";
import PerfectScrollbar from "perfect-scrollbar";
import CxLoader from "../../components/cxLoader";
import mainHelper from "../../../helpers/main";
import Confirm from "../../components/confirmationBox";
import IAB_List from "../IABCategoreis.json";
let helpers = new mainHelper();
const formName = "EditAdset";
var ps;

class EditAdset extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;
        this.state = {
            loader: this.props.loading,
            msg: null,
            msgAnimate: false
        }
        this.props.getSegmentList();
        this.props.getIABList();
        this.notify = {
            cancelWarning: {
                title: 'Are you sure you want to leave before saving?',
                confirm: async (data) => {
                    this.cancelButton(data, true);
                    this.refs.confirmRef.removeConfirmation();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Yes',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.elementRef);
        }else{
            this.refs.elementRef.style.overflow = 'auto';
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.loading != prevProps.loading) {
            // if it is in edit form and the data has been loaded then turn off the loader
            this.setState({ loader: this.props.loading });
        }


        if(this.props.errorUpdate && JSON.stringify(this.props.errorUpdate) != JSON.stringify(prevProps.errorUpdate)){
            this.printMessage(false, 'Error! Adset Not updated.', 'error', () => {
                this.props.adsetUpdateError(undefined);
            });
        }

        if(this.props.errorPublish && JSON.stringify(this.props.errorPublish) != JSON.stringify(prevProps.errorPublish)){
            this.printMessage(false, 'Error! Adset Not Published.', 'error', ()=> {
                this.props.adsetPublishError(undefined);
            });
        }

    }

    confirmOptionActions = (type, data) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: type, data: data });
        }
        return false;
    }

    cancelButton = (event, passToEnd = false) => {
        event.preventDefault();
        if(!passToEnd &&
            this.props.drawerMode === 'edit' &&
            this.props.selectedType.type === 'adset' &&
            this.props.isDirtyAdset
        ){
            this.confirmOptionActions('cancelWarning', event);
            return;
        }
        this.props.reset();
        this.props.toggleDrawer(false);
    }

    discardButton = (event) => {
        event.preventDefault();
        this.props.reset();
        this.props.toggleDrawer(false);
    }

    publishButton = async (event) => {
        event.preventDefault();
        this.handleSubmit(this.onPublish)();
    }

    onPublish = async (formVals) => {
        await this.onSave(formVals);
        this.setState({ loader: true });
        await this.props.publishAdset({ ids: [this.props.adset.id] });
        this.printMessage(false, 'Adset Published!');
        this.props.reloadCampaignList();
        this.props.reloadAdsetList();
        this.props.reloadAdList();
    }

    saveButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSave)();
    }
    onSave = async (formVals) => {
        const mappedForm = {
            id: formVals.id,
            name: formVals.name,
            brand: formVals.brand,
            totalBudget: formVals.totalBudget,
            maxCpc: formVals.maxCpc,
            isRetargeted: formVals.isRetargeted,
            segmentList: (Array.isArray(formVals.segmentList) && formVals.segmentList?.map(s=>s.value)) || [],
            iabCategory: formVals.iabCategory || [],
            pixelId: (formVals.pixelId && formVals.pixelId.value)?  formVals.pixelId.value *1 : undefined,
            dailyBudget: formVals.dailyBudget,
            startDateDetails: { date: this.formatDate(formVals.startDate), isEnabled: formVals.isStartDateEnabled === "true" },
            endDateDetails: { date: this.formatDate(formVals.endDate), isEnabled: formVals.isEndDateEnabled === "true" },
            locationDetails: {
                isAllCountriesEnabled: formVals["isAllCountriesEnabled"],
                locations: formVals["locations"] && formVals["locations"].map(i => i.value) || [],
                regions: formVals["regions"] && formVals["regions"].map(i => i.value) || [],
                blockedLocations: formVals["blockedLocations"] && formVals["blockedLocations"].map(i => i.value) || []
            },
            categoryList: formVals["audienceCategories"] && formVals["audienceCategories"].map(i => i.value) || [],
            deviceList: Object.keys(formVals).filter(i => i.startsWith('device') && formVals[i]).map(i => i.split('_')[1]),
            schedule: Object.keys(formVals).filter(i => i.startsWith('schedule') && formVals[i]).map(i => i.split('_')[1])
        }
        const ref = this;
        setTimeout(() => {
            ref.setState({ loader: true });
        }, 1);
        await this.props.updateAdset(mappedForm);
        // notify user that his changes were updated
        this.printMessage(false, 'Changes Saved!');
        // on success reload the tree
        this.props.reloadTree();
        // and reload the three tables
        this.props.reloadCampaignList();
        this.props.reloadAdsetList();
        this.props.reloadAdList();
    }

    formatDate(date) {
        if (!date)
            return "";
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var result = [year, month, day].join('-');
        return result;
    }

    renderInfoCard = () => {
        return (
            <Card className={"first-child"}>
                <CardHeader>
                    Adset Info
                </CardHeader>
                <CardBody>
                    <Field showMsg={true} className={"cx-edit-campaign-input"} label={"Adset Name *"} name={"name"} component={Input} />
                    <Field className={"cx-edit-campaign-input"} label={"Brand"} name={"brand"} component={Input} />
                </CardBody>
            </Card>
        );
    }

    renderTargetCard = () => {
        return (
            <Card>
                <CardHeader>
                    Target
                </CardHeader>
                <CardBody>
                    <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"All Countries"} name={"isAllCountriesEnabled"} component={Checkbox} />
                    {this.props.isAllCountriesEnabled ? '' :
                        <>
                            <Field
                                className={"cx-edit-campaign-select cx-edit-campaign-input"}
                                placeholder={"-- Choose --"}
                                label={"Regions"}
                                name={"regions"}
                                component={SelectF}
                                isMulti={true}
                                options={this.props.allRegions}
                            />
                            <Field
                                showMsg={true}
                                className={"cx-edit-campaign-select cx-edit-campaign-input"}
                                placeholder={"-- Choose --"}
                                label={"Target Locations"}
                                name={"locations"}
                                component={SelectF}
                                isMulti={true}
                                options={this.props.allLocations}
                            />
                        </>
                    }
                    <Field
                        className={"cx-edit-campaign-select cx-edit-campaign-input"}
                        placeholder={"-- Choose --"}
                        label={"Blocked Locations"}
                        name={"blockedLocations"}
                        component={SelectF}
                        isMulti={true}
                        options={this.props.allLocations}
                    />
                    <Field
                        className={"cx-edit-campaign-select cx-edit-campaign-input"}
                        placeholder={"-- Choose --"}
                        label={"Audience Categories *"}
                        name={"audienceCategories"}
                        showMsg={true}
                        component={SelectF}
                        isMulti={true}
                        options={this.props.allCategories}
                    />
                    <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"Retargeting"} name={"isRetargeted"} component={Checkbox} />
                    {this.props.isRetargeted ?
                        <Field
                            className={"cx-edit-campaign-select cx-edit-campaign-input"}
                            placeholder={"-- Choose --"}
                            label={"Pixel *"}
                            name={"pixelId"}
                            showMsg={true}
                            component={SelectF}
                            options={this.props.pixels}
                        /> :
                        ''
                    }
                    <div className={'cx-edit-campaign-input cx-edit-adset-devices'}>
                        <div className={"cx-input-label"}>
                            <label>
                                Mobile
                            </label>
                        </div>
                        <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"IOS"} name={"device_mobile-ios"} component={Checkbox} />
                        <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"Android"} name={"device_mobile-android"} component={Checkbox} />
                    </div>
                    <div className={'cx-edit-campaign-input cx-edit-adset-devices'}>
                        <div className={"cx-input-label"}>
                            <label>
                                Tablet
                            </label>
                        </div>
                        <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"IOS"} name={"device_tablet-ios"} component={Checkbox} />
                        <Field className={"cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"Android"} name={"device_tablet-android"} component={Checkbox} />
                    </div>
                    <div className={'cx-edit-campaign-input cx-edit-adset-devices'}>
                        <div className={"cx-input-label"}>
                            <label>
                                Desktop
                            </label>
                        </div>
                        <Field className={"cx-check-desktop cx-edit-campaign-checkbox cx-edit-campaign-input"} label={"Desktop"} name={"device_desktop"} component={Checkbox} showMsg={true} />
                    </div>
                </CardBody>
            </Card>
        );
    }

    renderScheduleCard = () => {
        return (
            <Card>
                <CardHeader>
                    Schedule
                </CardHeader>
                <CardBody>
                    <div className={"cx-schdule-dates-container"}>
                        <div className={"cx-dates-container"}>
                            <label className={"cx-label-text"}>Start: </label>
                            <div className={"cx-date-data"}>
                                <Field showMsg={true} name={'isStartDateEnabled'} options={[{ value: 'false', title: 'As soon as reviewed' }, { value: 'true', title: 'From' }]} className={"cx-edit-from-input"} component={Radio} />
                                <Field disabled={!(this.props.isStartDateEnabled == 'true')} name={'startDate'} placeholder={"From"} className={'cx-start-datepicker'} component={Datepicker} />
                            </div>
                        </div>
                        <div className={"cx-dates-container middle-last"}>
                            <label className={"cx-label-text"}>End: </label>
                            <div className={"cx-date-data"}>
                                <Field showMsg={true} name={'isEndDateEnabled'} options={[{ value: 'false', title: 'When I stop it' }, { value: 'true', title: 'Until' }]} className={"cx-edit-to-input"} component={Radio} />
                                <Field disabled={!(this.props.isEndDateEnabled == 'true')} name={'endDate'} placeholder={"Until"} className={'cx-end-datepicker'} component={Datepicker} />
                            </div>
                        </div>
                        <div className={"cx-dates-container middle-last"}>
                            <label className={"cx-label-text"}>Days: </label>
                            <div className={"cx-date-data"}>
                                {
                                    this.props.days && this.props.days.map((d) => {
                                        return <Field showMsg={(d == 'sunday')? true: false} key={'cx-schedule-' + d} className={"cx-edit-campaign-checkbox cx-edit-campaign-input"}
                                            label={d.substr(0, 3)} name={"schedule_" + d} component={Checkbox} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    printMessage = (loader, msg, type='success', callback = ()=>{}) => {
        const ref = this;
        if(type == "error") msg = <span style={{color: '#F0555D'}}>{msg}</span>;
        // Update user with the message after save
        this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
            setTimeout(() => {
                ref.setState({ msgAnimate: false }, () => {
                    setTimeout(() => {
                        // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
                        ref.setState({ msg: null });
                        (callback)();
                    }, 200);
                });
            }, 2000);
        });
    }

    renderSegmentationCard = () => {
        return (
            <Card>
                <CardHeader>
                    Segmentation
                </CardHeader>
                <CardBody>
                    <Field
                        disabled={this.state.loader}
                        className={"adset-form-segments-select"}
                        label={"Segments"}
                        name={"segmentList"}
                        isMulti={true}
                        options={this.props.segmentsList}
                        component={SelectF}
                    />
                    <br />
                    <br />
                    <p style={{opacity: '.6'}}>
                        <i className="fa fa-info"></i> &nbsp;&nbsp; Choose what segment(group) of users you want the ads in this adset to to be shown to. 
                    </p>
                </CardBody>
            </Card>
        );
    }

    renderIABCard = () => {
        return (
            <Card className="overflow-visible">
                <CardHeader>
                    IAB Categories
                </CardHeader>
                <CardBody>
                    <Field
                        disabled={this.state.loader}
                        className={"adset-form-segments-select"}
                        label={"IAB Categories"}
                        name={"iabCategory"}
                        isMulti={true}
                        options={this.props.IABList}
                        component={SelectF}
                    />
                </CardBody>
            </Card>
        );
    }

    renderBudgetCard = () => {
        return (
            <Card className={"last-child"}>
                <CardHeader>
                    Budget
                </CardHeader>
                <CardBody>
                    <Field showMsg={true} type={"number"} className={"cx-edit-campaign-input"} label={"Max CPC"} icon={"fas fa-dollar-sign"} name={"maxCpc"} component={Input} />
                    <Field showMsg={true} type={"number"} className={"cx-edit-campaign-input"} label={"Total Budget *"} icon={"fas fa-dollar-sign"} name={"totalBudget"} component={Input} />
                    <Field showMsg={true} type={"number"} className={"cx-edit-campaign-input"} label={"Daily Ad Delivery"} icon={"fas fa-dollar-sign"} name={"dailyBudget"} component={Input} />
                </CardBody>
            </Card>
        );
    }

    render() {
        return (
            <Form className={`cx-edit-drawer-form cx-edit-adset-form active`} onSubmit={() => (false)}>
                {this.state.loader ? <CxLoader /> : ''}
                <div className={"cx-edit-drawer-container"} ref="elementRef">
                    {this.renderInfoCard()}
                    {this.renderIABCard()}
                    {this.renderTargetCard()}
                    {this.renderScheduleCard()}
                    {this.renderSegmentationCard()}
                    {this.renderBudgetCard()}
                    <div className={"cx-action-buttons"}>

                        <button className={"btn cx-cancel-btn pull-left"} onClick={(e) => { this.cancelButton(e) }}>Cancel</button>
                        {this.props.adset && this.props.adset.isPublishable &&
                            <button className={"btn cx-focus-btn pull-right"} onClick={(e) => { this.publishButton(e) }}>Save & Publish</button>
                        }
                        <button className={"btn cx-discard-btn pull-right"} onClick={(e) => { this.saveButton(e) }}>Save Draft</button>
                        <span className={`cx-form-msgs pull-right ${this.state.msgAnimate ? 'show' : ''}`}>{this.state.msg}</span>

                    </div>
                </div>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </Form>
        );
    }
}
const formatCXDate = (date) => {
    if (!date)
        return date;

    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}

const mapStateToProps = (state) => {
    let adset = state.adsets.adset;
    console.log(state)
    const IABList = state.adsets?.IABList;
    const segmentsList = (state.adsets && state.adsets.segmentsList) ?
    state.adsets.segmentsList.map(function (item) {
        return { value: item.id, label: item.name };
    }) : [];
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const devices = ["mobile-ios", "mobile-android", "tablet-ios", "tablet-android", "desktop"];
    const pixels = state.advertiser && state.advertiser.pixels || null;
    const formAction = state.slidedrawer && state.slidedrawer.formAction;
    let initialValues = null;
    
    if (adset) {
        initialValues = {
            id: adset.id,
            name: adset.name,
            brand: adset.brand,
            segmentList: adset.segmentList,
            iabCategory: adset.iabCategory,
            totalBudget: adset.totalBudget,
            maxCpc: adset.maxCpc,
            dailyBudget: adset.dailyBudget,
            isAllCountriesEnabled: adset.locationDetails ? adset.locationDetails.isAllCountriesEnabled : false,
            isRetargeted: adset.isRetargeted ? adset.isRetargeted : false,
            pixelId: adset.pixelId ? helpers.getSelectObjectByVal(pixels, adset.pixelId) : null,
            regions: adset.locationDetails ? helpers.getSelectObjectByVal(state.location.regionsSelect, adset.locationDetails.regions, 'multi') : [],
            locations: adset.locationDetails ? helpers.getSelectObjectByVal(state.location.locationsSelect, adset.locationDetails.locations, 'multi') : [],
            blockedLocations: adset.locationDetails ? helpers.getSelectObjectByVal(state.location.locationsSelect, adset.locationDetails.blockedLocations, 'multi') : [],
            audienceCategories: adset.categoryList ? helpers.getSelectObjectByVal(state.category.categoriesSelect, adset.categoryList, 'multi') : [],
            isStartDateEnabled: adset.startDateDetails.isEnabled ? "true" : "false",
            isEndDateEnabled: adset.endDateDetails.isEnabled ? "true" : "false",
            startDate: formatCXDate(adset.startDateDetails.date),
            endDate: formatCXDate(adset.endDateDetails.date),
        }
        adset.schedule.forEach((d) => {
            initialValues['schedule_' + d] = true;
        });
        adset.deviceList.forEach((d) => {
            initialValues['device_' + d] = true;
        });
    }
    return {
        drawerMode: state.slidedrawer && state.slidedrawer.drawerMode,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType,
        isDirtyAdset: isDirty(formName)(state),
        days: days,
        adset: adset,
        pixels,
        formAction: formAction,
        errorUpdate: (state.adsets && state.adsets.adsetUpdateError) || null,
        errorPublish: (state.adsets && state.adsets.adsetPublishError) || null,
        allLocations: state.location.locationsSelect || [],
        segmentsList: segmentsList,
        IABList: IABList,
        allRegions: state.location.regionsSelect || [],
        allCategories: state.category.categoriesSelect,
        isRetargeted: formValueSelector(formName)(state, 'isRetargeted'),
        isAllCountriesEnabled: formValueSelector(formName)(state, 'isAllCountriesEnabled'),
        isStartDateEnabled: formValueSelector(formName)(state, 'isStartDateEnabled'),
        isEndDateEnabled: formValueSelector(formName)(state, 'isEndDateEnabled'),
        initialValues: initialValues
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateAdset: (data) => dispatch(adsetActions.updateAdset(data)),
    publishAdset: (data) => dispatch(adsetActions.publishAdset(data)),
    toggleDrawer: (action) => dispatch(drawerActions.setDrawerVisible(action)),
    reloadTree: () => dispatch(campaignActions.fetchCampaignsTree()),
    reloadCampaignList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
    reloadAdsetList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
    reloadAdList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
    adsetPublishError: (data) => dispatch(adsetActions.adsetPublishError(data)),
    adsetUpdateError: (data) => dispatch(adsetActions.adsetUpdateError(data)),
    getSegmentList: () => dispatch(segmentActions.getList()),
    getIABList: () => dispatch(adsetActions.getIABList()),

});

function validate(form) {
    const errors = {};
    if (!form.name) {
        errors.name = "Adset Name is required";
    }
    // if(!form.totalBudget){
    //     errors.totalBudget = "Total Budget is required";
    // }
    // if(!form.maxCpc){
    //     errors.maxCpc = "Max Cpc is required";
    // }
    // if(!form.dailyBudget){
    //     errors.dailyBudget = "Daily Budget is required";
    // }
    return errors;
}

EditAdset = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate
})(EditAdset)

EditAdset = connect(
    mapStateToProps, mapDispatchToProps
)(EditAdset)

export default EditAdset
