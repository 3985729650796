import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    popupActions,
    recommendationActions,
} from "../../../actions";
import Confirm from "../../components/confirmationBox";
import mainHelper from "../../../helpers/main";
const helper = new mainHelper();

class ActionBtns extends Component {

    constructor(props) {
        super(props);
        this.notify = {
            delete: {
                title: 'Are you sure you want to delete the selected?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x*1;
                    });
                    await this.props.deleteItems({ ids: data });
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: `Delete`,
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    refreshData = () => {
        this.props.fetchList();
    }

    preventdefault = (event) => {
        if (event) event.preventDefault();
    }

    confirmOptionActions = (action) => {
        if(this.refs.confirmRef) {
            const selections = this.getSelections();
            return this.refs.confirmRef.confirmOptionActions({ type: action, data: selections.ids });
        }
        return false;
    }

    getSelections = () => {
        if(this.props.selections.length){
            return {
                type: 'recommendationConfig',
                ids: this.props.selections
            };
        }
        return false;
    }

    openPopupForm = (action = 'create') => {
        this.props.togglePopup(true);
        this.props.setRecommendationActionType(action);
    }

    render() {
        const disabled = !this.getSelections();
        return (
            <div className={"header-btn-container"}>
                    <button
                        className="btn cx-focus-btn"
                        onClick={(e) => { this.preventdefault(e); this.openPopupForm('create') }}
                    >
                        Create
                    </button>
                    <button
                        className="btn"
                        onClick={(e) => { this.preventdefault(e); this.confirmOptionActions('delete') }}
                        disabled={disabled}
                    >
                        Delete
                    </button>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </div>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        selections: (
            state.tabTable &&
            state.tabTable.tableSelections &&
            state.tabTable.tableSelections.recommendationConfig
        )? helper.getSelectedIds(state.tabTable.tableSelections.recommendationConfig) : [],
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchList: (type) => dispatch(recommendationActions.getConfigList()),
        deleteItems: (ids) => dispatch(recommendationActions.deleteConfig(ids)),
        setRecommendationActionType: (type) =>dispatch (recommendationActions.setRecommendationActionType(type)),
        togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(ActionBtns);