import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { classificationActions } from "../../../actions";
import { connect } from "react-redux";

// accepts data as follows:
// data = [{key: 'class-name', name: 'Class Name'}];
// Example:
// how to use it:
// filterClass = (byClass) => {
//   console.log('filter data')
//   console.log(byClass);
// }
// <FilterTabs callback={this.filterClass}/>

class FilterTabs extends React.Component {
  constructor(props) {
    super(props);
  }

  fetchData = async () => {
    await this.props.fetchData();
    if (this.props.tabs && this.props.tabs.length)
      this.props.callback(this.props.tabs[0]);
  };

  componentWillMount() {
    this.fetchData();
  }

  filterByTab = (tabDetail) => {
    // 1- call the callback to re-filter data
    this.props.callback(tabDetail);
    // 2- make the tab active
    this.props.setActiveTab(tabDetail.key);
  };

  render() {
    return (
      <>
        {this.props.tabs && this.props.tabs.length ? (
          <div className={"d-flex cx-filtertabs"}>
            {this.props.tabs.map((tabDetail, index) => {
              return (
                <span
                  key={`${tabDetail.name}-${tabDetail.key}-${index}`}
                  onClick={() => {
                    this.filterByTab(tabDetail);
                  }}
                  className={classnames("cx-white-color", {
                    active: this.props.activeKey === tabDetail.key,
                  })}
                >
                  {tabDetail.name}
                </span>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

FilterTabs.propTypes = {
  callback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeKey:
      (state.classificationInsight && state.classificationInsight.activetab) ||
      "",
    tabs:
      (state.classificationInsight && state.classificationInsight.tabs) || [],
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(classificationActions.Fetchtabs()),
    setActiveTab: (data) => dispatch(classificationActions.activeTab(data)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(FilterTabs);
