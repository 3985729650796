import {CHANGE_PASSWORD} from "../constants/appConstant";
import authApi from '../api/auth';
import {SubmissionError} from 'redux-form';
let api = new authApi();

export const changePasswordErr = (err) => ({ type: CHANGE_PASSWORD, payload: err });

export const changePassword = (data) => {

    function printError(key, msg = 'Error') {
        switch(key){
            default:
                return {[key]: msg};
        }
    }

    return (dispatch) => {
        return api.changeUserPassword(data)
            .then(response => {
                dispatch(changePasswordErr(undefined))
            })
            .catch(error => {
                if(error && error.response && error.response.data) {
                    const errors = error.response.data.description;
                    dispatch(changePasswordErr('errors'));
                    let errorMsgs = { _error: "Change Password Failed"};
                    for (let err in errors){
                        let errMsg = printError(err, errors[err]);
                        errorMsgs = {...errMsg, ...errorMsgs};
                    }
                    throw new SubmissionError(errorMsgs);
                }
                throw (error);
            });
    };
};
