import React, { Component } from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import ActionBtns from "./config/actionBtns";
import CampaignCategoryMappingTable from "./config/tableList";
import CampaignCategoryMappingForm from "./config/popupForm";
import DateFilter from "../components/dateFilter";
import _ from "lodash";
import { campaignCategoryMappingActions, recommendationActions ,categoryActions} from "../../actions";
import Popup from "../components/popup";

class CampaignCategoryMapping extends Component {

    constructor(props) {
        super(props);
        this.props.getClassList();
        this.props.fetchCategoryOptions();
        this.state = {
        }
    }

    componentDidUpdate(prevProps) {
        // Create 
        if (this.props.createErr && !_.isEqual(this.props.createErr, prevProps.createErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.createErrEvent();
        }
        if (this.props.create && !_.isEqual(this.props.create, prevProps.create)) {
            this.alertMsg(' Created Successfully', 'fa fa-check', 'success');
            this.props.createEvent();
        }
        // Update 
        if (this.props.updateErr && !_.isEqual(this.props.updateErr, prevProps.updateErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.updateErrEvent();
        }
        if (this.props.update && !_.isEqual(this.props.update, prevProps.update)) {
            this.alertMsg(' Updated Successfully', 'fa fa-check', 'success');
            this.props.updateEvent();
        }
        // delete
        if (this.props.deleteErr && !_.isEqual(this.props.deleteErr, prevProps.deleteErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.deleteErrEvent();
        }
        if (this.props.delete && !_.isEqual(this.props.delete, prevProps.delete)) {
            this.alertMsg(' Deleted Successfully', 'fa fa-check', 'success');
            this.props.deleteEvent();
        }
    }

    renderPageFilters = () => {
        return (
            <div className={"cx-page-filters"}>
                <DateFilter />
            </div>
        );
    }

    alertMsg = (msg, icon = 'tim-icons icon-simple-remove', type = 'danger') => {
        this.refs.notificationAlert.notificationAlert({
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    render() {
        const defaultFreshness = (this.props.defaultFreshness) ? this.props.defaultFreshness : 0;
        return (
            <>
                <Popup title={"Ad Mapping"} width={'35%'}>
                    <CampaignCategoryMappingForm />
                </Popup>

                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-tag"></i>&nbsp; Ad Mapping</h4>
                    </div>
                    <div className={"page-reactive-table"}>
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-campaignCategoryMapping-'}>
                                    <CampaignCategoryMappingTable />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        actionType: (state.campaignCategoryMapping && state.campaignCategoryMapping.actionType) ? state.campaignCategoryMapping.actionType : 'create',
        Item: state.campaignCategoryMapping && state.campaignCategoryMapping.Item,
        createErr: (state.campaignCategoryMapping && state.campaignCategoryMapping.createErr) || null,
        create: (state.campaignCategoryMapping && state.campaignCategoryMapping.create) || null,
        updateErr: (state.campaignCategoryMapping && state.campaignCategoryMapping.updateErr) || null,
        update: (state.campaignCategoryMapping && state.campaignCategoryMapping.update) || null,
        deleteErr: (state.campaignCategoryMapping && state.campaignCategoryMapping.deleteErr) || null,
        delete: (state.campaignCategoryMapping && state.campaignCategoryMapping.delete) || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: () => dispatch(campaignCategoryMappingActions.createEvent(undefined)),
        createErrEvent: () => dispatch(campaignCategoryMappingActions.createErrEvent(undefined)),
        updateErrEvent: () => dispatch(campaignCategoryMappingActions.updateErrEvent(undefined)),
        updateEvent: () => dispatch(campaignCategoryMappingActions.updateErrEvent(undefined)),
        deleteErrEvent: () => dispatch(campaignCategoryMappingActions.deleteErrEvent(undefined)),
        deleteEvent: () => dispatch(campaignCategoryMappingActions.deleteEvent(undefined)),
        getClassList: () => dispatch(recommendationActions.getRecommendationClassList()),
        fetchCategoryOptions: () => dispatch(categoryActions.fetchIABCategoryOptions())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCategoryMapping);