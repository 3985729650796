import React from 'react'
import {
  Card,
  CardBody,
  Row,
  CardTitle
} from "reactstrap";
import Throbber from "../../components/Throbber.jsx";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mainHelper from "../../../helpers/main";
import Tooltip from '../../../components/Tooltips/Tooltip';
import {authorsActions} from "../../../actions";
import {connect} from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data-for-charts.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

let helpers = new mainHelper();

let colorset = helpers.cxColorSet;

class AuthorsBubbleGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bubbleGraph: {},
      loader: false
    }
  }
  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData({ orderBy: 'pageviews', sort: 'DESC',size:20 },
      this.props.pageFilters, this.props.dateFilter);
      this.setState({ loader: false });
    }
  }
 
 

  /*
  * Helper function
  * to map the api to the state needed
  * */
  getData = () => {
    const {performance} = this.props;
    let pageviewsData = [];
    let cxPageviewsData = [];
    for (let i in performance) {
      const author = performance[i];
      pageviewsData.push({
        x: author.published_posts,
        y: author.pageviews,
        z: author.time,
        timespent: author.time,
        cx_timespent: author.cx_time,
        cx_timespent_percent: author.cx_impact,
        cx_pageviews: author.cx_pageviews,
        authorName: author.author,
        name: (author.authorName) ? author.authorName.split(' ')[0] : author.author
      });
      cxPageviewsData.push({
        x: author.published_posts,
        y: author.pageviews,
        z: author.cx_time,
        timespent: author.time,
        cx_timespent: author.cx_time,
        cx_timespent_percent: author.cx_impact,
        authorName: author.author,
        name: ''//(author.authorName)? author.authorName.split(' ')[0] : author.author + ' CX ',
      });
    }
    return [
      {
        name: 'Engagement Time', data: pageviewsData, border: 0, color: '#29b3e8'
      },
      {
        name: 'CX Impact', color: '#ff8d72', data: cxPageviewsData
      }
    ];
  }

  

  render() {
    return (
      <Card>
        <CardBody style={{ "minHeight": "435px" }}>
          <Row className={"justify-content-between mx-0"}>
            <CardTitle>Author Performance</CardTitle>
            <Tooltip text={" This card displays the Number of articles written by an author vs. the pageviews achieved. The size of the circle resembles the total time spent by users on this author’s articles. You can also view the contribution of CX for each author."}></Tooltip>
          </Row>
          {this.state.loader ? 
                <CxLoader />
                 : ''}
             
             {this.props.performance ?
              <HighchartsReact
                highcharts={Highcharts}
                allowChartUpdate={true}
                immutable={true}
                options={
                  {
                    colors: colorset,
                    chart: {
                      type: 'bubble',
                      zoomType: 'xy',
                      height: 372,
                      backgroundColor: 'transparent',
                    },
                    legend: {
                      "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
                        "color": '#fff'
                      }
                    },
                    navigator: {
                      handles: {
                        backgroundColor: '#666',
                        borderColor: '#AAA'
                      },
                      outlineColor: '#CCC',
                      maskFill: 'rgba(255,255,255,0.1)',
                      series: {
                        color: '#7798BF',
                        lineColor: '#A6C7ED'
                      },
                      xAxis: {
                        gridLineColor: '#505053'
                      }
                    },
                    exporting: {
                      "enabled": false
                    },
                    title: {
                      text: ' '
                    },
                    xAxis: {
                      gridLineColor: '#707073',
                      gridLineWidth: 1,
                      lineWidth: 0,
                      title: {
                        text: 'Article Count',
                        "style": {
                          "color": '#aeaeae'
                        }
                      },
                      labels: {
                        format: '{value}',
                        style: {
                          color: '#aeaeae',
                        }
                      },
                    },
                    credits: { "enabled": false },
                    yAxis: {
                      gridLineColor: '#707073',
                      startOnTick: false,
                      endOnTick: false,

                      title: {
                        text: 'Pageviews',
                        "style": {
                          "color": '#aeaeae'
                        }
                      },
                      labels: {
                        format: '{value}',
                        style: {
                          color: '#aeaeae',
                        },
                        formatter: function () {
                          return helpers.formatNumber(this.value);
                        }
                      },
                      maxPadding: 0.2,
                    },
                    tooltip: {
                      useHTML: true,
                      formatter: function () {
                        return '<table><tr><th colspan="2"><h4 style=\'color: black\'>' + this.point.authorName + '</h5></th></tr>' +
                            '<tr><th>Pageviews</th><td>' + helpers.formatNumber(this.point.y) + '</td></tr>' +
                            '<tr><th>Articles</th><td>' + this.point.x + '</td></tr>' +
                            '<tr><th>Timspent</th><td>' + helpers.formatTime(this.point.timespent) + '</td></tr>' +
                            '<tr><th>CX Impact</th><td>' + helpers.formatTime(this.point.cx_timespent) + ' <b>' + helpers.formatDecimal(this.point.cx_timespent_percent) + '%</b></td></tr>' +
                            '</table>';
                      },
                      followPointer: true
                    },
                    plotOptions: {
                      series: {

                        dataLabels: {
                          enabled: false,
                          borderWidth: 0,
                        },
                      },
                    },
                    series: this.getData()
                  }
                }
              />:<NoData />
          }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: state.filter && state.filter.date || null,
    pageFilters: state.authorsInsight && state.authorsInsight.pageFilters || null,
    performance: (state.authorsInsight && state.authorsInsight.performance) || null,
  }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params,filter, dateFilter) => dispatch(authorsActions.fetchPerformance(params,filter, dateFilter)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AuthorsBubbleGraph);