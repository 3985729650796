// import axios from 'axios';
import auth from "./auth";
import apiUrl from '../helpers/global';
import filterHelper from "../components/FilterBar/filterHelper"

/* Helper Class
* To make requesting countries and regions more flexible
* */
class Author {

  
  

  constructor(props) {
    this.details = props;
    this.auth = new auth();
  }

  // getFilterSchemaForApi(filters) {
  //   return filters ? Object.assign({}, ...filters.map(x => ({ [x.value]: x.typeValue }))) : {};
  // }

  getFullParams(filters, dateFilter, init) {
    console.log(dateFilter)
    if (!init)
      init = {};
    init["fromTimestamp"] = dateFilter.start;
    init["toTimestamp"] = dateFilter.end;
    init["filters"] = filterHelper.getFilterSchemaForApi(filters);

    return {
      data: JSON.stringify(init)
    };
  }

  authorSummary = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + '/authors-cx-impact', this.getFullParams(filters, dateFilter));
  }

  pageviewsBubbleGraph = async (params,filters, dateFilter) => {
    var init = this.getFullParams(filters, dateFilter, params);
    return this.auth.request.post(apiUrl + `/authors-performance`, init);
  }

  authorsByCXImpact = async (params, filters, dateFilter) => {
    var init = this.getFullParams(filters, dateFilter, params);
    return this.auth.request.post(apiUrl + '/authors-by-cx-pageviews-impact', init);
  }

  authorsTaxonomy = async (filters, dateFilter) => {
    return this.auth.request.post(apiUrl + '/authors-taxonomy', this.getFullParams(filters, dateFilter));
  }

  getAllAuthors = async (data) => {
    var params = { data: JSON.stringify({ "orderBy": "cx_pageviews", "sort": "desc", "size": 30, "page": 1 }) };
    return this.auth.request.post(apiUrl + '/authors-by-cx-pageviews-impact', params);
  }

}

export default new Author();
