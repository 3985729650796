import React, { useState, useRef, useEffect } from "react";
import { useController } from "react-hook-form";
import useOutsideClick from "../hooks/useOutsideClick";

const CXAdvancedSelect = ({
    required,
    control,
    name,
    options,
    className,
    defaultDisplay = "Select",
    dislayProp = "label",
    customOnChange,
    startOpened,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        rules: { required: required },
    });
    const [isSelectOpen, setIsSelectOpen] = useState(
        startOpened ? true : false
    );
    const [filter, setFilter] = useState("");

    const selectRef = useRef();
    const input = useRef();
    useOutsideClick(selectRef, () => {
        setIsSelectOpen(false);
    });

    useEffect(() => {
        if (input.current) input.current.focus();
    }, [isSelectOpen]);

    const toggleSelect = () => {
        setIsSelectOpen(!isSelectOpen);
    };

    const selectValue = (value) => {
        if (customOnChange) customOnChange(value);
        onChange(value);
    };

    const getSelectedValueLabel = () => {
        if (!value) return;
        const selection = options.filter((o) => {
            return isActive(o);
        });
        if (selection.length) return selection[0].label;
    };

    const isActive = (o) => {
        if(!value) return;
        if (dislayProp && o.value && o.value[dislayProp] && value[dislayProp]) {
            return o.value[dislayProp] === value[dislayProp];
        } else {
            return value.value ? o.value === value?.value : o.value === value;
        }
    };
    return (
        <div
            className={`cx-advanced-select ${
                isSelectOpen && "cx-advanced-select-open"
            } ${className ?? ""}`}
            ref={selectRef}
        >
            <div className="cx-advanced-select__trigger" onClick={toggleSelect}>
                <span className="cx-advanced-select__placeholder">
                    {getSelectedValueLabel() || defaultDisplay}
                </span>
                <span className="cx-advanced-select__arrow"></span>
            </div>

            {isSelectOpen && (
                <div className="cx-advanced-select__selectContainer__popup">
                    <i className="fa-sharp fa-solid fa-caret-up"></i>
                    <input
                        ref={input}
                        type="text"
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value);
                        }}
                        placeholder="Search..."
                    />
                    <ul>
                        {options
                            ?.filter(
                                (o) =>
                                    o.label
                                        .toLocaleLowerCase()
                                        .indexOf(filter.toLocaleLowerCase()) >
                                    -1
                            )
                            .map((o, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() => {
                                            selectValue(o);
                                            toggleSelect();
                                        }}
                                        className={
                                            isActive(o)
                                                ? "cx-advanced-select__active-item"
                                                : ""
                                        }
                                    >
                                        {o.label}
                                        {o.value?.extra?.metric && (
                                            <span
                                                title={
                                                    o.value.extra.metric.label
                                                }
                                            >
                                                {o.value.extra.metric.value}
                                            </span>
                                        )}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CXAdvancedSelect;
