import React from 'react'
import Highcharts, { color } from 'highcharts'
import mainHelper from "../../../../helpers/main";
import HighchartsReact from 'highcharts-react-official'
import { useQuery } from 'react-query';
import { getEvergreenPosts } from "../evergreenAnalysisAPI"
import '../evergreenAnalysis.scss'

let helpers = new mainHelper();

const EvergreenTopPosts = () => {
    const { data, isLoading } = useQuery(['get-evergreen-posts'], getEvergreenPosts, {
        refetchOnWindowFocus: false
    });

    const getChartData = () => {
        if (!data) return [];
        let posts = {}, series = [];

        for (let i in data) {
            let post = data[i],
                title = post.title;
            if (!posts[title]) {
              posts[title] = {
                cumulativeViews: 0,
                viewsData: []
              };
            }
            const currentViews = post.pageviews;
            const previousCumulativeViews = posts[title].cumulativeViews;
            const cumulativeViews = currentViews + previousCumulativeViews;
            posts[title].cumulativeViews = cumulativeViews;
            posts[title].viewsData.push([
              Date.parse(post.date),
              cumulativeViews
            ]);
          }
        for (let j in posts) {
            series.push({
                yAxis: 0, dataLabels: { enabled: false },
                type: 'spline', name: j, data: posts[j]['viewsData'], dashStyle: "", marker: { enabled: false, "symbol": "circle" }
            })
        }
        return series;
    }
    const options = {
            "title": { "text": "" },
            "subtitle": { "text": "" },
            "exporting": { "enabled": false },
            "chart": {
                "type": "spline", "polar": false, "backgroundColor": "#32334d", "height": 318, //"width": 1000
                },
            yAxis: {
                title: {
                    text: 'Cumulative Pageviews'
                }
            },

            xAxis: {
                tickInterval: 7 * 24 * 3600 * 1000, // one week
                type: 'datetime',
                labels: {
                    style: {
                        color: '#aeaeae'
                    }
                },
                'minPadding': 0
            },
            credits: {
                enabled: false
            },

            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                itemStyle: {
                     color: '#fff'
                }
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false,
                    }
                }
            },
            "tooltip": {
                "shared": true,
                "borderWidth": 1,
                "formatter": function () {
                    return this.points.reduce(function (s, point) {
                        return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
                    }, '<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) + '</div>');
                }
            },
            series: getChartData(),

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            itemStyle: {
                                color: '#808080'
                           }
                            
                        }
                    }
                }]
            }
        }
    
    return (

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />

    )
}
export default EvergreenTopPosts;