import React, { useState, useEffect } from "react";
import CxLoader from "../components/cxLoader";
import { useQuery } from "react-query"
import { CXPopup } from "../../CXComponents" 
import PopupNew from "../components/popup_new";
import useEmailNewsletterStore from "./emailNewsletterStore";
import EmailNewsletterCard from "./components/EmailNewsletterCard"
import {getList, getPublishers} from "./emailNewsletterAPI"
import EmailNewsletterForm from "./components/emailNewsletterForm";
import {loadPublisher} from "../DigestReportSetting/DigestReportSettingAPI";

function EmailNewsletterList() {
  
    const { isLoading, isFetching, data } = useQuery(["email-newsletter-list"], ()=>{
        return getList();
    }, { refetchOnWindowFocus: false });

    const getPublishersQuery = useQuery(["publisher-list"], ()=>{
        return getPublishers();
    }, { refetchOnWindowFocus: false });
    const getPublisherListByOrganization = useQuery(["publisher"], ()=>{
        return loadPublisher();
    },{ refetchOnWindowFocus: false })

    const isOpen = useEmailNewsletterStore(state=>state.isOpen);
    const setIsOpen = useEmailNewsletterStore(state=>state.setIsOpen);
    const setActionType = useEmailNewsletterStore(state=>state.setActionType);
    const actionType = useEmailNewsletterStore(state=>state.actionType);
    const setItemToLoad = useEmailNewsletterStore(state=>state.setItemToLoad);


    const openPopupForm = (emailN) => {
        setItemToLoad(emailN.id);
        setActionType('edit');
        setIsOpen(true);
    }

    
    return (
        <>
            <div className="content">
                <div>
                    <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Email Newsletter</h4>
                    <br />
                    <br />
                    <div className="cx-btn btn-blue-gradient" onClick={()=>{setActionType('create'); setIsOpen(true)}}>
                        <i className="fa fa-plus"></i> Create Newsletter
                    </div>
                </div>
                {
                    (!isLoading && !isFetching && !getPublishersQuery.isLoading)
                    ? <div className="segmentationList cx-mt-2">
                        {/*<div className="newsletter-card-container add-newsletter-btn">*/}

                        {/*</div>*/}
                        {
                            data && 
                            data.map((emailN, i)=>{
                                let schedule;
                                try{
                                    schedule = JSON.parse(emailN.schedule);
                                } catch(err){ throw new Error(err)}
                                if(!schedule) return '';
                                return (
                                    <div onClick={()=>openPopupForm(emailN)}>
                                        <EmailNewsletterCard
                                            key={i}
                                            id={emailN.id}
                                            title={emailN.title}
                                            isActive={emailN.isActive}
                                            timeToSend={schedule.hour}
                                            scheduledDay={schedule.day}
                                            scheduledType={schedule.type}
                                            subscribersCount={emailN.subscribersCount}
                                        />
                                    </div>
                                )
                            })
                        }
                        <PopupNew title={(actionType === 'create' ? "Create" : "Edit") + " Email Newsletter"} width={'40%'} isPopupOpen={isOpen} togglePopup={()=>setIsOpen(false)}>
                            <EmailNewsletterForm publisherList={[getPublisherListByOrganization.data]}/>
                        </PopupNew>
                    </div>
                    : <CxLoader minHeight="80vh"/>
                }
            </div>
        </>
    );
}

export default EmailNewsletterList;
