import React, { Component } from "react";
import { connect } from "react-redux";
import { loginActions } from "../../actions";
import NotificationAlert from "react-notification-alert";
import logo from "assets/img/logo.png";
import Throbber from "../components/Throbber";
import { Form, Field, reduxForm } from "redux-form";
import { Input } from "../components/formElts";
import { Card, CardBody, Container, Col } from "reactstrap";
import emailValidator from "email-validator";

class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.props.handleSubmit;
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keypress", (e) => {
      if (e.key === "Enter") this.loginButton(e);
    });
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  alertMsg = (msg, icon = "tim-icons icon-simple-remove", type = "danger") => {
    this.refs.notificationAlert.notificationAlert({
      place: "tc",
      message: msg,
      type: type,
      icon: icon,
      autoDismiss: 7,
    });
  };

  loginButton = (e) => {
    if (e) e.preventDefault();
    this.handleSubmit(this.onSubmit)();
  };

  onSubmit = async (formVals) => {
    this.setState({ loading: true });

    await this.props.login(formVals);

    this.alertMsg(
      "Logged in successfully",
      "tim-icons icon-check-2",
      "success"
    );
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.loginError &&
      this.props.loginError !== prevProps.loginError
    ) {
      this.alertMsg(this.props.loginError);
      this.props.errorEvent(undefined);
      this.setState({ loading: false });
    }
  }

  renderLogoHeader = () => {
    return (
      <div className={"cx-login-logo mt-4 mb-4 text-center"}>
        <img
          src={logo}
          className={"mb-3"}
          style={{ width: "50px" }}
          alt={"cx logo"}
        />
        <div>
          <h3>COGNATIVEX</h3>
        </div>
      </div>
    );
  };

  renderLoader = () => {
    return (
      <div className={"d-flex justify-content-center align-items-center"}>
        <Throbber wid={65} />
      </div>
    );
  };

  renderForm = () => {
    return (
      <Form className={`cx-login-form px-4`} onSubmit={() => false}>
        <Field
          showMsg={true}
          icon={"fa fa-envelope"}
          type={"email"}
          className={"cx-login-input"}
          placeholder={"Email *"}
          name={"email"}
          component={Input}
        />
        <Field
          showMsg={true}
          icon={"tim-icons icon-lock-circle"}
          type={"password"}
          className={"cx-login-input"}
          placeholder={"Password *"}
          name={"password"}
          component={Input}
        />
        <div className={"cx-action-buttons text-center pt-4 pb-4 pt-0"}>
          <button
            type="button"
            className={"btn cx-focus-btn m-0-imp"}
            style={{ padding: "8px 36px" }}
            onClick={(e) => {
              this.loginButton(e);
            }}
          >
            Login
          </button>
        </div>
      </Form>
    );
  };

  render() {
    return (
      <>
        <div className={"cx-notification-alert"}>
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-login card-white">
                <CardBody style={{ maxHeight: "390px" }}>
                  {this.renderLogoHeader()}
                  {this.state.loading ? this.renderLoader() : this.renderForm()}
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
  return {
    loginError: (state.login && state.login.error) || null,
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(loginActions.login(data)),
    errorEvent: (err) => dispatch(loginActions.errorEvent(err)),
  };
};

function validate(form) {
  const errors = {};
  if (!form.email) {
    errors.email = "Field is required";
  }
  if (form.email && !emailValidator.validate(form.email)) {
    errors.email = "Email is not valid";
  }
  if (!form.password) {
    errors.password = "Field is required";
  }
  return errors;
}

Login = reduxForm({
  form: "Login",
  enableReinitialize: true,
  validate,
})(Login);

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login;
