import {
    SET_ORGANIZATION_ACTION_TYPE,
    FETCH_ORGANIZATIONS,
    FETCH_ORGANIZATION_ITEM,
    UPDATE_ORGANIZATION,
    UPDATE_ERR_ORGANIZATION,
    CREATE_ORGANIZATION,
    CREATE_ERR_ORGANIZATION,
    DELETE_ORGANIZATION,
    DELETE_ERR_ORGANIZATION,
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_ORGANIZATION_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_ORGANIZATIONS:
            return { ...state, List: action.payload };
        case FETCH_ORGANIZATION_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_ORGANIZATION:
            return { ...state, update: action.payload };
        case UPDATE_ERR_ORGANIZATION:
            return { ...state, updateErr: action.payload };
        case CREATE_ORGANIZATION:
            return { ...state, create: action.payload };
        case CREATE_ERR_ORGANIZATION:
            return { ...state, createErr: action.payload };
        case DELETE_ORGANIZATION:
            return { ...state, delete: action.payload };
        case DELETE_ERR_ORGANIZATION:
            return { ...state, deleteErr: action.payload };     
        default:
            return state;
    }
}