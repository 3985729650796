import React  from "react";
import { connect } from "react-redux";
import {Card, CardBody, CardHeader} from "../../../components/Card/card";
import mainHelpers from "../../../helpers/main";
import PerfectScrollbar from "perfect-scrollbar";

let helper = new mainHelpers();
var ps;
class AdsList extends React.Component {

    componentDidMount() {
        // by default set drawer to closed
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.elementRef);
        }else{
            this.refs.elementRef.style.overflow = 'auto';
        }
    }

    printTypeStatus = (deliveryStatus) => {
        const getColor = helper.getStatusColor(deliveryStatus);
        return <>
            <span className={"cx-table-ico-color"} style={{backgroundColor: getColor}}></span>
            <span className={"cx-table-ico-state"} style={{color: getColor}}>{deliveryStatus}</span>
        </>;
    }

    adBox = (ad) => {
        return (
            <div className={"cx-adslist-box"} title={ad.title}>
                <a href={ad.clickUrl} target={"_blank"}>
                    <div className={"cx-adslist-box-image"} style={{backgroundImage: 'url('+ ad.image +')'}}></div>
                    <div className={"cx-adlist-box-content"}>
                        <div className={"cx-adlist-box-title"}>
                            <label>Title: </label>&nbsp;&nbsp;<span className={"cx-adlist-box-title-text"}>{ad.title}</span>&nbsp;&nbsp;<i className="fas fa-external-link-alt"></i>
                        </div>
                        <div className={"cx-adlist-box-lang"}>
                            <label>Lang: </label> <span>{ad.language}</span>
                        </div>
                        <div className={"cx-adlist-box-advertiser"} title={(ad.advertiserName)? ad.advertiserName: ' - '}>
                            <label>Advertiser: </label>&nbsp;&nbsp;<span className={"cx-adlist-box-advertiser-name"}>{(ad.advertiserName)? ad.advertiserName: ' - '}</span>
                        </div>
                        <div className={"cx-adlist-box-status"}>
                            {this.printTypeStatus(ad.delivery)}
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    render() {
        return (
            <div className={"cx-campaign-adslist"} ref={"elementRef"}>
                {(this.props.item && this.props.item.ads && this.props.item.ads.length)?
                    <div className={'cx-campaign-adslist-container'}>
                        {this.props.item.ads.map((ad)=>{
                            return this.adBox(ad);
                        })}
                    </div>
                    :
                    <span className={"cx-no-adlist-found"}>NO ADS FOR THIS ADSET</span>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        item: (state.mediaPlanner && state.mediaPlanner.allocationAdsetItem) || null,
    }
}

// export and connect data to store
export default connect(mapStateToProps)(AdsList);