import React from 'react';
import {useQuery} from "react-query";
import {EvergreenTotalsQuery}  from "../evergreenAnalysisAPI"
import {Grid} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const EvergreenTotals = () => {

    const {data, isLoading} = useQuery(['evergreen-performance-totals'], EvergreenTotalsQuery, {
        refetchOnWindowFocus: false
    });
    const getData = () => {
        if (!data) return [];
        return data;
    }
    let totals = getData()[0];
    return (
        <>
            {
                isLoading
                    ? "loading.."
                    :
                    <>
                        <Grid container mb={8}>
                            <Grid item md={4}>
                                <Card sx={{
                                    minWidth: 275,
                                    width: "80px",
                                    margin: "auto",
                                    backgroundColor: "#32334d",
                                    color: "white"
                                }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            Percentage of Evergreen Page Views
                                        </Typography>
                                        <Typography sx={{mb: 1.5}} variant="h4">
                                            {totals.pageviewsOldPosts}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card sx={{
                                    minWidth: 275,
                                    width: "80px",
                                    margin: "auto",
                                    backgroundColor: "#32334d",
                                    color: "white"
                                }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                        Percentage of Evergreen Articles from Last week
                                        </Typography>
                                        <Typography sx={{mb: 1.5}} variant="h4">
                                            {totals.percentageOfTotalPageviews}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card sx={{
                                    minWidth: 275,
                                    width: "80px",
                                    margin: "auto",
                                    backgroundColor: "#32334d",
                                    color: "white"
                                }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                        Page Views from Posts Published Last Week
                                        </Typography>
                                        <Typography sx={{mb: 1.5}} variant="h4">
                                            {totals.percentageOfPostsStillGetPageviews}%
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </>
            }
        </>
    );

}

export default EvergreenTotals;