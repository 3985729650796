import React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class PixelScript extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            script: (this.props.script)? this.props.script: null
        };
    }

    componentDidUpdate(prevProps){
        if(this.props.script != prevProps.script) {
            this.setState({script: this.props.script});
        }
    }

    closePopOver = (e) => {
        if (e) e.preventDefault();
        this.props.onClose();
    }

    render() {
        return (
            <>
                <SyntaxHighlighter
                    language="javascript"
                    style={docco}
                    showLineNumbers={true}
                    customStyle={{
                        backgroundColor: 'rgb(221, 243, 253)',
                        marginBottom: 0,
                        fontSize: '11px'
                    }}
                >
                    {this.state.script}
                </SyntaxHighlighter>
                <br/>
                <CopyToClipboard text={this.state.script} onCopy={() => alert("Pixel script copied successfully")}>
                    <a href={"#"} className={"cx-js-script-copy"} onClick={(e) => {
                        e.preventDefault();
                    }}>
                        <i className={"fa fa-clone"}/> Copy code snippet
                    </a>
                </CopyToClipboard>
                </>
        );
    }
}

export default PixelScript;