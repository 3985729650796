import {
    SET_ADVERTISER_ACTION_TYPE,
    FETCH_ADVERTISERS,
    FETCH_ADVERTISER_ITEM,
    UPDATE_ADVERTISER,
    UPDATE_ERR_ADVERTISER,
    CREATE_ADVERTISER,
    CREATE_ERR_ADVERTISER,
    DELETE_ADVERTISER,
    DELETE_ERR_ADVERTISER,
    FETCH_ADVERTISER_OPTIONS,
    PIXEL_LIST,
    CREATE_PIXEL,
    UPDATE_PIXEL,
    DELETE_PIXEL
} from "../constants/appConstant";
import api from '../api/advertiser';

/*
 * action creators
 */
export const setAdvertiserActionType   = (data) => ({ type: SET_ADVERTISER_ACTION_TYPE, payload: data});
const getListEvent = (data) => ({ type: FETCH_ADVERTISERS, payload: data});
const pixelsListEvent = (data) => ({ type: PIXEL_LIST, payload: data});
export const getItemEvent = (data) => ({ type: FETCH_ADVERTISER_ITEM, payload: data });
export const updateEvent = (updated) => ({ type: UPDATE_ADVERTISER, payload: updated });
export const updateErrEvent = (err) => ({ type: UPDATE_ERR_ADVERTISER, payload: err });
export const createEvent = (created) => ({ type: CREATE_ADVERTISER, payload: created });
export const createErrEvent = (err) => ({ type: CREATE_ERR_ADVERTISER, payload: err });
export const deleteEvent = (deleted) => ({ type: DELETE_ADVERTISER, payload: deleted });
export const deleteErrEvent = (err) => ({ type: DELETE_ERR_ADVERTISER, payload: err });
export const createPixelErr = (err) => ({ type: CREATE_PIXEL, payload: err });
export const updatePixelErr = (err) => ({ type: UPDATE_PIXEL, payload: err });
export const deletePixelErr = (err) => ({ type: DELETE_PIXEL, payload: err });

const advertisersOptions = (advertisers) => ({ type: FETCH_ADVERTISER_OPTIONS, payload: advertisers});

export const fetchAdvertisersOptions = () => {
    return (dispatch) => {
        return api.getListByOrganization()
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data){
                    res = [];
                    for(let i in response.data.data){
                        const adv = response.data.data[i];
                        res.push({label: adv.name, value: adv.id});
                    }
                }
                dispatch(advertisersOptions(res))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const getList = () => {
    return (dispatch) => {
        return api.getList()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getListEvent(res))
            })
            .catch(error => {
                dispatch(getListEvent(null))
                throw (error);
            });
    };
};

export const getItem = (data) => {
    return (dispatch) => {
        return api.getItem(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getItemEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const update = (data) => {
    return (dispatch) => {
        return api.updateItem(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(updateEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(updateErrEvent('Error'));
                throw (error);
            });
    };
}

export const create = (data) => {
    return (dispatch) => {
        return api.create(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(createEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(createErrEvent('Error'));
                throw (error);
            });
    };
}

export const deleteItem = (data) => {
    return (dispatch) => {
        return api.delete(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(deleteEvent(res))
                dispatch(deleteErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(deleteErrEvent('Error'));
                throw (error);
            });
    };
}

export const pixelList = (data) => {
    return (dispatch) => {
        return api.pixelsList(data)
            .then(response => {
                let res = null;
                // if (response && response.data && response.data.data)
                //     res = response.data.data;
                if (response && response.data && response.data.data){
                    res = response.data.data.map((pixel)=>{
                        pixel.label = pixel.name;
                        pixel.value = pixel.id;
                        return pixel;
                    });
                }
                dispatch(pixelsListEvent(res))
            })
            .catch(error => {
                dispatch(pixelsListEvent(null))
                throw (error);
            });
    };
}

export const createPixel = (data) => {
    return (dispatch) => {
        return api.createPixel(data)
            .then(response => {
                dispatch(createPixelErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(createPixelErr('Error'));
                throw (error);
            });
    };
}

export const updatePixel = (data) => {
    return (dispatch) => {
        return api.updatePixel(data)
            .then(response => {
                dispatch(updatePixelErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(updatePixelErr('Error'));
                throw (error);
            });
    };
}

export const deletePixel = (data) => {
    return (dispatch) => {
        return api.deletePixel(data)
            .then(response => {
                dispatch(deletePixelErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(deletePixelErr('Error'));
                throw (error);
            });
    };
}