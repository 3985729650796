import React, { Component } from "react";
import { Form, Field, reduxForm, reset, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import {
    Input,
    Uploader,
    SelectF,
} from '../../components/formElts'; import { popupActions, campaignActions, adActions, adsetActions, drawerActions } from "../../../actions";
import CxLoader from "../../components/cxLoader";
import { campaignCreateError } from "../../../actions/campaignActions";
import { Dropdown } from 'react-bootstrap';

const formName = "quickCreate";

//Campaign dropdown
const newCampaign = { title: 'Create New Campaign', key: 'new-camp' };
const exisitingCampaign = { title: 'Use Existing Campaign', key: 'exisiting-camp' };

//Adset dropdown
const newAdset = { title: 'Create New Adset', key: 'new-adset' };
const exisitingAdset = { title: 'Use Existing Adset', key: 'exisiting-adset' };
const skipAdset = { title: 'Skip Ad set', key: 'skip-adset' };

//Ad dropdown
const newAd = { title: 'Create New Ad', key: 'new-ad' };
const skipAd = { title: 'Skip Ad', key: 'skip-ad' };

class QuickCreate extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;

        this.state = {
            loader: false,
            msg: null,
            dropItemsCampaignSelected: newCampaign,
            dropItemsCampaign: [newCampaign, exisitingCampaign],
            dropItemsAdsetSelected: newAdset,
            dropItemsAdset: [newAdset, exisitingAdset, skipAdset],
            dropItemsAdSelected: newAd,
            dropItemsAd: [newAd, skipAd],
            msgAnimate: false
        }
    }

    campaignDropDownClick = (item) => {
        this.setState({ dropItemsCampaignSelected: item, dropItemsAdsetSelected: newAdset, dropItemsAdSelected: newAd });
        return false;
    }

    adsetDropDownClick = (item) => {
        this.setState({ dropItemsAdsetSelected: item, dropItemsAdSelected: item.key == skipAdset.key ? skipAd : newAd });
        return false;
    }

    adDropDownClick = (item) => {
        this.setState({ dropItemsAdSelected: item });
        return false;
    }

    componentDidUpdate(prevProps) {

        if (this.props.errorCreate && this.props.errorCreate != prevProps.errorCreate) {
            this.printMessage(false, 'Error! Campaign not created.', 'error', () => {
                this.props.campaignCreateError(undefined);
            });
        }

        if (this.props.activeTab != prevProps.activeTab) {
            if (this.props.activeTab == 'campaign') {
                this.setState({ dropItemsCampaignSelected: newCampaign, dropItemsAdsetSelected: newAdset, dropItemsAdSelected: newAd });
            } else if (this.props.activeTab == 'adset') {
                this.setState({ dropItemsCampaignSelected: exisitingCampaign, dropItemsAdsetSelected: newAdset, dropItemsAdSelected: newAd });
            } else if (this.props.activeTab == 'ad') {
                this.setState({ dropItemsCampaignSelected: exisitingCampaign, dropItemsAdsetSelected: exisitingAdset, dropItemsAdSelected: newAd });
            }
        }
    }

    printMessage = (loader, msg, type = 'success', callback = () => { }) => {
        const ref = this;
        if (type == "error") msg = <span style={{ color: '#F0555D' }}>{msg}</span>;
        // Update user with the message after save
        this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
            setTimeout(() => {
                ref.setState({ msgAnimate: false }, () => {
                    setTimeout(() => {
                        // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
                        ref.setState({ msg: null });
                        (callback)();
                    }, 200);
                });
            }, 2000);
        });
    }

    onSubmit = (formVals) => {
        this.setState({ loader: true }, async () => {
            let finalForm = {};

            if (this.state.dropItemsCampaignSelected.key == newCampaign.key)
                finalForm.newCampaignName = formVals.newCampaignName
            else if (this.state.dropItemsCampaignSelected.key == exisitingCampaign.key)
                finalForm.existingCampaignId = formVals.existingCampaignId.value

            if (this.state.dropItemsAdsetSelected.key == newAdset.key)
                finalForm.newAdsetName = formVals.newAdsetName
            else if (this.state.dropItemsAdsetSelected.key == exisitingAdset.key)
                finalForm.existingAdsetId = formVals.existingAdsetId.value

            if (this.state.dropItemsAdSelected.key == newAd.key)
                finalForm.newAdName = formVals.newAdName


            await this.props.postQuickCreate(finalForm);
            this.setState({ loader: false });
            this.props.reset();
            this.props.togglePopup(false);
            this.props.reloadCampaignList();
            this.props.reloadAdsetList();
            this.props.reloadAdList();
            if (this.props.submitted) {
                this.props.setDrawerVisible(true, [{ typeid: this.props.submitted.id, type: 'campaign', campaignId: this.props.submitted.id }])
            }
            this.props.resetSubmittedFlag();
        });
    }

    cancelButton = (event) => {
        event.preventDefault();
        this.props.reset();
        this.props.togglePopup(false);
    }

    submitButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSubmit)();
    }

    render() {
        return (
            <Form className={"cx-quick-create-container cx-quick-create-campaing"} onSubmit={() => (false)}>
                <div className="cx-dropitems">
                    <Dropdown>
                        <Dropdown.Toggle>{this.state.dropItemsCampaignSelected.title}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.dropItemsCampaign
                                .map(item => <Dropdown.Item key={'dropItemsCampaign-' + item.key} onClick={() => this.campaignDropDownClick(item)}>
                                    {
                                        item.key == this.state.dropItemsCampaignSelected.key ?
                                            (<strong> <i className="fa fa-check"></i> {item.title}</strong>) :
                                            (<span><i className="fa fa-check invisible"></i> {item.title}</span>)
                                    }
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="cx-dropitems-inputs">
                    {this.state.dropItemsCampaignSelected.key == newCampaign.key &&
                        <Field showMsg={true} disabled={this.state.loader} className={"cx-popup-form-layout cx-quick-create-input"} label={"Campaign name"} name={"newCampaignName"} component={Input} />
                    }
                    {this.state.dropItemsCampaignSelected.key == exisitingCampaign.key &&
                        <Field
                            showMsg={true}
                            className={"cx-create-campaign-select cx-popup-form-layout cx-quick-create-input"}
                            placeholder={"-- Choose a campaign --"}
                            label={"Use Existing"}
                            name={"existingCampaignId"}
                            component={SelectF}
                            isMulti={false}
                            options={this.props.campaigns}
                        />
                    }
                </div>

                <br />
                <div className="cx-dropitems">
                    <Dropdown>
                        <Dropdown.Toggle>{this.state.dropItemsAdsetSelected.title}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.dropItemsAdset
                                .map(item => <Dropdown.Item
                                    key={'dropItemsAdset-' + item.key}
                                    disabled={
                                        item.key == skipAdset.key && this.state.dropItemsCampaignSelected.key == exisitingCampaign.key ||
                                        item.key == exisitingAdset.key && this.state.dropItemsCampaignSelected.key != exisitingCampaign.key
                                    }
                                    onClick={() => this.adsetDropDownClick(item)}>
                                    {
                                        item.key == this.state.dropItemsAdsetSelected.key ?
                                            (<strong> <i className="fa fa-check"></i> {item.title}</strong>) :
                                            (<span><i className="fa fa-check invisible"></i> {item.title}</span>)
                                    }
                                </Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="cx-dropitems-inputs">
                    {this.state.dropItemsAdsetSelected.key == newAdset.key &&
                        <Field showMsg={true} disabled={this.state.loader} className={"cx-popup-form-layout cx-quick-create-input"} label={"Adset name"} name={"newAdsetName"} component={Input} />
                    }
                    {this.state.dropItemsAdsetSelected.key == exisitingAdset.key &&
                        <Field
                            showMsg={true}
                            className={"cx-create-campaign-select cx-popup-form-layout cx-quick-create-input"}
                            placeholder={"Choose an adset"}
                            label={"Use Exisiting"}
                            name={"existingAdsetId"}
                            component={SelectF}
                            isMulti={false}
                            options={(this.props.adsets && this.props.existingCampaignId) ? this.props.adsets.filter(x => x.campaignId == this.props.existingCampaignId.value) : []}
                        />
                    }
                </div>
                <br />
                <div className="cx-dropitems">
                    <Dropdown>
                        <Dropdown.Toggle>{this.state.dropItemsAdSelected.title}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.dropItemsAd
                                .map(item => <Dropdown.Item
                                    key={'dropItemsAd-'+item.key}
                                    disabled={item.key == newAd.key && this.state.dropItemsAdsetSelected.key == skipAdset.key}
                                    onClick={() => this.adDropDownClick(item)}>
                                    {
                                        item.key == this.state.dropItemsAdSelected.key ?
                                            (<strong> <i className="fa fa-check"></i> {item.title}</strong>) :
                                            (<span><i className="fa fa-check invisible"></i> {item.title}</span>)
                                    }
                                </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="cx-dropitems-inputs">
                    {this.state.dropItemsAdSelected.key == 'new-ad' &&
                        <Field showMsg={true} disabled={this.state.loader} className={"cx-popup-form-layout cx-quick-create-input"} label={"Ad name"} name={"newAdName"} component={Input} />
                    }
                </div>
                <br />
                {/* <div className={"cx-text"}>Creating 1 campaign, 1 ad set and 1 ad</div>
                <br /> */}
                <div className={"cx-action-buttons"}>
                    <button disabled={this.state.loader} className="btn cx-focus-btn" onClick={(e) => { this.submitButton(e) }}>
                        Save to Draft
                    </button>
                    {(this.state.loader) ? <CxLoader inPlace={true} /> : ''}
                    <button disabled={this.state.loader} className="btn" onClick={(e) => { this.cancelButton(e) }}>
                        Cancel
                    </button>
                    <span className={`d-flex align-items-center cx-form-msgs pull-right ${this.state.msgAnimate ? 'show' : ''}`}>{this.state.msg}</span>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    const existingCampaignId = formValueSelector('quickCreate')(state, 'existingCampaignId');

    return {
        submitted: (state.campaigns && state.campaigns.quickCreateFormSubmission) ? state.campaigns.quickCreateFormSubmission : false,
        errorCreate: (state.campaigns && state.campaigns.campaignCreateError) || null,
        campaigns: state.campaigns && state.campaigns.campaignList && state.campaigns.campaignList.map(item => ({ label: item.name, value: item.id })),
        adsets: state.adsets && state.adsets.adsetList && state.adsets.adsetList.map(item => ({ campaignId: item.campaignId, label: item.name, value: item.id })),
        activeTab: state.tabTable.activeTab,
        existingCampaignId: existingCampaignId,
        initialValues: {
            // campaignName: '',
            // adsetName: '',
            // adName: ''
        }
    }
};

const mapDispatchToProps = (dispatch) => ({
    postQuickCreate: (data) => dispatch(campaignActions.quickCreateCampaign(data)),
    resetSubmittedFlag: () => dispatch(campaignActions.resetQuickCreateFormSubmissionFlag()),
    togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    reloadCampaignList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
    reloadAdsetList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
    reloadAdList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
    setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
    campaignCreateError: (err) => dispatch(campaignActions.campaignCreateError(err))
});

function validate(form) {
    const errors = {};
    if (!form.campaignName) {
        errors.campaignName = "Campaign name is required";
    }
    if (!form.adsetName) {
        errors.adsetName = "Adset name is required";
    }
    if (!form.adName) {
        errors.adName = "Ad name is required";
    }
    return errors;
}


QuickCreate = reduxForm({
    form: formName,
    validate
})(QuickCreate);

QuickCreate = connect(
    mapStateToProps, mapDispatchToProps
)(QuickCreate);

export default QuickCreate;