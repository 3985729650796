import React, { Component } from "react";
import mainHelper from "../../../helpers/main";
import { widgetPerformanceActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";

const helpers = new mainHelper();

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        }
    }
    componentDidUpdate(prevProps) {
        if( !_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter &&!_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
          this.fetchData();
        }
     }    
      
    /*
    * componentDidMount
    */
    componentDidMount() {
      this.fetchData();
    }

    fetchData = async () => {
        if(this.props.dateFilter){
            this.setState({ loader: true });
            await this.props.fetchSummary(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }
    render() {
        const { summary } = this.props;
        
        return (
            <div className={"cx-summary-container"} style={{position:'relative',minHeight:'51px'}}>
                {this.state.loader ? 
                <CxLoader />
                 : ''}
                {summary &&
                    <>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>Impressions</span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.impressions, 1000000)}</strong>
                        </div>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>Views</span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.views, 1000000)}</strong>
                        </div>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>Clicks</span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.clicks, 1000000)}</strong>
                        </div>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>CTR</span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.ctr, 1000000)} %</strong>
                        </div>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>Visible 
                            
                            </span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.visibleCtr, 1000000)} %</strong>
                        </div>
                        <div className={"cx-summary-box"} >
                            <span className={"cx-summary-title"}>View rate</span>
                            <strong className={"cx-summary-value"}>{helpers.formatCXNumber(summary.vr, 1000000)} %</strong>
                        </div>
                    </>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        summary: state.widgetPerformance && state.widgetPerformance.summary
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSummary: (filter, dateFilter) => dispatch(widgetPerformanceActions.fetchWidgetPerformanceSummary(filter, dateFilter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
