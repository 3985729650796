import React from "react";
import { Card, CardTitle, CardBody, Row } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mainHelper from "../../../helpers/main";
import Tooltip from "../../../components/Tooltips/Tooltip";
import HC_more from "highcharts/highcharts-more";
import { classificationActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
HC_more(Highcharts);
let helpers = new mainHelper();
let options = {
  chart: {
    backgroundColor: "transparent",
    type: "packedbubble",
    height: "450",
  },
  legend: {
    layout: "horizontal",
    verticalAlign: "top",
    enabled: true,
    floating: false,
    itemStyle: {
      color: "#fff",
    },
  },
  style: {
    fontFamily: "Tajawal",
  },
  title: {
    text: "",
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    useHTML: true,
    formatter() {
      return this.point.text;
    },
  },
  credits: false,
  plotOptions: {
    series: {
      animation: false,
    },
    packedbubble: {
      minSize: "0%",
      maxSize: "130%",
      zMin: 0,
      zMax: 1000,
      useSimulation: false,
      layoutAlgorithm: {
        splitSeries: false,
        gravitationalConstant: 0.02,
      },
      dataLabels: {
        enabled: true,
        formatter: function () {
          return this.point.name.length > 13
            ? this.point.name.substring(0, 10) + "..."
            : this.point.name;
        },
        format: "{point.name}",
        style: {
          color: "#f5f5f5",
          fontFamily: "Tajawal",
          textOutline: "none",
          fontWeight: "normal",
        },
      },
    },
  },
};

class ClassesBubbleCluster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loader: false,
    };
    this.setClassTitle();
  }

  setClassTitle = () => {
    this.classTitle = helpers.capitalizeFirstLetter(this.props.classKey);
  };

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.classMapping, prevProps.classMapping) ||
      !_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      (this.props.dateFilter &&
        !_.isEqual(this.props.dateFilter, prevProps.dateFilter))
    ) {
      this.fetchData();
    }
  }

  componentWillMount() {
    this.fetchData();
  }

  transformValue = (val, maxUiValue, maxValue) => {
    return val;
  };

  mapToState = () => {
    let data = this.props.publishedArticles;
    const maxUiValue = 16;
    let maxValue = Math.max.apply(
      Math,
      data.map(function (o) {
        return o.published_posts;
      })
    );

    let constructData = [];
    const size_weight_tags = 1;
    const size_weight_authors = 1;
    for (let i in data) {
      let authorData = data[i];
      let className = authorData.class;

      let postCount = authorData.published_posts * size_weight_authors;
      let tags = authorData.data;
      let innerData = [];
      for (let j in tags) {
        let authorTag = tags[j];
        let tag = authorTag.name;
        let occurrenceCount = authorTag.value * size_weight_tags;
        let tagslice = tag;
        if (tag.includes(" ")) {
          tagslice = tag.split(" ")[0];
        }
        innerData.push({
          tag: tagslice,
          name: helpers.sliceString(tagslice, 13),
          value: this.transformValue(occurrenceCount, maxUiValue, maxValue),
          text:
            "<p  style='color: #000' >" +
            tag +
            "</p><p style='color: #000' ><strong>" +
            ((authorTag.value * 100) / authorData.published_posts).toFixed(2) +
            "%</strong> (" +
            authorTag.value +
            " out of " +
            authorData.published_posts +
            " published articles)</p>",
        });
      }
      constructData.push({
        tag: className,
        name: helpers.capitalizeFirstLetter(helpers.sliceString(className, 15)),
        value: postCount,
        data: innerData,
      });
    }

    // data is in construct data
    return {
      ...options,
      series: constructData,
    };
  };

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(
        {
          orderBy: "published_posts",
          sort: "DESC",
          size: 5,
          classMapping: this.props.classMapping,
        },
        this.props.pageFilters,
        this.props.dateFilter
      );
      this.setClassTitle();
      this.setState({ loader: false });
    }
  };

  render() {
    return (
      <Card>
        <CardBody style={{ height: "500px" }}>
          <Row className={"justify-content-between mx-0 mb-2"}>
            <CardTitle>
              Top <span className="cx-pink">{this.classTitle}</span> by
              published articles
            </CardTitle>
            <Tooltip
              text={
                "This card displays the top 25 tags with the most published articles. The size of the bubble corresponds to the number of published articles."
              }
            ></Tooltip>
          </Row>
          {this.state.loader ? <CxLoader /> : ""}

          {this.props.publishedArticles ? (
            <HighchartsReact
              containerProps={{ className: "highchartscss" }}
              highcharts={Highcharts}
              allowChartUpdate={true}
              immutable={true}
              options={this.mapToState()}
            />
          ) : (
            "No Data"
          )}
        </CardBody>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dateFilter: (state.filter && state.filter.date) || null,
    pageFilters:
      (state.classificationInsight &&
        state.classificationInsight.pageFilters) ||
      null,
    publishedArticles:
      (state.classificationInsight &&
        state.classificationInsight.publishedArticles) ||
      null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params, filter, dateFilter) =>
      dispatch(
        classificationActions.fetchPublishedArticles(params, filter, dateFilter)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassesBubbleCluster);
