import api from "api/contentAnalysis.js";

export const getContentAnalysisTotals = async () => {
    return api.getContentAnalysisTotals()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}