export const widgetFormDefaults = {
    "id": 0,
    "title": "",
    "key": "",
    "api_token": "",
    "slotNumber": 0,
    "schema": [],
    "outline": "[]",
    "widgetLayout": "{}",
    "widgetType": "recommendation-bottom",
    "testData": "[]",
    "appdomain": "",
    "isTest": false,
    "online": false,
    "publisherId": 0,
    "publisher": {
        "value": 0,
        "label": ""
    }
}