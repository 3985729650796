import React, {Component} from "react";
import { connect } from 'react-redux';
import {recommendationActions} from "../../../actions";
import {Form, Field, reduxForm} from "redux-form";
import {Input} from "../../components/formElts";
import EditAd from "../../campaign/components/editAdForm";

class EditDefaultFreshness extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;
    }
    saveButton = (event) =>{
        event.preventDefault();
        this.handleSubmit(this.onSubmit)();
    }
    onSubmit = (formVals) => {
        this.props.saveDefaultRecommendationFreshness(formVals);
    }
    render() {
        return (
            <Form className={"cx-recommendation-default-freshness"} onSubmit={()=>(false)}>
                <i className={"fa fa-pencil-alt"}></i>
                &nbsp;&nbsp;
                <Field
                   type={'number'}
                   className={'cx-freshness-input'}
                   name={"freshnessDays"}
                   component={Input}
                />
                <div className={"cx-action-buttons"}>
                    <button className={'btn cx-focus-btn'} onClick={(e)=> {this.saveButton(e)}}>save</button>
                </div>
            </Form>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    const defaultFreshness = (state.recommendation && state.recommendation.defaultRecommendationFreshness)? state.recommendation.defaultRecommendationFreshness : 0;
    return {
        defaultFreshness: defaultFreshness,
        initialValues: {
            freshnessDays: defaultFreshness
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveDefaultRecommendationFreshness:(val) => dispatch(recommendationActions.setDefaultFreshnessDays(val)),
    }
}

EditDefaultFreshness = reduxForm({
    form: 'EditDefaultFreshness',
})(EditDefaultFreshness)

EditDefaultFreshness = connect(
    mapStateToProps, mapDispatchToProps
)(EditDefaultFreshness)

export default EditDefaultFreshness;