import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();
const helper = new main();

const evergreenAnalysisData = {
    getevergreenAnalysisData: async () => {
        const url = apiUrl + `evergreen-posts`;
        // return auth.request.post(url, { data: JSON.stringify({fromDate:"2023-02-01", toDate:"2023-03-06", month:"2", size:"10"}) });

        return {
            data: {
                "data": [
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 43.441558441558442,
                        "visitors": 5142,
                        "pageviews": 5698,
                        "total_time_spent": 247530,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 43.6055900621118,
                        "visitors": 2335,
                        "pageviews": 2576,
                        "total_time_spent": 112328,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 39.355579868708972,
                        "visitors": 804,
                        "pageviews": 914,
                        "total_time_spent": 35971,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 33.385185185185186,
                        "visitors": 226,
                        "pageviews": 270,
                        "total_time_spent": 9014,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 41.232142857142854,
                        "visitors": 458,
                        "pageviews": 504,
                        "total_time_spent": 20781,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 44.689488910318225,
                        "visitors": 924,
                        "pageviews": 1037,
                        "total_time_spent": 46343,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 43.6266318537859,
                        "visitors": 964,
                        "pageviews": 1149,
                        "total_time_spent": 50127,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 41.4720194647202,
                        "visitors": 360,
                        "pageviews": 411,
                        "total_time_spent": 17045,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/xQqSnT74YOIOcbHaDqIprVUZC1yD8sFkuP7n8rG3dK1OK9tghnoi2yi1cUQ6AH0QyfUTppIT5m9YfykkOLMOG0BtiZhZHxYT",
                        "author": "البيان الإلكتروني",
                        "section": "فكر وفن",
                        "title": "صلاة التراويح .. وقتها وعدد ركعاتها ومشروعيتها وفضلها",
                        "url": "https://www.albayan.ae/five-senses/last-page/2020-04-24-1.3840240",
                        "avg_time_spent": 40.474626865671645,
                        "visitors": 294,
                        "pageviews": 335,
                        "total_time_spent": 13559,
                        "post_id": "1.3840240",
                        "date_published": "2020-04-23",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 2.2293047525031731,
                        "visitors": 7094,
                        "pageviews": 7091,
                        "total_time_spent": 15808,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 5.0530120481927714,
                        "visitors": 830,
                        "pageviews": 830,
                        "total_time_spent": 4194,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 4.5064548162859976,
                        "visitors": 1007,
                        "pageviews": 1007,
                        "total_time_spent": 4538,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 12,
                        "visitors": 2,
                        "pageviews": 2,
                        "total_time_spent": 24,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 26,
                        "visitors": 1,
                        "pageviews": 1,
                        "total_time_spent": 26,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/BE8e7DDJu1fnOrdDooKYyvNbUktBhDgv_vhhxkBrQo-G7tA5P5tP1-j-4rVC60okUS6l_8EpgmtyhbN7GnHhsAaoz4pUjBcs4Qj54tuTzK1tsxs",
                        "author": "دبي - البيان",
                        "section": "الاقتصادي",
                        "title": "«مجموعة الحثيلي»: دبي تسبق الزمن بأجندتها الاقتصادية",
                        "url": "https://www.albayan.ae/economy/uae/2023-01-28-1.4605902",
                        "avg_time_spent": 10.5,
                        "visitors": 3,
                        "pageviews": 4,
                        "total_time_spent": 42,
                        "post_id": "1.4605902",
                        "date_published": "2023-01-28",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 36.054054054054056,
                        "visitors": 33,
                        "pageviews": 37,
                        "total_time_spent": 1334,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 40.952924393723251,
                        "visitors": 609,
                        "pageviews": 701,
                        "total_time_spent": 28708,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 44.9162702188392,
                        "visitors": 925,
                        "pageviews": 1051,
                        "total_time_spent": 47207,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 29.657608695652176,
                        "visitors": 143,
                        "pageviews": 184,
                        "total_time_spent": 5457,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 48.6625,
                        "visitors": 349,
                        "pageviews": 400,
                        "total_time_spent": 19465,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 41.616,
                        "visitors": 770,
                        "pageviews": 875,
                        "total_time_spent": 36414,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 49.789983844911148,
                        "visitors": 547,
                        "pageviews": 619,
                        "total_time_spent": 30820,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 53.906779661016948,
                        "visitors": 411,
                        "pageviews": 472,
                        "total_time_spent": 25444,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/juMZLZnZCLcMK0cUnZdZlBAXSCrNZRWRr2r60ah-WCtk3lzqffzDyEfLkL8zetcQInvjvOxfVy2xnLbaRLSTCJmlotNj-aIoBqQasSo1M03I2E4",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "أدعية مستحبة قبل الإفطار في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-13-1.4140484",
                        "avg_time_spent": 43.432183908045978,
                        "visitors": 780,
                        "pageviews": 870,
                        "total_time_spent": 37786,
                        "post_id": "1.4140484",
                        "date_published": "2021-04-13",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 44.500974658869396,
                        "visitors": 444,
                        "pageviews": 513,
                        "total_time_spent": 22829,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 42.820512820512818,
                        "visitors": 927,
                        "pageviews": 1092,
                        "total_time_spent": 46760,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 47.140977443609025,
                        "visitors": 446,
                        "pageviews": 532,
                        "total_time_spent": 25079,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 42.39779005524862,
                        "visitors": 155,
                        "pageviews": 181,
                        "total_time_spent": 7674,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 42.720634920634922,
                        "visitors": 249,
                        "pageviews": 315,
                        "total_time_spent": 13457,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 46.836619718309862,
                        "visitors": 298,
                        "pageviews": 355,
                        "total_time_spent": 16627,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 43.106557377049178,
                        "visitors": 208,
                        "pageviews": 244,
                        "total_time_spent": 10518,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 47.512345679012348,
                        "visitors": 141,
                        "pageviews": 162,
                        "total_time_spent": 7697,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/4n5CYmUNFafmDGaoKkV1QruK8WGCEwkjP8BQamtdid_92VnLMolmxc3DBIR2hHIxun7HWUCq425gDEsJcBUg9kZLlFRKuCdCf2pylpa8k98-",
                        "author": "البيان الإلكتروني",
                        "section": "منوعات",
                        "title": "7 من أفضل الأعمال الصالحة في رمضان",
                        "url": "https://www.albayan.ae/varieties/2021-04-18-1.4144325",
                        "avg_time_spent": 50.739910313901348,
                        "visitors": 160,
                        "pageviews": 223,
                        "total_time_spent": 11315,
                        "post_id": "1.4144325",
                        "date_published": "2021-04-18",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 55.982142857142854,
                        "visitors": 217,
                        "pageviews": 280,
                        "total_time_spent": 15675,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 62.047244094488192,
                        "visitors": 207,
                        "pageviews": 254,
                        "total_time_spent": 15760,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 65.802325581395351,
                        "visitors": 210,
                        "pageviews": 258,
                        "total_time_spent": 16977,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 62.963235294117645,
                        "visitors": 107,
                        "pageviews": 136,
                        "total_time_spent": 8563,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 67.936454849498332,
                        "visitors": 244,
                        "pageviews": 299,
                        "total_time_spent": 20313,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 69,
                        "visitors": 270,
                        "pageviews": 312,
                        "total_time_spent": 21528,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 65.435251798561154,
                        "visitors": 232,
                        "pageviews": 278,
                        "total_time_spent": 18191,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 64.427083333333329,
                        "visitors": 243,
                        "pageviews": 288,
                        "total_time_spent": 18555,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/3zSJ0OiNa6T54yEQunTgLu5PL7ylHyKA0A5KNj69Voaw27KUiOw-EvGcFmXfzp9jGdRl6B2XAIvC5LURRvfG3zEcx0drKJdaB9puZpElcXxrlw",
                        "author": "البيان",
                        "section": "منوعات",
                        "title": "كيفية الرقية الشرعية على الماء",
                        "url": "https://www.albayan.ae/varieties/2021-12-11-1.4321361",
                        "avg_time_spent": 62.61904761904762,
                        "visitors": 266,
                        "pageviews": 336,
                        "total_time_spent": 21040,
                        "post_id": "1.4321361",
                        "date_published": "2021-12-11",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 16.588235294117649,
                        "visitors": 29,
                        "pageviews": 34,
                        "total_time_spent": 564,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 19.931623931623932,
                        "visitors": 107,
                        "pageviews": 117,
                        "total_time_spent": 2332,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 21.438709677419354,
                        "visitors": 139,
                        "pageviews": 155,
                        "total_time_spent": 3323,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 24.45945945945946,
                        "visitors": 70,
                        "pageviews": 74,
                        "total_time_spent": 1810,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 23.7,
                        "visitors": 198,
                        "pageviews": 220,
                        "total_time_spent": 5214,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 24.94140625,
                        "visitors": 233,
                        "pageviews": 256,
                        "total_time_spent": 6385,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 25.355670103092784,
                        "visitors": 174,
                        "pageviews": 194,
                        "total_time_spent": 4919,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 26.222857142857144,
                        "visitors": 158,
                        "pageviews": 175,
                        "total_time_spent": 4589,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/WRFdW5IYsdwWKWpLjtzDoRDLUlZGAyr1Pu-ClvbjGhL-uHGLwFUKrEi0V5jaFyjN_lpZqmhjxU9rFXPcUVhEyFsqA4FzUja18A",
                        "author": "البيان",
                        "section": "عبر الإمارات",
                        "title": "الآية التي جمعت كل حروف  اللغة العربية",
                        "url": "https://www.albayan.ae/across-the-uae/2009-08-23-1.462407",
                        "avg_time_spent": 21.846625766871167,
                        "visitors": 139,
                        "pageviews": 163,
                        "total_time_spent": 3561,
                        "post_id": "1.462407",
                        "date_published": "2009-08-22",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 12.800804828973844,
                        "visitors": 426,
                        "pageviews": 497,
                        "total_time_spent": 6362,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 13.741641337386019,
                        "visitors": 299,
                        "pageviews": 329,
                        "total_time_spent": 4521,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 11.992753623188406,
                        "visitors": 125,
                        "pageviews": 138,
                        "total_time_spent": 1655,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 14.542857142857143,
                        "visitors": 32,
                        "pageviews": 35,
                        "total_time_spent": 509,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 16.872727272727271,
                        "visitors": 54,
                        "pageviews": 55,
                        "total_time_spent": 928,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 14.186666666666667,
                        "visitors": 69,
                        "pageviews": 75,
                        "total_time_spent": 1064,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 11.952380952380953,
                        "visitors": 40,
                        "pageviews": 42,
                        "total_time_spent": 502,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 13.616666666666667,
                        "visitors": 51,
                        "pageviews": 60,
                        "total_time_spent": 817,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/OupEHSG2hcPYCYQFJiFspmmg_TZ5Tw7Eo2RXCGXFseC_nYkrMzpXeB_OHiJTFjB37bwnyhVbmDaHrTYrysooC7l2VoUWEs4",
                        "author": "تصوير: زيشان أحمد",
                        "section": "ملاحق",
                        "title": "شهر أنزل فيه القرآن (هُدىً لِلنَّاسِ وَبَيِّنَاتٍ مِنَ الْهُدَى وَالْفُرْقَانِ)",
                        "url": "https://www.albayan.ae/supplements/ramadan/first/2016-06-27-1.2669464",
                        "avg_time_spent": 15.64,
                        "visitors": 45,
                        "pageviews": 50,
                        "total_time_spent": 782,
                        "post_id": "1.2669464",
                        "date_published": "2016-06-27",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 26,
                        "visitors": 93,
                        "pageviews": 109,
                        "total_time_spent": 2834,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 27.595041322314049,
                        "visitors": 112,
                        "pageviews": 121,
                        "total_time_spent": 3339,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 23.827272727272728,
                        "visitors": 100,
                        "pageviews": 110,
                        "total_time_spent": 2621,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 20.942307692307693,
                        "visitors": 45,
                        "pageviews": 52,
                        "total_time_spent": 1089,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 20.653846153846153,
                        "visitors": 90,
                        "pageviews": 104,
                        "total_time_spent": 2148,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 25.737373737373737,
                        "visitors": 86,
                        "pageviews": 99,
                        "total_time_spent": 2548,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 29.095238095238095,
                        "visitors": 95,
                        "pageviews": 105,
                        "total_time_spent": 3055,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 27.733067729083665,
                        "visitors": 207,
                        "pageviews": 251,
                        "total_time_spent": 6961,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/HxEQK2--oVQjgUw_PglriREVKGmhhNW8uxfVeOv-1XN_epYjxFHdjPxKx4WtVS0Q-p5oG97CsV-bs0K5dgoB6zgflfzdpXXg2Q",
                        "author": "البيان",
                        "section": "ملاحق",
                        "title": "اللّهم اهدِنا فيمن هديت وعافنا فيمن عافيت",
                        "url": "https://www.albayan.ae/supplements/ramadan/prayer/2016-07-04-1.2673069",
                        "avg_time_spent": 28.553264604810998,
                        "visitors": 243,
                        "pageviews": 291,
                        "total_time_spent": 8309,
                        "post_id": "1.2673069",
                        "date_published": "2016-07-03",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 65.553191489361708,
                        "visitors": 39,
                        "pageviews": 47,
                        "total_time_spent": 3081,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 59.373333333333335,
                        "visitors": 67,
                        "pageviews": 75,
                        "total_time_spent": 4453,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 61.061403508771932,
                        "visitors": 102,
                        "pageviews": 114,
                        "total_time_spent": 6961,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 52.372549019607845,
                        "visitors": 44,
                        "pageviews": 51,
                        "total_time_spent": 2671,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 62.835164835164832,
                        "visitors": 86,
                        "pageviews": 91,
                        "total_time_spent": 5718,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 51.3177570093458,
                        "visitors": 86,
                        "pageviews": 107,
                        "total_time_spent": 5491,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 63.904761904761905,
                        "visitors": 135,
                        "pageviews": 147,
                        "total_time_spent": 9394,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 62.459770114942529,
                        "visitors": 147,
                        "pageviews": 174,
                        "total_time_spent": 10868,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/Q7dE10Z1zvfzAozh8K_PDSADK3xv9QZwQY7g0dGnnT48qrZtwVpMJ6ua8wjUq2v4Hrb1Uz2uxQF2V7vesVI-FBMPrMAsqM3SRbVG_4UtU5AWEg",
                        "author": "وكالات",
                        "section": " الصحي",
                        "title": "ما الوقت المثالي لتناول التمر بالحليب في رمضان؟ وما فوائده؟",
                        "url": "https://www.albayan.ae/health/2022-04-08-1.4410775",
                        "avg_time_spent": 66.160714285714292,
                        "visitors": 93,
                        "pageviews": 112,
                        "total_time_spent": 7410,
                        "post_id": "1.4410775",
                        "date_published": "2022-04-08",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-22",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 23.467289719626169,
                        "visitors": 97,
                        "pageviews": 107,
                        "total_time_spent": 2511,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-23",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 19.3265306122449,
                        "visitors": 88,
                        "pageviews": 98,
                        "total_time_spent": 1894,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-24",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 22.423913043478262,
                        "visitors": 85,
                        "pageviews": 92,
                        "total_time_spent": 2063,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-25",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 24.155555555555555,
                        "visitors": 42,
                        "pageviews": 45,
                        "total_time_spent": 1087,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-26",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 25.234782608695653,
                        "visitors": 105,
                        "pageviews": 115,
                        "total_time_spent": 2902,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-27",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 26.547169811320753,
                        "visitors": 98,
                        "pageviews": 106,
                        "total_time_spent": 2814,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-28",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 26.728395061728396,
                        "visitors": 77,
                        "pageviews": 81,
                        "total_time_spent": 2165,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-29",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 23.944444444444443,
                        "visitors": 100,
                        "pageviews": 108,
                        "total_time_spent": 2586,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    },
                    {
                        "date": "2023-03-30",
                        "thumb": "https://lh3.googleusercontent.com/Tn9mjsSKiDnjFlSiQf11fudeN-cPQceRSsTYVfKVg2U72Kc3mIXs4HdSM0ZYOngrFOuv_clFg5PKeamP5cOiJ8W5MM4aCJ8a80PKMmh2bQk7BF4",
                        "author": "البيان",
                        "section": " الصحي",
                        "title": "خمسة مشروبات سحرية للتخلص من الكحة نهائياً",
                        "url": "https://www.albayan.ae/health/2022-02-10-1.4366680",
                        "avg_time_spent": 22.088607594936708,
                        "visitors": 72,
                        "pageviews": 79,
                        "total_time_spent": 1745,
                        "post_id": "1.4366680",
                        "date_published": "2022-02-10",
                        "publisher": "albayan.ae"
                    }
                ],
                "api": "/evergreen-posts"
            }
        }
    },
    getEvergreenPerformanceTotals: async () => {
        const url = apiUrl + `newsletter-performance-totals`;
        // return auth.request.post(url, { data: JSON.stringify({ fromDate: "2023-01-01", toDate: "2023-03-06" }) });

        return {
            data: {
                "data": [
                    {
                        "pageviewsOldPosts": 24358,
                        "percentageOfTotalPageviews":22,
                        "percentageOfPostsStillGetPageviews": 18
                    }
                ],
                "api": "/evergreen-performance-totals"
            }
        }
    },
    sectionEvergreen: async () => {

        return {
            data: {
                "data": [
                    {
                        "section": "الإمارات",
                        "pageview":4120 ,
                        "evergreenp":11.3

                    },
                    {
                        "section": "أخبار",
                        "pageview":3681 ,
                        "evergreenp":4.4

                    },
                    {
                        "section": "رياضة",
                        "pageview":1196 ,
                        "evergreenp":1.3

                    },
                    {
                        "section": "فن",
                        "pageview":2751 ,
                        "evergreenp":2.9

                    },
                    {
                        "section": "منوعات",
                        "pageview":3714 ,
                        "evergreenp":12.7

                    },
                    {
                        "section": "اقتصاد",
                        "pageview":1456 ,
                        "evergreenp":6.1

                    },
                    {
                        "section": "سياسة",
                        "pageview":4785 ,
                        "evergreenp":3.8
                    },
                    {
                        "section": "مشاهير",
                        "pageview":2654 ,
                        "evergreenp":2.4
                    },
                ],
                "api": "/section-ever-green"
            }
        }
    }
}
export const utitlityApi = {

}

export default evergreenAnalysisData;