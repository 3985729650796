import React, { useState, useEffect } from "react";
import helper from '../../helpers/main'
import { Modal } from "@material-ui/core";
import CxLoader from "../components/cxLoader";
const getOrg = new helper().getOrganization;

function Revenue() {
    const [accessGranted, setAccessGranted] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setAccessGranted( (JSON.parse(getOrg()).name) === 'mz-mz.net' )
    }, []);

    const renderReport = () => {
        return(
            <> {isLoading
            ? <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: '70vh'
                }}
              >
                 <CxLoader />
            </div>
            : <></>}
            <iframe
                width="85%"
                height="1800px"
                src="https://datastudio.google.com/embed/reporting/49d3cc8e-935c-4d6b-83aa-6b37760df837/page/NZYzC"
                frameBorder="0"
                style={{display:'block', marginTop: '2rem', marginInline:'auto', border:0}}
                allowFullScreen
                onLoad={()=>{setIsLoading(false)}}
            ></iframe>
            </>
        )
    }

    return (
        <div className="content cx-page-revenue">
            <div>
                <h4 style={{ "marginBottom": "20px" }}><i className="tim-icons icon-money-coins"></i>&nbsp; Revenue</h4>
            </div>
            { accessGranted ? renderReport() : <h4>Sorry, your organization seems to not have access to Revenue Reports Feature by CognativeX</h4> }
        </div>
    );
}

export default Revenue;
