import React, {useState,useEffect, useRef} from 'react';
import { useController } from 'react-hook-form';
import useOutsideClick from "../hooks/useOutsideClick";


const CXMultiSelect = ({control, name, options, defaultDisplay, errorMsg, style}) => {

    const {
        field: { onChange, value },
      } = useController({
        name,
        control,
        rules: { required: true },
      });
    const [isOpen, setIsOpen] = useState(false);
    const [selections, setSelections] = useState(value || []);
    const [filter, setFilter] = useState("");
    const multiSelectRef = useRef();

    //instead of passing a single ref you can pass a list of refs to listen for the click outside them 
    useOutsideClick(multiSelectRef, ()=>{
        setIsOpen(false);
    });

    useEffect(() => {         // synchronize state with react-hook-form
        onChange(selections)
    }, [selections, onChange]);

    const toggleSelect = () => {
        setIsOpen(!isOpen);
    }

    const selectValue = (value) => {

        const selectionsClone = selections.slice();
        const index = selectionsClone.indexOf(value);   //may change the way of checking when dealing with objects(check for id or so, define your own high order fuinction)        
        index < 0
        ? setSelections([...selectionsClone, value])
        : setSelections(selectionsClone.splice(index, 1) && selectionsClone);
    }
    return(
        <div 
            className={`cx-multi-select ${isOpen&&'cx-multi-select-open'}`}
            ref={multiSelectRef}
            style={style}
        >
            <div onClick={toggleSelect} className="cx-multi-select__trigger">
                <span className='cx-multi-select__placeholder'>{selections.length + "selected" || defaultDisplay || "Select"}</span>
                <span className="cx-multi-select__arrow"></span>          
            </div>

            {
                isOpen && 
                <ul className='cx-multi-select__options'>
                    <span className='cx-multi-select__separator'></span>
                    <li
                        className={`cx-multi-select__search`} 
                    >
                        <input 
                            type="text" 
                            value={filter} 
                            onChange={(e)=>setFilter(e.target.value)}
                            placeholder="Search"
                        />
                    </li>
                    {options.filter(o=>o.label.toLowerCase().includes(filter.toLowerCase())).map((o, i)=>{
                        return  <li 
                                    style={{'--i':i}} 
                                    className={`cx-multi-select__option ${(value.includes(o.value)) && "cx-multi-select__active-option"}`} 
                                    key={i}
                                    onClick={()=>{selectValue(o.value)}}
                                >
                                    {o.label}
                                </li>
                    })}
                </ul>
            }
            <span className='errorMsg'>{errorMsg??''}</span>
            
        </div>
    )
}

export default CXMultiSelect;
