import React, { useEffect } from "react";
import { Card, CardHeader, CardBody } from "../../components/Card/card";
import Dialog from "@material-ui/core/Dialog";

const PopupNew = ({title, width, isPopupOpen, togglePopup, children}) => {


    useEffect(() => {
        if (isPopupOpen) {
            document.getElementsByClassName("ps")[0].style.overflow = "";
        }
    }, []);

    return (
        <Dialog
            open={isPopupOpen}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div
                style={{
                    maxWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
                className={`cx-popup${isPopupOpen ? " open" : ""}`}
            >
                <div style={{ zIndex: 1000, maxHeight: "100vh", width: width }}>
                    <Card>
                        <CardHeader>
                            {title}
                            <span className={"close-btn"} onClick={()=>togglePopup()}>
                                <i className={"fa fa-times"}></i>
                            </span>
                        </CardHeader>
                        <CardBody>{children}</CardBody>
                    </Card>
                </div>
            </div>
        </Dialog>
    );
}

export default PopupNew;
