import React, {useEffect, useState} from "react";
import SchemaField from "./SchemaField";
import { CXInputField, CXSelectField, CXCheckbox } from "CXComponents";
import { Row, Col } from "react-bootstrap";
import CxLoader from "views/components/cxLoader";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useForm, useWatch } from "react-hook-form";
import {
    readWidget,
    editWidget,
    createWidget,
    getWidgetTypes,
    getMappingsList,
} from "../widgetAPI";
import useWidgetStore from "../widgetStore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useCodeMirror from "hooks/useCodeMirror";
import { widgetFormDefaults } from "constants/formValuesConstant";

const WidgetForm = ({ readResult, publishersList, mappingsList }) => {
    const [activeTab, setActiveTab] = useState("General");
    const [HTML, setHTML] = useState(
        JSON.parse(readResult.data?.widgetLayout || "{}").template ||
            "<!-- write some HTML -->"
    );
    const [CSS, setCSS] = useState(
        JSON.parse(readResult.data?.widgetLayout || "{}").css ||
            "/* Let's be stylish */"
    );
    const [testJSON, setTestJSON] = useState(readResult.data?.testData || "[]");
    const HTMLEditorRef = useCodeMirror(HTML, "HTML", setHTML);

    const CSSEditorRef = useCodeMirror(CSS, "CSS", setCSS);

    const testJSONEditorRef = useCodeMirror(testJSON, "JSON", setTestJSON);

    const qc = useQueryClient();

    const schema = yup
        .object({
            publisher: yup.object().shape({
                label: yup.string().required("Choosing a publisher is required"),
                value: yup.number().required("Choosing a publisher is required"),
            }).required("Choosing a publisher is required"),
            title: yup.string().required("Please enter a title"),
        })
        .required();

    const togglePopup = useWidgetStore((state) => state.togglePopup);
    const actionType = useWidgetStore((state) => state.actionType);
    const canSubmit = useWidgetStore((state) => state.canSubmit);

    const editWidgetQuery = useMutation((widget) => {
        return editWidget(widget);
    });
    const createWidgetQuery = useMutation((widget) => {
        return createWidget(widget);
    });

    const isSaving = editWidgetQuery.isLoading || createWidgetQuery.isLoading;

    const getInitialValues = (data) => {
        if (!data) return {};

        const mapped = { ...data };

        mapped.outline = JSON.parse(data.outline);

        let type = JSON.parse(data.widgetLayout)?.type?.toLowerCase();
        if (type) {
            if (type.includes("bottom")) {
                type = { label: "Bottom", value: "recommendation-bottom" };
            } else if (type.includes("email")) {
                type = {
                    label: "Email Collection",
                    value: "recommendation-popup1",
                };
            } else if (type.includes("popup")) {
                type = { label: "Popup", value: "recommendation-popup1" };
            } else if (type.includes("side")) {
                type = { label: "Sidebar", value: "recommendation-sidebar" };
            }
        } else {
            type = { label: "Bottom", value: "recommendation-bottom" };
        }
        mapped.type = type;

        mapped.publisher = {
            label: mapped.publisherName,
            value: mapped.publisherId,
        };
        return mapped;
    };

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...widgetFormDefaults,
            ...getInitialValues(readResult.data),
        },
    });

    const isTest = useWatch({
        control,
        name: "isTest",
    });

    const cancelButton = () => {
        togglePopup(false);
    };

    const submitButton = () => {
        handleSubmit(onSubmit)();
    };

    const onSubmit = async (formVals) => {
        const mapped = { ...formVals };
        console.log("onSubmit",CSS);
        if(formVals.type.value === 'email-collection-popup'){
          formVals.type.value = 'recommendation-popup1';
        }

        mapped.widgetLayout = {
            template: HTML,
            css: CSS,
            type: formVals?.type?.value || "Not Specified",
        };
        mapped.testData = JSON.parse(testJSON || "[]");
        if(!Array.isArray(formVals.outline)) formVals.outline = [];
        mapped.schema = formVals.outline?.map((s) => s.logic);
        mapped.outline = formVals.outline?.map((s) => ({
            logic: s.logic,
            category: s.category,
            allowBoosting: s.allowBoosting,
        }));
        mapped.publisherId = mapped.publisher.value;
        mapped.appdomain = mapped.publisher.label;
        if (actionType === "create") {
            await createWidgetQuery.mutateAsync(mapped);
        } else {
            await editWidgetQuery.mutateAsync(mapped);
            //empty the cache for this query because it changed
            qc.invalidateQueries(["widget-read", readResult.data.id]);
        }
        qc.invalidateQueries(["widget-list"]);
        togglePopup();
    };

    const renderGeneralForm = () => {
        return (
            <>
                <CXSelectField
                    label="Publisher"
                    name="publisher"
                    control={control}
                    errorMsg={errors?.publisher?.message}
                    required={true}
                    options={publishersList?.map((pub) => ({
                        label: pub.name,
                        value: pub.id,
                    }))}
                    defaultDisplay="Select Publisher"
                    displayProp="label"
                />
                <br />
                <CXSelectField
                    {...{
                        control,
                        label: "Type",
                        options: getWidgetTypes(),
                        name: "type",
                    }}
                />
                <br />
                <CXInputField
                    label="Title"
                    name="title"
                    register={register}
                    errorMsg={errors?.title?.message}
                />
                <br />
                <CXInputField
                    label="Key"
                    name="key"
                    register={register}
                    errorMsg={errors?.key?.message}
                />
                <br />
                <CXInputField
                    label="API Token"
                    name="api_token"
                    register={register}
                    errorMsg={errors?.api_token?.message}
                />
                <br />
                <CXCheckbox
                    label="Is Online"
                    name="online"
                    register={register}
                />
                <br />
                <CXCheckbox label="Is Test" name="isTest" register={register} />
                {isTest ? (
                    <div>
                        <br />
                        <label>JSON Test Data:</label>
                        <div ref={testJSONEditorRef.ref}> </div>
                    </div>
                ) : (
                    ""
                )}

                <br />
                <div className={"cx-action-buttons"}>
                    <button
                        type="button"
                        className="btn cx-focus-btn"
                        onClick={submitButton}
                        disabled={!canSubmit}
                    >
                        Save
                    </button>
                    {isSaving ? <CxLoader inPlace={true} /> : ""}
                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };

    const renderSchemaForm = () => {
        return (
            <>
                <SchemaField
                    {...{
                        control,
                        register,
                        mappingsList,
                    }}
                />
                <br />
                <div className={"cx-action-buttons"}>
                    <button
                        type="button"
                        className="btn cx-focus-btn"
                        onClick={submitButton}
                        disabled={!canSubmit}
                    >
                        Save
                    </button>

                    {isSaving ? <CxLoader inPlace={true} /> : ""}

                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };
    const renderLayoutForm = () => {
        return (
            <>
                <label>HTML:</label>
                <div ref={HTMLEditorRef.ref}></div>
                <br />
                <label>CSS:</label>
                <div ref={CSSEditorRef.ref}></div>

                <br />
                <div className={"cx-action-buttons"}>
                    <button
                        type="button"
                        className="btn cx-focus-btn"
                        onClick={submitButton}
                        disabled={!canSubmit}
                    >
                        Save
                    </button>

                    {isSaving ? <CxLoader inPlace={true} /> : ""}

                    <button
                        type="button"
                        className="btn"
                        onClick={cancelButton}
                    >
                        Cancel
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            {readResult.isLoading ? (
                <CxLoader minHeight="50vh" />
            ) : (
                <>
                    <Row className={"mx-0 mb-4"}>
                        <Col className={"px-0"}>
                            <div
                                className={"reactive-tabs publisher-form-tabs"}
                            >
                                <div
                                    className={`reactive-tab ${
                                        activeTab === "General" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("General")}
                                >
                                    <i className={"fa fa-list"}></i> &nbsp;{" "}
                                    <p>General</p>
                                </div>
                                <div
                                    className={`reactive-tab ${
                                        activeTab === "Schema" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("Schema")}
                                >
                                    <i className={"fa fa-grip"}></i> &nbsp;{" "}
                                    <p>Schema</p>
                                </div>
                                <div
                                    className={`reactive-tab ${
                                        activeTab === "Layout" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("Layout")}
                                >
                                    <i className={"fa fa-code"}></i> &nbsp;{" "}
                                    <p>Layout</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className={"mx-0"}>
                        <Col className={"px-0"}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div
                                    className={`${
                                        activeTab === "General" ? "" : "d-none"
                                    }`}
                                >
                                    {renderGeneralForm()}
                                </div>
                                <div
                                    className={`${
                                        activeTab === "Schema" ? "" : "d-none"
                                    }`}
                                >
                                    {renderSchemaForm()}
                                </div>
                                <div
                                    className={`${
                                        activeTab === "Layout" ? "" : "d-none"
                                    }`}
                                >
                                    {renderLayoutForm()}
                                </div>
                            </form>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

const LoadData = ({ publishersList }) => {
    const actionType = useWidgetStore((state) => state.actionType);
    const itemToLoad = useWidgetStore((state) => state.itemToLoad);

    const readWidgetQuery = useQuery(
        ["widget-read", itemToLoad],
        () => {
            if (actionType === "edit") return readWidget(itemToLoad);
        },
        { refetchOnWindowFocus: false }
    );

    const mappingsListQuery = useQuery(
        ["get-mapping-list", itemToLoad],
        getMappingsList,
        { refetchOnWindowFocus: false }
    );



    return (
        <>
            {readWidgetQuery.isLoading || mappingsListQuery.isLoading ? (
                <CxLoader minHeight="300px" />
            ) : (
                <WidgetForm
                    readResult={readWidgetQuery}
                    publishersList={publishersList}
                    mappingsList={mappingsListQuery.data}
                />
            )}
        </>
    );
};

export default LoadData;
