import React from 'react';

const SurveyCard = ({surveyType, title, isActive, asnwers, onClick}) => {
    return (
        <div className="newsletter-card-container" onClick={onClick}>
        <h4 className="title">{title}</h4>
        <span
            className={`status status-${isActive ? "active" : "not-active"}`}
            title={isActive ? "Active" : "Not Active"}
        ></span>
        <span className="schedule">{surveyType}</span>
        <div className="metric">
            <span>
                answers
            </span>
            {asnwers}
        </div>
    </div>
    );
}

export default SurveyCard;
