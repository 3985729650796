import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { CXInputField, CXCheckbox, CXRadio } from "../../../CXComponents";
import CxLoader from "../../components/cxLoader";
import { useForm } from "react-hook-form";
import usePublisherStore from "./PublisherStore";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { readPublisher, createPublisher, editPublisher } from "./publisherAPI";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {isDev} from "../../../helpers/global";


export const CreatePublisher = () => {
  const qc = useQueryClient();

  const schema = yup.object({
    domain: yup.string("Domain should be a string").max(30).required(),
    portalEmail: yup.string().email("Please make sure the email is a valid email").required("Please enter an email"),
    portalPassword: yup.string().min(3).max(30),
    widgetServing: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      'Enter correct url!'
    ),
    adServing: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      'Enter correct url!'
    ),
    static: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      'Enter correct url!'
    ),
    tracking: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      'Enter correct url!'
    ),
    articleUrl: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$|^$/,
      'Enter correct url!'
    )
  }).required();

  const togglePopup = usePublisherStore((state) => state.togglePopup);
  const actionType = usePublisherStore((state) => state.actionType);

  const createPublisherQuery = useMutation((publisher) => {
    if (actionType === "create") return createPublisher(publisher);
  });

  const isSaving = createPublisherQuery.isLoading ;

  const cancelButton = () => {
    togglePopup(false);
  };

  const submitButton = () => {
    handleSubmit(onSubmit)();
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (formVals) => {
    await createPublisherQuery.mutateAsync(formVals);
    // empty the cache for this query because it changed
    qc.invalidateQueries(["publisher-list"]);
    togglePopup();
  };

  return (
        <form
          className={"cx-recommendatdion-config-form-container"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CXInputField
              label="Domain *"
              name="domain"
              register={register}
              errorMsg={errors?.domain?.message}
          />
          <br />
          <CXInputField
              label="Portal Email *"
              name="portalEmail"
              register={register}
              errorMsg={errors?.portalEmail?.message}
          />
          <br />
          <CXInputField
              label="Portal Password *"
              name="portalPassword"
              register={register}
              errorMsg={errors?.portalPassword?.message}
          />
          <br />
          <div className={"cx-action-buttons"}>
            <button
              type="button"
              className="btn cx-focus-btn"
              onClick={submitButton}
            >
              Save
            </button>
            {isSaving && <CxLoader inPlace={true} />}
            <button
              type="button"
              className="btn"
              onClick={cancelButton}
            >
              Cancel
            </button>
            {
              isSaving && <span>This may take a while! (~20 secs)</span>
            }
          </div>
        </form>
  );
}


export const EditPublisher = ({readResult}) => {
  const [activeTab, setActiveTab] = useState("General");

  const qc = useQueryClient();

  const schema = yup.object({
    domain: yup.string("Domain should be a string").max(30).required(),
    scriptVersion: yup.string().max(9).required(),
    floorCpc: yup.number().typeError("Please specify a number").required(),
    fCap: yup.number().typeError("Please specify a number").required(),
    discrepancyPercentage: yup.number().typeError("Please specify a number").max(100).required(),
    revenueSharePercentage: yup.number().typeError("Please specify a number").max(100).required(),
  }).required();

  const togglePopup = usePublisherStore((state) => state.togglePopup);
  

  const editPublisherQuery = useMutation((publisher) => {
    return editPublisher(publisher);
  });

  const isSaving = editPublisherQuery.isLoading;

  const cancelButton = () => {
    togglePopup(false);
  };

  const submitButton = () => {
    handleSubmit(onSubmit)();
  };

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {...readResult.data, readPostIdFromUrl: readResult.data?.readPostIdFromUrl ? "true" : "false"}
  });

  const onSubmit = async (formVals) => {
    if(isDev){
      if(formVals.widgetServing === "") formVals.widgetServing = "new-widget-serving-dhczmo2e7a-ew.a.run.app";
      if(formVals.adServing === "") formVals.adServing = "log-dev.cognativex.com";
      if(formVals.static === "") formVals.static = "static-dev.cognativex.com";
      if(formVals.tracking === "") formVals.tracking = "log-dev.cognativex.com";
    }
    await editPublisherQuery.mutateAsync(formVals);
    //empty the cache for this query because it changed
    await qc.invalidateQueries(["publisher-read", readResult.data.id]);
    togglePopup();
  };

  const renderConfigForm = () => {
    return <>
      <div className="publisher-form-configuration">
        <CXCheckbox
            label="Lite Integration"
            name="isLite"
            register={register}
            errorMsg={errors?.liteIntegration?.message}
        />
        <CXCheckbox
            label="Campaigns Enabled"
            name="isCampaignsEnabled"
            register={register}
            errorMsg={errors?.isCampaignsEnabled?.message}
        />
        <CXCheckbox
            label="Pageviews Enabled"
            name="isPageviewsEnabled"
            register={register}
            errorMsg={errors?.isPageviewsEnabled?.message}
        />
        <CXCheckbox
            label="Heartbeats Enabled"
            name="isHeartbeatsEnabled"
            register={register}
            errorMsg={errors?.isHeartbeatsEnabled?.message}
        />
        <CXCheckbox
            label="Widgets Enabled"
            name="isWidgetsEnabled"
            register={register}
            errorMsg={errors?.isWidgetsEnabled?.message}
        />
        <CXRadio
            label="Read PostId From:"
            name="readPostIdFromUrl"
            className="transform-down-3"
            options={[{label: "URL", value:true}, {label: "JSON-LD", value:false}]}
            register={register}
            errorMsg={errors?.readPostIdFromUrl?.message}
        />
      </div>
      <br/>
      <div className={"cx-action-buttons"}>
          <button
            type="button"
            className="btn cx-focus-btn"
            onClick={submitButton}
          >
            Save
          </button>
          {isSaving && <CxLoader inPlace={true} />}
          <button
            type="button"
            className="btn"
            onClick={cancelButton}
          >
            Cancel
          </button>
          {
            isSaving && <span>This may take a while! (~20 secs)</span>
          }
      </div>
      </>
  }


  const renderAdManagementForm = () => {
    return <>
    <CXInputField
        type="number"
        className="publisher-form-general__input-number"
        label="Floor cpc"
        name="floorCpc"
        register={register}
        errorMsg={errors?.floorCpc?.message}
    />
    <br />
    <CXInputField
        type="number"
        className="publisher-form-general__input-number"
        label="Discrepancy %"
        name="discrepancyPercentage"
        register={register}
        errorMsg={errors?.discrepancyPercentage?.message}
    />
    <br />
    <CXInputField
        type="number"
        className="publisher-form-general__input-number"
        label="Revenue Share %"
        name="revenueSharePercentage"
        register={register}
        errorMsg={errors?.revenueSharePercentage?.message}
    />
    <br />
    <CXInputField
        type="number"
        className="publisher-form-general__input-number"
        label="Freq Cap Per Day"
        name="fCap"
        register={register}
        errorMsg={errors?.fCap?.message}
    />
    <br />
    <div className={"cx-action-buttons"}>
      <button
        type="button"
        className="btn cx-focus-btn"
        onClick={submitButton}
      >
        Save
      </button>
      {isSaving && <CxLoader inPlace={true} />}
      <button
        type="button"
        className="btn"
        onClick={cancelButton}
      >
        Cancel
      </button>
      {
        isSaving && <span>This may take a while! (~20 secs)</span>
      }
    </div>
      </>
  }


  const renderGeneralForm = () => {
    return (
      <>
          <CXInputField
              label="Domain"
              name="domain"
              register={register}
              errorMsg={errors?.domain?.message}
          />
          <br />
          <CXInputField
              label="Script Version"
              name="scriptVersion"
              register={register}
              errorMsg={errors?.scriptVersion?.message}
          />
          <br />
          <CXInputField
              label="Article URL"
              name="articleUrl"
              register={register}
              errorMsg={errors?.articleUrl?.message}
          />
          <br />
          <CXInputField
              label="App Secret Key"
              name="appSecret"
              register={register}
              errorMsg={errors?.appSecret?.message}
          />
          <br />
          <CXInputField
              label="Report URL"
              name="reportUrl"
              register={register}
              errorMsg={errors?.reportUrl?.message}
          />
        <br />
        <CXInputField
            label="Email Configuration"
            name="emailConfigs"
            register={register}
            errorMsg={errors?.emailConfigs?.message}
        />
          <br />
        <div className={"cx-action-buttons"}>
          <button
            type="button"
            className="btn cx-focus-btn"
            onClick={submitButton}
          >
            Save
          </button>
          {isSaving && <CxLoader inPlace={true} />}
          <button
            type="button"
            className="btn"
            onClick={cancelButton}
          >
            Cancel
          </button>
          {
            isSaving && <span>This may take a while! (~20 secs)</span>
          }
        </div>
      </>
    );
  };


  const renderScriptURLsForm = () => {
    return <>
      <CXInputField
        label="Widget Serving"
        name="widgetServing"
        register={register}
        errorMsg={errors?.widgetServing?.message}
        placeholder={"Will default to " + (isDev ? "new-widget-serving-dhczmo2e7a-ew.a.run.app" : "widget.cognativex.com")}
      />
      <br />
      <CXInputField
        label="Ad Click URL"
        name="adServing"
        register={register}
        errorMsg={errors?.adServing?.message}
        placeholder={"Will default to " + (isDev ? "log-dev.cognativex.com" : "campaigns.cognativex.com")}
      />
      <br />
      <CXInputField
        label="Static URL"
        name="static"
        register={register}
        errorMsg={errors?.static?.message}
        placeholder={"Will default to " + (isDev ? "static-dev.cognativex.com" : "static.cognativex.com")}
      />
      <br />
      <CXInputField
        label="Logging URL"
        name="tracking"
        register={register}
        errorMsg={errors?.log?.message}
        placeholder={"Will default to " + (isDev ? "log-dev.cognativex.com" : "log.cognativex.com")}
      />
      <br />
      <div className={"cx-action-buttons"}>
          <button
            type="button"
            className="btn cx-focus-btn"
            onClick={submitButton}
          >
            Save
          </button>
          {isSaving && <CxLoader inPlace={true} />}
          <button
            type="button"
            className="btn"
            onClick={cancelButton}
          >
            Cancel
          </button>
          {
            isSaving && <span>This may take a while! (~20 secs)</span>
          }
        </div>

    </>
  }


  return (
    <>
      {
        readResult.isLoading
        ? <CxLoader minHeight="50vh" />
        : <div id={"publisher-config-form-container"}>
            <Row className={"mx-0 mb-4"}>
              <Col className={"px-0"}>
                <div className={"reactive-tabs publisher-form-tabs"}>
                  <div
                    className={`reactive-tab ${
                      activeTab === "General" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("General")}
                  >
                    <i className={"fa fa-list"}></i> &nbsp; <p>General</p>
                  </div>
                  <div
                    className={`reactive-tab ${
                      activeTab === "Configuration" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Configuration")}
                  >
                    <i className={"fa fa-gear"}></i> &nbsp; <p>Configuration</p>
                  </div>
                  <div
                    className={`reactive-tab ${
                      activeTab === "Ad Management" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Ad Management")}
                  >
                    <i className={"fa fa-rectangle-ad"}></i> &nbsp; <p>Ad Management</p>
                  </div>
                  <div
                    className={`reactive-tab ${
                      activeTab === "Script URLs" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("Script URLs")}
                  >
                    <i className={"fa fa-file"}></i> &nbsp; <p>Script URLs</p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className={"mx-0"}>
              <Col className={"px-0"}>
                <form
                    className={"publisher-form-container"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    className={`${
                      activeTab === "General"
                      ? "publisher-form-general"
                      : "d-none"
                    }`}
                  >
                      {renderGeneralForm()}
                    
                  </div>
                  <div
                    className={`${
                      activeTab === "Configuration"
                        ? ""
                        : "d-none"
                    }`}
                  >
                    {renderConfigForm()}
                  </div>
                  <div
                    className={`${
                      activeTab === "Ad Management"
                        ? ""
                        : "d-none"
                    }`}
                  >
                    {renderAdManagementForm()}
                  </div>
                  <div
                    className={`${
                      activeTab === "Script URLs"
                        ? ""
                        : "d-none"
                    }`}
                  >
                    {renderScriptURLsForm()}
                  </div>
                </form>
              </Col>
            </Row>
          </div>
      }
     
    </>
  );
}


const Wrapper = () => {
  const actionType = usePublisherStore((state) => state.actionType);
  const itemToLoad = usePublisherStore((state) => state.itemToLoad);

  const readPublisherQuery = useQuery( ["publisher-read", itemToLoad], () => {
    if(actionType==="edit") return readPublisher(itemToLoad);
  },
  {
    refetchOnWindowFocus: false,
  });

  return <>
    {
      actionType==="edit"
      ? readPublisherQuery.isLoading ? <CxLoader minHeight="300px"/> : 
        <EditPublisher readResult={readPublisherQuery}/>  
      : <CreatePublisher />
    }
    </>

}

export default Wrapper;
