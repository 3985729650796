import Login from "views/login/login";
import ReduxCampaignList from "views/campaign/List";
import Revenue from "views/Revenue/Revenue";
import NetworkMonitor from "views/admin/NetworkMonitor/NetworkMonitor";
// import ContentAnalysis from "views/discovery/ContentAnalysis/ContentAnalysis";
// import TrafficAnalysis from "views/discovery/TrafficAnalysis/TrafficAnalysis";
// import ContentGroupingAnalysis from "views/discovery/ContentGroupingAnalysis/ContentGroupingAnalysis";
// import UserProfileAnalysis from "views/discovery/UserProfileAnalysis/UserProfileAnalysis";
// import EmailNewsletterAnalysis from "views/discovery/NewsLetterReport/NewsletterReport";
import NewsLetterAnalysis from "views/analysis/newsletter/NewsletterAnalysis";
import ContentGroupingNewAnalysis from "views/analysis/contentgrouping/ContentGroupingCharts";
import EvergreenAnalysis from "views/analysis/evergreen/EvergreenAnalysis";
import ContentAnalysisNew from "views/analysis/contentAnalysis/ContentAnalysis";
import EmailNewsLetter from "views/EmailNewsLetter/List"
import Segmentation from "views/Segmentation/List";
import ContentGrouping from "views/ContentGrouping/List";
import ReduxMpAdsetReview from "views/mediaPlanner/adsetReview";
import ReduxMpAdReview from "views/mediaPlanner/adReview";
import ReduxRecommendationConfig from "views/recommendation/config";
import Survey from "views/Survey/Survey";
import Notification from "views/Notification/List";
import IntegrationForm from "views/integration/overview";
import ReduxPublisherConfig from "views/admin/publisher/List";
import ReduxUsersConfig from "views/admin/user/List";
import ReduxRoleConfig from "views/admin/role/List";
import ReduxWidget from "views/admin/widget/List.jsx";
import ReduxAdvertiser from "views/admin/advertiser/List";
import ReduxAudit from "views/admin/audit/List";
import ReduxCampaignCategoryMapping from "views/campaignCategoryMapping/config";
import PostsOverview from "views/posts/postsOverview.jsx";
import AuthorsOverview from "views/authors/authorsOverview";
import ClassesOverview from "views/classes/classesOverview";
import WidgetOverview from "views/widget/overview.jsx";
import mainHelper from "./helpers/main";
import ArticleBoosting from "./views/ArticleBoosting/List";
import DigestReportSettingList from "./views/DigestReportSetting/DigestReportsList"

const helpers = new mainHelper();

function processRoutes(routes) {
  function isAuthorized(permissions, routepermissions) {
    for (var j in permissions) {
      let permission = permissions[j];

      if (permission === "*") {
        return true;
      }

      if (routepermissions.indexOf(permission) > -1) {
        return true;
      }
    }
    return false;
  }

  let route = [];
  let currentOrganization = helpers.getOrganization();

  let permissions = helpers.getPermissions();

  for (let i in routes) {
    let routeI = routes[i];
    const routepermissions = routeI["permissions"];
    if (!routepermissions) {
      // if no permission on main link then show this route and continue
      route.push(routeI);
      continue;
    }
    // now sure i have route permission in `routepermissions`
    const routesView = routeI["views"];
    // case i dont have views so add all of the route
    if (!routesView && isAuthorized(permissions, routepermissions)) {
      route.push(routeI);
      continue;
    }

    // now sure i have views in the route
    if (isAuthorized(permissions, routepermissions)) {
      // if this route is authorized start filtering the views inside
      // route.push(routeI);
      let routeViewArr = [];
      for (let v in routesView) {
        const routeView = routesView[v];
        // there are 2 cases
        const routeViewPermission = routeView["permissions"];
        // 1 we dont have permissions arr
        // then we need to use the main routepermissions
        if (!routeViewPermission) {
          routeViewArr.push(routeView);
          continue;
        }
        // sure there is permission in view
        if (isAuthorized(permissions, routeViewPermission)) {
          routeViewArr.push(routeView);
        }
      }
      // if we have at least one view push the routeI with the views gathered depending on permission
      if (routeViewArr.length) {
        // update views
        routeI["views"] = routeViewArr;
        // push to data route
        route.push(routeI);
      }
    }
  }
  return route;
}

const allRoutes = [

  {
    name: "Campaigns",
    rtlName: "حملات",
    icon: "fa fa-bullhorn",
    path: "/campaign-list",
    component: ReduxCampaignList,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-list"],
  },
  {
    collapse: true,
    name: "Media Planner",
    rtlName: "مخطط الإعلانات",
    icon: "fa fa-wrench",
    state: "mediaPlannerCollapse",
    permissions: ["adset-read-allocation"],
    views: [
      {
        path: "/mp-adset-review",
        name: "Review Targeting",
        rtlName: "مراجعة مجموعة الإعلان",
        mini: "A",
        rtlMini: "م",
        component: ReduxMpAdsetReview,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["adset-read-allocation"],
      },
      {
        path: "/mp-ad-review",
        name: "Review Ads",
        rtlName: "مراجعة الإعلان",
        mini: "A",
        rtlMini: "م",
        component: ReduxMpAdReview,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["review-ad-list"],
      },
    ],
  },
  {
    name: "Ad Mapping",
    rtlName: "",
    icon: "fa fa-tag",
    path: "/campaign-category-mapping-list",
    component: ReduxCampaignCategoryMapping,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-category-mapping-config"],
  },
  {
    name: "Revenue",
    rtlName: "ايرادات",
    icon: "tim-icons icon-money-coins",
    path: "/revenue",
    component: Revenue,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-list"],
  },

  {
    name: "Segmentation",
    rtlName: "حملات",
    icon: "fa fa-users",
    path: "/segmentation",
    component: Segmentation,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-list"],
  },
  {
    name: "Content Grouping",
    rtlName: "جمع المحتوى",
    icon: "fa fa-layer-group",
    path: "/content-grouping",
    component: ContentGrouping,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["content-grouping"],
  },
  {
    name: "Article Boosting",
    rtlName: "Article boost",
    icon: "fa fa-rocket",
    path: "/article-boosting",
    component: ArticleBoosting,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["article-boosting"],
  },
  {
    name: "Email NewsLetter",
    rtlName: "أخبار الايمايل",
    icon: "tim-icons icon-email-85",
    path: "/Email-Newsletter",
    component: EmailNewsLetter,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["email-newsletter"],
  },
  {
    name: "Survey",
    rtlName: "",
    icon: "fa fa-poll-h",
    path: "/survey",
    component: Survey,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-category-mapping-config"],
  },
  {
    name: "Notification",
    rtlName: "",
    icon: "fa fa-bell",
    path: "/notification",
    component: Notification,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["campaign-category-mapping-config"],
  },
  {
    collapse: true,
    name: "Insights",
    rtlName: "محتوى",
    icon: "tim-icons icon-notes",
    state: "insightCollapse",
    permissions: ["posts-pageviews-by-day"],
    views: [
      {
        path: "/posts",
        name: "Content",
        rtlName: "مقالات",
        mini: "C",
        rtlMini: "م",
        component: PostsOverview,
        layout: "/admin",
        rtlLayout: "/rtl",
      },
      {
        name: "Authors",
        rtlName: "مؤلفون",
        mini: "A",
        rtlMini: "مؤ",
        path: "/authors",
        layout: "/admin",
        rtlLayout: "/rtl",
        component: AuthorsOverview,
      },
      {
        name: "Classifications",
        rtlName: "تصنيفات",
        mini: "C",
        rtlMini: "تص",
        path: "/classes",
        layout: "/admin",
        rtlLayout: "/rtl",
        component: ClassesOverview,
      },
      {
        path: "/widget-overview",
        name: "Widget Performance",
        rtlName: "أداء",
        mini: "W",
        rtlMini: "أ",
        component: WidgetOverview,
        layout: "/admin",
      },
      {
        name: "Content Groups",
        rtlName: "مجموعة المحتويات",
        mini: "d",
        rtlMini: "مح",
        path: "/content-group-analysis",
        layout: "/admin",
        rtlLayout: "/rtl",
        component: ContentGroupingNewAnalysis,
      },
      {
        name: "Email Newsletter",
        rtlName: "البريد الالكتروني",
        mini: "e",
        rtlMini: "با",
        path: "/email-newsletter-analysis",
        layout: "/admin",
        rtlLayout: "/rtl",
        component: NewsLetterAnalysis,
      },
      {
        name: "Ever Green Content",
        rtlName: "المواضيع المستمرة",
        mini: "e",
        rtlMini: "با",
        path: "/evergreen-content-analysis",
        layout: "/admin",
        rtlLayout: "/rtl",
        component: EvergreenAnalysis,
      }
    ],
  },
  {
    name: "Digest Report",
    rtlName: "التقارير",
    icon: "fas fa-file-invoice",
    path: "/digest-report-setting",
    component: DigestReportSettingList,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["digest-report-setting"],
  },
  {
    collapse: true,
    name: "Discovery",
    rtlName: "اكتشاف",
    icon: "fa fa-lightbulb",
    permissions: ["cx-discovery"],
    views: [
      {
        path: "/campaign-monitor",
        name: "Campaign Monitor",
        rtlName: "",
        mini: "CM",
        rtlMini: "ن م",
        component: NetworkMonitor,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: [],
      },
      // {
      //   path: "/traffic-analysis",
      //   name: "traffic Analysis",
      //   rtlName: "",
      //   mini: "TA",
      //   rtlMini: "ك أ",
      //   component: TrafficAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/user-profile-analysis",
      //   name: "User Profile Analysis",
      //   rtlName: "",
      //   mini: "UPA",
      //   rtlMini: "ن م",
      //   component: UserProfileAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/content-analysis",
      //   name: "Content Analysis",
      //   rtlName: "",
      //   mini: "CA",
      //   rtlMini: "ن م",
      //   component: ContentAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/content-grouping-analysis",
      //   name: "Content Grouping Analysis",
      //   rtlName: "",
      //   mini: "CGA",
      //   rtlMini: "ن ج م",
      //   component: ContentGroupingAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/content-grouping-new-analysis",
      //   name: "Content Grouping New Analysis",
      //   rtlName: "",
      //   mini: "CGNA",
      //   rtlMini: "أ ن ج م",
      //   component: ContentGroupingNewAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      {
        path: "/content-new-analysis",
        name: "Content New Analysis",
        rtlName: "",
        mini: "CNA",
        rtlMini: "أ م",
        component: ContentAnalysisNew,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: [],
      },
      // {
      //   path: "/newsletter-analysis",
      //   name: "Newsletter Analysis",
      //   rtlName: "",
      //   mini: "NA",
      //   rtlMini: "ن ج م",
      //   component: NewsLetterAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/email-newsletter-analysis",
      //   name: "Email Newsletter Analysis",
      //   rtlName: "",
      //   mini: "ENA",
      //   rtlMini: "أ ن س",
      //   component: EmailNewsletterAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
      // {
      //   path: "/evergreen-analysis",
      //   name: "Evergreen Analysis",
      //   rtlName: "",
      //   mini: "EA",
      //   rtlMini: "أ س",
      //   component: EvergreenAnalysis,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: [],
      // },
    ],
  },
  {
    name: "widgets",
    rtlName: "widgets",
    icon: "fa fa-layer-group",
    path: "/widget-list",
    component: ReduxWidget,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["widget-list"],
  },
  {
    name: "AI Config",
    rtlName: "توصية",
    icon: "fas fa-bullseye",
    path: "/recommendation-config",
    component: ReduxRecommendationConfig,
    layout: "/admin",
    rtlLayout: "/rtl",
    permissions: ["recommendation-config"],
  },
  {
    collapse: true,
    name: "Admin",
    rtlName: "إدارة",
    icon: "fa fa-cog",
    state: "adminDataCollapse",
    permissions: ["organization-list"],
    views: [
      // {
      //   path: "/organization-list",
      //   name: "Organizations",
      //   rtlName: "",
      //   mini: "O",
      //   rtlMini: "إ",
      //   component: ReduxOrganizationConfig,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: ["organization-list"],
      // },
      {
        path: "/publisher-list",
        name: "Publishers",
        rtlName: "",
        mini: "P",
        rtlMini: "إ",
        component: ReduxPublisherConfig,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["publisher-list"],
      },
      {
        path: "/advertiser-list",
        name: "Advertisers",
        rtlName: "",
        mini: "P",
        rtlMini: "إ",
        component: ReduxAdvertiser,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["advertiser-list"],
      },
      {
        path: "/user-list",
        name: "Users",
        rtlName: "",
        mini: "P",
        rtlMini: "إ",
        component: ReduxUsersConfig,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["user-list"],
      },
      {
        path: "/role-list",
        name: "Roles",
        rtlName: "",
        mini: "P",
        rtlMini: "إ",
        component: ReduxRoleConfig,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["role-list"],
      },{
        path: "/integration",
        name: "Integration",
        rtlName: "Integration",
        mini: "i",
        rtlMini: "اا",
        component: IntegrationForm,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: [],
      },
      // {
      //   path: "/widget-list",
      //   name: "Widgets",
      //   rtlName: "",
      //   mini: "P",
      //   rtlMini: "إ",
      //   component: ReduxWidget,
      //   layout: "/admin",
      //   rtlLayout: "/rtl",
      //   permissions: ["widget-list"],
      // },

      {
        path: "/audit-list",
        name: "Audit",
        rtlName: "",
        mini: "P",
        rtlMini: "إ",
        component: ReduxAudit,
        layout: "/admin",
        rtlLayout: "/rtl",
        permissions: ["audit-list"],
      },
    ],
  },

  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    invisible: true,
    views: [
      {
        path: "/login",
        name: "CX Portal",
        rtlName: "تسجيل الدخول",
        mini: "L",
        rtlMini: "ت",
        component: Login,
        layout: "/auth",
      },
    ],
  },

];

const routes = processRoutes(allRoutes);

const permissionsLP = helpers.getDefaultLandingPages();

export default { routes, permissionsLP };
