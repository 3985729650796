import React, { useState, useEffect } from 'react';
import useSegmentStore from "./segmentStore";
import { CXSelect, CXControlledPill, CXSelectWPills, CXSelectWPillsPrimitive } from "../../CXComponents";
import { useForm, useFieldArray, useWatch } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CxLoader from "../components/cxLoader"
import { readSegment, getDimensionsList, updateSegment, createSegment } from "./segmentAPI";
import CXPillList from './components/CXPillList';
import UsersMeter from './components/UsersMeter';


//utility
function getOperators(dimensionsData, selectedDimension){
    return dimensionsData
            .filter(d=>d.dimension.value===selectedDimension.value)[0]
            .operators?.map(o=>({label: o.label, value: o}));
}
function getValueOptions(dimensionsData, selectedDimension){
    return dimensionsData
            .filter(d=>d.dimension.value===selectedDimension.value)[0]
            .options?.map(o=>({label: o.label, value: o}));
}



const NewDimension = ({ register, setValue, dimensionsData, control, append, closeAddingNew}) => {
    const qc = useQueryClient();
    const toggleIsAddingNewGlobal = useSegmentStore(state=>state.toggleIsAddingNewGlobal);
    
    
    const [AllDimensionsValues, dimensionValue, operatorValue, valuesValue] = useWatch({
        control,
        name: ['dimensions', 'dimension', 'operator', 'value'],
    });

    // reset values on mount
    useEffect(()=>{
        setValue("dimension", undefined);
        setValue("operator", undefined);
        setValue("value", undefined);
    }, [setValue])
    
    function saveNewDimension(){
        const mappedValuesValue = Array.isArray(valuesValue) ? valuesValue : [valuesValue];
        let l = {
            dimension: {label: dimensionValue.label, value: dimensionValue.value},
            operator: {label: operatorValue.label, value: operatorValue.value},
            type: dimensionValue.extra?.type,
            value: mappedValuesValue.map(v=>v?.value?.value || v)
        }
        append(l);
        qc.invalidateQueries(["users-count"]);
        closeAddingNew();
        toggleIsAddingNewGlobal();
    }
    
    function filterAvailableDimensions(){
        return dimensionsData?.filter(d=>
            !AllDimensionsValues.map(data=>data.dimension.value).includes(d.dimension.value)   
        ).map(d=>({
            label: d.dimension.label,
            value: {label: d.dimension.label, value: d.dimension.value, extra: {type: d.type} },
            extra: {type: d.type}
        }))
    }

    function renderValueEntry(type){
        if(type.includes("string"))
                return <CXSelectWPills 
                            {...{
                                control,
                                name: 'value',
                                startFresh: true,
                                startOpened: true,
                                width: "100%",
                                options: getValueOptions(dimensionsData, dimensionValue) || [],
                                metric: "user_count"
                            }}
                        />
            else if(type.includes("integer"))
                return <input
                            className="cx-input segment-item-input"
                            type='number'
                            {...register("value")}
                        />
            else if(type.includes("date"))
                return <input
                            className="cx-input segment-item-input"
                            type='date'
                            {...register("value")}
                        />
    }
    return (
        <div className="segment-form__dimensions-item">
            <CXSelect 
                {...{
                    control,
                    name: 'dimension',
                    options: filterAvailableDimensions(),
                    dislayProp: "label",
                    defaultDisplay: "Select Dimension",
                    style: {width: '400px'}
                }}
            />
            {
                dimensionValue &&
                <CXSelect 
                    {...{
                        control,
                        name: 'operator',
                        options: getOperators(dimensionsData, dimensionValue),
                        dislayProp: "label",
                        defaultDisplay: "Select Operator",
                    }}
                />
            }
            {   
                dimensionValue && operatorValue && 
                renderValueEntry(dimensionValue.extra?.type)
            }

            <div className='editPermissionForm__actions'>
                <button
                    type="button"
                    className={`addPermissionForm__submitBtn cx-btn ${(!dimensionValue || !operatorValue || !valuesValue || !valuesValue.length)&&"disabled"}`}
                    onClick={saveNewDimension}
                >
                    <i className="fa fa-check"></i>
                </button>
                <button
                    type='button'
                    className='addPermissionForm__cancleBtn cx-btn'
                    onClick={()=>{closeAddingNew(); toggleIsAddingNewGlobal();}}
                >
                    <i className="fa fa-times"></i>
                </button>
            </div>
            
        </div>
    )
}

const SegmentItem = ( { register, control, namePrefix, dimensionsData, update, remove, index,  } ) => {
    const [isEditing, setIsEditing] = useState(false);
    const currentDimensions = useWatch({
        control,
        name: "dimensions"
    });
    const qc = useQueryClient();


    const operatorValue = useWatch({
        control,
        name: namePrefix+'operator'
    })
    const valuesValue = useWatch({
        control,
        name: namePrefix+'value'
    })
  
    
    const renderViewMode = () => {
        return (
            <div className="segment-form__dimensions-item">
                <CXControlledPill 
                    name={namePrefix+"dimension"}
                    control={control}
                    className={"segmentation-dimension-pill segmentation-dimension-pill-dimension"}
                    displayProp="label"
                />
                <CXControlledPill 
                    name={namePrefix+"operator"}
                    control={control}
                    className={"segmentation-dimension-pill"}
                    displayProp="label"
                /> 
                <CXPillList
                    name={namePrefix+"value"}
                    control={control}
                    displayProp="label"
                />
                <div className="PermissionRow__actions">
                    <button type="button" className='permissionsRow__editBtn cx-icon-btn' onClick={()=>setIsEditing(true)}><i className="fa fa-pen"></i></button>
                    <button type='button' className='permissionsRow__deleteBtn cx-icon-btn' onClick={()=>{remove(); qc.invalidateQueries(['users-count']);}}> <i className="fa fa-trash"></i> </button>
                </div>
            </div>
        )
    }
    
    const renderEditingMode = () => {
        
        const selectedDimension = currentDimensions[index].dimension;
        const type = currentDimensions[index].type;
        const operators = getOperators(dimensionsData, selectedDimension);
        const valuesOptions = getValueOptions(dimensionsData, selectedDimension);
        function updateDimension(){
            const mappedValuesValue = Array.isArray(valuesValue) ? valuesValue : [valuesValue];
            let l = {
                dimension: {label: selectedDimension.label, value: selectedDimension.value},
                operator: {label: operatorValue.label, value: operatorValue.value},
                type: type,
                value: mappedValuesValue.map(v=>v?.value?.value || v)
            }
            update(index, l);
            qc.invalidateQueries(["users-count"]);
            setIsEditing(false)
        }

        function renderValueEntry(type){
            if(type.includes("string"))
                return <CXSelectWPillsPrimitive 
                            {...{
                                control,
                                name: namePrefix+'value',
                                width: "100%",
                                options: valuesOptions,
                        }}
                    />
            else if(type.includes("integer"))
                return <input
                            className="cx-input segment-item-input"
                            type='number'
                            {...register(namePrefix + "value")}

                        />
            else if(type.includes("date"))
                return <input
                            className="cx-input segment-item-input"
                            type='date'
                            {...register(namePrefix + "value")}

                        />
        }
        
        return (
            <div className="segment-form__dimensions-item">
                <CXControlledPill 
                    name={namePrefix+"dimension"}
                    control={control}
                    className={"segmentation-dimension-pill segmentation-dimension-pill-dimension"}
                    displayProp="label"
                />
                <CXSelect 
                    {...{
                        control,
                        name: namePrefix+'operator',
                        options: operators,
                        defaultDisplay: "Select Operator",
                        dislayProp: "label",
                    }}
                />
                {
                    selectedDimension && operatorValue &&
                    renderValueEntry(type)
                }
                
                <div className='editPermissionForm__actions'>
                    <button type="button" className='addPermissionForm__submitBtn cx-btn' onClick={()=>updateDimension()}><i className="fa fa-check"></i></button>
                    <button type='button' className='addPermissionForm__cancleBtn cx-btn' onClick={()=>setIsEditing(false)}> <i className="fa fa-times"></i> </button>
                </div>
              
            </div>
        )
    }

    return (
        !isEditing
        ? renderViewMode()
        : renderEditingMode()
    )
}

const DimensionsFieldArray = ({register, setValue, control, name, dimensionsData}) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name
    });

    const toggleIsAddingNewGlobal = useSegmentStore(state=>state.toggleIsAddingNewGlobal);

    const [isAddingNew, setIsAddingNew] = useState(false);

    const closeAddingNew = () => setIsAddingNew(false)

    return (
        <div className="cx-dimensions-array-container">
            <button type='button' className={`cx-btn float-right ${isAddingNew&&"disabled"}`} onClick={()=>{setIsAddingNew(true); toggleIsAddingNewGlobal()}}>
                <i className='fa fa-plus' style={{color: '#21c8df', fontWeight:'900'}}></i> &nbsp; Add Dimension
            </button>
            <br />
            <div className="cx-permissions-list">
                {
                    fields.map((field, index)=>{
                        return (
                            <SegmentItem
                                {...{
                                    register,
                                    control,
                                    namePrefix: `${name}.${index}.`,
                                    key: field.id,
                                    index,
                                    dimensionsData,
                                    update,
                                    remove: ()=>remove(index)
                                }}
                            /> 
                        )
                    })
                }
                {
                    isAddingNew &&
                    <NewDimension
                        {...{
                            register,
                            setValue,
                            control,
                            dimensionsData,
                            append,
                            closeAddingNew
                        }}
                    />

                }
            </div>
        </div>
    )
}

const SegmentForm = ({segmentData, itemToLoad, dimensionsData}) => {

    const qc = useQueryClient();

    const actionType = useSegmentStore(state=>state.actionType);
    const setIsDirty = useSegmentStore(state=>state.setIsDirty);

    const {formState: { isDirty, dirtyFields }, control, handleSubmit, register, setValue} = useForm({
        defaultValues: segmentData
    });

    useEffect(() => {
        setIsDirty(isDirty)
    }, [isDirty, setIsDirty]);

    const updateSegmentQuery = useMutation((data)=>{
        return updateSegment(data);
    });

    const createSegmentQuery = useMutation((data)=>{
        return createSegment(data);
    });

    const closeDrawer = useSegmentStore(state=>state.closeDrawer);
    const isAddingNewGlobal = useSegmentStore(state=>state.isAddingNewGlobal);

    const onSubmit = async (values) => {
        const mapped = {...values};
        delete mapped.dimension;
        delete mapped.operator;
        delete mapped.value;
        //map the added dimensions
        mapped.dimensions = values.dimensions.map(d => {
            if(typeof d.operator != "object") return d; //skip the good ones
            d.operator = d.operator.value;
            return d;
        });
        if(actionType === 'create') await createSegmentQuery.mutateAsync(mapped);
        else{
            mapped.id = itemToLoad;
            await updateSegmentQuery.mutateAsync(mapped);
            await qc.invalidateQueries(["segment-read", itemToLoad]);
        }
        closeDrawer();
        await qc.invalidateQueries(["segments-list"]);
    }

    return (
        <form className={`segment-form ${actionType === "create" ? "segment-create-mode": "segment-edit-mode"}`} onSubmit={handleSubmit(onSubmit)}>

            <div className="segment-form__segment-name">
                <label htmlFor="name">Title: </label> &nbsp;&nbsp;
                <input required {...register("name")}/>
            </div>
            <br />
                <UsersMeter
                    control={control}
                    name="userCount"
                />
            <br />
            <div className="segment-form__dimensions-list">
                <DimensionsFieldArray
                    {...{
                        register,
                        setValue,
                        control,
                        name: "dimensions",
                        dimensionsData
                    }}
                />
            </div>
            <br />
            <div className='float-right'>
                {
                    updateSegmentQuery.isLoading || createSegmentQuery.isLoading
                    ? <CxLoader inPlace="true" />
                    : <button className={`cx-btn segment-save-btn ${isAddingNewGlobal&&"disabled"}`} type='submit'> 
                        { actionType === 'create' ? 'create' : 'save' } 
                    </button>
                }
            </div>
            
        </form>
    );
}

export const EditSegment = () => {

    const itemToLoad = useSegmentStore(state=>state.itemToLoad);
    const readSegmentQuery = useQuery(["segment-read", itemToLoad], ()=>{
        return readSegment(itemToLoad);
    }, {refetchOnWindowFocus: false})
    const getDimensionsListQuery = useQuery(["dimensions-list"], ()=>{
        return getDimensionsList();
    }, {refetchOnWindowFocus: false})
    return (
        <>
        {
            !getDimensionsListQuery.isLoading && !readSegmentQuery.isLoading
            ? <SegmentForm itemToLoad={itemToLoad} segmentData={readSegmentQuery.data} dimensionsData={getDimensionsListQuery.data} />
            : <CxLoader minHeight={"70vh"}/>
        }
        </>
        
    );
}

export const CreateSegment = () => {

    const getDimensionsListQuery = useQuery(["dimensions-list"], ()=>{
        return getDimensionsList();
    }, {refetchOnWindowFocus: false})
    
    return (
        <>
        {
            !getDimensionsListQuery.isLoading
            ? <SegmentForm dimensionsData={getDimensionsListQuery.data} />
            : <CxLoader minHeight={"70vh"}/>
        }
        </>
        
    );
}


export default CreateSegment;
