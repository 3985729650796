import React, { useState } from "react";
import { deleteUser, refetchList } from "./userAPI";
import useUserStore from "./UserStore";
import { useMutation, useQueryClient } from "react-query";
import CxLoader from "../../components/cxLoader";
import {CXConfirm} from "../../../CXComponents"


const ActionButtons = () => {


    const qc = useQueryClient();

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const togglePopup = useUserStore((state) => state.togglePopup);
    const setActionType = useUserStore((state) => state.setActionType);
    const selectedRows = useUserStore((state) => state.selectedRows);
    const emptySelectedRows = useUserStore((state) => state.emptySelectedRows);
    const deleteUserQuery = useMutation(userID=>{
        return deleteUser(userID);
    });

    const openPopupForm = (action = 'create') => {
        setActionType(action);
        togglePopup();
    }

    const deleteSelectedRows = async () => {
        setIsDeletePopupOpen(true);
    }
    return (
        <>
        {
            isDeletePopupOpen && 
            <CXConfirm 
                {...{
                    width: '400px',
                    title: "Delete Users",
                    subtitle: "Are you sure you want to permanently delete the selected users?",
                    confirmText: "Delete All",
                    cancelText: "Cancel",
                    confirmAction: async ()=>{
                        setIsDeletePopupOpen(false);
                        emptySelectedRows();
                        await deleteUserQuery.mutateAsync(selectedRows);
                        await qc.invalidateQueries(["user-list"]);
                    },
                    cancelAction: ()=>{
                        setIsDeletePopupOpen(false);
                    }
                }} 
            />
        }
        
        <div className={"header-btn-container"}>
            <button
                className="btn cx-focus-btn"
                onClick={(e) => { openPopupForm('create') }}
            >
                Create
            </button>
            <button
                className="btn"
                onClick={deleteSelectedRows}
                disabled={selectedRows.length==0}
            >
                Delete
            </button>
            {deleteUserQuery.isLoading ? <CxLoader inPlace={true}/> : ''}
        </div>
        </>
    )
}

export default ActionButtons;