import React, { Component } from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import AuditTable from "./tableList";
import _ from "lodash";
import { auditActions,organizationActions } from "../../../actions";

class AuditList  extends Component {

    constructor(props) {
        super(props);
        this.state={
            changeDefaultAudit: 0,
        }
    }

    componentDidUpdate(prevProps) {
       
    }

    alertMsg = (msg, icon = 'tim-icons icon-simple-remove', type = 'danger') => {
        this.refs.notificationAlert.notificationAlert({
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    render() {
        return (
            <>          

                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; Audit</h4>
                    </div>
                    <div className={"page-reactive-table"}>                   
                   
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-audit-'}>
                                    <AuditTable />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        actionType: (state.audit && state.audit.actionType)? state.audit.actionType : 'create',
        Item: state.audit && state.audit.Item,
        // createErr: (state.audit && state.audit.createErr) || null,
        // create: (state.audit && state.audit.create) || null,
        // updateErr: (state.audit && state.audit.updateErr) || null,
        // update: (state.audit && state.audit.update) || null,
        // deleteErr: (state.audit && state.audit.deleteErr) || null,
        // delete: (state.audit && state.audit.delete) || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // createEvent:() => dispatch(auditActions.createEvent(undefined)),
        // createErrEvent: () => dispatch(auditActions.createErrEvent(undefined)),
        // updateErrEvent: () => dispatch(auditActions.updateErrEvent(undefined)),
        // updateEvent: () => dispatch(auditActions.updateErrEvent(undefined)),
        // deleteErrEvent: () => dispatch(auditActions.deleteErrEvent(undefined)),
        // deleteEvent: () => dispatch(auditActions.deleteEvent(undefined)),
        // getOrganizationList: () => dispatch(organizationActions.getList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditList);