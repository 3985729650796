import React, { Component } from "react";
import { connect } from 'react-redux';
import {advertiserActions, adActions, campaignActions,adsetActions, categoryActions, locationActions} from '../../actions';
import Drawer from './components/drawer';
import Popup from '../components/popup';
import QuickCreate from './components/quickCreateForm';
import PublishAll from './components/publishAll';
import CampaignTable from "./components/campaignTable";
import AdsetTable from "./components/adsetTable";
import AdTable from "./components/adTable";
import { Row, Col } from "reactstrap";
import DateFilter from "../components/dateFilter";
import CampTabs from "./components/campTabs";
import ActionBtns from "./components/actionBtns";
import NotificationAlert from "react-notification-alert";
import mainHelper from "../../helpers/main";
import analytics from "../../helpers/analytics";
import {FilterBar, StartFilters, EndFilters} from "../../components/FilterBar/filterBar";
import AdvancedFilter from "./components/campaignAdvancedFilter";


import ReactGA from 'react-ga';
let helpers = new mainHelper();

class CampaignList extends Component {
    constructor(props) {
        super(props);      
        analytics.logPageView('Campaigns');
      }
    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    componentDidMount() {
        this.props.fetchRegionsOptions();
        this.props.fetchLocationsOptions();
        this.props.fetchAdvertisersOptions();
        this.props.fetchCategoryOptions();
        this.props.fetchPixelsOptions();
    }

    componentDidUpdate(prevProps) {
        if(this.props.campaignDuplicateError && this.props.campaignDuplicateError != prevProps.campaignDuplicateError){
            this.alertMsg('Campaign duplicate operation failed, try again later');
            this.props.campaignDuplicateErrorA(undefined);
        }
        if(this.props.campaignDeleteError && this.props.campaignDeleteError != prevProps.campaignDeleteError){
            this.alertMsg('Campaign delete operation failed, try again later');
            this.props.campaignDeleteErrorA(undefined);
        }
        if(this.props.adsetDuplicateError && this.props.adsetDuplicateError != prevProps.adsetDuplicateError){
            this.alertMsg('Adset duplicate operation failed, try again later');
            this.props.adsetDuplicateErrorA(undefined);
        }
        if(this.props.adsetDeleteError && this.props.adsetDeleteError != prevProps.adsetDeleteError){
            this.alertMsg('Adset delete operation failed, try again later');
            this.props.adsetDeleteErrorA(undefined);
        }
        if(this.props.adsetCreateError && this.props.adsetCreateError != prevProps.adsetCreateError){
            this.alertMsg('Adset create operation failed, try again later');
            this.props.adsetCreateErrorA(undefined);
        }
        if(this.props.adDuplicateError && this.props.adDuplicateError != prevProps.adDuplicateError){
            this.alertMsg('Ad duplicate operation failed, try again later');
            this.props.adDuplicateErrorA(undefined);
        }
        if(this.props.adDeleteError && this.props.adDeleteError != prevProps.adDeleteError){
            this.alertMsg('Ad delete operation failed, try again later');
            this.props.adDeleteErrorA(undefined);
        }
        if(this.props.adCreateError && this.props.adCreateError != prevProps.adCreateError){
            this.alertMsg('Ad create operation failed, try again later');
            this.props.adCreateErrorA(undefined);
        }
    }

    getCampaignListData = async (page = '') => {
        // calls the action to change the state of the store
        await this.props.fetchCampaigns(page);

        this.setState({ loading: false });
    }

    fetchCampaignData(state) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.getCampaignListData(state.page ? state.page + 1 : 1);
    }

    renderPageFilters = () => {
        return (
            <div className={"cx-page-filter-container mb-3"}>
                <div className={"justify-content-end d-flex"}>
                    {/* <DateFilter className={"mr-3"}/> */}
                    <PublishAll/>
                </div>
                <FilterBar className={"mt-3 cx-campaign-filter-listing"}>
                    <StartFilters><AdvancedFilter /></StartFilters>
                </FilterBar>
            </div>
        );
    }

    /*
    * Renders the table for the campaigns, adsets and ads
    */

    render() {
        return (
            <>
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <Popup title={"Create Campaign"} width={'35%'}>
                    <QuickCreate />
                </Popup>
 
                <Drawer />
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-bullhorn"></i>&nbsp;Campaigns</h4>
                    </div>
                    {/* {this.renderPageFilters()} */}
                    <div className={"page-reactive-table"}>
                        <Row className={"mx-0"}>
                            <Col className={"px-0"}>
                                <CampTabs />
                            </Col>
                        </Row>
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0"}>
                                <div id={'cx-campaign-table-dashboard'} className={`${(this.props.activeTabList === 'campaign') ? '' : 'd-none'}`}>

                                    <CampaignTable />
                                </div>
                                <div id={'cx-adset-table-dashboard'} className={`${(this.props.activeTabList === 'adset') ? '' : 'd-none'}`}>
                                    <AdsetTable />
                                </div>
                                <div id={'cx-ad-table-dashboard'} className={`${(this.props.activeTabList === 'ad') ? '' : 'd-none'}`}>
                                  <AdTable/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        activeTabList: state.tabTable && state.tabTable.activeTab,
        campaignDuplicateError: (state.campaigns && state.campaigns.campaignDuplicateError) || null,
        campaignDeleteError: (state.campaigns && state.campaigns.campaignDeleteError) || null,
        adsetDuplicateError: (state.adsets && state.adsets.adsetDuplicateError) || null,
        adsetDeleteError: (state.adsets && state.adsets.adsetDeleteError) || null,
        adsetCreateError: (state.adsets && state.adsets.adsetCreateError) || null,
        adDuplicateError: (state.ads && state.ads.adDuplicateError) || null,
        adDeleteError: (state.ads && state.ads.adDeleteError) || null,
        adCreateError: (state.ads && state.ads.adCreateError) || null,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchRegionsOptions: () => dispatch(locationActions.fetchRegionsSelect()),
        fetchLocationsOptions: () => dispatch(locationActions.fetchLocationsSelect()),
        fetchCategoryOptions: () => dispatch(categoryActions.fetchAllCategoryOptions()),
        fetchAdvertisersOptions: () => dispatch(advertiserActions.fetchAdvertisersOptions()),
        fetchPixelsOptions: () => dispatch(advertiserActions.pixelList()),

        adCreateErrorA: (data) => dispatch(adActions.adCreateError(data)),
        adDuplicateErrorA: (data) => dispatch(adActions.adDuplicateError(data)),
        adDeleteErrorA: (data) => dispatch(adActions.adDeleteError(data)),

        adsetCreateErrorA: (data) => dispatch(adsetActions.adsetCreateError(data)),
        adsetDuplicateErrorA: (data) => dispatch(adsetActions.adsetDuplicateError(data)),
        adsetDeleteErrorA: (data) => dispatch(adsetActions.adsetDeleteError(data)),

        campaignDuplicateErrorA: (data) => dispatch(campaignActions.campaignDuplicateError(data)),
        campaignDeleteErrorA: (data) => dispatch(campaignActions.campaignDeleteError(data)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);