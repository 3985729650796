import React, { Component } from "react";

import Tree from './campTree';
import { drawerActions, campaignActions, adActions, adsetActions } from "../../../actions";
import { connect } from "react-redux";
import EditCampaignForm from "./editCampaignForm";
import EditAdsetForm from "./editAdsetForm";
import EditAdForm from "./editAdForm";
import DrawerReport from "./drawerReport";
import mainHelpers from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
import Switch from "react-bootstrap-switch";
import PerfectScrollbar from "perfect-scrollbar";
import Confirm from "../../components/confirmationBox";

import { reduxForm, isDirty } from "redux-form";

let helper = new mainHelpers();
var ps;
class Drawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTree: '',
            treeloader: (this.props.drawerTree) ? false : true,
            activationLoader: false
        }

        this.notify = {
            closeDrawerWarning: {
                title: 'Are you sure you want to leave before saving?',
                confirm: async (data) => {
                    this.closeDrawer(null, true);
                    this.refs.confirmRef.removeConfirmation();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Yes',
                cancelBtnTxt: 'Cancel'
            },
            openPerformanceWarning: {
                title: 'Are you sure you want to leave before saving?',
                confirm: async (data) => {
                    this.openPerformance(data, true);
                    this.refs.confirmRef.removeConfirmation();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Yes',
                cancelBtnTxt: 'Cancel'
            },
        }

        this.promiseDrawerOpened = null;
        this.props.setDrawerMode();
    }

    /*
    * componentDidMount
    */
    componentDidMount() {
        // by default set drawer to closed
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.elementRef);
        }else{
            this.refs.elementRef.style.overflow = 'auto';
        }
    }

    confirmOptionActions = (type, data) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: type, data: data });
        }
        return false;
    }

    updateDrawerData = (ref, prevProps) => {
        if ((ref.props.selectedData && (JSON.stringify(prevProps.selectedData) !== JSON.stringify(ref.props.selectedData)))) {
            ref.fetchTreeData();
        }
        if (
            (ref.props.selectedData && (JSON.stringify(prevProps.selectedData) !== JSON.stringify(ref.props.selectedData))) ||
            (JSON.stringify(prevProps.selectedType) !== JSON.stringify(ref.props.selectedType))
        ) {
            if (ref.props.selectedData) {
                // on open drawer open first item in tree
                if (!ref.props.selectedType) {
                    ref.props.setDrawerForm(ref.props.selectedData[0]);
                    // fetch data of selected item in tree
                    ref.fetchSelectItemData(ref.props.selectedData[0]);
                }
            }
        }
    }
    /*
    * componentDidUpdate
    */
    componentDidUpdate (prevProps) {
        if (prevProps.isDrawerOpened !== this.props.isDrawerOpened) {
            // animate the toggle drawer
            // animateDrawer
            this.animateToggleDrawer();
            // disable scroll of page on open drawer
            helper.disableScrollPage(this.props.isDrawerOpened);
        }
        const ref = this;
        this.promiseDrawerOpened && this.promiseDrawerOpened.then(()=>{
            // after open drawer get the data
            ref.updateDrawerData(ref, prevProps);
        });

        // todo drawerMode change
        // on drawer mode change; toggle between performance and edit, recall the one responsible for fetching data
        if (prevProps.drawerMode !== this.props.drawerMode){
            this.fetchSelectItemData(this.props.selectedType);
        }
    }

    refreshData = () => {
        this.fetchTreeData();
        // and reload the three tables
        this.props.campaignReloadList();
        this.props.adsetReloadList();
        this.props.adReloadList();
    }

    fetchSelectItemData = async (selectedType) => {
        if (selectedType) {
            switch(this.props.drawerMode){
                case 'edit':
                    // means it is edit or create functionality
                    // fetch the data of the form
                    this.setState({ loadingForm: true });
                    if (selectedType.type === 'campaign') {
                        await this.props.fetchCampaignData(selectedType.typeid);
                    } else if (selectedType.type === 'adset') {
                        await this.props.fetchAdsetData(selectedType.typeid);
                    }
                    else if (selectedType.type === 'ad') {
                        await this.props.fetchAdData(selectedType.typeid);
                    }
                    this.setState({ loadingForm: false });
                    break;
                case 'report' :
                    // fetch the report data
                    // @todo
                    console.log('=> todo fetch report Data for ' + selectedType.type + ' of id: ' + selectedType.typeid);
                    break;
            }
        }
    }

    fetchTreeData = async () => {
        this.setState({ loadingTree: true });
        await this.props.reloadTree();
        this.setState({ loadingTree: false });
    }
    /*
    * Toggle Drawer
    */

    passToEnd = (passToEnd) => {
        return (
            !passToEnd &&
            this.props.drawerMode === 'edit' &&
            (
                (this.props.selectedType.type === 'campaign' && this.props.isDirtyCampaign) ||
                (this.props.selectedType.type === 'adset' && this.props.isDirtyAdset) ||
                (this.props.selectedType.type === 'ad' && this.props.isDirtyAd)
            )
        );
    }

    closeDrawer = (event = null, passToEnd = false) => {
        if (event) event.preventDefault();
        if(this.passToEnd(passToEnd)){
            this.confirmOptionActions('closeDrawerWarning', null);
            return;
        }
        this.props.setDrawerVisible(false);
        this.props.setDrawerForm();
        this.props.resetDrawerForm();
    }

    openPerformance = (e, passToEnd = false) => {
        if(this.passToEnd(passToEnd)){
            this.confirmOptionActions('openPerformanceWarning', e);
            return;
        }
        this.drawerModeChange(e, 'report');
    }

    /*
    * animate the Toggle Drawer
    */
    animateToggleDrawer = () => {
        const elt = this.refs.drawerContainerElt;
        if (!elt) return '';
        if (this.props.isDrawerOpened) {
            // if drawer opened set some time out to remove the none display
            elt.classList.remove('d-none');
            this.promiseDrawerOpened =  new Promise(function(resolve) {
                setTimeout(function () {
                    // open drawer
                    elt.classList.add('open');
                    setTimeout(function(){
                        // then fetch data
                        resolve('opened');
                    }, 500);
                }, 10);
            });
        } else {
            this.promiseDrawerOpened = null;
            // close effect
            elt.classList.remove('open');
            setTimeout(function () {
                // then hide the drawer
                elt.classList.add('d-none');
                // transition lasts for 0.5s so set the timeout for 500
            }, 500);
        }
        return '';
    }

    renderActiveForm = () => {
        if (this.props.selectedType) {
            switch(this.props.drawerMode) {
                case 'edit':
                    if (this.props.selectedType.type === 'campaign') {
                        return <EditCampaignForm key={this.props.selectedType.typeid} loading={this.state.loadingForm} />
                    } else if (this.props.selectedType.type === 'adset') {
                        return <EditAdsetForm key={this.props.selectedType.typeid} loading={this.state.loadingForm} />
                    } else if (this.props.selectedType.type === 'ad') {
                        return <EditAdForm key={this.props.selectedType.typeid} loading={this.state.loadingForm} />
                    }
                    break;
                case 'report':
                    return  <DrawerReport
                        type={this.props.selectedType.type}
                        key={this.props.selectedType.type + '-' + this.props.selectedType.typeid}
                    />
                default:
                    break;
            }
        }
    }

    printTypeStatus = () => {
        const type = this.props.selectedType && this.props.selectedType.type;
        const status = this.props[type + 'Selected'] && this.props[type + 'Selected'].delivery;
        return <>
            <span className={"cx-table-ico-color"} style={{backgroundColor: helper.getStatusColor(status)}}></span>
            <span className={"cx-table-ico-state"}>{status}</span>
        </>;
    }

    activateTypeAction = async (type) => {
        const activateFunc = this.props[type+'Activate'];
        const params = this.props[type + 'Selected'];
        this.setState({activationLoader: true});
        await activateFunc(params);
        // refresh type list
        //this.props[type+'ReloadList']();
        this.refreshData();
        await this.fetchSelectItemData(this.props.selectedType);
        this.setState({activationLoader: false});
    }

    activateType = () => {
        const type = this.props.selectedType && this.props.selectedType.type;
        if(!(this.props[type + 'Selected'] && Object.keys(this.props[type + 'Selected']).length)) return '';
        const isTurnedOn = this.props[type + 'Selected'] && this.props[type + 'Selected'].isTurnedOn;
        return <div className={`cx-input-container cx-switch-container`}>
            <Switch
                offColor=""
                offText=""
                onColor=""
                onText=""
                disabled={this.state.activationLoader}
                onChange={(e) => { this.activateTypeAction (type)}}
                value={isTurnedOn}
            />
        </div>;
    }

    drawerModeChange = (e, mode) => {
        e.preventDefault();
        this.props.setDrawerMode(mode);
    }

    render() {
        return (
            <div ref="drawerContainerElt" className={`cx-drawer-container d-none`}>
                <div className={'overlay'}></div>
                <div className={"cx-drawer-float"}>
                    <div className={"cx-drawer-actions"}>
                        <a className={"cx-drawer-back"} href={"#"} onClick={(e) => { this.closeDrawer(e) }}>
                            <i className={"fa fa-arrow-circle-right"}></i>
                        </a>
                        <a className={`cx-drawer-edit ${this.props.drawerMode == 'edit' ? 'active' : ''}`}
                           href={"#"}
                           onClick={(e)=>{this.drawerModeChange(e, 'edit')}}
                        >
                            <i className={"fa fa-pencil-alt"}></i>
                        </a>
                        <a className={`cx-drawer-performance ${this.props.drawerMode == 'report' ? 'active' : ''}`}
                           href={"#"}
                           onClick={(e)=>{this.openPerformance(e)}}
                        >
                            <i className={"fa fa-chart-pie"}></i>
                        </a>
                    </div>
                    <div className={"cx-drawer-tree"}>
                        {this.state.loadingTree ? <CxLoader /> : ''}
                        <div className={"cx-drawer-tree-search"}>
                            <i className={"fa fa-search"}></i>
                            <input type={"text"} value={this.state.searchTree} placeholder={"Search"} onChange={(e) => { this.setState({ searchTree: e.target.value }); }} />
                        </div>
                        <div className={"cx-drawer-tree-content"} ref="elementRef">
                            <Tree searchStr={this.state.searchTree} refreshData={this.refreshData}
                                fetchSelectItemData={this.fetchSelectItemData}
                                loading={this.state.loadingTree} />
                        </div>
                    </div>
                    <div className={"cx-drawer-content"}>
                        <div className={"cx-drawer-breadcrumb d-flex justify-content-between"}>
                            <span className={"count"}>
                                {(this.props.drawerMode == 'edit')?
                                    // edit mode means create or edit forms
                                    <>{(this.props.drawerFormAction === 'add') ? 'Create' : 'Edit'} {this.props.selectedType && this.props.selectedType.type}</>
                                    :
                                    // else report mode
                                    <>{this.props.selectedType && this.props.selectedType.type} Report</>
                                }

                            </span>
                            {(this.props.drawerMode == 'edit') ?
                                <div className={"cx-type-status-container d-flex"}>
                                    <div className={"cx-type-status mr-2"}>{this.printTypeStatus()}</div>
                                    <div className={"cx-type-status"}>{this.activateType()}</div>
                                </div>
                                : <></>
                            }
                            {/*<span className={"active"}>Campaign</span>*/}
                            {/*<i className="fas fa-chevron-right"></i>*/}
                            {/*<span className={"count"}>4 Adsets</span><i className="fas fa-chevron-right"></i>*/}
                            {/*<span>6 Ads</span>*/}
                        </div>
                        <div className={"cx-drawer-content-inner"}>
                            {this.renderActiveForm()}
                        </div>
                    </div>
                </div>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        campaignSelected: state.campaigns.campaign,
        adsetSelected: state.adsets.adset,
        adSelected: state.ads.ad,
        drawerFormAction: state.slidedrawer && state.slidedrawer.formAction,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType,
        selectedData: state.slidedrawer && state.slidedrawer.selectedData,
        isDrawerOpened: state.slidedrawer && state.slidedrawer.isDrawerOpened,
        drawerMode: state.slidedrawer && state.slidedrawer.drawerMode,
        drawerTree: state.campaigns.campaignsTree,
        isDirtyCampaign: isDirty('EditCampaign')(state),
        isDirtyAdset: isDirty('EditAdset')(state),
        isDirtyAd: isDirty('EditAd')(state),
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
        setDrawerMode: (mode) => dispatch(drawerActions.setDrawerMode(mode)),
        resetDrawerForm: () => dispatch(drawerActions.resetDrawerForm()),
        setDrawerForm: (selectedType, formAction = 'edit') => dispatch(drawerActions.setDrawerForm(selectedType, formAction)),
        fetchCampaignData: (campaignId) => dispatch(campaignActions.fetchCampaign(campaignId)),
        fetchAdsetData: (adsetId) => dispatch(adsetActions.fetchAdset(adsetId)),
        fetchAdData: (adId) => dispatch(adActions.fetchAd(adId)),
        reloadTree: () => dispatch(campaignActions.fetchCampaignsTree()),
        campaignReloadList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
        adsetReloadList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
        adReloadList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
        campaignActivate: (obj) => dispatch(campaignActions.activateCampaign(obj)),
        adActivate: (obj) => dispatch(adActions.activateAd(obj)),
        adsetActivate: (obj) => dispatch(adsetActions.activateAdset(obj)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(Drawer);