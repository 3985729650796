import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { widgetPerformanceActions, mapActions } from "../../../actions";
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
import _ from "lodash";

require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);

const helper = new mainHelper();

class Map extends Component {
    constructor(props){
        super(props);
        this.state = {
            loader: false,
        }
    }
    componentDidUpdate(prevProps) {
        if(!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter &&!_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
          this.fetchData();
        }
     }
    /*
    * componentDidMount
    */
   
    componentDidMount() {
        this.fetchData();
    }
  
    fetchData = async () => {
        if (this.props.dateFilter) {
        this.setState({loader: true});
        if(!this.props.mapData){
            await this.props.fetchMapData();
        }
        await this.props.locationFetch(this.props.pageFilters,this.props.dateFilter);
        this.setState({loader: false});
    }}

    getData = () => {
        // return the data in the following format [[countryCode, clicks], [countryCode, clicks]]
        if(!this.props.locationWidgetData.length) return [];
        let res = [];

        for(let i in this.props.locationWidgetData) {
            const locationD = this.props.locationWidgetData[i];
            if(locationD.clicks){
                // res.push([locationD.countryCode.toLowerCase(), locationD.clicks]);
                res.push({
                    'hc-key': locationD.country_code.toLowerCase(),
                    value: locationD.clicks,
                    impressions: locationD.impressions,
                    views: locationD.views
                });
            }
        }
        //original means res will look like so [["sa", 78], [..]
        // 0: {hc-key: "sa", value: 78}
        // 1: {hc-key: "ae", value: 145}
        // 2: {hc-key: "kw", value: 29}
        // 3: {hc-key: "ro", value: 1}
        return res;
    }

    formatNumber = (subtab, value)=> {
        switch(subtab){
            case 'clicks':
            case 'views':
            case 'impressions':
                return helper.formatNumber(value);
            case 'amountSpent':
                return '$' + value.toLocaleString();
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-performance-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    highcharts={Highcharts}
                    containerProps={{ className: "highchartscss" }}
                    constructorType={'mapChart'}
                    options={
                        {
                            exporting: {
                                enabled: false,
                                buttons: {
                                    contextButton: {
                                        menuItems: [
                                            'viewFullscreen',
                                            'separator',
                                            'downloadPDF'
                                        ]
                                    }
                                },
                            },
                            title: {
                                text: ''
                            },
                            subtitle: {
                                text:  'Clicks By Location',
                                style: {
                                    color: '#9899A6'
                                },
                            },
                            chart:{
                                backgroundColor: 'transparent',
                                style: {
                                    fontFamily: 'Poppins'
                                }
                            },
                            mapNavigation: {
                                enabled: true
                            },
                            credits: {
                                enabled: false
                            },
                            colorAxis: {
                                min: 0,
                                stops: [
                                    [0, '#BBE6F9'],
                                    [1, '#4090B4']
                                ],
                                labels: {
                                    style: {
                                        color: '#9899A6'
                                    }
                                }
                            },
                            tooltip: {
                                formatter: function () {
                                    return '<div><h2><strong>' + this.point.name + '</strong></h2><br/>' +
                                        '<div><strong>Clicks:</strong><span>' + self.formatNumber('clicks',this.point.value) + '</span></div><br/>' +
                                        '<div><strong>Views:</strong><span>' + self.formatNumber('views',this.point.views) + '</span></div><br/>' +
                                        '<div><strong>Impressions:</strong><span>' + self.formatNumber('impressions',this.point.impressions) + '</span></div><br/>' +
                                        '</div>';
                                },
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            },
                            legend: {
                                enabled: true,
                                layout: 'vertical',
                                align: 'left',
                                verticalAlign: 'bottom'
                            },
                            series: [{
                                mapData: this.props.mapData,
                                name: "Clicks",
                                data: this.getData()
                            }]
                        }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {        
        dateFilter: state.filter && state.filter.date|| null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        locationWidgetData: (state.widgetPerformance && state.widgetPerformance.locationGraphData)? state.widgetPerformance.locationGraphData: [],
        mapData: (state.map && state.map.mapData)? state.map.mapData : null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        locationFetch: (filter,dateFilter) => dispatch(widgetPerformanceActions.fetchLocationGraph(filter,dateFilter)),
        fetchMapData: () => dispatch(mapActions.fetchMapData())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);