import _auth from "./auth";
import apiUrl from '../helpers/global.jsx';
import {ES_API} from "../helpers/global.jsx"

const auth = new _auth();

const contentGroupingApi = {

    getList : async () => {
        const url = apiUrl + `/content-grouping-list`;
        return auth.request.post(url);
    },
    read : async (id) => {
        const url = apiUrl + `/content-grouping-read`;
        return auth.request.post(url, { data: JSON.stringify({id}) });
    },
    create : async (data) => {
        const url = apiUrl + `/content-grouping-create`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    update : async (data) => {
        const url = apiUrl + `/content-grouping-update`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    delete : async (data) => {
        const url = apiUrl + `/content-grouping-delete`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    getAppDomains : async () => {
        const url = ES_API + `/list-app-domains`;
        return auth.request.get(url);
    },
    getAllClassesMapping : async () => {
        const url = ES_API + `/list-top-classes-mapping`;
        return auth.request.get(url);
    },
    ES_searchKeywords: async (domain, classMapping, text) => {
        const url = ES_API + `/search-keywords?appdomain=${domain}&mapping=${classMapping}&text=${text}`;
        return auth.request.get(url)
    },
    ES_searchClasses: async (domain, classMapping, text) => {
        const url = ES_API + `/search-classes-values?appdomain=${domain}&mapping=${classMapping}&text=${text}`;
        return auth.request.get(url)
    },
}

export default contentGroupingApi;