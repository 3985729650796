import React, {useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {CXPill} from "../../../CXComponents"
import useContentGroupingStore from '../contentGroupingStore';

const CXPillList = ({name, control, setValue, displayProp}) => {

    const { field: { value }} = useController({
        name,
        control,
    });

    const selectedKeywords = useContentGroupingStore(state=>state.selectedKeywords);
    const setSelectedKeywords = useContentGroupingStore(state=>state.setSelectedKeywords);
    const removeKeyword = useContentGroupingStore(state=>state.removeKeyword);

    const [pills, setPills] = useState(value);
    
    useEffect(() => {
        if(value) setSelectedKeywords(value)
    }, []);

    useEffect(() => {
        setPills(value)
    }, [value]);

    useEffect(() => {
        setValue(name, selectedKeywords);
    }, [selectedKeywords]);
    return (
        <div className='segmentation-dimension-pill-container'>
            {
                pills?.map((l, i)=>{
                    return <CXPill
                                key={i}
                                text={(typeof l === "object" && displayProp) ? l[displayProp] : (typeof l === "object") ? "You are trying to render an object" : l}
                                close={()=>removeKeyword(pills[i])}
                                background={l.type === "keyword" ? "#F0555D" : "#3578ff"}
                            />
                })
            }
        </div>
    );
}

export default CXPillList;
