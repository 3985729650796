import {FETCH_CATEGORIES_LIST, FETCH_CATEGORIES_OPTIONS} from "../constants/appConstant";
import campaignApi from '../api/campaigns';

const api = new campaignApi();

/*
 * action creators
 */
const categoryList = (categories) => ({ type: FETCH_CATEGORIES_LIST, payload: categories});

const categoryOptions = (categories) => ({ type: FETCH_CATEGORIES_OPTIONS, payload: categories});

export const fetchAllCategories = (queryStr) => {
    return (dispatch) => {
        return api.getCategories(queryStr)
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(categoryList(res))
            })
            .catch(error => {
                throw(error);
            });
    };
};

export const fetchIABCategories = (queryStr) => {
    return (dispatch) => {
        return api.getIABCategories(queryStr)
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(categoryList(res))
            })
            .catch(error => {
                throw(error);
            });
    };
};

// categoriesMapping

export const fetchAllCategoryOptions = (queryStr) => {
    return async (dispatch, getState) => {
        // get the locations
        await dispatch(fetchAllCategories());
        if(getState().category.categories && getState().category.categories){
            dispatch(categoryOptions(getState().category.categories));
        }
    };
};

export const fetchIABCategoryOptions = (queryStr) => {
    return async (dispatch, getState) => {
        // get the locations
        await dispatch(fetchIABCategories());
        if(getState().category.categories && getState().category.categories){
            dispatch(categoryOptions(getState().category.categories));
        }
    };
};