import React, { useState, useEffect, useCallback } from "react";
import { CXCheckbox, CXSelectField } from "CXComponents";
import { useWatch } from "react-hook-form";
import useWidgetStore from "../widgetStore";

const schemaSelectFieldOptions = [
    {
        label: "Content+Location",
        value: "Content+Location",
    },
    {
        label: "Trending+Location",
        value: "Trending+Location",
    },
    {
        label: "Related+Content",
        value: "Related+Content",
    },
    {
        label: "Previously_searched+Trending",
        value: "Previously_searched+Trending",
    },
    {
        label: "Previously_searched+Content",
        value: "Previously_searched+Content",
    },
    {
        label: "Missed_Articles_From_User_Profile",
        value: "Missed_Articles_From_User_Profile",
    },
    {
        label: "Related_News",
        value: "Related_News",
    },
    {
        label: "Trending",
        value: "Trending",
    },
    {
        label: "Content",
        value: "Content",
    },
    {
        label: "Campaign",
        value: "Campaign",
    },
    {
        label: "Random",
        value: "Random",
    },
    {
        label: "Trending_Content",
        value: "Trending_Content",
    },
    {
        label: "Trending_Reversed",
        value: "Trending_Reversed",
    },

];

const SchemaFieldItem = ({
    control,
    register,
    index,
    duplicateField,
    remove,
    update,
    mappingsList,
    field
}) => {
    const [logicWatch, mappingWatch, valueWatch, allowBoostingWatch] = useWatch(
        {
            control,
            name: [
                `outline.${index}.logic`,
                `outline.${index}.mapping`,
                `outline.${index}.category`,
                `outline.${index}.allowBoosting`,
            ],
        }
    );

    const disableFormSubmit = useWidgetStore((state) => state.disableFormSubmit);
    const enableFormSubmit = useWidgetStore((state) => state.enableFormSubmit);


    const getValuesList = useCallback(() => {
        if (!mappingWatch) return [];
        let mappingMatch;
        mappingMatch = mappingsList
            .filter((obj) => obj.class === mappingWatch.value)[0]
        if(!mappingMatch) return [];
        return mappingMatch.values.map((value) => ({
            label: value.tag,
            value: value.tag,
        }));
    }, [mappingsList, mappingWatch])

    const [isEditing, setIsEditing] = useState(false);
    const [valuesList, setValuesList] = useState(getValuesList());


    useEffect(() => {
        setValuesList(getValuesList());
    }, [mappingWatch, getValuesList]);




    const updateSchemaItem = () => {
        update(index, {
            logic: logicWatch?.value,
            mapping: mappingWatch?.value,
            category: valueWatch?.value,
            allowBoosting: allowBoostingWatch,
        });
        setIsEditing(false);
        enableFormSubmit();
    };

    const cancelUpdatingSchemaItem = () => {
        setIsEditing(false);
        enableFormSubmit();
    };

    const getMappingsList = () => {
        return mappingsList.map((obj) => ({
            label: obj.class,
            value: obj.class,
        }));
    };

    

    const renderEditingMode = () => {
        return (
            <div className="schema-field-item__edit">
                <div className="schema-field-item__edit__title">
                    <h5>Logic:</h5>
                    <CXSelectField
                        {...{
                            control,
                            name: `outline.${index}.logic`,
                            options: schemaSelectFieldOptions,
                            className: "schema-logic-select",
                        }}
                    />
                </div>

                <div className="schema-field-item__edit__title">
                    <h5>Mapping:</h5>
                    <CXSelectField
                        {...{
                            control,
                            name: `outline.${index}.mapping`,
                            options: getMappingsList(),
                            className: "schema-logic-select",
                        }}
                    />
                </div>
                <div className="schema-field-item__edit__title">
                    <h5>Value:</h5>
                    <CXSelectField
                        {...{
                            control,
                            name: `outline.${index}.category`,
                            options: valuesList,
                            className: "schema-logic-select",
                        }}
                    />
                </div>
                <CXCheckbox
                    {...{
                        label: "Allow Article Boosting Here",
                        name: `outline.${index}.allowBoosting`,
                        defaultValue: false,
                        register,
                    }}
                />
                <div className="schema-field-item__edit__actions">
                    <i
                        className="fa fa-check"
                        title="save"
                        onClick={updateSchemaItem}
                    ></i>
                    <i
                        className="fa fa-times"
                        title="cancel"
                        onClick={cancelUpdatingSchemaItem}
                    ></i>
                </div>
            </div>
        );
    };

    const renderViewMode = () => {
        return (
            <div className="schema-field-item__view">
                <div className="schema-field-item__view__title">
                    <h5>Logic:</h5>
                    <h3>{field?.logic}</h3>
                </div>
                <div className="schema-field-item__view__title">
                    <h5>Category:</h5>
                    <h3>{field?.category}</h3>
                </div>
                {
                    field?.allowBoosting && 
                    <div className="schema-field-item__view__allowBoostingIcon">
                        <i className="fa fa-rocket"></i>
                    </div>          
                }
                
                <div className="schema-field-item__view__actions">
                    <i
                        className="fa fa-pencil"
                        title="edit"
                        onClick={() => {disableFormSubmit(); setIsEditing(true)}}
                    ></i>
                    <i
                        className="fa fa-copy"
                        title="duplicate"
                        onClick={() => duplicateField(index)}
                    ></i>
                    <i
                        className="fa fa-times"
                        title="remove"
                        onClick={() => remove(index)}
                    ></i>
                </div>
            </div>
        );
    };

    return <>{isEditing ? renderEditingMode() : renderViewMode()}</>;
};

export default SchemaFieldItem;
