import React, { Component } from "react";
import { connect } from 'react-redux';
import { campaignActions, drawerActions, adsetActions, adActions } from '../../../actions';
import Confirm from '../../components/confirmationBox';
import EditAd from "./editAdForm";
import { reduxForm, isDirty } from "redux-form";

class Tree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: this.props.loading,
            searchStr: this.props.searchStr
        };
        this.notify = {
            warning: {
                title: 'Are you sure you want to leave before saving?',
                confirm: async (data) => {
                    this.treeClick(data, true);
                    this.refs.confirmRef.removeConfirmation();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Yes',
                cancelBtnTxt: 'Cancel'
            },
            delete: {
                title: 'Are you sure you want to delete?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();

                    if (data.type == "ad")
                        await this.props.deleteAd({ ids: [data.typeid] })
                    else if (data.type == "adset")
                        await this.props.deleteAdset({ ids: [data.typeid] })
                    else if (data.type == "campaign")
                        await this.props.deleteCampaign({ ids: [data.typeid] })

                    this.props.refreshData();

                    this.hideFormIfDeleted(data);
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Delete',
                cancelBtnTxt: 'Cancel'
            },
            duplicate: {
                title: 'Are you sure you want to duplicate?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();

                    if (data.type == "ad")
                        await this.props.duplicateAd({ ids: [data.typeid] });
                    else if (data.type == "adset")
                        await this.props.duplicateAdset({ ids: [data.typeid] });
                    else if (data.type == "campaign")
                        await this.props.duplicateCampaign({ ids: [data.typeid] });

                    this.props.refreshData();

                    //alert(data.type + ' duplicated');
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Duplicate',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    hideFormIfDeleted = async (data) => {
        if(this.isSameForm(data)){
            if(data.type != 'campaign' || (this.props.selectedData && this.props.selectedData.length > 1)){
                // await this.props.resetSelections({selectedType: undefined});
                if(this.props.selectedData.length){
                    let selectedData = this.props.selectedData.filter( (item) => {
                        return (!this.props.selectedType) || !(item.type == this.props.selectedType.type && item.typeid == this.props.selectedType.typeid);
                    });
                    this.props.resetSelections({selectedType: undefined});
                    this.props.setDrawerVisible(true, selectedData);
                    this.treeClick(this.props.selectedData[0], true);
                }
            }else if(this.props.selectedData && this.props.selectedData.length && this.props.selectedData.length == 1){
                // if it is the only one selected in the tree then close the drawer
                this.props.setDrawerVisible(false);
            }
        }
    }

    confirmOptionActions = (type, data) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: type, data: data });
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loader: this.props.loading });
        }
        if (prevProps.searchStr !== this.props.searchStr) {
            this.setState({ searchStr: this.props.searchStr });
        }
    }


    treeClick = async (data, passToEnd = false) => {
        if(!passToEnd &&
            this.props.drawerMode === 'edit' &&
            (
                (this.props.selectedType.type === 'campaign' && this.props.isDirtyCampaign) ||
                (this.props.selectedType.type === 'adset' && this.props.isDirtyAdset) ||
                (this.props.selectedType.type === 'ad' && this.props.isDirtyAd)
            )
        ){
            this.confirmOptionActions('warning', data);
            return;
        }

        this.props.setDrawerForm(data);
        this.setState({
            active: data
        });
        await this.props.fetchSelectItemData(data);
    }

    removeAllOpenedOptions = () => {
        const parents = document.querySelectorAll('.cx-tree-more-options');
        const children = document.querySelectorAll('.cx-tree-options-values');
        for (let i = 0; i < parents.length; i++) {
            parents[i].classList.remove('open');
            children[i].classList.add('d-none');
        }
    }

    openOptions(e, id, data) {
        e.stopPropagation();
        if (!document.getElementById('cx-tree-options-btn-' + id).classList.contains('open')) {
            // doesnt contain class open so close others and open the one needed
            this.removeAllOpenedOptions();
            document.getElementById('cx-tree-options-btn-' + id).classList.add('open');
            document.getElementById('cx-tree-options-' + id).classList.remove('d-none');
        } else {
            // has class opened so close it
            this.removeAllOpenedOptions();
        }
    }

    createOptionActions = async (type, data) => {
        switch (type) {
            case 'adset':
                await this.props.quickCreateAdset({ campaignId: data.typeid });
                break;
            case 'ad':
                await this.props.quickCreateAd({ campaignId: data.campaignId, adsetId: data.typeid });
                break;
            default:
                break;
        }
        this.props.refreshData();
    }

    renderOptions = (data) => {
        switch (data.type) {
            default:
            case 'campaign':
                return (
                    <>
                        <div className={"add"} onClick={() => this.createOptionActions('adset', data)}>
                            Create Adset
                        </div>
                        <div className={"clone"} onClick={() => this.confirmOptionActions('duplicate', data)}>
                            Duplicate
                        </div>
                        <div className={"delete"} onClick={() => this.confirmOptionActions('delete', data)}>
                            Delete
                        </div>
                    </>
                );
            case 'adset':
                return (
                    <>
                        <div className={"add"} onClick={() => this.createOptionActions('ad', data)}>
                            Create Ad
                        </div>
                        <div className={"clone"} onClick={() => this.confirmOptionActions('duplicate', data)}>
                            Duplicate
                        </div>
                        <div className={"delete"} onClick={() => this.confirmOptionActions('delete', data)}>
                            Delete
                        </div>
                    </>
                );
            case 'ad':
                return (
                    <>
                        <div className={"clone"} onClick={() => this.confirmOptionActions('duplicate', data)}>
                            Duplicate
                        </div>
                        <div className={"delete"} onClick={() => this.confirmOptionActions('delete', data)}>
                            Delete
                        </div>
                    </>
                );
        }
    }

    renderSearchedName (name, string){
        if(!string || !name.includes(string)) return name;
        const parts = name.split(string);
        return parts.map((p, i)=> {
            return <>{p}{(i !== parts.length-1)? <span className={'cx-search-result'}>{string}</span> : ''}</>;
        });
    }

    isSameForm = (data) => {
        return (
            this.props.selectedType &&
            this.props.selectedType.type &&
            this.props.selectedType.typeid &&
            this.props.selectedType.type == data.type &&
            this.props.selectedType.typeid == data.typeid
        );
    }

    drawTree = (data, oldIndex = 0) => {
        return (<>
            {data.map((data) => {
                const active = (
                    // this.props.formAction === 'edit' &&
                    this.isSameForm(data)
                ) ? ' active' : '';
                const clickFunc = (!active) ? () => (this.treeClick(data)) : () => { };
                const id = 'tree-' + data.type + '-' + data.typeid;
                return (
                    <div key={id} className={`cx-tree-level-${oldIndex + 1}${active}`} onClick={(e) => { e.stopPropagation(); clickFunc() }}>
                        <div className={"cx-tree-type-name-options"}>
                            <div className={`cx-tree-type-name`}>
                                <i className={data.icon}></i>
                                <span title={data.name} className={'cx-tree-name-text'}>{this.renderSearchedName(data.name, this.state.searchStr)}</span>
                            </div>
                            { (this.props.drawerMode == 'edit')?
                                <div id={`cx-tree-options-btn-${id}`} className={"cx-tree-more-options"} onClick={(e) => { this.openOptions(e, id, data) }}>
                                    <i className="fas fa-ellipsis-v"></i>
                                    <div id={`cx-tree-options-${id}`} className={"cx-tree-options-values d-none"}>
                                        {this.renderOptions(data)}
                                    </div>
                                </div>
                                :
                                <></>
                            }
                        </div>
                        {(data.children && data.children.length) ? this.drawTree(data.children, oldIndex + 1) : ''}
                    </div>
                );
            })}
        </>)
    }


    render() {
        return (
            <div className={"cx-tree-container"}>
                {this.props.campaignsTree && this.props.campaignsTree.length > 0 && this.drawTree(this.props.campaignsTree)}
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        drawerMode: state.slidedrawer && state.slidedrawer.drawerMode,
        campaignsTree: state.campaigns.campaignsTree,
        formAction: state.slidedrawer && state.slidedrawer.formAction,
        selectedData: state.slidedrawer && state.slidedrawer.selectedData,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType,
        isDirtyCampaign: isDirty('EditCampaign')(state),
        isDirtyAdset: isDirty('EditAdset')(state),
        isDirtyAd: isDirty('EditAd')(state),
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        deleteCampaign: (item) => dispatch(campaignActions.deleteCampaign(item)),
        deleteAdset: (item) => dispatch(adsetActions.deleteAdset(item)),
        deleteAd: (item) => dispatch(adActions.deleteAd(item)),

        quickCreateAd: (item) => dispatch(adActions.quickCreateAd(item)),
        quickCreateAdset: (item) => dispatch(adsetActions.quickCreateAdset(item)),

        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),

        duplicateAd: (item) => dispatch(adActions.duplicateAd(item)),
        duplicateAdset: (item) => dispatch(adsetActions.duplicateAdset(item)),
        duplicateCampaign: (campaign) => dispatch(campaignActions.duplicateCampaign(campaign)),
        setDrawerForm: (selectedType, formAction = 'edit') => dispatch(drawerActions.setDrawerForm(selectedType, formAction)),
        resetSelections: (data) => dispatch(drawerActions.resetSelectedType(data)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(Tree);