import {
    FETCH_AD_LIST,
    FETCH_AD_ITEM,
    UPDATE_AD_ITEM,
    PUBLISH_AD,
    PUBLISH_AD_ERROR,
    UPDATE_AD_ERROR,
    DUPLICATE_AD_ERROR,
    DELETE_AD_ERROR,
    CREATE_AD_ERROR
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_AD_LIST:
            return { ...state, adList: action.payload };
        case FETCH_AD_ITEM:
            return { ...state, ad: action.payload };
        case UPDATE_AD_ITEM:
            return { ...state, ad: action.payload };
        case PUBLISH_AD:
            return { ...state, ad: action.payload };
        case PUBLISH_AD_ERROR:
            return { ...state, adPublishError: action.payload };
        case UPDATE_AD_ERROR:
            return { ...state, adUpdateError: action.payload };
        case DUPLICATE_AD_ERROR:
            return {...state, adDuplicateError: action.payload };
        case DELETE_AD_ERROR:
            return {...state, adDeleteError: action.payload };
        case CREATE_AD_ERROR:
            return {...state, adCreateError: action.payload };
        // case DELETE_AD_ITEM:
        //     return { ...state, ad: action.payload };
        // case DUPLICATE_AD_ITEM:
        //         return { ...state, ad: action.payload };
        default:
            return state;
    }
}