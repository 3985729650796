import React from 'react'
import Highcharts, { color } from 'highcharts'
import mainHelper from "../../../../helpers/main";
import HighchartsReact from 'highcharts-react-official'
import { useQuery } from 'react-query';
import { getContentAnalysisTotals } from "../contentAnalysisAPI"
import '../contentAnalysis.scss'
import {Grid} from "@mui/material";

let helpers = new mainHelper();

const ContentAnalysisTotals = () => {
    const { data, isLoading } = useQuery(['get-content-analysis-totals'], getContentAnalysisTotals, {
        refetchOnWindowFocus: false
    });
    const getSectionRoaData = () => {
        if (!data) return [];
        let sectionData = {};
        for (let i = 0; i < data.length; i++) {
            const section = data[i].section;
            if (!sectionData[section]) {
                sectionData[section] = {
                ROA: 0,
                published_posts: 0,
              };
            }
            sectionData[section].ROA += data[i].ROA;
            sectionData[section].published_posts += data[i].published_posts;
          }
          return sectionData;
    }
    console.log(getSectionRoaData())
    const getData = () => {
        if (!data) return [];
        let contentData = {},
        pageviews = [], posts = [];
        for (let i in data) {
            let date = data[i].date;
            //date = Date.parse(date);
            if (!contentData[date]) {
                contentData[date] = {
                    pageviews: 0,
                    published_posts: 0,
                };
            }
            contentData[date].pageviews += data[i].pageviews;
            contentData[date].published_posts += data[i].published_posts;
        }
        
        for (let j in contentData) {
            console.log(j)
            pageviews.push([Date.parse(j), contentData[j].pageviews])
            posts.push([Date.parse(j), contentData[j].published_posts])
        }
        console.log(posts)
        return [
            {
                yAxis: 0, dataLabels: { enabled: false },
                type: 'spline', name: "Pageviews", color: '#ff8d72', data: pageviews, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                yAxis: 1, fillOpacity: 0.3, "dataLabels": { "enabled": false },
                type: 'spline', name: "Published Posts", color: '#55c0f0', data: posts, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "square" }
            }
        ];
    }
let sectionRoaData = getSectionRoaData()
const optionBubble = {
    "title": { "text": "" },
    "subtitle": { "text": "" },
    "exporting": { "enabled": false },
    chart: {
        type: 'bubble',
        plotBorderWidth: 0,
        zoomType: 'xy',
        backgroundColor: "transparent",
        width: 1000
    },
    credits: {
        enabled: false
    },
    xAxis: {
        title: { text: "ROA(Return On Article)", style: {
            color: '#fff'
        }},
        gridLineWidth: 1,
        accessibility: {
            rangeDescription: 'Range: 0 to 100.'
        },
        labels: {
            style: {
                color: '#fff'
            }
        }
    },
    tooltip: {
        formatter: function () {
          console.log(this.point)
            return this.point.name + '<br>Published Posts: ' + Highcharts.numberFormat(this.point.y, 0) + '<br>ROA: ' + Highcharts.numberFormat(this.point.x, 2);
        }
    },
    yAxis: {
        title: { text: "Published Posts", style: {
            color: '#fff'
        } },
        startOnTick: false,
        endOnTick: false,
        accessibility: {
            rangeDescription: 'Range: 0 to 100.'
        },
        labels: {
            style: {
                color: '#fff'
            }
        }
    },
    legend: {
        layout: "horizontal", "enabled": true, "floating": false, "itemStyle": {
            color: '#fff'
        }
    },
    series: Object.keys(sectionRoaData).map(key => ({
        name: key,
        data: [{
            x: parseFloat(sectionRoaData[key]['ROA'] ?? 0),
            y: parseFloat(sectionRoaData[key]['published_posts'] ?? 0),
            name: key
        }]
    }))

}
const option = {

    chart: {
        scrollablePlotArea: {
            minWidth: 700
        },
        backgroundColor: "transparent"
    },

    title: {
        text: '',
    },

    subtitle: {
        text: '',
        align: ''
    },

    xAxis: {
        type: 'datetime',
        tickInterval: 7 * 24 * 3600 * 1000, // one week
        tickWidth: 0,
        gridLineWidth: 1,
        labels: {
            align: 'left',
            x: 3,
            y: -3,
            style: {
                color: '#aeaeae'
            }
        }
    },
    credits: {
        enabled: false
    },

    yAxis: [{ // left y axis
        title: {
            text: null
        },
        labels: {
            align: 'left',
            x: 3,
            y: 16,
            style: {
                color: '#aeaeae'
            },
            format: '{value:.,0f}'
        },
        showFirstLabel: false
    }, { // right y axis
        max: 1000, // set max value to 1000
        gridLineWidth: 0,
        opposite: true,
        title: {
            text: null
        },
        labels: {
            align: 'right',
            style: {
                color: '#aeaeae'
            },
            x: -3,
            y: 16,
            format: '{value:.,0f}'
        },
        showFirstLabel: false
    }],

    legend: {
        align: 'left',
        verticalAlign: 'top',
        borderWidth: 0,
        itemStyle: {
            color: '#d5d4da'
        }
    },

    tooltip: {
        shared: true,
        crosshairs: true
    },

    plotOptions: {
        series: {
            cursor: 'pointer',
            className: 'popup-on-click',
            marker: {
                lineWidth: 1
            },
            events: {
                click: function() {
                    if (this.series.options.className.indexOf('popup-on-click') !== -1) {
                        const chart = this.series.chart;
                        const date = Highcharts.dateFormat('%A, %b %e, %Y', this.x);
                        const text = `<b>${date}</b><br/>${this.y} ${this.series.name}`;
                
                        const anchorX = this.plotX + this.series.xAxis.pos;
                        const anchorY = this.plotY + this.series.yAxis.pos;
                        const align = anchorX < chart.chartWidth - 200 ? 'left' : 'right';
                        const x = align === 'left' ? anchorX + 10 : anchorX - 10;
                        const y = anchorY - 30;
                        if (!chart.sticky) {
                            chart.sticky = chart.renderer
                                .label(text, x, y, 'callout',  anchorX, anchorY)
                                .attr({
                                    align,
                                    fill: 'rgba(0, 0, 0, 0.75)',
                                    padding: 10,
                                    zIndex: 7 // Above series, below tooltip
                                })
                                .css({
                                    color: 'white'
                                })
                                .on('click', function () {
                                    chart.sticky = chart.sticky.destroy();
                                })
                                .add();
                        } else {
                            chart.sticky
                                .attr({ align, text })
                                .animate({ anchorX, anchorY, x, y }, { duration: 250 });
                        }
                    }
                }
              }
        
        }
    },

    series: getData()
}
return (

    <>
        <Grid container>
            <Grid item md={12}>
                <h3>Page views over time of content groups </h3>
            </Grid>
            <Grid item md={12}>
                <HighchartsReact highcharts={Highcharts} options={option}/>
            </Grid>
            <Grid item md={12}>
                <h3>ROA Analysis of Content groups </h3>
            </Grid>
            <Grid item md={12}>
                <HighchartsReact highcharts={Highcharts} options={optionBubble}/>
            </Grid>
        </Grid>
    </>

)
}
export default ContentAnalysisTotals;