import React from "react";

const CXInputField = ({
    label,
    name,
    style,
    defaultValue,
    disabled,
    className,
    placeholder,
    type,
    register,
    errorMsg,
  }) => {
    return (
      <div
        className={`cx-input-container cx-popup-form-layout ${className ?? ""}`}
        style={style}
      >
        {label && (
          <div className={"cx-input-label"}>
            <label htmlFor={name}>{label}</label>
          </div>
        )}
        <div className={"cx-input-icon-container"}>
          <input
            id={name}
            className={`cx-input-icon-input ${errorMsg ? "cx-input-icon-input-error" : ""}`}
            placeholder={placeholder ?? ""}
            autoComplete="off"
            type={type ? type : "text"}
            disabled={disabled}
            defaultValue={defaultValue ?? ""}
            {...register(name)}
          />
          <span className="errorMsg"> {errorMsg??''} </span>
        </div>
      </div>
    );
};

export default CXInputField;