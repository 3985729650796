import api from "api/evergreen.js";

export const getEvergreenPosts = async () => {
    return api.getevergreenAnalysisData()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const EvergreenTotalsQuery = async () => {
    return api.getEvergreenPerformanceTotals()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const sectionEvergreenQuery = async() => {
    return api.sectionEvergreen()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}