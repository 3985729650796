import {
    FETCH_CAMPAIGN_LIST,
    FETCH_CAMPAIGN_TREE,
    QUICK_CREATE_CAMPAIN,
    FETCH_CAMPAIGN_ITEM,
    UPDATE_CAMPAIGN_ITEM,
    PUBLISH_CAMPAIGN,
    PUBLISH_CAMPAIGN_ERROR,
    UPDATE_CAMPAIGN_ERROR,
    DELETE_CAMPAIGN_ERROR,
    DUPLICATE_CAMPAIGN_ERROR,
    CREATE_CAMPAIGN_ERROR,
    FETCH_TO_PUBLISH,
    FETCH_TO_PUBLISH_ERR,
    ERR_PUBLISH,
    ALL_PUBLISHED,
    CAMPAIGN_FILTER,
    CAMPAIGN_FILTERS_SELECTED
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_CAMPAIGN_LIST:
            return { ...state, campaignList: action.payload };
        case QUICK_CREATE_CAMPAIN:
            return { ...state, quickCreateFormSubmission: action.payload };
        case FETCH_CAMPAIGN_TREE:
            return { ...state, campaignsTree: action.payload };
        case FETCH_CAMPAIGN_ITEM:
            return { ...state, campaign: action.payload };
        case UPDATE_CAMPAIGN_ITEM:
            return { ...state, campaign: action.payload };
        case PUBLISH_CAMPAIGN:
            return { ...state, campaign: action.payload };
        case PUBLISH_CAMPAIGN_ERROR:
            return { ...state, campaignPublishError: action.payload };
        case UPDATE_CAMPAIGN_ERROR:
            return { ...state, campaignUpdateError: action.payload };
        case DELETE_CAMPAIGN_ERROR:
            return { ...state, campaignDeleteError: action.payload };
        case DUPLICATE_CAMPAIGN_ERROR:
            return { ...state, campaignDuplicateError: action.payload };
        case CREATE_CAMPAIGN_ERROR:
            return { ...state, campaignCreateError: action.payload };
        case FETCH_TO_PUBLISH:
            return { ...state, toPublishList: action.payload };
        case FETCH_TO_PUBLISH_ERR:
            return { ...state, toPublishListErr: action.payload };
        case ALL_PUBLISHED:
            return { ...state, allPublishMsg: action.payload };
        case ERR_PUBLISH:
            return { ...state, allPublishErr: action.payload };
        case CAMPAIGN_FILTER:
            return { ...state, pageFilterList: action.payload };
        case CAMPAIGN_FILTERS_SELECTED:
            return { ...state, pageFilters: action.payload };
        default:
            return state;
    }
}