import axios from "axios";
// import querystring from "querystring";
import auth from "./auth";
import apiUrl from "../helpers/global";
import mainHelper from "../helpers/main";
import querystring from "querystring";

let helper = new mainHelper();
/* Helper Class
 * To make requesting countries and regions more flexible
 * */
class advertiser {
  
  

  constructor(props) {
    this.details = props;
    if (window.location.hostname === "localhost") {
      this.baseUrl = "http://localhost:5000";
    }
    this.auth = new auth();
  }

  getList = async () => {
    const url = apiUrl + `/advertiser-list`;
    return this.auth.request.post(url);
  };

  getListByOrganization = async () => {
    const url = apiUrl + `/advertiser-organization-list`;
    return this.auth.request.post(url);
  };

  create = async (data) => {
    const url = apiUrl + `/advertiser-create`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  getItem = async (id) => {
    const url = apiUrl + `/advertiser-read`;
    return this.auth.request.post(url, { data: JSON.stringify(id) });
  };

  updateItem = async (data) => {
    const url = apiUrl + `/advertiser-update`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  delete = async (data) => {
    const url = apiUrl + `/advertiser-delete`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  pixelsList = async (data) => {
    const url = apiUrl + `/pixel-list`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  createPixel = async (data) => {
    const url = apiUrl + `/pixel-create`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  updatePixel = async (data) => {
    const url = apiUrl + `/pixel-update`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };

  deletePixel = async (data) => {
    const url = apiUrl + `/pixel-delete`;
    return this.auth.request.post(url, { data: JSON.stringify(data) });
  };
}

export default new advertiser();
