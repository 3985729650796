import React, { useState } from "react";
import { deletePublisher, refetchList } from "./publisherAPI";
import usePublisherStore from "./PublisherStore";
import { useMutation, useQueryClient } from "react-query";
import CxLoader from "../../components/cxLoader";
import {CXConfirm} from "../../../CXComponents"


const ActionButtons = () => {

    const qc = useQueryClient();

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const togglePopup = usePublisherStore((state) => state.togglePopup);
    const setActionType = usePublisherStore((state) => state.setActionType);
    const selectedRows = usePublisherStore((state) => state.selectedRows);
    const emptySelectedRows = usePublisherStore((state) => state.emptySelectedRows);
    const setItemToLoad = usePublisherStore((state) => state.setItemToLoad);
    const deletePublisherQuery = useMutation(PublisherID=>{
        return deletePublisher(PublisherID);
    });

    const openPopupForm = (action = 'create') => {
        if(action==="create") setItemToLoad(-1);
        setActionType(action);
        togglePopup();
    }
    const deleteSelectedRows = async () => {
        setIsDeletePopupOpen(true);
    }
    return (
        <>
        {
            isDeletePopupOpen && 
            <CXConfirm 
                {...{
                    width: '400px',
                    title: "Delete Publishers",
                    subtitle: "Are you sure you want to permanently delete the selected publishers?",
                    confirmText: "Delete All",
                    cancelText: "Cancel",
                    confirmAction: async ()=>{
                        setIsDeletePopupOpen(false);
                        emptySelectedRows();
                        await deletePublisherQuery.mutateAsync(selectedRows);
                        await qc.invalidateQueries(["publisher-list"]);
                    },
                    cancelAction: ()=>{
                        setIsDeletePopupOpen(false);
                    }
                }} 
            />
        }
        
        <div className={"header-btn-container"}>
            <button
                className="btn cx-focus-btn"
                onClick={(e) => { openPopupForm('create') }}
            >
                Create
            </button>
            <button
                className="btn"
                onClick={deleteSelectedRows}
                disabled={selectedRows.length===0}
            >
                Delete
            </button>
            {deletePublisherQuery.isLoading ? <CxLoader inPlace={true}/> : ''}
        </div>
        </>
    )
}

export default ActionButtons;