import {
  FETCH_ALLOCATIONS,
  FETCH_ALLOCATION_ITEM,
  UPDATE_ALLOCATION,
  UPDATE_ALLOCATION_ERROR,
  FETCH_ADS_REVIEW,
  ACCEPT_ADS_REVIEW,
  ERR_ACCEPT_ADS_REVIEW,
  REJECT_ADS_REVIEW,
  ERR_REJECT_ADS_REVIEW,
} from "../constants/appConstant";
import mpApi from "../api/mediaPlanner";
import { SubmissionError } from "redux-form";
const api = new mpApi();
/*
 * action creators
 */

// -----------------adset Review
const adsetAllocationListEvent = (type, data) => {
  console.log("adsetAllocationListEvent");

  return {
    type: FETCH_ALLOCATIONS,
    payload: data,
    typeData: type,
  };
};
export const adsetAllocationItemEvent = (adset) => {
  console.log("adsetAllocationItemEvent");
  return {
    type: FETCH_ALLOCATION_ITEM,
    payload: adset,
  };
};
const updateAllocationEvent = (updated) => {
  console.log("updateAllocationEvent");
  return {
    type: UPDATE_ALLOCATION,
    payload: updated,
  };
};
export const updateErrAllocationEvent = (err) => {
  console.log("updateErrAllocationEvent");
  return {
    type: UPDATE_ALLOCATION_ERROR,
    payload: err,
  };
};

// -----------------adset Review
export const fetchAdsetAllocationItem = (id) => {
  return (dispatch) => {
    return api
      .getAdsetAllocationItem(id)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        console.log(res);
        dispatch(adsetAllocationItemEvent(res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchAdsetAllocationList = (type) => {
  console.log("fetchAdsetAllocationList");
  return (dispatch) => {
    return api
      .getAdsetAllocations(type)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(adsetAllocationListEvent(type.type, res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateAdsetAllocations = (data) => {
  console.log("updateAdsetAllocations");

  return (dispatch) => {
    return api
      .updateAdsetAllocations(data)
      .then((response) => {
        let res = null;
        if (response && response.data) res = response.data;
        dispatch(updateAllocationEvent(res));
        dispatch(updateErrAllocationEvent(undefined));
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          dispatch(updateErrAllocationEvent(error.response.data));
        throw error;
      });
  };
};

// -----------------ads Review
const adsReviewsListEvent = (type, data) => ({
  type: FETCH_ADS_REVIEW,
  payload: data,
  typeData: type,
});
export const acceptAdReviewEvent = (ids) => ({
  type: ACCEPT_ADS_REVIEW,
  payload: ids,
});
export const acceptErrAdEvent = (err) => ({
  type: ERR_ACCEPT_ADS_REVIEW,
  payload: err,
});
export const rejectAdReviewEvent = (ids) => ({
  type: REJECT_ADS_REVIEW,
  payload: ids,
});
export const rejectErrAdEvent = (err) => ({
  type: ERR_REJECT_ADS_REVIEW,
  payload: err,
});

// -----------------ads Review
export const fetchAdsReviewsList = (type) => {
  // type =>  pending accepted rejected
  return (dispatch) => {
    return api
      .getAdsReviewList(type)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(adsReviewsListEvent(type.type, res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const acceptAdsReview = (data) => {
  return (dispatch) => {
    return api
      .acceptAdsRevision(data)
      .then((response) => {
        let res = null;
        if (response && response.data) res = response.data;
        dispatch(acceptAdReviewEvent(res));
        dispatch(acceptErrAdEvent(undefined));
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          dispatch(acceptErrAdEvent("errors"));
        throw error;
      });
  };
};

export const rejectAdsReview = (data) => {
  return (dispatch) => {
    return api
      .rejectAdsRevision(data)
      .then((response) => {
        let res = null;
        if (response && response.data) res = response.data;
        dispatch(rejectAdReviewEvent(res));
        dispatch(rejectErrAdEvent(undefined));
      })
      .catch((error) => {
        if (error && error.response && error.response.data)
          dispatch(rejectErrAdEvent("errors"));
        throw error;
      });
  };
};
