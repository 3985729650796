import {
    FETCH_MAP_DATA
} from "../constants/appConstant";

export default function (state = {} , action) {
    switch(action.type) {
        case FETCH_MAP_DATA:
            return { ...state, mapData: action.payload };
        default:
            return state;
    }
}