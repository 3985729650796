import create from 'zustand';

const useSurveyStore = create( (set, get) => ({
    isPopupOpen: false,
    togglePopup: () => set( state => ({isPopupOpen: !state.isPopupOpen}) ),
    
    itemToLoad: null,
    setItemToLoad: (id) => set( _state => ({itemToLoad: id}) ),
    
    actionType: 'create',
    setActionType: (type) => set( _state => ({actionType: type})),

    isDirty: false,
    setIsDirty: (bool) => set( _state => ({isDirty: bool}) )

}));


export default useSurveyStore;



