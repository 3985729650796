import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { widgetPerformanceActions } from "../../../actions";
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
import _ from "lodash";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
const helpers = new mainHelper();

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
            this.fetchData();
        }
    }
    /*
    * componentDidMount
    */
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.props.devicesFetch(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }


    getData = () => {
        // return the data in the following format [{name: device - os, y: clicks}, ..]
        if (!this.props.devicesData.length) return [];
        let res = [];

        for (let i in this.props.devicesData) {
            const devicesD = this.props.devicesData[i];
            if (devicesD['clicks']) {
                const deviceName = (devicesD.os) ? devicesD.device + ' - ' + devicesD.os : devicesD.device;
                res.push({
                    name: deviceName,
                    y: devicesD['clicks'],
                    impressions: devicesD['impressions'],
                    views: devicesD['views']
                });
            }
        }
        return res;
    }

    formatNumber = (subtab, value) => {
        if (!value) return;
        switch (subtab) {
            case 'clicks':
            case 'views':
            case 'impressions':
                return helpers.formatNumber(value);
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-devices-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    containerProps={{ className: "highchartscss" }}
                    highcharts={Highcharts}
                    options={
                        {
                            exporting: {
                                "enabled": false,
                                buttons: {
                                    contextButton: {
                                        menuItems: [
                                            'viewFullscreen',
                                            'separator',
                                            'downloadPDF'
                                        ]
                                    }
                                },
                            },
                            colors: helpers.cxColors && helpers.cxColors.dark,
                            credits: false,
                            chart: {
                                type: 'column',
                                backgroundColor: 'transparent',
                                style: {
                                    fontFamily: 'Poppins'
                                }
                            },
                            title: {
                                text: ''
                            },
                            subtitle: {
                                text: 'Clicks By Device',
                                style: {
                                    color: '#9899A6'
                                }
                            },
                            xAxis: {
                                type: 'category',
                                labels: {
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                            },
                            yAxis: {
                                title: {
                                    text: "Clicks",
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                labels: {
                                    formatter: function () {
                                        return self.formatNumber("clicks", this.y);
                                    },
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                gridLineColor: 'rgba(152,153,166, 0.2)'
                            },
                            legend: {
                                enabled: false
                            },
                            plotOptions: {
                                series: {
                                    // pointWidth: 50,
                                    borderWidth: 0,
                                    dataLabels: {
                                        enabled: true,
                                        style: {
                                            textOutline: 0,
                                            color: '#9899A6'
                                        },
                                        formatter: function () {
                                            return self.formatNumber('clicks', this.y);
                                        },
                                    }
                                }
                            },
                            tooltip: {
                                formatter: function () {
                                    return '<div><h2><strong>' + this.point.name + '</strong></h2><br/>' +
                                        '<div><strong>Clicks:</strong><span>' + self.formatNumber('clicks', this.point.y) + '</span></div><br/>' +
                                        '<div><strong>Views:</strong><span>' + self.formatNumber('views', this.point.views) + '</span></div><br/>' +
                                        '<div><strong>Impressions:</strong><span>' + self.formatNumber('impressions', this.point.impressions) + '</span></div><br/>' +
                                        '</div>';
                                },
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            },

                            series: [
                                {
                                    name: "Devices",
                                    colorByPoint: true,
                                    data: this.getData()
                                }
                            ]
                        }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        devicesData: (state.widgetPerformance && state.widgetPerformance.devicesData) ? state.widgetPerformance.devicesData : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        devicesFetch: (filter, dateFilter) => dispatch(widgetPerformanceActions.fetchDevicesGraph(filter, dateFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);