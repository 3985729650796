import axios from 'axios';
// import querystring from "querystring";
import auth from "./auth";
import apiUrl from '../helpers/global';
import mainHelper from '../helpers/main';
import querystring from 'querystring';

let helper = new mainHelper();
/* Helper Class
* To make requesting countries and regions more flexible
* */
class widgetManageApi {
    constructor(props) {
        this.details = props;
        if (window.location.hostname === 'localhost') {
            this.baseUrl = 'http://localhost:5000';
        }
        this.auth = new auth();
    }

    getList = async () => {
        const url = apiUrl + `/widget-list`;
        return this.auth.request.post(url);
    }
   

    create = async (data) => {
        const url = apiUrl + `/widget-create`;
        return this.auth.request.post(url, { data: JSON.stringify(data) });
    }

    getItem = async (id) => {
        const url = apiUrl + `/widget-read`;
        return this.auth.request.post(url, { data: JSON.stringify(id) });
    }

    updateItem = async (data) => {
        const url = apiUrl + `/widget-update`;
        return this.auth.request.post(url, { data: JSON.stringify(data) });
    }

    delete = async (data) => {
        const url = apiUrl + `/widget-delete`;
        return this.auth.request.post(url, { data: JSON.stringify(data) })
    }

    getClasses = async () => {
        const url = apiUrl + `/recommendation-classes-list`;
        return this.auth.request.post(url)
    }

}

export default new widgetManageApi();
