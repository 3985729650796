import React from 'react';
import CxLoader from '../views/components/cxLoader';
import CXPopup from './CXPopup';


const CXConfirm = ({className, width, title, subtitle, confirmText, cancelText, confirmAction, cancelAction, loadingState}) => {
    return (
        <CXPopup>
            <div className={`cx-popup-confirm ${className??''}`} style={{width}}>
                <div className="cx-popup-confirm__title">
                    <h3>{title??"Are you sure?"}</h3>
                    <p>{subtitle??""}</p>
                </div>
                <div className="cx-popup-confirm__actions">
                    {
                        loadingState
                        ? <CxLoader inPlace="true"/>
                        : <>
                            <button className='cx-btn cx-bg-danger cx-popup-confirm__confirmBtn' onClick={confirmAction??(()=>{})}>{confirmText??"confirm"}</button>
                            <button className='cx-btn cx-popup-confirm__cancelBtn' onClick={cancelAction??(()=>{})}>{cancelText??"cancel"}</button> 
                        </>
                    }
                </div>
            </div>            
        </CXPopup>
    );
}

export default CXConfirm;
