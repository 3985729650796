import React from "react";
import { CXInputField } from "../../../CXComponents"


const ChoiceField = ({ index, control, register, remove, update, field }) => {
    return <div className="choice">
        <CXInputField 
            register={register}
            name={`choices.${index}.text`}
            label={`Choice ${index+1}`}
        />
        <span className={"close-btn"} onClick={()=>remove(index)}>
            <i className={"fa fa-times"}></i>
        </span>
    </div>;
};

export default ChoiceField;
