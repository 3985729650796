import {
    ONBOARDING_ADD_INFO_ERR,
    ONBOARDING_VALIDATE_METADATA, ONBOARDING_VALIDATE_METADATA_ERR,
    ONBOARDING_ADD_USERS_ERR,
    ONBOARDING_LOAD_DETAILS,
    ONBOARDING_SEND_EMAIL_ERR
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case ONBOARDING_ADD_INFO_ERR:
            return {...state, addInfoSaveErr: action.payload};
        case ONBOARDING_VALIDATE_METADATA:
            return {...state, metaDataValidated: action.payload};
        case ONBOARDING_VALIDATE_METADATA_ERR:
            return {...state, metaDataValidateErr: action.payload};
        case ONBOARDING_ADD_USERS_ERR:
            return {...state, addUsersSaveErr: action.payload};
        case ONBOARDING_LOAD_DETAILS:
            return {...state, onboardDetails: action.payload};
        case ONBOARDING_SEND_EMAIL_ERR:
        return {...state, onboardingEmailErr: action.payload};
        default:
            return state;
    }
}