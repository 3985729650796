import React, { useState } from "react";
import useArticleBoostingStore from "../articleBoostingStore";
import { CXConfirm, CXCardStatus } from "CXComponents";
import { useMutation, useQueryClient } from "react-query";
import { deleteArticleBoost } from "../articleBoostAPI";
import {
    CCard,
    CCardImage,
    CCardBody,
    CCardTitle,
    CCardText,
} from "@coreui/react";

const ArticleBoostingCard = ({ cogThumb, title, duration, isActive, id }) => {
    const [isDeletedArticleBoostOpen, setIsDeletedArticleBoostOpen] =
        useState(false);
    const setItemToLoad = useArticleBoostingStore(
        (state) => state.setItemToLoad
    );
    const setIsOpen = useArticleBoostingStore((state) => state.setIsOpen);
    const setActionType = useArticleBoostingStore(
        (state) => state.setActionType
    );

    const deleteArticleBoostingQuery = useMutation(() => {
        return deleteArticleBoost(id);
    });

    const qc = useQueryClient();

    const openArticleBoostingForm = (e) => {
        setActionType("edit");
        setIsOpen(true);
        setItemToLoad(id);
    };

    const confirmArticleBoostingDelete = () => {
        setIsDeletedArticleBoostOpen(true);
    };

    return (
        <>
            <div className="cursor-pointer">
                <CCard style={{ width: "18rem" }}>
                    <div onClick={openArticleBoostingForm}>
                        <CCardImage
                            style={{
                                width: "300px",
                                height: "200px",
                                objectFit: "cover",
                            }}
                            orientation="top"
                            src={cogThumb}
                        />
                        <CCardBody>
                            <CCardTitle
                                style={{
                                    textAlign: "right",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    direction: "rtl",
                                }}
                            >
                                {title}
                            </CCardTitle>
                            <CCardText>
                                {duration} days of boosting is remaining
                            </CCardText>
                            <br />
                        </CCardBody>
                        <div
                            className="flex"
                            style={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: '0 10px 10px 10px'
                            }}
                        >
                            <CXCardStatus isActive={isActive} />
                            <button
                                className="cx-btn delete-article-boost"
                                onClick={confirmArticleBoostingDelete}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </CCard>
            </div>

            {isDeletedArticleBoostOpen && (
                <CXConfirm
                    {...{
                        title: "Delete Article Boost",
                        subtitle:
                            "Are you sure you want to permanently delete this Article Boosting service for this publisher?",
                        confirmAction: async () => {
                            await deleteArticleBoostingQuery.mutateAsync([id]);
                            setIsDeletedArticleBoostOpen(false);
                            await qc.invalidateQueries([
                                "article-boosting-list",
                            ]);
                        },
                        cancelAction: () => {
                            setIsDeletedArticleBoostOpen(false);
                        },
                        loadingState: deleteArticleBoostingQuery.isLoading,
                    }}
                />
            )}
        </>
    );
};

export default ArticleBoostingCard;
