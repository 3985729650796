import React from 'react';

const CXPopup = ({children, className, width, style, onClose}) => {
    return (
        <>
            <div className="cx-popup-card-overlay"></div>
            <div className={`cx-popup-card ${className??''}`} style={{width: width, ...(style??{})}}>
                {children}
            </div>
        </>
    );
}

export default CXPopup;
