import {
    SET_ROLE_ACTION_TYPE,
    FETCH_ROLES, FETCH_PERMISSIONS,
    FETCH_ROLE_ITEM,
    UPDATE_ROLE,
    UPDATE_ERR_ROLE,
    CREATE_ROLE,
    CREATE_ERR_ROLE,
    DELETE_ROLE,
    DELETE_ERR_ROLE
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_ROLE_ACTION_TYPE:
            return { ...state, actionType: action.payload };
        case FETCH_ROLES:
            return { ...state, List: action.payload };
        case FETCH_PERMISSIONS:
            return { ...state, Permissions: action.payload };
        case FETCH_ROLE_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_ROLE:
            return { ...state, update: action.payload };
        case UPDATE_ERR_ROLE:
            return { ...state, updateErr: action.payload };
        case CREATE_ROLE:
            return { ...state, create: action.payload };
        case CREATE_ERR_ROLE:
            return { ...state, createErr: action.payload };
        case DELETE_ROLE:
            return { ...state, delete: action.payload };
        case DELETE_ERR_ROLE:
            return { ...state, deleteErr: action.payload };
        default:
            return state;
    }
}