import React from "react";
import CxLoader from "../components/cxLoader";
import SurveyCard from "./components/SurveyCard";
import { getSurveys } from "./surveyAPI";
import { useQuery } from "react-query";
import PopupNew from "../components/popup_new";
import useSurveyStore from "./surveyStore";
import CreateSurveyForm from "./components/CreateSurveyForm";
import EditSurveyForm from "./components/EditSurveyForm";
import { getSegments } from "../Segmentation/segmentAPI";
import { getContentGList } from "../ContentGrouping/contentGroupingAPI";

const SurveysList = () => {
    const { isLoading, isFetching, data } = useQuery(
        ["surveys-list"],
        () => {
            // return getSurveys();
        },
        { refetchOnWindowFocus: false }
    );

    const getSegmentsQuery = useQuery(
        ["segments-list"],
        () => {
            return getSegments();
        },
        { refetchOnWindowFocus: false }
    );
    const getContentGroup = useQuery(
        ["content-grouping-list"],
        () => {
            return getContentGList();
        },
        { refetchOnWindowFocus: false }
    );

    const getContentGroupingList = useQuery(
        ["content-grouping-list"],
        () => {
            return getContentGList();
        },
        { refetchOnWindowFocus: false }
    );

    const isPopupOpen = useSurveyStore((state) => state.isPopupOpen);
    const togglePopup = useSurveyStore((state) => state.togglePopup);
    const closeDrawer = useSurveyStore((state) => state.closeDrawer);
    const setActionType = useSurveyStore((state) => state.setActionType);
    const itemToLoad = useSurveyStore((state) => state.itemToLoad);
    const setItemToLoad = useSurveyStore((state) => state.setItemToLoad);
    const actionType = useSurveyStore((state) => state.actionType);
    const isDirty = useSurveyStore((state) => state.isDirty);
    const formTitle =
        actionType === "create" ? "Create Survey" : "View & Edit Survey";

    const oneItem = [
        {
            surveyType: "Multiple Choice Question",
            choices: [
                {
                    text: "كان قرار صائب و سيؤثر بإيجابية على الوضع الاقتصادي و الاجتماعي للمملكة",
                },
                {
                    text: "كان قرار خاطئ و سيساهم في أنتشار البطالة و انعزال المملكة",
                },
                {
                    text: "في البداية ستواجه المملكة بعض المشاكل و لكن المستقبل واعد بعد هذا الانفصال",
                },
            ],
            targeting: {
                behavioral: {
                    scrollDepth: "30",
                },
                contextual: {
                    articleUrl: "",
                    category: "إقتصاد",
                },
                demographics: {
                    location: "",
                    device: "",
                },
            },
            title: "Survey Economics",
            questionText: "كيف تنظر الى حالة الإقتصاد العالمي؟ ",
        },
    ];

    return (
        <>
            <PopupNew
                title={formTitle}
                width={"70%"}
                isPopupOpen={isPopupOpen}
                togglePopup={togglePopup}
            >
                {actionType === "create" &&
                !getSegmentsQuery.isLoading &&
                !getSegmentsQuery.isFetching &&
                !getContentGroupingList.isLoading &&
                !getContentGroupingList.isFetching ? (
                    <CreateSurveyForm
                        togglePopup={togglePopup}
                        segments={getSegmentsQuery.data}
                        contentGList={getContentGroupingList.data}
                    />
                ) : (
                    <CreateSurveyForm
                        togglePopup={togglePopup}
                        segments={getSegmentsQuery.data}
                        contentGList={getContentGroupingList.data}
                        defaultValues={oneItem[0]}
                    />
                )}
            </PopupNew>
            <div className="content">
                <div>
                    <h4 style={{ marginBottom: "20px" }}>
                        <i className="fa fa-poll-h"></i>&nbsp; Survey
                    </h4>
                </div>
                <br />
                <div
                    className="cx-btn btn-blue-gradient"
                    onClick={() => {
                        setActionType("create");
                        togglePopup();
                    }}
                >
                    <i className="fa fa-plus"></i> Create New Survey
                </div>
                {!isLoading &&
                !isFetching &&
                !getSegmentsQuery.isLoading &&
                !getSegmentsQuery.isFetching &&
                !getContentGroup.isLoading &&
                !getContentGroup.isFetching ? (
                    <div className="segmentationList">
                        {oneItem.map((seg, i) => {
                            return (
                                <SurveyCard
                                    key={i}
                                    surveyType={seg.surveyType}
                                    title={seg.title}
                                    isActive={true}
                                    asnwers={31}
                                    onClick={() => {
                                        setActionType("edit");
                                        togglePopup();
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <CxLoader minHeight="80vh" />
                )}
            </div>
        </>
    );
};

export default SurveysList;
