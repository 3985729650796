import React from "react";
import { authActions } from "../../actions";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader } from "../Card/card";
import { Field, Form, reduxForm } from "redux-form";
import { Input } from "../../views/components/formElts";
import CxLoader from "../../views/components/cxLoader";

class ChangePass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpened: this.props.isPopupOpened
        ? this.props.isPopupOpened
        : false,
      loader: false,
      msg: null,
      msgAnimate: false,
    };
    this.handleSubmit = this.props.handleSubmit;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPopupOpened !== prevProps.isPopupOpened) {
      this.setState({ isPopupOpened: this.props.isPopupOpened });
    }
    if (this.props.changePasswordError !== prevProps.changePasswordError) {
      this.setState({ loader: false });
      this.props.changePassErrEvent("undefined");
    }
  }

  printMessage = (loader, msg, type = "success", callback = () => {}) => {
    const ref = this;
    if (type === "error") msg = <span style={{ color: "#F0555D" }}>{msg}</span>;
    // Update user with the message after save
    this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
      setTimeout(() => {
        ref.setState({ msgAnimate: false }, () => {
          setTimeout(() => {
            // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
            ref.setState({ msg: null });
            callback();
          }, 200);
        });
      }, 2000);
    });
  };

  submitButton = (event) => {
    event.preventDefault();
    this.handleSubmit(this.onSubmit)();
  };

  onSubmit = async (formVals) => {
    this.setState({ loader: true });
    await this.props.changePassword(formVals);
    this.printMessage(
      false,
      "Password Changed Successfully !",
      "success",
      () => {
        this.props.reset();
        this.cancelButton();
      }
    );
  };

  cancelButton = (event) => {
    if (event) event.preventDefault();
    this.props.reset();
    this.props.closePopup();
  };

  popupRender = () => {
    return (
      <div className={`cx-popup${this.state.isPopupOpened ? " open" : ""}`}>
        <div style={{width: '30%'}}>
          <Card>
            <CardHeader>
              Change Password
              <span
                className={"close-btn"}
                onClick={(e) => {
                  this.cancelButton(e);
                }}
              >
                <i className={"fa fa-times"}></i>
              </span>
            </CardHeader>
            <CardBody>
              <Form className={"cx-change-pass-container"} onSubmit={() => false}>
                <Field
                  showMsg={true}
                  type={"password"}
                  disabled={this.state.loader}
                  className={"cx-popup-form-layout cx-change-pass-input"}
                  label={"Old Password"}
                  name={"oldPassword"}
                  component={Input}
                />
                <br />
                <Field
                  showMsg={true}
                  type={"password"}
                  disabled={this.state.loader}
                  className={"cx-popup-form-layout cx-change-pass-input"}
                  label={"New Password"}
                  name={"newPassword"}
                  component={Input}
                />
                <br />
                <div className={"cx-action-buttons"}>
                  <button
                    disabled={this.state.loader}
                    className="btn cx-focus-btn"
                    onClick={(e) => {
                      this.submitButton(e);
                    }}
                  >
                    Save
                  </button>
                  {this.state.loader ? <CxLoader inPlace={true} /> : ""}
                  <button
                    disabled={this.state.loader}
                    className="btn"
                    onClick={(e) => {
                      this.cancelButton(e);
                    }}
                  >
                    Cancel
                  </button>
                  <span
                    className={`d-flex align-items-center cx-form-msgs pull-right ${
                      this.state.msgAnimate ? "show" : ""
                    }`}
                  >
                    {this.state.msg}
                  </span>
                </div>
              </Form>
            </CardBody>
          </Card>
          </div>
      </div>
    );
  };

  render() {
    return <>{this.popupRender()}</>;
  }
}

const mapStateToProps = (state) => {
  return {
    changePasswordError:
      state.auth && state.auth.changePasswordError
        ? state.auth.changePasswordError
        : null,
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassErrEvent: (err) => dispatch(authActions.changePasswordErr(err)),
  changePassword: (data) => dispatch(authActions.changePassword(data)),
});

function validate(form) {
  const errors = {};

  if (!form.oldPassword) {
    errors.oldPassword = "Field is required";
  }
  if (!form.newPassword) {
    errors.newPassword = "Field is required";
  }
  return errors;
}

ChangePass = reduxForm({
  form: "ChangePass",
  validate,
})(ChangePass);

ChangePass = connect(mapStateToProps, mapDispatchToProps)(ChangePass);

export default ChangePass;
