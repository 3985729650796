import React, {useState, useEffect, useRef} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import useArticleBoostingStore from "./articleBoostingStore";
import {readArticleBoost, createArticleBoost, updateArticleBoost, refetchList} from "./articleBoostAPI";
import CxLoader from '../components/cxLoader';
import {useForm, useWatch} from 'react-hook-form'
import {CXInputField, CXSelectField, CXCheckbox} from "../../CXComponents"
import {Row, Col} from "reactstrap"
import IntegrationApi from "../../api/integration";
import api from "../../api/onboard";
import {validateMetadataErr, validateMetadataEvent} from "../../actions/onboardActions";
import {CCard, CRow, CCol, CCardImage, CCardBody, CCardTitle, CCardText} from "@coreui/react";

const ArticleBoostingForm = ({data = {}, itemToLoad, publisherData, widgetsList,segments,contentGroups}) => {
    const qc = useQueryClient();
    const actionType = useArticleBoostingStore(state => state.actionType);
    const setIsOpen = useArticleBoostingStore(state => state.setIsOpen);


    const [activeTab, setActiveTab] = useState("General");
    const [isLoading, setIsLoading] = useState(false);
    const [crawlResults, setCrawlResults] = useState();

    let postJson = {}

    const {register, control, handleSubmit, setValue, formState: {errors}} = useForm({
        defaultValues: data
    });

    const articleUrl = useWatch({
        control,
        name: 'articleUrl',
    });


    const create = useMutation((data) => {
        return createArticleBoost(data);
    })

    const update = useMutation((data) => {
        return updateArticleBoost(data);
    })

    const onSubmit = async (formValues) => {
        const mapped = {...formValues};


        mapped.publisherId = publisherData.id;
        console.log(mapped);

        if (actionType === "edit") {
            mapped.widgetId = data.widgetId;
            await update.mutateAsync(mapped)
            qc.invalidateQueries(['article-boosting-read', itemToLoad])
        } else {
            mapped.widgetId = mapped.widgetId.value;
            const post = {};
            post.cogThumb = crawlResults.data.thumb;
            post.postType = crawlResults.data.postType;
            post.postID = crawlResults.data.postid;
            post.title = crawlResults.data.title;
            post.url = crawlResults.data.url;
            post.datePublished = crawlResults.data.datePublished;
            post.AppDomain = publisherData.domain;

            mapped.post = JSON.stringify(post);
            await create.mutateAsync(mapped);
        }
        refetchList(qc);
        setIsOpen(false);
    }

    const cancelButton = () => {
        setIsOpen(false);
    }

    const doesTabIncludeErrors = (tabName) => {
        switch (tabName) {
            case "General":
                return (errors.publisherId || errors.widgetId);
            default:
                return false;
        }
    }

    const crawlUrl = async () => {
        setIsLoading(true);
        let response = {};
        response = await IntegrationApi.crawlJsonLd(articleUrl);

        let result = {};
        setIsLoading(false);
        if (response && response.data) {
            if (response.data.status === 'OK') {
                result.data = response.data.data;

            } else if (response.data.status === 'NOT_FOUND') {
                result.error = 'CognativeX meta data Not found in the article';
            } else {
                result.error = 'An Error Has Occured: ' + response.data.status;
            }
        } else {
            result.error = 'An Error Has Occured';
        }

        setCrawlResults(result);
    };

    const validateMetadata = (data) => {
        return (dispatch) => {
            return api.validateMetadata(data)
                .then(response => {
                    let res = null;
                    if (response && response.data && response.data.data)
                        res = response.data.data;
                    dispatch(validateMetadataEvent(res))
                    dispatch(validateMetadataErr(undefined));
                })
                .catch(error => {
                    if (error && error.response && error.response.data)
                        dispatch(validateMetadataErr('Error'));
                    throw (error);
                });
        };
    }

    const renderGeneralForm = () => {
        return <>
            {publisherData?.id && actionType === "create" && <><CXSelectField
                {...{
                    control,
                    label: "Widgets *",
                    required: true,
                    name: "widgetId",
                    options: widgetsList.filter(wid => {
                        return wid.publisherId === publisherData.id
                    }).map(wid => ({value: wid.id, label: wid.title})),
                    defaultDisplay: "Select Widget",
                }}
            /><br/>
            </>}

            {publisherData?.id && actionType === "edit" && <><CXSelectField
                {...{
                    control,
                    label: "Widgets *",
                    required: true,
                    name: "widgetId",
                    options: widgetsList.filter(wid => {
                        return wid.publisherId === publisherData.id
                    }).map(wid => ({value: wid.id, label: wid.title})),
                    defaultDisplay: data.widgetLabel,
                }}
            /><br/>
            </>}

            
            <CXSelectField
                {...{
                    control,
                    label: "Segmentation",
                    required: true,
                    name: "segmentation",
                    options: segments.map((seg) => ({
                        value: seg.id,
                        label: seg.name,
                    })),
                }}
            />
            <br/>
            
            {actionType === "edit" && (<>
                <CXCheckbox
                    label="Is Active"
                    name="isActive"
                    register={register}
                    errorMsg={errors?.isActive?.message}
                />
                <br/>
            </>)
            }


            {actionType === "create" && (
                <div style={{position: "relative"}}>
                    <CXInputField
                        label="Article Url"
                        name="articleUrl"
                        register={register}
                        errorMsg={errors?.mainUrl?.message}
                        className={'postUrlInputField'}
                    />
                    <button type="button" className="getDetailsBtn" onClick={() => crawlUrl()}>
                        {isLoading ? 'Loading...' : 'Crawl Post'}
                    </button>
                </div>
            )
            }

            {crawlResults && crawlResults.error && (
                <div style={{color: 'rgb(198, 81, 81)'}}>
                    {crawlResults.error}
                </div>
            )}

            {actionType === "create" && crawlResults && crawlResults.data && (
                <div>

                    {validateMetadata(crawlResults.data) ?
                        <div className="mb-10">
                            <i className="fa fa-check green" style={{fontSize: '30px', color: '#429a42'}}></i> Meta Data
                            is valid
                        </div> :
                        <div className="mb-10">
                            <i className="fa fa-ban " style={{fontSize: '30px', color: 'red'}}></i> Meta Data is not
                            valid
                        </div>
                    }

                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <CCard className="mb-3" style={{maxWidth: '540px', border: "1px solid #f7f7f714"}}>
                            <CRow className="g-0">
                                <CCol md={8}>
                                    <CCardBody>
                                        <CCardTitle style={{textAlign: "right"}}>{crawlResults.data.title}</CCardTitle>
                                    </CCardBody>

                                </CCol>
                                <CCol md={4}>
                                    <CCardImage src={crawlResults.data.thumb}/>
                                </CCol>
                            </CRow>
                        </CCard>
                    </div>
                </div>
            )}

            {actionType == "edit" && data && data.post && (postJson = JSON.parse(data.post)) && (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width:"100%"}}><a href={postJson.url}
                                                                                                  target="_blank">
                    <CCard className="mb-3" style={{width: '100%', border: "1px solid #f7f7f714"}}>
                        <CRow className="g-0">
                            <CCol md={8}>
                                <CCardBody>
                                    <CCardTitle style={{textAlign: "right"}}>{postJson.title}</CCardTitle>
                                    <CCardText>
                                        <small className="text-medium-emphasis">Published
                                            In {postJson.datePublished}</small>
                                    </CCardText>
                                </CCardBody>

                            </CCol>
                            <CCol md={4}>
                                <CCardImage src={postJson.cogThumb}/>
                            </CCol>
                        </CRow>
                    </CCard>
                </a>
                </div>
            )}

            <br/>
            <br/>
            <div className={"cx-action-buttons"}>
                <button
                    className={"btn cx-focus-btn"}
                    disabled= {(crawlResults && crawlResults.data)||actionType==="edit" ? false : true}
                >
                    Save
                </button>
                {(create.isLoading || update.isLoading) ? <CxLoader inPlace={true}/> : ""}
                <button
                    type="button"
                    className="btn"
                    onClick={cancelButton}
                >
                    Cancel
                </button>
            </div>
        </>
    }


    return (
        <div>
            <Row className={"mx-0"}>
                <Col className={"px-0"}>
                    <form
                        className={"publisher-form-container"}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div
                            className={`${
                                activeTab === "General"
                                    ? "publisher-form-general"
                                    : "d-none"
                            }`}
                        >
                            {renderGeneralForm()}
                        </div>
                    </form>
                </Col>
            </Row>
        </div>
    );
}

const LoadData = (props) => {
    const itemToLoad = useArticleBoostingStore(state => state.itemToLoad);
    const actionType = useArticleBoostingStore(state => state.actionType);
    const readArticleBoostingQuery = useQuery(["article-boosting-read", itemToLoad], () => {
        if (actionType == "edit") return readArticleBoost(itemToLoad);
    }, {refetchOnWindowFocus: false});

    return (
        <>
            {
                readArticleBoostingQuery.isLoading ? <CxLoader minHeight="80vh"/> :
                    actionType === "edit"
                        ? <ArticleBoostingForm data={readArticleBoostingQuery.data} publisherData={props.publisherData}
                                               itemToLoad={itemToLoad} widgetsList={props.widgetsList} segments={props.segments} contentGroups = {props.contentGroups}/>
                        : <ArticleBoostingForm publisherData={props.publisherData} widgetsList={props.widgetsList}
                                               data={readArticleBoostingQuery.data} itemToLoad={itemToLoad} segments={props.segments} contentGroups = {props.contentGroups}/>
            }
        </>
    )
}

export default LoadData;