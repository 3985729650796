import create from 'zustand';

const useSegmentStore = create( (set, get) => ({
    isOpen: false,
    closeDrawer: () => set( _state => ({isOpen: false}) ),
    openDrawer: () => set( _state => ({isOpen: true}) ),

    itemToLoad: null,
    setItemToLoad: (id) => set( _state => ({itemToLoad: id}) ),
    
    actionType: 'create',
    setActionType: (type) => set( _state => ({actionType: type})),

    isAddingNewGlobal: false,
    toggleIsAddingNewGlobal: () => set(state => ({isAddingNewGlobal: !state.isAddingNewGlobal})),

    isDirty: false,
    setIsDirty: (bool) => set( _state => ({isDirty: bool}) )

}));


export default useSegmentStore;



