import React, { Component } from "react";
import { connect } from "react-redux";
import { mediaPlannerActions, tableActions } from "../../../actions";
import ReactTable from "react-table";
import Confirm from "../../components/confirmationBox";
import { FormGroup, Input, Label } from "reactstrap";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};
class ReviewAllocationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pages: null,
      loading: true,
      sorted: [{ id: "id", desc: true }],
    };
    this.notify = {
      accepted: {
        title: "Are you sure you want to accept the selected?",
        confirm: async (data) => {
          this.refs.confirmRef.removeConfirmation();
          data = data.map(function (x) {
            return x * 1;
          });
          await this.props.acceptAds({ ids: data });
          this.refreshData();
        },
        cancel: () => {
          this.refs.confirmRef.removeConfirmation();
        },
        successBtnTxt: "Accept",
        cancelBtnTxt: "Cancel",
      },
      rejected: {
        title: "Are you sure you want to reject the selected?",
        confirm: async (data) => {
          this.refs.confirmRef.removeConfirmation();
          data = data.map(function (x) {
            return x * 1;
          });
          await this.props.rejectAds({ ids: data });
          this.refreshData();
        },
        cancel: () => {
          this.refs.confirmRef.removeConfirmation();
        },
        successBtnTxt: `Reject`,
        cancelBtnTxt: "Cancel",
      },
    };
  }
  componentDidMount(page) {
    this.fetchData();
  }
  fetchData = async () => {
    this.setState({ loading: true });
    await this.props.fetchList("pending");
    this.setState({ loading: false });
  };
  refreshData = () => {
    this.fetchData();
    this.props.fetchList("accepted");
    this.props.fetchList("rejected");
  };
  tableCols = () => {
    return [
      {
        id: "checkbox",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <FormGroup check>
              <Label check className="d-inline">
                <Input
                  type="checkbox"
                  checked={this.props.selected[original.id] === true}
                  onChange={() => this.props.toggleSelection(original.id)}
                />
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          );
        },
        sortable: false,
        width: 45,
      },
      {
        Header: "Ad",
        accessor: "title",
        Cell: ({ original }) => {
          return (
            <div className={"d-flex"}>
              <div className={"cx-table-image-styling"}>
                {original.image ? (
                  <a
                    href={original.image}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={original.image} width={40} alt={original.title} />
                  </a>
                ) : (
                  <i className={"fa fa-image"}></i>
                )}
              </div>
              <div className={"d-flex flex-column"}>
                <div className={"cx-table-name-styling"}>{original.title}</div>
                <div className={"table-actions-icons"}>
                  <a
                    href={"/#"}
                    onClick={(e) => {
                      this.rowActions("accepted", original, e);
                    }}
                  >
                    Accept
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={"/#"}
                    onClick={(e) => {
                      this.rowActions("rejected", original, e);
                    }}
                  >
                    Reject
                  </a>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          );
        },
      },
      // {
      //     Header: "Campaign",
      //     accessor: "campaignName",
      //     Cell: ({ original }) => {
      //         return <span>{original.campaignName}</span>
      //     }
      // },
      // {
      //     Header: "Adset",
      //     accessor: "adsetName",
      //     Cell: ({ original }) => {
      //         return <span>{original.adsetName}</span>
      //     }
      // },
      {
        Header: "Advertiser",
        accessor: "advertiserName",
        Cell: ({ original }) => {
          return <span>{original.advertiserName}</span>;
        },
        width: 200,
      },
      // {
      //     Header: "Ad Title",
      //     accessor: "title",
      //     Cell: ({ original }) => {
      //         return <span>{original.title}</span>
      //     }
      // },
      {
        Header: "Url",
        accessor: "clickrUrl",
        Cell: ({ original }) => {
          return (
            <span className={"cx-truncate-adsurl"}>
              <a
                href={original.clickUrl}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                {original.clickUrl}
              </a>
            </span>
          );
        },
        width: 200,
      },
      {
        Header: "Language",
        accessor: "lang",
        Cell: ({ original }) => {
          return <span>{original.language}</span>;
        },
        width: 150,
      },
    ];
  };
  rowActions = (action = "accepted", obj, event) => {
    if (event) event.preventDefault();
    if (this.refs.confirmRef) {
      return this.refs.confirmRef.confirmOptionActions({
        type: action,
        data: [obj.id],
      });
    }
  };
  render() {
    return (
      <>
        <ReactTable
          noDataText=''
          NoDataComponent={NoData}
          defaultSorting={this.state.sorted}
          sortable={true}
          showPageSizeOptions={false}
          minRows={0}
          data={this.props.tableList}
          resizable={false}
          columns={this.tableCols()}
          defaultPageSize={this.state.pageSize}
          showPaginationBottom={true}
          className="-highlight cx-reactive-table"
          loading={this.state.loading}
        />
        <Confirm ref={"confirmRef"} notify={this.notify} />
      </>
    );
  }
}
// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
  return {
    selected:
      state.tabTable &&
      state.tabTable.tableSelections &&
      state.tabTable.tableSelections.pending
        ? state.tabTable.tableSelections.pending
        : {},
    tableList:
      state.mediaPlanner.pendingReviewsAdsList &&
      state.mediaPlanner.pendingReviewsAdsList
        ? state.mediaPlanner.pendingReviewsAdsList
        : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchList: (type) =>
      dispatch(mediaPlannerActions.fetchAdsReviewsList({ type })),
    acceptAds: (ids) => dispatch(mediaPlannerActions.acceptAdsReview(ids)),
    rejectAds: (ids) => dispatch(mediaPlannerActions.rejectAdsReview(ids)),
    toggleSelection: (id) =>
      dispatch(tableActions.toggleSelection("pending", id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewAllocationTable);
