import {change} from "redux-form";
// import {UPLOAD_FILE} from "../constants/appConstant";
import {UPLOAD_PERCENT} from "../constants/appConstant";
import campaignApi from '../api/campaigns';

const api = new campaignApi();

/*
 * action creators
 * action can be false that is closed or opened that is true
 */

// const uploadFile = (fileInfo) => ({
//     type: UPLOAD_FILE, payload: fileInfo
// });
export const uploadPercent = (percent) => ({
    type: UPLOAD_PERCENT, payload: percent
});

export const uploadImage = (form, fieldName, file, adId) => {
    return (dispatch) => {
        return api.imageUpload({mimeType: file.type, adId: adId}, file, (percent)=>{
            dispatch(uploadPercent(percent));
        })
            .then(response => {
                let res = null;
                if(response && response.data){
                    res = response.data.url;
                    // dispatch(uploadFile({name: `${form}-${fieldName}`, value: res}));
                    dispatch(uploadPercent(undefined));
                    dispatch(change(form, fieldName, res));
                }
            })
            .catch(error => {
                throw(error);
            });
    }
};

export const removeUpload = (form, fieldName) => {
    return (dispatch) => {
        dispatch(change(form, fieldName, null));
    }
}
