import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import { drawerActions, mediaPlannerActions } from "actions";
import CxLoader from "../../components/cxLoader";
import NotificationAlert from "react-notification-alert";
import { Button, CircularProgress, makeStyles, Modal } from "@material-ui/core";
import MediaPlanner from "api/mediaPlanner";
import { EditRounded } from "@material-ui/icons";
import "../../../assets/css/new_css.css";
import { FormGroup, Input, Label } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/styles";
import {
  MediaPlannerReviewTargetingStyledModal,
  MediaPlannerReviewTargetingStyledPopup,
} from "../../../style/media_planner.style";
var seperator = "-";

class AdsetAllocation extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);

    this.handleSubmit = this.props.handleSubmit;
    console.log(this.props);
    this.state = {
      msgAnimate: false,
      msg: null,
      loader: false,
      data: [],
    };
  }

  componentDidMount() {
    // by default set drawer to closed
    if (navigator.platform.indexOf("Win") > -1) {
      // ps = new PerfectScrollbar(this.refs.elementRef);
    } else {
      this.refs.elementRef.style.overflow = "auto";
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.errorUpdate &&
      JSON.stringify(this.props.errorUpdate) !=
        JSON.stringify(prevProps.errorUpdate)
    ) {
      this.alertMsg(this.props.errorUpdate.message);
      this.printMessage(
        false,
        "Error! Allocations Not updated.",
        "error",
        () => {
          this.props.eventUpdateError(undefined);
        }
      );
    }
  }

  alertMsg = (msg, icon = "tim-icons icon-simple-remove", type = "danger") => {
    this.refs.notificationAlert.notificationAlert({
      place: "tc",
      message: msg,
      type: type,
      icon: icon,
      autoDismiss: 7,
    });
  };

  printAllocationHeader = () => {
    const headerItems = ["Publisher", "Allocation", "CPC", "Revenue Share"];
    return (
      <div className={"cx-table-like-header cx-table-like-container"}>
        {headerItems.map((headerItem, i) => (
          <div key={headerItem + i} className={"cx-table-like-item"}>
            {headerItem}
          </div>
        ))}
      </div>
    );
  };

  printElt = (key, item) => {
    const orgName = item.publisher.split(".").join("|");
    switch (key) {
      default:
        return (
          <Field
            type={"hidden"}
            name={`${orgName}${seperator}${key}`}
            component={Input}
          />
        );
      case "publisher":
        return item[key];
      case "budgetPercentage":
        // item[key]
        // name =  yawmiyati.com-percentage
        return (
          <Field
            type={"number"}
            icon={"fa fa-percent"}
            showMsg={true}
            className={"cx-edit-campaign-input"}
            name={`${orgName}${seperator}${key}`}
            component={Input}
          />
        );
      case "cpc":
        return (
          <Field
            type={"number"}
            icon={"fa fa-dollar-sign"}
            value={item["cpc"]}
            showMsg={true}
            className={"cx-edit-campaign-input"}
            name={`${orgName}${seperator}${key}`}
            component={Input}
          />
        );
      case "revenueSharePercentage":
        return (
          <Field
            type={"number"}
            icon={"fa fa-percent"}
            showMsg={true}
            className={"cx-edit-campaign-input"}
            name={`${orgName}${seperator}${key}`}
            component={Input}
          />
        );
    }
  };

  PrintAllocationBody = ({ header }) => {
    const bodykeys = [
      "publisher",
      "budgetPercentage",
      "cpc",
      "revenueSharePercentage",
    ];

    const [data, setData] = useState([...this.props.item.allocations]);
    const [model, setModel] = useState([]);

    return (
      <>
        <div style={{ display: "flex" }}>
          {model}
          <Button
            style={{
              width: 100,
              color: "white",
              backgroundColor: "#4B4C6E",
              marginLeft: 10,
              height: 30,
              display: "flex",
              alignItems: "center",
              textTransform: "none",
            }}
            onClick={() => {
              setModel((prev) => [
                <CreateAllocationBody
                  adsetId={this.props.item.id}
                  onAdd={(e) => {
                    const list = [];
                    const f = data.map((res) => res.publisherId);

                    e.map((res) => {
                      if (f.includes(res)) {
                        list.push(data[f]);
                      } else {
                        list.push(res);
                      }
                      return res;
                    });

                    setData(list);
                  }}
                  data={data}
                  onClose={() => setModel([])}
                />,
              ]);
            }}
            startIcon={<EditRounded style={{ fontSize: 20 }} />}
          >
            Edit
          </Button>
        </div>
        {header}
        {data.map((bItem, bi) => {
          return (
            <>
              <div
                style={{ borderLeft: "none" }}
                key={"bodyItem" + bi}
                className={"cx-table-like-body cx-table-like-container"}
              >
                {bodykeys.map((key, i) => {
                  return (
                    <div style={{ borderLeft: "none" }} key={key + i}>
                      {this.printElt(key, bItem)}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
        <div style={{ height: 30 }}></div>
      </>
    );
  };

  printMessage = (loader, msg, type = "success", callback = () => {}) => {
    const ref = this;
    if (type == "error") msg = <span style={{ color: "#F0555D" }}>{msg}</span>;
    // Update user with the message after save
    this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
      setTimeout(() => {
        ref.setState({ msgAnimate: false }, () => {
          setTimeout(() => {
            // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
            ref.setState({ msg: null });
            callback();
          }, 200);
        });
      }, 2000);
    });
  };

  cancelButton = (event,onCancel) => {

    onCancel()
    event.preventDefault();
    this.props.reset();

    this.props.toggleDrawer(false);
  };

  saveButton = (event) => {
    event.preventDefault();
    this.handleSubmit(this.onSave)();
  };

  mapVals = (formVals) => {
    const toSendkeys = [
      "revenueSharePercentage",
      "budgetPercentage",
      "publisherId",
      "cpc",
      "id",
      "adsetId",
    ];
    let publisherGather = {};
    let res = [];
    let adsetId = "";
    for (let key in formVals) {
      for (let i in toSendkeys) {
        let keyIndex = key.indexOf(seperator + toSendkeys[i]);
        if (keyIndex > -1) {
          //if belong
          let publisherName = key.substr(0, keyIndex - 1);
          if (!publisherGather[publisherName]) {
            publisherGather[publisherName] = {
              [toSendkeys[i]]: formVals[key] * 1,
            };
          } else {
            publisherGather[publisherName][toSendkeys[i]] = formVals[key] * 1;
          }
          if (!adsetId && toSendkeys[i] == "adsetId") {
            adsetId = formVals[key] * 1;
          }
        }
      }
    }
    for (let p in publisherGather) {
      res.push(publisherGather[p]);
    }
    return { allocations: res, adsetId };
  };

  //todo: take addset id  and check all

  onSave = async (formVals) => {
    const mapped = this.mapVals({ ...formVals });
    mapped.adsetId = this.props.items.id;

    var sum = 0;
    const data = this.state.data.map((res) => {
      sum += res.budgetPercentage;
      return res;
    });
    console.log(data);
    mapped.allocations = data;
    if (sum < 99 || sum > 100) {
      this.printMessage(true, "Sum of Allocation must be around 100%");
      return this.setState({ loader: false });
    }
    this.setState({ loader: true });

    await this.props.updateAllocations(mapped);

    this.props.refetchList("allocated");
    this.props.refetchList("not_allocated");

    await this.props.fetchDrawerData(mapped.adsetId);

    this.printMessage(false, "Changes Saved!");
  };

  render() {
    return (
      <Form
        className={"cx-edit-drawer-form"}
        onSubmit={() => false}
        style={{ wdith: "54vw", height: "100%" }}
      >
        <div className={"cx-notification-alert"}>
          <NotificationAlert ref="notificationAlert" />
        </div>
        {this.state.loader ? <CxLoader /> : ""}
        <div
          className={"cx-campaign-adset-allocation"}
          ref={"elementRef"}
        ></div>
        {this.props.item ? (
          <DenseTable
              onCallback={()=>{

              }}
            data={this.props.item}
            onChange={(e) => {
              this.setState((prev) => ({
                ...prev,
                data: e,
              }));
            }}
          />
        ) : (
          <>
            <CxLoader />
          </>
        )}{" "}
        <div className={"cx-action-buttons"}>
          <button
            className={"btn cx-cancel-btn pull-left"}
            onClick={(e) => {
              this.cancelButton(e,()=>{
              });
            }}
          >
            Cancel
          </button>
          <button
            className={"btn cx-discard-btn pull-right"}
            onClick={(e) => {
              // console.log(this.props);
              // // // console.log(this.props);
              this.saveButton(e);
            }}
          >
            Save
          </button>
          <span
            className={`cx-form-msgs pull-right ${
              this.state.msgAnimate ? "show" : ""
            }`}
          >
            {this.state.msg}
          </span>
        </div>
      </Form>
    );
  }
}

function getInitialValues(item) {
  let vals = {};
  const allocations =
    item && item.allocations && item.allocations.length ? item.allocations : [];
  for (let i in allocations) {
    const orgDetails = allocations[i];
    const getKeys = Object.keys(orgDetails); // [org, cpc, percent]
    for (let j in getKeys) {
      if (getKeys[j] !== "publisher") {
        vals[
          `${orgDetails.publisher.split(".").join("|")}${seperator}${
            getKeys[j]
          }`
        ] = orgDetails[getKeys[j]] ? orgDetails[getKeys[j]] : 0;
      }
    }
  }
  return vals;
}

const mapStateToProps = (state) => {
  // get the props needed for this component from the state.
  const item =
    (state.mediaPlanner && state.mediaPlanner.allocationAdsetItem) || null;
  const initialValues = getInitialValues(item);
  return {
    errorUpdate:
      (state.mediaPlanner && state.mediaPlanner.allocationAdsetUpdateErr) ||
      null,
    item,
    initialValues,
  };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    refetchList: (type) =>
      dispatch(mediaPlannerActions.fetchAdsetAllocationList({ type })),
    toggleDrawer: (action) => dispatch(drawerActions.setDrawerVisible(action)),
    updateAllocations: (data) =>
      dispatch(mediaPlannerActions.updateAdsetAllocations(data)),
    eventUpdateError: (data) =>
      dispatch(mediaPlannerActions.updateErrAllocationEvent(data)),
    fetchDrawerData: (adsetId) =>
      dispatch(mediaPlannerActions.fetchAdsetAllocationItem(adsetId * 1)),
  };
};

AdsetAllocation = reduxForm({
  form: "AdsetAllocation",
})(AdsetAllocation);

AdsetAllocation = connect(mapStateToProps, mapDispatchToProps)(AdsetAllocation);

export default AdsetAllocation;
// Modal for Add Delete allocation
function CreateAllocationBody({ onClose, onAdd, data, adsetId }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [select, setSelect] = useState(data);
  const classes = useStyles();

  useEffect(() => {
    const f = new MediaPlanner();
    const json = JSON.parse(localStorage.getItem("organization"));
    f.getPublishersList(json.id).then((res) => {
      setLoading(false);

      setList(res.data.data);
    });
  }, []);

  return (
    <Modal
      open={true}
      style={{
        zIndex: "99999",
        flexDirection: "column",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MediaPlannerReviewTargetingStyledPopup>
        {loading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <div
            className={"section"}
            id="section"
            style={{
              padding: 0,
            }}
          >
            <h3 id={"title"}>Edit</h3>

            <div
              className={"section"}
              style={{
                margin: 0,
                overflowX: "hidden",
              }}
            >
              <MediaPlannerReviewTargetingStyledModal>
                <TableContainer
                  id="table"
                  component={Paper}
                  style={{ backgroundColor: "#32334d" }}
                  className={"section"}
                >
                  <Table
                    className={classes.table}
                    size="medium"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            color: "#9899a6",
                            borderColor: "#3f3f4e",
                            borderRight: "solid 1px #1e1e2f",
                          }}
                        >
                          <FormGroup check>
                            <Label check className="d-inline">
                              <Input
                                className={"form-check-input"}
                                type="checkbox"
                                checked={select.length === list.length}
                                onChange={() => {
                                  if (select.length === list.length) {
                                    return setSelect([]);
                                  }

                                  setSelect((prev) =>
                                    list.map((res) => ({
                                      budgetPercentage: 0,
                                      publisherId: res.id,
                                      revenueSharePercentage:
                                        res.revenueSharePercentage,
                                      cpc: 0.1,
                                      publisher: res.domain,

                                      adsetId: adsetId,
                                    }))
                                  );
                                }}
                              />
                              <span className="form-check-sign" />
                            </Label>
                          </FormGroup>
                        </TableCell>{" "}
                        <TableCell
                          style={{
                            color: "#9899a6",
                            borderColor: "#3f3f4e",
                            borderRight: "solid 1px #1e1e2f",
                          }}
                        >
                          <h4> Domain</h4>
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#9899a6",
                            borderColor: "#3f3f4e",
                            borderRight: "solid 1px #1e1e2f",
                          }}
                        >
                          <h4> Floor Cpc</h4>
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#9899a6",
                            borderColor: "#3f3f4e",
                            borderRight: "solid 1px #1e1e2f",
                          }}
                        >
                          <h4> Revenue Share</h4>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list.sort((a,b)=>a.domain.trim().localeCompare(b.domain.trim())).map((res) => (
                        <TableRow key={res.id}>
                          {" "}
                          <TableCell
                            style={{
                              color: "#9899a6",
                              fontSize: 16,
                              borderColor: "#3f3f4e",
                              borderRight: "solid 1px #1e1e2f",
                            }}
                          >
                            <FormGroup check>
                              <Label check className="d-inline">
                                <Input
                                  className={"form-check-input"}
                                  type="checkbox"
                                  checked={select
                                    .map((res) => res.publisherId)
                                    .includes(res.id)}
                                  onChange={() => {
                                    if (
                                      select
                                        .map((res) => res.publisherId)
                                        .includes(res.id)
                                    ) {
                                      setSelect((prev) =>
                                        prev.filter(
                                          (f) => f.publisherId !== res.id
                                        )
                                      );
                                    } else {
                                      setSelect((prev) => [
                                        ...prev,
                                        {
                                          budgetPercentage: 0,
                                          publisherId: res.id,
                                          revenueSharePercentage:
                                            res.revenueSharePercentage,
                                          cpc: 0.1,
                                          publisher: res.domain,

                                          adsetId: adsetId,
                                        },
                                      ]);
                                    }
                                  }}
                                />
                                <span className="form-check-sign" />
                              </Label>
                            </FormGroup>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#9899a6",
                              fontSize: 16,
                              borderColor: "#3f3f4e",
                              borderRight: "solid 1px #1e1e2f",
                            }}
                            component="th"
                            scope="row"
                          >
                            <h3
                              style={{ fontSize: 14, margin: "0px 0 0 10px" }}
                            >
                              {res.domain}
                            </h3>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#9899a6",
                              fontSize: 16,
                              borderColor: "#3f3f4e",
                              borderRight: "solid 1px #1e1e2f",
                            }}
                            component="th"
                            scope="row"
                          >
                            <h3
                              style={{ fontSize: 14, margin: "0px 0 0 10px" }}
                            >
                              {res.floorCpc}
                            </h3>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#9899a6",
                              fontSize: 16,
                              borderColor: "#3f3f4e",
                              borderRight: "solid 1px #1e1e2f",
                            }}
                            component="th"
                            scope="row"
                          >
                            <h3
                              style={{ fontSize: 14, margin: "0px 0 0 10px" }}
                            >
                              {res.revenueSharePercentage}
                            </h3>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MediaPlannerReviewTargetingStyledModal>
            </div>

            <div id="action_bottom">
              <Button
                style={{
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  fontFamily: '"Poppins", sans-serif',
                  backgroundColor: "#4090B4",
                  color: "white",
                  textTransform: "none",
                  height: 30,
                  padding: "8px 10px",
                }}
                onClick={() => {
                  onAdd(select);
                  onClose();
                }}
                variant="contained"
                disableElevation
              >
                Save
              </Button>
              <div style={{ width: "10px" }}></div>
              <Button
                aria-valuetext={""}
                style={{
                  fontSize: "0.875rem",
                  fontFamily: `"Poppins", sans-serif`,
                  fontWeight: 500,
                  height: 30,
                  color: "white",
                  padding: "8px 10px",
                  backgroundColor: "#4B4C6E",
                  textTransform: "none",
                }}
                onClick={onClose}
                variant="contained"
                disableElevation
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </MediaPlannerReviewTargetingStyledPopup>
    </Modal>
  );
}
//style
const useStyles = makeStyles({
  backgroundColor: "#32334d",
  table: {
    color: "white",
    backgroundColor: "#32334d",
    minWidth: 100,
    padding: 10,
  },
  row: {
    width: "100%",
    color: "white",
    backgroundColor: "transparent",
    borderRadius: "6px",
    border: " 1px solid #4B4C6E",
    padding: "4px",
    outline: "none",
  },
});
// table for allocation
export function DenseTable({ data, onChange, props }) {
  const classes = useStyles();

  const [allocationsData, setAllocationsData] = useState(data.allocations);
  const [model, setModel] = useState([]);
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    onChange(allocationsData);
  }, [update]);
  useEffect(()=>{
    setAllocationsData(data.allocations)
  },[data.allocations])

  return (
    <>
      <div style={{ display: "flex" }}>
        {model}
        <div
          style={{
            margin: "-3px",
            borderBottom: "1px solid #1e1e2f",
            width: "100%",
          }}
        >
          {" "}
          <BootstrapButton
            style={{ backgroundColor: "#4090B4" }}
            onClick={() => {
              setModel(() => [
                <CreateAllocationBody
                  onAdd={(e) => {
                    setAllocationsData(e);
                    setUpdate(Date.now());
                  }}
                  data={allocationsData}
                  onClose={() => setModel([])}
                />,
              ]);
            }}
            startIcon={
              <div>
                <i className="fa fa-pencil-alt" style={{ fontSize: "70%" }}></i>
              </div>
            }
          >
            Edit
          </BootstrapButton>
          <BootstrapButton
            onClick={() => {
              const value = Math.trunc(100 * 100 / allocationsData.length) / 100;
              allocationsData.map(
                (res) => (res.budgetPercentage = value)
              );
              setUpdate(Date.now());
              setAllocationsData(allocationsData);
            }}
            startIcon={
              <div>
                <i className="fa fa-clone" style={{ fontSize: "60%" }}></i>
              </div>
            }
          >
            Split Evenly
          </BootstrapButton>
          <BootstrapButton
            onClick={() => {
              allocationsData.map((res) => (res.budgetPercentage = 0));
              setUpdate(Date.now());
              setAllocationsData(allocationsData);
            }}
            startIcon={
              <div>
                <i className="fa fa-trash" style={{ fontSize: "60%" }}></i>
              </div>
            }
          >
            Clear
          </BootstrapButton>
        </div>
      </div>
      <MediaPlannerReviewTargetingStyledModal>
        <TableContainer
          id="table"
          component={Paper}
          style={{ backgroundColor: "#32334d" }}
          className={"section"}
        >
          <Table
            className={classes.table}
            size="medium"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    color: "#9899a6",
                    borderColor: "#3f3f4e",
                    borderRight: "solid 1px #1e1e2f",
                  }}
                >
                  <h4> Publisher</h4>
                </TableCell>
                <TableCell
                  style={{
                    color: "#9899a6",
                    borderColor: "#3f3f4e",
                    borderRight: "solid 1px #1e1e2f",
                  }}
                >
                  <h4> Allocation</h4>
                </TableCell>
                <TableCell
                  style={{
                    color: "#9899a6",
                    borderColor: "#3f3f4e",
                    borderRight: "solid 1px #1e1e2f",
                  }}
                >
                  <h4> CPC</h4>
                </TableCell>
                <TableCell
                  style={{
                    color: "#9899a6",
                    borderColor: "#3f3f4e",
                    borderRight: "solid 1px #1e1e2f",
                  }}
                >
                  <h4> Revenue Share</h4>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allocationsData.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell
                    style={{
                      color: "#9899a6",
                      fontSize: 16,
                      borderColor: "#3f3f4e",
                      borderRight: "solid 1px #1e1e2f",
                    }}
                    component="th"
                    scope="row"
                  >
                    {row.publisher}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#9899a6",
                      fontSize: 16,
                      borderColor: "#3f3f4e",
                      borderRight: "solid 1px #1e1e2f",
                    }}
                    align="right"
                  >
                    <input
                      type="number"
                      name="budgetPercentage"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        allocationsData[i].budgetPercentage = parseFloat(
                          e.target.value
                        );
                        setUpdate(Date.now());

                        setAllocationsData(allocationsData);
                      }}
                      value={row.budgetPercentage}
                      className={classes.row}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#9899a6",
                      fontSize: 16,
                      borderColor: "#3f3f4e",
                      borderRight: "solid 1px #1e1e2f",
                    }}
                    align="right"
                  >
                    <input
                      name="cpc"
                      type="number"
                      min={0}
                      onChange={(e) => {
                        allocationsData[i].cpc = parseFloat(e.target.value);
                        setUpdate(Date.now());
                        setAllocationsData(allocationsData);
                      }}
                      value={row.cpc}
                      className={classes.row}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#9899a6",
                      fontSize: 16,
                      borderColor: "#3f3f4e",
                      borderRight: "solid 1px #1e1e2f",
                    }}
                    align="right"
                  >
                    <input
                      name="revenueSharePercentage"
                      type="number"
                      min={0}
                      max={100}
                      onChange={(e) => {
                        allocationsData[i].revenueSharePercentage = parseFloat(
                          e.target.value
                        );
                        setUpdate(Date.now());
                        setAllocationsData(allocationsData);
                      }}
                      value={row.revenueSharePercentage}
                      className={classes.row}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MediaPlannerReviewTargetingStyledModal>
    </>
  );
}

//# Header Button Style
const BootstrapButton = withStyles({
  root: {
    margin: "8px 0px 8px 10px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "0.875rem",
    padding: "1px 14px",
    color: "white",
    border: "1px solid",
    lineHeight: 1.5,
    borderRadius: 6,
    backgroundColor: "#4B4C6E",
    borderColor: "transparent",
    fontFamily: '"Poppins", sans-serif',
    "&:hover": {
      color: "rgb(152, 153, 166)",
      // borderColor: "#0062cc",
      boxShadow: "none",
      outline: "none",
      backgroundColor: "#4B4C6E",
    },
    "&:active": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);
