/* Helper Class
* To make requesting countries and regions more flexible
* */
import auth from "./auth";
import apiUrl from "../helpers/global";

class restCountries {
    constructor(props) {
        this.auth = new auth();
    }

    getCountries = async () => {
        return this.auth.request.post(apiUrl + '/static', { data: JSON.stringify({ name: "countries" }) });
    }

    getLanguages = async () => {
        return this.auth.request.post(apiUrl + '/static', { data: JSON.stringify({ name: "languages" }) });
    }

    getRegionsCountries = async () => {
        return this.auth.request.post(apiUrl + '/static', { data: JSON.stringify({ name: "regions" }) });
    }
}

export default new restCountries();
