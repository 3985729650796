import React from "react";
import { Col, Row } from "reactstrap";
import ActionBtns from "./actionBtns";
import PopupNew from "../../components/popup_new";
import usePublisherStore from "./PublisherStore";
import PublisherForm from "./popupForm";
import PublisherTable from "./tableList";


const PublisherList = () => {

    const isPopupOpen = usePublisherStore((state) => state.isPopupOpen);
    const togglePopup = usePublisherStore((state) => state.togglePopup);

    return (
        <>
        <PopupNew title={"Publisher"} width={'60%'} isPopupOpen={isPopupOpen} togglePopup={togglePopup}>
            <PublisherForm />                
        </PopupNew>

        <div className="content">
            <div>
                <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; Publisher</h4>
            </div>
            <div className={"page-reactive-table"}>                    
                <Row className={"cx-action-buttons mx-0"}>
                    <Col className={"px-0"}>
                        <ActionBtns/>
                    </Col>
                </Row>
                <Row className={"mx-0"}>
                    <Col className={"px-0 cx-admediaplanner-review"}>
                        <div id={'cx-user-config'}>
                            <PublisherTable />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    );
}


export default PublisherList;