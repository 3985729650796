import {
    FETCH_REPORT_PERFORMANCE,
    FETCH_REPORT_PERFORMANCE_TOTALS,
    FETCH_REPORT_LOCATION,
    FETCH_REPORT_DEVICES
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_REPORT_PERFORMANCE:
            return { ...state, performanceData: action.payload };
        case FETCH_REPORT_LOCATION:
            return { ...state, locationData: action.payload };
            case FETCH_REPORT_DEVICES:
            return { ...state, devicesData: action.payload };
        case FETCH_REPORT_PERFORMANCE_TOTALS:
            return { ...state, performanceTotals: action.payload };
        default:
            return state;
    }
}