import React, {useState} from 'react';
import useSegmentStore from '../segmentStore';
import { CXConfirm } from '../../../CXComponents';
import { useMutation, useQueryClient } from "react-query";
import {deleteSegment} from '../segmentAPI';

const SegmentCard = ({title, nbOfDimenstions, status, userCount, id}) => {
        
    const [isDeleteSegmentOpen, setIsDeleteSegmentOpen] = useState(false);
    const setItemToLoad = useSegmentStore((state)=>state.setItemToLoad);
    const openDrawer = useSegmentStore((state)=>state.openDrawer);
    const setActionType = useSegmentStore((state)=>state.setActionType);

    const deleteSegmentQuery = useMutation(()=>{
        return deleteSegment(id);
    })

    const qc = useQueryClient();

    const openSegmentForm = (e) => {
        setActionType('edit');
        openDrawer();
        setItemToLoad(id);
    }

    const confirmSegmentDelete = () => {
        setIsDeleteSegmentOpen(true);
    }

    return (
        <>
        <div className="segment-card-container">
            <div className="segment-card-container__topRight" onClick={confirmSegmentDelete}>
                <i title='Status' className={`segment-card-container__topRight__status ${status&&('cx-status-'+status)}`}></i>
                <i className={`segment-card-container__topRight__delete-btn fa fa-trash`}></i>
            </div>
            <div onClick={openSegmentForm}>
                <div className="segment-card-container__title">
                    {title}
                </div>
                <div className="segment-card-container__dimensions">
                    {nbOfDimenstions + (nbOfDimenstions === 1 ? " dimension" : " dimensions")}
                </div>
                <div className="segment-card-container__users">
                    % <span>{userCount}</span>
                </div>
                <div className="segment-card-container__action">
                    Click to view
                </div>
            </div>
        </div>
        {
            isDeleteSegmentOpen && 
            <CXConfirm
                {...{
                    title: "Delete Segment",
                    subtitle: "Are you sure you want to permanently delete this segment?",
                    confirmAction: async ()=>{
                        await deleteSegmentQuery.mutateAsync([id]);
                        setIsDeleteSegmentOpen(false);
                        await qc.invalidateQueries(["segments-list"]);
                    },
                    cancelAction: ()=>{
                        setIsDeleteSegmentOpen(false);
                    },
                    loadingState: deleteSegmentQuery.isLoading
                }}
            />
        }
        </>
    );
}

export default SegmentCard;
