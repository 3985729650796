import { SET_FILTER } from "../constants/appConstant";

/*
* action creators
* action can be false that is closed or opened that is true
*/
export const initFilterDate = () => {
    let today = new Date();
    today = today.getFullYear() + '/' + (today.getMonth()+1) + '/' + today.getDate();
    let sixDaysAgo = new Date();
    sixDaysAgo = new Date(sixDaysAgo.setDate(sixDaysAgo.getDate()-6));
    sixDaysAgo = sixDaysAgo.getFullYear() + '/' + (sixDaysAgo.getMonth()+1) + '/' + sixDaysAgo.getDate();
    return (dispatch) => {
        dispatch(setDateFilter({start: sixDaysAgo, end: today}));
    };
}

export const setDateFilter = (date) => {
    console.log('setDateFilter')
    function replaceDash (date) {
        if(date.includes('-')){
            return date.split('-').join('/');
        }
        return date;
    }
    return { type: SET_FILTER, payload: {start: replaceDash(date.start), end: replaceDash(date.end)} };
}
