import { filter } from 'lodash';
import { set } from 'lodash';
import create from 'zustand';

const useContentGroupingStore = create( (set, get) => ({
    isOpen: false,
    closeDrawer: () => set( _state => ({isOpen: false}) ),
    openDrawer: () => set( _state => ({isOpen: true}) ),

    itemToLoad: null,
    setItemToLoad: (id) => set( _state => ({itemToLoad: id}) ),
    
    actionType: 'create',
    setActionType: (type) => set( _state => ({actionType: type})),

    isDirty: false,
    setIsDirty: (bool) => set( _state => ({isDirty: bool}) ),

    selectedKeywords: [],
    setSelectedKeywords: (list) => {console.log(list); return set( _state => ({selectedKeywords: list}))},
    removeKeyword: (kword) => set(state => ({selectedKeywords : state.selectedKeywords.filter(w=>!(w.label === kword.label && w.type === kword.type))})),
    addKeyword: (kword) => set(state => ({selectedKeywords : [...state.selectedKeywords, kword]})),

    filter: {
        domainFilter: {},
        classFilter: {},
        textFilter: ""
    },
    setFilter: filter => set(state => ({filter: {...state.filter, ...filter}})),


}));


export default useContentGroupingStore;



