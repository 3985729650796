import React, {useState} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useContentGroupingStore from './contentGroupingStore';
import {readContentG, createContentG, updateContentG, refetchList} from "./contentGroupingAPI";
import CxLoader from '../components/cxLoader';
import {useForm, useWatch} from 'react-hook-form'
import CXPillList from './components/CXPillList';
import KeywordsPool from './components/KeywordsPool';

const ContentGroupingForm = ({data, itemToLoad}) => {
    const qc = useQueryClient();
    
    const actionType = useContentGroupingStore(state=>state.actionType);
    const closeDrawer = useContentGroupingStore(state=>state.closeDrawer);
    const setSelectedKeywords = useContentGroupingStore(state=>state.setSelectedKeywords);
    if(actionType === 'create') setSelectedKeywords([]);

    const {register, control, handleSubmit, setValue, getValues, formState: {errors}} = useForm({
        defaultValues: data
    });

    const create = useMutation((data)=>{
        return createContentG(data);
    })
    const update = useMutation((data)=>{
        return updateContentG(data);
    })

    const onSubmit = async (formValues) => {
        const mapped = {...formValues};
        //remove filters state for now, dont store it one the server
        delete mapped.textFilter;
        delete mapped.domainFilter;
        delete mapped.classFilter;

        //for now
        mapped.coveragePercentage = 0;

        if(actionType === "edit"){
            await update.mutateAsync(mapped);
            qc.invalidateQueries(['content-grouping-read', itemToLoad])
        }
        else await create.mutateAsync(mapped);
        closeDrawer();
        refetchList(qc);
    }

    
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="segment-form__segment-name">
                    <label htmlFor="title">Title: </label> &nbsp;&nbsp;
                    <input required {...register("title")}/>
                </div>

                <div className="selected-keywords-area">
                    <div className="selected-keywords-title">Selected Keywords:</div>
                    <div className="flex">
                        <div className="selected-keywords-container">
                            <CXPillList 
                                name="content"
                                control={control}
                                displayProp="label"
                                setValue={setValue}
                            /> 
                        </div>
                        <button type="submit" className='contentG-submitBtn'>{(create.isLoading || update.isLoading) ? <CxLoader inPlace={true}/> : "Save"}</button>
                    </div>
                    
                </div>
                <div className="pool-area">
                    <div className="pool-title">All Keywords:</div>
                    <KeywordsPool
                        {...{
                            control,
                            register,
                            getValues,
                            setValue
                        }}
                    />
                </div>
            </form>
        </div>
    );
}


const LoadData = () => {

    const itemToLoad = useContentGroupingStore(state=>state.itemToLoad);
    const actionType = useContentGroupingStore(state=>state.actionType);
    const readContentGQuery = useQuery(['content-grouping-read', itemToLoad], ()=>{
        if(actionType === "edit") return readContentG(itemToLoad);
    }, {refetchOnWindowFocus: false});


    return (
        <>
            {
                readContentGQuery.isLoading ? <CxLoader minHeight="80vh"/> : 
                actionType === "edit"
                ? <ContentGroupingForm data={readContentGQuery.data} itemToLoad={itemToLoad}/>
                : <ContentGroupingForm/>
            }
        </>
    )

}

export default LoadData;