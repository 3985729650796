import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import RouterComponent from "./App";
import mainHelper from './helpers/main';
import ReactGA from 'react-ga';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import "react-notification-alert/dist/animate.css";

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const helper = new mainHelper();
const middleWares = [thunk];

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middleWares)
    )
);

ReactGA.initialize('UA-147710441-1');
ReactGA.set({ userId: helper.getUserEmail() });

const hist = createBrowserHistory();
hist.listen(function (location) {
  //ReactGA.pageview( location.pathname, null, helper.getOrganization() + " | " + helper.getInfo('email'));
});
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Router history={hist}>
        <RouterComponent />
      </Router>
    </Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById("root")
);

