import React from "react";
import Select from "react-select";
import { Field, formValues } from "redux-form";
import { FormGroup, Label, Input as InputStrap } from "reactstrap";
import ReactDatetime from "react-datetime";
import Switch from "react-bootstrap-switch";
import UploaderC from "./uploadElt";
import organization from "../../api/organization";

// we are calling formValues which returns a function that we are immediatly calling by passing our component(also function) to it.
export const Input = formValues("isTest")(
  ({
    input,
    isTest,
    label,
    disabled,
    className,
    meta: { touched, error, warning },
    icon,
    placeholder,
    type,
    showMsg = false,
  }) => {
    if (input.name == "testData" && !isTest) return <></>;
    return (
      <div className={`cx-input-container ${className ? className : ""}`}>
        {!label ? (
          ""
        ) : (
          <div className={"cx-input-label"}>
            {" "}
            <label>{label}</label>{" "}
          </div>
        )}
        <div className={"cx-input-icon-container"}>
          <i className={icon ? icon : ""}></i>
          <input
            disabled={disabled}
            className={`cx-input-icon-input ${icon ? "with-icon" : ""} ${
              touched && ((error && "error") || (warning && "warning") || "")
            }`}
            placeholder={placeholder ? placeholder : ""}
            autoComplete="off"
            type={type ? type : "text"}
            {...input}
          />
          {touched &&
            ((error && (
              <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
                {error}
              </div>
            )) ||
              (warning && (
                <div
                  className={`warning input-warning ${
                    showMsg ? "show-msg" : ""
                  }`}
                >
                  {warning}
                </div>
              )))}
        </div>
      </div>
    );
  }
);

export const Textarea = formValues("isTest")(
  ({
    input,
    label,
    disabled,
    className,
    meta: { touched, error, warning },
    icon,
    placeholder,
    type,
    showMsg = false,
  }) => {
    return (
      <div className={`cx-input-container ${className ? className : ""}`}>
        {!label ? (
          ""
        ) : (
          <div className={"cx-input-label"}>
            {" "}
            <label>{label}</label>{" "}
          </div>
        )}
        <div className={"cx-input-icon-container"}>
          <i className={icon ? icon : ""}></i>
          <textarea
            cols={200}
            rows={4}
            disabled={disabled}
            className={`cx-input-icon-input ${icon ? "with-icon" : ""} ${
              touched && ((error && "error") || (warning && "warning") || "")
            }`}
            placeholder={placeholder ? placeholder : ""}
            autoComplete="off"
            type={type ? type : "text"}
            {...input}
          ></textarea>
          {touched &&
            ((error && (
              <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
                {error}
              </div>
            )) ||
              (warning && (
                <div
                  className={`warning input-warning ${
                    showMsg ? "show-msg" : ""
                  }`}
                >
                  {warning}
                </div>
              )))}
        </div>
      </div>
    );
  }
);

export const SchemaFieldSelect = ({
  input,
  meta: { touched, error, warning },
  className,
  options,
  showMsg,
}) => {
  if (!input.value) {
    //setting the default to 'Trending' (didn't find the built-in way to do that as it changed a lot over versions but it works fine)
    input.onChange({ label: "Trending", value: "Trending" });
  }
  return (
    <div className={`cx-input-container cx-select-container ${className}`}>
      <div className={"cx-input-icon-container"}>
        <label>Logic</label>
        <Select
          className={`cx-input-icon-container-select react-select info ${
            touched && ((error && "error") || (warning && "warning") || "")
          }`}
          classNamePrefix={"react-select"}
          options={options}
          {...input}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur(input.value)}
        />

        {touched &&
          ((error && (
            <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
              {error}
            </div>
          )) ||
            (warning && (
              <div
                className={`warning input-warning ${showMsg ? "show-msg" : ""}`}
              >
                {warning}
              </div>
            )))}
      </div>
    </div>
  );
};

export const SchemaFieldCategory = ({ input }) => {
  if (!input.value) {
    //setting the default to 'default' (didn't find the built-in way to do that as it changed a lot over versions but it works fine)
    input.onChange("default");
  }
  return (
    <div className={"cx-input-icon-container"}>
      <label>Category</label>
      <input
        className={`cx-input-icon-input`}
        placeholder="Category"
        autoComplete="off"
        type="text"
        {...input}
      />
    </div>
  );
};

export const SchemaFields = ({
  fields,
  label,
  meta: { submitFailed, error },
  options,
  className,
}) => {
  const emptySchema = () => {
    fields.removeAll();
  };
  return (
    <div className={`cx-input-container ${className ? className : ""}`}>
      {!label ? (
        ""
      ) : (
        <div className={"cx-input-label"}>
          {" "}
          <label>{label}</label>{" "}
        </div>
      )}
      <ul className="cx-popup-widget-form-schemaFieldsList cx-input-icon-container">
        <li>
          <button
            title="Add Schema Field"
            className={"cx-popup-widget-form-addSchemaFieldBtn"}
            type="button"
            onClick={() => fields.push()}
          >
            +
          </button>
        </li>
        {fields.length ? (
          <li className="cx-popup-widget-form-numberOfWidgetsDisplay">
            {`This widget has `} <span> {fields.length}</span>
            {` item${fields.length > 1 ? "s" : ""}`}
            <span
              style={{
                display: "inline-block",
                marginLeft: "1rem",
                cursor: "pointer",
              }}
              onClick={emptySchema}
            >
              {" "}
              Clear All{" "}
            </span>
          </li>
        ) : (
          ""
        )}

        {fields.map((field, index) => (
          <li key={index} className="cx-popup-widget-form-widgetSchemaWindow">
            <Field
              name={field + "logic"}
              options={options}
              component={SchemaFieldSelect}
            />

            <Field name={field + "category"} component={SchemaFieldCategory} />
            <button
              type="button"
              title="Remove Schema Field"
              onClick={() => fields.remove(index)}
            />
          </li>
        ))}
        {submitFailed && error && <li className="error">{error}</li>}
      </ul>
    </div>
  );
};

export const SwitchF = ({ input, className, meta, defaultValue }) => {
  return (
    <div className={`cx-input-container cx-switch-container ${className}`}>
      <Switch
        offColor=""
        offText=""
        onColor=""
        onText=""
        onChange={(e) => input.onChange(e.state.value)}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const Checkbox = ({
  input,
  label,
  className,
  meta: { touched, error, warning },
  defaultChecked,
  showMsg = false,
}) => {
  return (
    <div className={`cx-input-container cx-checkbox-container ${className}`}>
      <FormGroup check inline>
        <Label check className={"cx-checkbox-label"}>
          <InputStrap
            type="checkbox"
            className={`cx-checkbox-input ${
              touched && ((error && "error") || (warning && "warning") || "")
            }`}
            defaultChecked={defaultChecked}
            {...input}
            value={input.value}
            checked={
              input.value || (!input.value && input.value == defaultChecked)
            }
          />
          <span className="form-check-sign" />
          <span className={"cx-checkbox-label-value"}>{label}</span>
        </Label>
        {touched &&
          ((error && (
            <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
              {error}
            </div>
          )) ||
            (warning && (
              <div
                className={`warning input-warning ${showMsg ? "show-msg" : ""}`}
              >
                {warning}
              </div>
            )))}
      </FormGroup>
    </div>
  );
};

export const Radio = ({
  defaultChecked,
  meta: { touched, error, warning },
  input,
  options,
  className,
  showMsg = false,
}) => {
  return (
    <div className={`cx-input-container cx-radio-container ${className}`}>
      <FormGroup check className="form-check-radio">
        {options.map((o) => {
          return (
            <div
              className={"cx-rad-container"}
              key={`radio-key-${o.title.split(" ").join("-")}`}
            >
              <Label check>
                <InputStrap
                  className={`cx-radio-input ${
                    touched &&
                    ((error && "error") || (warning && "warning") || "")
                  }`}
                  type="radio"
                  {...input}
                  value={o.value}
                  checked={
                    o.value === input.value ||
                    (!input.value && o.value == defaultChecked)
                  }
                />
                <span className="form-check-sign" />
                <span className={"cx-checkbox-label-value"}>{o.title}</span>
              </Label>
            </div>
          );
        })}
        {touched &&
          ((error && (
            <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
              {error}
            </div>
          )) ||
            (warning && (
              <div
                className={`warning input-warning ${showMsg ? "show-msg" : ""}`}
              >
                {warning}
              </div>
            )))}
      </FormGroup>
    </div>
  );
};

export const Datepicker = ({
  input,
  meta: { touched, error, warning },
  placeholder,
  className,
  disabled = false,
  showMsg = false,
}) => {
  return (
    <div className={`cx-single-datepicker cx-input-container ${className}`}>
      <FormGroup
        className={`cx-input-icon-container ${
          disabled ? "cx-input-is-disabled" : ""
        }`}
      >
        <ReactDatetime
          locale="en"
          dateFormat="MM/DD/YYYY"
          inputProps={{
            className: `cx-input-icon-input form-control ${
              touched && ((error && "error") || (warning && "warning") || "")
            }`,
            placeholder: placeholder,
            disabled: disabled,
          }}
          timeFormat={false}
          {...input}
        />
        {touched &&
          ((error && (
            <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
              {error}
            </div>
          )) ||
            (warning && (
              <div
                className={`warning input-warning ${showMsg ? "show-msg" : ""}`}
              >
                {warning}
              </div>
            )))}
      </FormGroup>
    </div>
  );
};

export const Uploader = UploaderC;

export const SelectF = ({
  input,
  placeholder,
  className,
  meta: { touched, error, warning },
  isMulti,
  isClearable,
  options,
  label,
  showMsg = false,
}) => {
  return (
    <div className={`cx-input-container cx-select-container ${className}`}>
      <div className={"cx-input-label"}>
        <label>{label}</label>
      </div>
      <div className={"cx-input-icon-container"}>
        <Select
          className={`cx-input-icon-container-select react-select info ${
            touched && ((error && "error") || (warning && "warning") || "")
          }`}
          classNamePrefix={"react-select"}
          options={options}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable={isClearable}
          {...input}
          onChange={(value) => input.onChange(value)}
          onBlur={() => input.onBlur(input.value)}
        />
        {touched &&
          ((error && (
            <div className={`error input-error ${showMsg ? "show-msg" : ""}`}>
              {error}
            </div>
          )) ||
            (warning && (
              <div
                className={`warning input-warning ${showMsg ? "show-msg" : ""}`}
              >
                {warning}
              </div>
            )))}
      </div>
    </div>
  );
};
