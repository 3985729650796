import {
    FETCH_SEGMENTS
} from "../constants/appConstant";
import api from '../api/segment';

/*
 * action creators
 */

const getSegmentsEvent = (data) => ({ type: FETCH_SEGMENTS, payload: data});



export const getList = () => {
    return async (dispatch) => {
        return api.getList()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getSegmentsEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};
