import {LOGIN_ERR, LOGIN} from '../constants/appConstant';
export default function (state = {} , action) {
    switch(action.type) {
        case LOGIN_ERR:
            return { ...state, error: action.payload };
        case LOGIN:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}