import {
    TOGGLE_SELECTION_CAMPAIGN,
    SET_SELECTION_ARRAYS,
    TOGGLE_ALL_CAMPAIGN,
    SET_SELECTION_MAP
} from '../constants/appConstant';
import mainHelper from '../helpers/main';
let helper = new mainHelper();

export default function (state = {} , action) {
    switch(action.type) {
        case TOGGLE_SELECTION_CAMPAIGN:
            return { ...state, selected: {...state.selected, [action.payload.type]: action.payload.selected}};
        case SET_SELECTION_ARRAYS:
            return { ...state, selected_arr: {...state.selected_arr, [action.payload.type]: helper.getSelectedIds(action.payload.selected)}};
        case TOGGLE_ALL_CAMPAIGN:
            return { ...state, selectAll: {...state.selectAll, [action.payload.type]: action.payload.state} };
        case SET_SELECTION_MAP:
            const payload = action.payload;
            const type = payload.type;
            const selected = payload.selected;
            const oldValues = state.selectionMap && state.selectionMap[type]? state.selectionMap[type]: {};
            const mergeValues = Object.keys(selected).length? {...oldValues, ...selected}: undefined;
            return { ...state, selectionMap: {...state.selectionMap, [type]: mergeValues}};
        default:
            return state;
    }
}