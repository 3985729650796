import api from "api/newsletterAnalysis";

export const getNewsletterPerformance = async () => {
    return api.getNewsletterPerformance()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getNewsletterPerformanceTotals = async () => {
    return api.getNewsletterPerformanceTotals()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getNewsletterPostsPerformance = async () => {
    return api.getNewsletterPostsPerformance()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}