import React, {Component} from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Drawer from "./adsetReview/drawer";
import {Col, Row} from "reactstrap";
import TableTabs from "./adsetReview/tableTabs";
import ReviewAllocationTable from "./adsetReview/reviewAllocationTable";
import AllocatedTable from "./adsetReview/allocatedTable";
import DateFilter from "../components/dateFilter";

class AdsetReview extends Component {
    state={
        model:[]

    }

    renderPageFilters = () => {
        return (
            <div className={"cx-page-filters"}>
                <DateFilter />
            </div>
        );
    }

    render() {
        return (
            <>
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <Drawer />
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-wrench"></i>&nbsp; Media Planner &gt; Review Targeting</h4>
                    </div>
                    {/*{this.renderPageFilters()}*/}
                    <div className={"page-reactive-table"}>
                        <Row className={"mx-0"}>
                            <Col className={"px-0"}>
                                <TableTabs />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0"}>
                                <div id={'cx-campaign-table-dashboard'} className={`${(this.props.activeTabList === 'review-allocations') ? '' : 'd-none'}`}>
                                    <ReviewAllocationTable />
                                </div>
                                <div id={'cx-adset-table-dashboard'} className={`${(this.props.activeTabList === 'allocated') ? '' : 'd-none'}`}>
                                    <AllocatedTable />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        activeTabList: state.tabTable && state.tabTable.activeTab
    }
}

export default connect(mapStateToProps)(AdsetReview);