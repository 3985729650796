import auth from "./auth";
import apiUrl from "../helpers/global";
import filterHelper from "../components/FilterBar/filterHelper";

class posts {
  
  
  constructor() {
    this.auth = new auth();
  }

  getFullParams(filters, dateFilter, init) {
    //console.log(dateFilter)
    if (!init) init = {};
    init["fromTimestamp"] = dateFilter.start;
    init["toTimestamp"] = dateFilter.end;
    init["filters"] = filterHelper.getFilterSchemaForApi(filters);

    return {
      data: JSON.stringify(init),
    };
  }

  filters = async (data) => {
    return this.auth.request.post(apiUrl + `/insights-filter`, {
      data: JSON.stringify(data),
    });
  };

  getAllAuthors = async (data) => {
    var params = {
      data: JSON.stringify({
        orderBy: "cx_pageviews",
        sort: "desc",
        size: 30,
        page: 1,
      }),
    };
    return this.auth.request.post(
      apiUrl + "/authors-by-cx-pageviews-impact",
      params
    );
  };

  getAllSections = async (data) => {
    var params = {
      data: JSON.stringify({
        orderBy: "cx_pageviews",
        sort: "desc",
        size: 30,
        page: 1,
      }),
    };
    return this.auth.request.post(
      apiUrl + "/classes-by-cx-pageviews-impact",
      params
    );
  };

  postsPublishedByDay = async (filters, dateFilter) => {
    var params = this.getFullParams(filters, dateFilter, {
      orderBy: "timestamp",
      sort: "DESC",
      size: 100,
    });
    return this.auth.request.post(
      apiUrl + "/posts-pageviews-by-day",
      params
    );
  };

  postsSummaryByCXImpact = async (filters, dateFilter) => {
    return this.auth.request.post(
      apiUrl + "/posts-cx-impact",
      this.getFullParams(filters, dateFilter)
    );
  };

  postsByCXPageviewsImpact = async (init, filters, dateFilter) => {
    var params = this.getFullParams(filters, dateFilter, init);
    return this.auth.request.post(
      apiUrl + "/posts-by-cx-pageviews-impact",
      params
    );
  };

  postsByCXTimeSpentImpact = async (init, filters, dateFilter) => {
    var params = this.getFullParams(filters, dateFilter, init);
    return this.auth.request.post(
      apiUrl + "/posts-by-cx-timespent-impact",
      params
    );
  };
}

export default posts;
