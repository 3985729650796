import React from "react";

export const Card = (props) => {
  const className = props.className ? props.className : "";
  return (
    <div
      style={{ overflowY: "auto", width: "100%" }}
      className={`cx-card-box-container ${className}`}
    >
      {props.children}
    </div>
  );
};

export const CardBody = (props) => {
  return <div className={"cx-card-box-body"}>{props.children}</div>;
};

export const CardHeader = (props) => {
  return <div className={"cx-card-box-header"}>{props.children}</div>;
};
