import React from 'react'
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle
} from "reactstrap";
import Tooltip from '../../../components/Tooltips/Tooltip';
import mainHelper from "../../../helpers/main";
import { authorsActions } from "../../../actions";
import { connect } from "react-redux";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import noData from "../../../assets/img/no-data-for-charts.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

let helpers = new mainHelper();

class AuthorsSummary extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          loader: false,
      }
  }
  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || 
    this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(this.props.pageFilters, this.props.dateFilter);
      this.setState({ loader: false });
    }
  }

  render() {
    const { summary } = this.props;
    return (
      <Card>
        <CardBody style={{ "height": "435px" }}>
          <Row className={"justify-content-between mx-0 mb-2"}>
            <CardTitle>Summary</CardTitle>
            <Tooltip text={"This card summarises the performance of your top authors in the recommendation engine. When two or more authors are selected in the filter, the top author will appear in the summary."}></Tooltip>
          </Row>
          {this.state.loader ? 
                <CxLoader />
                 : ''}
            {summary ?
              <>
                <Row>
                  <Col>
                    <div className="card-description">
                      <div className="mb-2">
                        <Tooltip text={helpers.getFilterTooltip()}>
                          <a href="/#" onClick={e => e.preventDefault()}>&nbsp;For the selected filters</a>
                        </Tooltip>
                        <span>&nbsp;during the
                                        <Tooltip
                          // text={this.props.date.startDate + '  -  ' + this.props.date.endDate}
                          >
                            <a href="/#" onClick={e => e.preventDefault()}>
                              &nbsp;selected period</a></Tooltip>.</span>
                      </div>
                      <div style={{ "padding": "0px 20px 0px 20px" }}>
                        {summary.top_recommmended_author ?
                          <>
                            <div>
                              <Tooltip text={helpers.formatCXNumber(summary.top_recommmended_author_impressions) + ' impression'}>
                                <span className="text-warning" style={{ "fontSize": "1.3rem" }}>{helpers.formatDecimal(summary.top_recommmended_author_impressions_percentage) + '%'}</span>
                              </Tooltip> &nbsp;of articles recommended were written by
                              &nbsp;<span className="text-success font-weight-500" style={{ "fontSize": "1.3rem" }}>“{summary.top_recommmended_author}”</span>.
                        </div>
                            <hr style={{ "borderTop": "1px solid rgba(255, 255, 255, 0.1)" }} />
                          </>
                          :
                          ''}
                        {summary.top_clicked_author ?
                          <>
                            <div>
                              <Tooltip text={helpers.formatCXNumber(summary.top_clicked_author_clicks) + ' clicks'}>
                                <span className="text-warning" style={{ "fontSize": "1.3rem" }}>{helpers.formatDecimal(summary.top_clicked_author_clicks_percentage) + '%'}</span>
                              </Tooltip> &nbsp;of clicks on articles were written by
                              &nbsp;<span className="text-success font-weight-500" style={{ "fontSize": "1.3rem" }}>“{summary.top_clicked_author}”</span>.
                        </div>
                            <hr style={{ "borderTop": "1px solid rgba(255, 255, 255, 0.1)" }} />
                          </>
                          :
                          ''}
                        {summary.top_engaged_author ?
                          <>
                            <div>
                              <Tooltip text={helpers.formatTime(summary.total_engagement_time)}>
                                <span className="text-warning" style={{ "fontSize": "1.3rem" }}>{helpers.formatDecimal(summary.top_engaged_author_time_percentage) + '%'}</span>
                              </Tooltip>&nbsp;of the total time spent on the site were from articles written by
                              &nbsp;<span className="text-success font-weight-500" style={{ "fontSize": "1.3rem" }}>“{summary.top_engaged_author}”</span>.
                        </div>
                            <hr style={{ "borderTop": "1px solid rgba(255, 255, 255, 0.1)" }} />
                          </>
                          :
                          ''}
                      </div>
                    </div>
                    <Row className={"mx-0"}>
                      <Col className={"px-2 py-2"} lg={6}>
                        <div className={'px-2 py-2 cx-mini-boxes'}>
                          <h5 className={'mb-1'}>
                            <Tooltip text={"Number of authors recommended"}>
                              <span> Recommeded Authors </span>
                            </Tooltip>
                          </h5>
                          <p className={'mb-0'}>
                            {summary.recommended_auhtors}
                          </p>
                        </div>
                      </Col>
                      <Col className={"px-2 py-2"} lg={6}>
                        <div className={'px-2 py-2 cx-mini-boxes'}>
                          <h5 className={'mb-1'}>
                            <Tooltip text={"Number of authors clicked on by users"}>
                              <span>  Clicked Authors </span>
                            </Tooltip>

                          </h5>
                          <p className={'mb-0'}>
                            {summary.clicked_authors}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </> : <NoData />
          }
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: state.filter && state.filter.date || null,
    pageFilters: state.authorsInsight && state.authorsInsight.pageFilters || null,
    summary: (state.authorsInsight && state.authorsInsight.summary) || null,
  }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (filter, dateFilter) => dispatch(authorsActions.fetchSummary(filter, dateFilter)),
  };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AuthorsSummary);
