import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();
const helper = new main();

const emailNewsLetterApi = {
    getList : async () => {
        const url = apiUrl + `/newsletter-service-list`;
        return auth.request.post(url);
    },
    read : async (id) => {
        const url = apiUrl + `/newsletter-service-read`;
        return auth.request.post(url, { data: JSON.stringify({id}) });
    },
    create : async (data) => {
        const url = apiUrl + `/newsletter-service-create`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    update : async (data) => {
        const url = apiUrl + `/newsletter-service-update`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    delete : async (data) => {
        const url = apiUrl + `/newsletter-service-delete`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
}
export const utitlityApi = {
    getPublishers : async () => {
        const url = apiUrl + `/publisher-list`;
        return auth.request.post(url);
    },
    imageUpload : async (data, file) => {
        const url = apiUrl + `/image-upload`,
              formData = new FormData(),
              config = {
                  headers: {
                      'content-type': 'multipart/form-data'
                  }
              };
        formData.append('data', JSON.stringify({ mimeType: data.mimeType, folderName: "emailService" }));
        formData.append('organization', helper.getOrganization());
        formData.append('file', file);
        return auth.request.upload(url, formData, config);
      },
    getPublisherByOrganization : async () => {
        const url = apiUrl + `/publisher-read-by-organization`;
        return auth.request.post(url);
    },
}

export default emailNewsLetterApi;