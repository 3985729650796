import React, { Component } from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import ActionBtns from "./actionBtns";
import AdvertiserTable from "./tableList";
import AdvertiserForm from "./popupForm";
// import DateFilter from "../../components/dateFilter";
import _ from "lodash";
import { advertiserActions,organizationActions } from "../../../actions";
import Popup from "../../components/popup";
import PixelsList from "./pixelList";

class AdvertiserList  extends Component {

    constructor(props) {
        super(props);
        this.props.getOrganizationList();
        this.state={
            changeDefaultAdvertiser: 0,
            isPixelsListPopupOpened: false,
            pixelForAdvertiserId: null
        }
    }

    componentDidUpdate(prevProps) {
        // Create 
        if (this.props.createErr && !_.isEqual(this.props.createErr, prevProps.createErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.createErrEvent();
        }
        if (this.props.create && !_.isEqual(this.props.create, prevProps.create)) {
            this.alertMsg('Advertiser Created Successfully', 'fa fa-check', 'success');
            this.props.createEvent();
        }
        // Update 
        if (this.props.updateErr && !_.isEqual(this.props.updateErr, prevProps.updateErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.updateErrEvent();
        }
        if (this.props.update && !_.isEqual(this.props.update, prevProps.update)) {
            this.alertMsg('Advertiser Updated Successfully', 'fa fa-check', 'success');
            this.props.updateEvent();
        }
        // delete
        if (this.props.deleteErr && !_.isEqual(this.props.deleteErr, prevProps.deleteErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.deleteErrEvent();
        }
        if (this.props.delete && !_.isEqual(this.props.delete, prevProps.delete)) {
            this.alertMsg('Advertiser Deleted Successfully', 'fa fa-check', 'success');
            this.props.deleteEvent();
        }
    }

    alertMsg = (msg, icon = 'tim-icons icon-simple-remove', type = 'danger') => {
        this.refs.notificationAlert.notificationAlert({
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    render() {
        return (
            <>
                <Popup title={"Advertiser"} width={'35%'}>
                 <AdvertiserForm />                
                </Popup>
                <PixelsList
                    pixelForAdvertiserId={this.state.pixelForAdvertiserId}
                    isPopupOpened={this.state.isPixelsListPopupOpened}
                    closePopup={()=>{this.setState({
                        isPixelsListPopupOpened: false,
                        pixelForAdvertiserId: null
                    })}}
                />
                <div className={"cx-notification-alert"}>
                    <NotificationAlert ref="notificationAlert" />
                </div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-cog"></i>&nbsp; Advertiser</h4>
                    </div>
                    <div className={"page-reactive-table"}>                    
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-advertiser-'}>
                                    <AdvertiserTable openPixelList={(advertiserId)=> {
                                        this.setState({isPixelsListPopupOpened: true, pixelForAdvertiserId: advertiserId})
                                    }} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        actionType: (state.advertiser && state.advertiser.actionType)? state.advertiser.actionType : 'create',
        Item: state.advertiser && state.advertiser.Item,
        createErr: (state.advertiser && state.advertiser.createErr) || null,
        create: (state.advertiser && state.advertiser.create) || null,
        updateErr: (state.advertiser && state.advertiser.updateErr) || null,
        update: (state.advertiser && state.advertiser.update) || null,
        deleteErr: (state.advertiser && state.advertiser.deleteErr) || null,
        delete: (state.advertiser && state.advertiser.delete) || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent:() => dispatch(advertiserActions.createEvent(undefined)),
        createErrEvent: () => dispatch(advertiserActions.createErrEvent(undefined)),
        updateErrEvent: () => dispatch(advertiserActions.updateErrEvent(undefined)),
        updateEvent: () => dispatch(advertiserActions.updateErrEvent(undefined)),
        deleteErrEvent: () => dispatch(advertiserActions.deleteErrEvent(undefined)),
        deleteEvent: () => dispatch(advertiserActions.deleteEvent(undefined)),
        getOrganizationList: () => dispatch(organizationActions.getList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserList);