import React from "react";
import SurveyTypeCard from "./SurveyTypeCard";
import ratingIcon from "assets/img/ratingIcon.svg";
import commentIcon from "assets/img/commentIcon.svg";
import singleQuestionIcon from "assets/img/singleQuestionIcon.svg";
import multipleQuestionIcon from "assets/img/multipleQuestionIcon.svg";
import { useController } from "react-hook-form";

const SurveyTypes = ({ name, control }) => {
    const {
        field: { value, onChange },
    } = useController({
        control,
        name: name,
    });

    const handleSurveyClick = (type) => {
        onChange(type);
    };

    return (
        <div className="survey-types-cards">
            <SurveyTypeCard
                icon={ratingIcon}
                title={"Rating Scale"}
                onClick={handleSurveyClick}
                selected={value==="Rating Scale"}
            />
            <SurveyTypeCard
                icon={commentIcon}
                title={"Open Ended Question"}
                onClick={handleSurveyClick}
                selected={value==="Open Ended Question"}
            />
            <SurveyTypeCard
                onClick={handleSurveyClick}
                icon={singleQuestionIcon}
                title={"Single Choice Question"}
                selected={value==="Single Choice Question"}
            />
            <SurveyTypeCard
                onClick={handleSurveyClick}
                icon={multipleQuestionIcon}
                title={"Multiple Choice Question"}
                selected={value==="Multiple Choice Question"}
            />
        </div>
    );
};

export default SurveyTypes;
