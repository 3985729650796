import {
    SET_RECOMMENDATION_ACTION_TYPE,
    FETCH_RECOMMENDATION_CONFIGS,
    FETCH_RECOMMENDATION_CONFIG_ITEM,
    UPDATE_RECOMMENDATION_CONFIG,
    UPDATE_ERR_RECOMMENDATION_CONFIG,
    CREATE_RECOMMENDATION_CONFIG,
    CREATE_ERR_RECOMMENDATION_CONFIG,
    DELETE_RECOMMENDATION_CONFIG,
    DELETE_ERR_RECOMMENDATION_CONFIG,
    FETCH_RECOMMENDATION_CLASS_LIST,
    DEFAULT_RECOMMENDATION_FRESHNESS_DAYS,
    CONFIG_RECOMMENDATION_ERROR
} from "../constants/appConstant";
import api from '../api/recommendation';

/*
 * action creators
 */
const getRecommendationClassListEvent = (list, values) => ({type: FETCH_RECOMMENDATION_CLASS_LIST, payload: {list, values}});
export const setRecommendationActionType  = (data) => ({ type: SET_RECOMMENDATION_ACTION_TYPE, payload: data});
const getConfigListEvent = (data) => ({ type: FETCH_RECOMMENDATION_CONFIGS, payload: data});
export const getConfigItemEvent = (data) => ({ type: FETCH_RECOMMENDATION_CONFIG_ITEM, payload: data });
export const updateConfigEvent = (updated) => ({ type: UPDATE_RECOMMENDATION_CONFIG, payload: updated });
export const updateConfigErrEvent = (err) => ({ type: UPDATE_ERR_RECOMMENDATION_CONFIG, payload: err });
export const createConfigEvent = (created) => ({ type: CREATE_RECOMMENDATION_CONFIG, payload: created });
export const createConfigErrEvent = (err) => ({ type: CREATE_ERR_RECOMMENDATION_CONFIG, payload: err });
export const deleteConfigEvent = (deleted) => ({ type: DELETE_RECOMMENDATION_CONFIG, payload: deleted });
export const deleteConfigErrEvent = (err) => ({ type: DELETE_ERR_RECOMMENDATION_CONFIG, payload: err });
export const getFreshness = (freshness) => ({ type: DEFAULT_RECOMMENDATION_FRESHNESS_DAYS, payload: freshness });
export const configListErrEvent = (err) => ({ type: CONFIG_RECOMMENDATION_ERROR, payload: err });


export const getConfigList = () => {
    return (dispatch) => {
        return api.getConfigList()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getConfigListEvent(res));
                dispatch(configListErrEvent(undefined));
            })
            .catch(error => {
                dispatch(getConfigListEvent(null));
                dispatch(configListErrEvent('error'));
                throw (error);
            });
    };
};

export const getConfigItem = (data) => {
    return (dispatch) => {
        return api.getConfigItem(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getConfigItemEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const getDefaultFreshnessDays = () => {
    return (dispatch) => {
        return api.readDefaultFreshnessDays()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.freshnessDays)
                    res = response.data.freshnessDays;
                dispatch(getFreshness(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const setDefaultFreshnessDays = (data) => {
    return (dispatch) => {
        return api.setDefaultFreshnessDays(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.freshnessDays)
                    res = response.data.freshnessDays;
                dispatch(getFreshness(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const updateConfig = (data) => {
    return (dispatch) => {
        return api.updateConfigItem(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(updateConfigEvent(res))
                dispatch(updateConfigErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(updateConfigErrEvent('Error'));
                throw (error);
            });
    };
}

export const createConfig = (data) => {
    return (dispatch) => {
        return api.createConfig(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(createConfigEvent(res))
                dispatch(updateConfigErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(createConfigErrEvent('Error'));
                throw (error);
            });
    };
}

export const deleteConfig = (data) => {
    return (dispatch) => {
        return api.deleteConfig(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(deleteConfigEvent(res))
                dispatch(deleteConfigErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(deleteConfigErrEvent('Error'));
                throw (error);
            });
    };
}

export const getRecommendationClassList = () => {
    return (dispatch) => {
        return api.getClassList()
            .then(response => {
                let classList = null;
                let valueList = null;
                if (response && response.data && response.data.data && response.data.data.length){
                    classList = [];
                    valueList = {};
                    for (let i in response.data.data){
                        const item = response.data.data[i];
                        classList.push({label: item.class, value: item.class});
                        let classValList = [];
                        for (let j in item.values){
                            classValList.push({label: item.values[j].tag, value: item.values[j].tag});
                        }
                        valueList[item.class] = classValList;
                    }
                }
                dispatch(getRecommendationClassListEvent(classList, valueList))
            })
            .catch(error => {
                dispatch(getRecommendationClassListEvent(null, null))
                throw (error);
            });
    };
};