import React from "react";
import { Card, CardBody, Row, CardTitle } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mainHelper from "../../../helpers/main";
import _ from "lodash";
import CxLoader from "../../components/cxLoader";
import Tooltip from "../../../components/Tooltips/Tooltip";
import { classificationActions } from "../../../actions";
import { connect } from "react-redux";
require("highcharts/modules/no-data-to-display")(Highcharts);
let helpers = new mainHelper();
let colorset = helpers.cxColorSet;
let options = {
  colors: colorset,
  chart: {
    type: "bubble",
    zoomType: "xy",
    height: 372,
    backgroundColor: "transparent",
  },
  legend: {
    layout: "horizontal",
    enabled: true,
    floating: false,
    itemStyle: {
      color: "#fff",
    },
  },
  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(255,255,255,0.1)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
    xAxis: {
      gridLineColor: "#505053",
    },
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: " ",
  },
  xAxis: {
    gridLineColor: "#707073",
    gridLineWidth: 1,
    lineWidth: 0,
    title: {
      text: "Article Count",
      style: {
        color: "#aeaeae",
      },
    },
    labels: {
      format: "{value}",
      style: {
        color: "#aeaeae",
      },
    },
  },
  credits: { enabled: false },
  yAxis: {
    gridLineColor: "#707073",
    startOnTick: false,
    endOnTick: false,
    title: {
      text: "Pageviews",
      style: {
        color: "#aeaeae",
      },
    },
    labels: {
      format: "{value}",
      style: {
        color: "#aeaeae",
      },
      formatter: function () {
        return helpers.formatNumber(this.value);
      },
    },
    maxPadding: 0.2,
  },
  tooltip: {
    useHTML: true,
    formatter: function () {
      return (
        "<table><tr><th colspan=\"2\"><h4 style='color: black'>" +
        this.point.className +
        "</h5></th></tr>" +
        "<tr><th>Pageviews</th><td>" +
        helpers.formatNumber(this.point.y) +
        "</td></tr>" +
        "<tr><th>Articles</th><td>" +
        this.point.x +
        "</td></tr>" +
        "<tr><th>Timspent</th><td>" +
        helpers.formatTime(this.point.timespent) +
        "</td></tr>" +
        (this.point.cx_timespent
          ? "<tr><th>CX Impact</th><td>" +
            helpers.formatTime(this.point.cx_timespent) +
            " <b>" +
            helpers.formatDecimal(this.point.cx_timespent_percent) +
            "%</b></td></tr>"
          : "") +
        "</table>"
      );
    },
    followPointer: true,
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false,
        borderWidth: 0,
      },
    },
  },
  series: [],
  lang: {
    noData: "No Data",
  },
  noData: {
    style: {
      fontWeight: "bold",
      fontSize: "15px",
      color: "#303030",
    },
  },
};

class ClassesBubbleGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bubbleGraph: {},
      loader: false,
    };
    this.setClassTitle();
  }
  setClassTitle = () => {
    this.classTitle = helpers.capitalizeFirstLetter(this.props.classKey);
  };

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (!_.isEqual(this.props.queryFilterData, prevProps.queryFilterData)) {
  //     this.authorBubble(this.props.queryFilterData);
  //     this.setClassTitle();
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.classMapping, prevProps.classMapping) ||
      !_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
      (this.props.dateFilter &&
        !_.isEqual(this.props.dateFilter, prevProps.dateFilter))
    ) {
      this.fetchData();
    }
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if (this.props.dateFilter) {
      this.setState({ loader: true });
      await this.props.fetchData(
        {
          orderBy: "pageviews",
          sort: "DESC",
          size: 20,
          classMapping: this.props.classMapping,
        },
        this.props.pageFilters,
        this.props.dateFilter
      );
      this.setClassTitle();
      this.setState({ loader: false });
    }
  };

  /*
   * Helper function
   * to map the api to the state needed
   * */
  mapToState = () => {
    let data = this.props.performance;
    let pageviewsData = [];
    let cxPageviewsData = [];
    for (let i in data) {
      let item = data[i];
      pageviewsData.push({
        x: item.published_posts,
        y: item.pageviews,
        z: item.time,
        timespent: item.time,
        cx_timespent: item.cx_time,
        cx_timespent_percent: item.cx_impact,
        cx_pageviews: item.cx_pageviews,
        className: item.class,
        name: item.className ? item.className.split(" ")[0] : item.class,
      });
      cxPageviewsData.push({
        x: item.published_posts,
        y: item.pageviews,
        z: item.cx_time,
        timespent: item.time,
        cx_timespent: item.cx_time,
        cx_timespent_percent: item.cx_impact,
        className: item.class,
        name: "",
      });
    }
    return {
      ...options,
      series: [
        {
          name: "Engagement Time",
          data: pageviewsData,
          border: 0,
          color: "#29b3e8",
        },
        {
          name: "CX Impact",
          color: "#ff8d72",
          data: cxPageviewsData,
        },
      ],
    };
  };

  render() {
    return (
      <Card>
        <CardBody style={{ height: "435px" }}>
          <Row className={"justify-content-between mx-0"}>
            <CardTitle>
              <span className="cx-pink">{this.classTitle}</span> Performance
            </CardTitle>
            <Tooltip
              text={
                "This card displays the Number of articles written about a specific tag vs. the pageviews achieved. The size of the circle resembles the total timespent by users on articles that mention this tag. You can also view the contribution of CX for each author. Tip: Look for anomalies in this graph."
              }
            ></Tooltip>
          </Row>
          {this.state.loader ? <CxLoader /> : ""}
          {this.props.performance ? (
            <HighchartsReact
              containerProps={{ className: "highchartscss" }}
              highcharts={Highcharts}
              allowChartUpdate={true}
              immutable={true}
              options={this.mapToState()}
            />
          ) : (
            "No Data"
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dateFilter: (state.filter && state.filter.date) || null,
    pageFilters:
      (state.classificationInsight &&
        state.classificationInsight.pageFilters) ||
      null,
    performance:
      (state.classificationInsight &&
        state.classificationInsight.performance) ||
      [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (params, filter, dateFilter) =>
      dispatch(
        classificationActions.fetchPerformance(params, filter, dateFilter)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassesBubbleGraph);
