import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useInput } from '../../hooks/input-hook';
import IntegrationApi from '../../api/integration';
import api from '../../api/publisherManageApi';
import CxLoader from "../components/cxLoader";

function Integration() {
  const { value: crawledUrl, bind: bindCrawledUrl, reset: resetCrawledUrl } = useInput('');
  const [crawlResults, setCrawlResults] = useState();
  const [publisherData, setPublisherData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPublisher, setIsLoadingPublisher] = useState(true);
  const [isCheckingTracker, setIsCheckingTracker] = useState(true);
  const [isTrackerValid, setTrackerValid] = useState(false);

  const checkTracker = async () => {
    setIsCheckingTracker(true);
    let response = await IntegrationApi.checkTracker();
    if (response && response.data && response.data.data) {
      setTrackerValid(response.data.data.isTrackerExist);
    }
    setIsCheckingTracker(false);
    //resetCrawledUrl()
  };
  const validateMetadata = (result) => {
    return result.title && result.url && result.postType && result.thumb && result.datePublished
  }
  const crawlUrl = async () => {
    setIsLoading(true);
    let response = {};
    if (publisherData.isLite) {
      response = await IntegrationApi.crawlMetadata(crawledUrl);
    } else {
      response = await IntegrationApi.crawlJsonLd(crawledUrl);
    }

    let result = {};
    setIsLoading(false);
    if (response && response.data) {
      if (response.data.status === 'OK') {
        result.data = response.data.data;

      }
      else if (response.data.status === 'NOT_FOUND') {
        result.error = 'CognativeX meta data Not found in the article';
      } else {
        result.error = 'An Error Has Occured: ' + response.data.status;
      }
    } else {
      result.error = 'An Error Has Occured';
    }

    setCrawlResults(result);

    //resetCrawledUrl()
  };

  const loadPublisher = async () => {
    setIsLoadingPublisher(true);
    let response = await api.getByOrganization();
    if (response && response.data && response.data.data) {
      setPublisherData(response.data.data);
      checkTracker();
    }
    setIsLoadingPublisher(false);
    //resetCrawledUrl()
  };

  useEffect(() => {
    console.log('useEffect');
    loadPublisher();
  }, []);

  const handleFocus = (event) => event.target.select();


  return (
    <div className="content cx-page-widget-performance">
      <div>
        <h4 style={{ "marginBottom": "20px" }}><i className="tim-icons icon-notes"></i>&nbsp; Integration</h4>
      </div>
      {isLoadingPublisher ? <CxLoader /> :
        <Row className={"cx-widget-performance-graphs align-items-start mx-0"}>
          <div className={"col-sm-12"}>
            <div className={"card"}>
              <div className="card-body"  >
                <div className="cx-card-title justify-content-between mx-0 row">
                  <div className="card-title">Tracker validation</div>
                </div>
                <div>
                  <div className="mb-10">
                    {publisherData && publisherData.script &&
                      <textarea onFocus={handleFocus}
                        readonly="readonly" rows="12" style={{ width: '100%', backgroundColor: 'lightcyan', border: 0 }}>
                        {publisherData.script}
                      </textarea>
                      // <SyntaxHighlighter 
                      //   language='javascript' 
                      //   style={docco}
                      //   renderer={virtualizedRenderer()}
                      // >
                      //   {publisherData.script}
                      // </SyntaxHighlighter> 
                    }
                  </div>

                  {
                    isCheckingTracker ? 'Checking Tracker...' : (
                      isTrackerValid ?
                        <div>
                          <i className="fa fa-check green" style={{ fontSize: '30px', color: '#429a42' }}></i>  Tracker installed on {publisherData.domain}
                        </div>
                        :
                        <div style={{ color: '#c65151' }}>
                          <i className="fa fa-ban" style={{ fontSize: '30px', color: '#c65151' }}></i>  Tracker is not installed on {publisherData.domain}
                        </div>)
                  }
                </div>
              </div>
            </div>
          </div>

          <div className={"col-sm-12"}>
            <div className={"card"}>
              <div className="card-body" style={{ minHeight: '345px', overflowX: 'auto' }} >
                <div className="cx-card-title justify-content-between mx-0 row">
                  <div className="card-title">
                    Meta data validation 
                    {publisherData && publisherData.isLite?<span style={{color:'grey'}}> (using Open Graph Protocol <a target="_blank" href="https://ogp.me/">ogp.me</a>)</span>:<span style={{color:'grey'}}> (using CognativeX Json-Ld)</span>}

                    </div>
                   <div>
                   <a href="http://cognativex.com/integration" target="_blank">For more info check</a> 
                   </div>
                </div>
                <div>
           
                    </div>
                <div className="cx-input-container">
                  <div className="cx-input-icon-container" style={{ width: '100%', marginBottom: '10px' }}>
                    <input className="cx-input-icon-input"
                      placeholder="Paste Article Url"
                      type="text"
                      {...bindCrawledUrl}
                    />
                  </div>
                </div>
                <div className="cx-action-buttons mb-10" >
                  <button type="button" className="btn cx-focus-btn" onClick={() => crawlUrl()}>
                    {isLoading ? 'Loading...' : 'Crawl'}
                  </button>
                </div>
                <div style={{ "color": "#c5b9b9" }}>

                {crawlResults && crawlResults.error && (
                  <div style={{ color: 'rgb(198, 81, 81)' }}>
                    {crawlResults.error}
                  </div>
                )}


                  {crawlResults && crawlResults.data && (
                    <div>

                      {validateMetadata(crawlResults.data) ?
                        <div className="mb-10">
                          <i className="fa fa-check green" style={{ fontSize: '30px', color: '#429a42' }}></i>  Meta Data is valid
                        </div> :
                        <div className="mb-10">
                          <i className="fa fa-ban " style={{ fontSize: '30px', color: 'red' }}></i>  Meta Data is not valid
                      </div>
                      }

                      <table class="table table-bordered">
                        <tr>
                          <td>Title</td>
                          <td>{crawlResults.data.title}</td>
                        </tr>
                        <tr>
                          <td>Image</td>
                          <td>
                            <img src={crawlResults.data.thumb} width="200" />
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Postid</td>
                          <td>{crawlResults.postid}</td>
                        </tr> */}
                        <tr>
                          <td>Type</td>
                          <td>{crawlResults.data.postType}</td>
                        </tr>
                        <tr>
                          <td>Published Time</td>
                          <td>{crawlResults.data.datePublished}</td>
                        </tr>
                        {/* <tr>
                          <td>Author</td>
                          <td>{crawlResults.author}</td>
                        </tr> */}
                        <tr>
                          <td>Url</td>
                          <td>{crawlResults.data.url}</td>
                        </tr>
                      </table>
                    </div>
                  )}

                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      }</div>
  );
}

export default Integration;
