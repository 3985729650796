import React, { Component } from "react";
import { Form, Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input, SelectF } from "../../components/formElts";
import { popupActions, advertiserActions } from "../../../actions";
import CxLoader from "../../components/cxLoader";
import mainHelper from "../../../helpers/main";
let helpers = new mainHelper();

class AdvertiserForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit;
    this.state = {
      loader: false,
    };
  }

  onSubmit = (formVals) => {
    this.setState({ loader: true }, async () => {
      const mapped = { ...formVals };
      if (mapped.organization)
        mapped.organizationId = mapped.organization.value;
      if (this.props.actionType == "create") {
        await this.props.createForm(mapped);
      } else {
        if (this.props.item && this.props.item.id) {
          mapped.id = this.props.item.id;
          await this.props.updateForm(mapped);
        }
      }
      this.setState({ loader: false });
      this.props.reset();
      this.props.togglePopup(false);
      this.props.reloadList();
    });
  };

  cancelButton = (event) => {
    event.preventDefault();
    this.props.reset();
    this.props.removeItem();
    this.props.togglePopup(false);
  };

  submitButton = (event) => {
    event.preventDefault();
    this.handleSubmit(this.onSubmit)();
  };

  render() {
    return (
      <div id={"cx-advertiser-config-form-container"}>
        <Form
          className={"cx-recommendation-config-form-container"}
          onSubmit={() => false}
        >
          <Field
            showMsg={true}
            disabled={this.state.loader}
            className={"cx-popup-form-layout"}
            label={"Organization"}
            name={"organization"}
            options={this.props.organizationList}
            component={SelectF}
          />
          <br />
          <Field
            showMsg={true}
            disabled={this.state.loader}
            className={"cx-popup-form-layout"}
            type={"text"}
            label={"Advertiser"}
            name={"name"}
            component={Input}
          />
          <br />
          <Field
            showMsg={true}
            disabled={this.state.loader}
            className={"cx-popup-form-layout"}
            type={"number"}
            label={"Rank"}
            name={"rank"}
            component={Input}
          />
          <br />
          <Field
            showMsg={true}
            disabled={this.state.loader}
            className={"cx-popup-form-layout"}
            type={"number"}
            label={"Item Count"}
            name={"itemCount"}
            component={Input}
          />
          <br />
          <div className={"cx-action-buttons"}>
            <button
              disabled={this.state.loader}
              className="btn cx-focus-btn"
              onClick={(e) => {
                this.submitButton(e);
              }}
            >
              Save
            </button>
            {this.state.loader ? <CxLoader inPlace={true} /> : ""}
            <button
              disabled={this.state.loader}
              className="btn"
              onClick={(e) => {
                this.cancelButton(e);
              }}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const organizationList =
    state.organization && state.organization.List
      ? state.organization.List.map(function (item) {
          return { value: item.id, label: item.name };
        })
      : [];

  //const organizationMappingForm = formValueSelector('AdvertiserForm')(state, 'organizationList');

  const itemExist =
    state.advertiser && state.advertiser.Item ? state.advertiser.Item : "";
  const initialValues = itemExist
    ? {
        id: itemExist.id,
        name: itemExist.name,
        rank: itemExist.rank,
        itemCount: itemExist.itemCount,
        organizationId: itemExist.organizationId,
        organization: itemExist.organizationId
          ? helpers.getSelectObjectByVal(
              organizationList,
              itemExist.organizationId
            )
          : [],
      }
    : {
        rank: 50,
        itemCount: 0,
      };

  return {
    item: itemExist,
    organizationList: organizationList,
    actionType:
      state.advertiser && state.advertiser.actionType
        ? state.advertiser.actionType
        : "create",
    initialValues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reloadList: () => dispatch(advertiserActions.getList()),
  removeItem: () => dispatch(advertiserActions.getItemEvent("undefined")),
  createForm: (data) => dispatch(advertiserActions.create(data)),
  updateForm: (data) => dispatch(advertiserActions.update(data)),
  togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
});

function validate(form) {
  const errors = {};

  if (!form.name) {
    errors.name = "Name field is required";
  }
  if (!form.domain) {
    errors.domain = "Domain field is required";
  }
  if (!form.organization) {
    errors.organization = "Organization field is required";
  }
  return errors;
}

AdvertiserForm = reduxForm({
  validate,
  form: "AdvertiserForm",
  enableReinitialize: true,
})(AdvertiserForm);

AdvertiserForm = connect(mapStateToProps, mapDispatchToProps)(AdvertiserForm);

export default AdvertiserForm;
