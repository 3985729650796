import React from "react";
import { Modal, ModalBody, Row, Col, Label } from "reactstrap";
import PropTypes from "prop-types";
import Select from "react-select";

// accepts data as follows:
// data = {author: {label: test, value: test}, category: {label: test, value:test}};
// ex. of how to use it:
// {(this.state.filter)?
//   <Col lg={"3"}>
//     <Filter
//       callback={this.filterCallback}
//       data={this.state.filter}
//       selected={'Author=lea,pia&Category=تسريحة,Jamalouki'}/>
//   </Col>
//   : <></>
// }


class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.data,
      selected: {},
      selectedClone: {},
      modalClassic: false,
      defaultMsg: (props.defaultMsg) ? props.defaultMsg : 'Filter'
    };
  }

  componentDidMount() {
    if (this.props.selected) {
      this.setState({
        selected: this.constructArrayFilter(this.props.selected)
      }, () => {
        this.setState({
          selectedClone: this.state.selected
        });
      });
    }
  }



  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.data !== prevProps.data || this.props.defaultMsg !== prevProps.defaultMsg) {
      // when data changes, then empty the selection
      this.setState({
        filters: this.props.data,
        selected: {},
        selectedClone: {},
        defaultMsg: this.props.defaultMsg
      });
    }
  }
  /*
  * Helper function
  * to get the label of an item by value and type
  * */
  getItemLabel = (type, value) => {
    let filters = this.state.filters;
    if (filters && filters[type].length) {
      let filterType = filters[type];
      for (let i in filterType) {
        if (value === filterType[i]['value']) {
          return filterType[i]['label']
        }
      }
    }
  }

  /*
  * Helper function
  * to get the array of filter from query string form
  * */
  constructArrayFilter = (queryStr) => {
    let arrayFilter = {};
    console.log('constructArrayFilter')
    console.log(queryStr)
    // let filterSectioning = queryStr.split('&');
    // for (let sectionIndex in filterSectioning) {
    //   let section = filterSectioning[sectionIndex],
    //     getType = section.split('='),
    //     type = getType[0],
    //     dataValues = getType[1].split(',');
    //   if (!dataValues.length) continue;
    //   arrayFilter[type] = [];
    //   for (let dataIndex in dataValues) {
    //     let value = dataValues[dataIndex]
    //     let item = {
    //       value: value,
    //       label: this.getItemLabel(type, value)
    //     };
    //     arrayFilter[type].push(item)
    //   }
    // }
    return arrayFilter;
  }

  /*
  * Helper function
  * to update the filter data
  * */
  constructQueryFilter = (filterData) => {
    let mappedFilters = {};
    for (let keyFilterType in filterData) {
      let keyFilterValues = [];
      let keyFilterData = filterData[keyFilterType];
      if (!keyFilterData.length) continue;
      for (let index in keyFilterData) {
        let item = keyFilterData[index];
        keyFilterValues.push(item.value)
      }

      mappedFilters[keyFilterType.includes('class')?'classes.value':keyFilterType]=keyFilterValues.join(',');
    }
    let f = { 'filters': mappedFilters };
    this.props.callback(f);
    return f;
  }

  /*
  * Helper function
  * for del pill click
  * */
  delFilter = (type, item) => {
    // remove the item => `item` of type => `type` from selected
    let selectedStateRef = this.state.selected;
    let selectedType = selectedStateRef[type];
    if (!(selectedType && selectedType.length)) return;
    // loop in the selected type and del once found
    for (let index = selectedType.length - 1; index >= 0; index--) {
      if (selectedType[index].label === item.label && selectedType[index].value === item.value) {
        selectedType.splice(index, 1);
        // break out of loop
        break;
      }
    }
    if (selectedType.length === 0) {
      delete selectedStateRef[type];
    } else {
      selectedStateRef[type] = selectedType;
    }
    // update the selections
    this.setState({ selected: selectedStateRef }, () => {
      this.constructQueryFilter(this.state.selected);
    });
    return;
  }

  /*
  * Helper function
  * for the filter change
  * */
  onFilterChange = (event, type) => {
    if (event.length === 0) {
      let selection = this.state.selectedClone;
      delete selection[type];
      this.setState({ selectedClone: selection });
    } else {
      this.setState({
        selectedClone: {
          ...this.state.selectedClone,
          [type]: event
        }
      });
    }
  }

  onSave = () => {
    this.setState({
      selected: this.state.selectedClone
    }, () => {
      this.constructQueryFilter(this.state.selected);
      this.toggleModalClassic();
    });
  }

  /*
  * Helper function
  * for the classic modal
  * */
  toggleModalClassic = () => {
    this.setState({
      modalClassic: !this.state.modalClassic
    });
  };

  formatLabel = (typeKeyName) => {
    return typeKeyName.includes("class_") ? typeKeyName.replace("class_", "") : typeKeyName;
  }
  /*
  * Helper function
  * prints a modal
  * */
  filterModal = () => {
    return (
      <Modal
        isOpen={this.state.modalClassic}
        toggle={this.toggleModalClassic}
        style={{ maxWidth: '620px', marginTop: '20px' }}
      >
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.toggleModalClassic}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <ModalBody className="">
          <div className={"col-sm-12"}>
            <div id={'cx-pop-filters'}>
              {Object.keys(this.state.filters).map((typeKeyName, i) => {
                let filterType = this.state.filters[typeKeyName];
                if (!filterType.length) return '';
                return (
                  <Row className={"cx-pop-filter mb-2"} key={typeKeyName + '-pop-' + i}>
                    <Col sm={3}><Label>{this.formatLabel(typeKeyName)}</Label></Col>
                    <Col sm={9}>
                      <Select
                        isMulti
                        isClearable={true}
                        className="react-select info"
                        classNamePrefix="react-select"
                        name={typeKeyName}
                        value={this.state.selectedClone[typeKeyName]}
                        onChange={e => { this.onFilterChange(e, typeKeyName) }}
                        options={filterType}
                        placeholder={this.formatLabel(typeKeyName)}
                      />
                    </Col>
                  </Row>
                );
              })}
            </div>
            <div className={"pull-right"}>
              <button className={"btn btn-primary"} onClick={this.onSave}>Filter</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.filterModal()}
        <Row className={"cx-filter-wrapper"} style={{ "margin": "0px" }}>
          <a href={'/#'} className={'filter-add-btn'} onClick={(e) => { e.preventDefault(); this.toggleModalClassic(); }} title={this.state.defaultMsg}>
            <i className="fa fa-plus-circle" />
          </a>
          {(Object.keys(this.state.selected).length !== 0) ? Object.keys(this.state.selected).map((typeKeyName, i) => {
            // typeKeyName ex. author
            // this.state.selected[typeKeyName] ex. [{label: label, value: value}, {label: label, value: value}]
            return this.state.selected[typeKeyName].map((item, index) => {
              return (
                <div className={"filter-selected"} key={typeKeyName + i + '-' + index}>
                  <div className={'filter-pill'}>
                    <span className={'filter-type'}>{typeKeyName}:</span>
                    <span className={'filter-label'}>{item.label}</span>
                    <a href={'/#'} className={'filter-delete'} onClick={(e) => { e.preventDefault(); this.delFilter(typeKeyName, item) }}>
                      <i className="tim-icons icon-simple-remove" />
                    </a>
                  </div>
                </div>
              );
            });
          }) :
            <div className={'filter-selected'}>
              <div className="cx-white-color">{this.state.defaultMsg}</div>
            </div>
          }
        </Row>
      </>
    );
  }
}

Filter.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  selected: PropTypes.string,
  defaultMsg: PropTypes.string
};

export default Filter;
