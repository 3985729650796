import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { campaignPerformanceActions } from "../../../actions";
import _ from 'lodash';
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const helper = new mainHelper();

class LineChart extends Component {
    constructor(props){
        super(props);
        this.state = {
            loader: false,
        }
    }

    /*
    * componentDidMount
    */
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({loader: true});
        await this.props.performanceFetch();
        this.setState({loader: false});
    }

    getData = () => {
        // get the `this.props.activeSubtab` eg. clicks
        // and return the data in the following format [[date, clicks], [date, clicks]]
        // where date = new Date(d).getTime()
        if(!this.props.performanceData.length) return [];
        let res = [];
        for(let i in this.props.performanceData) {
            const performanceD = this.props.performanceData[i];
            if(performanceD[this.props.activeSubtab]){
                res.push([new Date(performanceD.timestamp).getTime(), performanceD[this.props.activeSubtab]]);
            }
        }
        return res;
    }

    formatNumber = (subtab, value)=> {
        switch(subtab){
            case 'clicks':
            case 'views':
            case 'impressions':
                return helper.formatNumber(value);
            case 'amountSpent':
                return '$' + value.toLocaleString();
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-performance-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    key={this.props.activeSubtab + '-' + this.props.selectedType.typeid + '-' + this.props.selectedType.type + '-' + this.props.selectedType.campaignId}
                    highcharts={Highcharts}
                    containerProps={{ className: "highchartscss" }}
                    options={
                        {
                            chart: {
                                zoomType: 'x',
                                backgroundColor: 'transparent',
                                style: {
                                    fontFamily: 'Poppins'
                                }
                            },
                            exporting: {
                                "enabled": true,
                                buttons: {
                                    contextButton: {
                                        menuItems: [
                                            'viewFullscreen',
                                            'separator',
                                            'downloadPDF'
                                        ]
                                    }
                                },
                            },
                            title: {
                                text: ''
                            },
                            subtitle: {
                                text: document.ontouchstart === undefined ?
                                    'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
                                style: {
                                    color: '#9899A6'
                                }
                            },
                            xAxis: {
                                type: 'datetime',
                                labels: {
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                minPadding: 0
                            },
                            yAxis: {
                                title: {
                                    text: _.startCase(this.props.activeSubtab),
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                labels: {
                                    formatter: function () {
                                      return self.formatNumber(self.props.activeSubtab, this.value);
                                    },
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                gridLineColor: 'rgba(152,153,166, 0.2)'
                            },
                            credits: { enabled: false },
                            legend: {
                                enabled: false
                            },
                            plotOptions: {
                                area: {
                                    fillColor: {
                                        linearGradient: {
                                            x1: 0,
                                            y1: 0,
                                            x2: 0,
                                            y2: 1
                                        },
                                        stops: [
                                            [0, '#4090B4'],
                                            [1, Highcharts.Color('#BBE6F9').setOpacity(0.4).get('rgba')]
                                        ]
                                    },
                                    marker: {
                                        radius: 2
                                    },
                                    lineColor: '#4090B4',
                                    lineWidth: 1,
                                    states: {
                                        hover: {
                                            lineWidth: 1
                                        }
                                    },
                                    threshold: null
                                }
                            },

                            series: [{
                                type: 'area',
                                name: _.startCase(this.props.activeSubtab) + ' Graph',
                                data: this.getData()
                            }]
                        }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        performanceData: (state.campaignPerformance && state.campaignPerformance.performanceData)? state.campaignPerformance.performanceData: [],
        activeSubtab: state.slidedrawer && state.slidedrawer.subtab,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        performanceFetch: () => dispatch(campaignPerformanceActions.fetchTypePerformance())
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(LineChart);