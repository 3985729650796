import React, { useState } from "react";
import useEmailNewsletterStore from "../emailNewsletterStore";
import { CXConfirm, CXCardStatus } from "CXComponents";
import { useMutation, useQueryClient } from "react-query";
import { deleteEmailNewsletter } from "../emailNewsletterAPI";

const EmailNewsletterCard = ({
    id,
    title,
    scheduledType,
    scheduledDay,
    timeToSend,
    isActive,
    subscribersCount,
}) => {
    const [isDeleteEmailNewsletterOpen, setIsDeleteEmailNewsletterOpen] =
        useState(false);
    const setItemToLoad = useEmailNewsletterStore(
        (state) => state.setItemToLoad
    );
    const setIsOpen = useEmailNewsletterStore((state) => state.setIsOpen);
    const setActionType = useEmailNewsletterStore(
        (state) => state.setActionType
    );

    const deleteEmailNewsletterQuery = useMutation(() => {
        return deleteEmailNewsletter(id);
    });

    const qc = useQueryClient();

    const confirmEmailNewsletterDelete = () => {
        setIsDeleteEmailNewsletterOpen(true);
    };
    
    const getFormattedSubTitle = () => {
        if(scheduledType === 'daily'){
            return timeToSend + "(UTC)" 
        }
        let day = "";
        switch (scheduledType) {
            case "weekly":
                day += `Each ${scheduledDay}`;
                break;
            case "monthly":
                day += `${formatDayLocale(scheduledDay)} of each month`;
                break;
            default:
                day += "";
        }   
        return day + ' - ' + timeToSend + '(UTC)';
    };

    const formatDayLocale = (day) => {
        day = parseInt(day);
        if (day === 1) return "First day";
        if (day === 2) return "Second day";
        if (day === 3) return "Third day";
        if (day >= 4) return day + "th";
    };

    return (
        <>
            <div className="newsletter-card-container">
                <h4 className="title">{title}</h4>
                <span
                    className={`status status-${isActive ? "active" : "not-active"}`}
                    title={isActive ? "Active" : "Not Active"}
                ></span>
                <span className="schedule">{getFormattedSubTitle()}</span>
                <div className="typeTag">{scheduledType}</div>
                <div className="metric">
                    {subscribersCount}
                    <span>
                        <i className="fa fa-user"></i>
                    </span>
                </div>
            </div>
            
        </>
    );
};

export default EmailNewsletterCard;
