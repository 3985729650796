import { FormGroup, Label } from "reactstrap";
import React from "react";


const CXCheckbox = ({
    label,
    name,
    defaultValue,
    disabled,
    className,
    register,
  }) => {
    return (
      <div className={`cx-input-container cx-checkbox-container ${className}`}>
        <FormGroup check inline>
          <Label check className={"cx-checkbox-label"}>
            <input
              id={name}
              type="checkbox"
              disabled={disabled}
              className={`cx-checkbox-input`}
              defaultChecked={defaultValue}
              {...register(name)}
            />
            <span className="form-check-sign" />
            <span className={"cx-checkbox-label-value"}>{label}</span>
          </Label>
        </FormGroup>
      </div>
    );
};

export default CXCheckbox;