import api, {utitlityApi} from "../../api/emailNewsLetter";


// CRUD APIs
export const getList = async () => {
    return api.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const readEmailNewsletter = async (id) => {
    return api.read(id)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const createEmailNewsletter = async (data) => {
    return api.create(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const updateEmailNewsletter = async (data) => {
    return api.update(data)
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const deleteEmailNewsletter = async (id) => {
    return api.delete({ids:[id]})
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}


// utility
export const refetchList = (qc) => {
    return qc.invalidateQueries(["email-newsletter-list"]);
}

export const getPublishers = async () => {
    return utitlityApi.getPublishers()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const uploadImage = async (file) => {
    return utitlityApi.imageUpload({mimeType: file.type}, file)
        .then(response => {
            if(response && response.data && response.data.url){
                return response.data.url;
            }
        })
        .catch(error => {
            throw(error);
        });
};

export const loadPublisher = async () => {
    return utitlityApi.getPublisherByOrganization()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
};