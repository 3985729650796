import React, { Component } from "react";
import { connect } from 'react-redux';
import {Form, reduxForm} from "redux-form";
import PerfectScrollbar from "perfect-scrollbar";
import $ from 'jquery';
import _ from 'lodash';
import {campaignActions, campaignSelectionActions} from '../../../actions';
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import Tooltip from "../../../components/Tooltips/Tooltip";
import analytics from "../../../helpers/analytics";
import {
    Label,
    FormGroup,
    Input
} from "reactstrap";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};

const postIdName = '-publish-all';
var ps;
class PublishDraftItemsForm extends Component {

    constructor (props){
        super(props);
        this.headers = ['campaigns', 'adsets', 'ads'];
        this.state = {
            sorted: [{ id: 'id', desc: true }],
            activeTabList: 'campaigns'+postIdName,
            pageSize: 10,
            loading: this.props.loading,
            maxTableHeight: 0
        }
    }

    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.elementRef);
        }else{
            this.refs.elementRef.style.overflow = 'auto';
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.publishList && Object.keys(this.props.publishList).length > 0 && !_.isEqual(this.props.publishList, prevProps.publishList)){
            this.setHeights();
        }
        if(!_.isEqual(this.props.loading, prevProps.loading)){
            this.setState({loading: this.props.loading});
        }
    }

    renderTooltipErrorText = (type, errors) => {
        return (
            <div className={"px-2 cx-publish-errors"}>
                <div><i className='fa fa-exclamation-triangle cx-error'></i></div>
                <h5>
                    <strong>This {type.substr(0, type.length-1)} has {Object.keys(errors).length} error{Object.keys(errors).length>1 && 's'}</strong>
                </h5>
                <div className={"cx-publish-errors-list"}>
                    {Object.keys(errors).map((key)=>{
                        return <div key={'key-' + key}>{key}: {errors[key]}</div>
                    })}
                </div>
            </div>
        );
    }

    tableCols = (type) => {
        return [
            {
                id: "checkbox",
                accessor: "",
                Header: x => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selectAll[type] === 1}
                                       onChange={() => this.props.toggleAll(type+'Publish', this.props.publishList[type])}
                                       ref={input => {
                                           if (input) {
                                               input.indeterminate = this.props.selectAll[type] === 2;
                                           }
                                       }}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                       checked={this.props.selected[type][original.id] === true}
                                       onChange={() => this.props.toggleSelection(type+'Publish', original.id, original.id)}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: ({ original }) => {
                    return <span>{original.name}</span>;
                }
            },
            {
                Header: "Errors",
                accessor: "isValid",
                Cell: ({ original }) => {
                    return <span>
                        {original.isValid===false&&Object.keys(original.validationDetails).length?
                            <Tooltip placement={"left"} text={this.renderTooltipErrorText(type, original.validationDetails)}>
                                <i className='fa fa-exclamation-triangle cx-error'></i>
                            </Tooltip>
                            : '—'
                        }
                    </span>;
                } , width: 70
            },
        ];
    }

    tabsHeader = () => {
        return (
            <div className={"reactive-tabs"}>
                <div
                    style={{width: '33%'}}
                    id={"campaigns"+postIdName+"-tab"}
                    className={`${(this.state.activeTabList === 'campaigns'+postIdName) ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.setState({activeTabList:'campaigns'+postIdName})}}
                >
                    <i className={"fa fa-bullhorn"}></i> Campaigns <span className={"cx-tab-subtitle"}>({this.props.publishList? this.props.publishList.campaigns.length : 0})</span>
                </div>
                <div
                    style={{width: '33%'}}
                    id={"adsets"+postIdName+"-tab"}
                    className={`${(this.state.activeTabList === "adsets"+postIdName) ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.setState({activeTabList:'adsets'+postIdName})}}
                >
                    <i className={"fa fa-images"}></i> Adsets <span className={"cx-tab-subtitle"}>({this.props.publishList? this.props.publishList.adsets.length : 0})</span>
                </div>
                <div
                    style={{width: '33%'}}
                    id={"ads-tab"}
                    className={`${(this.state.activeTabList === "ads"+postIdName) ? 'active' : ''} reactive-tab`}
                    onClick={e => { this.setState({activeTabList:'ads'+postIdName}) }}
                >
                    <i className={"fa fa-image"}></i> Ads <span className={"cx-tab-subtitle"}>({this.props.publishList? this.props.publishList.ads.length : 0})</span>
                </div>
            </div>
        );
    }

    getMaxHeight = () => {
        let maxheight = 0;
        for(let i in this.headers){
            const selector = `#id-${this.headers[i]}${postIdName}`;
            const hidden = $(selector).hasClass('d-none');
            if(hidden) $(selector).removeClass('d-none');
            if($(selector).length && $(selector).height() > maxheight){
                maxheight = $(selector).height();
            }
            if(hidden) $(selector).addClass('d-none');
        }
        return maxheight;
    }

    tableRender = (type) => {
        return (
            <div
                key={"key-"+type+postIdName}
                id={"id-"+type+postIdName}
                className={(this.state.activeTabList === type+postIdName)? '': 'd-none'}
            >
                <ReactTable
                    noDataText=''
                    NoDataComponent={NoData}
                    defaultSorting={this.state.sorted}
                    sortable={false}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.publishList? this.props.publishList[type] : []}
                    resizable={false}
                    columns={this.tableCols(type)}
                    defaultPageSize={this.state.pageSize}
                    showPaginationBottom={false}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
            </div>
        );
    }

    setHeights = () => {
        const maxHeight = this.getMaxHeight();
        if(maxHeight <= 0) return;
        const selector = `.cx-publish-content-table`;
        if($(selector).length){
            $(selector).css('height', maxHeight+'px');
        }
    }

    cancelButton = (e) => {
        if(e) e.preventDefault();
        this.props.closePopup();
        this.headers.map((type)=>{
            return this.props.toggleAllOff(type+'Publish')
        });
    }

    getSubmitValues = () =>{
        let res = {};
        for(let i in this.props.selected_vals){
            if(this.props.selected_vals[i].length){
                res = {...res, [i]: this.props.selected_vals[i].map((r)=>r*1)};
            }
        }
        return res;
    }

    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    publishButton = (e) => {
        e.preventDefault();
        const res = this.getSubmitValues();
        // if nothing is selected prompt with an error
        if(!Object.keys(res).length) {
            //todo print msg that none has been chosen to be published

            // icon ='tim-icons icon-simple-remove', type= 'danger'
            this.alertMsg('Choose what you would like to publish');
            return;
        }
        // close popup
        this.cancelButton();
        this.props.publishAll(res);
        analytics.logEvent('Campaign','Review and publish submit');
    }

    tabsActions = () => {
        return (
            <div className={"cx-action-buttons"}>
                <button className={"btn cx-discard-btn cx-focus-btn"} onClick={(e) => { this.publishButton(e) }}>Publish</button>
                <button className={"btn cx-cancel-btn"} onClick={(e) => { this.cancelButton(e) }}>Cancel</button>
            </div>
        );
    }

    tabsContent = () => {
        return (
            <div className={"cx-publish-content-table"} ref="elementRef">
                {
                    this.headers.map((type)=> {
                        return this.tableRender(type)
                    })
                }
            </div>
        );
    }

    render() {
        return (
            <Form className={"cx-publish-all-container"} onSubmit={() => (false)}>
                {this.tabsHeader()}
                {this.tabsContent()}
                {this.tabsActions()}
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
            </Form>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    const campaignsHideSelectAll = (
        state.campaignSelections &&
        state.campaignSelections.selected_arr &&
        state.campaignSelections.selected_arr.campaignsPublish &&
        state.campaignSelections.selected_arr.campaignsPublish.length
    ) || false;
    const adsetsHideSelectAll = (
        state.campaignSelections &&
        state.campaignSelections.selected_arr &&
        state.campaignSelections.selected_arr.adsetsPublish &&
        state.campaignSelections.selected_arr.adsetsPublish.length
    ) || false;
    const adsHideSelectAll = (
        state.campaignSelections &&
        state.campaignSelections.selected_arr &&
        state.campaignSelections.selected_arr.adsPublish &&
        state.campaignSelections.selected_arr.adsPublish.length
    ) || false;
    return {
        hideSelectAll: {
            campaigns: campaignsHideSelectAll,
            adsets: adsetsHideSelectAll,
            ads: adsHideSelectAll
        },
        selectAll: {
            campaigns: (state.campaignSelections && state.campaignSelections.selectAll && state.campaignSelections.selectAll.campaignsPublish)? state.campaignSelections.selectAll.campaignsPublish : 0,
            adsets: (state.campaignSelections && state.campaignSelections.selectAll && state.campaignSelections.selectAll.adsetsPublish)? state.campaignSelections.selectAll.adsetsPublish : 0,
            ads: (state.campaignSelections && state.campaignSelections.selectAll && state.campaignSelections.selectAll.adsPublish)? state.campaignSelections.selectAll.adsPublish : 0
        },
        publishList: (state.campaigns && state.campaigns.toPublishList) || null,
        selected: {
            campaigns: (state.campaignSelections && state.campaignSelections.selected && state.campaignSelections.selected.campaignsPublish) ? state.campaignSelections.selected.campaignsPublish : {},
            adsets: (state.campaignSelections && state.campaignSelections.selected && state.campaignSelections.selected.adsetsPublish)? state.campaignSelections.selected.adsetsPublish : {},
            ads: (state.campaignSelections && state.campaignSelections.selected && state.campaignSelections.selected.adsPublish)? state.campaignSelections.selected.adsPublish : {}
        },
        selected_vals: {
            campaignIds: (state.campaignSelections && state.campaignSelections.selected_arr && state.campaignSelections.selected_arr.campaignsPublish) ? state.campaignSelections.selected_arr.campaignsPublish : [],
            adsetIds: (state.campaignSelections && state.campaignSelections.selected_arr && state.campaignSelections.selected_arr.adsetsPublish) ? state.campaignSelections.selected_arr.adsetsPublish : [],
            adIds: (state.campaignSelections && state.campaignSelections.selected_arr && state.campaignSelections.selected_arr.adsPublish) ? state.campaignSelections.selected_arr.adsPublish : []
        }
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSelection: (type, id, cid) => dispatch(campaignSelectionActions.toggleSelection(type, id, cid)),
        toggleAll: (type, obj) => dispatch(campaignSelectionActions.toggleAll(type, obj)),
        publishAll: (data) => dispatch(campaignActions.publishAll(data)),
        toggleAllOff: (type) => dispatch(campaignSelectionActions.toggleAllOff(type))
    };
};

PublishDraftItemsForm = reduxForm({
    form: 'PublishDraftItemsForm',
    enableReinitialize: true
})(PublishDraftItemsForm);

PublishDraftItemsForm = connect(
    mapStateToProps, mapDispatchToProps
)(PublishDraftItemsForm);

export default PublishDraftItemsForm;
