import React, { Component } from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";
import ActionBtns from "./config/actionBtns";
import RecommendationConfigTable from "./config/tableList";
import RecommendationConfigForm from "./config/popupForm";
import DateFilter from "../components/dateFilter";
import _ from "lodash";
import {popupActions, recommendationActions} from "../../actions";
import Popup from "../components/popup";
import Tooltip from "../../components/Tooltips/Tooltip";
import EditDefaultFreshness from './config/editDefaultFreshness';

class Config extends Component {

    constructor(props) {
        super(props);
        this.props.getClassList();
        this.props.getDefaultRecommendationFreshness();
        this.state={
            changeDefaultRecommendation: 0,
        }
    }

    componentDidUpdate(prevProps) {
        // Create Config
        if (this.props.createConfigErr && !_.isEqual(this.props.createConfigErr, prevProps.createConfigErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.createConfigErrEvent();
            this.props.togglePopup(false);
        }
        if (this.props.createConfig && !_.isEqual(this.props.createConfig, prevProps.createConfig)) {
            this.alertMsg('Config Created Successfully', 'fa fa-check', 'success');
            this.props.createConfigEvent();
        }
        // Update Config
        if (this.props.updateConfigErr && !_.isEqual(this.props.updateConfigErr, prevProps.updateConfigErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.updateConfigErrEvent();
            this.props.togglePopup(false);
        }
        if (this.props.updateConfig && !_.isEqual(this.props.updateConfig, prevProps.updateConfig)) {
            this.alertMsg('Config Updated Successfully', 'fa fa-check', 'success');
            this.props.updateConfigEvent();
        }
        // delete
        if (this.props.deleteConfigErr && !_.isEqual(this.props.deleteConfigErr, prevProps.deleteConfigErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.deleteConfigErrEvent();
        }
        if (this.props.deleteConfig && !_.isEqual(this.props.deleteConfig, prevProps.deleteConfig)) {
            this.alertMsg('Config Deleted Successfully', 'fa fa-check', 'success');
            this.props.deleteConfigEvent();
        }
    }

    renderPageFilters = () => {
        return (
            <div className={"cx-page-filters"}>
                <DateFilter />
            </div>
        );
    }

    alertMsg = (msg, icon = 'tim-icons icon-simple-remove', type = 'danger') => {
        this.refs.notificationAlert.notificationAlert({
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }

    render() {
        const defaultFreshness = (this.props.defaultFreshness)? this.props.defaultFreshness : 0;
        return (
            <>
                <Popup title={"AI Config"} width={'35%'}>
                 <RecommendationConfigForm />                
                </Popup>

                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fas fa-bullseye"></i>&nbsp; AI &gt; Config</h4>
                    </div>
                    <div className={"page-reactive-table"}>
                        <Row className={"mx-0"}>
                            <div className={"cx-header-message"}>
                                <i className={"fa fa-info cx-icon-msg"}></i>
                                The default Config freshness value is&nbsp;
                                <Tooltip
                                    key={`defaultFreshness-${defaultFreshness}`}
                                    action={'click'}
                                    text={<EditDefaultFreshness />}
                                >
                                    <a href={"/#"} onClick={e=>{e.preventDefault();}}>
                                        <strong>{defaultFreshness}</strong>&nbsp;day{(defaultFreshness > 1)? 's' : ''}
                                    </a>
                                </Tooltip>
                            </div>
                        </Row>
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns />
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-recommendation-config'}>
                                    <RecommendationConfigTable />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        defaultFreshness: (state.recommendation && state.recommendation.defaultRecommendationFreshness)? state.recommendation.defaultRecommendationFreshness : 0,
        actionType: (state.recommendation && state.recommendation.actionType)? state.recommendation.actionType : 'create',
        configItem: state.recommendation && state.recommendation.configItem,
        createConfigErr: (state.recommendation && state.recommendation.createConfigErr) || null,
        createConfig: (state.recommendation && state.recommendation.createConfig) || null,
        updateConfigErr: (state.recommendation && state.recommendation.updateConfigErr) || null,
        updateConfig: (state.recommendation && state.recommendation.updateConfig) || null,
        deleteConfigErr: (state.recommendation && state.recommendation.deleteConfigErr) || null,
        deleteConfig: (state.recommendation && state.recommendation.deleteConfig) || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDefaultRecommendationFreshness:() => dispatch(recommendationActions.getDefaultFreshnessDays()),
        createConfigEvent:() => dispatch(recommendationActions.createConfigEvent(undefined)),
        createConfigErrEvent: () => dispatch(recommendationActions.createConfigErrEvent(undefined)),
        updateConfigErrEvent: () => dispatch(recommendationActions.updateConfigErrEvent(undefined)),
        updateConfigEvent: () => dispatch(recommendationActions.updateConfigErrEvent(undefined)),
        deleteConfigErrEvent: () => dispatch(recommendationActions.deleteConfigErrEvent(undefined)),
        deleteConfigEvent: () => dispatch(recommendationActions.deleteConfigEvent(undefined)),
        getClassList: () => dispatch(recommendationActions.getRecommendationClassList()),
        togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Config);