import React, { useState } from "react";
import ReactTable from "react-table";
import {FormGroup, Input, Label} from "reactstrap";
import Boolean from "views/components/Boolean.jsx"
import noData from "assets/img/no-data.svg"
import useWidgetStore from "../widgetStore";
import { deleteWidget } from "../widgetAPI";
import { useMutation } from "react-query";
import { CXConfirm } from "CXComponents";


const TableList = ({widgetListQuery}) =>  {

    const [sortingConfig, setSortingConfig] = useState([{ id: 'id', desc: true }]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [widgetsToLoad, setWidgetsToLoad] = useState(null);

    const togglePopup = useWidgetStore(state=>state.togglePopup);
    const setItemToLoad = useWidgetStore(state=>state.setItemToLoad);
    const setActionType = useWidgetStore(state=>state.setActionType);
    const selectedRows = useWidgetStore(state=>state.selectedRows);
    const toggleSelectCheckBox = useWidgetStore(state=>state.toggleSelectCheckBox);
    const pageSize = 10;


    const deleteWidgetQuery = useMutation(widgetID=>{
        return deleteWidget(widgetID);
    });


    const NoData = () => {
        return <img className="cx-no-data-svg" src={noData}/>
    }

    const rowActions = async (action = 'edit', obj) => {
        switch (action) {
            case "delete":
                setIsDeletePopupOpen(true);
                setItemToDelete(obj.id);
                break;
            case 'edit':
                setItemToLoad(obj.id);
                setActionType("edit");
                togglePopup();
                break;
            default: break;
        }
    }

    const tableCols = () => {
        return [
          {
            filterable: false,
            id: "checkbox",
            accessor: "",
            Cell: ({ original }) => {
              return (
                <FormGroup check>
                  <Label check className="d-inline">
                    <Input type="checkbox"
                        checked={selectedRows.includes(original.id)}
                        onChange={() => toggleSelectCheckBox(original.id)}
                    />
                    <span className="form-check-sign" />
                  </Label>
                </FormGroup>
              );
            },
            sortable: false,
            width: 45,
          },
    
          {
            Header: "Widget",
            accessor: "title",
            Cell: ({ original }) => {
              return (
                <div className={"d-flex"}>
                  <div className={"d-flex flex-column"}>
                    <div className={"cx-table-name-styling"}>
                      {" "}
                      <a
                        href={"#"}
                        onClick={(e) => {
                          e.preventDefault();
                          rowActions("edit", original, e);
                        }}
                      >
                        {original.title}
                      </a>
                    </div>
                    <div className={"table-actions-icons"}>
                      <a
                        href={"#"}
                        onClick={(e) => {
                          e.preventDefault();
                          rowActions("edit", original, e);
                        }}
                      >
                        Edit
                      </a>
                      &nbsp;&nbsp;
                      <a
                        href={"#"}
                        onClick={(e) => {
                          e.preventDefault();
                          rowActions("delete", original, e);
                        }}
                      >
                        Delete
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              );
            },
            width: 250,
          },
          // {
          //   Header: "Domain",
          //   accessor: "appdomain",
          //   Cell: ({ original }) => {
          //     return <span>{original.appdomain}</span>;
          //   },
          // },
          {
            Header: "Id",
            accessor: "id",
            Cell: ({ original }) => {
              return <span>{original.id}</span>;
            },
          },
    
          {
            Header: "Size",
            accessor: "slotNumber",
            Cell: ({ original }) => {
              return original["slotNumber"];
            },
          },
          {
            Header: "Is Test",
            accessor: "isTest",
            Cell: ({ original }) => {
              return <Boolean status={original["isTest"]} />;
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter.value === "true") {
                return row.isTest;
              }
              return !row.isTest;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Show All</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            ),
          },
          {
            Header: "Is Online",
            accessor: "online",
            Cell: ({ original }) => {
              return <Boolean status={original["online"]} />;
            },
            filterMethod: (filter, row) => {
              if (filter.value === "all") {
                return true;
              }
              if (filter.value === "true") {
                return row.online;
              }
              return !row.online;
            },
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
              >
                <option value="all">Show All</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            ),
          },
        ];
    }

    return (
        <>      
            <ReactTable
                filterable={true}
                defaultSorting={sortingConfig}
                sortable={true}
                showPageSizeOptions={false}
                minRows={0}
                data={widgetListQuery.data}
                resizable={false}
                columns={tableCols()}
                defaultPageSize={pageSize}
                showPaginationBottom={true}
                className="-highlight cx-reactive-table"
                NoDataComponent={NoData}
            />
            {
              isDeletePopupOpen && 
              <CXConfirm 
                  {...{
                      width: '400px',
                      title: "Delete Widget",
                      subtitle: "Are you sure you want to permanently delete this widget?",
                      confirmText: "Delete",
                      cancelText: "Cancel",
                      confirmAction: async ()=>{
                          setIsDeletePopupOpen(false);
                          await deleteWidgetQuery.mutateAsync([itemToDelete]);
                          await widgetListQuery.refetch();
                      },
                      cancelAction: ()=>{
                          setIsDeletePopupOpen(false);
                      }
                  }} 
              />
            }
            
        </>
    );
}

export default TableList;