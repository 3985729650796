import create from 'zustand';

const useEmailNewsletterStore = create( (set, get) => ({
    isOpen: false,
    setIsOpen: (bool) => set( _state => ({isOpen: bool}) ),

    itemToLoad: null,
    setItemToLoad: (id) => set( _state => ({itemToLoad: id}) ),
    
    actionType: 'create',
    setActionType: (type) => set( _state => ({actionType: type})),
}));


export default useEmailNewsletterStore;



