import React, { useState } from "react";
import ReactTable from "react-table";
import {FormGroup, Input, Label} from "reactstrap";
import Boolean from "../../components/Boolean.jsx"
import noData from "../../../assets/img/no-data.svg"
import usePublisherStore from "./PublisherStore";
import CxLoader from "../../components/cxLoader"
import { fetchList, deletePublisher } from "./publisherAPI";
import { useQuery, useMutation } from "react-query";
import WidgetScripts from "./WidgetScripts";
import { CXConfirm } from "../../../CXComponents";
import PopupNew from "../../components/popup_new"


const TableList = () =>  {

    const [sortingConfig, setSortingConfig] = useState([{ id: 'id', desc: true }]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
    const [isWidgetScriptsOpen, setWidgetScriptsOpen] = useState(false);
    const [widgetsToLoad, setWidgetsToLoad] = useState(null);
    const [domainToLoad, setDomainToLoad] = useState(null);

    const togglePopup = usePublisherStore(state=>state.togglePopup);
    const setItemToLoad = usePublisherStore(state=>state.setItemToLoad);
    const setActionType = usePublisherStore(state=>state.setActionType);
    const selectedRows = usePublisherStore(state=>state.selectedRows);
    const toggleSelectCheckBox = usePublisherStore(state=>state.toggleSelectCheckBox);


    const {isLoading, data, isFetching, refetch} = useQuery(['publisher-list'], fetchList, { refetchOnWindowFocus: false });
    const deletePublisherQuery = useMutation(publisherID=>{
        return deletePublisher(publisherID);
    });

    const pageSize = 10;

    const NoData = () => {
        return <img className="cx-no-data-svg" src={noData}/>
    }

    const rowActions = async (action = 'edit', obj, event) => {
        switch (action) {
            case "delete":
                setIsDeletePopupOpen(true);
                console.log(isDeletePopupOpen)
                setItemToDelete(obj.id);
                break;
            case 'edit':
                setItemToLoad(obj.id);
                setActionType("edit");
                togglePopup();
                break;
            // case "pixel":
            //     if (!obj.script) return;
            //     openScript(obj.script);
            //     break;
            case "widget scripts":
                // if (!obj.widgets) return;
                openWidgetScripts(obj.widgets, obj.domain);
                break;
            default: break;
        }
    }

    const openWidgetScripts = (widgets, domain) => {
        setWidgetScriptsOpen(true);
        setWidgetsToLoad(widgets);
        setDomainToLoad(domain);
    }


    // const openScript = (script) => {
    //     setIsScriptOpen(true);
    //     setScriptToLoad(script);
    // }

    const tableCols = () => {
        return [
            {
                filterable:false,
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <FormGroup check>
                            <Label check className="d-inline">
                                <Input type="checkbox"
                                    checked={selectedRows.includes(original.id)}
                                    onChange={() => toggleSelectCheckBox(original.id)}
                                />
                                <span className="form-check-sign" />
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                width: 45,
            },
            {
                Header: "Publisher",
                accessor: "domain",
                Cell: ({ original }) => {
                    return(
                        <>
                        <div className={'d-flex'}>
                            <div className={'d-flex flex-column'}>
                                <div className={"cx-table-name-styling"}> <a href={'#'} onClick={(e) => { e.preventDefault(); rowActions('edit', original, e) }}>{original.domain}</a></div>
                                <div className={"table-actions-icons"}>
                                    <a href={'#d'} onClick={(e) => { e.preventDefault(); rowActions('edit', original, e) }}>Edit</a>&nbsp;&nbsp;
                                    <a href={'#d'} onClick={(e) => { e.preventDefault(); rowActions('delete', original, e) }}>Delete</a>&nbsp;&nbsp;
                                    {original.script && <a href={'#d'} onClick={(e) => { e.preventDefault(); rowActions('widget scripts', original, e) }}>Widget Scripts</a>}
                                    
                                </div>
                            </div>
                        </div>
                        </>
                    ) 
                }
            }
            ,            
            {
              Header: "Script Version",
              accessor: "scriptVersion",
              Cell: ({ original }) => {
                return <span>{original.scriptVersion || "No Script"}</span>;
              },
            },
            {
              Header: "Floor Cpc",
              accessor: "floorCpc",
              Cell: ({ original }) => {
                return <span>{original.floorCpc}</span>;
              },
            },
            {
              Header: "Freq Cap Per Day",
              accessor: "fCap",
              Cell: ({ original }) => {
                return <span>{original.fCap}</span>;
              },
            },   
            {
                Header: "Has Report?",
                accessor: "reportUrl",
                Cell: ({ original }) => {
                  return <Boolean status={!!original.reportUrl} />;
                },
                filterMethod: (filter, row) => {
                    if (filter.value === "All") {
                        return true;
                    }
                    else if (filter.value === "Yes") {
                    return !!row.reportUrl;
                    }
                    return !row.reportUrl;
                },
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="All">Show All</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                ),
            },         
        ];
    }
   

    const renderTable = () =>
        <ReactTable
            filterable={true}
            defaultSorting={sortingConfig}
            sortable={true}
            showPageSizeOptions={false}
            minRows={0}
            data={data}
            resizable={false}
            columns={tableCols()}
            defaultPageSize={pageSize}
            showPaginationBottom={true}
            className="-highlight cx-reactive-table"
            NoDataComponent={NoData}
        />

    return (
        <>      

            <PopupNew title="Widget Scripts" minWidth="70vw" height="50vh" isPopupOpen={isWidgetScriptsOpen} togglePopup={()=>{setWidgetScriptsOpen(!isWidgetScriptsOpen)}}>
                <WidgetScripts widgets={widgetsToLoad} domain={domainToLoad}/>
            </PopupNew>   
            {
                (isLoading || isFetching)
                ? <CxLoader minHeight="400px"/>
                : renderTable()
            }
            {
                isDeletePopupOpen && 
                <CXConfirm 
                    {...{
                        width: '400px',
                        title: "Delete Publisher",
                        subtitle: "Are you sure you want to permanently delete this publisher?",
                        confirmText: "Delete",
                        cancelText: "Cancel",
                        confirmAction: async ()=>{
                            setIsDeletePopupOpen(false);
                            await deletePublisherQuery.mutateAsync([itemToDelete]);
                            await refetch();
                        },
                        cancelAction: ()=>{
                            setIsDeletePopupOpen(false);
                        }
                    }} 
                />
            }
            
        </>
    );
}

export default TableList;