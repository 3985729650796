import {ACTIVATE_TAB, TABLE_SELECTIONS} from "../constants/appConstant";

export default function (state = {}, action) {
    switch (action.type) {
        case ACTIVATE_TAB:
            return { ...state, activeTab: action.payload };
        case TABLE_SELECTIONS:
            let old = {};
            if(state.tableSelections && state.tableSelections[action.payload.type] && Object.keys(state.tableSelections[action.payload.type]).length){
                old = {...state.tableSelections[action.payload.type], ...action.payload.selected}
            }else{
                old = {...action.payload.selected};
            }
            return { ...state, tableSelections: {...state.tableSelections, [action.payload.type]: old}};
        default:
            return state;
    }
}