import {
    FETCH_REGION_LIST,
    FETCH_COUNTRY_LIST,
    FETCH_LANG_LIST,
    FETCH_REGION_SELECT_OPTIONS,
    FETCH_LOCATION_LIST,
    FETCH_LOCATION_SELECT_OPTIONS
} from "../constants/appConstant";
import api from '../api/countries';

/*
 * action creators
 */
const regionList = (regions) => ({ type: FETCH_REGION_LIST, payload: regions});

const regionMapping = (data) => {
    let regions = [];
    for (let region in data) {
        regions.push({value: region, label: region});
    }
    return regions;
}

const locationMapping = (data) => {
    return data.map(function(country){
        const newObject = {};
        Object.assign(newObject, country, {'value': country['code'] });
        Object.assign(newObject, country, {'label': country['name'] });
        return newObject;
    });
}

export const fetchRegions = () => {
    return (dispatch) => {
        return api.getRegionsCountries()
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data){
                    res = response.data.data;
                }
                dispatch(regionList(res));
            })
            .catch(error => {
                throw(error);
            });
    };
};

const regionOptions = (regions) => ({ type: FETCH_REGION_SELECT_OPTIONS, payload: regions});

export const fetchRegionsSelect = () => {
    return async (dispatch, getState) => {
        // get the regions
        await dispatch(fetchRegions());
        if(getState().location && getState().location.regions){
            dispatch(regionOptions(regionMapping(getState().location.regions)));
        }
    };
};

const countryList = (countries) => ({ type: FETCH_COUNTRY_LIST, payload: countries});

export const fetchCountries = () => {
    return (dispatch) => {
        return api.getCountries()
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(countryList(res))
            })
            .catch(error => {
                throw(error);
            });
    };
};

const languageList = (languages) => ({ type: FETCH_LANG_LIST, payload: languages});

export const fetchLanguages = () => {
    return (dispatch) => {
        return api.getLanguages()
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(languageList(res))
            })
            .catch(error => {
                throw(error);
            });
    };
}

const LocationList = (locations) => ({ type: FETCH_LOCATION_LIST, payload: locations});

const locationOptions = (locations) => ({ type: FETCH_LOCATION_SELECT_OPTIONS, payload: locations});

const fetchLocations = () => {
    return (dispatch) => {
        return api.getCountries()
            .then(response => {
                let res = null;
                if(response && response.data && response.data.data){
                    res = response.data.data;
                }
                dispatch(LocationList(res));
            })
            .catch(error => {
                throw(error);
            });
    };
}

export const fetchLocationsSelect = () => {
    return async (dispatch, getState) => {
        // get the locations
        await dispatch(fetchLocations());
        if(getState().location && getState().location.locations){
            dispatch(locationOptions(locationMapping(getState().location.locations)));
        }
    };
};
