import React from 'react';

const CXPill = ({ title='', background, text, close, className='', onClick, disabled }) => {
    return (
        <span title={title} onClick={onClick} className={`cx-pill ${className} ${disabled?"cx-pill-disabled":""}`}  style={background&&{background}}>
            <span>{text}</span>
            {
                close &&
                <span className="cx-pill__close">
                    <i className="fa fa-times" onClick={close}></i>
                </span>
            }
        </span>
    );
}

export default CXPill;
