import React from "react";
import { connect } from "react-redux";
import { widgetPerformanceActions } from "../../actions";
import { Row } from "reactstrap";
import ReactGA from "react-ga";
import DateFilter from "../components/dateFilter";
import { FilterBar, StartFilters } from "../../components/FilterBar/filterBar";
import AdvancedFilter from "./components/widgetAdvancedFilter";
import DevicePerformance from "./components/deviceGraph";
import TimePerformance from "./components/timeGraph";
import LocationPerformance from "./components/locationGraph";
import TopPostsTable from "./components/topPostsTable";
import TopWidgetsTable from "./components/topWidgetsTable";
import Summary from "./components/summary";

import _ from "lodash";
import mainHelper from "../../helpers/main";

let helpers = new mainHelper();

class WidgetOverview extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.pageview(
      "Insights - Widget Performance",
      null,
      helpers.getUserDetails()
    );
    //this.props.fetchSummary(this.props.pageFilters,this.props.dateFilter);
  }

  renderPageFilters = () => {
    return (
      <div className={"cx-page-filter-container mb-3"}>
        <div className={"justify-content-end d-flex"}>
          <DateFilter className={"mr-3"} />
        </div>
        <FilterBar className={"mt-3 cx-campaign-filter-listing"}>
          <StartFilters>
            <AdvancedFilter />
          </StartFilters>
        </FilterBar>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="content cx-page-widget-performance">
          <div>
            <h4 style={{ marginBottom: "20px" }}>
              <i className="tim-icons icon-notes"></i>&nbsp; Insights {">"}{" "}
              Widget Performance
            </h4>
          </div>
          {this.renderPageFilters()}

          <Summary />

          <Row
            className={"cx-widget-performance-graphs align-items-start mx-0"}
          >
            <div className={"col-sm-6 cx-card-look cx-card-look-left"}>
              <TimePerformance />
            </div>
            <div className={"col-sm-6 cx-card-look cx-card-look-right"}>
              <DevicePerformance />
            </div>
            <div className={"col-sm-6 cx-card-look cx-card-look-left"}>
              <LocationPerformance />
            </div>
            <div className={"col-sm-6 cx-card-look cx-card-look-right"}>
              <TopPostsTable />
            </div>
            <div className={"col-sm-12 cx-card-look"}>
              <TopWidgetsTable />
            </div>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageFilters:
      (state.widgetPerformance && state.widgetPerformance.pageFilters) || null,
    dateFilter: (state.filter && state.filter.date) || null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetOverview);
