import {
    FETCH_ADSET_LIST,
    FETCH_ADSET_ITEM,
    DELETE_ADSET_ITEM,
    UPDATE_ADSET_ITEM,
    DUPLICATE_ADSET_ITEM,
    QUICK_CREATE_ADSET,
    UPDATE_ADSET_STATUS,
    PUBLISH_ADSET,
    PUBLISH_ADSET_ERROR,
    UPDATE_ADSET_ERROR,
    DUPLICATE_ADSET_ERROR,
    DELETE_ADSET_ERROR,
    CREATE_ADSET_ERROR,
    FETCH_IAB

} from "../constants/appConstant";
import adsetsApi from '../api/adsets';
import {SubmissionError} from "redux-form";

const api = new adsetsApi();
/*
 * action creators
 */
const adsetsQuickCreateEvent = (adsets) => ({ type: FETCH_ADSET_LIST, payload: adsets });

const adsetsEvent = (adsets) => ({ type: FETCH_ADSET_LIST, payload: adsets });
const getIABListEvent = (data) => ({ type: FETCH_IAB, payload: data });
export const adsetEvent = (adset) => ({ type: FETCH_ADSET_ITEM, payload: adset });
const adsetUpdateEvent = (adset) => ({ type: UPDATE_ADSET_ITEM, payload: adset });
const adsetDeleteEvent = (adset) => ({ type: DELETE_ADSET_ITEM, payload: adset });
const adsetDuplicateEvent = (adset) => ({ type: DUPLICATE_ADSET_ITEM, payload: adset });
const adsetQuickCreateEvent = (adset) => ({ type: QUICK_CREATE_ADSET, payload: adset });
const activateAdsetEvent = (adset) => ({ type: UPDATE_ADSET_STATUS, payload: adset });
const adsetPublishEvent = (adset) => ({ type: PUBLISH_ADSET, payload: adset });
export const adsetPublishError = (err) => ({ type: PUBLISH_ADSET_ERROR, payload: err });
export const adsetUpdateError = (err) => ({ type: UPDATE_ADSET_ERROR, payload: err });

export const adsetCreateError = (err) => ({ type: CREATE_ADSET_ERROR, payload: err });
export const adsetDuplicateError = (err) => ({ type: DUPLICATE_ADSET_ERROR, payload: err });
export const adsetDeleteError = (err) => ({ type: DELETE_ADSET_ERROR, payload: err });

export const activateAdset = (data) => {
    return (dispatch) => {
        return api.updateAdsetStatus(data)
            .then(response => {
                let res = null;
                if(response && response.data)
                    res = response.data;
               // dispatch(activateAdsetEvent(res))
            })
            .catch(error => {
                throw(error);
            });
    };
}

export const fetchAllAdsets = (queryStr) => {
    return (dispatch) => {
        return api.getAdsetList(queryStr)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.adsets;
                dispatch(adsetsEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const fetchAdset = (id) => {
    return (dispatch) => {
        return api.getAdset(id)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.adset;
                dispatch(adsetEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const getIABList = () => {
    return async (dispatch) => {
        return api.getIABList()
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.categories;
                dispatch(getIABListEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const publishAdset = (item) => {

    function printError(key, msg = 'Error') {
        switch(key){
            case 'categoryList':
                return {audienceCategories: msg};
            case 'deviceList':
                return {device_desktop: msg};
            case 'isStartDateEnabled':
                return {startDate: msg};
            case 'isEndDateEnabled':
                return {endDate: msg};
            case 'schedule':
                return {schedule_sunday: msg};
            default:
                return {[key]: msg};
        }
    }

    return (dispatch) => {
        return api.publishAdset(item)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.adsets)
                    res = response.data.adsets[0];
                dispatch(adsetPublishEvent(res));
                dispatch(adsetPublishError(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data) {
                    const errors = error.response.data.description;
                    dispatch(adsetPublishError(errors));
                    let errorMsgs = { _error: "Publish Adset Failed"};
                    for (let err in errors){
                        let errMsg = printError(err, errors[err]);
                        errorMsgs = {...errMsg, ...errorMsgs};
                    }
                    throw new SubmissionError(errorMsgs);
                }
                // dispatch(adsetPublishError((error.response.data && error.response.data.description) || null));
                // setTimeout(function(){
                //     dispatch(adsetPublishError(undefined));
                // }, 300)
                throw (error);
            });
    };
};

export const updateAdset = (item) => {
    return (dispatch) => {
        return api.updateAdset(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.adset;
                dispatch(adsetUpdateEvent(res))
                dispatch(adsetUpdateError(undefined));
            })
            .catch(error => {
                dispatch(adsetUpdateError(error.response.data));
                throw (error);
            });
    };
};

export const deleteAdset = (item) => {
    return (dispatch) => {
        return api.deleteAdset(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.id;
                //dispatch(adsetDeleteEvent(res))
                dispatch(adsetDeleteError(undefined));
            })
            .catch(error => {
                dispatch(adsetDeleteError('error'));
                throw (error);
            });
    };
};

export const duplicateAdset = (item) => {
    return (dispatch) => {
        return api.duplicateAdset(item)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.adset;
                dispatch(adsetDuplicateEvent(res))
                dispatch(adsetDuplicateError(undefined));
            })
            .catch(error => {
                dispatch(adsetDuplicateError('error'));
                throw (error);
            });
    };
};


export const quickCreateAdset = (data) => {
    return (dispatch) => {
        return api.quickCreateAdset(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.adset;
                dispatch(adsetQuickCreateEvent(res))
                dispatch(adsetCreateError(undefined))
            })
            .catch(error => {
                dispatch(adsetCreateError('error'))
                throw (error);
            });
    };
};
