import {
    FETCH_ALLOCATIONS,
    FETCH_ALLOCATION_ITEM,
    UPDATE_ALLOCATION,
    UPDATE_ALLOCATION_ERROR,
    FETCH_ADS_REVIEW,
    ERR_ACCEPT_ADS_REVIEW,
    REJECT_ADS_REVIEW,
    ERR_REJECT_ADS_REVIEW,
    ACCEPT_ADS_REVIEW
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case FETCH_ALLOCATIONS:
            if(action.typeData == 'not_allocated')
                return { ...state, reviewAdsetList: action.payload };
            return { ...state, allocatedAdsetList: action.payload };
        case FETCH_ADS_REVIEW:
            if (action.typeData == 'pending')
                return { ...state, pendingReviewsAdsList: action.payload };
            else if (action.typeData == 'accepted')
                return { ...state, acceptedReviewsAdsList: action.payload };
            return { ...state, rejectedReviewsAdsList: action.payload };
        case FETCH_ALLOCATION_ITEM:
            return { ...state, allocationAdsetItem: action.payload };
        case UPDATE_ALLOCATION:
            return { ...state, allocationAdsetUpdate: action.payload };
        case UPDATE_ALLOCATION_ERROR:
            return { ...state, allocationAdsetUpdateErr: action.payload };
        case ACCEPT_ADS_REVIEW:
            return { ...state, successAdAccepted: action.payload };
        case ERR_ACCEPT_ADS_REVIEW:
            return { ...state, ReviewAdsAcceptErr: action.payload };
        case REJECT_ADS_REVIEW:
            return { ...state, successAdRejected: action.payload };
        case ERR_REJECT_ADS_REVIEW:
            return { ...state, ReviewAdsRejectErr: action.payload };
        default:
            return state;
    }
}