import {
    ACTIVATE_TAB,
    TABLE_SELECTIONS
} from "../constants/appConstant";

/*
* action creators
* action can be false that is closed or opened that is true
*/
export const activateTab = (tab = 'campaign') => ({ type: ACTIVATE_TAB, payload: tab }); // used for the campaigns page

export const toggleTableSelectionEvent = (type, selected) => ({ type: TABLE_SELECTIONS, payload: {type, selected} });

export const toggleSelection = (type , id) => {
    return (dispatch, getState) => {
        const allSelectedInType = Object.assign({}, getState().tabTable && getState().tabTable.tableSelections && getState().tabTable.tableSelections[type]);
        allSelectedInType[id] = !allSelectedInType[id];
        dispatch(toggleTableSelectionEvent(type, allSelectedInType));
    };
};