import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();
const helper = new main();

const contentAnalysisData = {
    getContentAnalysisTotals: async () => {
        const url = apiUrl + `content-analysis-totals`;
        // return auth.request.post(url, { data: JSON.stringify({ fromDate: "2023-01-01", toDate: "2023-03-06" }) });

        return {
            data: {
                "data": [
                    {
                        "date": "2023-03-30",
                        "visitors": 54,
                        "pageviews": 63,
                        "engaged_minutes": 28,
                        "ROA": 3.9375,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 1544,
                        "pageviews": 1713,
                        "engaged_minutes": 1225,
                        "ROA": 2.9432989690721651,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 28545,
                        "pageviews": 34043,
                        "engaged_minutes": 17070,
                        "ROA": 24.178267045454547,
                        "section": "الرياضي",
                        "published_posts": 45
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 3606,
                        "pageviews": 4459,
                        "engaged_minutes": 2175,
                        "ROA": 5.359375,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 15282,
                        "pageviews": 25164,
                        "engaged_minutes": 11462,
                        "ROA": 18.261248185776488,
                        "section": "الإمارات",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 4260,
                        "pageviews": 6227,
                        "engaged_minutes": 2014,
                        "ROA": 11.404761904761905,
                        "section": "العرب والعالم",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 230,
                        "pageviews": 249,
                        "engaged_minutes": 190,
                        "ROA": 2.6489361702127661,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 10461,
                        "pageviews": 14076,
                        "engaged_minutes": 5907,
                        "ROA": 8.6996291718170582,
                        "section": "الاقتصادي",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 1894,
                        "pageviews": 2129,
                        "engaged_minutes": 1276,
                        "ROA": 1.8119148936170213,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 2421,
                        "pageviews": 3024,
                        "engaged_minutes": 1943,
                        "ROA": 4,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 2770,
                        "pageviews": 3260,
                        "engaged_minutes": 1820,
                        "ROA": 1.9289940828402368,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 2258,
                        "pageviews": 2607,
                        "engaged_minutes": 1580,
                        "ROA": 4.374161073825503,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 35,
                        "pageviews": 39,
                        "engaged_minutes": 23,
                        "ROA": 1.95,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 330,
                        "pageviews": 387,
                        "engaged_minutes": 218,
                        "ROA": 2.1381215469613259,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 9061,
                        "pageviews": 13207,
                        "engaged_minutes": 5865,
                        "ROA": 12.785091965150048,
                        "section": "منوعات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 463,
                        "pageviews": 557,
                        "engaged_minutes": 277,
                        "ROA": 1.3357314148681054,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 272,
                        "pageviews": 320,
                        "engaged_minutes": 281,
                        "ROA": 2.8828828828828827,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 104,
                        "pageviews": 126,
                        "engaged_minutes": 70,
                        "ROA": 2,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 1718,
                        "pageviews": 2234,
                        "engaged_minutes": 955,
                        "ROA": 12.480446927374302,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 6391,
                        "pageviews": 8844,
                        "engaged_minutes": 4272,
                        "ROA": 27.724137931034484,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 44,
                        "pageviews": 49,
                        "engaged_minutes": 20,
                        "ROA": 1.3243243243243243,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 42,
                        "pageviews": 51,
                        "engaged_minutes": 34,
                        "ROA": 1.3421052631578947,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 858,
                        "pageviews": 1019,
                        "engaged_minutes": 773,
                        "ROA": 1.8663003663003663,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 16,
                        "pageviews": 18,
                        "engaged_minutes": 9,
                        "ROA": 1.3846153846153846,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 45,
                        "pageviews": 50,
                        "engaged_minutes": 26,
                        "ROA": 2.0833333333333335,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 217,
                        "pageviews": 262,
                        "engaged_minutes": 131,
                        "ROA": 1.6477987421383649,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 851,
                        "pageviews": 1129,
                        "engaged_minutes": 526,
                        "ROA": 13.938271604938272,
                        "section": "رمضان",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 65,
                        "pageviews": 82,
                        "engaged_minutes": 46,
                        "ROA": 1.7446808510638299,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 2,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 242,
                        "pageviews": 334,
                        "engaged_minutes": 152,
                        "ROA": 6.1851851851851851,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 1.6,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 75,
                        "pageviews": 88,
                        "engaged_minutes": 37,
                        "ROA": 2.0952380952380953,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 6,
                        "ROA": 1.1666666666666667,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 11,
                        "pageviews": 15,
                        "engaged_minutes": 8,
                        "ROA": 2.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 34,
                        "pageviews": 36,
                        "engaged_minutes": 10,
                        "ROA": 7.2,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 10,
                        "ROA": 6.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 29,
                        "pageviews": 31,
                        "engaged_minutes": 17,
                        "ROA": 1.631578947368421,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1986,
                        "pageviews": 2579,
                        "engaged_minutes": 1407,
                        "ROA": 2.6615067079463364,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 20253,
                        "pageviews": 24861,
                        "engaged_minutes": 12593,
                        "ROA": 16.227806788511749,
                        "section": "الرياضي",
                        "published_posts": 36
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 2982,
                        "pageviews": 3819,
                        "engaged_minutes": 1881,
                        "ROA": 4.6516443361753961,
                        "section": "فكر وفن",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1706,
                        "pageviews": 1913,
                        "engaged_minutes": 1394,
                        "ROA": 3.3444055944055946,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 603,
                        "pageviews": 781,
                        "engaged_minutes": 252,
                        "ROA": 4.5144508670520231,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 541,
                        "pageviews": 626,
                        "engaged_minutes": 339,
                        "ROA": 1.3579175704989155,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 2800,
                        "pageviews": 3280,
                        "engaged_minutes": 1791,
                        "ROA": 1.7826086956521738,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 7777,
                        "pageviews": 10886,
                        "engaged_minutes": 5001,
                        "ROA": 6.4874851013110844,
                        "section": "الاقتصادي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 283,
                        "pageviews": 328,
                        "engaged_minutes": 254,
                        "ROA": 2.5826771653543306,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 2241,
                        "pageviews": 2590,
                        "engaged_minutes": 1442,
                        "ROA": 4.163987138263666,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 14033,
                        "pageviews": 25968,
                        "engaged_minutes": 10113,
                        "ROA": 18.495726495726494,
                        "section": "الإمارات",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 125,
                        "pageviews": 154,
                        "engaged_minutes": 80,
                        "ROA": 2.2985074626865671,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 5774,
                        "pageviews": 8351,
                        "engaged_minutes": 3181,
                        "ROA": 15.638576779026216,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 60,
                        "pageviews": 71,
                        "engaged_minutes": 21,
                        "ROA": 1.5777777777777777,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 958,
                        "pageviews": 1187,
                        "engaged_minutes": 813,
                        "ROA": 2.1120996441281137,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 2058,
                        "pageviews": 2363,
                        "engaged_minutes": 1417,
                        "ROA": 1.8919135308246597,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 8326,
                        "pageviews": 12521,
                        "engaged_minutes": 5303,
                        "ROA": 11.249775381850853,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 240,
                        "pageviews": 301,
                        "engaged_minutes": 176,
                        "ROA": 1.6722222222222223,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 44,
                        "pageviews": 49,
                        "engaged_minutes": 26,
                        "ROA": 1.1951219512195121,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 267,
                        "pageviews": 290,
                        "engaged_minutes": 197,
                        "ROA": 2.9591836734693877,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 5866,
                        "pageviews": 8106,
                        "engaged_minutes": 3884,
                        "ROA": 26.664473684210527,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 411,
                        "pageviews": 469,
                        "engaged_minutes": 302,
                        "ROA": 1.9142857142857144,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 88,
                        "pageviews": 92,
                        "engaged_minutes": 63,
                        "ROA": 2.0909090909090908,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1074,
                        "pageviews": 1448,
                        "engaged_minutes": 655,
                        "ROA": 17.445783132530121,
                        "section": "رمضان",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 56,
                        "pageviews": 61,
                        "engaged_minutes": 31,
                        "ROA": 1.2448979591836735,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 16,
                        "pageviews": 20,
                        "engaged_minutes": 12,
                        "ROA": 1.6666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 108,
                        "pageviews": 124,
                        "engaged_minutes": 56,
                        "ROA": 10.333333333333334,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 18,
                        "pageviews": 19,
                        "engaged_minutes": 14,
                        "ROA": 1.1875,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 36,
                        "pageviews": 39,
                        "engaged_minutes": 17,
                        "ROA": 1.5,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 65,
                        "pageviews": 70,
                        "engaged_minutes": 52,
                        "ROA": 2.5,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 233,
                        "pageviews": 309,
                        "engaged_minutes": 126,
                        "ROA": 7.1860465116279073,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 8,
                        "pageviews": 12,
                        "engaged_minutes": 7,
                        "ROA": 4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1.6,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1949,
                        "pageviews": 2199,
                        "engaged_minutes": 1243,
                        "ROA": 1.8128606760098929,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 9786,
                        "pageviews": 13945,
                        "engaged_minutes": 5936,
                        "ROA": 8.222287735849056,
                        "section": "الاقتصادي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 2788,
                        "pageviews": 3272,
                        "engaged_minutes": 1741,
                        "ROA": 1.7600860677783754,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 3202,
                        "pageviews": 4680,
                        "engaged_minutes": 2117,
                        "ROA": 9.0522243713733079,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 2169,
                        "pageviews": 2804,
                        "engaged_minutes": 1783,
                        "ROA": 3.3341260404280617,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 21,
                        "pageviews": 28,
                        "engaged_minutes": 6,
                        "ROA": 2,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 58,
                        "pageviews": 65,
                        "engaged_minutes": 23,
                        "ROA": 1.7105263157894737,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 10766,
                        "pageviews": 16913,
                        "engaged_minutes": 7801,
                        "ROA": 12.150143678160919,
                        "section": "الإمارات",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 8173,
                        "pageviews": 11552,
                        "engaged_minutes": 5178,
                        "ROA": 11.033428844317097,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 230,
                        "pageviews": 247,
                        "engaged_minutes": 170,
                        "ROA": 2.5204081632653059,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1090,
                        "pageviews": 1398,
                        "engaged_minutes": 593,
                        "ROA": 17.475,
                        "section": "رمضان",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 16758,
                        "pageviews": 20499,
                        "engaged_minutes": 10528,
                        "ROA": 14.146997929606625,
                        "section": "الرياضي",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 260,
                        "pageviews": 291,
                        "engaged_minutes": 236,
                        "ROA": 2.346774193548387,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 5424,
                        "pageviews": 7398,
                        "engaged_minutes": 3617,
                        "ROA": 22.975155279503106,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 166,
                        "pageviews": 247,
                        "engaged_minutes": 71,
                        "ROA": 4.8431372549019605,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 26,
                        "pageviews": 28,
                        "engaged_minutes": 10,
                        "ROA": 1.1666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1624,
                        "pageviews": 1837,
                        "engaged_minutes": 1289,
                        "ROA": 3.3521897810218979,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 470,
                        "pageviews": 574,
                        "engaged_minutes": 246,
                        "ROA": 1.4034229828850855,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 2031,
                        "pageviews": 2347,
                        "engaged_minutes": 1382,
                        "ROA": 3.9645270270270272,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 868,
                        "pageviews": 1044,
                        "engaged_minutes": 764,
                        "ROA": 2.0271844660194174,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 3752,
                        "pageviews": 4789,
                        "engaged_minutes": 2489,
                        "ROA": 6.0314861460957179,
                        "section": "فكر وفن",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 746,
                        "pageviews": 961,
                        "engaged_minutes": 328,
                        "ROA": 5.7202380952380949,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 124,
                        "pageviews": 142,
                        "engaged_minutes": 68,
                        "ROA": 2.0285714285714285,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 403,
                        "pageviews": 461,
                        "engaged_minutes": 303,
                        "ROA": 2.3520408163265305,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 51,
                        "pageviews": 56,
                        "engaged_minutes": 38,
                        "ROA": 2.6666666666666665,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 198,
                        "pageviews": 236,
                        "engaged_minutes": 145,
                        "ROA": 1.6275862068965516,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 57,
                        "pageviews": 67,
                        "engaged_minutes": 29,
                        "ROA": 1.3137254901960784,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 18,
                        "pageviews": 20,
                        "engaged_minutes": 6,
                        "ROA": 1.1764705882352942,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 20,
                        "pageviews": 23,
                        "engaged_minutes": 11,
                        "ROA": 1.2777777777777777,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 83,
                        "pageviews": 91,
                        "engaged_minutes": 67,
                        "ROA": 2.2195121951219514,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 2.25,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 49,
                        "pageviews": 53,
                        "engaged_minutes": 28,
                        "ROA": 1.7096774193548387,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 49,
                        "pageviews": 54,
                        "engaged_minutes": 38,
                        "ROA": 1.2,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 5,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.75,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 4,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2053,
                        "pageviews": 2366,
                        "engaged_minutes": 1356,
                        "ROA": 4.180212014134276,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 8670,
                        "pageviews": 12670,
                        "engaged_minutes": 5423,
                        "ROA": 12.170989433237272,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 10159,
                        "pageviews": 14682,
                        "engaged_minutes": 6953,
                        "ROA": 8.71852731591449,
                        "section": "الاقتصادي",
                        "published_posts": 63
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1923,
                        "pageviews": 2566,
                        "engaged_minutes": 1614,
                        "ROA": 3.1216545012165451,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 8295,
                        "pageviews": 14038,
                        "engaged_minutes": 5884,
                        "ROA": 10.413946587537092,
                        "section": "الإمارات",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 4403,
                        "pageviews": 5654,
                        "engaged_minutes": 2631,
                        "ROA": 6.7631578947368425,
                        "section": "فكر وفن",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 22,
                        "pageviews": 25,
                        "engaged_minutes": 12,
                        "ROA": 1.1904761904761905,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 929,
                        "pageviews": 1139,
                        "engaged_minutes": 802,
                        "ROA": 2.0522522522522522,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1746,
                        "pageviews": 1951,
                        "engaged_minutes": 1401,
                        "ROA": 3.2194719471947195,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 4187,
                        "pageviews": 5804,
                        "engaged_minutes": 2474,
                        "ROA": 10.129144851657941,
                        "section": "العرب والعالم",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 18834,
                        "pageviews": 22884,
                        "engaged_minutes": 11915,
                        "ROA": 15.286573146292586,
                        "section": "الرياضي",
                        "published_posts": 46
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1967,
                        "pageviews": 2264,
                        "engaged_minutes": 1292,
                        "ROA": 1.9009235936188078,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 95,
                        "pageviews": 109,
                        "engaged_minutes": 60,
                        "ROA": 1.7301587301587302,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2696,
                        "pageviews": 3144,
                        "engaged_minutes": 1798,
                        "ROA": 1.8680926916221035,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 4729,
                        "pageviews": 6394,
                        "engaged_minutes": 3377,
                        "ROA": 20.363057324840764,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 261,
                        "pageviews": 308,
                        "engaged_minutes": 226,
                        "ROA": 2.5882352941176472,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 15,
                        "pageviews": 17,
                        "engaged_minutes": 4,
                        "ROA": 1.2142857142857142,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 474,
                        "pageviews": 588,
                        "engaged_minutes": 252,
                        "ROA": 1.4482758620689655,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 201,
                        "pageviews": 242,
                        "engaged_minutes": 137,
                        "ROA": 1.6805555555555556,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 425,
                        "pageviews": 489,
                        "engaged_minutes": 313,
                        "ROA": 2.1733333333333333,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 52,
                        "pageviews": 61,
                        "engaged_minutes": 32,
                        "ROA": 1.326086956521739,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 76,
                        "pageviews": 83,
                        "engaged_minutes": 62,
                        "ROA": 2.1842105263157894,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 248,
                        "pageviews": 269,
                        "engaged_minutes": 181,
                        "ROA": 2.89247311827957,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 44,
                        "pageviews": 44,
                        "engaged_minutes": 39,
                        "ROA": 2.3157894736842106,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1897,
                        "pageviews": 2418,
                        "engaged_minutes": 997,
                        "ROA": 32.675675675675677,
                        "section": "رمضان",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1334,
                        "pageviews": 1738,
                        "engaged_minutes": 501,
                        "ROA": 8.69,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 73,
                        "pageviews": 85,
                        "engaged_minutes": 27,
                        "ROA": 2.1794871794871793,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 160,
                        "pageviews": 242,
                        "engaged_minutes": 68,
                        "ROA": 4.7450980392156863,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 30,
                        "pageviews": 35,
                        "engaged_minutes": 11,
                        "ROA": 2.0588235294117645,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 41,
                        "pageviews": 45,
                        "engaged_minutes": 22,
                        "ROA": 1.7307692307692308,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 9,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 53,
                        "pageviews": 60,
                        "engaged_minutes": 35,
                        "ROA": 1.2765957446808511,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 13,
                        "pageviews": 14,
                        "engaged_minutes": 4,
                        "ROA": 1.5555555555555556,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 2.3333333333333335,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2438,
                        "pageviews": 2823,
                        "engaged_minutes": 1531,
                        "ROA": 1.8794940079893476,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 32,
                        "pageviews": 34,
                        "engaged_minutes": 22,
                        "ROA": 1.8888888888888888,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 1794,
                        "pageviews": 2021,
                        "engaged_minutes": 1422,
                        "ROA": 3.3571428571428572,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2929,
                        "pageviews": 3641,
                        "engaged_minutes": 1624,
                        "ROA": 4.2835294117647056,
                        "section": "فكر وفن",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 8596,
                        "pageviews": 12272,
                        "engaged_minutes": 4930,
                        "ROA": 11.321033210332104,
                        "section": "منوعات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 4893,
                        "pageviews": 6855,
                        "engaged_minutes": 2396,
                        "ROA": 12.554945054945055,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 247,
                        "pageviews": 277,
                        "engaged_minutes": 200,
                        "ROA": 2.8265306122448979,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 17372,
                        "pageviews": 21610,
                        "engaged_minutes": 10253,
                        "ROA": 14.71068754254595,
                        "section": "الرياضي",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 1701,
                        "pageviews": 2331,
                        "engaged_minutes": 1076,
                        "ROA": 34.791044776119406,
                        "section": "رمضان",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 281,
                        "pageviews": 309,
                        "engaged_minutes": 247,
                        "ROA": 2.7105263157894739,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 1989,
                        "pageviews": 2265,
                        "engaged_minutes": 1270,
                        "ROA": 1.812,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2130,
                        "pageviews": 2402,
                        "engaged_minutes": 1433,
                        "ROA": 3.9571663920922568,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 72,
                        "pageviews": 76,
                        "engaged_minutes": 20,
                        "ROA": 2.1714285714285713,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 201,
                        "pageviews": 276,
                        "engaged_minutes": 87,
                        "ROA": 6.1333333333333337,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 8340,
                        "pageviews": 13619,
                        "engaged_minutes": 4799,
                        "ROA": 12.062887511071745,
                        "section": "الإمارات",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 4528,
                        "pageviews": 6150,
                        "engaged_minutes": 2984,
                        "ROA": 19.21875,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 250,
                        "pageviews": 280,
                        "engaged_minutes": 131,
                        "ROA": 1.4070351758793971,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2182,
                        "pageviews": 2927,
                        "engaged_minutes": 1578,
                        "ROA": 3.3337129840546695,
                        "section": "اتجاهات",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 532,
                        "pageviews": 622,
                        "engaged_minutes": 323,
                        "ROA": 1.3177966101694916,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 5360,
                        "pageviews": 6981,
                        "engaged_minutes": 2601,
                        "ROA": 4.6571047364909939,
                        "section": "الاقتصادي",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 67,
                        "pageviews": 69,
                        "engaged_minutes": 56,
                        "ROA": 2.6538461538461537,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 96,
                        "pageviews": 106,
                        "engaged_minutes": 41,
                        "ROA": 1.2325581395348837,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 1430,
                        "pageviews": 2025,
                        "engaged_minutes": 548,
                        "ROA": 11.312849162011172,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 54,
                        "pageviews": 57,
                        "engaged_minutes": 17,
                        "ROA": 1.2666666666666666,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 908,
                        "pageviews": 1081,
                        "engaged_minutes": 670,
                        "ROA": 1.8964912280701753,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 421,
                        "pageviews": 465,
                        "engaged_minutes": 303,
                        "ROA": 2.1933962264150941,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 26,
                        "pageviews": 30,
                        "engaged_minutes": 12,
                        "ROA": 1.1538461538461537,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 119,
                        "pageviews": 147,
                        "engaged_minutes": 93,
                        "ROA": 2.2615384615384615,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 5,
                        "pageviews": 7,
                        "engaged_minutes": 5,
                        "ROA": 1.4,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 77,
                        "pageviews": 87,
                        "engaged_minutes": 56,
                        "ROA": 2.4166666666666665,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.1666666666666667,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 6,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 64,
                        "pageviews": 65,
                        "engaged_minutes": 45,
                        "ROA": 1.25,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 7,
                        "ROA": 1.2222222222222223,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 21,
                        "pageviews": 26,
                        "engaged_minutes": 9,
                        "ROA": 1.5294117647058822,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 1531,
                        "pageviews": 1945,
                        "engaged_minutes": 874,
                        "ROA": 4.1918103448275863,
                        "section": "فكر وفن",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 972,
                        "pageviews": 1080,
                        "engaged_minutes": 628,
                        "ROA": 3.1858407079646018,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 6005,
                        "pageviews": 9153,
                        "engaged_minutes": 3571,
                        "ROA": 11.244471744471744,
                        "section": "منوعات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 8534,
                        "pageviews": 11998,
                        "engaged_minutes": 5072,
                        "ROA": 11.350993377483444,
                        "section": "الرياضي",
                        "published_posts": 60
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 4157,
                        "pageviews": 5343,
                        "engaged_minutes": 1825,
                        "ROA": 5.94988864142539,
                        "section": "الاقتصادي",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 274,
                        "pageviews": 321,
                        "engaged_minutes": 139,
                        "ROA": 1.3155737704918034,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 892,
                        "pageviews": 1448,
                        "engaged_minutes": 698,
                        "ROA": 2.9076305220883536,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 1249,
                        "pageviews": 1416,
                        "engaged_minutes": 696,
                        "ROA": 1.6182857142857143,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 136,
                        "pageviews": 180,
                        "engaged_minutes": 49,
                        "ROA": 5.1428571428571432,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 6700,
                        "pageviews": 9896,
                        "engaged_minutes": 3481,
                        "ROA": 11.766944114149821,
                        "section": "الإمارات",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 2,
                        "ROA": 1.1428571428571428,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 3894,
                        "pageviews": 5498,
                        "engaged_minutes": 2039,
                        "ROA": 11.874730021598273,
                        "section": "العرب والعالم",
                        "published_posts": 30
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 3795,
                        "pageviews": 5354,
                        "engaged_minutes": 2591,
                        "ROA": 19.683823529411764,
                        "section": " الصحي",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 367,
                        "pageviews": 485,
                        "engaged_minutes": 174,
                        "ROA": 3.9430894308943087,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 1457,
                        "pageviews": 1903,
                        "engaged_minutes": 710,
                        "ROA": 45.30952380952381,
                        "section": "رمضان",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 941,
                        "pageviews": 1056,
                        "engaged_minutes": 561,
                        "ROA": 1.5690936106983655,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 844,
                        "pageviews": 948,
                        "engaged_minutes": 669,
                        "ROA": 2.700854700854701,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 132,
                        "pageviews": 147,
                        "engaged_minutes": 93,
                        "ROA": 2.0416666666666665,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 42,
                        "pageviews": 50,
                        "engaged_minutes": 21,
                        "ROA": 1.6129032258064515,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 17,
                        "pageviews": 18,
                        "engaged_minutes": 12,
                        "ROA": 1.6363636363636365,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 323,
                        "pageviews": 338,
                        "engaged_minutes": 137,
                        "ROA": 1.8074866310160427,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 592,
                        "pageviews": 680,
                        "engaged_minutes": 367,
                        "ROA": 1.5887850467289719,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 125,
                        "pageviews": 152,
                        "engaged_minutes": 136,
                        "ROA": 2.375,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 82,
                        "pageviews": 100,
                        "engaged_minutes": 58,
                        "ROA": 1.36986301369863,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 75,
                        "pageviews": 80,
                        "engaged_minutes": 16,
                        "ROA": 1.1764705882352942,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 41,
                        "pageviews": 45,
                        "engaged_minutes": 20,
                        "ROA": 1.8,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 17,
                        "pageviews": 20,
                        "engaged_minutes": 8,
                        "ROA": 1.6666666666666667,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 13,
                        "pageviews": 17,
                        "engaged_minutes": 7,
                        "ROA": 1.8888888888888888,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 1,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 4,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1.1666666666666667,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 599,
                        "pageviews": 780,
                        "engaged_minutes": 263,
                        "ROA": 4.2622950819672134,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 2997,
                        "pageviews": 3765,
                        "engaged_minutes": 1798,
                        "ROA": 5.2805049088359048,
                        "section": "فكر وفن",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 12027,
                        "pageviews": 15943,
                        "engaged_minutes": 6156,
                        "ROA": 11.552898550724638,
                        "section": "الرياضي",
                        "published_posts": 62
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 13347,
                        "pageviews": 16689,
                        "engaged_minutes": 3779,
                        "ROA": 8.4629817444219064,
                        "section": "الاقتصادي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 424,
                        "pageviews": 484,
                        "engaged_minutes": 219,
                        "ROA": 1.3045822102425877,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 2215,
                        "pageviews": 2723,
                        "engaged_minutes": 1672,
                        "ROA": 3.8190743338008417,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 2253,
                        "pageviews": 2590,
                        "engaged_minutes": 1410,
                        "ROA": 4.6499102333931779,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 13,
                        "pageviews": 15,
                        "engaged_minutes": 8,
                        "ROA": 1.3636363636363635,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 8977,
                        "pageviews": 13028,
                        "engaged_minutes": 6079,
                        "ROA": 12.187090739008418,
                        "section": "منوعات",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 5383,
                        "pageviews": 7388,
                        "engaged_minutes": 3587,
                        "ROA": 23.603833865814696,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 949,
                        "pageviews": 1221,
                        "engaged_minutes": 498,
                        "ROA": 10.347457627118644,
                        "section": "رمضان",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 79,
                        "pageviews": 84,
                        "engaged_minutes": 65,
                        "ROA": 2,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 1629,
                        "pageviews": 1865,
                        "engaged_minutes": 1074,
                        "ROA": 1.9089048106448312,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 2035,
                        "pageviews": 2386,
                        "engaged_minutes": 1264,
                        "ROA": 1.8496124031007752,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 33,
                        "pageviews": 40,
                        "engaged_minutes": 18,
                        "ROA": 2.1052631578947367,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 9,
                        "ROA": 1.1428571428571428,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 17923,
                        "pageviews": 28174,
                        "engaged_minutes": 8735,
                        "ROA": 23.815722738799661,
                        "section": "الإمارات",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 1506,
                        "pageviews": 1648,
                        "engaged_minutes": 1225,
                        "ROA": 3.4333333333333331,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 4072,
                        "pageviews": 5677,
                        "engaged_minutes": 1863,
                        "ROA": 11.515212981744423,
                        "section": "العرب والعالم",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 281,
                        "pageviews": 317,
                        "engaged_minutes": 281,
                        "ROA": 2.855855855855856,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 197,
                        "pageviews": 220,
                        "engaged_minutes": 163,
                        "ROA": 2.7160493827160495,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 64,
                        "pageviews": 65,
                        "engaged_minutes": 8,
                        "ROA": 1.1607142857142858,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 742,
                        "pageviews": 901,
                        "engaged_minutes": 592,
                        "ROA": 1.8928571428571428,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 376,
                        "pageviews": 423,
                        "engaged_minutes": 260,
                        "ROA": 2.35,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 93,
                        "pageviews": 98,
                        "engaged_minutes": 27,
                        "ROA": 1.2098765432098766,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 172,
                        "pageviews": 203,
                        "engaged_minutes": 121,
                        "ROA": 1.5859375,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 40,
                        "pageviews": 54,
                        "engaged_minutes": 12,
                        "ROA": 1.5428571428571429,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 65,
                        "pageviews": 67,
                        "engaged_minutes": 37,
                        "ROA": 1.5227272727272727,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 90,
                        "pageviews": 103,
                        "engaged_minutes": 55,
                        "ROA": 1.6612903225806452,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 164,
                        "pageviews": 239,
                        "engaged_minutes": 76,
                        "ROA": 5.3111111111111109,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 28,
                        "pageviews": 34,
                        "engaged_minutes": 21,
                        "ROA": 1.7894736842105263,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 36,
                        "pageviews": 40,
                        "engaged_minutes": 25,
                        "ROA": 1.7391304347826086,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 717,
                        "pageviews": 933,
                        "engaged_minutes": 338,
                        "ROA": 5.0983606557377046,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 54,
                        "pageviews": 59,
                        "engaged_minutes": 16,
                        "ROA": 1.5526315789473684,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 35709,
                        "pageviews": 52295,
                        "engaged_minutes": 23048,
                        "ROA": 35.430216802168019,
                        "section": "الإمارات",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 10784,
                        "pageviews": 15791,
                        "engaged_minutes": 7865,
                        "ROA": 14.111706881143878,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4887,
                        "pageviews": 5893,
                        "engaged_minutes": 3343,
                        "ROA": 8.253501400560225,
                        "section": "فكر وفن",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 197,
                        "pageviews": 214,
                        "engaged_minutes": 155,
                        "ROA": 2.5176470588235293,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 14156,
                        "pageviews": 18025,
                        "engaged_minutes": 6741,
                        "ROA": 12.967625899280575,
                        "section": "الرياضي",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 1678,
                        "pageviews": 1919,
                        "engaged_minutes": 1097,
                        "ROA": 1.7968164794007491,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 284,
                        "pageviews": 326,
                        "engaged_minutes": 295,
                        "ROA": 3.2277227722772279,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 2612,
                        "pageviews": 2949,
                        "engaged_minutes": 1486,
                        "ROA": 5.2010582010582009,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 1472,
                        "pageviews": 1614,
                        "engaged_minutes": 1152,
                        "ROA": 3.0395480225988702,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 17,
                        "pageviews": 19,
                        "engaged_minutes": 5,
                        "ROA": 1.1875,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 5499,
                        "pageviews": 7601,
                        "engaged_minutes": 2644,
                        "ROA": 13.972426470588236,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 7267,
                        "pageviews": 10222,
                        "engaged_minutes": 4055,
                        "ROA": 6.9442934782608692,
                        "section": "الاقتصادي",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 1655,
                        "pageviews": 2204,
                        "engaged_minutes": 1333,
                        "ROA": 3.1530758226037197,
                        "section": "اتجاهات",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 562,
                        "pageviews": 691,
                        "engaged_minutes": 345,
                        "ROA": 11.711864406779661,
                        "section": "رمضان",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 2294,
                        "pageviews": 2875,
                        "engaged_minutes": 1448,
                        "ROA": 1.6133557800224467,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 188,
                        "pageviews": 264,
                        "engaged_minutes": 85,
                        "ROA": 5.7391304347826084,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 397,
                        "pageviews": 459,
                        "engaged_minutes": 199,
                        "ROA": 1.282122905027933,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 7891,
                        "pageviews": 11198,
                        "engaged_minutes": 5806,
                        "ROA": 34.140243902439025,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 407,
                        "pageviews": 467,
                        "engaged_minutes": 338,
                        "ROA": 2.6089385474860336,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 44,
                        "pageviews": 49,
                        "engaged_minutes": 30,
                        "ROA": 1.1951219512195121,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 165,
                        "pageviews": 200,
                        "engaged_minutes": 102,
                        "ROA": 1.6260162601626016,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 775,
                        "pageviews": 926,
                        "engaged_minutes": 652,
                        "ROA": 1.9577167019027484,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 90,
                        "pageviews": 106,
                        "engaged_minutes": 54,
                        "ROA": 1.7966101694915255,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 47,
                        "pageviews": 58,
                        "engaged_minutes": 37,
                        "ROA": 1.3809523809523809,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 14,
                        "pageviews": 18,
                        "engaged_minutes": 6,
                        "ROA": 1.3846153846153846,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 37,
                        "pageviews": 40,
                        "engaged_minutes": 16,
                        "ROA": 1.8181818181818181,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 27,
                        "pageviews": 28,
                        "engaged_minutes": 19,
                        "ROA": 2.8,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 69,
                        "pageviews": 72,
                        "engaged_minutes": 34,
                        "ROA": 4.5,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 26,
                        "pageviews": 26,
                        "engaged_minutes": 9,
                        "ROA": 1.8571428571428572,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 80,
                        "pageviews": 91,
                        "engaged_minutes": 61,
                        "ROA": 2.275,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 4,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 7635,
                        "pageviews": 10698,
                        "engaged_minutes": 4256,
                        "ROA": 6.8532991672005128,
                        "section": "الاقتصادي",
                        "published_posts": 66
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1467,
                        "pageviews": 1680,
                        "engaged_minutes": 1211,
                        "ROA": 3.1638418079096047,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 949,
                        "pageviews": 1257,
                        "engaged_minutes": 516,
                        "ROA": 7.022346368715084,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 6599,
                        "pageviews": 7690,
                        "engaged_minutes": 4861,
                        "ROA": 9.6851385390428213,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 26,
                        "pageviews": 35,
                        "engaged_minutes": 14,
                        "ROA": 1.8421052631578947,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 15797,
                        "pageviews": 24384,
                        "engaged_minutes": 8795,
                        "ROA": 16.169761273209549,
                        "section": "الإمارات",
                        "published_posts": 90
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 11314,
                        "pageviews": 16466,
                        "engaged_minutes": 7629,
                        "ROA": 15.33147113594041,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 15155,
                        "pageviews": 19675,
                        "engaged_minutes": 7548,
                        "ROA": 13.816713483146067,
                        "section": "الرياضي",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 2573,
                        "pageviews": 3107,
                        "engaged_minutes": 1525,
                        "ROA": 1.644785600847009,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1610,
                        "pageviews": 2188,
                        "engaged_minutes": 1114,
                        "ROA": 2.980926430517711,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 523,
                        "pageviews": 605,
                        "engaged_minutes": 222,
                        "ROA": 1.3209606986899562,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1921,
                        "pageviews": 2164,
                        "engaged_minutes": 1365,
                        "ROA": 1.9981532779316713,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 84,
                        "pageviews": 89,
                        "engaged_minutes": 75,
                        "ROA": 2.4722222222222223,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 2265,
                        "pageviews": 2579,
                        "engaged_minutes": 1232,
                        "ROA": 4.7670979667282811,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 4182,
                        "pageviews": 5932,
                        "engaged_minutes": 1965,
                        "ROA": 11.005565862708719,
                        "section": "العرب والعالم",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 206,
                        "pageviews": 236,
                        "engaged_minutes": 131,
                        "ROA": 1.5526315789473684,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 765,
                        "pageviews": 929,
                        "engaged_minutes": 625,
                        "ROA": 2.015184381778742,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 6177,
                        "pageviews": 8567,
                        "engaged_minutes": 3978,
                        "ROA": 26.039513677811549,
                        "section": " الصحي",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 440,
                        "pageviews": 494,
                        "engaged_minutes": 348,
                        "ROA": 2.6137566137566139,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 81,
                        "pageviews": 91,
                        "engaged_minutes": 41,
                        "ROA": 1.6545454545454545,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 42,
                        "pageviews": 47,
                        "engaged_minutes": 18,
                        "ROA": 1.8076923076923077,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 412,
                        "pageviews": 459,
                        "engaged_minutes": 211,
                        "ROA": 12.405405405405405,
                        "section": "رمضان",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 317,
                        "pageviews": 354,
                        "engaged_minutes": 313,
                        "ROA": 2.9747899159663866,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 203,
                        "pageviews": 219,
                        "engaged_minutes": 146,
                        "ROA": 2.3548387096774195,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 38,
                        "pageviews": 47,
                        "engaged_minutes": 25,
                        "ROA": 1.2051282051282051,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 32,
                        "pageviews": 33,
                        "engaged_minutes": 24,
                        "ROA": 1.9411764705882353,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 3,
                        "ROA": 1.3,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 77,
                        "pageviews": 84,
                        "engaged_minutes": 46,
                        "ROA": 3.8181818181818183,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 225,
                        "pageviews": 313,
                        "engaged_minutes": 108,
                        "ROA": 6.520833333333333,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 4,
                        "ROA": 1.25,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 53,
                        "pageviews": 64,
                        "engaged_minutes": 20,
                        "ROA": 1.7777777777777777,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 26,
                        "pageviews": 28,
                        "engaged_minutes": 7,
                        "ROA": 4,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 5,
                        "pageviews": 7,
                        "engaged_minutes": 2,
                        "ROA": 3.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 7006,
                        "pageviews": 10551,
                        "engaged_minutes": 4265,
                        "ROA": 4.6316944688323094,
                        "section": "الاقتصادي",
                        "published_posts": 64
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 8252,
                        "pageviews": 12017,
                        "engaged_minutes": 5128,
                        "ROA": 10.315021459227468,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 523,
                        "pageviews": 666,
                        "engaged_minutes": 298,
                        "ROA": 1.4478260869565218,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2960,
                        "pageviews": 3736,
                        "engaged_minutes": 1839,
                        "ROA": 4.3190751445086706,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 3375,
                        "pageviews": 4805,
                        "engaged_minutes": 1605,
                        "ROA": 8.415061295971979,
                        "section": "العرب والعالم",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 15308,
                        "pageviews": 25123,
                        "engaged_minutes": 8753,
                        "ROA": 16.292477302204929,
                        "section": "الإمارات",
                        "published_posts": 73
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2024,
                        "pageviews": 2792,
                        "engaged_minutes": 1407,
                        "ROA": 3.339712918660287,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 301,
                        "pageviews": 358,
                        "engaged_minutes": 288,
                        "ROA": 2.9833333333333334,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1567,
                        "pageviews": 1763,
                        "engaged_minutes": 1201,
                        "ROA": 3.193840579710145,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 26454,
                        "pageviews": 32023,
                        "engaged_minutes": 12735,
                        "ROA": 21.814032697547685,
                        "section": "الرياضي",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 2,
                        "ROA": 1.5714285714285714,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2128,
                        "pageviews": 2398,
                        "engaged_minutes": 1492,
                        "ROA": 2.0304826418289585,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1595,
                        "pageviews": 1826,
                        "engaged_minutes": 935,
                        "ROA": 3.4452830188679244,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1782,
                        "pageviews": 2259,
                        "engaged_minutes": 956,
                        "ROA": 12.344262295081966,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2601,
                        "pageviews": 3128,
                        "engaged_minutes": 1675,
                        "ROA": 1.6532769556025371,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 130,
                        "pageviews": 153,
                        "engaged_minutes": 79,
                        "ROA": 2.283582089552239,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 4902,
                        "pageviews": 6706,
                        "engaged_minutes": 3248,
                        "ROA": 21.021943573667713,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 241,
                        "pageviews": 293,
                        "engaged_minutes": 166,
                        "ROA": 1.8544303797468353,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 47,
                        "pageviews": 49,
                        "engaged_minutes": 34,
                        "ROA": 2.0416666666666665,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 58,
                        "pageviews": 61,
                        "engaged_minutes": 22,
                        "ROA": 1.1296296296296295,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 221,
                        "pageviews": 248,
                        "engaged_minutes": 160,
                        "ROA": 2.6382978723404253,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 64,
                        "pageviews": 75,
                        "engaged_minutes": 30,
                        "ROA": 1.25,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 891,
                        "pageviews": 1086,
                        "engaged_minutes": 712,
                        "ROA": 2,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 351,
                        "pageviews": 415,
                        "engaged_minutes": 262,
                        "ROA": 2.1065989847715736,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 242,
                        "pageviews": 327,
                        "engaged_minutes": 109,
                        "ROA": 7.7857142857142856,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 60,
                        "pageviews": 66,
                        "engaged_minutes": 53,
                        "ROA": 1.8857142857142857,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 3,
                        "ROA": 1.5,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 106,
                        "pageviews": 123,
                        "engaged_minutes": 77,
                        "ROA": 3.5142857142857142,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 49,
                        "pageviews": 60,
                        "engaged_minutes": 29,
                        "ROA": 1.3043478260869565,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 38,
                        "pageviews": 45,
                        "engaged_minutes": 13,
                        "ROA": 1.9565217391304348,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1.3333333333333333,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 2,
                        "ROA": 1.6,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 3,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 19,
                        "pageviews": 19,
                        "engaged_minutes": 4,
                        "ROA": 1.4615384615384615,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 12,
                        "pageviews": 18,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 30,
                        "pageviews": 30,
                        "engaged_minutes": 13,
                        "ROA": 1.3043478260869565,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 6,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 2919,
                        "pageviews": 3453,
                        "engaged_minutes": 1833,
                        "ROA": 1.6030640668523677,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 53,
                        "pageviews": 60,
                        "engaged_minutes": 18,
                        "ROA": 1.4634146341463414,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 14016,
                        "pageviews": 20520,
                        "engaged_minutes": 9461,
                        "ROA": 18.175376439326836,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 8381,
                        "pageviews": 11970,
                        "engaged_minutes": 4950,
                        "ROA": 6.4632829373650109,
                        "section": "الاقتصادي",
                        "published_posts": 52
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 2116,
                        "pageviews": 2741,
                        "engaged_minutes": 1438,
                        "ROA": 2.9632432432432432,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 14548,
                        "pageviews": 18777,
                        "engaged_minutes": 7557,
                        "ROA": 11.59074074074074,
                        "section": "الرياضي",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 4577,
                        "pageviews": 5871,
                        "engaged_minutes": 2218,
                        "ROA": 6.3129032258064512,
                        "section": "فكر وفن",
                        "published_posts": 20
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 2021,
                        "pageviews": 2317,
                        "engaged_minutes": 1292,
                        "ROA": 1.7291044776119402,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 34,
                        "pageviews": 39,
                        "engaged_minutes": 19,
                        "ROA": 1.56,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 873,
                        "pageviews": 1069,
                        "engaged_minutes": 680,
                        "ROA": 1.9123434704830053,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 249,
                        "pageviews": 285,
                        "engaged_minutes": 195,
                        "ROA": 2.8787878787878789,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 613,
                        "pageviews": 710,
                        "engaged_minutes": 295,
                        "ROA": 1.3472485768500948,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1471,
                        "pageviews": 1803,
                        "engaged_minutes": 577,
                        "ROA": 9.1522842639593911,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1697,
                        "pageviews": 1869,
                        "engaged_minutes": 1356,
                        "ROA": 3.0892561983471074,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 4609,
                        "pageviews": 6643,
                        "engaged_minutes": 2256,
                        "ROA": 11.926391382405745,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 82,
                        "pageviews": 101,
                        "engaged_minutes": 64,
                        "ROA": 2.6578947368421053,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1466,
                        "pageviews": 1655,
                        "engaged_minutes": 891,
                        "ROA": 2.9086115992970125,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 244,
                        "pageviews": 273,
                        "engaged_minutes": 173,
                        "ROA": 1.5780346820809248,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 272,
                        "pageviews": 311,
                        "engaged_minutes": 248,
                        "ROA": 2.5284552845528454,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 49,
                        "pageviews": 55,
                        "engaged_minutes": 38,
                        "ROA": 2.5,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 43,
                        "pageviews": 48,
                        "engaged_minutes": 24,
                        "ROA": 1.8461538461538463,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 378,
                        "pageviews": 456,
                        "engaged_minutes": 265,
                        "ROA": 2.0448430493273544,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 10512,
                        "pageviews": 17610,
                        "engaged_minutes": 6962,
                        "ROA": 11.245210727969349,
                        "section": "الإمارات",
                        "published_posts": 71
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 68,
                        "pageviews": 73,
                        "engaged_minutes": 34,
                        "ROA": 1.46,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 11423,
                        "pageviews": 14874,
                        "engaged_minutes": 8203,
                        "ROA": 48.29220779220779,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 18,
                        "pageviews": 36,
                        "engaged_minutes": 9,
                        "ROA": 2.25,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 160,
                        "pageviews": 184,
                        "engaged_minutes": 102,
                        "ROA": 2.3896103896103895,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 2.6666666666666665,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 328,
                        "pageviews": 433,
                        "engaged_minutes": 143,
                        "ROA": 8.66,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 117,
                        "pageviews": 137,
                        "engaged_minutes": 98,
                        "ROA": 4.0294117647058822,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 16,
                        "pageviews": 18,
                        "engaged_minutes": 8,
                        "ROA": 1.3846153846153846,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 16,
                        "pageviews": 16,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 36,
                        "pageviews": 36,
                        "engaged_minutes": 8,
                        "ROA": 1.2413793103448276,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 49,
                        "pageviews": 56,
                        "engaged_minutes": 37,
                        "ROA": 1.3658536585365855,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1.5,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1915,
                        "pageviews": 2481,
                        "engaged_minutes": 851,
                        "ROA": 14.341040462427745,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 3884,
                        "pageviews": 4710,
                        "engaged_minutes": 1772,
                        "ROA": 5.8509316770186333,
                        "section": "فكر وفن",
                        "published_posts": 22
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 29,
                        "pageviews": 30,
                        "engaged_minutes": 9,
                        "ROA": 1.5789473684210527,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 12503,
                        "pageviews": 20434,
                        "engaged_minutes": 7769,
                        "ROA": 15.192565055762081,
                        "section": "الإمارات",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 11163,
                        "pageviews": 16295,
                        "engaged_minutes": 7001,
                        "ROA": 13.693277310924369,
                        "section": "منوعات",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1151,
                        "pageviews": 1356,
                        "engaged_minutes": 796,
                        "ROA": 1.9234042553191488,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 6261,
                        "pageviews": 8363,
                        "engaged_minutes": 3186,
                        "ROA": 4.7436188315371526,
                        "section": "الاقتصادي",
                        "published_posts": 29
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 261,
                        "pageviews": 293,
                        "engaged_minutes": 205,
                        "ROA": 2.4830508474576272,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.2222222222222223,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 8810,
                        "pageviews": 12144,
                        "engaged_minutes": 4962,
                        "ROA": 7.666666666666667,
                        "section": "الرياضي",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1997,
                        "pageviews": 2602,
                        "engaged_minutes": 1320,
                        "ROA": 2.6824742268041235,
                        "section": "اتجاهات",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 2683,
                        "pageviews": 3130,
                        "engaged_minutes": 1514,
                        "ROA": 1.6092544987146529,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1787,
                        "pageviews": 1994,
                        "engaged_minutes": 1356,
                        "ROA": 3.4261168384879723,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 2098,
                        "pageviews": 2426,
                        "engaged_minutes": 1334,
                        "ROA": 1.7328571428571429,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 84,
                        "pageviews": 95,
                        "engaged_minutes": 59,
                        "ROA": 2.2093023255813953,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1466,
                        "pageviews": 1684,
                        "engaged_minutes": 877,
                        "ROA": 2.9543859649122806,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 703,
                        "pageviews": 819,
                        "engaged_minutes": 297,
                        "ROA": 1.489090909090909,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 153,
                        "pageviews": 185,
                        "engaged_minutes": 83,
                        "ROA": 2.5,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 43,
                        "pageviews": 45,
                        "engaged_minutes": 31,
                        "ROA": 2.0454545454545454,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 382,
                        "pageviews": 456,
                        "engaged_minutes": 286,
                        "ROA": 2,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 57,
                        "pageviews": 64,
                        "engaged_minutes": 19,
                        "ROA": 1.6842105263157894,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 56,
                        "pageviews": 62,
                        "engaged_minutes": 30,
                        "ROA": 1.3478260869565217,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 32,
                        "pageviews": 34,
                        "engaged_minutes": 10,
                        "ROA": 1.6190476190476191,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 5958,
                        "pageviews": 8527,
                        "engaged_minutes": 2681,
                        "ROA": 14.701724137931034,
                        "section": "العرب والعالم",
                        "published_posts": 23
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 84,
                        "pageviews": 96,
                        "engaged_minutes": 73,
                        "ROA": 3.5555555555555554,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 61,
                        "pageviews": 66,
                        "engaged_minutes": 26,
                        "ROA": 1.736842105263158,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 130,
                        "pageviews": 135,
                        "engaged_minutes": 58,
                        "ROA": 1.2980769230769231,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 9801,
                        "pageviews": 12551,
                        "engaged_minutes": 6757,
                        "ROA": 40.487096774193546,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 325,
                        "pageviews": 442,
                        "engaged_minutes": 121,
                        "ROA": 9.4042553191489358,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 267,
                        "pageviews": 316,
                        "engaged_minutes": 185,
                        "ROA": 1.7853107344632768,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 282,
                        "pageviews": 322,
                        "engaged_minutes": 298,
                        "ROA": 2.7758620689655173,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 8,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 22,
                        "pageviews": 23,
                        "engaged_minutes": 0,
                        "ROA": 1.0454545454545454,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 32,
                        "pageviews": 39,
                        "engaged_minutes": 10,
                        "ROA": 2.7857142857142856,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 5,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 263,
                        "pageviews": 297,
                        "engaged_minutes": 233,
                        "ROA": 2.3203125,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 8318,
                        "pageviews": 13331,
                        "engaged_minutes": 4397,
                        "ROA": 10.909165302782323,
                        "section": "الإمارات",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 8435,
                        "pageviews": 12275,
                        "engaged_minutes": 5095,
                        "ROA": 10.554600171969046,
                        "section": "منوعات",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1850,
                        "pageviews": 2094,
                        "engaged_minutes": 1480,
                        "ROA": 3.5134228187919465,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1809,
                        "pageviews": 2317,
                        "engaged_minutes": 869,
                        "ROA": 2.69732246798603,
                        "section": "فكر وفن",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1390,
                        "pageviews": 1741,
                        "engaged_minutes": 684,
                        "ROA": 9.61878453038674,
                        "section": "التقنية",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 6304,
                        "pageviews": 8741,
                        "engaged_minutes": 2918,
                        "ROA": 14.765202702702704,
                        "section": "العرب والعالم",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 235,
                        "pageviews": 257,
                        "engaged_minutes": 172,
                        "ROA": 2.763440860215054,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 13440,
                        "pageviews": 17594,
                        "engaged_minutes": 7052,
                        "ROA": 11.314469453376205,
                        "section": "الرياضي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 7,
                        "pageviews": 10,
                        "engaged_minutes": 3,
                        "ROA": 1.4285714285714286,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1830,
                        "pageviews": 2484,
                        "engaged_minutes": 1306,
                        "ROA": 2.6709677419354838,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 831,
                        "pageviews": 911,
                        "engaged_minutes": 322,
                        "ROA": 1.7519230769230769,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 2325,
                        "pageviews": 2625,
                        "engaged_minutes": 1367,
                        "ROA": 1.7349636483807005,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 2498,
                        "pageviews": 2924,
                        "engaged_minutes": 1488,
                        "ROA": 1.6127964699393271,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 78,
                        "pageviews": 89,
                        "engaged_minutes": 49,
                        "ROA": 2.870967741935484,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1396,
                        "pageviews": 1573,
                        "engaged_minutes": 827,
                        "ROA": 2.8968692449355431,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1361,
                        "pageviews": 1584,
                        "engaged_minutes": 924,
                        "ROA": 1.9924528301886792,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 102,
                        "pageviews": 116,
                        "engaged_minutes": 41,
                        "ROA": 1.303370786516854,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 6964,
                        "pageviews": 8937,
                        "engaged_minutes": 3377,
                        "ROA": 6.0589830508474574,
                        "section": "الاقتصادي",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 74,
                        "pageviews": 110,
                        "engaged_minutes": 33,
                        "ROA": 6.4705882352941178,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 239,
                        "pageviews": 294,
                        "engaged_minutes": 161,
                        "ROA": 1.7093023255813953,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 9,
                        "pageviews": 18,
                        "engaged_minutes": 4,
                        "ROA": 2,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 429,
                        "pageviews": 502,
                        "engaged_minutes": 252,
                        "ROA": 2.024193548387097,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 4141,
                        "pageviews": 5593,
                        "engaged_minutes": 3115,
                        "ROA": 19.154109589041095,
                        "section": " الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 71,
                        "pageviews": 83,
                        "engaged_minutes": 20,
                        "ROA": 1.66,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 87,
                        "pageviews": 97,
                        "engaged_minutes": 60,
                        "ROA": 2.2558139534883721,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 46,
                        "pageviews": 53,
                        "engaged_minutes": 24,
                        "ROA": 1.8275862068965518,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 153,
                        "pageviews": 188,
                        "engaged_minutes": 105,
                        "ROA": 2.647887323943662,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 55,
                        "pageviews": 63,
                        "engaged_minutes": 51,
                        "ROA": 1.3125,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 893,
                        "pageviews": 1114,
                        "engaged_minutes": 326,
                        "ROA": 27.85,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 37,
                        "pageviews": 45,
                        "engaged_minutes": 15,
                        "ROA": 1.9565217391304348,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 56,
                        "pageviews": 62,
                        "engaged_minutes": 42,
                        "ROA": 1.9375,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 10,
                        "ROA": 1.1818181818181819,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 29,
                        "pageviews": 32,
                        "engaged_minutes": 14,
                        "ROA": 1.28,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1966,
                        "pageviews": 2333,
                        "engaged_minutes": 1265,
                        "ROA": 1.8341194968553458,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 9047,
                        "pageviews": 12477,
                        "engaged_minutes": 6273,
                        "ROA": 10.868466898954704,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 7449,
                        "pageviews": 10502,
                        "engaged_minutes": 3981,
                        "ROA": 17.860544217687075,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1826,
                        "pageviews": 2321,
                        "engaged_minutes": 1046,
                        "ROA": 2.7763157894736841,
                        "section": "فكر وفن",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 285,
                        "pageviews": 327,
                        "engaged_minutes": 220,
                        "ROA": 2.9727272727272727,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 2214,
                        "pageviews": 2939,
                        "engaged_minutes": 1746,
                        "ROA": 3.4617196702002357,
                        "section": "اتجاهات",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1305,
                        "pageviews": 1517,
                        "engaged_minutes": 863,
                        "ROA": 2.9686888454011742,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 128,
                        "pageviews": 147,
                        "engaged_minutes": 99,
                        "ROA": 2.7735849056603774,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 2497,
                        "pageviews": 2961,
                        "engaged_minutes": 1532,
                        "ROA": 1.5783582089552239,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1271,
                        "pageviews": 1497,
                        "engaged_minutes": 442,
                        "ROA": 34.813953488372093,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 44,
                        "pageviews": 49,
                        "engaged_minutes": 30,
                        "ROA": 1.1666666666666667,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 8928,
                        "pageviews": 13109,
                        "engaged_minutes": 4871,
                        "ROA": 6.1028864059590315,
                        "section": "الاقتصادي",
                        "published_posts": 64
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 269,
                        "pageviews": 311,
                        "engaged_minutes": 260,
                        "ROA": 2.488,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 11413,
                        "pageviews": 17769,
                        "engaged_minutes": 7095,
                        "ROA": 13.471569370735406,
                        "section": "الإمارات",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 20839,
                        "pageviews": 27336,
                        "engaged_minutes": 12653,
                        "ROA": 16.388489208633093,
                        "section": "الرياضي",
                        "published_posts": 66
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1795,
                        "pageviews": 2003,
                        "engaged_minutes": 1393,
                        "ROA": 3.4297945205479454,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 47,
                        "pageviews": 48,
                        "engaged_minutes": 32,
                        "ROA": 1.7142857142857142,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 717,
                        "pageviews": 785,
                        "engaged_minutes": 303,
                        "ROA": 1.2805872756933117,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 882,
                        "pageviews": 1118,
                        "engaged_minutes": 424,
                        "ROA": 5.4536585365853663,
                        "section": "التقنية",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 991,
                        "pageviews": 1177,
                        "engaged_minutes": 754,
                        "ROA": 1.9616666666666667,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 9,
                        "pageviews": 11,
                        "engaged_minutes": 4,
                        "ROA": 1.375,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 245,
                        "pageviews": 284,
                        "engaged_minutes": 167,
                        "ROA": 1.7423312883435582,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 373,
                        "pageviews": 418,
                        "engaged_minutes": 201,
                        "ROA": 1.6653386454183268,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 48,
                        "pageviews": 63,
                        "engaged_minutes": 37,
                        "ROA": 1.5,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 7511,
                        "pageviews": 9778,
                        "engaged_minutes": 5240,
                        "ROA": 31.440514469453376,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 73,
                        "pageviews": 96,
                        "engaged_minutes": 52,
                        "ROA": 3.096774193548387,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 30,
                        "pageviews": 36,
                        "engaged_minutes": 14,
                        "ROA": 1.8947368421052631,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 73,
                        "pageviews": 79,
                        "engaged_minutes": 20,
                        "ROA": 1.4363636363636363,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 12,
                        "pageviews": 15,
                        "engaged_minutes": 8,
                        "ROA": 1.3636363636363635,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 22,
                        "pageviews": 27,
                        "engaged_minutes": 19,
                        "ROA": 1.9285714285714286,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 112,
                        "pageviews": 133,
                        "engaged_minutes": 93,
                        "ROA": 2.8297872340425534,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 6,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 2,
                        "ROA": 3.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 0,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 128,
                        "pageviews": 138,
                        "engaged_minutes": 70,
                        "ROA": 15.333333333333334,
                        "section": "الاستدامة ",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 1830,
                        "pageviews": 2320,
                        "engaged_minutes": 955,
                        "ROA": 11.100478468899521,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 10117,
                        "pageviews": 14236,
                        "engaged_minutes": 5982,
                        "ROA": 7.8091058694459683,
                        "section": "الاقتصادي",
                        "published_posts": 68
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 9322,
                        "pageviews": 13419,
                        "engaged_minutes": 6381,
                        "ROA": 11.99195710455764,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 1974,
                        "pageviews": 2218,
                        "engaged_minutes": 1635,
                        "ROA": 3.5544871794871793,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 22897,
                        "pageviews": 30147,
                        "engaged_minutes": 14201,
                        "ROA": 19.912153236459709,
                        "section": "الرياضي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 6231,
                        "pageviews": 8988,
                        "engaged_minutes": 3244,
                        "ROA": 14.90547263681592,
                        "section": "العرب والعالم",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 2900,
                        "pageviews": 3784,
                        "engaged_minutes": 2489,
                        "ROA": 3.92938733125649,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 279,
                        "pageviews": 319,
                        "engaged_minutes": 272,
                        "ROA": 2.5118110236220472,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 260,
                        "pageviews": 286,
                        "engaged_minutes": 207,
                        "ROA": 2.86,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 1960,
                        "pageviews": 2593,
                        "engaged_minutes": 1114,
                        "ROA": 2.8875278396436528,
                        "section": "فكر وفن",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 2805,
                        "pageviews": 3340,
                        "engaged_minutes": 1773,
                        "ROA": 1.5769593956562795,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 22,
                        "pageviews": 26,
                        "engaged_minutes": 11,
                        "ROA": 1.4444444444444444,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 12814,
                        "pageviews": 21084,
                        "engaged_minutes": 8347,
                        "ROA": 13.532734274711169,
                        "section": "الإمارات",
                        "published_posts": 79
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 2035,
                        "pageviews": 2334,
                        "engaged_minutes": 1310,
                        "ROA": 1.6900796524257784,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 73,
                        "pageviews": 83,
                        "engaged_minutes": 55,
                        "ROA": 2.2432432432432434,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 1722,
                        "pageviews": 1966,
                        "engaged_minutes": 986,
                        "ROA": 3.7024482109227872,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 552,
                        "pageviews": 705,
                        "engaged_minutes": 303,
                        "ROA": 1.4718162839248434,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 829,
                        "pageviews": 963,
                        "engaged_minutes": 747,
                        "ROA": 1.8771929824561404,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 232,
                        "pageviews": 263,
                        "engaged_minutes": 168,
                        "ROA": 1.5028571428571429,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 6782,
                        "pageviews": 9096,
                        "engaged_minutes": 4641,
                        "ROA": 29.436893203883496,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 121,
                        "pageviews": 143,
                        "engaged_minutes": 85,
                        "ROA": 2.1343283582089554,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 17,
                        "pageviews": 17,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 332,
                        "pageviews": 370,
                        "engaged_minutes": 231,
                        "ROA": 1.6444444444444444,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 83,
                        "pageviews": 105,
                        "engaged_minutes": 73,
                        "ROA": 3.5,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 90,
                        "pageviews": 107,
                        "engaged_minutes": 32,
                        "ROA": 1.8448275862068966,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 63,
                        "pageviews": 67,
                        "engaged_minutes": 34,
                        "ROA": 1.34,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 70,
                        "pageviews": 82,
                        "engaged_minutes": 33,
                        "ROA": 1.28125,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 14,
                        "pageviews": 15,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 303,
                        "pageviews": 400,
                        "engaged_minutes": 139,
                        "ROA": 6.0606060606060606,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 6,
                        "ROA": 1.75,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 55,
                        "pageviews": 62,
                        "engaged_minutes": 37,
                        "ROA": 2.3846153846153846,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 2,
                        "ROA": 1.2857142857142858,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 5,
                        "ROA": 1.8571428571428572,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 27,
                        "pageviews": 30,
                        "engaged_minutes": 14,
                        "ROA": 1.6666666666666667,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 6,
                        "pageviews": 11,
                        "engaged_minutes": 3,
                        "ROA": 1.8333333333333333,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 20,
                        "pageviews": 20,
                        "engaged_minutes": 6,
                        "ROA": 1.6666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 19,
                        "pageviews": 22,
                        "engaged_minutes": 4,
                        "ROA": 2.75,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 266,
                        "pageviews": 297,
                        "engaged_minutes": 219,
                        "ROA": 2.7,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 98,
                        "pageviews": 117,
                        "engaged_minutes": 39,
                        "ROA": 2.1272727272727274,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4489,
                        "pageviews": 6399,
                        "engaged_minutes": 2463,
                        "ROA": 10.901192504258944,
                        "section": "العرب والعالم",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 2934,
                        "pageviews": 3646,
                        "engaged_minutes": 1893,
                        "ROA": 1.5970214629872974,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 2187,
                        "pageviews": 2981,
                        "engaged_minutes": 1592,
                        "ROA": 2.8746383799421409,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 3158,
                        "pageviews": 3979,
                        "engaged_minutes": 1754,
                        "ROA": 4.0685071574642127,
                        "section": "فكر وفن",
                        "published_posts": 20
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 41,
                        "pageviews": 49,
                        "engaged_minutes": 21,
                        "ROA": 1.96,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 11847,
                        "pageviews": 17081,
                        "engaged_minutes": 7535,
                        "ROA": 14.674398625429554,
                        "section": "منوعات",
                        "published_posts": 15
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 7072,
                        "pageviews": 9936,
                        "engaged_minutes": 3781,
                        "ROA": 5.3941368078175893,
                        "section": "الاقتصادي",
                        "published_posts": 71
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 99,
                        "pageviews": 107,
                        "engaged_minutes": 83,
                        "ROA": 2.3260869565217392,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1563,
                        "pageviews": 1761,
                        "engaged_minutes": 920,
                        "ROA": 3.3226415094339621,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 265,
                        "pageviews": 364,
                        "engaged_minutes": 122,
                        "ROA": 7.4285714285714288,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 17507,
                        "pageviews": 27766,
                        "engaged_minutes": 11266,
                        "ROA": 16.6163973668462,
                        "section": "الإمارات",
                        "published_posts": 96
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1579,
                        "pageviews": 2081,
                        "engaged_minutes": 751,
                        "ROA": 9.459090909090909,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1877,
                        "pageviews": 2099,
                        "engaged_minutes": 1523,
                        "ROA": 3.3055118110236221,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1017,
                        "pageviews": 1213,
                        "engaged_minutes": 864,
                        "ROA": 1.9852700490998363,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 3,
                        "ROA": 1.2222222222222223,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 35,
                        "pageviews": 36,
                        "engaged_minutes": 16,
                        "ROA": 1.2413793103448276,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 2140,
                        "pageviews": 2473,
                        "engaged_minutes": 1386,
                        "ROA": 1.6766101694915254,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 602,
                        "pageviews": 748,
                        "engaged_minutes": 351,
                        "ROA": 1.3429084380610412,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 298,
                        "pageviews": 344,
                        "engaged_minutes": 304,
                        "ROA": 2.8666666666666667,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 15044,
                        "pageviews": 20207,
                        "engaged_minutes": 8171,
                        "ROA": 12.458076448828606,
                        "section": "الرياضي",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 79,
                        "pageviews": 87,
                        "engaged_minutes": 50,
                        "ROA": 1.4745762711864407,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 5723,
                        "pageviews": 7633,
                        "engaged_minutes": 4144,
                        "ROA": 24.00314465408805,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 273,
                        "pageviews": 317,
                        "engaged_minutes": 189,
                        "ROA": 1.6173469387755102,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 160,
                        "pageviews": 207,
                        "engaged_minutes": 114,
                        "ROA": 2.7236842105263159,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 424,
                        "pageviews": 498,
                        "engaged_minutes": 310,
                        "ROA": 1.930232558139535,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 94,
                        "pageviews": 111,
                        "engaged_minutes": 58,
                        "ROA": 2.7073170731707319,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 90,
                        "pageviews": 105,
                        "engaged_minutes": 58,
                        "ROA": 1.3461538461538463,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 29,
                        "pageviews": 32,
                        "engaged_minutes": 5,
                        "ROA": 2,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 59,
                        "pageviews": 62,
                        "engaged_minutes": 35,
                        "ROA": 2.1379310344827585,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 19,
                        "pageviews": 20,
                        "engaged_minutes": 3,
                        "ROA": 1.0526315789473684,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 15,
                        "pageviews": 18,
                        "engaged_minutes": 9,
                        "ROA": 1.3846153846153846,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 23,
                        "pageviews": 24,
                        "engaged_minutes": 11,
                        "ROA": 1.3333333333333333,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 7,
                        "ROA": 1.2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 1884,
                        "pageviews": 2107,
                        "engaged_minutes": 1552,
                        "ROA": 3.3181102362204724,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 2639,
                        "pageviews": 3365,
                        "engaged_minutes": 1798,
                        "ROA": 16.256038647342994,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 9888,
                        "pageviews": 14875,
                        "engaged_minutes": 5974,
                        "ROA": 6.6914080071974809,
                        "section": "الاقتصادي",
                        "published_posts": 80
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 264,
                        "pageviews": 317,
                        "engaged_minutes": 181,
                        "ROA": 1.8218390804597702,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 10943,
                        "pageviews": 15807,
                        "engaged_minutes": 6083,
                        "ROA": 9.7034990791896867,
                        "section": "الرياضي",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 1020,
                        "pageviews": 1247,
                        "engaged_minutes": 869,
                        "ROA": 2.0611570247933884,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 1971,
                        "pageviews": 2628,
                        "engaged_minutes": 1115,
                        "ROA": 2.7346514047866806,
                        "section": "فكر وفن",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 3169,
                        "pageviews": 3773,
                        "engaged_minutes": 2000,
                        "ROA": 1.596699111299196,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 16886,
                        "pageviews": 26117,
                        "engaged_minutes": 11025,
                        "ROA": 15.417355371900827,
                        "section": "الإمارات",
                        "published_posts": 75
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 22,
                        "pageviews": 30,
                        "engaged_minutes": 19,
                        "ROA": 1.7647058823529411,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 12476,
                        "pageviews": 18136,
                        "engaged_minutes": 7835,
                        "ROA": 14.325434439178515,
                        "section": "منوعات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 529,
                        "pageviews": 682,
                        "engaged_minutes": 304,
                        "ROA": 1.3268482490272373,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 2305,
                        "pageviews": 3036,
                        "engaged_minutes": 1649,
                        "ROA": 3.1461139896373056,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 2263,
                        "pageviews": 2645,
                        "engaged_minutes": 1429,
                        "ROA": 1.7993197278911566,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 1503,
                        "pageviews": 1725,
                        "engaged_minutes": 936,
                        "ROA": 2.9896013864818025,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 105,
                        "pageviews": 124,
                        "engaged_minutes": 66,
                        "ROA": 2.5833333333333335,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 299,
                        "pageviews": 329,
                        "engaged_minutes": 229,
                        "ROA": 3.1941747572815533,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 4244,
                        "pageviews": 6067,
                        "engaged_minutes": 1928,
                        "ROA": 9.02827380952381,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 6552,
                        "pageviews": 9033,
                        "engaged_minutes": 4640,
                        "ROA": 27.87962962962963,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 167,
                        "pageviews": 202,
                        "engaged_minutes": 92,
                        "ROA": 2.525,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 435,
                        "pageviews": 490,
                        "engaged_minutes": 281,
                        "ROA": 1.8773946360153257,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 61,
                        "pageviews": 74,
                        "engaged_minutes": 35,
                        "ROA": 1.2131147540983607,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 323,
                        "pageviews": 436,
                        "engaged_minutes": 156,
                        "ROA": 7.3898305084745761,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 298,
                        "pageviews": 333,
                        "engaged_minutes": 259,
                        "ROA": 2.9210526315789473,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 80,
                        "pageviews": 94,
                        "engaged_minutes": 49,
                        "ROA": 1.3428571428571427,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 60,
                        "pageviews": 63,
                        "engaged_minutes": 46,
                        "ROA": 2.3333333333333335,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 46,
                        "pageviews": 49,
                        "engaged_minutes": 29,
                        "ROA": 1.4411764705882353,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 67,
                        "pageviews": 81,
                        "engaged_minutes": 27,
                        "ROA": 1.8409090909090908,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 38,
                        "pageviews": 43,
                        "engaged_minutes": 10,
                        "ROA": 1.4333333333333333,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 63,
                        "pageviews": 69,
                        "engaged_minutes": 45,
                        "ROA": 2.3,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 64,
                        "pageviews": 79,
                        "engaged_minutes": 29,
                        "ROA": 4.9375,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 7,
                        "ROA": 1.8333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 15,
                        "pageviews": 16,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 1.25,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 15123,
                        "pageviews": 23979,
                        "engaged_minutes": 9069,
                        "ROA": 14.462605548854041,
                        "section": "الإمارات",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 1914,
                        "pageviews": 2113,
                        "engaged_minutes": 1574,
                        "ROA": 3.4925619834710742,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 3188,
                        "pageviews": 4544,
                        "engaged_minutes": 1407,
                        "ROA": 7.5607321131447591,
                        "section": "العرب والعالم",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2121,
                        "pageviews": 2831,
                        "engaged_minutes": 1479,
                        "ROA": 2.8113207547169812,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 3301,
                        "pageviews": 4478,
                        "engaged_minutes": 2494,
                        "ROA": 4.8254310344827589,
                        "section": "فكر وفن",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 7535,
                        "pageviews": 10056,
                        "engaged_minutes": 5149,
                        "ROA": 32.649350649350652,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 1488,
                        "pageviews": 1688,
                        "engaged_minutes": 902,
                        "ROA": 3.1143911439114391,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 13651,
                        "pageviews": 18533,
                        "engaged_minutes": 8638,
                        "ROA": 16.357458075904677,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 10007,
                        "pageviews": 15410,
                        "engaged_minutes": 5749,
                        "ROA": 7.1177829099307157,
                        "section": "الاقتصادي",
                        "published_posts": 76
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 630,
                        "pageviews": 750,
                        "engaged_minutes": 363,
                        "ROA": 1.3736263736263736,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2234,
                        "pageviews": 2554,
                        "engaged_minutes": 1404,
                        "ROA": 1.73387644263408,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 267,
                        "pageviews": 297,
                        "engaged_minutes": 220,
                        "ROA": 2.5384615384615383,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 4736,
                        "pageviews": 5833,
                        "engaged_minutes": 3333,
                        "ROA": 29.912820512820513,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 3164,
                        "pageviews": 3814,
                        "engaged_minutes": 2112,
                        "ROA": 1.7087813620071686,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 282,
                        "pageviews": 363,
                        "engaged_minutes": 218,
                        "ROA": 1.8059701492537314,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 312,
                        "pageviews": 353,
                        "engaged_minutes": 302,
                        "ROA": 2.6742424242424243,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 10660,
                        "pageviews": 14851,
                        "engaged_minutes": 5972,
                        "ROA": 8.95717732207479,
                        "section": "الرياضي",
                        "published_posts": 52
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 75,
                        "pageviews": 90,
                        "engaged_minutes": 46,
                        "ROA": 2.5714285714285716,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 1113,
                        "pageviews": 1317,
                        "engaged_minutes": 889,
                        "ROA": 2.08716323296355,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 15,
                        "pageviews": 16,
                        "engaged_minutes": 8,
                        "ROA": 1.0666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 220,
                        "pageviews": 252,
                        "engaged_minutes": 140,
                        "ROA": 3.15,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 397,
                        "pageviews": 463,
                        "engaged_minutes": 278,
                        "ROA": 1.9786324786324787,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 70,
                        "pageviews": 83,
                        "engaged_minutes": 45,
                        "ROA": 1.2575757575757576,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 107,
                        "pageviews": 117,
                        "engaged_minutes": 82,
                        "ROA": 2.3877551020408165,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 311,
                        "pageviews": 343,
                        "engaged_minutes": 109,
                        "ROA": 21.4375,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 86,
                        "pageviews": 100,
                        "engaged_minutes": 27,
                        "ROA": 1.7241379310344827,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 59,
                        "pageviews": 66,
                        "engaged_minutes": 27,
                        "ROA": 1.32,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 32,
                        "pageviews": 34,
                        "engaged_minutes": 17,
                        "ROA": 1.7,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 475,
                        "pageviews": 638,
                        "engaged_minutes": 266,
                        "ROA": 14.837209302325581,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 37,
                        "pageviews": 40,
                        "engaged_minutes": 23,
                        "ROA": 1.9047619047619047,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1.1428571428571428,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 73,
                        "pageviews": 78,
                        "engaged_minutes": 50,
                        "ROA": 3.25,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 9,
                        "pageviews": 14,
                        "engaged_minutes": 4,
                        "ROA": 2.3333333333333335,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.2,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1410,
                        "pageviews": 1626,
                        "engaged_minutes": 897,
                        "ROA": 3.1089866156787762,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 15645,
                        "pageviews": 23324,
                        "engaged_minutes": 11048,
                        "ROA": 20.281739130434783,
                        "section": "منوعات",
                        "published_posts": 23
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 3338,
                        "pageviews": 4736,
                        "engaged_minutes": 1594,
                        "ROA": 7.6510500807754447,
                        "section": "العرب والعالم",
                        "published_posts": 22
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1920,
                        "pageviews": 2135,
                        "engaged_minutes": 1565,
                        "ROA": 3.421474358974359,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 12395,
                        "pageviews": 18782,
                        "engaged_minutes": 7038,
                        "ROA": 15.0256,
                        "section": "الإمارات",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 8260,
                        "pageviews": 11605,
                        "engaged_minutes": 4929,
                        "ROA": 6.1207805907173,
                        "section": "الاقتصادي",
                        "published_posts": 40
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 53,
                        "pageviews": 60,
                        "engaged_minutes": 42,
                        "ROA": 2.5,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 4136,
                        "pageviews": 5064,
                        "engaged_minutes": 2991,
                        "ROA": 26.375,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 4136,
                        "pageviews": 5225,
                        "engaged_minutes": 3010,
                        "ROA": 5.9106334841628962,
                        "section": "فكر وفن",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 25,
                        "pageviews": 33,
                        "engaged_minutes": 8,
                        "ROA": 2.2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 10009,
                        "pageviews": 14090,
                        "engaged_minutes": 5839,
                        "ROA": 9.6177474402730372,
                        "section": "الرياضي",
                        "published_posts": 37
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2194,
                        "pageviews": 2892,
                        "engaged_minutes": 1458,
                        "ROA": 2.7780979827089336,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2260,
                        "pageviews": 2533,
                        "engaged_minutes": 1340,
                        "ROA": 1.8144699140401146,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 245,
                        "pageviews": 294,
                        "engaged_minutes": 243,
                        "ROA": 1.9864864864864864,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2602,
                        "pageviews": 3038,
                        "engaged_minutes": 1725,
                        "ROA": 1.6840354767184036,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 395,
                        "pageviews": 443,
                        "engaged_minutes": 260,
                        "ROA": 1.823045267489712,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 20,
                        "pageviews": 22,
                        "engaged_minutes": 13,
                        "ROA": 1.5714285714285714,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 86,
                        "pageviews": 99,
                        "engaged_minutes": 68,
                        "ROA": 2.1063829787234041,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 60,
                        "pageviews": 63,
                        "engaged_minutes": 46,
                        "ROA": 2.1724137931034484,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1176,
                        "pageviews": 1381,
                        "engaged_minutes": 860,
                        "ROA": 1.9287709497206704,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 32,
                        "pageviews": 35,
                        "engaged_minutes": 15,
                        "ROA": 1.2962962962962963,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 62,
                        "pageviews": 69,
                        "engaged_minutes": 60,
                        "ROA": 1.3529411764705883,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 6925,
                        "pageviews": 8917,
                        "engaged_minutes": 4510,
                        "ROA": 27.269113149847094,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 22,
                        "pageviews": 25,
                        "engaged_minutes": 7,
                        "ROA": 1.3157894736842106,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 539,
                        "pageviews": 632,
                        "engaged_minutes": 316,
                        "ROA": 1.3769063180827887,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 285,
                        "pageviews": 300,
                        "engaged_minutes": 213,
                        "ROA": 2.6086956521739131,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 256,
                        "pageviews": 312,
                        "engaged_minutes": 186,
                        "ROA": 1.6684491978609626,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 138,
                        "pageviews": 154,
                        "engaged_minutes": 48,
                        "ROA": 1.4951456310679612,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 53,
                        "pageviews": 75,
                        "engaged_minutes": 20,
                        "ROA": 1.9736842105263157,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1228,
                        "pageviews": 1481,
                        "engaged_minutes": 529,
                        "ROA": 30.854166666666668,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 183,
                        "pageviews": 220,
                        "engaged_minutes": 110,
                        "ROA": 3.2352941176470589,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 8,
                        "pageviews": 11,
                        "engaged_minutes": 6,
                        "ROA": 2.2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 17,
                        "pageviews": 21,
                        "engaged_minutes": 7,
                        "ROA": 1.6153846153846154,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 27,
                        "pageviews": 35,
                        "engaged_minutes": 16,
                        "ROA": 2.5,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "الجرافيك اليومي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 8,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 255,
                        "pageviews": 280,
                        "engaged_minutes": 224,
                        "ROA": 2.204724409448819,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 72,
                        "pageviews": 79,
                        "engaged_minutes": 21,
                        "ROA": 1.6458333333333333,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 7228,
                        "pageviews": 9592,
                        "engaged_minutes": 4730,
                        "ROA": 29.513846153846153,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2058,
                        "pageviews": 2292,
                        "engaged_minutes": 1658,
                        "ROA": 3.7328990228013028,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1786,
                        "pageviews": 2724,
                        "engaged_minutes": 1291,
                        "ROA": 3.2390011890606423,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2353,
                        "pageviews": 2636,
                        "engaged_minutes": 1397,
                        "ROA": 1.8485273492286114,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 159,
                        "pageviews": 230,
                        "engaged_minutes": 91,
                        "ROA": 4.3396226415094343,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 14929,
                        "pageviews": 22074,
                        "engaged_minutes": 9916,
                        "ROA": 16.697428139183057,
                        "section": "الإمارات",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 294,
                        "pageviews": 326,
                        "engaged_minutes": 227,
                        "ROA": 2.9636363636363638,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 7313,
                        "pageviews": 9562,
                        "engaged_minutes": 3829,
                        "ROA": 5.1325818572195381,
                        "section": "الاقتصادي",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1668,
                        "pageviews": 2141,
                        "engaged_minutes": 796,
                        "ROA": 2.4665898617511521,
                        "section": "فكر وفن",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2456,
                        "pageviews": 2876,
                        "engaged_minutes": 1645,
                        "ROA": 1.7139451728247914,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 16,
                        "pageviews": 25,
                        "engaged_minutes": 10,
                        "ROA": 2.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 8695,
                        "pageviews": 12590,
                        "engaged_minutes": 5398,
                        "ROA": 11.161347517730496,
                        "section": "منوعات",
                        "published_posts": 20
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1117,
                        "pageviews": 1292,
                        "engaged_minutes": 793,
                        "ROA": 1.914074074074074,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 11942,
                        "pageviews": 16393,
                        "engaged_minutes": 6485,
                        "ROA": 10.001830384380719,
                        "section": "الرياضي",
                        "published_posts": 48
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 253,
                        "pageviews": 297,
                        "engaged_minutes": 167,
                        "ROA": 1.7068965517241379,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1312,
                        "pageviews": 1516,
                        "engaged_minutes": 872,
                        "ROA": 2.9667318982387476,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 700,
                        "pageviews": 788,
                        "engaged_minutes": 359,
                        "ROA": 1.3873239436619718,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 78,
                        "pageviews": 88,
                        "engaged_minutes": 57,
                        "ROA": 2.3783783783783785,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 4238,
                        "pageviews": 5825,
                        "engaged_minutes": 1857,
                        "ROA": 8.7593984962406015,
                        "section": "العرب والعالم",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 65,
                        "pageviews": 65,
                        "engaged_minutes": 46,
                        "ROA": 1.911764705882353,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2473,
                        "pageviews": 3056,
                        "engaged_minutes": 1452,
                        "ROA": 16.169312169312171,
                        "section": "التقنية",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 579,
                        "pageviews": 619,
                        "engaged_minutes": 257,
                        "ROA": 2.0429042904290431,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 100,
                        "pageviews": 114,
                        "engaged_minutes": 38,
                        "ROA": 1.2954545454545454,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 160,
                        "pageviews": 165,
                        "engaged_minutes": 54,
                        "ROA": 1.7934782608695652,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 153,
                        "pageviews": 183,
                        "engaged_minutes": 103,
                        "ROA": 2.3461538461538463,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 50,
                        "pageviews": 57,
                        "engaged_minutes": 36,
                        "ROA": 2.5909090909090908,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 35,
                        "pageviews": 39,
                        "engaged_minutes": 16,
                        "ROA": 1.7727272727272727,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 57,
                        "pageviews": 61,
                        "engaged_minutes": 15,
                        "ROA": 1.326086956521739,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 4,
                        "pageviews": 8,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 20,
                        "pageviews": 24,
                        "engaged_minutes": 9,
                        "ROA": 2.4,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 13,
                        "pageviews": 16,
                        "engaged_minutes": 9,
                        "ROA": 2.2857142857142856,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 2.25,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 1.8,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 20191,
                        "pageviews": 31738,
                        "engaged_minutes": 14015,
                        "ROA": 22.040277777777778,
                        "section": "الإمارات",
                        "published_posts": 72
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 7593,
                        "pageviews": 11395,
                        "engaged_minutes": 3982,
                        "ROA": 17.21299093655589,
                        "section": "العرب والعالم",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1287,
                        "pageviews": 1499,
                        "engaged_minutes": 921,
                        "ROA": 3.0843621399176953,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 100,
                        "pageviews": 107,
                        "engaged_minutes": 38,
                        "ROA": 1.9814814814814814,
                        "section": "إكسبو 2020",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1783,
                        "pageviews": 2361,
                        "engaged_minutes": 879,
                        "ROA": 3.0425257731958761,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 10315,
                        "pageviews": 13995,
                        "engaged_minutes": 5725,
                        "ROA": 7.3310633839706654,
                        "section": "الاقتصادي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 2446,
                        "pageviews": 3365,
                        "engaged_minutes": 1847,
                        "ROA": 3.7264673311184939,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 37,
                        "pageviews": 41,
                        "engaged_minutes": 14,
                        "ROA": 1.8636363636363635,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 2501,
                        "pageviews": 2989,
                        "engaged_minutes": 1712,
                        "ROA": 1.7520515826494725,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1873,
                        "pageviews": 2106,
                        "engaged_minutes": 1452,
                        "ROA": 3.5217391304347827,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 7201,
                        "pageviews": 10635,
                        "engaged_minutes": 4436,
                        "ROA": 9.2639372822299659,
                        "section": "منوعات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 14433,
                        "pageviews": 19302,
                        "engaged_minutes": 9076,
                        "ROA": 12.412861736334404,
                        "section": "الرياضي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 63,
                        "pageviews": 72,
                        "engaged_minutes": 19,
                        "ROA": 1.4693877551020409,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 598,
                        "pageviews": 697,
                        "engaged_minutes": 412,
                        "ROA": 1.531868131868132,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1979,
                        "pageviews": 2250,
                        "engaged_minutes": 1231,
                        "ROA": 1.8337408312958434,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 7760,
                        "pageviews": 10027,
                        "engaged_minutes": 5265,
                        "ROA": 32.66123778501629,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 3,
                        "ROA": 1.1111111111111112,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 253,
                        "pageviews": 284,
                        "engaged_minutes": 265,
                        "ROA": 2.4482758620689653,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 62,
                        "pageviews": 65,
                        "engaged_minutes": 38,
                        "ROA": 2.5,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 2345,
                        "pageviews": 3098,
                        "engaged_minutes": 1505,
                        "ROA": 16.305263157894736,
                        "section": "التقنية",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 281,
                        "pageviews": 311,
                        "engaged_minutes": 201,
                        "ROA": 2.9339622641509435,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 915,
                        "pageviews": 1068,
                        "engaged_minutes": 752,
                        "ROA": 1.8736842105263158,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 23,
                        "pageviews": 30,
                        "engaged_minutes": 10,
                        "ROA": 2.5,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 27,
                        "pageviews": 30,
                        "engaged_minutes": 16,
                        "ROA": 1.5,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 378,
                        "pageviews": 433,
                        "engaged_minutes": 247,
                        "ROA": 1.7459677419354838,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 168,
                        "pageviews": 190,
                        "engaged_minutes": 69,
                        "ROA": 1.7592592592592593,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 244,
                        "pageviews": 291,
                        "engaged_minutes": 185,
                        "ROA": 1.598901098901099,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 95,
                        "pageviews": 109,
                        "engaged_minutes": 66,
                        "ROA": 2.4222222222222221,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 188,
                        "pageviews": 256,
                        "engaged_minutes": 85,
                        "ROA": 5.2244897959183669,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 31,
                        "pageviews": 39,
                        "engaged_minutes": 23,
                        "ROA": 2.4375,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 139,
                        "pageviews": 170,
                        "engaged_minutes": 83,
                        "ROA": 2.5757575757575757,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 21,
                        "pageviews": 24,
                        "engaged_minutes": 7,
                        "ROA": 1.8461538461538463,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 5,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 2.75,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 9,
                        "pageviews": 12,
                        "engaged_minutes": 6,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 2271,
                        "pageviews": 2907,
                        "engaged_minutes": 1201,
                        "ROA": 15.885245901639344,
                        "section": "التقنية",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 1274,
                        "pageviews": 1472,
                        "engaged_minutes": 864,
                        "ROA": 2.8693957115009745,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 10537,
                        "pageviews": 16212,
                        "engaged_minutes": 6832,
                        "ROA": 13.521267723102586,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 2051,
                        "pageviews": 2338,
                        "engaged_minutes": 1405,
                        "ROA": 1.7901990811638591,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 6397,
                        "pageviews": 9264,
                        "engaged_minutes": 3259,
                        "ROA": 14.61198738170347,
                        "section": "العرب والعالم",
                        "published_posts": 22
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 3390,
                        "pageviews": 4459,
                        "engaged_minutes": 2800,
                        "ROA": 4.5780287474332653,
                        "section": "اتجاهات",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 79,
                        "pageviews": 89,
                        "engaged_minutes": 59,
                        "ROA": 2.1190476190476191,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 551,
                        "pageviews": 674,
                        "engaged_minutes": 338,
                        "ROA": 1.5388127853881279,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 2942,
                        "pageviews": 3775,
                        "engaged_minutes": 1404,
                        "ROA": 4.3093607305936077,
                        "section": "فكر وفن",
                        "published_posts": 22
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 12001,
                        "pageviews": 18799,
                        "engaged_minutes": 8197,
                        "ROA": 11.935873015873016,
                        "section": "الإمارات",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 17,
                        "pageviews": 20,
                        "engaged_minutes": 7,
                        "ROA": 1.4285714285714286,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 969,
                        "pageviews": 1111,
                        "engaged_minutes": 807,
                        "ROA": 1.8578595317725752,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 8548,
                        "pageviews": 12601,
                        "engaged_minutes": 4260,
                        "ROA": 6.88203167667941,
                        "section": "الاقتصادي",
                        "published_posts": 78
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 13160,
                        "pageviews": 18188,
                        "engaged_minutes": 7755,
                        "ROA": 12.005280528052806,
                        "section": "الرياضي",
                        "published_posts": 45
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 2892,
                        "pageviews": 3419,
                        "engaged_minutes": 1947,
                        "ROA": 1.6993041749502982,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 29,
                        "pageviews": 34,
                        "engaged_minutes": 23,
                        "ROA": 1.8888888888888888,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 238,
                        "pageviews": 274,
                        "engaged_minutes": 247,
                        "ROA": 2.4035087719298245,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 55,
                        "pageviews": 67,
                        "engaged_minutes": 18,
                        "ROA": 1.6341463414634145,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 368,
                        "pageviews": 413,
                        "engaged_minutes": 257,
                        "ROA": 1.8274336283185841,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 233,
                        "pageviews": 271,
                        "engaged_minutes": 151,
                        "ROA": 1.6325301204819278,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 262,
                        "pageviews": 291,
                        "engaged_minutes": 215,
                        "ROA": 2.5526315789473686,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 71,
                        "pageviews": 75,
                        "engaged_minutes": 53,
                        "ROA": 2.6785714285714284,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 1806,
                        "pageviews": 2021,
                        "engaged_minutes": 1507,
                        "ROA": 3.3852596314907872,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 69,
                        "pageviews": 80,
                        "engaged_minutes": 34,
                        "ROA": 1.3114754098360655,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 55,
                        "pageviews": 61,
                        "engaged_minutes": 39,
                        "ROA": 1.1509433962264151,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 5715,
                        "pageviews": 7461,
                        "engaged_minutes": 3740,
                        "ROA": 24.87,
                        "section": " الصحي",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 161,
                        "pageviews": 224,
                        "engaged_minutes": 77,
                        "ROA": 4.3076923076923075,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 155,
                        "pageviews": 178,
                        "engaged_minutes": 106,
                        "ROA": 2.0459770114942528,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 1,
                        "pageviews": 3,
                        "engaged_minutes": 3,
                        "ROA": 3,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 47,
                        "pageviews": 50,
                        "engaged_minutes": 28,
                        "ROA": 1.8518518518518519,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 38,
                        "pageviews": 44,
                        "engaged_minutes": 23,
                        "ROA": 1.8333333333333333,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.4,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 36,
                        "pageviews": 41,
                        "engaged_minutes": 13,
                        "ROA": 2.5625,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 12,
                        "pageviews": 14,
                        "engaged_minutes": 11,
                        "ROA": 1.5555555555555556,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 6,
                        "ROA": 1.2857142857142858,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 4,
                        "pageviews": 13,
                        "engaged_minutes": 1,
                        "ROA": 1.0833333333333333,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 1556,
                        "pageviews": 1798,
                        "engaged_minutes": 1056,
                        "ROA": 3.263157894736842,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 4630,
                        "pageviews": 5732,
                        "engaged_minutes": 3162,
                        "ROA": 19.63013698630137,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 3946,
                        "pageviews": 4910,
                        "engaged_minutes": 1869,
                        "ROA": 5.1575630252100844,
                        "section": "فكر وفن",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 66,
                        "pageviews": 86,
                        "engaged_minutes": 17,
                        "ROA": 1.6862745098039216,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 12125,
                        "pageviews": 18637,
                        "engaged_minutes": 8758,
                        "ROA": 16.1219723183391,
                        "section": "منوعات",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 280,
                        "pageviews": 306,
                        "engaged_minutes": 217,
                        "ROA": 2.9142857142857141,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 2020,
                        "pageviews": 2301,
                        "engaged_minutes": 1630,
                        "ROA": 3.4916540212443095,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 14163,
                        "pageviews": 19172,
                        "engaged_minutes": 7852,
                        "ROA": 12.798397863818424,
                        "section": "الرياضي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 9363,
                        "pageviews": 14362,
                        "engaged_minutes": 6119,
                        "ROA": 25.10839160839161,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 522,
                        "pageviews": 609,
                        "engaged_minutes": 348,
                        "ROA": 1.3967889908256881,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 2692,
                        "pageviews": 3472,
                        "engaged_minutes": 2025,
                        "ROA": 3.4240631163708088,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 3151,
                        "pageviews": 3829,
                        "engaged_minutes": 2198,
                        "ROA": 1.8027306967984935,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 8580,
                        "pageviews": 11931,
                        "engaged_minutes": 4433,
                        "ROA": 6.0288024254674077,
                        "section": "الاقتصادي",
                        "published_posts": 74
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 63,
                        "pageviews": 75,
                        "engaged_minutes": 41,
                        "ROA": 1.3157894736842106,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 2412,
                        "pageviews": 2770,
                        "engaged_minutes": 1563,
                        "ROA": 1.9330076762037682,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 14422,
                        "pageviews": 22725,
                        "engaged_minutes": 9280,
                        "ROA": 13.542908224076282,
                        "section": "الإمارات",
                        "published_posts": 68
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 303,
                        "pageviews": 351,
                        "engaged_minutes": 223,
                        "ROA": 1.6794258373205742,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 303,
                        "pageviews": 342,
                        "engaged_minutes": 308,
                        "ROA": 2.6307692307692307,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 221,
                        "pageviews": 299,
                        "engaged_minutes": 113,
                        "ROA": 6.5,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 1098,
                        "pageviews": 1302,
                        "engaged_minutes": 890,
                        "ROA": 1.9667673716012084,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 494,
                        "pageviews": 575,
                        "engaged_minutes": 359,
                        "ROA": 2.1375464684014869,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 55,
                        "pageviews": 59,
                        "engaged_minutes": 40,
                        "ROA": 1.2553191489361701,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 1890,
                        "pageviews": 2430,
                        "engaged_minutes": 929,
                        "ROA": 11.571428571428571,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 18,
                        "pageviews": 20,
                        "engaged_minutes": 6,
                        "ROA": 1.1764705882352942,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 72,
                        "pageviews": 75,
                        "engaged_minutes": 53,
                        "ROA": 2.34375,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 17,
                        "pageviews": 18,
                        "engaged_minutes": 11,
                        "ROA": 1.125,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 90,
                        "pageviews": 102,
                        "engaged_minutes": 57,
                        "ROA": 2.4878048780487805,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 10,
                        "pageviews": 10,
                        "engaged_minutes": 6,
                        "ROA": 1.6666666666666667,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 29,
                        "pageviews": 33,
                        "engaged_minutes": 11,
                        "ROA": 1.5714285714285714,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 140,
                        "pageviews": 169,
                        "engaged_minutes": 104,
                        "ROA": 2.9137931034482758,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 57,
                        "pageviews": 72,
                        "engaged_minutes": 47,
                        "ROA": 2.7692307692307692,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 42,
                        "pageviews": 48,
                        "engaged_minutes": 34,
                        "ROA": 1.5483870967741935,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 27,
                        "pageviews": 33,
                        "engaged_minutes": 19,
                        "ROA": 1.32,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 9,
                        "pageviews": 12,
                        "engaged_minutes": 6,
                        "ROA": 6,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 9215,
                        "pageviews": 11070,
                        "engaged_minutes": 6263,
                        "ROA": 37.272727272727273,
                        "section": " الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2010,
                        "pageviews": 2237,
                        "engaged_minutes": 1672,
                        "ROA": 3.5339652448657186,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 95,
                        "pageviews": 106,
                        "engaged_minutes": 65,
                        "ROA": 2.3043478260869565,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 1609,
                        "pageviews": 1814,
                        "engaged_minutes": 1052,
                        "ROA": 3.028380634390651,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 6726,
                        "pageviews": 9733,
                        "engaged_minutes": 3269,
                        "ROA": 14.592203898050975,
                        "section": "العرب والعالم",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 12205,
                        "pageviews": 16938,
                        "engaged_minutes": 6841,
                        "ROA": 8.74896694214876,
                        "section": "الاقتصادي",
                        "published_posts": 63
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2700,
                        "pageviews": 3580,
                        "engaged_minutes": 1885,
                        "ROA": 3.54806739345887,
                        "section": "اتجاهات",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2558,
                        "pageviews": 2952,
                        "engaged_minutes": 1736,
                        "ROA": 2.0906515580736542,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 98,
                        "pageviews": 113,
                        "engaged_minutes": 51,
                        "ROA": 1.3950617283950617,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 27,
                        "pageviews": 28,
                        "engaged_minutes": 13,
                        "ROA": 2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 12046,
                        "pageviews": 17824,
                        "engaged_minutes": 9398,
                        "ROA": 16.057657657657657,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 1120,
                        "pageviews": 1394,
                        "engaged_minutes": 972,
                        "ROA": 2.1057401812688821,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 16168,
                        "pageviews": 22008,
                        "engaged_minutes": 9366,
                        "ROA": 13.973333333333333,
                        "section": "الرياضي",
                        "published_posts": 46
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 15510,
                        "pageviews": 25022,
                        "engaged_minutes": 9643,
                        "ROA": 15.001199040767386,
                        "section": "الإمارات",
                        "published_posts": 81
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 3224,
                        "pageviews": 3934,
                        "engaged_minutes": 2306,
                        "ROA": 1.7665020206555906,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2121,
                        "pageviews": 2740,
                        "engaged_minutes": 1105,
                        "ROA": 3.0176211453744495,
                        "section": "فكر وفن",
                        "published_posts": 15
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 158,
                        "pageviews": 194,
                        "engaged_minutes": 103,
                        "ROA": 2.6944444444444446,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 557,
                        "pageviews": 666,
                        "engaged_minutes": 344,
                        "ROA": 1.4509803921568627,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2738,
                        "pageviews": 3516,
                        "engaged_minutes": 1333,
                        "ROA": 19.752808988764045,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 310,
                        "pageviews": 340,
                        "engaged_minutes": 233,
                        "ROA": 3.2380952380952381,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 304,
                        "pageviews": 361,
                        "engaged_minutes": 324,
                        "ROA": 2.8650793650793651,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 60,
                        "pageviews": 69,
                        "engaged_minutes": 46,
                        "ROA": 2.3,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 279,
                        "pageviews": 348,
                        "engaged_minutes": 202,
                        "ROA": 1.901639344262295,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 485,
                        "pageviews": 581,
                        "engaged_minutes": 334,
                        "ROA": 2.3909465020576133,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 66,
                        "pageviews": 79,
                        "engaged_minutes": 49,
                        "ROA": 1.253968253968254,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 41,
                        "pageviews": 49,
                        "engaged_minutes": 15,
                        "ROA": 3.0625,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 85,
                        "pageviews": 97,
                        "engaged_minutes": 62,
                        "ROA": 2.8529411764705883,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 13,
                        "pageviews": 14,
                        "engaged_minutes": 8,
                        "ROA": 1.5555555555555556,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 12,
                        "pageviews": 12,
                        "engaged_minutes": 9,
                        "ROA": 2.4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 70,
                        "pageviews": 74,
                        "engaged_minutes": 29,
                        "ROA": 1.4509803921568627,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 49,
                        "pageviews": 54,
                        "engaged_minutes": 24,
                        "ROA": 1.6875,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 233,
                        "pageviews": 302,
                        "engaged_minutes": 119,
                        "ROA": 6.8636363636363633,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 31,
                        "pageviews": 36,
                        "engaged_minutes": 19,
                        "ROA": 1.8947368421052631,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 15,
                        "pageviews": 17,
                        "engaged_minutes": 6,
                        "ROA": 1.5454545454545454,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 13,
                        "ROA": 3.6666666666666665,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3234,
                        "pageviews": 3909,
                        "engaged_minutes": 2172,
                        "ROA": 1.7776261937244202,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 22,
                        "pageviews": 26,
                        "engaged_minutes": 17,
                        "ROA": 1.625,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 288,
                        "pageviews": 320,
                        "engaged_minutes": 230,
                        "ROA": 3.1683168316831685,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 1538,
                        "pageviews": 1895,
                        "engaged_minutes": 545,
                        "ROA": 9.3349753694581281,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 5609,
                        "pageviews": 7098,
                        "engaged_minutes": 3914,
                        "ROA": 23.739130434782609,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 1985,
                        "pageviews": 2230,
                        "engaged_minutes": 1614,
                        "ROA": 3.5453100158982513,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 7646,
                        "pageviews": 11033,
                        "engaged_minutes": 4392,
                        "ROA": 5.896846606092998,
                        "section": "الاقتصادي",
                        "published_posts": 60
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 11857,
                        "pageviews": 18232,
                        "engaged_minutes": 7696,
                        "ROA": 16.514492753623188,
                        "section": "منوعات",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 16281,
                        "pageviews": 23086,
                        "engaged_minutes": 11229,
                        "ROA": 14.492153170119272,
                        "section": "الرياضي",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3518,
                        "pageviews": 4471,
                        "engaged_minutes": 1785,
                        "ROA": 5.1038812785388128,
                        "section": "فكر وفن",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 171,
                        "pageviews": 199,
                        "engaged_minutes": 126,
                        "ROA": 2.4875,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 10506,
                        "pageviews": 18038,
                        "engaged_minutes": 7666,
                        "ROA": 11.141445336627548,
                        "section": "الإمارات",
                        "published_posts": 62
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 79,
                        "pageviews": 92,
                        "engaged_minutes": 50,
                        "ROA": 1.4375,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 24,
                        "pageviews": 24,
                        "engaged_minutes": 16,
                        "ROA": 1.2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 1154,
                        "pageviews": 1396,
                        "engaged_minutes": 971,
                        "ROA": 2.2053712480252763,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 6245,
                        "pageviews": 9202,
                        "engaged_minutes": 3263,
                        "ROA": 14.676236044657097,
                        "section": "العرب والعالم",
                        "published_posts": 22
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 2277,
                        "pageviews": 3020,
                        "engaged_minutes": 1630,
                        "ROA": 3.091095189355169,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3067,
                        "pageviews": 3475,
                        "engaged_minutes": 2105,
                        "ROA": 2.4786019971469329,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 285,
                        "pageviews": 333,
                        "engaged_minutes": 276,
                        "ROA": 2.664,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 1709,
                        "pageviews": 1933,
                        "engaged_minutes": 1119,
                        "ROA": 3.4766187050359711,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 488,
                        "pageviews": 555,
                        "engaged_minutes": 339,
                        "ROA": 2.2023809523809526,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 94,
                        "pageviews": 102,
                        "engaged_minutes": 59,
                        "ROA": 3.2903225806451615,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 311,
                        "pageviews": 381,
                        "engaged_minutes": 240,
                        "ROA": 1.9740932642487046,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 22,
                        "pageviews": 23,
                        "engaged_minutes": 5,
                        "ROA": 1.2777777777777777,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 52,
                        "pageviews": 63,
                        "engaged_minutes": 26,
                        "ROA": 5.25,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 542,
                        "pageviews": 639,
                        "engaged_minutes": 306,
                        "ROA": 1.392156862745098,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 60,
                        "pageviews": 63,
                        "engaged_minutes": 40,
                        "ROA": 2.1,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 11,
                        "pageviews": 14,
                        "engaged_minutes": 8,
                        "ROA": 1.4,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 109,
                        "pageviews": 119,
                        "engaged_minutes": 72,
                        "ROA": 2.2037037037037037,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 290,
                        "pageviews": 389,
                        "engaged_minutes": 162,
                        "ROA": 8.6444444444444439,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 8,
                        "ROA": 1.2857142857142858,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 63,
                        "pageviews": 76,
                        "engaged_minutes": 38,
                        "ROA": 1.3818181818181818,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 63,
                        "pageviews": 75,
                        "engaged_minutes": 25,
                        "ROA": 1.9736842105263157,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 46,
                        "pageviews": 49,
                        "engaged_minutes": 18,
                        "ROA": 1.75,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 9,
                        "ROA": 3.6666666666666665,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 2.5,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 9759,
                        "pageviews": 14918,
                        "engaged_minutes": 5496,
                        "ROA": 23.128682170542636,
                        "section": "العرب والعالم",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 4866,
                        "pageviews": 5853,
                        "engaged_minutes": 2323,
                        "ROA": 6.30032292787944,
                        "section": "فكر وفن",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 6902,
                        "pageviews": 9485,
                        "engaged_minutes": 3050,
                        "ROA": 5.4417670682730925,
                        "section": "الاقتصادي",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 10809,
                        "pageviews": 15181,
                        "engaged_minutes": 6241,
                        "ROA": 9.6879387364390563,
                        "section": "الرياضي",
                        "published_posts": 35
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 2038,
                        "pageviews": 2917,
                        "engaged_minutes": 1500,
                        "ROA": 2.7860553963705827,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 3590,
                        "pageviews": 4124,
                        "engaged_minutes": 2383,
                        "ROA": 2.7131578947368422,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 92,
                        "pageviews": 106,
                        "engaged_minutes": 51,
                        "ROA": 3.4193548387096775,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 2777,
                        "pageviews": 3487,
                        "engaged_minutes": 1875,
                        "ROA": 1.7637835103692463,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 24,
                        "pageviews": 44,
                        "engaged_minutes": 19,
                        "ROA": 1.5714285714285714,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 7845,
                        "pageviews": 11908,
                        "engaged_minutes": 4706,
                        "ROA": 10.221459227467811,
                        "section": "منوعات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 1247,
                        "pageviews": 1481,
                        "engaged_minutes": 956,
                        "ROA": 1.9564068692206076,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 9534,
                        "pageviews": 14435,
                        "engaged_minutes": 6423,
                        "ROA": 10.201413427561837,
                        "section": "الإمارات",
                        "published_posts": 35
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 2055,
                        "pageviews": 2283,
                        "engaged_minutes": 1685,
                        "ROA": 3.6123417721518987,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 686,
                        "pageviews": 923,
                        "engaged_minutes": 372,
                        "ROA": 1.4839228295819935,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 1695,
                        "pageviews": 1911,
                        "engaged_minutes": 1073,
                        "ROA": 3.6056603773584905,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 31,
                        "pageviews": 35,
                        "engaged_minutes": 21,
                        "ROA": 1.6666666666666667,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 314,
                        "pageviews": 419,
                        "engaged_minutes": 179,
                        "ROA": 7.7592592592592595,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 287,
                        "pageviews": 351,
                        "engaged_minutes": 289,
                        "ROA": 2.7,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 64,
                        "pageviews": 72,
                        "engaged_minutes": 23,
                        "ROA": 1.5,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 2746,
                        "pageviews": 3848,
                        "engaged_minutes": 1874,
                        "ROA": 11.767584097859327,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 452,
                        "pageviews": 523,
                        "engaged_minutes": 314,
                        "ROA": 2.0671936758893281,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 50,
                        "pageviews": 55,
                        "engaged_minutes": 41,
                        "ROA": 1.0576923076923077,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 330,
                        "pageviews": 433,
                        "engaged_minutes": 287,
                        "ROA": 2.2670157068062826,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 148,
                        "pageviews": 170,
                        "engaged_minutes": 101,
                        "ROA": 2.0987654320987654,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 6,
                        "ROA": 1.2857142857142858,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 98,
                        "pageviews": 110,
                        "engaged_minutes": 60,
                        "ROA": 2.2,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 69,
                        "pageviews": 107,
                        "engaged_minutes": 37,
                        "ROA": 1.4266666666666667,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 590,
                        "pageviews": 791,
                        "engaged_minutes": 277,
                        "ROA": 4.119791666666667,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 368,
                        "pageviews": 412,
                        "engaged_minutes": 284,
                        "ROA": 3.2698412698412698,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 4,
                        "pageviews": 7,
                        "engaged_minutes": 2,
                        "ROA": 1.4,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 27,
                        "pageviews": 32,
                        "engaged_minutes": 15,
                        "ROA": 2.2857142857142856,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 17,
                        "pageviews": 21,
                        "engaged_minutes": 10,
                        "ROA": 1.75,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 79,
                        "pageviews": 86,
                        "engaged_minutes": 52,
                        "ROA": 2.3243243243243241,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 29,
                        "pageviews": 36,
                        "engaged_minutes": 14,
                        "ROA": 1.8947368421052631,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 9,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 872,
                        "pageviews": 1097,
                        "engaged_minutes": 369,
                        "ROA": 6.568862275449102,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 25,
                        "pageviews": 27,
                        "engaged_minutes": 14,
                        "ROA": 1.8,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1916,
                        "pageviews": 2145,
                        "engaged_minutes": 1527,
                        "ROA": 3.4764991896272286,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 10678,
                        "pageviews": 16967,
                        "engaged_minutes": 7031,
                        "ROA": 14.269974768713205,
                        "section": "الإمارات",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 13054,
                        "pageviews": 20211,
                        "engaged_minutes": 8031,
                        "ROA": 18.175359712230215,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 5109,
                        "pageviews": 6920,
                        "engaged_minutes": 2310,
                        "ROA": 4.136282127913927,
                        "section": "الاقتصادي",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 13,
                        "pageviews": 17,
                        "engaged_minutes": 2,
                        "ROA": 1.4166666666666667,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 2050,
                        "pageviews": 2739,
                        "engaged_minutes": 1362,
                        "ROA": 2.7835365853658538,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1817,
                        "pageviews": 2356,
                        "engaged_minutes": 996,
                        "ROA": 2.8943488943488944,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 926,
                        "pageviews": 1140,
                        "engaged_minutes": 395,
                        "ROA": 24.25531914893617,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 4960,
                        "pageviews": 7240,
                        "engaged_minutes": 2147,
                        "ROA": 12.905525846702318,
                        "section": "العرب والعالم",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 70,
                        "pageviews": 79,
                        "engaged_minutes": 25,
                        "ROA": 1.8372093023255813,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 652,
                        "pageviews": 742,
                        "engaged_minutes": 323,
                        "ROA": 1.5267489711934157,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 8385,
                        "pageviews": 11502,
                        "engaged_minutes": 4275,
                        "ROA": 7.724647414372062,
                        "section": "الرياضي",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 2785,
                        "pageviews": 3112,
                        "engaged_minutes": 1825,
                        "ROA": 2.2324246771879483,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 2612,
                        "pageviews": 3044,
                        "engaged_minutes": 1515,
                        "ROA": 1.8151460942158617,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 483,
                        "pageviews": 543,
                        "engaged_minutes": 262,
                        "ROA": 1.7980132450331126,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 310,
                        "pageviews": 346,
                        "engaged_minutes": 285,
                        "ROA": 2.5072463768115942,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1582,
                        "pageviews": 1817,
                        "engaged_minutes": 1011,
                        "ROA": 3.5213178294573644,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 123,
                        "pageviews": 150,
                        "engaged_minutes": 89,
                        "ROA": 2.0270270270270272,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 4752,
                        "pageviews": 6417,
                        "engaged_minutes": 3013,
                        "ROA": 20.7,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1404,
                        "pageviews": 1598,
                        "engaged_minutes": 906,
                        "ROA": 2.2071823204419889,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 104,
                        "pageviews": 109,
                        "engaged_minutes": 43,
                        "ROA": 1.211111111111111,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 294,
                        "pageviews": 366,
                        "engaged_minutes": 249,
                        "ROA": 2.0220994475138121,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 27,
                        "pageviews": 28,
                        "engaged_minutes": 11,
                        "ROA": 2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 71,
                        "pageviews": 80,
                        "engaged_minutes": 54,
                        "ROA": 2.5,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 292,
                        "pageviews": 316,
                        "engaged_minutes": 218,
                        "ROA": 3.3617021276595747,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 224,
                        "pageviews": 237,
                        "engaged_minutes": 50,
                        "ROA": 1.5906040268456376,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.6666666666666667,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 119,
                        "pageviews": 132,
                        "engaged_minutes": 97,
                        "ROA": 2.5882352941176472,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 86,
                        "pageviews": 88,
                        "engaged_minutes": 16,
                        "ROA": 2.3783783783783785,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 45,
                        "pageviews": 50,
                        "engaged_minutes": 27,
                        "ROA": 2.9411764705882355,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 1.2857142857142858,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.2222222222222223,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.6666666666666667,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 12717,
                        "pageviews": 18489,
                        "engaged_minutes": 7205,
                        "ROA": 15.642131979695431,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 18276,
                        "pageviews": 29406,
                        "engaged_minutes": 11382,
                        "ROA": 19.976902173913043,
                        "section": "الإمارات",
                        "published_posts": 60
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 120,
                        "pageviews": 137,
                        "engaged_minutes": 47,
                        "ROA": 1.2232142857142858,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 2248,
                        "pageviews": 2910,
                        "engaged_minutes": 1188,
                        "ROA": 3.1056563500533616,
                        "section": "فكر وفن",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 152,
                        "pageviews": 178,
                        "engaged_minutes": 104,
                        "ROA": 2.5428571428571427,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 22,
                        "pageviews": 28,
                        "engaged_minutes": 15,
                        "ROA": 1.6470588235294117,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 1110,
                        "pageviews": 1305,
                        "engaged_minutes": 829,
                        "ROA": 1.902332361516035,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 706,
                        "pageviews": 905,
                        "engaged_minutes": 309,
                        "ROA": 4.7883597883597879,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 1995,
                        "pageviews": 2241,
                        "engaged_minutes": 1722,
                        "ROA": 3.5515055467511885,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 1944,
                        "pageviews": 2633,
                        "engaged_minutes": 1323,
                        "ROA": 2.7256728778467907,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 9936,
                        "pageviews": 14508,
                        "engaged_minutes": 5495,
                        "ROA": 9.1997463538363977,
                        "section": "الرياضي",
                        "published_posts": 46
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 2238,
                        "pageviews": 2553,
                        "engaged_minutes": 1457,
                        "ROA": 1.8473227206946454,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 88,
                        "pageviews": 97,
                        "engaged_minutes": 61,
                        "ROA": 2.3658536585365852,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 1476,
                        "pageviews": 1695,
                        "engaged_minutes": 1011,
                        "ROA": 3.2658959537572256,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 2491,
                        "pageviews": 2974,
                        "engaged_minutes": 1694,
                        "ROA": 1.7300756253635834,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 501,
                        "pageviews": 591,
                        "engaged_minutes": 288,
                        "ROA": 1.4275362318840579,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 77,
                        "pageviews": 100,
                        "engaged_minutes": 44,
                        "ROA": 1.7543859649122806,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 293,
                        "pageviews": 330,
                        "engaged_minutes": 224,
                        "ROA": 3,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 7070,
                        "pageviews": 9552,
                        "engaged_minutes": 3104,
                        "ROA": 5.6958855098389982,
                        "section": "الاقتصادي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 275,
                        "pageviews": 307,
                        "engaged_minutes": 265,
                        "ROA": 2.20863309352518,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 16,
                        "pageviews": 17,
                        "engaged_minutes": 11,
                        "ROA": 1.1333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 430,
                        "pageviews": 505,
                        "engaged_minutes": 310,
                        "ROA": 1.9573643410852712,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 4091,
                        "pageviews": 5810,
                        "engaged_minutes": 2866,
                        "ROA": 18.802588996763753,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 7087,
                        "pageviews": 9811,
                        "engaged_minutes": 3176,
                        "ROA": 14.198263386396526,
                        "section": "العرب والعالم",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 279,
                        "pageviews": 331,
                        "engaged_minutes": 200,
                        "ROA": 1.9702380952380953,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 9,
                        "pageviews": 13,
                        "engaged_minutes": 9,
                        "ROA": 1.4444444444444444,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 42,
                        "pageviews": 49,
                        "engaged_minutes": 30,
                        "ROA": 1.8846153846153846,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 57,
                        "pageviews": 62,
                        "engaged_minutes": 39,
                        "ROA": 2.2142857142857144,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 633,
                        "pageviews": 741,
                        "engaged_minutes": 294,
                        "ROA": 8.5172413793103452,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 32,
                        "pageviews": 36,
                        "engaged_minutes": 20,
                        "ROA": 1.3846153846153846,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 3,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 110,
                        "pageviews": 124,
                        "engaged_minutes": 43,
                        "ROA": 1.24,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 38,
                        "pageviews": 44,
                        "engaged_minutes": 17,
                        "ROA": 2.2,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 10,
                        "pageviews": 10,
                        "engaged_minutes": 8,
                        "ROA": 3.3333333333333335,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 4,
                        "ROA": 1.25,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1.3333333333333333,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 19,
                        "pageviews": 23,
                        "engaged_minutes": 6,
                        "ROA": 2.3,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 72,
                        "pageviews": 81,
                        "engaged_minutes": 30,
                        "ROA": 1.8,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 1251,
                        "pageviews": 1617,
                        "engaged_minutes": 539,
                        "ROA": 7.88780487804878,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 9540,
                        "pageviews": 13657,
                        "engaged_minutes": 5099,
                        "ROA": 24.830909090909092,
                        "section": "العرب والعالم",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 103,
                        "pageviews": 113,
                        "engaged_minutes": 62,
                        "ROA": 1.9152542372881356,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2135,
                        "pageviews": 2690,
                        "engaged_minutes": 996,
                        "ROA": 2.7477017364657814,
                        "section": "فكر وفن",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 1377,
                        "pageviews": 1593,
                        "engaged_minutes": 927,
                        "ROA": 3.1052631578947367,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 387,
                        "pageviews": 449,
                        "engaged_minutes": 208,
                        "ROA": 9.76086956521739,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 94,
                        "pageviews": 120,
                        "engaged_minutes": 59,
                        "ROA": 1.7647058823529411,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2248,
                        "pageviews": 2583,
                        "engaged_minutes": 1509,
                        "ROA": 1.9062730627306272,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2961,
                        "pageviews": 3507,
                        "engaged_minutes": 1952,
                        "ROA": 1.6820143884892087,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 10439,
                        "pageviews": 14875,
                        "engaged_minutes": 6522,
                        "ROA": 13.082673702726472,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 8825,
                        "pageviews": 11803,
                        "engaged_minutes": 4398,
                        "ROA": 6.4638554216867474,
                        "section": "الاقتصادي",
                        "published_posts": 62
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2094,
                        "pageviews": 2820,
                        "engaged_minutes": 1520,
                        "ROA": 3.1649831649831648,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 539,
                        "pageviews": 675,
                        "engaged_minutes": 320,
                        "ROA": 1.3803680981595092,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 11955,
                        "pageviews": 16195,
                        "engaged_minutes": 6072,
                        "ROA": 10.746516257465162,
                        "section": "الرياضي",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 23803,
                        "pageviews": 37160,
                        "engaged_minutes": 15997,
                        "ROA": 22.54854368932039,
                        "section": "الإمارات",
                        "published_posts": 97
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 1937,
                        "pageviews": 2162,
                        "engaged_minutes": 1639,
                        "ROA": 3.5676567656765679,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 281,
                        "pageviews": 338,
                        "engaged_minutes": 196,
                        "ROA": 1.7789473684210526,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 241,
                        "pageviews": 272,
                        "engaged_minutes": 270,
                        "ROA": 2.176,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 935,
                        "pageviews": 1077,
                        "engaged_minutes": 812,
                        "ROA": 1.7860696517412935,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 15,
                        "pageviews": 19,
                        "engaged_minutes": 9,
                        "ROA": 1.9,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 5162,
                        "pageviews": 8247,
                        "engaged_minutes": 4058,
                        "ROA": 27.217821782178216,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 446,
                        "pageviews": 527,
                        "engaged_minutes": 320,
                        "ROA": 2.1422764227642275,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 68,
                        "pageviews": 72,
                        "engaged_minutes": 48,
                        "ROA": 2.3225806451612905,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 136,
                        "pageviews": 156,
                        "engaged_minutes": 87,
                        "ROA": 2.3636363636363638,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 280,
                        "pageviews": 307,
                        "engaged_minutes": 212,
                        "ROA": 3.13265306122449,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 18,
                        "pageviews": 18,
                        "engaged_minutes": 5,
                        "ROA": 1.2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 29,
                        "pageviews": 42,
                        "engaged_minutes": 23,
                        "ROA": 2.2105263157894739,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 44,
                        "pageviews": 51,
                        "engaged_minutes": 29,
                        "ROA": 1.275,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 31,
                        "pageviews": 38,
                        "engaged_minutes": 17,
                        "ROA": 1.7272727272727273,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 13,
                        "pageviews": 17,
                        "engaged_minutes": 13,
                        "ROA": 3.4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 7,
                        "pageviews": 12,
                        "engaged_minutes": 6,
                        "ROA": 2.4,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 23,
                        "pageviews": 29,
                        "engaged_minutes": 12,
                        "ROA": 4.1428571428571432,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 50,
                        "pageviews": 55,
                        "engaged_minutes": 30,
                        "ROA": 2.5,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 3,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.6666666666666667,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1887,
                        "pageviews": 2402,
                        "engaged_minutes": 721,
                        "ROA": 11.832512315270936,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1920,
                        "pageviews": 2142,
                        "engaged_minutes": 1550,
                        "ROA": 3.4382022471910112,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 553,
                        "pageviews": 628,
                        "engaged_minutes": 332,
                        "ROA": 1.491686460807601,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 109,
                        "pageviews": 121,
                        "engaged_minutes": 89,
                        "ROA": 2.4693877551020407,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 2266,
                        "pageviews": 2841,
                        "engaged_minutes": 1068,
                        "ROA": 2.9258496395468589,
                        "section": "فكر وفن",
                        "published_posts": 15
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1437,
                        "pageviews": 1646,
                        "engaged_minutes": 914,
                        "ROA": 3.0146520146520146,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 50,
                        "pageviews": 56,
                        "engaged_minutes": 28,
                        "ROA": 2.8,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 827,
                        "pageviews": 945,
                        "engaged_minutes": 404,
                        "ROA": 18.9,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 20435,
                        "pageviews": 30918,
                        "engaged_minutes": 14443,
                        "ROA": 19.239576851275668,
                        "section": "الإمارات",
                        "published_posts": 80
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 11115,
                        "pageviews": 15798,
                        "engaged_minutes": 7422,
                        "ROA": 13.242246437552389,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 13,
                        "pageviews": 15,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 13720,
                        "pageviews": 18737,
                        "engaged_minutes": 8091,
                        "ROA": 12.433311214333113,
                        "section": "الرياضي",
                        "published_posts": 40
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 2577,
                        "pageviews": 3463,
                        "engaged_minutes": 1874,
                        "ROA": 3.3458937198067633,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 2388,
                        "pageviews": 2733,
                        "engaged_minutes": 1562,
                        "ROA": 1.8668032786885247,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 8435,
                        "pageviews": 11942,
                        "engaged_minutes": 5520,
                        "ROA": 18.835962145110411,
                        "section": "العرب والعالم",
                        "published_posts": 23
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 12232,
                        "pageviews": 17844,
                        "engaged_minutes": 6675,
                        "ROA": 6.343405616779239,
                        "section": "الاقتصادي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 3295,
                        "pageviews": 3849,
                        "engaged_minutes": 2158,
                        "ROA": 1.7599451303155007,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 314,
                        "pageviews": 351,
                        "engaged_minutes": 262,
                        "ROA": 2.9008264462809916,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 317,
                        "pageviews": 374,
                        "engaged_minutes": 217,
                        "ROA": 1.8888888888888888,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 6270,
                        "pageviews": 9857,
                        "engaged_minutes": 5030,
                        "ROA": 31.694533762057876,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 9,
                        "ROA": 1.4444444444444444,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 64,
                        "pageviews": 70,
                        "engaged_minutes": 40,
                        "ROA": 2,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 480,
                        "pageviews": 568,
                        "engaged_minutes": 346,
                        "ROA": 2.16793893129771,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 106,
                        "pageviews": 122,
                        "engaged_minutes": 54,
                        "ROA": 1.4698795180722892,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 78,
                        "pageviews": 86,
                        "engaged_minutes": 45,
                        "ROA": 1.4333333333333333,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1068,
                        "pageviews": 1239,
                        "engaged_minutes": 896,
                        "ROA": 1.9149922720247294,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 291,
                        "pageviews": 340,
                        "engaged_minutes": 278,
                        "ROA": 2.72,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 153,
                        "pageviews": 188,
                        "engaged_minutes": 101,
                        "ROA": 2.088888888888889,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 61,
                        "pageviews": 67,
                        "engaged_minutes": 24,
                        "ROA": 1.4565217391304348,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 31,
                        "pageviews": 38,
                        "engaged_minutes": 28,
                        "ROA": 2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 61,
                        "pageviews": 68,
                        "engaged_minutes": 38,
                        "ROA": 2.125,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 8,
                        "pageviews": 10,
                        "engaged_minutes": 7,
                        "ROA": 1.4285714285714286,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 7,
                        "pageviews": 10,
                        "engaged_minutes": 6,
                        "ROA": 1.4285714285714286,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 37,
                        "pageviews": 38,
                        "engaged_minutes": 25,
                        "ROA": 1.6521739130434783,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 5,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 2.3333333333333335,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 20,
                        "pageviews": 22,
                        "engaged_minutes": 7,
                        "ROA": 2,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2738,
                        "pageviews": 3542,
                        "engaged_minutes": 1363,
                        "ROA": 17.888888888888889,
                        "section": "التقنية",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 8285,
                        "pageviews": 11996,
                        "engaged_minutes": 6461,
                        "ROA": 37.962025316455694,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2884,
                        "pageviews": 3819,
                        "engaged_minutes": 2127,
                        "ROA": 3.6862934362934361,
                        "section": "اتجاهات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 50,
                        "pageviews": 60,
                        "engaged_minutes": 31,
                        "ROA": 1.8181818181818181,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 18960,
                        "pageviews": 29861,
                        "engaged_minutes": 14520,
                        "ROA": 16.281897491821155,
                        "section": "الإمارات",
                        "published_posts": 71
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 10247,
                        "pageviews": 13981,
                        "engaged_minutes": 6785,
                        "ROA": 12.011168384879726,
                        "section": "منوعات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 12321,
                        "pageviews": 16614,
                        "engaged_minutes": 6378,
                        "ROA": 8.4078947368421044,
                        "section": "الاقتصادي",
                        "published_posts": 58
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 7247,
                        "pageviews": 10128,
                        "engaged_minutes": 3924,
                        "ROA": 15.849765258215962,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2227,
                        "pageviews": 2816,
                        "engaged_minutes": 997,
                        "ROA": 2.9090909090909092,
                        "section": "فكر وفن",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2045,
                        "pageviews": 2261,
                        "engaged_minutes": 1671,
                        "ROA": 3.4,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 541,
                        "pageviews": 639,
                        "engaged_minutes": 343,
                        "ROA": 1.4013157894736843,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 15226,
                        "pageviews": 21088,
                        "engaged_minutes": 9695,
                        "ROA": 13.873684210526315,
                        "section": "الرياضي",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2473,
                        "pageviews": 2870,
                        "engaged_minutes": 1650,
                        "ROA": 1.9223040857334226,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 101,
                        "pageviews": 111,
                        "engaged_minutes": 74,
                        "ROA": 2.7073170731707319,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 3256,
                        "pageviews": 3871,
                        "engaged_minutes": 2251,
                        "ROA": 1.7219750889679715,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 1502,
                        "pageviews": 1703,
                        "engaged_minutes": 967,
                        "ROA": 3.0907441016333936,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 294,
                        "pageviews": 344,
                        "engaged_minutes": 194,
                        "ROA": 1.8105263157894738,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 72,
                        "pageviews": 77,
                        "engaged_minutes": 54,
                        "ROA": 2.8518518518518516,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 287,
                        "pageviews": 328,
                        "engaged_minutes": 235,
                        "ROA": 3.6444444444444444,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 1098,
                        "pageviews": 1289,
                        "engaged_minutes": 932,
                        "ROA": 1.9891975308641976,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 465,
                        "pageviews": 539,
                        "engaged_minutes": 320,
                        "ROA": 2.0891472868217056,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 66,
                        "pageviews": 77,
                        "engaged_minutes": 52,
                        "ROA": 1.2833333333333334,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 85,
                        "pageviews": 92,
                        "engaged_minutes": 30,
                        "ROA": 1.5333333333333334,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 101,
                        "pageviews": 120,
                        "engaged_minutes": 65,
                        "ROA": 1.4285714285714286,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 164,
                        "pageviews": 211,
                        "engaged_minutes": 131,
                        "ROA": 2.5731707317073171,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 323,
                        "pageviews": 378,
                        "engaged_minutes": 322,
                        "ROA": 2.6808510638297873,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 7,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 41,
                        "pageviews": 44,
                        "engaged_minutes": 27,
                        "ROA": 1.5714285714285714,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 23,
                        "pageviews": 23,
                        "engaged_minutes": 3,
                        "ROA": 1.0454545454545454,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 9,
                        "pageviews": 12,
                        "engaged_minutes": 8,
                        "ROA": 1.5,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 213,
                        "pageviews": 272,
                        "engaged_minutes": 99,
                        "ROA": 5.7872340425531918,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 3,
                        "ROA": 3.6666666666666665,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 46,
                        "pageviews": 56,
                        "engaged_minutes": 25,
                        "ROA": 2.4347826086956523,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 43,
                        "pageviews": 51,
                        "engaged_minutes": 17,
                        "ROA": 3,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 19,
                        "pageviews": 24,
                        "engaged_minutes": 14,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 26,
                        "pageviews": 28,
                        "engaged_minutes": 12,
                        "ROA": 1.4,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 3,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 2,
                        "pageviews": 6,
                        "engaged_minutes": 5,
                        "ROA": 3,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 38,
                        "pageviews": 48,
                        "engaged_minutes": 24,
                        "ROA": 2,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 3349,
                        "pageviews": 3998,
                        "engaged_minutes": 2375,
                        "ROA": 1.7558190601668862,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 72,
                        "pageviews": 78,
                        "engaged_minutes": 27,
                        "ROA": 1.5294117647058822,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 16681,
                        "pageviews": 23659,
                        "engaged_minutes": 12521,
                        "ROA": 19.982263513513512,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 7618,
                        "pageviews": 10637,
                        "engaged_minutes": 4134,
                        "ROA": 16.777602523659304,
                        "section": "العرب والعالم",
                        "published_posts": 20
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 172,
                        "pageviews": 220,
                        "engaged_minutes": 79,
                        "ROA": 4.6808510638297873,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 19357,
                        "pageviews": 31346,
                        "engaged_minutes": 13505,
                        "ROA": 17.983935742971887,
                        "section": "الإمارات",
                        "published_posts": 89
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 301,
                        "pageviews": 336,
                        "engaged_minutes": 228,
                        "ROA": 3,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 10693,
                        "pageviews": 14186,
                        "engaged_minutes": 6051,
                        "ROA": 7.3617021276595747,
                        "section": "الاقتصادي",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 2686,
                        "pageviews": 3469,
                        "engaged_minutes": 2007,
                        "ROA": 3.3910068426197459,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 1793,
                        "pageviews": 2355,
                        "engaged_minutes": 834,
                        "ROA": 2.5079872204472844,
                        "section": "فكر وفن",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 1988,
                        "pageviews": 2200,
                        "engaged_minutes": 1665,
                        "ROA": 3.3434650455927053,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 19,
                        "pageviews": 24,
                        "engaged_minutes": 9,
                        "ROA": 1.6,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 8039,
                        "pageviews": 10370,
                        "engaged_minutes": 5969,
                        "ROA": 31.907692307692308,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 1124,
                        "pageviews": 1339,
                        "engaged_minutes": 971,
                        "ROA": 2.0165662650602409,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 11170,
                        "pageviews": 15098,
                        "engaged_minutes": 5818,
                        "ROA": 9.3776397515527954,
                        "section": "الرياضي",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 622,
                        "pageviews": 758,
                        "engaged_minutes": 357,
                        "ROA": 1.5758835758835759,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 2394,
                        "pageviews": 2789,
                        "engaged_minutes": 1676,
                        "ROA": 1.8409240924092409,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 1520,
                        "pageviews": 1729,
                        "engaged_minutes": 1030,
                        "ROA": 3.0227272727272729,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 25,
                        "pageviews": 29,
                        "engaged_minutes": 19,
                        "ROA": 2.2307692307692308,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 177,
                        "pageviews": 203,
                        "engaged_minutes": 115,
                        "ROA": 2.182795698924731,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 55,
                        "pageviews": 76,
                        "engaged_minutes": 42,
                        "ROA": 1.4339622641509433,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 907,
                        "pageviews": 1118,
                        "engaged_minutes": 386,
                        "ROA": 5.6180904522613062,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 302,
                        "pageviews": 352,
                        "engaged_minutes": 199,
                        "ROA": 1.7959183673469388,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 501,
                        "pageviews": 574,
                        "engaged_minutes": 358,
                        "ROA": 2.2162162162162162,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 89,
                        "pageviews": 100,
                        "engaged_minutes": 53,
                        "ROA": 1.3513513513513513,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 8,
                        "ROA": 1.2222222222222223,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 356,
                        "pageviews": 424,
                        "engaged_minutes": 398,
                        "ROA": 2.904109589041096,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 13,
                        "pageviews": 16,
                        "engaged_minutes": 9,
                        "ROA": 1.4545454545454546,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 13,
                        "pageviews": 17,
                        "engaged_minutes": 6,
                        "ROA": 2.4285714285714284,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 13,
                        "pageviews": 14,
                        "engaged_minutes": 9,
                        "ROA": 2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 13,
                        "pageviews": 14,
                        "engaged_minutes": 8,
                        "ROA": 1.2727272727272727,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 101,
                        "pageviews": 115,
                        "engaged_minutes": 75,
                        "ROA": 2.5555555555555554,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 55,
                        "pageviews": 63,
                        "engaged_minutes": 31,
                        "ROA": 2.4230769230769229,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 39,
                        "pageviews": 44,
                        "engaged_minutes": 26,
                        "ROA": 1.9130434782608696,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 13,
                        "pageviews": 13,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 40,
                        "pageviews": 43,
                        "engaged_minutes": 13,
                        "ROA": 14.333333333333334,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.2,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 37,
                        "pageviews": 42,
                        "engaged_minutes": 24,
                        "ROA": 2.3333333333333335,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 64,
                        "pageviews": 67,
                        "engaged_minutes": 25,
                        "ROA": 1.5227272727272727,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 601,
                        "pageviews": 751,
                        "engaged_minutes": 264,
                        "ROA": 3.9319371727748691,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 8497,
                        "pageviews": 12155,
                        "engaged_minutes": 5044,
                        "ROA": 18.278195488721803,
                        "section": "العرب والعالم",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 2251,
                        "pageviews": 2576,
                        "engaged_minutes": 1509,
                        "ROA": 1.8426323319027182,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 1655,
                        "pageviews": 2170,
                        "engaged_minutes": 805,
                        "ROA": 2.511574074074074,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 2004,
                        "pageviews": 2239,
                        "engaged_minutes": 1674,
                        "ROA": 3.5315457413249209,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 162,
                        "pageviews": 194,
                        "engaged_minutes": 93,
                        "ROA": 4.2173913043478262,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 21103,
                        "pageviews": 28004,
                        "engaged_minutes": 13924,
                        "ROA": 25.138240574506284,
                        "section": "منوعات",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 11162,
                        "pageviews": 14149,
                        "engaged_minutes": 5999,
                        "ROA": 8.2261627906976749,
                        "section": "الاقتصادي",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 94,
                        "pageviews": 108,
                        "engaged_minutes": 73,
                        "ROA": 2.7,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 2761,
                        "pageviews": 3275,
                        "engaged_minutes": 1937,
                        "ROA": 1.7532119914346895,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 2247,
                        "pageviews": 2949,
                        "engaged_minutes": 1642,
                        "ROA": 3.0815047021943576,
                        "section": "اتجاهات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 12383,
                        "pageviews": 18859,
                        "engaged_minutes": 6919,
                        "ROA": 13.252986647926916,
                        "section": "الإمارات",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 23,
                        "pageviews": 27,
                        "engaged_minutes": 13,
                        "ROA": 1.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 573,
                        "pageviews": 678,
                        "engaged_minutes": 327,
                        "ROA": 1.4273684210526316,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 13363,
                        "pageviews": 17476,
                        "engaged_minutes": 7426,
                        "ROA": 11.776280323450134,
                        "section": "الرياضي",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 1484,
                        "pageviews": 1704,
                        "engaged_minutes": 925,
                        "ROA": 3.1094890510948905,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 1175,
                        "pageviews": 1406,
                        "engaged_minutes": 924,
                        "ROA": 1.9260273972602739,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 67,
                        "pageviews": 87,
                        "engaged_minutes": 65,
                        "ROA": 1.45,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 287,
                        "pageviews": 322,
                        "engaged_minutes": 222,
                        "ROA": 2.9814814814814814,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 10828,
                        "pageviews": 14062,
                        "engaged_minutes": 8248,
                        "ROA": 44.783439490445858,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 297,
                        "pageviews": 359,
                        "engaged_minutes": 211,
                        "ROA": 1.9725274725274726,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 321,
                        "pageviews": 363,
                        "engaged_minutes": 313,
                        "ROA": 2.556338028169014,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 445,
                        "pageviews": 515,
                        "engaged_minutes": 303,
                        "ROA": 1.8327402135231317,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 38,
                        "pageviews": 44,
                        "engaged_minutes": 18,
                        "ROA": 1.6923076923076923,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 156,
                        "pageviews": 181,
                        "engaged_minutes": 96,
                        "ROA": 2.2911392405063293,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 41,
                        "pageviews": 46,
                        "engaged_minutes": 32,
                        "ROA": 2.0909090909090908,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 75,
                        "pageviews": 81,
                        "engaged_minutes": 55,
                        "ROA": 2.53125,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 66,
                        "pageviews": 69,
                        "engaged_minutes": 30,
                        "ROA": 1.2321428571428572,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.2,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "ميتافيرس",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 6,
                        "ROA": 1.6666666666666667,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 6,
                        "ROA": 2.75,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 28,
                        "pageviews": 28,
                        "engaged_minutes": 5,
                        "ROA": 2,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 69,
                        "pageviews": 85,
                        "engaged_minutes": 26,
                        "ROA": 1.8888888888888888,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 219,
                        "pageviews": 265,
                        "engaged_minutes": 100,
                        "ROA": 5.408163265306122,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 8223,
                        "pageviews": 13302,
                        "engaged_minutes": 4677,
                        "ROA": 10.650120096076861,
                        "section": "الإمارات",
                        "published_posts": 62
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 29015,
                        "pageviews": 36850,
                        "engaged_minutes": 18285,
                        "ROA": 32.099303135888505,
                        "section": "منوعات",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 1164,
                        "pageviews": 1381,
                        "engaged_minutes": 923,
                        "ROA": 1.9588652482269504,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 1984,
                        "pageviews": 2981,
                        "engaged_minutes": 1404,
                        "ROA": 3.0955347871235723,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 645,
                        "pageviews": 750,
                        "engaged_minutes": 350,
                        "ROA": 1.4763779527559056,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 29,
                        "pageviews": 32,
                        "engaged_minutes": 5,
                        "ROA": 1.6,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 12921,
                        "pageviews": 18682,
                        "engaged_minutes": 8203,
                        "ROA": 30.180936995153473,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 16,
                        "pageviews": 17,
                        "engaged_minutes": 5,
                        "ROA": 1.2142857142857142,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 10322,
                        "pageviews": 12830,
                        "engaged_minutes": 5250,
                        "ROA": 8.4966887417218544,
                        "section": "الاقتصادي",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 2431,
                        "pageviews": 2746,
                        "engaged_minutes": 1500,
                        "ROA": 1.8795345653661875,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 14622,
                        "pageviews": 19437,
                        "engaged_minutes": 8189,
                        "ROA": 13.322138450993831,
                        "section": "الرياضي",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 627,
                        "pageviews": 785,
                        "engaged_minutes": 282,
                        "ROA": 4.7575757575757578,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 2413,
                        "pageviews": 3080,
                        "engaged_minutes": 1182,
                        "ROA": 3.8260869565217392,
                        "section": "فكر وفن",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 2589,
                        "pageviews": 3023,
                        "engaged_minutes": 1637,
                        "ROA": 1.7972651605231866,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 1368,
                        "pageviews": 1602,
                        "engaged_minutes": 877,
                        "ROA": 3.0867052023121389,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 156,
                        "pageviews": 161,
                        "engaged_minutes": 41,
                        "ROA": 1.1418439716312057,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 1928,
                        "pageviews": 2139,
                        "engaged_minutes": 1537,
                        "ROA": 3.5297029702970297,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 325,
                        "pageviews": 366,
                        "engaged_minutes": 354,
                        "ROA": 2.6715328467153285,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 287,
                        "pageviews": 337,
                        "engaged_minutes": 232,
                        "ROA": 3.2095238095238097,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 105,
                        "pageviews": 113,
                        "engaged_minutes": 43,
                        "ROA": 1.2417582417582418,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 420,
                        "pageviews": 474,
                        "engaged_minutes": 278,
                        "ROA": 1.9190283400809716,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 8,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 305,
                        "pageviews": 364,
                        "engaged_minutes": 207,
                        "ROA": 1.775609756097561,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 9319,
                        "pageviews": 12025,
                        "engaged_minutes": 6589,
                        "ROA": 37.344720496894411,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 47,
                        "pageviews": 55,
                        "engaged_minutes": 40,
                        "ROA": 2.2916666666666665,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 5,
                        "pageviews": 8,
                        "engaged_minutes": 8,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 50,
                        "pageviews": 56,
                        "engaged_minutes": 18,
                        "ROA": 1.4736842105263157,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 116,
                        "pageviews": 129,
                        "engaged_minutes": 82,
                        "ROA": 2.58,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 12,
                        "pageviews": 12,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 129,
                        "pageviews": 157,
                        "engaged_minutes": 93,
                        "ROA": 2.453125,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 3,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 17,
                        "pageviews": 19,
                        "engaged_minutes": 14,
                        "ROA": 1.7272727272727273,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 29,
                        "pageviews": 30,
                        "engaged_minutes": 17,
                        "ROA": 1.875,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 4,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 65,
                        "pageviews": 76,
                        "engaged_minutes": 25,
                        "ROA": 2.0540540540540539,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 772,
                        "pageviews": 951,
                        "engaged_minutes": 323,
                        "ROA": 5.3426966292134832,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 2367,
                        "pageviews": 2645,
                        "engaged_minutes": 1500,
                        "ROA": 1.8444909344490934,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 1857,
                        "pageviews": 2090,
                        "engaged_minutes": 1520,
                        "ROA": 3.4039087947882738,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 27979,
                        "pageviews": 37137,
                        "engaged_minutes": 20041,
                        "ROA": 32.209019947961842,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 8943,
                        "pageviews": 11822,
                        "engaged_minutes": 5018,
                        "ROA": 17.83107088989442,
                        "section": "العرب والعالم",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 525,
                        "pageviews": 634,
                        "engaged_minutes": 320,
                        "ROA": 1.5131264916467781,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 2040,
                        "pageviews": 2715,
                        "engaged_minutes": 1620,
                        "ROA": 3.2593037214885956,
                        "section": "اتجاهات",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 4482,
                        "pageviews": 5592,
                        "engaged_minutes": 1774,
                        "ROA": 6.64922711058264,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 2592,
                        "pageviews": 2995,
                        "engaged_minutes": 1724,
                        "ROA": 1.7816775728732896,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 243,
                        "pageviews": 311,
                        "engaged_minutes": 127,
                        "ROA": 6.3469387755102042,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 14495,
                        "pageviews": 23429,
                        "engaged_minutes": 9155,
                        "ROA": 16.604535790219703,
                        "section": "الإمارات",
                        "published_posts": 75
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 13389,
                        "pageviews": 17827,
                        "engaged_minutes": 5068,
                        "ROA": 7.3848384424192215,
                        "section": "الاقتصادي",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 15303,
                        "pageviews": 20401,
                        "engaged_minutes": 8483,
                        "ROA": 13.897138964577657,
                        "section": "الرياضي",
                        "published_posts": 45
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 1377,
                        "pageviews": 1603,
                        "engaged_minutes": 918,
                        "ROA": 3.2449392712550607,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 961,
                        "pageviews": 1139,
                        "engaged_minutes": 825,
                        "ROA": 1.8672131147540985,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 289,
                        "pageviews": 331,
                        "engaged_minutes": 230,
                        "ROA": 2.9819819819819822,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 338,
                        "pageviews": 387,
                        "engaged_minutes": 394,
                        "ROA": 2.7841726618705036,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 136,
                        "pageviews": 169,
                        "engaged_minutes": 102,
                        "ROA": 2.640625,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 5582,
                        "pageviews": 7295,
                        "engaged_minutes": 4080,
                        "ROA": 23.6084142394822,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 66,
                        "pageviews": 71,
                        "engaged_minutes": 54,
                        "ROA": 2.84,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 432,
                        "pageviews": 492,
                        "engaged_minutes": 313,
                        "ROA": 2.2062780269058297,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 276,
                        "pageviews": 330,
                        "engaged_minutes": 201,
                        "ROA": 1.8435754189944134,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1.3333333333333333,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 55,
                        "pageviews": 65,
                        "engaged_minutes": 32,
                        "ROA": 1.3265306122448979,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 23,
                        "pageviews": 25,
                        "engaged_minutes": 17,
                        "ROA": 1.6666666666666667,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 38,
                        "pageviews": 43,
                        "engaged_minutes": 21,
                        "ROA": 2.3888888888888888,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 115,
                        "pageviews": 131,
                        "engaged_minutes": 80,
                        "ROA": 2.9772727272727271,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 63,
                        "pageviews": 74,
                        "engaged_minutes": 37,
                        "ROA": 1.2982456140350878,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.2222222222222223,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 28,
                        "pageviews": 31,
                        "engaged_minutes": 19,
                        "ROA": 1.631578947368421,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 18,
                        "pageviews": 23,
                        "engaged_minutes": 11,
                        "ROA": 4.6,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.5714285714285714,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 14,
                        "pageviews": 15,
                        "engaged_minutes": 2,
                        "ROA": 2.1428571428571428,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 17,
                        "pageviews": 19,
                        "engaged_minutes": 11,
                        "ROA": 1.9,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "فيروس كورونا المستجد (كوفيد-19)",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 8570,
                        "pageviews": 12735,
                        "engaged_minutes": 5482,
                        "ROA": 22.071057192374351,
                        "section": "العرب والعالم",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 26864,
                        "pageviews": 39051,
                        "engaged_minutes": 24980,
                        "ROA": 32.5425,
                        "section": "منوعات",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1291,
                        "pageviews": 1567,
                        "engaged_minutes": 544,
                        "ROA": 7.7192118226600988,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 15682,
                        "pageviews": 20928,
                        "engaged_minutes": 8272,
                        "ROA": 14.14054054054054,
                        "section": "الرياضي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 545,
                        "pageviews": 639,
                        "engaged_minutes": 332,
                        "ROA": 1.4655963302752293,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 282,
                        "pageviews": 313,
                        "engaged_minutes": 224,
                        "ROA": 2.81981981981982,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1567,
                        "pageviews": 2074,
                        "engaged_minutes": 836,
                        "ROA": 2.6186868686868685,
                        "section": "فكر وفن",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1309,
                        "pageviews": 1508,
                        "engaged_minutes": 885,
                        "ROA": 2.8506616257088848,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 16,
                        "pageviews": 16,
                        "engaged_minutes": 4,
                        "ROA": 1.7777777777777777,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 851,
                        "pageviews": 1010,
                        "engaged_minutes": 697,
                        "ROA": 1.8600368324125229,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 3492,
                        "pageviews": 4681,
                        "engaged_minutes": 3191,
                        "ROA": 4.7716615698267075,
                        "section": "اتجاهات",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1751,
                        "pageviews": 1976,
                        "engaged_minutes": 1455,
                        "ROA": 3.4127806563039722,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 2396,
                        "pageviews": 2772,
                        "engaged_minutes": 1695,
                        "ROA": 2.008695652173913,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 271,
                        "pageviews": 308,
                        "engaged_minutes": 306,
                        "ROA": 2.5040650406504064,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 3001,
                        "pageviews": 3640,
                        "engaged_minutes": 2082,
                        "ROA": 1.7601547388781431,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 13672,
                        "pageviews": 21784,
                        "engaged_minutes": 8371,
                        "ROA": 13.291031116534473,
                        "section": "الإمارات",
                        "published_posts": 81
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 76,
                        "pageviews": 97,
                        "engaged_minutes": 32,
                        "ROA": 1.8301886792452831,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 420,
                        "pageviews": 501,
                        "engaged_minutes": 327,
                        "ROA": 2.1139240506329116,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 11102,
                        "pageviews": 14731,
                        "engaged_minutes": 5737,
                        "ROA": 8.624707259953162,
                        "section": "الاقتصادي",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 225,
                        "pageviews": 284,
                        "engaged_minutes": 144,
                        "ROA": 1.9452054794520548,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 5348,
                        "pageviews": 6983,
                        "engaged_minutes": 3415,
                        "ROA": 21.09667673716012,
                        "section": " الصحي",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 66,
                        "pageviews": 77,
                        "engaged_minutes": 40,
                        "ROA": 1.375,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 101,
                        "pageviews": 117,
                        "engaged_minutes": 52,
                        "ROA": 2.25,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 70,
                        "pageviews": 74,
                        "engaged_minutes": 54,
                        "ROA": 2.3125,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 67,
                        "pageviews": 76,
                        "engaged_minutes": 55,
                        "ROA": 1.3333333333333333,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 62,
                        "pageviews": 72,
                        "engaged_minutes": 30,
                        "ROA": 2.88,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 113,
                        "pageviews": 132,
                        "engaged_minutes": 78,
                        "ROA": 1.76,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 329,
                        "pageviews": 403,
                        "engaged_minutes": 205,
                        "ROA": 8.2244897959183678,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 7,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 3,
                        "pageviews": 5,
                        "engaged_minutes": 5,
                        "ROA": 1.6666666666666667,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 34,
                        "pageviews": 36,
                        "engaged_minutes": 22,
                        "ROA": 1.7142857142857142,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 19,
                        "pageviews": 27,
                        "engaged_minutes": 5,
                        "ROA": 1.6875,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "ميتافيرس",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 25,
                        "pageviews": 28,
                        "engaged_minutes": 13,
                        "ROA": 1.6470588235294117,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.25,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 10,
                        "pageviews": 12,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 309,
                        "pageviews": 348,
                        "engaged_minutes": 323,
                        "ROA": 2.6564885496183206,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 15411,
                        "pageviews": 24356,
                        "engaged_minutes": 9943,
                        "ROA": 13.073537305421363,
                        "section": "الإمارات",
                        "published_posts": 87
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 21805,
                        "pageviews": 32024,
                        "engaged_minutes": 15977,
                        "ROA": 26.035772357723577,
                        "section": "منوعات",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 5818,
                        "pageviews": 8442,
                        "engaged_minutes": 2816,
                        "ROA": 10.371007371007371,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 1987,
                        "pageviews": 2209,
                        "engaged_minutes": 1605,
                        "ROA": 3.4142194744976817,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 2322,
                        "pageviews": 3075,
                        "engaged_minutes": 1683,
                        "ROA": 3.099798387096774,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 101,
                        "pageviews": 104,
                        "engaged_minutes": 59,
                        "ROA": 2.810810810810811,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 1890,
                        "pageviews": 2538,
                        "engaged_minutes": 1006,
                        "ROA": 2.442733397497594,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 42,
                        "pageviews": 53,
                        "engaged_minutes": 21,
                        "ROA": 2.65,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 2397,
                        "pageviews": 2732,
                        "engaged_minutes": 1623,
                        "ROA": 1.9542203147353361,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 3224,
                        "pageviews": 3828,
                        "engaged_minutes": 2314,
                        "ROA": 1.7771587743732591,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 18,
                        "pageviews": 18,
                        "engaged_minutes": 4,
                        "ROA": 1.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 2989,
                        "pageviews": 3435,
                        "engaged_minutes": 1273,
                        "ROA": 16.838235294117649,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 11218,
                        "pageviews": 16531,
                        "engaged_minutes": 4464,
                        "ROA": 6.3949709864603479,
                        "section": "الاقتصادي",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 664,
                        "pageviews": 819,
                        "engaged_minutes": 401,
                        "ROA": 1.6027397260273972,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 1367,
                        "pageviews": 1562,
                        "engaged_minutes": 932,
                        "ROA": 3.0330097087378642,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 16944,
                        "pageviews": 23675,
                        "engaged_minutes": 10449,
                        "ROA": 13.48234624145786,
                        "section": "الرياضي",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 270,
                        "pageviews": 303,
                        "engaged_minutes": 211,
                        "ROA": 2.8317757009345796,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 1189,
                        "pageviews": 1362,
                        "engaged_minutes": 964,
                        "ROA": 2.0921658986175116,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 147,
                        "pageviews": 168,
                        "engaged_minutes": 98,
                        "ROA": 1.9764705882352942,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 491,
                        "pageviews": 581,
                        "engaged_minutes": 413,
                        "ROA": 2.727699530516432,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 91,
                        "pageviews": 100,
                        "engaged_minutes": 47,
                        "ROA": 1.36986301369863,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 8351,
                        "pageviews": 10624,
                        "engaged_minutes": 5832,
                        "ROA": 38.492753623188406,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 279,
                        "pageviews": 334,
                        "engaged_minutes": 179,
                        "ROA": 1.8152173913043479,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.1666666666666667,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 60,
                        "pageviews": 67,
                        "engaged_minutes": 42,
                        "ROA": 1.34,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 37,
                        "pageviews": 46,
                        "engaged_minutes": 28,
                        "ROA": 2.0909090909090908,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 63,
                        "pageviews": 74,
                        "engaged_minutes": 39,
                        "ROA": 2.3870967741935485,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 74,
                        "pageviews": 90,
                        "engaged_minutes": 37,
                        "ROA": 1.8367346938775511,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 41,
                        "pageviews": 44,
                        "engaged_minutes": 17,
                        "ROA": 1.4193548387096775,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 346,
                        "pageviews": 438,
                        "engaged_minutes": 226,
                        "ROA": 6.84375,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 7,
                        "ROA": 1.2857142857142858,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 5,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 6,
                        "ROA": 1.5714285714285714,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 66,
                        "pageviews": 69,
                        "engaged_minutes": 48,
                        "ROA": 2.6538461538461537,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 2,
                        "pageviews": 5,
                        "engaged_minutes": 6,
                        "ROA": 2.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 4861,
                        "pageviews": 5562,
                        "engaged_minutes": 2521,
                        "ROA": 29.273684210526316,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 292,
                        "pageviews": 320,
                        "engaged_minutes": 238,
                        "ROA": 2.7586206896551726,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 8976,
                        "pageviews": 13099,
                        "engaged_minutes": 5949,
                        "ROA": 18.766475644699142,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 144,
                        "pageviews": 174,
                        "engaged_minutes": 95,
                        "ROA": 2.32,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 1980,
                        "pageviews": 2245,
                        "engaged_minutes": 1707,
                        "ROA": 3.5862619808306708,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 9150,
                        "pageviews": 11314,
                        "engaged_minutes": 6798,
                        "ROA": 37.713333333333331,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 16573,
                        "pageviews": 26071,
                        "engaged_minutes": 11798,
                        "ROA": 15.052540415704389,
                        "section": "الإمارات",
                        "published_posts": 77
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 14,
                        "pageviews": 17,
                        "engaged_minutes": 7,
                        "ROA": 1.3076923076923077,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 7272,
                        "pageviews": 9892,
                        "engaged_minutes": 3837,
                        "ROA": 5.2701118806606289,
                        "section": "الاقتصادي",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 2318,
                        "pageviews": 3201,
                        "engaged_minutes": 1761,
                        "ROA": 3.0927536231884059,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 524,
                        "pageviews": 626,
                        "engaged_minutes": 289,
                        "ROA": 1.4764150943396226,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 2305,
                        "pageviews": 2602,
                        "engaged_minutes": 1462,
                        "ROA": 1.8336856941508104,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 1496,
                        "pageviews": 1706,
                        "engaged_minutes": 1002,
                        "ROA": 3.1947565543071161,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 3247,
                        "pageviews": 3890,
                        "engaged_minutes": 2280,
                        "ROA": 1.7491007194244603,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 20512,
                        "pageviews": 28741,
                        "engaged_minutes": 12101,
                        "ROA": 22.846581875993639,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 278,
                        "pageviews": 326,
                        "engaged_minutes": 171,
                        "ROA": 1.5748792270531402,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 360,
                        "pageviews": 402,
                        "engaged_minutes": 365,
                        "ROA": 2.8920863309352516,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 2672,
                        "pageviews": 3506,
                        "engaged_minutes": 1696,
                        "ROA": 3.7497326203208554,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 65,
                        "pageviews": 71,
                        "engaged_minutes": 52,
                        "ROA": 2.1515151515151514,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 14328,
                        "pageviews": 19361,
                        "engaged_minutes": 6989,
                        "ROA": 12.1997479521109,
                        "section": "الرياضي",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 77,
                        "pageviews": 87,
                        "engaged_minutes": 31,
                        "ROA": 1.6730769230769231,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 1462,
                        "pageviews": 1722,
                        "engaged_minutes": 986,
                        "ROA": 2.8415841584158414,
                        "section": "مسارات ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 685,
                        "pageviews": 801,
                        "engaged_minutes": 576,
                        "ROA": 3.2429149797570851,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 71,
                        "pageviews": 79,
                        "engaged_minutes": 46,
                        "ROA": 1.234375,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 68,
                        "pageviews": 78,
                        "engaged_minutes": 52,
                        "ROA": 1.3220338983050848,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 22,
                        "pageviews": 27,
                        "engaged_minutes": 8,
                        "ROA": 1.8,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 417,
                        "pageviews": 537,
                        "engaged_minutes": 258,
                        "ROA": 8.6612903225806459,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 40,
                        "pageviews": 48,
                        "engaged_minutes": 13,
                        "ROA": 1.6,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 100,
                        "pageviews": 114,
                        "engaged_minutes": 76,
                        "ROA": 2.7804878048780486,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 15,
                        "pageviews": 16,
                        "engaged_minutes": 6,
                        "ROA": 1.0666666666666667,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1.4,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 62,
                        "pageviews": 76,
                        "engaged_minutes": 37,
                        "ROA": 2.1714285714285713,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 55,
                        "pageviews": 60,
                        "engaged_minutes": 34,
                        "ROA": 3.5294117647058822,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 6,
                        "ROA": 4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 31474,
                        "pageviews": 42105,
                        "engaged_minutes": 19240,
                        "ROA": 37.327127659574465,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 3499,
                        "pageviews": 4155,
                        "engaged_minutes": 1969,
                        "ROA": 20.56930693069307,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1416,
                        "pageviews": 1663,
                        "engaged_minutes": 906,
                        "ROA": 2.7307060755336616,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 2212,
                        "pageviews": 2502,
                        "engaged_minutes": 1430,
                        "ROA": 1.7707006369426752,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 2904,
                        "pageviews": 3741,
                        "engaged_minutes": 1444,
                        "ROA": 4.0487012987012987,
                        "section": "فكر وفن",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 12188,
                        "pageviews": 14874,
                        "engaged_minutes": 10450,
                        "ROA": 51.825783972125436,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 53,
                        "pageviews": 66,
                        "engaged_minutes": 40,
                        "ROA": 2.64,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 11732,
                        "pageviews": 18812,
                        "engaged_minutes": 7865,
                        "ROA": 11.936548223350254,
                        "section": "الإمارات",
                        "published_posts": 77
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 16306,
                        "pageviews": 21371,
                        "engaged_minutes": 7060,
                        "ROA": 33.708201892744476,
                        "section": "العرب والعالم",
                        "published_posts": 34
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 3280,
                        "pageviews": 3898,
                        "engaged_minutes": 2253,
                        "ROA": 1.7324444444444445,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 14,
                        "pageviews": 20,
                        "engaged_minutes": 7,
                        "ROA": 2.8571428571428572,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 9383,
                        "pageviews": 13198,
                        "engaged_minutes": 4847,
                        "ROA": 5.3628606257618854,
                        "section": "الاقتصادي",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 2009,
                        "pageviews": 2240,
                        "engaged_minutes": 1682,
                        "ROA": 3.6187399030694669,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 11953,
                        "pageviews": 16460,
                        "engaged_minutes": 6243,
                        "ROA": 10.173053152039556,
                        "section": "الرياضي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 546,
                        "pageviews": 673,
                        "engaged_minutes": 328,
                        "ROA": 1.3651115618661258,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 2406,
                        "pageviews": 3135,
                        "engaged_minutes": 1803,
                        "ROA": 3.097826086956522,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 273,
                        "pageviews": 300,
                        "engaged_minutes": 196,
                        "ROA": 2.6315789473684212,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1489,
                        "pageviews": 1696,
                        "engaged_minutes": 1034,
                        "ROA": 3.1233885819521179,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 93,
                        "pageviews": 104,
                        "engaged_minutes": 31,
                        "ROA": 1.8245614035087718,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 93,
                        "pageviews": 101,
                        "engaged_minutes": 48,
                        "ROA": 1.3466666666666667,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 251,
                        "pageviews": 292,
                        "engaged_minutes": 178,
                        "ROA": 1.6497175141242937,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 33,
                        "pageviews": 36,
                        "engaged_minutes": 22,
                        "ROA": 1.7142857142857142,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 371,
                        "pageviews": 427,
                        "engaged_minutes": 400,
                        "ROA": 3.3622047244094486,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 143,
                        "pageviews": 160,
                        "engaged_minutes": 107,
                        "ROA": 2.1621621621621623,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 529,
                        "pageviews": 618,
                        "engaged_minutes": 396,
                        "ROA": 2.6186440677966103,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 92,
                        "pageviews": 104,
                        "engaged_minutes": 73,
                        "ROA": 2.6,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 31,
                        "pageviews": 34,
                        "engaged_minutes": 14,
                        "ROA": 2.2666666666666666,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 39,
                        "pageviews": 43,
                        "engaged_minutes": 20,
                        "ROA": 1.8695652173913044,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 76,
                        "pageviews": 94,
                        "engaged_minutes": 66,
                        "ROA": 1.4029850746268657,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 637,
                        "pageviews": 755,
                        "engaged_minutes": 353,
                        "ROA": 15.729166666666666,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 63,
                        "pageviews": 70,
                        "engaged_minutes": 47,
                        "ROA": 2.5925925925925926,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 26,
                        "pageviews": 27,
                        "engaged_minutes": 14,
                        "ROA": 1.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 8,
                        "ROA": 1.8571428571428572,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 4,
                        "ROA": 1.6666666666666667,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 2469,
                        "pageviews": 3038,
                        "engaged_minutes": 1433,
                        "ROA": 16.333333333333332,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 73,
                        "pageviews": 79,
                        "engaged_minutes": 21,
                        "ROA": 1.6458333333333333,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 20210,
                        "pageviews": 26606,
                        "engaged_minutes": 10547,
                        "ROA": 41.767660910518053,
                        "section": "العرب والعالم",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 1386,
                        "pageviews": 1572,
                        "engaged_minutes": 936,
                        "ROA": 2.3117647058823527,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 3285,
                        "pageviews": 4033,
                        "engaged_minutes": 1767,
                        "ROA": 4.5365579302587173,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 2560,
                        "pageviews": 3274,
                        "engaged_minutes": 1789,
                        "ROA": 3.3238578680203044,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 1511,
                        "pageviews": 1748,
                        "engaged_minutes": 980,
                        "ROA": 3.3745173745173744,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 2157,
                        "pageviews": 2397,
                        "engaged_minutes": 1715,
                        "ROA": 3.7688679245283021,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 69,
                        "pageviews": 75,
                        "engaged_minutes": 33,
                        "ROA": 3.2608695652173911,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 12257,
                        "pageviews": 18586,
                        "engaged_minutes": 6813,
                        "ROA": 13.5466472303207,
                        "section": "الإمارات",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 15740,
                        "pageviews": 19091,
                        "engaged_minutes": 6215,
                        "ROA": 11.283096926713949,
                        "section": "الاقتصادي",
                        "published_posts": 34
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 286,
                        "pageviews": 311,
                        "engaged_minutes": 210,
                        "ROA": 2.7043478260869565,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 764,
                        "pageviews": 851,
                        "engaged_minutes": 395,
                        "ROA": 1.415973377703827,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 11907,
                        "pageviews": 15286,
                        "engaged_minutes": 6184,
                        "ROA": 9.7301082113303625,
                        "section": "الرياضي",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 2282,
                        "pageviews": 2637,
                        "engaged_minutes": 1512,
                        "ROA": 1.8662420382165605,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 97,
                        "pageviews": 117,
                        "engaged_minutes": 81,
                        "ROA": 2.4375,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 3193,
                        "pageviews": 3764,
                        "engaged_minutes": 2066,
                        "ROA": 1.8587654320987654,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 33,
                        "pageviews": 36,
                        "engaged_minutes": 21,
                        "ROA": 1.7142857142857142,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 532,
                        "pageviews": 606,
                        "engaged_minutes": 285,
                        "ROA": 2.0542372881355933,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 336,
                        "pageviews": 391,
                        "engaged_minutes": 354,
                        "ROA": 3.1031746031746033,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 54,
                        "pageviews": 62,
                        "engaged_minutes": 43,
                        "ROA": 2.48,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 214,
                        "pageviews": 233,
                        "engaged_minutes": 59,
                        "ROA": 1.484076433121019,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 109,
                        "pageviews": 124,
                        "engaged_minutes": 42,
                        "ROA": 1.203883495145631,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 44371,
                        "pageviews": 54704,
                        "engaged_minutes": 27085,
                        "ROA": 48.755793226381464,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 250,
                        "pageviews": 288,
                        "engaged_minutes": 177,
                        "ROA": 1.5824175824175823,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 18371,
                        "pageviews": 21618,
                        "engaged_minutes": 17087,
                        "ROA": 76.659574468085111,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 3,
                        "ROA": 2,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 31,
                        "pageviews": 35,
                        "engaged_minutes": 20,
                        "ROA": 1.5909090909090908,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 1672,
                        "pageviews": 1986,
                        "engaged_minutes": 1005,
                        "ROA": 45.136363636363633,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 120,
                        "pageviews": 137,
                        "engaged_minutes": 65,
                        "ROA": 1.7792207792207793,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 34,
                        "pageviews": 41,
                        "engaged_minutes": 23,
                        "ROA": 2.1578947368421053,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 14,
                        "pageviews": 14,
                        "engaged_minutes": 4,
                        "ROA": 1.75,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 10,
                        "pageviews": 16,
                        "engaged_minutes": 5,
                        "ROA": 1.6,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 7,
                        "ROA": 4.333333333333333,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1808,
                        "pageviews": 2187,
                        "engaged_minutes": 1003,
                        "ROA": 12.217877094972067,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 333,
                        "pageviews": 355,
                        "engaged_minutes": 63,
                        "ROA": 1.792929292929293,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 28815,
                        "pageviews": 36539,
                        "engaged_minutes": 19083,
                        "ROA": 31.472006890611542,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1935,
                        "pageviews": 2136,
                        "engaged_minutes": 1617,
                        "ROA": 3.7083333333333335,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1350,
                        "pageviews": 1602,
                        "engaged_minutes": 885,
                        "ROA": 2.3150289017341041,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 259,
                        "pageviews": 280,
                        "engaged_minutes": 204,
                        "ROA": 2.5454545454545454,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1953,
                        "pageviews": 2537,
                        "engaged_minutes": 1152,
                        "ROA": 3.0383233532934133,
                        "section": "فكر وفن",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 11434,
                        "pageviews": 18328,
                        "engaged_minutes": 7088,
                        "ROA": 14.864557988645579,
                        "section": "الإمارات",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 493,
                        "pageviews": 556,
                        "engaged_minutes": 308,
                        "ROA": 1.3969849246231156,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1450,
                        "pageviews": 1662,
                        "engaged_minutes": 1010,
                        "ROA": 3.2910891089108909,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1.1428571428571428,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 2604,
                        "pageviews": 3037,
                        "engaged_minutes": 1775,
                        "ROA": 1.7698135198135199,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 15,
                        "pageviews": 20,
                        "engaged_minutes": 5,
                        "ROA": 1.5384615384615385,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 18544,
                        "pageviews": 25179,
                        "engaged_minutes": 8972,
                        "ROA": 40.808752025931931,
                        "section": "العرب والعالم",
                        "published_posts": 41
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 18247,
                        "pageviews": 23431,
                        "engaged_minutes": 5360,
                        "ROA": 10.497759856630825,
                        "section": "الاقتصادي",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 9417,
                        "pageviews": 11389,
                        "engaged_minutes": 8482,
                        "ROA": 39.137457044673539,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 16498,
                        "pageviews": 21596,
                        "engaged_minutes": 10065,
                        "ROA": 14.532974427994617,
                        "section": "الرياضي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 2624,
                        "pageviews": 3382,
                        "engaged_minutes": 2282,
                        "ROA": 4.1701602959309492,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 2169,
                        "pageviews": 2402,
                        "engaged_minutes": 1275,
                        "ROA": 1.7622890682318415,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 81,
                        "pageviews": 89,
                        "engaged_minutes": 57,
                        "ROA": 2.1190476190476191,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 235,
                        "pageviews": 273,
                        "engaged_minutes": 164,
                        "ROA": 1.6545454545454545,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 345,
                        "pageviews": 392,
                        "engaged_minutes": 331,
                        "ROA": 2.9253731343283582,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 97,
                        "pageviews": 104,
                        "engaged_minutes": 32,
                        "ROA": 1.223529411764706,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 59,
                        "pageviews": 70,
                        "engaged_minutes": 37,
                        "ROA": 1.1864406779661016,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 52,
                        "pageviews": 54,
                        "engaged_minutes": 15,
                        "ROA": 1.3170731707317074,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 395,
                        "pageviews": 465,
                        "engaged_minutes": 279,
                        "ROA": 2.13302752293578,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.6666666666666667,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 24,
                        "pageviews": 27,
                        "engaged_minutes": 8,
                        "ROA": 2.0769230769230771,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 40,
                        "pageviews": 49,
                        "engaged_minutes": 24,
                        "ROA": 5.4444444444444446,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 68,
                        "pageviews": 70,
                        "engaged_minutes": 53,
                        "ROA": 2.4137931034482758,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1888,
                        "pageviews": 2163,
                        "engaged_minutes": 1004,
                        "ROA": 60.083333333333336,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 133,
                        "pageviews": 143,
                        "engaged_minutes": 88,
                        "ROA": 2.1029411764705883,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 1.1428571428571428,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 26,
                        "pageviews": 32,
                        "engaged_minutes": 15,
                        "ROA": 1.7777777777777777,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 2.25,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 10,
                        "pageviews": 12,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 4542,
                        "pageviews": 5689,
                        "engaged_minutes": 3016,
                        "ROA": 20.537906137184116,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 35,
                        "pageviews": 43,
                        "engaged_minutes": 11,
                        "ROA": 2.263157894736842,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 70,
                        "pageviews": 78,
                        "engaged_minutes": 28,
                        "ROA": 1.625,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 300,
                        "pageviews": 375,
                        "engaged_minutes": 146,
                        "ROA": 7.8125,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 12241,
                        "pageviews": 17233,
                        "engaged_minutes": 7864,
                        "ROA": 15.156552330694812,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 10173,
                        "pageviews": 13833,
                        "engaged_minutes": 5529,
                        "ROA": 6.36,
                        "section": "الاقتصادي",
                        "published_posts": 41
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 1927,
                        "pageviews": 2160,
                        "engaged_minutes": 1554,
                        "ROA": 3.4285714285714284,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 44,
                        "pageviews": 58,
                        "engaged_minutes": 17,
                        "ROA": 4.4615384615384617,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 532,
                        "pageviews": 616,
                        "engaged_minutes": 325,
                        "ROA": 1.4987834549878345,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 6084,
                        "pageviews": 7328,
                        "engaged_minutes": 6257,
                        "ROA": 8.46189376443418,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 701,
                        "pageviews": 918,
                        "engaged_minutes": 335,
                        "ROA": 5.4,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 2685,
                        "pageviews": 3172,
                        "engaged_minutes": 1861,
                        "ROA": 1.8271889400921659,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 13414,
                        "pageviews": 22190,
                        "engaged_minutes": 8629,
                        "ROA": 16.659159159159159,
                        "section": "الإمارات",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 53,
                        "pageviews": 62,
                        "engaged_minutes": 38,
                        "ROA": 1.2916666666666667,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 18,
                        "pageviews": 18,
                        "engaged_minutes": 7,
                        "ROA": 1.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 9898,
                        "pageviews": 14710,
                        "engaged_minutes": 5890,
                        "ROA": 24.35430463576159,
                        "section": "العرب والعالم",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 271,
                        "pageviews": 302,
                        "engaged_minutes": 218,
                        "ROA": 3.02,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 20506,
                        "pageviews": 26700,
                        "engaged_minutes": 12033,
                        "ROA": 17.8,
                        "section": "الرياضي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 2114,
                        "pageviews": 2400,
                        "engaged_minutes": 1341,
                        "ROA": 1.8085908063300677,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 309,
                        "pageviews": 349,
                        "engaged_minutes": 324,
                        "ROA": 2.585185185185185,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 1398,
                        "pageviews": 1648,
                        "engaged_minutes": 954,
                        "ROA": 3.2440944881889764,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 79,
                        "pageviews": 85,
                        "engaged_minutes": 49,
                        "ROA": 2.236842105263158,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 949,
                        "pageviews": 1165,
                        "engaged_minutes": 836,
                        "ROA": 2.1105072463768115,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 3144,
                        "pageviews": 4053,
                        "engaged_minutes": 1831,
                        "ROA": 4.8422939068100357,
                        "section": "فكر وفن",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 31,
                        "pageviews": 36,
                        "engaged_minutes": 20,
                        "ROA": 2.5714285714285716,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 69,
                        "pageviews": 71,
                        "engaged_minutes": 66,
                        "ROA": 2.3666666666666667,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 381,
                        "pageviews": 431,
                        "engaged_minutes": 264,
                        "ROA": 1.959090909090909,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 71,
                        "pageviews": 88,
                        "engaged_minutes": 54,
                        "ROA": 1.3333333333333333,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 247,
                        "pageviews": 286,
                        "engaged_minutes": 151,
                        "ROA": 1.7228915662650603,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 34,
                        "pageviews": 37,
                        "engaged_minutes": 22,
                        "ROA": 1.85,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 97,
                        "pageviews": 109,
                        "engaged_minutes": 73,
                        "ROA": 1.7868852459016393,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 1.125,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 6,
                        "ROA": 1.75,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 2006,
                        "pageviews": 2298,
                        "engaged_minutes": 1319,
                        "ROA": 1.6946902654867257,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 41,
                        "pageviews": 45,
                        "engaged_minutes": 23,
                        "ROA": 1.875,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 14625,
                        "pageviews": 24547,
                        "engaged_minutes": 10022,
                        "ROA": 15.256059664387818,
                        "section": "الإمارات",
                        "published_posts": 75
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 11609,
                        "pageviews": 17620,
                        "engaged_minutes": 7816,
                        "ROA": 14.894336432797971,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 7203,
                        "pageviews": 10335,
                        "engaged_minutes": 3748,
                        "ROA": 16.914893617021278,
                        "section": "العرب والعالم",
                        "published_posts": 40
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 2363,
                        "pageviews": 3195,
                        "engaged_minutes": 1980,
                        "ROA": 3.3953241232731135,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1347,
                        "pageviews": 1547,
                        "engaged_minutes": 889,
                        "ROA": 2.891588785046729,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 4150,
                        "pageviews": 5238,
                        "engaged_minutes": 3389,
                        "ROA": 16.166666666666668,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 22360,
                        "pageviews": 28151,
                        "engaged_minutes": 10962,
                        "ROA": 18.010876519513754,
                        "section": "الرياضي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 526,
                        "pageviews": 600,
                        "engaged_minutes": 346,
                        "ROA": 1.4851485148514851,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 3160,
                        "pageviews": 3777,
                        "engaged_minutes": 2206,
                        "ROA": 1.7715759849906192,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1865,
                        "pageviews": 2064,
                        "engaged_minutes": 1544,
                        "ROA": 3.3615635179153096,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 52,
                        "pageviews": 62,
                        "engaged_minutes": 44,
                        "ROA": 2.2962962962962963,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1938,
                        "pageviews": 2558,
                        "engaged_minutes": 1023,
                        "ROA": 2.7925764192139737,
                        "section": "فكر وفن",
                        "published_posts": 27
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 13885,
                        "pageviews": 18729,
                        "engaged_minutes": 7808,
                        "ROA": 7.7810552555047776,
                        "section": "الاقتصادي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 44,
                        "pageviews": 46,
                        "engaged_minutes": 21,
                        "ROA": 1.9166666666666667,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 951,
                        "pageviews": 1130,
                        "engaged_minutes": 805,
                        "ROA": 2.073394495412844,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1522,
                        "pageviews": 1833,
                        "engaged_minutes": 669,
                        "ROA": 8.6872037914691944,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 219,
                        "pageviews": 244,
                        "engaged_minutes": 147,
                        "ROA": 1.5345911949685536,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 274,
                        "pageviews": 298,
                        "engaged_minutes": 199,
                        "ROA": 2.5689655172413794,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 75,
                        "pageviews": 83,
                        "engaged_minutes": 28,
                        "ROA": 1.4310344827586208,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 448,
                        "pageviews": 531,
                        "engaged_minutes": 308,
                        "ROA": 1.9594095940959411,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 79,
                        "pageviews": 91,
                        "engaged_minutes": 50,
                        "ROA": 1.3,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 59,
                        "pageviews": 66,
                        "engaged_minutes": 46,
                        "ROA": 1.2,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 3,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.6666666666666667,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 50,
                        "pageviews": 55,
                        "engaged_minutes": 35,
                        "ROA": 2.5,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 411,
                        "pageviews": 498,
                        "engaged_minutes": 142,
                        "ROA": 7.78125,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 340,
                        "pageviews": 398,
                        "engaged_minutes": 344,
                        "ROA": 2.9264705882352939,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 4,
                        "ROA": 1.1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 75,
                        "pageviews": 85,
                        "engaged_minutes": 50,
                        "ROA": 2.125,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 115,
                        "pageviews": 128,
                        "engaged_minutes": 85,
                        "ROA": 1.7066666666666668,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 31,
                        "pageviews": 39,
                        "engaged_minutes": 18,
                        "ROA": 2.0526315789473686,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 8,
                        "ROA": 1.8571428571428572,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 15,
                        "pageviews": 20,
                        "engaged_minutes": 15,
                        "ROA": 2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 15,
                        "pageviews": 20,
                        "engaged_minutes": 6,
                        "ROA": 1.5384615384615385,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 663,
                        "pageviews": 859,
                        "engaged_minutes": 322,
                        "ROA": 4.5935828877005349,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 344,
                        "pageviews": 390,
                        "engaged_minutes": 342,
                        "ROA": 2.8467153284671531,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 12289,
                        "pageviews": 17795,
                        "engaged_minutes": 7806,
                        "ROA": 15.393598615916956,
                        "section": "منوعات",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 3509,
                        "pageviews": 4315,
                        "engaged_minutes": 2415,
                        "ROA": 1.7994161801501252,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 45,
                        "pageviews": 55,
                        "engaged_minutes": 33,
                        "ROA": 2.1153846153846154,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1730,
                        "pageviews": 2259,
                        "engaged_minutes": 968,
                        "ROA": 2.6451990632318503,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 134,
                        "pageviews": 140,
                        "engaged_minutes": 26,
                        "ROA": 1.1764705882352942,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1466,
                        "pageviews": 1640,
                        "engaged_minutes": 993,
                        "ROA": 3.1238095238095238,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 13743,
                        "pageviews": 23952,
                        "engaged_minutes": 10058,
                        "ROA": 14.257142857142858,
                        "section": "الإمارات",
                        "published_posts": 105
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 14961,
                        "pageviews": 19023,
                        "engaged_minutes": 8634,
                        "ROA": 8.07427843803056,
                        "section": "الاقتصادي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 7859,
                        "pageviews": 11531,
                        "engaged_minutes": 4395,
                        "ROA": 20.591071428571428,
                        "section": "العرب والعالم",
                        "published_posts": 23
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 15417,
                        "pageviews": 21019,
                        "engaged_minutes": 8321,
                        "ROA": 13.491014120667522,
                        "section": "الرياضي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 2662,
                        "pageviews": 3589,
                        "engaged_minutes": 2016,
                        "ROA": 3.6622448979591837,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 548,
                        "pageviews": 660,
                        "engaged_minutes": 373,
                        "ROA": 1.4347826086956521,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 2216,
                        "pageviews": 2560,
                        "engaged_minutes": 1475,
                        "ROA": 1.7522245037645447,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 93,
                        "pageviews": 104,
                        "engaged_minutes": 73,
                        "ROA": 2.6,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 2069,
                        "pageviews": 2265,
                        "engaged_minutes": 1723,
                        "ROA": 3.6473429951690823,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 245,
                        "pageviews": 302,
                        "engaged_minutes": 158,
                        "ROA": 1.7062146892655368,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.375,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 302,
                        "pageviews": 368,
                        "engaged_minutes": 126,
                        "ROA": 8.3636363636363633,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 289,
                        "pageviews": 311,
                        "engaged_minutes": 212,
                        "ROA": 2.7280701754385963,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 486,
                        "pageviews": 542,
                        "engaged_minutes": 338,
                        "ROA": 2.0608365019011408,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 75,
                        "pageviews": 85,
                        "engaged_minutes": 48,
                        "ROA": 1.25,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1022,
                        "pageviews": 1209,
                        "engaged_minutes": 907,
                        "ROA": 2.0016556291390728,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 14,
                        "pageviews": 14,
                        "engaged_minutes": 10,
                        "ROA": 7,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 136,
                        "pageviews": 155,
                        "engaged_minutes": 102,
                        "ROA": 2.3846153846153846,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 66,
                        "pageviews": 69,
                        "engaged_minutes": 50,
                        "ROA": 3,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 93,
                        "pageviews": 115,
                        "engaged_minutes": 58,
                        "ROA": 1.513157894736842,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 5472,
                        "pageviews": 6905,
                        "engaged_minutes": 4735,
                        "ROA": 23.406779661016948,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 25,
                        "pageviews": 30,
                        "engaged_minutes": 14,
                        "ROA": 1.5,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 15,
                        "pageviews": 17,
                        "engaged_minutes": 13,
                        "ROA": 1.4166666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 25,
                        "pageviews": 35,
                        "engaged_minutes": 16,
                        "ROA": 2.5,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 27,
                        "pageviews": 31,
                        "engaged_minutes": 12,
                        "ROA": 2.5833333333333335,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 8,
                        "ROA": 1.5714285714285714,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 6,
                        "ROA": 1.5,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1.1666666666666667,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 1618,
                        "pageviews": 1854,
                        "engaged_minutes": 998,
                        "ROA": 3.1530612244897958,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 13423,
                        "pageviews": 22672,
                        "engaged_minutes": 8326,
                        "ROA": 13.74060606060606,
                        "section": "الإمارات",
                        "published_posts": 77
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 7469,
                        "pageviews": 10931,
                        "engaged_minutes": 5165,
                        "ROA": 17.106416275430359,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 2619,
                        "pageviews": 3417,
                        "engaged_minutes": 1945,
                        "ROA": 3.0346358792184724,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 90,
                        "pageviews": 104,
                        "engaged_minutes": 69,
                        "ROA": 2.4761904761904763,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 13380,
                        "pageviews": 17219,
                        "engaged_minutes": 8175,
                        "ROA": 15.117647058823529,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 785,
                        "pageviews": 982,
                        "engaged_minutes": 377,
                        "ROA": 4.7902439024390242,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 284,
                        "pageviews": 309,
                        "engaged_minutes": 202,
                        "ROA": 2.7105263157894739,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 11,
                        "pageviews": 14,
                        "engaged_minutes": 6,
                        "ROA": 1.5555555555555556,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 2370,
                        "pageviews": 2985,
                        "engaged_minutes": 1194,
                        "ROA": 2.9554455445544554,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 3607,
                        "pageviews": 4271,
                        "engaged_minutes": 2407,
                        "ROA": 1.7504098360655738,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 41,
                        "pageviews": 42,
                        "engaged_minutes": 12,
                        "ROA": 1.2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 11801,
                        "pageviews": 15768,
                        "engaged_minutes": 7060,
                        "ROA": 6.0880308880308878,
                        "section": "الاقتصادي",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 759,
                        "pageviews": 838,
                        "engaged_minutes": 375,
                        "ROA": 1.4991055456171736,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 82,
                        "pageviews": 98,
                        "engaged_minutes": 49,
                        "ROA": 1.380281690140845,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 2224,
                        "pageviews": 2579,
                        "engaged_minutes": 1428,
                        "ROA": 1.754421768707483,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 635,
                        "pageviews": 719,
                        "engaged_minutes": 378,
                        "ROA": 1.9327956989247312,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 15202,
                        "pageviews": 20099,
                        "engaged_minutes": 7517,
                        "ROA": 12.166464891041162,
                        "section": "الرياضي",
                        "published_posts": 48
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 303,
                        "pageviews": 334,
                        "engaged_minutes": 266,
                        "ROA": 2.3034482758620691,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 77,
                        "pageviews": 82,
                        "engaged_minutes": 56,
                        "ROA": 2.4848484848484849,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 2084,
                        "pageviews": 2373,
                        "engaged_minutes": 1737,
                        "ROA": 3.7252747252747254,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 1403,
                        "pageviews": 1578,
                        "engaged_minutes": 887,
                        "ROA": 2.0387596899224807,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 9072,
                        "pageviews": 11284,
                        "engaged_minutes": 7848,
                        "ROA": 35.93630573248408,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 245,
                        "pageviews": 303,
                        "engaged_minutes": 187,
                        "ROA": 1.6833333333333333,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 146,
                        "pageviews": 167,
                        "engaged_minutes": 111,
                        "ROA": 2.168831168831169,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 213,
                        "pageviews": 219,
                        "engaged_minutes": 49,
                        "ROA": 2.0857142857142859,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 41,
                        "pageviews": 46,
                        "engaged_minutes": 19,
                        "ROA": 2.5555555555555554,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 95,
                        "pageviews": 104,
                        "engaged_minutes": 24,
                        "ROA": 1.4857142857142858,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 61,
                        "pageviews": 63,
                        "engaged_minutes": 26,
                        "ROA": 1.4651162790697674,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 159,
                        "pageviews": 212,
                        "engaged_minutes": 93,
                        "ROA": 3.7857142857142856,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 4,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 22,
                        "pageviews": 26,
                        "engaged_minutes": 11,
                        "ROA": 1.5294117647058822,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 10,
                        "ROA": 1.125,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 30,
                        "pageviews": 33,
                        "engaged_minutes": 15,
                        "ROA": 1.4347826086956521,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 7,
                        "ROA": 1.4444444444444444,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 2.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 6,
                        "ROA": 2.6666666666666665,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 3,
                        "pageviews": 13,
                        "engaged_minutes": 3,
                        "ROA": 2.1666666666666665,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 9244,
                        "pageviews": 13757,
                        "engaged_minutes": 5345,
                        "ROA": 22.552459016393442,
                        "section": "العرب والعالم",
                        "published_posts": 40
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 2332,
                        "pageviews": 3076,
                        "engaged_minutes": 1625,
                        "ROA": 2.7862318840579712,
                        "section": "اتجاهات",
                        "published_posts": 15
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1703,
                        "pageviews": 1949,
                        "engaged_minutes": 1110,
                        "ROA": 3.4991023339317775,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 64,
                        "pageviews": 77,
                        "engaged_minutes": 28,
                        "ROA": 1.673913043478261,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 14789,
                        "pageviews": 24983,
                        "engaged_minutes": 10263,
                        "ROA": 15.393099199014172,
                        "section": "الإمارات",
                        "published_posts": 78
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 24715,
                        "pageviews": 32222,
                        "engaged_minutes": 17367,
                        "ROA": 28.743978590544156,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 6405,
                        "pageviews": 9595,
                        "engaged_minutes": 3612,
                        "ROA": 3.9862899875363524,
                        "section": "الاقتصادي",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 718,
                        "pageviews": 817,
                        "engaged_minutes": 330,
                        "ROA": 1.3824027072758038,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 12344,
                        "pageviews": 16610,
                        "engaged_minutes": 6323,
                        "ROA": 10.884665792922673,
                        "section": "الرياضي",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1398,
                        "pageviews": 1626,
                        "engaged_minutes": 926,
                        "ROA": 2.1593625498007967,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1927,
                        "pageviews": 2474,
                        "engaged_minutes": 932,
                        "ROA": 2.609704641350211,
                        "section": "فكر وفن",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 2679,
                        "pageviews": 3032,
                        "engaged_minutes": 1544,
                        "ROA": 1.8867454884878656,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 192,
                        "pageviews": 271,
                        "engaged_minutes": 96,
                        "ROA": 4.442622950819672,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 402,
                        "pageviews": 418,
                        "engaged_minutes": 82,
                        "ROA": 2.5962732919254656,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 48,
                        "pageviews": 51,
                        "engaged_minutes": 18,
                        "ROA": 1.1860465116279071,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1336,
                        "pageviews": 1667,
                        "engaged_minutes": 654,
                        "ROA": 8.6822916666666661,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 8298,
                        "pageviews": 10560,
                        "engaged_minutes": 6790,
                        "ROA": 39.849056603773583,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 2113,
                        "pageviews": 2369,
                        "engaged_minutes": 1671,
                        "ROA": 3.4234104046242773,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 299,
                        "pageviews": 322,
                        "engaged_minutes": 228,
                        "ROA": 2.8495575221238938,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 3646,
                        "pageviews": 4325,
                        "engaged_minutes": 2496,
                        "ROA": 1.8482905982905984,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 159,
                        "pageviews": 190,
                        "engaged_minutes": 96,
                        "ROA": 2.375,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 652,
                        "pageviews": 730,
                        "engaged_minutes": 400,
                        "ROA": 2.3028391167192428,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 272,
                        "pageviews": 326,
                        "engaged_minutes": 176,
                        "ROA": 1.6464646464646464,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 109,
                        "pageviews": 118,
                        "engaged_minutes": 44,
                        "ROA": 1.18,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 103,
                        "pageviews": 111,
                        "engaged_minutes": 82,
                        "ROA": 2.3125,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 354,
                        "pageviews": 409,
                        "engaged_minutes": 338,
                        "ROA": 2.9637681159420288,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 14,
                        "pageviews": 17,
                        "engaged_minutes": 7,
                        "ROA": 2.125,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 41,
                        "pageviews": 47,
                        "engaged_minutes": 21,
                        "ROA": 2.2380952380952381,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 4,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 44,
                        "pageviews": 51,
                        "engaged_minutes": 27,
                        "ROA": 2.3181818181818183,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 56,
                        "pageviews": 65,
                        "engaged_minutes": 43,
                        "ROA": 2.2413793103448274,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 39,
                        "pageviews": 39,
                        "engaged_minutes": 19,
                        "ROA": 1.6956521739130435,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 46,
                        "pageviews": 54,
                        "engaged_minutes": 16,
                        "ROA": 2.347826086956522,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 10,
                        "ROA": 2.4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.25,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 6,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 1.8,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "مسابقة التسامح        ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.5714285714285714,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 395,
                        "pageviews": 447,
                        "engaged_minutes": 448,
                        "ROA": 3.3358208955223883,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2292,
                        "pageviews": 2615,
                        "engaged_minutes": 1519,
                        "ROA": 1.8046928916494134,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 271,
                        "pageviews": 276,
                        "engaged_minutes": 59,
                        "ROA": 1.8648648648648649,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2126,
                        "pageviews": 2382,
                        "engaged_minutes": 1728,
                        "ROA": 3.8051118210862618,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 36723,
                        "pageviews": 47249,
                        "engaged_minutes": 28852,
                        "ROA": 39.081058726220014,
                        "section": "منوعات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 5615,
                        "pageviews": 8063,
                        "engaged_minutes": 3038,
                        "ROA": 3.6124551971326166,
                        "section": "الاقتصادي",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 56,
                        "pageviews": 65,
                        "engaged_minutes": 33,
                        "ROA": 2.3214285714285716,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2527,
                        "pageviews": 3097,
                        "engaged_minutes": 1144,
                        "ROA": 15.562814070351759,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 9522,
                        "pageviews": 12078,
                        "engaged_minutes": 6636,
                        "ROA": 40.530201342281877,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2205,
                        "pageviews": 3021,
                        "engaged_minutes": 1615,
                        "ROA": 2.8128491620111733,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 1712,
                        "pageviews": 1948,
                        "engaged_minutes": 1070,
                        "ROA": 3.6074074074074076,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 3129,
                        "pageviews": 3701,
                        "engaged_minutes": 2171,
                        "ROA": 1.8124387855044075,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 16064,
                        "pageviews": 24301,
                        "engaged_minutes": 10135,
                        "ROA": 16.981830887491263,
                        "section": "الإمارات",
                        "published_posts": 88
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 9737,
                        "pageviews": 13484,
                        "engaged_minutes": 5670,
                        "ROA": 22.214168039538716,
                        "section": "العرب والعالم",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 77,
                        "pageviews": 87,
                        "engaged_minutes": 21,
                        "ROA": 1.6415094339622642,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 8579,
                        "pageviews": 10998,
                        "engaged_minutes": 4983,
                        "ROA": 7.4210526315789478,
                        "section": "الرياضي",
                        "published_posts": 34
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 11,
                        "pageviews": 13,
                        "engaged_minutes": 7,
                        "ROA": 1.8571428571428572,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2183,
                        "pageviews": 2808,
                        "engaged_minutes": 1100,
                        "ROA": 3.2613240418118465,
                        "section": "فكر وفن",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 314,
                        "pageviews": 354,
                        "engaged_minutes": 237,
                        "ROA": 2.901639344262295,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 1204,
                        "pageviews": 1415,
                        "engaged_minutes": 867,
                        "ROA": 2.0243204577968528,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 544,
                        "pageviews": 634,
                        "engaged_minutes": 327,
                        "ROA": 1.4608294930875576,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 233,
                        "pageviews": 285,
                        "engaged_minutes": 175,
                        "ROA": 1.5833333333333333,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 93,
                        "pageviews": 111,
                        "engaged_minutes": 69,
                        "ROA": 2.6428571428571428,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 166,
                        "pageviews": 196,
                        "engaged_minutes": 104,
                        "ROA": 2.5454545454545454,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 78,
                        "pageviews": 87,
                        "engaged_minutes": 66,
                        "ROA": 2.5588235294117645,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 439,
                        "pageviews": 516,
                        "engaged_minutes": 309,
                        "ROA": 2.0806451612903225,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 97,
                        "pageviews": 104,
                        "engaged_minutes": 37,
                        "ROA": 1.2093023255813953,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 224,
                        "pageviews": 298,
                        "engaged_minutes": 125,
                        "ROA": 4.806451612903226,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 17,
                        "pageviews": 21,
                        "engaged_minutes": 16,
                        "ROA": 1.6153846153846154,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 13,
                        "pageviews": 17,
                        "engaged_minutes": 5,
                        "ROA": 1.5454545454545454,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 6,
                        "ROA": 2.2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 78,
                        "pageviews": 82,
                        "engaged_minutes": 30,
                        "ROA": 2.2162162162162162,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 8,
                        "pageviews": 12,
                        "engaged_minutes": 7,
                        "ROA": 4,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 76,
                        "pageviews": 84,
                        "engaged_minutes": 35,
                        "ROA": 5.6,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 28,
                        "pageviews": 31,
                        "engaged_minutes": 25,
                        "ROA": 1.4761904761904763,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.6666666666666667,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 2801,
                        "pageviews": 3544,
                        "engaged_minutes": 1454,
                        "ROA": 4.1305361305361306,
                        "section": "فكر وفن",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 30,
                        "pageviews": 33,
                        "engaged_minutes": 19,
                        "ROA": 1.9411764705882353,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 2088,
                        "pageviews": 2336,
                        "engaged_minutes": 1613,
                        "ROA": 3.65,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 1264,
                        "pageviews": 1427,
                        "engaged_minutes": 813,
                        "ROA": 2.165402124430956,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 1949,
                        "pageviews": 2810,
                        "engaged_minutes": 1549,
                        "ROA": 3.2674418604651163,
                        "section": "اتجاهات",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 85,
                        "pageviews": 88,
                        "engaged_minutes": 63,
                        "ROA": 1.9555555555555555,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 39070,
                        "pageviews": 51524,
                        "engaged_minutes": 31992,
                        "ROA": 43.443507588532881,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 1812,
                        "pageviews": 2186,
                        "engaged_minutes": 720,
                        "ROA": 12.077348066298342,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 11512,
                        "pageviews": 16669,
                        "engaged_minutes": 6722,
                        "ROA": 27.461285008237233,
                        "section": "العرب والعالم",
                        "published_posts": 33
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 2635,
                        "pageviews": 3106,
                        "engaged_minutes": 1785,
                        "ROA": 1.8324483775811209,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 2546,
                        "pageviews": 2804,
                        "engaged_minutes": 1393,
                        "ROA": 1.8971583220568335,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 107,
                        "pageviews": 122,
                        "engaged_minutes": 36,
                        "ROA": 1.2978723404255319,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 17680,
                        "pageviews": 25423,
                        "engaged_minutes": 9475,
                        "ROA": 21.841065292096221,
                        "section": "الإمارات",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 21,
                        "pageviews": 24,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 593,
                        "pageviews": 673,
                        "engaged_minutes": 268,
                        "ROA": 1.3326732673267327,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 11438,
                        "pageviews": 14590,
                        "engaged_minutes": 6064,
                        "ROA": 9.3886743886743886,
                        "section": "الرياضي",
                        "published_posts": 42
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 193,
                        "pageviews": 224,
                        "engaged_minutes": 126,
                        "ROA": 1.5664335664335665,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 5037,
                        "pageviews": 7216,
                        "engaged_minutes": 2286,
                        "ROA": 3.6098049024512258,
                        "section": "الاقتصادي",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 109,
                        "pageviews": 129,
                        "engaged_minutes": 55,
                        "ROA": 1.7671232876712328,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 1653,
                        "pageviews": 1881,
                        "engaged_minutes": 1048,
                        "ROA": 3.5093283582089554,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 235,
                        "pageviews": 304,
                        "engaged_minutes": 151,
                        "ROA": 5.96078431372549,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 322,
                        "pageviews": 353,
                        "engaged_minutes": 242,
                        "ROA": 3.0695652173913044,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 6,
                        "ROA": 1.5,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 401,
                        "pageviews": 439,
                        "engaged_minutes": 227,
                        "ROA": 1.7918367346938775,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 48,
                        "pageviews": 51,
                        "engaged_minutes": 19,
                        "ROA": 1.4571428571428571,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 66,
                        "pageviews": 75,
                        "engaged_minutes": 49,
                        "ROA": 1.3157894736842106,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 24,
                        "pageviews": 29,
                        "engaged_minutes": 11,
                        "ROA": 1.5263157894736843,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 9344,
                        "pageviews": 11509,
                        "engaged_minutes": 5349,
                        "ROA": 37.1258064516129,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 308,
                        "pageviews": 354,
                        "engaged_minutes": 277,
                        "ROA": 2.7022900763358777,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 53,
                        "pageviews": 68,
                        "engaged_minutes": 17,
                        "ROA": 1.6585365853658536,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 67,
                        "pageviews": 71,
                        "engaged_minutes": 50,
                        "ROA": 2.84,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 11,
                        "pageviews": 16,
                        "engaged_minutes": 7,
                        "ROA": 2.6666666666666665,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 15,
                        "pageviews": 15,
                        "engaged_minutes": 7,
                        "ROA": 1,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 3.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 4,
                        "ROA": 2.5,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 4725,
                        "pageviews": 6100,
                        "engaged_minutes": 2847,
                        "ROA": 20.132013201320131,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 26259,
                        "pageviews": 34399,
                        "engaged_minutes": 16367,
                        "ROA": 29.350682593856654,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1825,
                        "pageviews": 2400,
                        "engaged_minutes": 1307,
                        "ROA": 2.7842227378190256,
                        "section": "اتجاهات",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 21290,
                        "pageviews": 27089,
                        "engaged_minutes": 11269,
                        "ROA": 18.427891156462586,
                        "section": "الرياضي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1517,
                        "pageviews": 1735,
                        "engaged_minutes": 985,
                        "ROA": 3.47,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 12745,
                        "pageviews": 21097,
                        "engaged_minutes": 7981,
                        "ROA": 17.124188311688311,
                        "section": "الإمارات",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1054,
                        "pageviews": 1311,
                        "engaged_minutes": 685,
                        "ROA": 6.6212121212121211,
                        "section": "التقنية",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 2686,
                        "pageviews": 3208,
                        "engaged_minutes": 1764,
                        "ROA": 1.8114059853190287,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 13,
                        "pageviews": 16,
                        "engaged_minutes": 3,
                        "ROA": 1.7777777777777777,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 530,
                        "pageviews": 599,
                        "engaged_minutes": 285,
                        "ROA": 1.3644646924829158,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 2227,
                        "pageviews": 2515,
                        "engaged_minutes": 1401,
                        "ROA": 1.7673928320449754,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 983,
                        "pageviews": 1184,
                        "engaged_minutes": 751,
                        "ROA": 1.8853503184713376,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 5135,
                        "pageviews": 7723,
                        "engaged_minutes": 2560,
                        "ROA": 3.9403061224489795,
                        "section": "الاقتصادي",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 315,
                        "pageviews": 358,
                        "engaged_minutes": 234,
                        "ROA": 3.3773584905660377,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 195,
                        "pageviews": 229,
                        "engaged_minutes": 143,
                        "ROA": 1.5684931506849316,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 2511,
                        "pageviews": 3111,
                        "engaged_minutes": 1318,
                        "ROA": 3.7035714285714287,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 86,
                        "pageviews": 97,
                        "engaged_minutes": 63,
                        "ROA": 1.7636363636363637,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 14519,
                        "pageviews": 20020,
                        "engaged_minutes": 11771,
                        "ROA": 32.819672131147541,
                        "section": "العرب والعالم",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1938,
                        "pageviews": 2174,
                        "engaged_minutes": 1561,
                        "ROA": 3.65993265993266,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 320,
                        "pageviews": 350,
                        "engaged_minutes": 321,
                        "ROA": 2.6315789473684212,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 306,
                        "pageviews": 391,
                        "engaged_minutes": 188,
                        "ROA": 6.9821428571428568,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 546,
                        "pageviews": 592,
                        "engaged_minutes": 266,
                        "ROA": 1.9283387622149837,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 35,
                        "pageviews": 37,
                        "engaged_minutes": 21,
                        "ROA": 1.4230769230769231,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 100,
                        "pageviews": 117,
                        "engaged_minutes": 76,
                        "ROA": 2.4375,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 69,
                        "pageviews": 80,
                        "engaged_minutes": 29,
                        "ROA": 1.3793103448275863,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 79,
                        "pageviews": 90,
                        "engaged_minutes": 59,
                        "ROA": 1.4754098360655739,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 5,
                        "pageviews": 7,
                        "engaged_minutes": 6,
                        "ROA": 1.4,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 26,
                        "pageviews": 27,
                        "engaged_minutes": 11,
                        "ROA": 1.588235294117647,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 60,
                        "pageviews": 62,
                        "engaged_minutes": 25,
                        "ROA": 1.55,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 72,
                        "pageviews": 81,
                        "engaged_minutes": 61,
                        "ROA": 2.7931034482758621,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 13,
                        "pageviews": 13,
                        "engaged_minutes": 10,
                        "ROA": 1.625,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 7,
                        "pageviews": 12,
                        "engaged_minutes": 8,
                        "ROA": 1.7142857142857142,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 42,
                        "pageviews": 47,
                        "engaged_minutes": 21,
                        "ROA": 2.7647058823529411,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 8,
                        "ROA": 1.1666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 17711,
                        "pageviews": 29148,
                        "engaged_minutes": 11702,
                        "ROA": 19.76135593220339,
                        "section": "الإمارات",
                        "published_posts": 85
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 886,
                        "pageviews": 1062,
                        "engaged_minutes": 783,
                        "ROA": 1.9887640449438202,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 2009,
                        "pageviews": 2727,
                        "engaged_minutes": 1405,
                        "ROA": 2.7601214574898787,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 11566,
                        "pageviews": 17612,
                        "engaged_minutes": 8054,
                        "ROA": 15.143594153052451,
                        "section": "منوعات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 16116,
                        "pageviews": 21583,
                        "engaged_minutes": 8396,
                        "ROA": 14.024041585445094,
                        "section": "الرياضي",
                        "published_posts": 34
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 513,
                        "pageviews": 599,
                        "engaged_minutes": 330,
                        "ROA": 1.3490990990990992,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 2293,
                        "pageviews": 2598,
                        "engaged_minutes": 1484,
                        "ROA": 1.8412473423104181,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 300,
                        "pageviews": 332,
                        "engaged_minutes": 251,
                        "ROA": 2.8620689655172415,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1371,
                        "pageviews": 1758,
                        "engaged_minutes": 766,
                        "ROA": 8.5756097560975615,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 43,
                        "pageviews": 46,
                        "engaged_minutes": 18,
                        "ROA": 1.84,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 63,
                        "pageviews": 70,
                        "engaged_minutes": 25,
                        "ROA": 3.3333333333333335,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 3222,
                        "pageviews": 4095,
                        "engaged_minutes": 1797,
                        "ROA": 4.5652173913043477,
                        "section": "فكر وفن",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 6943,
                        "pageviews": 10098,
                        "engaged_minutes": 3624,
                        "ROA": 4.5568592057761732,
                        "section": "الاقتصادي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 3324,
                        "pageviews": 4008,
                        "engaged_minutes": 2241,
                        "ROA": 1.7997305792546026,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 104,
                        "pageviews": 119,
                        "engaged_minutes": 79,
                        "ROA": 1.7761194029850746,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 283,
                        "pageviews": 326,
                        "engaged_minutes": 295,
                        "ROA": 2.76271186440678,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1523,
                        "pageviews": 1741,
                        "engaged_minutes": 1002,
                        "ROA": 3.1944954128440366,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1970,
                        "pageviews": 2229,
                        "engaged_minutes": 1658,
                        "ROA": 3.6243902439024391,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1989,
                        "pageviews": 2661,
                        "engaged_minutes": 1246,
                        "ROA": 8.782178217821782,
                        "section": " الصحي",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 65,
                        "pageviews": 75,
                        "engaged_minutes": 25,
                        "ROA": 1.6304347826086956,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 416,
                        "pageviews": 465,
                        "engaged_minutes": 287,
                        "ROA": 1.8979591836734695,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 86,
                        "pageviews": 97,
                        "engaged_minutes": 45,
                        "ROA": 1.3108108108108107,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 9287,
                        "pageviews": 14412,
                        "engaged_minutes": 5805,
                        "ROA": 23.358184764991897,
                        "section": "العرب والعالم",
                        "published_posts": 28
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 228,
                        "pageviews": 265,
                        "engaged_minutes": 167,
                        "ROA": 1.424731182795699,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 52,
                        "pageviews": 55,
                        "engaged_minutes": 45,
                        "ROA": 2.6190476190476191,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 72,
                        "pageviews": 84,
                        "engaged_minutes": 61,
                        "ROA": 1.5,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1.4,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 571,
                        "pageviews": 682,
                        "engaged_minutes": 325,
                        "ROA": 12.178571428571429,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 81,
                        "pageviews": 86,
                        "engaged_minutes": 51,
                        "ROA": 1.9111111111111112,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 33,
                        "pageviews": 37,
                        "engaged_minutes": 16,
                        "ROA": 1.6818181818181819,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 27,
                        "pageviews": 34,
                        "engaged_minutes": 11,
                        "ROA": 1.7894736842105263,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 17,
                        "pageviews": 18,
                        "engaged_minutes": 6,
                        "ROA": 1.3846153846153846,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 2.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 6,
                        "ROA": 1.2,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1691,
                        "pageviews": 2137,
                        "engaged_minutes": 743,
                        "ROA": 10.792929292929292,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 351,
                        "pageviews": 400,
                        "engaged_minutes": 326,
                        "ROA": 2.7586206896551726,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 8734,
                        "pageviews": 13452,
                        "engaged_minutes": 6400,
                        "ROA": 10.909975669099756,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 10614,
                        "pageviews": 16149,
                        "engaged_minutes": 6583,
                        "ROA": 26.088852988691439,
                        "section": "العرب والعالم",
                        "published_posts": 36
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 10927,
                        "pageviews": 15479,
                        "engaged_minutes": 5410,
                        "ROA": 9.8217005076142136,
                        "section": "الرياضي",
                        "published_posts": 38
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 9636,
                        "pageviews": 13665,
                        "engaged_minutes": 4511,
                        "ROA": 6.1250560286866875,
                        "section": "الاقتصادي",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 2435,
                        "pageviews": 3308,
                        "engaged_minutes": 1794,
                        "ROA": 3.2882703777335984,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 2473,
                        "pageviews": 2826,
                        "engaged_minutes": 1594,
                        "ROA": 1.9120433017591341,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1959,
                        "pageviews": 2522,
                        "engaged_minutes": 1027,
                        "ROA": 2.7472766884531592,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 2115,
                        "pageviews": 2339,
                        "engaged_minutes": 1782,
                        "ROA": 3.6376360808709176,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 18274,
                        "pageviews": 27584,
                        "engaged_minutes": 10466,
                        "ROA": 18.076015727391873,
                        "section": "الإمارات",
                        "published_posts": 68
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 76,
                        "pageviews": 91,
                        "engaged_minutes": 56,
                        "ROA": 1.8958333333333333,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 3735,
                        "pageviews": 4550,
                        "engaged_minutes": 2717,
                        "ROA": 1.8346774193548387,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1663,
                        "pageviews": 1931,
                        "engaged_minutes": 1147,
                        "ROA": 3.3582608695652172,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 66,
                        "pageviews": 69,
                        "engaged_minutes": 54,
                        "ROA": 2.5555555555555554,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 138,
                        "pageviews": 157,
                        "engaged_minutes": 114,
                        "ROA": 2.0657894736842106,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 112,
                        "pageviews": 143,
                        "engaged_minutes": 41,
                        "ROA": 3.3255813953488373,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1097,
                        "pageviews": 1330,
                        "engaged_minutes": 946,
                        "ROA": 2.1521035598705502,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 355,
                        "pageviews": 390,
                        "engaged_minutes": 258,
                        "ROA": 3.19672131147541,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 89,
                        "pageviews": 97,
                        "engaged_minutes": 49,
                        "ROA": 1.4923076923076923,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 3054,
                        "pageviews": 4018,
                        "engaged_minutes": 1600,
                        "ROA": 13.217105263157896,
                        "section": " الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 65,
                        "pageviews": 80,
                        "engaged_minutes": 42,
                        "ROA": 1.4285714285714286,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 524,
                        "pageviews": 585,
                        "engaged_minutes": 318,
                        "ROA": 1.3510392609699768,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 493,
                        "pageviews": 559,
                        "engaged_minutes": 338,
                        "ROA": 2.0327272727272727,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 222,
                        "pageviews": 253,
                        "engaged_minutes": 156,
                        "ROA": 1.4213483146067416,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 65,
                        "pageviews": 72,
                        "engaged_minutes": 23,
                        "ROA": 1.4693877551020409,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 17,
                        "pageviews": 18,
                        "engaged_minutes": 13,
                        "ROA": 1.5,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 32,
                        "pageviews": 33,
                        "engaged_minutes": 16,
                        "ROA": 1.5714285714285714,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 29,
                        "pageviews": 34,
                        "engaged_minutes": 19,
                        "ROA": 1.6190476190476191,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 6,
                        "ROA": 1.4444444444444444,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 31,
                        "pageviews": 41,
                        "engaged_minutes": 11,
                        "ROA": 2.05,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 14,
                        "pageviews": 17,
                        "engaged_minutes": 9,
                        "ROA": 1.3076923076923077,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 23,
                        "pageviews": 23,
                        "engaged_minutes": 13,
                        "ROA": 1.4375,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 6,
                        "ROA": 1.1428571428571428,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 1.5,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 3,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.6666666666666667,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 6,
                        "ROA": 6,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 12985,
                        "pageviews": 19978,
                        "engaged_minutes": 9694,
                        "ROA": 14.908955223880596,
                        "section": "منوعات",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 97,
                        "pageviews": 107,
                        "engaged_minutes": 71,
                        "ROA": 2.6097560975609757,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 2540,
                        "pageviews": 2919,
                        "engaged_minutes": 1622,
                        "ROA": 1.9267326732673267,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 8498,
                        "pageviews": 12843,
                        "engaged_minutes": 5127,
                        "ROA": 20.9510603588907,
                        "section": "العرب والعالم",
                        "published_posts": 44
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 288,
                        "pageviews": 321,
                        "engaged_minutes": 224,
                        "ROA": 3.1782178217821784,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 5990,
                        "pageviews": 9083,
                        "engaged_minutes": 3315,
                        "ROA": 4.05310129406515,
                        "section": "الاقتصادي",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 552,
                        "pageviews": 680,
                        "engaged_minutes": 342,
                        "ROA": 1.437632135306554,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 2084,
                        "pageviews": 2732,
                        "engaged_minutes": 1048,
                        "ROA": 2.8517745302713986,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 1882,
                        "pageviews": 2199,
                        "engaged_minutes": 1299,
                        "ROA": 3.8783068783068781,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 37,
                        "pageviews": 45,
                        "engaged_minutes": 33,
                        "ROA": 1.8,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 28330,
                        "pageviews": 39962,
                        "engaged_minutes": 15645,
                        "ROA": 26.983119513842,
                        "section": "الإمارات",
                        "published_posts": 68
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 24,
                        "pageviews": 26,
                        "engaged_minutes": 15,
                        "ROA": 1.3,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 1301,
                        "pageviews": 1596,
                        "engaged_minutes": 599,
                        "ROA": 7.6,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 303,
                        "pageviews": 350,
                        "engaged_minutes": 278,
                        "ROA": 2.5547445255474455,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 9213,
                        "pageviews": 13140,
                        "engaged_minutes": 5084,
                        "ROA": 8.1767268201617913,
                        "section": "الرياضي",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 2763,
                        "pageviews": 3764,
                        "engaged_minutes": 2046,
                        "ROA": 3.6650438169425512,
                        "section": "اتجاهات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 2147,
                        "pageviews": 2441,
                        "engaged_minutes": 1776,
                        "ROA": 3.62704309063893,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 3906,
                        "pageviews": 4691,
                        "engaged_minutes": 2705,
                        "ROA": 1.8666931953840031,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 152,
                        "pageviews": 183,
                        "engaged_minutes": 99,
                        "ROA": 2.0109890109890109,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 34,
                        "pageviews": 36,
                        "engaged_minutes": 15,
                        "ROA": 1.6363636363636365,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 1100,
                        "pageviews": 1287,
                        "engaged_minutes": 928,
                        "ROA": 2.141430948419301,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 237,
                        "pageviews": 283,
                        "engaged_minutes": 172,
                        "ROA": 1.5988700564971752,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 4,
                        "ROA": 1.1111111111111112,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 4039,
                        "pageviews": 5491,
                        "engaged_minutes": 2484,
                        "ROA": 18.613559322033897,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 105,
                        "pageviews": 120,
                        "engaged_minutes": 67,
                        "ROA": 1.5789473684210527,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 72,
                        "pageviews": 86,
                        "engaged_minutes": 55,
                        "ROA": 1.2835820895522387,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 150,
                        "pageviews": 164,
                        "engaged_minutes": 65,
                        "ROA": 10.25,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 473,
                        "pageviews": 555,
                        "engaged_minutes": 341,
                        "ROA": 1.9964028776978417,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 78,
                        "pageviews": 87,
                        "engaged_minutes": 32,
                        "ROA": 2.175,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 30,
                        "pageviews": 40,
                        "engaged_minutes": 20,
                        "ROA": 2.8571428571428572,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 69,
                        "pageviews": 73,
                        "engaged_minutes": 48,
                        "ROA": 2.5172413793103448,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 132,
                        "pageviews": 197,
                        "engaged_minutes": 44,
                        "ROA": 3.078125,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 4,
                        "pageviews": 7,
                        "engaged_minutes": 2,
                        "ROA": 2.3333333333333335,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 15,
                        "pageviews": 16,
                        "engaged_minutes": 7,
                        "ROA": 1.3333333333333333,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 13,
                        "pageviews": 14,
                        "engaged_minutes": 6,
                        "ROA": 1.1666666666666667,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 7,
                        "ROA": 1.3333333333333333,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 6,
                        "ROA": 1.2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 18,
                        "pageviews": 18,
                        "engaged_minutes": 13,
                        "ROA": 3.6,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 17979,
                        "pageviews": 24493,
                        "engaged_minutes": 11671,
                        "ROA": 18.33308383233533,
                        "section": "منوعات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 1856,
                        "pageviews": 2345,
                        "engaged_minutes": 962,
                        "ROA": 10.46875,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2557,
                        "pageviews": 3495,
                        "engaged_minutes": 1872,
                        "ROA": 3.3190883190883191,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2830,
                        "pageviews": 3243,
                        "engaged_minutes": 1869,
                        "ROA": 2.0990291262135923,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2088,
                        "pageviews": 2866,
                        "engaged_minutes": 1170,
                        "ROA": 2.8292201382033562,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2145,
                        "pageviews": 2427,
                        "engaged_minutes": 1739,
                        "ROA": 3.6386806596701651,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 7857,
                        "pageviews": 11608,
                        "engaged_minutes": 4091,
                        "ROA": 5.140832595217006,
                        "section": "الاقتصادي",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 581,
                        "pageviews": 710,
                        "engaged_minutes": 320,
                        "ROA": 1.5106382978723405,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 11348,
                        "pageviews": 16259,
                        "engaged_minutes": 6527,
                        "ROA": 9.4419279907084785,
                        "section": "الرياضي",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 45,
                        "pageviews": 51,
                        "engaged_minutes": 25,
                        "ROA": 1.8888888888888888,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3723,
                        "pageviews": 4400,
                        "engaged_minutes": 2543,
                        "ROA": 1.8425460636515913,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 12032,
                        "pageviews": 19242,
                        "engaged_minutes": 6633,
                        "ROA": 12.018738288569644,
                        "section": "الإمارات",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3525,
                        "pageviews": 4582,
                        "engaged_minutes": 2092,
                        "ROA": 14.592356687898089,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 17388,
                        "pageviews": 31188,
                        "engaged_minutes": 13316,
                        "ROA": 43.988716502115658,
                        "section": "العرب والعالم",
                        "published_posts": 58
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 1974,
                        "pageviews": 2286,
                        "engaged_minutes": 1277,
                        "ROA": 3.9076923076923076,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 42,
                        "pageviews": 45,
                        "engaged_minutes": 26,
                        "ROA": 1.3636363636363635,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 258,
                        "pageviews": 291,
                        "engaged_minutes": 172,
                        "ROA": 1.5478723404255319,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 315,
                        "pageviews": 367,
                        "engaged_minutes": 313,
                        "ROA": 2.6402877697841727,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 122,
                        "pageviews": 135,
                        "engaged_minutes": 54,
                        "ROA": 1.7532467532467533,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 74,
                        "pageviews": 80,
                        "engaged_minutes": 50,
                        "ROA": 1.4814814814814814,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 1166,
                        "pageviews": 1398,
                        "engaged_minutes": 917,
                        "ROA": 2.1310975609756095,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 445,
                        "pageviews": 521,
                        "engaged_minutes": 350,
                        "ROA": 2.0038461538461538,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 77,
                        "pageviews": 93,
                        "engaged_minutes": 40,
                        "ROA": 1.9375,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 128,
                        "pageviews": 149,
                        "engaged_minutes": 83,
                        "ROA": 2.1285714285714286,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 34,
                        "pageviews": 40,
                        "engaged_minutes": 17,
                        "ROA": 1.8181818181818181,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 317,
                        "pageviews": 356,
                        "engaged_minutes": 280,
                        "ROA": 2.8943089430894311,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 12,
                        "pageviews": 15,
                        "engaged_minutes": 5,
                        "ROA": 1.3636363636363635,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 65,
                        "pageviews": 67,
                        "engaged_minutes": 51,
                        "ROA": 2.2333333333333334,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 96,
                        "pageviews": 105,
                        "engaged_minutes": 70,
                        "ROA": 2.2826086956521738,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 195,
                        "pageviews": 283,
                        "engaged_minutes": 81,
                        "ROA": 4.8793103448275863,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 17,
                        "pageviews": 24,
                        "engaged_minutes": 17,
                        "ROA": 1.6,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.25,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 8,
                        "ROA": 1.3,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 39,
                        "pageviews": 57,
                        "engaged_minutes": 17,
                        "ROA": 2.375,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 4,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 694,
                        "pageviews": 782,
                        "engaged_minutes": 357,
                        "ROA": 1.4270072992700731,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 300,
                        "pageviews": 345,
                        "engaged_minutes": 266,
                        "ROA": 2.3630136986301369,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 1844,
                        "pageviews": 2137,
                        "engaged_minutes": 1193,
                        "ROA": 3.6037099494097808,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 24552,
                        "pageviews": 31286,
                        "engaged_minutes": 14225,
                        "ROA": 24.790808240887479,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2143,
                        "pageviews": 2378,
                        "engaged_minutes": 1723,
                        "ROA": 3.5492537313432835,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2811,
                        "pageviews": 3194,
                        "engaged_minutes": 1759,
                        "ROA": 2.0164141414141414,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 29,
                        "pageviews": 33,
                        "engaged_minutes": 14,
                        "ROA": 1.4347826086956521,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 74,
                        "pageviews": 78,
                        "engaged_minutes": 25,
                        "ROA": 1.5918367346938775,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 182,
                        "pageviews": 252,
                        "engaged_minutes": 63,
                        "ROA": 3.8769230769230769,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2325,
                        "pageviews": 2828,
                        "engaged_minutes": 1106,
                        "ROA": 13.27699530516432,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 10296,
                        "pageviews": 14221,
                        "engaged_minutes": 5836,
                        "ROA": 8.5360144057623053,
                        "section": "الرياضي",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2640,
                        "pageviews": 3410,
                        "engaged_minutes": 1402,
                        "ROA": 3.5483870967741935,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 3223,
                        "pageviews": 3781,
                        "engaged_minutes": 2108,
                        "ROA": 1.8801591248135257,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 5255,
                        "pageviews": 6741,
                        "engaged_minutes": 3055,
                        "ROA": 21,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 12,
                        "pageviews": 15,
                        "engaged_minutes": 7,
                        "ROA": 1.3636363636363635,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 6309,
                        "pageviews": 9556,
                        "engaged_minutes": 2926,
                        "ROA": 15.025157232704403,
                        "section": "العرب والعالم",
                        "published_posts": 23
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2107,
                        "pageviews": 2868,
                        "engaged_minutes": 1594,
                        "ROA": 2.8200589970501473,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 5991,
                        "pageviews": 8321,
                        "engaged_minutes": 2936,
                        "ROA": 4.0139893873613124,
                        "section": "الاقتصادي",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 9795,
                        "pageviews": 15052,
                        "engaged_minutes": 5008,
                        "ROA": 11.861308116627265,
                        "section": "الإمارات",
                        "published_posts": 37
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 339,
                        "pageviews": 375,
                        "engaged_minutes": 252,
                        "ROA": 2.9761904761904763,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 1053,
                        "pageviews": 1265,
                        "engaged_minutes": 851,
                        "ROA": 1.955177743431221,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 132,
                        "pageviews": 145,
                        "engaged_minutes": 57,
                        "ROA": 1.6666666666666667,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 165,
                        "pageviews": 168,
                        "engaged_minutes": 43,
                        "ROA": 1.5700934579439252,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 1.25,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 421,
                        "pageviews": 487,
                        "engaged_minutes": 307,
                        "ROA": 1.8037037037037038,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 15,
                        "pageviews": 18,
                        "engaged_minutes": 4,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 52,
                        "pageviews": 54,
                        "engaged_minutes": 39,
                        "ROA": 1.7419354838709677,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 135,
                        "pageviews": 148,
                        "engaged_minutes": 87,
                        "ROA": 1.8974358974358974,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 33,
                        "pageviews": 35,
                        "engaged_minutes": 16,
                        "ROA": 1.4583333333333333,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 221,
                        "pageviews": 258,
                        "engaged_minutes": 170,
                        "ROA": 1.5176470588235293,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 13,
                        "pageviews": 15,
                        "engaged_minutes": 7,
                        "ROA": 1.875,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 120,
                        "pageviews": 133,
                        "engaged_minutes": 85,
                        "ROA": 2.5094339622641511,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 24,
                        "pageviews": 34,
                        "engaged_minutes": 8,
                        "ROA": 2,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 39,
                        "pageviews": 40,
                        "engaged_minutes": 16,
                        "ROA": 1.4285714285714286,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "الجرافيك اليومي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 3,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 4,
                        "ROA": 1.5,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 8,
                        "ROA": 1.4,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 2519,
                        "pageviews": 2972,
                        "engaged_minutes": 1718,
                        "ROA": 1.8311768330252618,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 18179,
                        "pageviews": 25624,
                        "engaged_minutes": 10986,
                        "ROA": 19.786872586872587,
                        "section": "منوعات",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 227,
                        "pageviews": 237,
                        "engaged_minutes": 55,
                        "ROA": 1.4905660377358489,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 12200,
                        "pageviews": 17652,
                        "engaged_minutes": 6275,
                        "ROA": 14.759197324414716,
                        "section": "الإمارات",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 2502,
                        "pageviews": 2837,
                        "engaged_minutes": 1698,
                        "ROA": 2.0163468372423594,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 7219,
                        "pageviews": 9596,
                        "engaged_minutes": 3509,
                        "ROA": 4.9185033316248079,
                        "section": "الاقتصادي",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 487,
                        "pageviews": 564,
                        "engaged_minutes": 297,
                        "ROA": 1.3891625615763548,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 1964,
                        "pageviews": 2523,
                        "engaged_minutes": 1242,
                        "ROA": 2.5305917753259779,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 352,
                        "pageviews": 397,
                        "engaged_minutes": 252,
                        "ROA": 3.3083333333333331,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 2434,
                        "pageviews": 2719,
                        "engaged_minutes": 1917,
                        "ROA": 3.7921896792189678,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 14,
                        "pageviews": 16,
                        "engaged_minutes": 8,
                        "ROA": 1.0666666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 1377,
                        "pageviews": 1556,
                        "engaged_minutes": 849,
                        "ROA": 2.1491712707182322,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 7832,
                        "pageviews": 11059,
                        "engaged_minutes": 4568,
                        "ROA": 17.013846153846153,
                        "section": "العرب والعالم",
                        "published_posts": 35
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 6493,
                        "pageviews": 8077,
                        "engaged_minutes": 3619,
                        "ROA": 26.054838709677419,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 14927,
                        "pageviews": 19176,
                        "engaged_minutes": 8399,
                        "ROA": 12.007514088916718,
                        "section": "الرياضي",
                        "published_posts": 41
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 1879,
                        "pageviews": 2144,
                        "engaged_minutes": 1051,
                        "ROA": 3.9267399267399266,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 277,
                        "pageviews": 305,
                        "engaged_minutes": 234,
                        "ROA": 2.5416666666666665,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 3052,
                        "pageviews": 3971,
                        "engaged_minutes": 1746,
                        "ROA": 4.4768883878241263,
                        "section": "فكر وفن",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 11,
                        "pageviews": 12,
                        "engaged_minutes": 8,
                        "ROA": 2.4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 199,
                        "pageviews": 269,
                        "engaged_minutes": 67,
                        "ROA": 3.7361111111111112,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 109,
                        "pageviews": 126,
                        "engaged_minutes": 88,
                        "ROA": 2.3333333333333335,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 71,
                        "pageviews": 79,
                        "engaged_minutes": 55,
                        "ROA": 3.16,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 47,
                        "pageviews": 54,
                        "engaged_minutes": 35,
                        "ROA": 1.2272727272727273,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 671,
                        "pageviews": 717,
                        "engaged_minutes": 271,
                        "ROA": 2.4222972972972974,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 1719,
                        "pageviews": 2093,
                        "engaged_minutes": 874,
                        "ROA": 10.259803921568627,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1.1428571428571428,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 131,
                        "pageviews": 152,
                        "engaged_minutes": 87,
                        "ROA": 2.1714285714285713,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 19,
                        "pageviews": 25,
                        "engaged_minutes": 6,
                        "ROA": 2.0833333333333335,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 26,
                        "pageviews": 28,
                        "engaged_minutes": 16,
                        "ROA": 1.4,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 235,
                        "pageviews": 279,
                        "engaged_minutes": 171,
                        "ROA": 1.453125,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 73,
                        "pageviews": 80,
                        "engaged_minutes": 21,
                        "ROA": 1.6326530612244898,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 1.25,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 37,
                        "pageviews": 39,
                        "engaged_minutes": 16,
                        "ROA": 2.1666666666666665,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 10,
                        "pageviews": 13,
                        "engaged_minutes": 6,
                        "ROA": 1.625,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 37,
                        "pageviews": 39,
                        "engaged_minutes": 21,
                        "ROA": 1.4444444444444444,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 4,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 4,
                        "ROA": 1.2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 1504,
                        "pageviews": 1908,
                        "engaged_minutes": 670,
                        "ROA": 8.0506329113924053,
                        "section": "التقنية",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 293,
                        "pageviews": 333,
                        "engaged_minutes": 305,
                        "ROA": 2.5227272727272729,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 7212,
                        "pageviews": 9463,
                        "engaged_minutes": 5108,
                        "ROA": 29.571875,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 10439,
                        "pageviews": 14369,
                        "engaged_minutes": 6228,
                        "ROA": 10.844528301886793,
                        "section": "منوعات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 351,
                        "pageviews": 382,
                        "engaged_minutes": 256,
                        "ROA": 2.984375,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2136,
                        "pageviews": 2889,
                        "engaged_minutes": 1555,
                        "ROA": 2.7967086156824781,
                        "section": "اتجاهات",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2437,
                        "pageviews": 2756,
                        "engaged_minutes": 1721,
                        "ROA": 2.0926347760060744,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 231,
                        "pageviews": 298,
                        "engaged_minutes": 101,
                        "ROA": 3.5476190476190474,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 6903,
                        "pageviews": 9585,
                        "engaged_minutes": 3703,
                        "ROA": 15.459677419354838,
                        "section": "العرب والعالم",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 13007,
                        "pageviews": 17751,
                        "engaged_minutes": 6683,
                        "ROA": 8.9201005025125628,
                        "section": "الاقتصادي",
                        "published_posts": 37
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2920,
                        "pageviews": 3473,
                        "engaged_minutes": 2122,
                        "ROA": 1.9315906562847609,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 13335,
                        "pageviews": 20807,
                        "engaged_minutes": 7843,
                        "ROA": 14.777698863636363,
                        "section": "الإمارات",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 13849,
                        "pageviews": 17401,
                        "engaged_minutes": 8131,
                        "ROA": 10.426003594967046,
                        "section": "الرياضي",
                        "published_posts": 45
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 479,
                        "pageviews": 534,
                        "engaged_minutes": 269,
                        "ROA": 1.2898550724637681,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2841,
                        "pageviews": 3537,
                        "engaged_minutes": 1149,
                        "ROA": 3.6091836734693876,
                        "section": "فكر وفن",
                        "published_posts": 13
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 199,
                        "pageviews": 222,
                        "engaged_minutes": 150,
                        "ROA": 1.4140127388535031,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 1661,
                        "pageviews": 1902,
                        "engaged_minutes": 1099,
                        "ROA": 3.5353159851301115,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 111,
                        "pageviews": 118,
                        "engaged_minutes": 32,
                        "ROA": 1.2553191489361701,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 65,
                        "pageviews": 78,
                        "engaged_minutes": 22,
                        "ROA": 1.6595744680851063,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2213,
                        "pageviews": 2485,
                        "engaged_minutes": 1809,
                        "ROA": 3.8113496932515338,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 1043,
                        "pageviews": 1192,
                        "engaged_minutes": 795,
                        "ROA": 1.9445350734094617,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 490,
                        "pageviews": 551,
                        "engaged_minutes": 275,
                        "ROA": 1.7603833865814698,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 116,
                        "pageviews": 122,
                        "engaged_minutes": 29,
                        "ROA": 2.7111111111111112,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 91,
                        "pageviews": 97,
                        "engaged_minutes": 64,
                        "ROA": 2.1086956521739131,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 97,
                        "pageviews": 114,
                        "engaged_minutes": 63,
                        "ROA": 1.9655172413793103,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 23,
                        "pageviews": 38,
                        "engaged_minutes": 10,
                        "ROA": 2.9230769230769229,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 66,
                        "pageviews": 69,
                        "engaged_minutes": 47,
                        "ROA": 2.3793103448275863,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 31,
                        "pageviews": 33,
                        "engaged_minutes": 18,
                        "ROA": 1.5714285714285714,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 2,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 37,
                        "pageviews": 38,
                        "engaged_minutes": 20,
                        "ROA": 2.1111111111111112,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 10,
                        "pageviews": 10,
                        "engaged_minutes": 6,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 54,
                        "pageviews": 62,
                        "engaged_minutes": 36,
                        "ROA": 1.2653061224489797,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 9,
                        "ROA": 1,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 6,
                        "ROA": 1.2857142857142858,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 16087,
                        "pageviews": 25466,
                        "engaged_minutes": 10956,
                        "ROA": 16.655330281229563,
                        "section": "الإمارات",
                        "published_posts": 74
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 3276,
                        "pageviews": 4403,
                        "engaged_minutes": 2645,
                        "ROA": 4.1576959395656283,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 16089,
                        "pageviews": 20270,
                        "engaged_minutes": 8951,
                        "ROA": 12.382406841783752,
                        "section": "الرياضي",
                        "published_posts": 37
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1061,
                        "pageviews": 1247,
                        "engaged_minutes": 875,
                        "ROA": 2.004823151125402,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 3195,
                        "pageviews": 4085,
                        "engaged_minutes": 1663,
                        "ROA": 3.7964684014869889,
                        "section": "فكر وفن",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 7398,
                        "pageviews": 9314,
                        "engaged_minutes": 5571,
                        "ROA": 29.10625,
                        "section": " الصحي",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 2167,
                        "pageviews": 2450,
                        "engaged_minutes": 1763,
                        "ROA": 3.763440860215054,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 492,
                        "pageviews": 555,
                        "engaged_minutes": 301,
                        "ROA": 1.3944723618090453,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 2658,
                        "pageviews": 3092,
                        "engaged_minutes": 1890,
                        "ROA": 2.2007117437722421,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 13056,
                        "pageviews": 17792,
                        "engaged_minutes": 6749,
                        "ROA": 8.5333333333333332,
                        "section": "الاقتصادي",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1711,
                        "pageviews": 1994,
                        "engaged_minutes": 1182,
                        "ROA": 3.5607142857142855,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 3288,
                        "pageviews": 3927,
                        "engaged_minutes": 2303,
                        "ROA": 1.7468861209964412,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 266,
                        "pageviews": 334,
                        "engaged_minutes": 105,
                        "ROA": 5.3015873015873014,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 407,
                        "pageviews": 466,
                        "engaged_minutes": 270,
                        "ROA": 1.8492063492063493,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 9773,
                        "pageviews": 13514,
                        "engaged_minutes": 6064,
                        "ROA": 10.115269461077844,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 3204,
                        "pageviews": 4009,
                        "engaged_minutes": 1794,
                        "ROA": 19.748768472906406,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 4992,
                        "pageviews": 7137,
                        "engaged_minutes": 2796,
                        "ROA": 11.855481727574752,
                        "section": "العرب والعالم",
                        "published_posts": 26
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 288,
                        "pageviews": 328,
                        "engaged_minutes": 279,
                        "ROA": 2.4477611940298507,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 105,
                        "pageviews": 118,
                        "engaged_minutes": 66,
                        "ROA": 1.4390243902439024,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 14,
                        "pageviews": 16,
                        "engaged_minutes": 8,
                        "ROA": 2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 35,
                        "pageviews": 41,
                        "engaged_minutes": 32,
                        "ROA": 1.9523809523809523,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 194,
                        "pageviews": 212,
                        "engaged_minutes": 121,
                        "ROA": 1.4929577464788732,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 122,
                        "pageviews": 132,
                        "engaged_minutes": 68,
                        "ROA": 1.9130434782608696,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 76,
                        "pageviews": 81,
                        "engaged_minutes": 52,
                        "ROA": 2.1315789473684212,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 290,
                        "pageviews": 306,
                        "engaged_minutes": 243,
                        "ROA": 2.970873786407767,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 31,
                        "pageviews": 36,
                        "engaged_minutes": 17,
                        "ROA": 1.5,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 64,
                        "pageviews": 75,
                        "engaged_minutes": 40,
                        "ROA": 1.4423076923076923,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 36,
                        "pageviews": 38,
                        "engaged_minutes": 17,
                        "ROA": 1.7272727272727273,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 78,
                        "pageviews": 87,
                        "engaged_minutes": 32,
                        "ROA": 1.6111111111111112,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 1.2857142857142858,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 11,
                        "pageviews": 14,
                        "engaged_minutes": 7,
                        "ROA": 1.4,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 61,
                        "pageviews": 66,
                        "engaged_minutes": 49,
                        "ROA": 2.75,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 28,
                        "pageviews": 38,
                        "engaged_minutes": 10,
                        "ROA": 2.2352941176470589,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 6,
                        "pageviews": 8,
                        "engaged_minutes": 4,
                        "ROA": 1.1428571428571428,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 5,
                        "ROA": 2.6666666666666665,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 3555,
                        "pageviews": 4261,
                        "engaged_minutes": 2408,
                        "ROA": 1.813191489361702,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 300,
                        "pageviews": 333,
                        "engaged_minutes": 237,
                        "ROA": 3.1714285714285713,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 10888,
                        "pageviews": 15122,
                        "engaged_minutes": 6988,
                        "ROA": 11.888364779874214,
                        "section": "منوعات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 4941,
                        "pageviews": 6938,
                        "engaged_minutes": 2669,
                        "ROA": 11.85982905982906,
                        "section": "العرب والعالم",
                        "published_posts": 25
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 32,
                        "pageviews": 36,
                        "engaged_minutes": 16,
                        "ROA": 1.6363636363636365,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 296,
                        "pageviews": 406,
                        "engaged_minutes": 122,
                        "ROA": 5.8,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 1290,
                        "pageviews": 1544,
                        "engaged_minutes": 678,
                        "ROA": 6.4873949579831933,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 13590,
                        "pageviews": 18326,
                        "engaged_minutes": 6455,
                        "ROA": 8.6729768102224334,
                        "section": "الاقتصادي",
                        "published_posts": 63
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 10117,
                        "pageviews": 12781,
                        "engaged_minutes": 7683,
                        "ROA": 41.09646302250804,
                        "section": " الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 507,
                        "pageviews": 633,
                        "engaged_minutes": 317,
                        "ROA": 1.4419134396355353,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 2550,
                        "pageviews": 2961,
                        "engaged_minutes": 1781,
                        "ROA": 1.9202334630350195,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 2452,
                        "pageviews": 3100,
                        "engaged_minutes": 1287,
                        "ROA": 2.9836381135707413,
                        "section": "فكر وفن",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 32,
                        "pageviews": 33,
                        "engaged_minutes": 16,
                        "ROA": 1.736842105263158,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 96,
                        "pageviews": 109,
                        "engaged_minutes": 54,
                        "ROA": 1.2528735632183907,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 11378,
                        "pageviews": 17729,
                        "engaged_minutes": 6536,
                        "ROA": 11.497405966277562,
                        "section": "الإمارات",
                        "published_posts": 87
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 20,
                        "pageviews": 25,
                        "engaged_minutes": 15,
                        "ROA": 1.4705882352941178,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 1095,
                        "pageviews": 1270,
                        "engaged_minutes": 932,
                        "ROA": 2.0418006430868165,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 2350,
                        "pageviews": 2627,
                        "engaged_minutes": 1922,
                        "ROA": 3.9267563527653215,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 2696,
                        "pageviews": 3632,
                        "engaged_minutes": 2150,
                        "ROA": 3.4990366088631983,
                        "section": "اتجاهات",
                        "published_posts": 15
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 21395,
                        "pageviews": 26452,
                        "engaged_minutes": 12559,
                        "ROA": 15.469005847953216,
                        "section": "الرياضي",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 306,
                        "pageviews": 337,
                        "engaged_minutes": 305,
                        "ROA": 2.4962962962962965,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 109,
                        "pageviews": 117,
                        "engaged_minutes": 80,
                        "ROA": 2.6,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 1799,
                        "pageviews": 2089,
                        "engaged_minutes": 1139,
                        "ROA": 3.6520979020979021,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 428,
                        "pageviews": 488,
                        "engaged_minutes": 266,
                        "ROA": 2.0165289256198347,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 128,
                        "pageviews": 145,
                        "engaged_minutes": 84,
                        "ROA": 1.9333333333333333,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 74,
                        "pageviews": 76,
                        "engaged_minutes": 56,
                        "ROA": 2.1111111111111112,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 70,
                        "pageviews": 79,
                        "engaged_minutes": 41,
                        "ROA": 1.234375,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 273,
                        "pageviews": 306,
                        "engaged_minutes": 170,
                        "ROA": 1.5692307692307692,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 39,
                        "pageviews": 50,
                        "engaged_minutes": 25,
                        "ROA": 2.3809523809523809,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 51,
                        "pageviews": 63,
                        "engaged_minutes": 22,
                        "ROA": 3.7058823529411766,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 4,
                        "ROA": 1.375,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 67,
                        "pageviews": 78,
                        "engaged_minutes": 29,
                        "ROA": 1.6956521739130435,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 8,
                        "pageviews": 11,
                        "engaged_minutes": 5,
                        "ROA": 1.5714285714285714,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 4,
                        "ROA": 1.4,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 3,
                        "ROA": 2.25,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 0,
                        "ROA": 4,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 8,
                        "pageviews": 11,
                        "engaged_minutes": 6,
                        "ROA": 1.8333333333333333,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1196,
                        "pageviews": 1474,
                        "engaged_minutes": 606,
                        "ROA": 6.0163265306122451,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 315,
                        "pageviews": 363,
                        "engaged_minutes": 285,
                        "ROA": 2.4527027027027026,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2560,
                        "pageviews": 2926,
                        "engaged_minutes": 1616,
                        "ROA": 1.9454787234042554,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 14820,
                        "pageviews": 20304,
                        "engaged_minutes": 7839,
                        "ROA": 9.448115402512796,
                        "section": "الاقتصادي",
                        "published_posts": 69
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2718,
                        "pageviews": 3642,
                        "engaged_minutes": 1717,
                        "ROA": 3.5917159763313609,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 111,
                        "pageviews": 121,
                        "engaged_minutes": 89,
                        "ROA": 2.8139534883720931,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 13639,
                        "pageviews": 19167,
                        "engaged_minutes": 8595,
                        "ROA": 14.631297709923665,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 277,
                        "pageviews": 292,
                        "engaged_minutes": 238,
                        "ROA": 2.8076923076923075,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2344,
                        "pageviews": 3360,
                        "engaged_minutes": 1854,
                        "ROA": 3.0939226519337018,
                        "section": "اتجاهات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 32,
                        "pageviews": 36,
                        "engaged_minutes": 18,
                        "ROA": 1.5652173913043479,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1159,
                        "pageviews": 1370,
                        "engaged_minutes": 996,
                        "ROA": 2.2061191626409018,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 540,
                        "pageviews": 665,
                        "engaged_minutes": 333,
                        "ROA": 1.4148936170212767,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 7426,
                        "pageviews": 10163,
                        "engaged_minutes": 3839,
                        "ROA": 6.107572115384615,
                        "section": "الرياضي",
                        "published_posts": 49
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 3609,
                        "pageviews": 4275,
                        "engaged_minutes": 2503,
                        "ROA": 1.8030366933783213,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1798,
                        "pageviews": 2070,
                        "engaged_minutes": 1173,
                        "ROA": 3.7364620938628157,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 154,
                        "pageviews": 173,
                        "engaged_minutes": 112,
                        "ROA": 1.9659090909090908,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 578,
                        "pageviews": 694,
                        "engaged_minutes": 173,
                        "ROA": 8.7848101265822791,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 13228,
                        "pageviews": 19967,
                        "engaged_minutes": 7500,
                        "ROA": 12.957170668397145,
                        "section": "الإمارات",
                        "published_posts": 69
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2321,
                        "pageviews": 2569,
                        "engaged_minutes": 1901,
                        "ROA": 3.7779411764705881,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 3602,
                        "pageviews": 5057,
                        "engaged_minutes": 1803,
                        "ROA": 8.55668358714044,
                        "section": "العرب والعالم",
                        "published_posts": 31
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 74,
                        "pageviews": 79,
                        "engaged_minutes": 59,
                        "ROA": 2.3235294117647061,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 247,
                        "pageviews": 278,
                        "engaged_minutes": 167,
                        "ROA": 1.5617977528089888,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 6117,
                        "pageviews": 7644,
                        "engaged_minutes": 4175,
                        "ROA": 25.227722772277229,
                        "section": " الصحي",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 522,
                        "pageviews": 612,
                        "engaged_minutes": 347,
                        "ROA": 2.5606694560669454,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 74,
                        "pageviews": 85,
                        "engaged_minutes": 36,
                        "ROA": 1.328125,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 5,
                        "ROA": 1.125,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 82,
                        "pageviews": 104,
                        "engaged_minutes": 44,
                        "ROA": 1.7627118644067796,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 59,
                        "pageviews": 74,
                        "engaged_minutes": 34,
                        "ROA": 5.2857142857142856,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 49,
                        "pageviews": 62,
                        "engaged_minutes": 31,
                        "ROA": 2.3846153846153846,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 64,
                        "pageviews": 72,
                        "engaged_minutes": 48,
                        "ROA": 1.3333333333333333,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 8,
                        "pageviews": 10,
                        "engaged_minutes": 3,
                        "ROA": 1.6666666666666667,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 8,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 33,
                        "pageviews": 37,
                        "engaged_minutes": 18,
                        "ROA": 1.48,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 21,
                        "pageviews": 21,
                        "engaged_minutes": 12,
                        "ROA": 1.6153846153846154,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 8,
                        "pageviews": 9,
                        "engaged_minutes": 4,
                        "ROA": 1.125,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 1,
                        "ROA": 1.5,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 12169,
                        "pageviews": 15822,
                        "engaged_minutes": 6669,
                        "ROA": 9.11405529953917,
                        "section": "الرياضي",
                        "published_posts": 56
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 3524,
                        "pageviews": 4234,
                        "engaged_minutes": 2530,
                        "ROA": 1.8024691358024691,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 10652,
                        "pageviews": 13410,
                        "engaged_minutes": 8106,
                        "ROA": 41.134969325153371,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 82,
                        "pageviews": 96,
                        "engaged_minutes": 37,
                        "ROA": 1.9591836734693877,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 14098,
                        "pageviews": 20040,
                        "engaged_minutes": 9081,
                        "ROA": 15.090361445783133,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1976,
                        "pageviews": 2437,
                        "engaged_minutes": 1096,
                        "ROA": 10.282700421940929,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 2105,
                        "pageviews": 2729,
                        "engaged_minutes": 1112,
                        "ROA": 2.7181274900398407,
                        "section": "فكر وفن",
                        "published_posts": 24
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 57,
                        "pageviews": 75,
                        "engaged_minutes": 36,
                        "ROA": 6.25,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 15543,
                        "pageviews": 20263,
                        "engaged_minutes": 8262,
                        "ROA": 9.5760869565217384,
                        "section": "الاقتصادي",
                        "published_posts": 62
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 2324,
                        "pageviews": 3297,
                        "engaged_minutes": 1810,
                        "ROA": 3.1978661493695442,
                        "section": "اتجاهات",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 352,
                        "pageviews": 424,
                        "engaged_minutes": 372,
                        "ROA": 2.904109589041096,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 38,
                        "pageviews": 44,
                        "engaged_minutes": 21,
                        "ROA": 2,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 12439,
                        "pageviews": 19431,
                        "engaged_minutes": 7040,
                        "ROA": 12.439820742637645,
                        "section": "الإمارات",
                        "published_posts": 57
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 19,
                        "pageviews": 25,
                        "engaged_minutes": 12,
                        "ROA": 1.4705882352941178,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1273,
                        "pageviews": 1524,
                        "engaged_minutes": 1027,
                        "ROA": 2.2411764705882353,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 2241,
                        "pageviews": 2499,
                        "engaged_minutes": 1805,
                        "ROA": 3.6967455621301775,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 2385,
                        "pageviews": 2714,
                        "engaged_minutes": 1611,
                        "ROA": 1.8834142956280362,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1703,
                        "pageviews": 1945,
                        "engaged_minutes": 1123,
                        "ROA": 3.4485815602836878,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 6521,
                        "pageviews": 9312,
                        "engaged_minutes": 3810,
                        "ROA": 14.851674641148325,
                        "section": "العرب والعالم",
                        "published_posts": 29
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 10,
                        "pageviews": 12,
                        "engaged_minutes": 5,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 304,
                        "pageviews": 341,
                        "engaged_minutes": 264,
                        "ROA": 2.8898305084745761,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 458,
                        "pageviews": 515,
                        "engaged_minutes": 291,
                        "ROA": 1.8933823529411764,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 8,
                        "ROA": 2,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 544,
                        "pageviews": 647,
                        "engaged_minutes": 370,
                        "ROA": 1.3913978494623656,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 260,
                        "pageviews": 296,
                        "engaged_minutes": 176,
                        "ROA": 1.5661375661375661,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 127,
                        "pageviews": 145,
                        "engaged_minutes": 78,
                        "ROA": 1.9594594594594594,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 87,
                        "pageviews": 103,
                        "engaged_minutes": 40,
                        "ROA": 1.2875,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 91,
                        "pageviews": 96,
                        "engaged_minutes": 64,
                        "ROA": 2.4615384615384617,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 29,
                        "pageviews": 32,
                        "engaged_minutes": 18,
                        "ROA": 1.3913043478260869,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 28,
                        "pageviews": 30,
                        "engaged_minutes": 11,
                        "ROA": 1.7647058823529411,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 71,
                        "pageviews": 83,
                        "engaged_minutes": 45,
                        "ROA": 1.360655737704918,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1020,
                        "pageviews": 1174,
                        "engaged_minutes": 305,
                        "ROA": 13.811764705882354,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 120,
                        "pageviews": 139,
                        "engaged_minutes": 82,
                        "ROA": 3.2325581395348837,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 11,
                        "pageviews": 14,
                        "engaged_minutes": 9,
                        "ROA": 3.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 12,
                        "pageviews": 13,
                        "engaged_minutes": 6,
                        "ROA": 1.1818181818181819,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 19,
                        "pageviews": 21,
                        "engaged_minutes": 15,
                        "ROA": 1.75,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 77,
                        "pageviews": 88,
                        "engaged_minutes": 27,
                        "ROA": 1.9130434782608696,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1491,
                        "pageviews": 1897,
                        "engaged_minutes": 448,
                        "ROA": 8.0381355932203391,
                        "section": "التقنية",
                        "published_posts": 3
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1780,
                        "pageviews": 2246,
                        "engaged_minutes": 844,
                        "ROA": 2.2895005096839958,
                        "section": "فكر وفن",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2120,
                        "pageviews": 2958,
                        "engaged_minutes": 1477,
                        "ROA": 2.9848637739656914,
                        "section": "اتجاهات",
                        "published_posts": 9
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 12457,
                        "pageviews": 15197,
                        "engaged_minutes": 5870,
                        "ROA": 7.8700155359917146,
                        "section": "الاقتصادي",
                        "published_posts": 29
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 10852,
                        "pageviews": 13337,
                        "engaged_minutes": 7595,
                        "ROA": 39.811940298507466,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 52,
                        "pageviews": 68,
                        "engaged_minutes": 28,
                        "ROA": 2.4285714285714284,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 15546,
                        "pageviews": 21933,
                        "engaged_minutes": 7756,
                        "ROA": 17.27007874015748,
                        "section": "الإمارات",
                        "published_posts": 41
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 13376,
                        "pageviews": 18698,
                        "engaged_minutes": 8519,
                        "ROA": 13.628279883381925,
                        "section": "منوعات",
                        "published_posts": 12
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2444,
                        "pageviews": 2718,
                        "engaged_minutes": 1976,
                        "ROA": 3.93342981186686,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 22,
                        "pageviews": 31,
                        "engaged_minutes": 14,
                        "ROA": 1.9375,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 12550,
                        "pageviews": 16372,
                        "engaged_minutes": 7092,
                        "ROA": 9.8448586891160552,
                        "section": "الرياضي",
                        "published_posts": 60
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2410,
                        "pageviews": 2690,
                        "engaged_minutes": 1527,
                        "ROA": 1.7524429967426709,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 325,
                        "pageviews": 363,
                        "engaged_minutes": 314,
                        "ROA": 2.7089552238805972,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 309,
                        "pageviews": 393,
                        "engaged_minutes": 145,
                        "ROA": 4.3666666666666663,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1426,
                        "pageviews": 1660,
                        "engaged_minutes": 927,
                        "ROA": 2.2045152722443557,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 5466,
                        "pageviews": 7705,
                        "engaged_minutes": 2903,
                        "ROA": 12.487844408427877,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 269,
                        "pageviews": 309,
                        "engaged_minutes": 208,
                        "ROA": 1.7556818181818181,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1625,
                        "pageviews": 1860,
                        "engaged_minutes": 1015,
                        "ROA": 3.6328125,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 3001,
                        "pageviews": 3558,
                        "engaged_minutes": 1981,
                        "ROA": 1.8060913705583757,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 667,
                        "pageviews": 782,
                        "engaged_minutes": 345,
                        "ROA": 1.582995951417004,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 124,
                        "pageviews": 131,
                        "engaged_minutes": 43,
                        "ROA": 1.1696428571428572,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 342,
                        "pageviews": 382,
                        "engaged_minutes": 263,
                        "ROA": 3.1311475409836067,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 353,
                        "pageviews": 418,
                        "engaged_minutes": 275,
                        "ROA": 1.8495575221238938,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 71,
                        "pageviews": 78,
                        "engaged_minutes": 42,
                        "ROA": 1.2,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 5,
                        "ROA": 1.1666666666666667,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 140,
                        "pageviews": 170,
                        "engaged_minutes": 86,
                        "ROA": 2.5373134328358211,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 7,
                        "ROA": 1.125,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 108,
                        "pageviews": 116,
                        "engaged_minutes": 101,
                        "ROA": 2.6976744186046511,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 12,
                        "pageviews": 15,
                        "engaged_minutes": 5,
                        "ROA": 1.5,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 43,
                        "pageviews": 47,
                        "engaged_minutes": 23,
                        "ROA": 1.46875,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 17,
                        "pageviews": 17,
                        "engaged_minutes": 11,
                        "ROA": 1.4166666666666667,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 60,
                        "pageviews": 61,
                        "engaged_minutes": 47,
                        "ROA": 2.44,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 5,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 2,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "رمضان ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 95,
                        "pageviews": 113,
                        "engaged_minutes": 61,
                        "ROA": 5.1363636363636367,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 2,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 590,
                        "pageviews": 694,
                        "engaged_minutes": 316,
                        "ROA": 1.316888045540797,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 41,
                        "pageviews": 54,
                        "engaged_minutes": 18,
                        "ROA": 2.25,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 14079,
                        "pageviews": 21464,
                        "engaged_minutes": 8954,
                        "ROA": 15.805596465390281,
                        "section": "منوعات",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 1257,
                        "pageviews": 1571,
                        "engaged_minutes": 388,
                        "ROA": 6.7715517241379306,
                        "section": "التقنية",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 5470,
                        "pageviews": 8016,
                        "engaged_minutes": 3044,
                        "ROA": 12.663507109004739,
                        "section": "العرب والعالم",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 2449,
                        "pageviews": 2718,
                        "engaged_minutes": 1951,
                        "ROA": 4.0147710487444606,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 1875,
                        "pageviews": 3048,
                        "engaged_minutes": 1351,
                        "ROA": 3.0944162436548224,
                        "section": "اتجاهات",
                        "published_posts": 7
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 2385,
                        "pageviews": 2647,
                        "engaged_minutes": 1478,
                        "ROA": 1.7518199867637325,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 17066,
                        "pageviews": 20887,
                        "engaged_minutes": 7749,
                        "ROA": 11.584581253466444,
                        "section": "الاقتصادي",
                        "published_posts": 21
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 284,
                        "pageviews": 291,
                        "engaged_minutes": 46,
                        "ROA": 2.0208333333333335,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 1217,
                        "pageviews": 1398,
                        "engaged_minutes": 961,
                        "ROA": 2.0928143712574849,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 266,
                        "pageviews": 287,
                        "engaged_minutes": 196,
                        "ROA": 2.5175438596491229,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 2109,
                        "pageviews": 2746,
                        "engaged_minutes": 1001,
                        "ROA": 3.0750279955207165,
                        "section": "فكر وفن",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 2716,
                        "pageviews": 3167,
                        "engaged_minutes": 1781,
                        "ROA": 1.8640376692171865,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 20,
                        "pageviews": 22,
                        "engaged_minutes": 10,
                        "ROA": 1.1578947368421053,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 8699,
                        "pageviews": 11085,
                        "engaged_minutes": 5978,
                        "ROA": 34.212962962962962,
                        "section": " الصحي",
                        "published_posts": 5
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 14930,
                        "pageviews": 19069,
                        "engaged_minutes": 8536,
                        "ROA": 11.578020643594414,
                        "section": "الرياضي",
                        "published_posts": 61
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 1603,
                        "pageviews": 1853,
                        "engaged_minutes": 1018,
                        "ROA": 3.5094696969696968,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 111,
                        "pageviews": 127,
                        "engaged_minutes": 74,
                        "ROA": 2.0483870967741935,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 80,
                        "pageviews": 88,
                        "engaged_minutes": 37,
                        "ROA": 1.76,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 16053,
                        "pageviews": 22505,
                        "engaged_minutes": 8057,
                        "ROA": 18.801169590643276,
                        "section": "الإمارات",
                        "published_posts": 29
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 68,
                        "pageviews": 78,
                        "engaged_minutes": 57,
                        "ROA": 2.6,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 138,
                        "pageviews": 145,
                        "engaged_minutes": 41,
                        "ROA": 1.4795918367346939,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 571,
                        "pageviews": 632,
                        "engaged_minutes": 287,
                        "ROA": 2.1793103448275861,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 244,
                        "pageviews": 284,
                        "engaged_minutes": 199,
                        "ROA": 1.5351351351351352,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 312,
                        "pageviews": 378,
                        "engaged_minutes": 298,
                        "ROA": 2.9076923076923076,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 33,
                        "pageviews": 35,
                        "engaged_minutes": 15,
                        "ROA": 1.6666666666666667,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 97,
                        "pageviews": 106,
                        "engaged_minutes": 75,
                        "ROA": 2.5853658536585367,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 508,
                        "pageviews": 677,
                        "engaged_minutes": 293,
                        "ROA": 7.6931818181818183,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 8,
                        "pageviews": 8,
                        "engaged_minutes": 6,
                        "ROA": 1.3333333333333333,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 7,
                        "pageviews": 7,
                        "engaged_minutes": 0,
                        "ROA": 1.4,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 13,
                        "pageviews": 16,
                        "engaged_minutes": 8,
                        "ROA": 4,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 29,
                        "pageviews": 34,
                        "engaged_minutes": 20,
                        "ROA": 1.6190476190476191,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 126,
                        "pageviews": 158,
                        "engaged_minutes": 80,
                        "ROA": 8.7777777777777786,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 3,
                        "ROA": 1.3333333333333333,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "صور من الذاكرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 22155,
                        "pageviews": 34007,
                        "engaged_minutes": 13061,
                        "ROA": 27.536032388663969,
                        "section": "الإمارات",
                        "published_posts": 39
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 2023,
                        "pageviews": 2323,
                        "engaged_minutes": 1396,
                        "ROA": 1.8334648776637728,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 2565,
                        "pageviews": 3054,
                        "engaged_minutes": 1766,
                        "ROA": 1.8276481149012567,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 2149,
                        "pageviews": 2421,
                        "engaged_minutes": 1834,
                        "ROA": 3.8736,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 35,
                        "pageviews": 44,
                        "engaged_minutes": 20,
                        "ROA": 2.0952380952380953,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 14631,
                        "pageviews": 19418,
                        "engaged_minutes": 10333,
                        "ROA": 14.677248677248677,
                        "section": "منوعات",
                        "published_posts": 10
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 31012,
                        "pageviews": 37487,
                        "engaged_minutes": 14412,
                        "ROA": 21.40890919474586,
                        "section": "الاقتصادي",
                        "published_posts": 41
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 5517,
                        "pageviews": 7297,
                        "engaged_minutes": 3822,
                        "ROA": 22.246951219512194,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 4299,
                        "pageviews": 6184,
                        "engaged_minutes": 2147,
                        "ROA": 10.006472491909385,
                        "section": "العرب والعالم",
                        "published_posts": 36
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1864,
                        "pageviews": 2606,
                        "engaged_minutes": 1350,
                        "ROA": 2.7605932203389831,
                        "section": "اتجاهات",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 2824,
                        "pageviews": 3741,
                        "engaged_minutes": 1408,
                        "ROA": 3.8173469387755103,
                        "section": "فكر وفن",
                        "published_posts": 16
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 50,
                        "pageviews": 54,
                        "engaged_minutes": 18,
                        "ROA": 1.3846153846153846,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 116,
                        "pageviews": 119,
                        "engaged_minutes": 25,
                        "ROA": 1.1333333333333333,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 21,
                        "pageviews": 25,
                        "engaged_minutes": 13,
                        "ROA": 1.4705882352941178,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1510,
                        "pageviews": 1886,
                        "engaged_minutes": 535,
                        "ROA": 8.5727272727272723,
                        "section": "التقنية",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 16743,
                        "pageviews": 21530,
                        "engaged_minutes": 10087,
                        "ROA": 13.273736128236745,
                        "section": "الرياضي",
                        "published_posts": 55
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 6,
                        "pageviews": 7,
                        "engaged_minutes": 3,
                        "ROA": 1.1666666666666667,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1477,
                        "pageviews": 1694,
                        "engaged_minutes": 1027,
                        "ROA": 3.2893203883495143,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 413,
                        "pageviews": 492,
                        "engaged_minutes": 297,
                        "ROA": 1.9759036144578312,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 278,
                        "pageviews": 302,
                        "engaged_minutes": 211,
                        "ROA": 2.7706422018348622,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1080,
                        "pageviews": 1281,
                        "engaged_minutes": 1001,
                        "ROA": 2.2434325744308232,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 437,
                        "pageviews": 523,
                        "engaged_minutes": 260,
                        "ROA": 1.3140703517587939,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 183,
                        "pageviews": 220,
                        "engaged_minutes": 136,
                        "ROA": 1.476510067114094,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 61,
                        "pageviews": 70,
                        "engaged_minutes": 41,
                        "ROA": 2.5925925925925926,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 227,
                        "pageviews": 237,
                        "engaged_minutes": 72,
                        "ROA": 2.37,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 23,
                        "pageviews": 28,
                        "engaged_minutes": 9,
                        "ROA": 1.6470588235294117,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 281,
                        "pageviews": 325,
                        "engaged_minutes": 300,
                        "ROA": 2.4436090225563909,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1142,
                        "pageviews": 1406,
                        "engaged_minutes": 549,
                        "ROA": 15.450549450549451,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 24,
                        "pageviews": 28,
                        "engaged_minutes": 14,
                        "ROA": 1.4736842105263157,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 468,
                        "pageviews": 562,
                        "engaged_minutes": 288,
                        "ROA": 37.466666666666669,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 82,
                        "pageviews": 91,
                        "engaged_minutes": 32,
                        "ROA": 1.8571428571428572,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 3,
                        "pageviews": 4,
                        "engaged_minutes": 1,
                        "ROA": 1.3333333333333333,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 89,
                        "pageviews": 100,
                        "engaged_minutes": 72,
                        "ROA": 2.5,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 93,
                        "pageviews": 107,
                        "engaged_minutes": 67,
                        "ROA": 1.9454545454545455,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 7,
                        "pageviews": 9,
                        "engaged_minutes": 10,
                        "ROA": 1.2857142857142858,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 9,
                        "pageviews": 9,
                        "engaged_minutes": 5,
                        "ROA": 3,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "البيان الأسبوعي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 38,
                        "pageviews": 51,
                        "engaged_minutes": 25,
                        "ROA": 2.4285714285714284,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 2254,
                        "pageviews": 2508,
                        "engaged_minutes": 1838,
                        "ROA": 3.603448275862069,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 16205,
                        "pageviews": 21862,
                        "engaged_minutes": 11092,
                        "ROA": 15.593437945791726,
                        "section": "منوعات",
                        "published_posts": 17
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 3537,
                        "pageviews": 4558,
                        "engaged_minutes": 2447,
                        "ROA": 14.46984126984127,
                        "section": " الصحي",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 1547,
                        "pageviews": 1773,
                        "engaged_minutes": 1064,
                        "ROA": 3.3389830508474576,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 4241,
                        "pageviews": 5510,
                        "engaged_minutes": 1982,
                        "ROA": 5.5769230769230766,
                        "section": "فكر وفن",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 628,
                        "pageviews": 754,
                        "engaged_minutes": 275,
                        "ROA": 9.3086419753086425,
                        "section": "كوفيد-19",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 16564,
                        "pageviews": 25864,
                        "engaged_minutes": 10587,
                        "ROA": 17.534915254237287,
                        "section": "الإمارات",
                        "published_posts": 53
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 3975,
                        "pageviews": 5682,
                        "engaged_minutes": 2156,
                        "ROA": 9.4228855721393039,
                        "section": "العرب والعالم",
                        "published_posts": 29
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 28511,
                        "pageviews": 34469,
                        "engaged_minutes": 12433,
                        "ROA": 18.58167115902965,
                        "section": "الاقتصادي",
                        "published_posts": 70
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 485,
                        "pageviews": 565,
                        "engaged_minutes": 304,
                        "ROA": 1.2870159453302961,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 2172,
                        "pageviews": 2443,
                        "engaged_minutes": 1450,
                        "ROA": 1.7754360465116279,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 96,
                        "pageviews": 114,
                        "engaged_minutes": 83,
                        "ROA": 2.1923076923076925,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 78,
                        "pageviews": 85,
                        "engaged_minutes": 39,
                        "ROA": 1.1486486486486487,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 1012,
                        "pageviews": 1198,
                        "engaged_minutes": 831,
                        "ROA": 1.9479674796747968,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 11195,
                        "pageviews": 14936,
                        "engaged_minutes": 6325,
                        "ROA": 9.2885572139303481,
                        "section": "الرياضي",
                        "published_posts": 50
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 2375,
                        "pageviews": 3175,
                        "engaged_minutes": 1878,
                        "ROA": 3.1909547738693469,
                        "section": "اتجاهات",
                        "published_posts": 8
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 3035,
                        "pageviews": 3643,
                        "engaged_minutes": 2101,
                        "ROA": 1.7901719901719901,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 100,
                        "pageviews": 115,
                        "engaged_minutes": 68,
                        "ROA": 1.9827586206896552,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 89,
                        "pageviews": 101,
                        "engaged_minutes": 29,
                        "ROA": 1.5538461538461539,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 5380,
                        "pageviews": 6453,
                        "engaged_minutes": 1970,
                        "ROA": 25.91566265060241,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 209,
                        "pageviews": 250,
                        "engaged_minutes": 179,
                        "ROA": 1.5723270440251573,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 9,
                        "pageviews": 14,
                        "engaged_minutes": 5,
                        "ROA": 2,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 289,
                        "pageviews": 314,
                        "engaged_minutes": 230,
                        "ROA": 2.8807339449541285,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 291,
                        "pageviews": 320,
                        "engaged_minutes": 261,
                        "ROA": 2.6016260162601625,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 398,
                        "pageviews": 471,
                        "engaged_minutes": 290,
                        "ROA": 1.8915662650602409,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 70,
                        "pageviews": 76,
                        "engaged_minutes": 52,
                        "ROA": 1.3333333333333333,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 20,
                        "pageviews": 21,
                        "engaged_minutes": 12,
                        "ROA": 1.4,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 40,
                        "pageviews": 47,
                        "engaged_minutes": 21,
                        "ROA": 1.7407407407407407,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 7,
                        "ROA": 1.375,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 82,
                        "pageviews": 91,
                        "engaged_minutes": 53,
                        "ROA": 3.3703703703703702,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 16,
                        "pageviews": 18,
                        "engaged_minutes": 8,
                        "ROA": 1.5,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 2,
                        "ROA": 1.25,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 235,
                        "pageviews": 254,
                        "engaged_minutes": 150,
                        "ROA": 21.166666666666668,
                        "section": "الاستدامة ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 29,
                        "pageviews": 32,
                        "engaged_minutes": 12,
                        "ROA": 1.4545454545454546,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 2,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 3466,
                        "pageviews": 4102,
                        "engaged_minutes": 2416,
                        "ROA": 1.8174568010633585,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 41,
                        "pageviews": 50,
                        "engaged_minutes": 29,
                        "ROA": 2,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 9641,
                        "pageviews": 14838,
                        "engaged_minutes": 5689,
                        "ROA": 11.13963963963964,
                        "section": "منوعات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 8799,
                        "pageviews": 10270,
                        "engaged_minutes": 3553,
                        "ROA": 43.333333333333336,
                        "section": "التقنية",
                        "published_posts": 6
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2409,
                        "pageviews": 2693,
                        "engaged_minutes": 1990,
                        "ROA": 3.971976401179941,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 5122,
                        "pageviews": 7583,
                        "engaged_minutes": 3006,
                        "ROA": 12.210950080515298,
                        "section": "العرب والعالم",
                        "published_posts": 32
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2623,
                        "pageviews": 3503,
                        "engaged_minutes": 1877,
                        "ROA": 3.1530153015301532,
                        "section": "اتجاهات",
                        "published_posts": 11
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 3448,
                        "pageviews": 4523,
                        "engaged_minutes": 1800,
                        "ROA": 4.340690978886756,
                        "section": "فكر وفن",
                        "published_posts": 19
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 322,
                        "pageviews": 363,
                        "engaged_minutes": 324,
                        "ROA": 2.6115107913669067,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 12700,
                        "pageviews": 20912,
                        "engaged_minutes": 7970,
                        "ROA": 14.016085790884718,
                        "section": "الإمارات",
                        "published_posts": 61
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 15005,
                        "pageviews": 18492,
                        "engaged_minutes": 6431,
                        "ROA": 9.4685099846390166,
                        "section": "الاقتصادي",
                        "published_posts": 51
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 9604,
                        "pageviews": 12392,
                        "engaged_minutes": 5216,
                        "ROA": 7.5931372549019605,
                        "section": "الرياضي",
                        "published_posts": 43
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2439,
                        "pageviews": 2742,
                        "engaged_minutes": 1621,
                        "ROA": 1.8015768725361367,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 1709,
                        "pageviews": 1941,
                        "engaged_minutes": 1094,
                        "ROA": 3.1612377850162865,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 69,
                        "pageviews": 76,
                        "engaged_minutes": 59,
                        "ROA": 2.375,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 158,
                        "pageviews": 186,
                        "engaged_minutes": 104,
                        "ROA": 2.325,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 514,
                        "pageviews": 586,
                        "engaged_minutes": 312,
                        "ROA": 1.2935982339955849,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 373,
                        "pageviews": 433,
                        "engaged_minutes": 252,
                        "ROA": 1.8583690987124464,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 291,
                        "pageviews": 319,
                        "engaged_minutes": 221,
                        "ROA": 2.7982456140350878,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 365,
                        "pageviews": 513,
                        "engaged_minutes": 159,
                        "ROA": 5.9651162790697674,
                        "section": "كوفيد-19",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 1157,
                        "pageviews": 1356,
                        "engaged_minutes": 979,
                        "ROA": 2.11875,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 5749,
                        "pageviews": 7067,
                        "engaged_minutes": 4516,
                        "ROA": 23.094771241830067,
                        "section": " الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 64,
                        "pageviews": 70,
                        "engaged_minutes": 42,
                        "ROA": 1.25,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 59,
                        "pageviews": 62,
                        "engaged_minutes": 37,
                        "ROA": 1.2653061224489797,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 18,
                        "pageviews": 31,
                        "engaged_minutes": 8,
                        "ROA": 2.5833333333333335,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 225,
                        "pageviews": 255,
                        "engaged_minutes": 162,
                        "ROA": 1.4912280701754386,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 121,
                        "pageviews": 130,
                        "engaged_minutes": 94,
                        "ROA": 2.8260869565217392,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 65,
                        "pageviews": 73,
                        "engaged_minutes": 24,
                        "ROA": 1.7380952380952381,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 10,
                        "pageviews": 11,
                        "engaged_minutes": 7,
                        "ROA": 1.8333333333333333,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 24,
                        "pageviews": 30,
                        "engaged_minutes": 14,
                        "ROA": 2,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 6,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.2,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 13,
                        "pageviews": 20,
                        "engaged_minutes": 12,
                        "ROA": 2,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 27,
                        "pageviews": 31,
                        "engaged_minutes": 18,
                        "ROA": 1.8235294117647058,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 5,
                        "ROA": 1.4285714285714286,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 5,
                        "pageviews": 5,
                        "engaged_minutes": 7,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 2,
                        "pageviews": 2,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "كاريكاتير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 4,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 0,
                        "ROA": 1,
                        "section": "ملفات البيان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 9,
                        "pageviews": 11,
                        "engaged_minutes": 8,
                        "ROA": 5.5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 2574,
                        "pageviews": 2935,
                        "engaged_minutes": 1693,
                        "ROA": 1.894770819883796,
                        "section": "5 الحواس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 78,
                        "pageviews": 87,
                        "engaged_minutes": 26,
                        "ROA": 1.8125,
                        "section": "الفيديو",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 17216,
                        "pageviews": 25886,
                        "engaged_minutes": 8982,
                        "ROA": 17.66962457337884,
                        "section": "الإمارات",
                        "published_posts": 40
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 11359,
                        "pageviews": 16866,
                        "engaged_minutes": 7267,
                        "ROA": 12.284049526584122,
                        "section": "منوعات",
                        "published_posts": 18
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 2699,
                        "pageviews": 3028,
                        "engaged_minutes": 2313,
                        "ROA": 4.4925816023738872,
                        "section": "البيان الصحي",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 2614,
                        "pageviews": 3559,
                        "engaged_minutes": 1976,
                        "ROA": 3.2681359044995411,
                        "section": "اتجاهات",
                        "published_posts": 14
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 4084,
                        "pageviews": 5954,
                        "engaged_minutes": 2211,
                        "ROA": 10.057432432432432,
                        "section": "العرب والعالم",
                        "published_posts": 35
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 350,
                        "pageviews": 398,
                        "engaged_minutes": 270,
                        "ROA": 3.2357723577235773,
                        "section": "بلسم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 2856,
                        "pageviews": 3744,
                        "engaged_minutes": 1438,
                        "ROA": 3.6314258001939863,
                        "section": "فكر وفن",
                        "published_posts": 20
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 3666,
                        "pageviews": 4373,
                        "engaged_minutes": 2549,
                        "ROA": 1.8412631578947369,
                        "section": "عبر الإمارات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 35517,
                        "pageviews": 42672,
                        "engaged_minutes": 20015,
                        "ROA": 21.475591343734273,
                        "section": "الاقتصادي",
                        "published_posts": 54
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 534,
                        "pageviews": 623,
                        "engaged_minutes": 299,
                        "ROA": 1.3283582089552239,
                        "section": "عالم واحد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 13036,
                        "pageviews": 16904,
                        "engaged_minutes": 7468,
                        "ROA": 9.5395033860045153,
                        "section": "الرياضي",
                        "published_posts": 47
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 1765,
                        "pageviews": 2034,
                        "engaged_minutes": 1163,
                        "ROA": 3.6,
                        "section": "ملاحق",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 433,
                        "pageviews": 510,
                        "engaged_minutes": 315,
                        "ROA": 1.9465648854961832,
                        "section": "ديارنا",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 8492,
                        "pageviews": 9961,
                        "engaged_minutes": 3635,
                        "ROA": 46.11574074074074,
                        "section": "التقنية",
                        "published_posts": 4
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 97,
                        "pageviews": 109,
                        "engaged_minutes": 87,
                        "ROA": 2.6585365853658538,
                        "section": "دويتشه فيله",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 1216,
                        "pageviews": 1448,
                        "engaged_minutes": 969,
                        "ROA": 2.2731554160125587,
                        "section": "مسارات ",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 69,
                        "pageviews": 86,
                        "engaged_minutes": 46,
                        "ROA": 1.4098360655737705,
                        "section": "إكسبو 2020",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 56,
                        "pageviews": 76,
                        "engaged_minutes": 43,
                        "ROA": 1.2881355932203389,
                        "section": "الصفحة الأخيرة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 11,
                        "pageviews": 11,
                        "engaged_minutes": 8,
                        "ROA": 1,
                        "section": "رمضان الخمسين",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 163,
                        "pageviews": 179,
                        "engaged_minutes": 103,
                        "ROA": 2.0813953488372094,
                        "section": "العلم اليوم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 42,
                        "pageviews": 45,
                        "engaged_minutes": 20,
                        "ROA": 1.5517241379310345,
                        "section": null,
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 15798,
                        "pageviews": 18886,
                        "engaged_minutes": 14104,
                        "ROA": 61.31818181818182,
                        "section": " الصحي",
                        "published_posts": 2
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 244,
                        "pageviews": 282,
                        "engaged_minutes": 165,
                        "ROA": 1.4461538461538461,
                        "section": "الكتب",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 304,
                        "pageviews": 367,
                        "engaged_minutes": 316,
                        "ROA": 2.7803030303030303,
                        "section": "اخترنا لكم",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 97,
                        "pageviews": 105,
                        "engaged_minutes": 74,
                        "ROA": 2.763157894736842,
                        "section": "الابتكار التفاعلي ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 1,
                        "pageviews": 1,
                        "engaged_minutes": 1,
                        "ROA": 1,
                        "section": "مسابقة التسامح        ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 35,
                        "pageviews": 46,
                        "engaged_minutes": 18,
                        "ROA": 2.0909090909090908,
                        "section": "معرض الصور",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 1564,
                        "pageviews": 1989,
                        "engaged_minutes": 766,
                        "ROA": 29.686567164179106,
                        "section": "كوفيد-19",
                        "published_posts": 1
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 10,
                        "pageviews": 10,
                        "engaged_minutes": 8,
                        "ROA": 5,
                        "section": "مبادرات",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 5,
                        "pageviews": 6,
                        "engaged_minutes": 3,
                        "ROA": 1.5,
                        "section": " البيان TV ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 36,
                        "pageviews": 37,
                        "engaged_minutes": 16,
                        "ROA": 1.48,
                        "section": "رمضان",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 20,
                        "pageviews": 22,
                        "engaged_minutes": 9,
                        "ROA": 1.2222222222222223,
                        "section": "المعرفة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 45,
                        "pageviews": 67,
                        "engaged_minutes": 23,
                        "ROA": 2.4814814814814814,
                        "section": "الاستدامة ",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 3,
                        "pageviews": 3,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "24 ساعة",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 7,
                        "pageviews": 8,
                        "engaged_minutes": 6,
                        "ROA": 1.1428571428571428,
                        "section": "حوار الشرق الأوسط",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 9,
                        "pageviews": 10,
                        "engaged_minutes": 7,
                        "ROA": 1.6666666666666667,
                        "section": "عام زايد",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 3,
                        "ROA": 1,
                        "section": "ميتافيرس",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 4,
                        "pageviews": 5,
                        "engaged_minutes": 1,
                        "ROA": 1.25,
                        "section": "عام الخير",
                        "published_posts": 0
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 4,
                        "pageviews": 4,
                        "engaged_minutes": 2,
                        "ROA": 1,
                        "section": "ثقافة",
                        "published_posts": 0
                    }
                ],
                "api": "/content-analysis-totals"
            }
        }
    },
}
export const utitlityApi = {

}

export default contentAnalysisData;