
const PRODUCTION = 'https://cx-portal-api-dot-cognativex-api.nw.r.appspot.com/';
//const PRODUCTION = 'https://cx-portal-api-v2-dot-cognativex-api.nw.r.appspot.com/';

const LOCAL = "https://cx-portal-api-dot-cognativex-dev.ew.r.appspot.com/";

export const ES_API = "https://search.cognativex.com/api"

export const isDev = false;

export default isDev ? LOCAL : PRODUCTION;