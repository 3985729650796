import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    postsActions,
} from '../../../actions';

import AdvancedFilter from "../../components/advancedFilter";

class PostsAdvancedFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(page) {
        this.props.getFilters();
    }

    filterAdd = (action) => {
        this.props.addToFilter(action);
    }

    filterRemove = (action) => {
        this.props.removeFromFilter(action)
    }

    render() {
        return (
            <AdvancedFilter
                list={this.props.filters}
                selected={this.props.selectedFilter}
                onRemove={this.filterRemove}
                onAdd={this.filterAdd}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: (state.postsInsight && state.postsInsight.pageFilterList)? state.postsInsight.pageFilterList : [],
        selectedFilter: (state.postsInsight && state.postsInsight.pageFilters)? state.postsInsight.pageFilters : []
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        getFilters: () => dispatch(postsActions.fetchPostFilters()),
        addToFilter: (newFilter) => dispatch(postsActions.addPostFilter(newFilter)),
        removeFromFilter: (filter) => dispatch(postsActions.removePostFilter(filter)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(PostsAdvancedFilter);