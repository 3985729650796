import React, { useState, useEffect } from "react";
import CxLoader from "../components/cxLoader";
import SegmentationCard from "./components/SegmentionCard"
import { getSegments } from "./segmentAPI";
import { useQuery } from "react-query"
import { CXConfirm, CXDrawer, CXPill } from "../../CXComponents" 
import useSegmentStore from "./segmentStore";
import {CreateSegment, EditSegment} from "./SegmentForm";


function SegmentationList() {
  
    const { isLoading, isFetching, data } = useQuery(["segments-list"], ()=>{
        return getSegments();
    }, { refetchOnWindowFocus: false });

    const isOpen = useSegmentStore(state=>state.isOpen);
    const openDrawer = useSegmentStore(state=>state.openDrawer);
    const closeDrawer = useSegmentStore(state=>state.closeDrawer);
    const setActionType = useSegmentStore(state=>state.setActionType);
    const actionType = useSegmentStore(state=>state.actionType);
    const isDirty = useSegmentStore(state=>state.isDirty);
    const formTitle = actionType === "create" ? "Create Segment" : "View & Edit Segment";

    
    return (
        <>

            {
                isOpen && 
                <CXDrawer
                    isOpen={isOpen}
                    closeDrawer={closeDrawer}
                    title={formTitle}
                    width="80vw"
                    isDirty={isDirty}
                >
                    {
                        actionType === "create"
                        ? <CreateSegment />
                        : <EditSegment />
                    }
                </CXDrawer>
            }
            <div className="content">
                <div>
                    <h4 style={{ "marginBottom": "20px" }}><i className="fa fa-users"></i>&nbsp; Segmentation</h4>
                </div>
                {
                    (!isLoading && !isFetching) ? 
                    <div className="segmentationList">
                        <div className="segment-card-container add-segment-btn" onClick={()=>{setActionType('create'); openDrawer()}}>
                            <i className="fa fa-plus"></i>
                        </div>
                        {
                            data && 
                            data.map((seg, i)=>{
                                return (
                                    <SegmentationCard
                                        key={i}
                                        id={seg.id}
                                        title={seg.name}
                                        userCount={seg.userCount}
                                        nbOfDimenstions={seg.dimensionsCount || 0}
                                        status={"active"}
                                    />
                                )
                            })
                        }
                    </div>
                    : <CxLoader minHeight="80vh"/>
                }
                
            </div>
        </>
    );
}

export default SegmentationList;
