import {
    AUTHORS_SUMMARY,
    AUTHORS_PERFORMANCE,
    AUTHORS_TABLE,
    AUTHORS_TAGS,
    AUTHORS_PERFORMANCE_FILTER,
    AUTHORS_PERFORMANCE_FILTERS_SELECTED
} from '../constants/appConstant';
export default function (state = {} , action) {
    switch(action.type) {
        case AUTHORS_PERFORMANCE_FILTER:
            return { ...state, pageFilterList: action.payload };
        case AUTHORS_PERFORMANCE_FILTERS_SELECTED:
            return { ...state, pageFilters: action.payload };
        case AUTHORS_SUMMARY:
            return { ...state, summary: action.payload };
        case AUTHORS_PERFORMANCE:
            return { ...state, performance: action.payload };
        case AUTHORS_TABLE:
            return { ...state, table: action.payload };
        case AUTHORS_TAGS:
            return { ...state, tags: action.payload };
        default:
            return state;
    }
}