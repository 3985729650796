import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { getNewsletterPerformanceTotals } from "../newletterAnalysisAPI";
import "../newsletterAnalysis.scss";
import { Grid } from "@mui/material";
import CxLoader from "../../../components/cxLoader";

const NewsletterPerformanceTotal = () => {
    const { data, isLoading } = useQuery(
        ["get-newsletter-performance-totals"],
        getNewsletterPerformanceTotals,
        {
            refetchOnWindowFocus: false,
        }
    );
    const getData = () => {
        if (!data) return [];
        return data;
    };
    let totals = getData()[0];
    return (
        <>
            {isLoading ? (
                <CxLoader inPlace="true"/>
            ) : (
                <div className="email-newsletter-analysis-cards">
                    <Card
                        sx={{
                            minWidth: 275,
                            width: "80px",
                            margin: "auto",
                            backgroundColor: "#32334d",
                            color: "white",
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Email Impressions
                            </Typography>
                            <Typography sx={{ mb: 1.5 }}>
                                {totals.impressions}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            minWidth: 275,
                            width: "80px",
                            margin: "auto",
                            backgroundColor: "#32334d",
                            color: "white",
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Email Clicks
                            </Typography>
                            <Typography sx={{ mb: 1.5 }}>
                                {totals.clicks}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            )}
        </>
    );
};

export default NewsletterPerformanceTotal;
