import publisherAPI from '../../../api/publisherManageApi';
import organizationAPI from '../../../api/organization'
import roleAPI from '../../../api/role'
import { useQueryClient } from 'react-query';


export const fetchList = async () => {
    return publisherAPI.getList()
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data)
                res = response.data.data;
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const readPublisher = async (id) => {
    return publisherAPI.getItem({id: id})
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data){
                res = response.data.data;
            }
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const createPublisher = async (mapped) => {
    return publisherAPI.create(mapped)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            return (error);
        });
}

export const editPublisher = async (data) => {
    return publisherAPI.updateItem(data)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}

export const deletePublisher = async (ids) => {
    return publisherAPI.delete({ids: ids})
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}


// utitlity
export const getOrganizations = async () => {
    return organizationAPI.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}
