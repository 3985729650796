import { POSTS_SUMMARY, POSTS_PAGEVIEWS_GRAPH, POSTS_TOP_POSTS, POSTS_ENGAGEMENT,POSTS_PERFORMANCE_FILTER,POSTS_PERFORMANCE_FILTERS_SELECTED } from '../constants/appConstant';
export default function (state = {} , action) {
    switch(action.type) {
        case POSTS_PERFORMANCE_FILTER:
            return { ...state, pageFilterList: action.payload };
        case POSTS_PERFORMANCE_FILTERS_SELECTED:
            return { ...state, pageFilters: action.payload };
        case POSTS_SUMMARY:
            return { ...state, summary: action.payload };
        case POSTS_PAGEVIEWS_GRAPH:
            return { ...state, pageviewsGraph: action.payload };
        case POSTS_TOP_POSTS:
            return { ...state, topPosts: action.payload };
        case POSTS_ENGAGEMENT:
            return { ...state, cxEngagement: action.payload };
        default:
            return state;
    }
}