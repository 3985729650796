import {
    SET_USERPERMISSION_ACTION_TYPE,
    FETCH_USERPERMISSIONS,
    FETCH_USERPERMISSION_ITEM,
    UPDATE_USERPERMISSION,
    UPDATE_ERR_USERPERMISSION,
    CREATE_USERPERMISSION,
    CREATE_ERR_USERPERMISSION,
    DELETE_USERPERMISSION,
    DELETE_ERR_USERPERMISSION
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_USERPERMISSION_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_USERPERMISSIONS:
            return { ...state, List: action.payload };
         case FETCH_USERPERMISSION_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_USERPERMISSION:
            return { ...state, update: action.payload };
        case UPDATE_ERR_USERPERMISSION:
            return { ...state, updateErr: action.payload };
        case CREATE_USERPERMISSION:
            return { ...state, create: action.payload };
        case CREATE_ERR_USERPERMISSION:
            return { ...state, createErr: action.payload };
        case DELETE_USERPERMISSION:
            return { ...state, delete: action.payload };
        case DELETE_ERR_USERPERMISSION:
            return { ...state, deleteErr: action.payload };     
        default:
            return state;
    }
}