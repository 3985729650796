import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();

const articleBoostApi = {
    getList : async () => {
        const url = apiUrl + `/article-boost-list`;
        return auth.request.post(url);
    },
    read : async (id) => {
        const url = apiUrl + `/article-boost-read`;
        console.log(id)
        return auth.request.post(url, { data: JSON.stringify({id}) });
    },
    create : async (data) => {
        const url = apiUrl + `/article-boost-create`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    update : async (data) => {
        const url = apiUrl + `/article-boost-update`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
    delete : async (data) => {
        const url = apiUrl + `/article-boost-delete`;
        return auth.request.post(url, {data: JSON.stringify(data)});
    },
}
export const utitlityApi = {
    getPublishers : async () => {
        const url = apiUrl + `/publisher-list`;
        return auth.request.post(url);
    },

    getWidgets : async () => {
        const url = apiUrl + `/widget-list`;
        return auth.request.post(url);
    },

    getPublisherByOrganization : async () => {
        const url = apiUrl + `/publisher-read-by-organization`;
        return auth.request.post(url);
    }
}

export default articleBoostApi;