/*
 * action types
 */
export const FETCH_CAMPAIGN_LIST = 'CAMPAIGN_LIST';
export const FETCH_ADSET_LIST = 'ADSET_LIST';
export const FETCH_AD_LIST = 'AD_LIST';
export const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
export const FETCH_IAB = 'FETCH_IAB';

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const FETCH_REGION_LIST = 'FETCH_REGION_LIST';
export const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST';
export const FETCH_REGION_SELECT_OPTIONS = 'FETCH_REGION_SELECT_OPTIONS';
export const FETCH_LANG_LIST = 'FETCH_LANG_LIST';
export const FETCH_LOCATION_LIST = 'FETCH_LOCATION_LIST';
export const FETCH_LOCATION_SELECT_OPTIONS = 'FETCH_LOCATION_SELECT_OPTIONS';
export const FETCH_CATEGORIES_LIST = 'FETCH_CATEGORIES_LIST';
export const FETCH_CATEGORIES_OPTIONS = 'FETCH_CATEGORIES_OPTIONS';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_PERCENT = 'UPLOAD_PERCENT';
export const DRAWER_VISIBLE = 'DRAWER_VISIBLE';

export const DRAWER_TREE = 'DRAWER_TREE';
export const DRAWER_MODE = 'DRAWER_MODE';

export const QUICK_CREATE_CAMPAIN = 'QUICK_CREATE_CAMPAIN';
export const QUICK_CREATE_ADSET = 'QUICK_CREATE_ADSET';
export const QUICK_CREATE_AD = 'QUICK_CREATE_AD';


export const SET_SELECTION_ARRAYS = 'SET_SELECTION_ARRAYS';
export const SET_SELECTION_MAP = 'SET_SELECTION_MAP';
export const TOGGLE_SELECTION_CAMPAIGN = 'TOGGLE_SELECTION_CAMPAIGN';
export const TOGGLE_ALL_CAMPAIGN = 'SELECT_ALL_CAMPAIGN';
export const FETCH_CAMPAIGN_TREE= 'CAMPAIGN_TREE';
export const DRAWER_FORM = 'DRAWER_FORM';
export const DRAWER_FORM_RESET = 'DRAWER_FORM_RESET';

export const FETCH_CAMPAIGN_ITEM = 'FETCH_CAMPAIGN_ITEM';
export const FETCH_ADSET_ITEM= 'FETCH_ADSET_ITEM';
export const FETCH_AD_ITEM = 'FETCH_AD_ITEM';

export const UPDATE_CAMPAIGN_ITEM = 'UPDATE_CAMPAIGN_ITEM';
export const UPDATE_ADSET_ITEM = 'UPDATE_ADSET_ITEM';
export const UPDATE_AD_ITEM = 'UPDATE_AD_ITEM';

export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS';
export const UPDATE_ADSET_STATUS = 'UPDATE_ADSET_STATUS';
export const UPDATE_ADSET_ERROR = 'UPDATE_ADSET_ERROR';
export const UPDATE_AD_STATUS = 'UPDATE_AD_STATUS';
export const UPDATE_AD_ERROR = 'UPDATE_AD_ERROR';

export const PUBLISH_CAMPAIGN = 'PUBLISH_CAMPAIGN';
export const PUBLISH_CAMPAIGN_ERROR = 'PUBLISH_CAMPAIGN_ERROR';
export const UPDATE_CAMPAIGN_ERROR = 'UPDATE_CAMPAIGN_ERROR';
export const PUBLISH_ADSET = 'PUBLISH_ADSET';
export const PUBLISH_ADSET_ERROR = 'PUBLISH_ADSET_ERROR';
export const PUBLISH_AD_ERROR = 'PUBLISH_AD_ERROR';
export const PUBLISH_AD = 'PUBLISH_AD';

export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';
export const DUPLICATE_CAMPAIGN_ERROR = 'DUPLICATE_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_ITEM = 'DELETE_CAMPAIGN_ITEM';
export const DELETE_ADSET_ITEM = 'DELETE_ADSET_ITEM';
export const DELETE_AD_ITEM = 'DELETE_AD_ITEM';

export const DUPLICATE_CAMPAIGN_ITEM = 'DUPLICATE_CAMPAIGN_ITEM';
export const DUPLICATE_ADSET_ITEM = 'DUPLICATE_ADSET_ITEM';
export const DUPLICATE_ADSET_ERROR = 'DUPLICATE_ADSET_ERROR';
export const CREATE_ADSET_ERROR = 'CREATE_ADSET_ERROR';
export const CREATE_AD_ERROR = 'CREATE_AD_ERROR';
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR';
export const DELETE_ADSET_ERROR = 'DELETE_ADSET_ERROR';
export const CONFIG_RECOMMENDATION_ERROR = 'CONFIG_RECOMMENDATION_ERROR';
export const DUPLICATE_AD_ITEM = 'DUPLICATE_AD_ITEM';
export const DUPLICATE_AD_ERROR = "DUPLICATE_AD_ERROR";
export const DELETE_AD_ERROR = "DELETE_AD_ERROR";

export const FETCH_TO_PUBLISH = "FETCH_TO_PUBLISH";
export const FETCH_TO_PUBLISH_ERR = "FETCH_TO_PUBLISH_ERR";

export const ALL_PUBLISHED = "ALL_PUBLISHED";
export const ERR_PUBLISH = "ERR_PUBLISH";
export const CAMPAIGN_FILTER = "CAMPAIGN_FILTER";
export const CAMPAIGN_FILTERS_SELECTED = "CAMPAIGN_FILTERS_SELECTED";
export const LOGIN = "LOGIN";
export const LOGIN_ERR = "LOGIN_ERR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const SET_FILTER = 'SET_FILTER';

export const ACTIVATE_TAB = 'ACTIVATE_TAB';

export const FETCH_ADS_REVIEW = 'FETCH_ADS_REVIEW';
export const FETCH_ALLOCATIONS = 'FETCH_ALLOCATIONS';
export const UPDATE_ALLOCATION_ERROR = 'UPDATE_ALLOCATION_ERROR';
export const FETCH_ALLOCATION_ITEM = 'FETCH_ALLOCATION_ITEM';
export const UPDATE_ALLOCATION = 'UPDATE_ALLOCATION';

export const ACCEPT_ADS_REVIEW = 'ACCEPT_ADS_REVIEW';
export const ERR_ACCEPT_ADS_REVIEW = 'ERR_ACCEPT_ADS_REVIEW';
export const REJECT_ADS_REVIEW = 'REJECT_ADS_REVIEW';
export const ERR_REJECT_ADS_REVIEW = 'ERR_REJECT_ADS_REVIEW';
export const TABLE_SELECTIONS = 'TABLE_SELECTIONS';

export const FETCH_CLASSES_LIST = 'FETCH_CLASSES_LIST';
export const FETCH_CLASSES_LIST_ERR = 'FETCH_CLASSES_LIST_ERR';



export const FETCH_RECOMMENDATION_CLASS_LIST = 'FETCH_RECOMMENDATION_CLASS_LIST';
export const DEFAULT_RECOMMENDATION_FRESHNESS_DAYS = 'DEFAULT_RECOMMENDATION_FRESHNESS_DAYS';

export const SET_RECOMMENDATION_ACTION_TYPE = 'SET_RECOMMENDATION_ACTION_TYPE';
export const FETCH_RECOMMENDATION_CONFIGS = 'FETCH_RECOMMENDATION_CONFIGS';
export const FETCH_RECOMMENDATION_CONFIG_ITEM = 'FETCH_RECOMMENDATION_CONFIG_ITEM';
export const UPDATE_RECOMMENDATION_CONFIG = 'UPDATE_RECOMMENDATION_CONFIG';
export const UPDATE_ERR_RECOMMENDATION_CONFIG = 'UPDATE_ERR_RECOMMENDATION_CONFIG';
export const CREATE_RECOMMENDATION_CONFIG = 'CREATE_RECOMMENDATION_CONFIG';
export const CREATE_ERR_RECOMMENDATION_CONFIG = 'CREATE_ERR_RECOMMENDATION_CONFIG';
export const DELETE_RECOMMENDATION_CONFIG = 'DELETE_RECOMMENDATION_CONFIG';
export const DELETE_ERR_RECOMMENDATION_CONFIG = 'DELETE_ERR_RECOMMENDATION_CONFIG';

export const SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE = 'SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE';
export const FETCH_CAMPAIGN_CATEGORY_MAPPINGS = 'FETCH_CAMPAIGN_CATEGORY_MAPPINGS';
export const FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM = 'FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM';
export const UPDATE_CAMPAIGN_CATEGORY_MAPPING = 'UPDATE_CAMPAIGN_CATEGORY_MAPPING';
export const UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING = 'UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING';
export const CREATE_CAMPAIGN_CATEGORY_MAPPING = 'CREATE_CAMPAIGN_CATEGORY_MAPPING';
export const CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING = 'CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING';
export const DELETE_CAMPAIGN_CATEGORY_MAPPING = 'DELETE_CAMPAIGN_CATEGORY_MAPPING';
export const DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING = 'DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING';




export const SET_ORGANIZATION_ACTION_TYPE = 'SET_ORGANIZATION_ACTION_TYPE';
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATION_ITEM = 'FETCH_ORGANIZATION_ITEM';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ERR_ORGANIZATION = 'UPDATE_ERR_ORGANIZATION';
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ERR_ORGANIZATION = 'CREATE_ERR_ORGANIZATION';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ERR_ORGANIZATION = 'DELETE_ERR_ORGANIZATION';


export const SET_PUBLISHER_ACTION_TYPE = 'SET_PUBLISHER_ACTION_TYPE';
export const FETCH_PUBLISHERS = 'FETCH_PUBLISHERS';
export const FETCH_PUBLISHER_ITEM = 'FETCH_PUBLISHER_ITEM';
export const UPDATE_PUBLISHER = 'UPDATE_PUBLISHER';
export const UPDATE_ERR_PUBLISHER = 'UPDATE_ERR_PUBLISHER';
export const CREATE_PUBLISHER = 'CREATE_PUBLISHER';
export const CREATE_ERR_PUBLISHER = 'CREATE_ERR_PUBLISHER';
export const DELETE_PUBLISHER = 'DELETE_PUBLISHER';
export const DELETE_ERR_PUBLISHER = 'DELETE_ERR_PUBLISHER';


export const SET_USERPERMISSION_ACTION_TYPE = 'SET_USERPERMISSION_ACTION_TYPE';
export const FETCH_USERPERMISSIONS = 'FETCH_USERPERMISSIONS';
export const FETCH_USERPERMISSION_ITEM = 'FETCH_USERPERMISSION_ITEM';
export const UPDATE_USERPERMISSION = 'UPDATE_USERPERMISSION';
export const UPDATE_ERR_USERPERMISSION = 'UPDATE_ERR_USERPERMISSION';
export const CREATE_USERPERMISSION = 'CREATE_USERPERMISSION';
export const CREATE_ERR_USERPERMISSION = 'CREATE_ERR_USERPERMISSION';
export const DELETE_USERPERMISSION = 'DELETE_USERPERMISSION';
export const DELETE_ERR_USERPERMISSION = 'DELETE_ERR_USERPERMISSION';


export const SET_ROLE_ACTION_TYPE = 'SET_ROLE_ACTION_TYPE';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLE_ITEM = 'FETCH_ROLE_ITEM';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ERR_ROLE = 'UPDATE_ERR_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ERR_ROLE = 'CREATE_ERR_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ERR_ROLE = 'DELETE_ERR_ROLE';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';


export const SET_AUDIT_ACTION_TYPE = 'SET_AUDIT_ACTION_TYPE';
export const FETCH_AUDITS = 'FETCH_AUDITS';
export const FETCH_AUDIT_ITEM = 'FETCH_AUDIT_ITEM';
export const UPDATE_AUDIT = 'UPDATE_AUDIT';
export const UPDATE_ERR_AUDIT = 'UPDATE_ERR_AUDIT';
export const CREATE_AUDIT = 'CREATE_AUDIT';
export const CREATE_ERR_AUDIT = 'CREATE_ERR_AUDIT';
export const DELETE_AUDIT = 'DELETE_AUDIT';
export const DELETE_ERR_AUDIT = 'DELETE_ERR_AUDIT';

export const SET_WIDGET_ACTION_TYPE = 'SET_WIDGET_ACTION_TYPE';
export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const FETCH_WIDGET_ITEM = 'FETCH_WIDGET_ITEM';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const UPDATE_ERR_WIDGET = 'UPDATE_ERR_WIDGET';
export const CREATE_WIDGET = 'CREATE_WIDGET';
export const CREATE_ERR_WIDGET = 'CREATE_ERR_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const DELETE_ERR_WIDGET = 'DELETE_ERR_WIDGET';



export const SET_USER_ACTION_TYPE = 'SET_USER_ACTION_TYPE';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER_ITEM = 'FETCH_USER_ITEM';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ERR_USER = 'UPDATE_ERR_USER';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_ERR_USER = 'CREATE_ERR_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_ERR_USER = 'DELETE_ERR_USER';

export const WIDGET_PERFORMANCE_FILTER = 'WIDGET_PERFORMANCE_FILTER';
export const WIDGET_PERFORMANCE_FILTERS_SELECTED = 'WIDGET_PERFORMANCE_FILTERS_SELECTED';
export const WIDGET_PERFORMANCE_SUMMARY = 'WIDGET_PERFORMANCE_SUMMARY';
export const WIDGET_PERFORMANCE_BY_DEVICE = 'WIDGET_PERFORMANCE_BY_DEVICE';
export const WIDGET_PERFORMANCE_BY_TIME = 'WIDGET_PERFORMANCE_BY_TIME';
export const WIDGET_PERFORMANCE_BY_LOCATION = 'WIDGET_PERFORMANCE_BY_LOCATION';
export const WIDGET_PERFORMANCE_TOP_POSTS = 'WIDGET_PERFORMANCE_TOP_POSTS';
export const WIDGET_PERFORMANCE_TOP_WIDGETS = 'WIDGET_PERFORMANCE_TOP_WIDGETS';

export const AUTHORS_SUMMARY = 'AUTHORS_SUMMARY';
export const AUTHORS_PERFORMANCE = 'AUTHORS_PERFORMANCE';
export const AUTHORS_TABLE = 'AUTHORS_TABLE';
export const AUTHORS_TAGS = 'AUTHORS_TAGS';
export const AUTHORS_PERFORMANCE_FILTER = 'AUTHORS_PERFORMANCE_FILTER';
export const AUTHORS_PERFORMANCE_FILTERS_SELECTED = 'AUTHORS_PERFORMANCE_FILTERS_SELECTED';



export const CLASSIFICATION_TABS = 'CLASSIFICATION_TABS';
export const CLASSIFICATION_ACTIVE_TAB = 'CLASSIFICATION_TAB';
export const CLASSIFICATION_SUMMARY = 'CLASSIFICATION_SUMMARY';
export const CLASSIFICATION_PERFORMANCE = 'CLASSIFICATION_PERFORMANCE';
export const CLASSIFICATION_TABLE = 'CLASSIFICATION_TABLE';
export const CLASSIFICATION_PUBLISHED_ARTICLES = 'CLASSIFICATION_PUBLISHED_ARTICLES';
export const CLASSIFICATION_PERFORMANCE_FILTER = 'CLASSIFICATION_PERFORMANCE_FILTER';
export const CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED = 'CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED';

export const ONBOARDING_ADD_INFO = 'ONBOARDING_ADD_INFO';
export const ONBOARDING_ADD_INFO_ERR = 'ONBOARDING_ADD_INFO_ERR';
export const ONBOARDING_VALIDATE_METADATA = 'ONBOARDING_VALIDATE_METADATA';
export const ONBOARDING_VALIDATE_METADATA_ERR = 'ONBOARDING_VALIDATE_METADATA_ERR';

export const ONBOARDING_ADD_USERS_ERR = 'ONBOARDING_ADD_USERS_ERR';
export const ONBOARDING_LOAD_DETAILS = 'ONBOARDING_LOAD_DETAILS';
export const ONBOARDING_SEND_EMAIL_ERR = 'ONBOARDING_SEND_EMAIL_ERR';

export const POSTS_PERFORMANCE_FILTER = 'POSTS_PERFORMANCE_FILTER';
export const POSTS_PERFORMANCE_FILTERS_SELECTED = 'POSTS_PERFORMANCE_FILTERS_SELECTED';
export const POSTS_SUMMARY = 'POSTS_SUMMARY';
export const POSTS_PAGEVIEWS_GRAPH = 'POSTS_PAGEVIEWS_GRAPH';
export const POSTS_TOP_POSTS = 'POSTS_TOP_POSTS';
export const POSTS_ENGAGEMENT = 'POSTS_ENGAGEMENT';

export const SET_REPORT_TAB = 'SET_PERFORMANCE_TAB';
export const SET_REPORT_SUBTAB = 'SET_REPORT_SUBTAB';
export const FETCH_REPORT_LOCATION = 'FETCH_REPORT_LOCATION';
export const FETCH_REPORT_PERFORMANCE = 'FETCH_REPORT_PERFORMANCE';
export const FETCH_REPORT_DEVICES = 'FETCH_REPORT_DEVICES';
export const FETCH_REPORT_PERFORMANCE_TOTALS = 'FETCH_REPORT_PERFORMANCE_TOTALS';

export const FETCH_MAP_DATA = 'FETCH_MAP_DATA';

export const FETCH_ADVERTISER_OPTIONS = 'FETCH_ADVERTISER_OPTIONS';
export const PIXEL_LIST = 'PIXEL_LIST';

export const CREATE_PIXEL = 'CREATE_PIXEL';
export const UPDATE_PIXEL = 'UPDATE_PIXEL';
export const DELETE_PIXEL = 'DELETE_PIXEL';

export const SET_ADVERTISER_ACTION_TYPE = 'SET_ADVERTISER_ACTION_TYPE';
export const FETCH_ADVERTISERS = 'FETCH_ADVERTISERS';
export const FETCH_ADVERTISER_ITEM = 'FETCH_ADVERTISER_ITEM';
export const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UPDATE_ERR_ADVERTISER = 'UPDATE_ERR_ADVERTISER';
export const CREATE_ADVERTISER = 'CREATE_ADVERTISER';
export const CREATE_ERR_ADVERTISER = 'CREATE_ERR_ADVERTISER';
export const DELETE_ADVERTISER = 'DELETE_ADVERTISER';
export const DELETE_ERR_ADVERTISER = 'DELETE_ERR_ADVERTISER';



