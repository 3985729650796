import {
    FETCH_CAMPAIGN_TREE,
    FETCH_CAMPAIGN_LIST,
    FETCH_CAMPAIGN_ITEM,
    UPDATE_CAMPAIGN_ITEM,
    QUICK_CREATE_CAMPAIN,
    DUPLICATE_CAMPAIGN_ITEM,
    DELETE_CAMPAIGN_ITEM,
    UPDATE_CAMPAIGN_STATUS,
    PUBLISH_CAMPAIGN,
    PUBLISH_CAMPAIGN_ERROR,
    UPDATE_CAMPAIGN_ERROR,
    DELETE_CAMPAIGN_ERROR,
    DUPLICATE_CAMPAIGN_ERROR,
    CREATE_CAMPAIGN_ERROR,
    FETCH_TO_PUBLISH,
    FETCH_TO_PUBLISH_ERR,
    ALL_PUBLISHED,
    ERR_PUBLISH,
    CAMPAIGN_FILTER,
    CAMPAIGN_FILTERS_SELECTED
} from "../constants/appConstant";
import campaignApi from '../api/campaigns';
import { SubmissionError } from "redux-form";
import _ from "lodash";

const api = new campaignApi();
/*
 * action creators
 */
const campaignListEvent = (campaigns) => ({ type: FETCH_CAMPAIGN_LIST, payload: campaigns });
const quickCreateCampaignEvent = (res) => ({ type: QUICK_CREATE_CAMPAIN, payload: res });
const campaignsTreeEvent = (campaigns) => ({ type: FETCH_CAMPAIGN_TREE, payload: campaigns });
export const campaignEvent = (campaign) => ({ type: FETCH_CAMPAIGN_ITEM, payload: campaign });
const campaignUpdateEvent = (campaign) => ({ type: UPDATE_CAMPAIGN_ITEM, payload: campaign });
const campaignDuplicateEvent = (campaign) => ({ type: DUPLICATE_CAMPAIGN_ITEM, payload: campaign });
const campaignDeleteEvent = (campaign) => ({ type: DELETE_CAMPAIGN_ITEM, payload: campaign });
const activateCampaignEvent = (campaign) => ({ type: UPDATE_CAMPAIGN_STATUS, payload: campaign });
const campaignPublishEvent = (campaign) => ({ type: PUBLISH_CAMPAIGN, payload: campaign });
export const campaignPublishError = (err) => ({ type: PUBLISH_CAMPAIGN_ERROR, payload: err });
export const campaignUpdateError = (err) => ({ type: UPDATE_CAMPAIGN_ERROR, payload: err });
export const campaignDeleteError = (err) => ({ type: DELETE_CAMPAIGN_ERROR, payload: err });
export const campaignDuplicateError = (err) => ({ type: DUPLICATE_CAMPAIGN_ERROR, payload: err });
export const campaignCreateError = (err) => ({ type: CREATE_CAMPAIGN_ERROR, payload: err });
const fetchToPublishEvent = (err) => ({ type: FETCH_TO_PUBLISH, payload: err });
export const fetchToPublishErr = (err) => ({ type: FETCH_TO_PUBLISH_ERR , payload: err });
export const publishAllEvent = (d) => ({ type: ALL_PUBLISHED , payload: d });
export const publishAllEventErr = (err) => ({ type: ERR_PUBLISH , payload: err });
const campaignFiltersList = (filters) => ({ type: CAMPAIGN_FILTER , payload: filters });
export const campaignFiltersSelected = (filters) => ({ type: CAMPAIGN_FILTERS_SELECTED , payload: filters });




export const resetQuickCreateFormSubmissionFlag = () => {
    return (dispatch) => {
        dispatch(quickCreateCampaignEvent(false));
    }
}

//Campaign Actions
export const fetchCampaign = (id) => {
    return (dispatch) => {
        return api.getCampaign(id)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.campaign;
                dispatch(campaignEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};

export const fetchAllCampaigns = (queryStr) => {
    return (dispatch) => {
        return api.getCampaigns(queryStr)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.campaigns;
                dispatch(campaignListEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };

};

export const quickCreateCampaign = (data) => {

    return (dispatch) => {
        return api.quickCreateCampaign(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(quickCreateCampaignEvent(res.campaign))
                dispatch(campaignCreateError(undefined));
            })
            .catch(error => {
                dispatch(campaignCreateError('error'));
                throw (error);
            });
    };
}

export const activateCampaign = (data) => {   
    return (dispatch) => {
        return api.updateCampaignStatus(data)
            .then(response => {
                let res = null;
                if(response && response.data)
                    res = response.data;
                //dispatch(activateCampaignEvent(res))
            })
            .catch(error => {
                throw(error);
            });
    };
}

export const fetchCampaignsTree = () => {
    return (dispatch, getState) => {
        if (getState().slidedrawer.selectedData) {
            const campaignIds = getState().slidedrawer.selectedData.map((e) => e.campaignId);
            return api.getCampTree(campaignIds)
                .then(response => {
                    let data = null;
                    if (response && response.data && response.data.campaigns) {
                        data = response.data.campaigns;
                    }
                    let mappedTree = [];
                    if (data) {
                        data.forEach(c => {
                            let camp = {
                                type: 'campaign',
                                name: c.name,
                                icon: 'fa fa-bullhorn',
                                typeid: c.id,
                                children: []
                            };
                            c.adsets.forEach(a => {
                                let adset = {
                                    type: 'adset',
                                    campaignId: camp.typeid,
                                    name: a.name,
                                    icon: 'fa fa-images',
                                    typeid: a.id,
                                    children: []
                                };
                                a.ads.forEach(d => {
                                    adset.children.push({
                                        type: 'ad',
                                        campaignId: camp.typeid,
                                        adsetId: adset.typeid,
                                        name: d.name,
                                        icon: 'fa fa-image',
                                        typeid: d.id,
                                        children: []
                                    })
                                });
                                camp.children.push(adset);
                            });
                            mappedTree.push(camp)
                        });
                    }
                    dispatch(campaignsTreeEvent(mappedTree));
                })
                .catch(error => {
                    throw (error);
                });
        }
    };
};

export const updateCampaign = (campaign) => {
    return (dispatch) => {
        return api.updateCampaign(campaign)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.campaign;
                dispatch(campaignUpdateEvent(res))
                dispatch(campaignUpdateError(undefined))
            })
            .catch(error => {
                if(error && error.response && error.response.data) dispatch(campaignUpdateError(error.response.data));
                throw (error);
            });
    };
};

export const publishCampaign = (campaign) => {

    function printError(key, msg = 'Error') {
        switch(key){
            default:
                return {[key]: msg};
        }
    }

    return (dispatch) => {
        return api.publishCampaign(campaign)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.campaigns)
                    res = response.data.campaigns[0];
                dispatch(campaignPublishEvent(res))
                dispatch(campaignPublishError(undefined))
            })
            .catch(error => {
                if(error && error.response && error.response.data) {
                    const errors = error.response.data.description;
                    dispatch(campaignPublishError(errors));
                    let errorMsgs = { _error: "Publish Campaign Failed"};
                    for (let err in errors){
                        let errMsg = printError(err, errors[err]);
                        errorMsgs = {...errMsg, ...errorMsgs};
                    }
                    throw new SubmissionError(errorMsgs);
                }
                throw (error);
            });
    };
};

export const deleteCampaign = (campaign) => {
    return (dispatch) => {
        return api.deleteCampaign(campaign)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.campaign;
                dispatch(campaignDeleteEvent(res));
                dispatch(campaignDeleteError(undefined))
            })
            .catch(error => {
                dispatch(campaignDeleteError('error'))
                throw (error);
            });
    };
};

export const duplicateCampaign = (campaign) => {
    return (dispatch) => {
        return api.duplicateCampaign(campaign)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data.campaign;
                dispatch(campaignDuplicateEvent(res));
                dispatch(campaignDuplicateError(undefined));
            })
            .catch(error => {
                dispatch(campaignDuplicateError('error'));
                throw (error);
            });
    };
};

export const fetchToPublish = () => {
    return (dispatch) => {
        return api.fetchAllToPublish()
            .then(response => {
                let res = null;
                if (response && response.data && response.data)
                    res = response.data;
                dispatch(fetchToPublishEvent(res));
                dispatch(fetchToPublishErr(undefined));
            })
            .catch(error => {
                dispatch(fetchToPublishErr('error'));
                throw (error);
            });
    };
}

export const publishAll = (data) => {
    return (dispatch) => {
        return api.publishAll(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data)
                    res = response.data;
                dispatch(publishAllEvent(res));
                dispatch(publishAllEventErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(publishAllEventErr(error.response.data.message));
                throw (error);
            });
    };
}

export const fetchCampaignFilters = () => {
    return (dispatch) => {
        return api.getCampaignFiltersList()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(campaignFiltersList(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}

export const addCampaignFilter = (addFilter) => {
    return (dispatch, getState) => {
        if(addFilter){
            let pageFilters;
            if(getState().campaigns && getState().campaigns.pageFilters && getState().campaigns.pageFilters.length){
                const doesItExistBefore = _.findIndex(getState().campaigns.pageFilters, (item) => {
                    return  (
                        item.value === addFilter.value &&
                        item.type === addFilter.type &&
                        _.isEqual(item.typeValue, addFilter.typeValue)
                    )
                });
                if(doesItExistBefore < 0){
                    // added a new filter
                    pageFilters = [...getState().campaigns.pageFilters, addFilter];
                }else{
                    // there is a duplicate so nothing to take
                    pageFilters = [...getState().campaigns.pageFilters];
                }
            }else{
             // empty
                pageFilters = [addFilter];
            }
            dispatch(campaignFiltersSelected(pageFilters));
        }
    };
}

export const removeCampaignFilter = (removeFilter) => {
    return (dispatch, getState) => {
        if(removeFilter){
            let pageFilters = [];
            if(getState().campaigns && getState().campaigns.pageFilters && getState().campaigns.pageFilters.length){
                // filter out the one needs to be removed
                pageFilters = getState().campaigns.pageFilters.filter((item)=>{
                    return ! (
                        item.value === removeFilter.value &&
                        item.type === removeFilter.type &&
                        _.isEqual(item.typeValue, removeFilter.typeValue)
                    )
                })
            }
            dispatch(campaignFiltersSelected(pageFilters));
        }
    };
}