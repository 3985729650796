import {
    // ONBOARDING_ADD_INFO,
    ONBOARDING_ADD_INFO_ERR,
    ONBOARDING_VALIDATE_METADATA,
    ONBOARDING_VALIDATE_METADATA_ERR,
    ONBOARDING_ADD_USERS_ERR,
    ONBOARDING_LOAD_DETAILS,
    ONBOARDING_SEND_EMAIL_ERR
} from "../constants/appConstant";
import api from '../api/onboard';

/** Add Info **/
// const addInfoSaveEvent = (data) => ({ type: ONBOARDING_ADD_INFO, payload: data });
export const addInfoSaveErr = (data) => ({ type: ONBOARDING_ADD_INFO_ERR, payload: data });

export const addInfoSave = (data) => {
    return (dispatch) => {
        return api.addInfoSave(data)
            .then(response => {
                // let res = null;
                // if (response && response.data)
                //     res = response.data;
                // dispatch(addInfoSaveEvent(res))
                dispatch(addInfoSaveErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(addInfoSaveErr('Error'));
                throw (error);
            });
    };
}

/** Validate Metadata **/
export const validateMetadataEvent = (data) => ({ type: ONBOARDING_VALIDATE_METADATA, payload: data });
export const validateMetadataErr = (data) => ({ type: ONBOARDING_VALIDATE_METADATA_ERR, payload: data });
export const validateMetadata = (data) => {
    return (dispatch) => {
        return api.validateMetadata(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(validateMetadataEvent(res))
                dispatch(validateMetadataErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(validateMetadataErr('Error'));
                throw (error);
            });
    };
}

/** Add Users **/
// const addUsersSaveEvent = (data) => ({ type: ONBOARDING_ADD_USERS, payload: data });
export const addUsersSaveErr = (data) => ({ type: ONBOARDING_ADD_USERS_ERR, payload: data });
export const addUsersSave = (data) => {
    return (dispatch) => {
        return api.addUsers(data)
            .then(response => {
                // let res = null;
                // if (response && response.data && response.data.data)
                //     res = response.data;
                // dispatch(addUsersSaveEvent(res))
                dispatch(addUsersSaveErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(addUsersSaveErr('Error'));
                throw (error);
            });
    };
}

/** Finish step**/
const loadOnboardingEvent = (data) => ({type: ONBOARDING_LOAD_DETAILS, payload: data});
export const emailOnboardingErr = (data) => ({type: ONBOARDING_SEND_EMAIL_ERR, payload: data});

export const emailOnboarding = (data) => {
    return (dispatch) => {
        return api.sendOnboardingEmail(data)
            .then(response => {
                dispatch(emailOnboardingErr(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(emailOnboardingErr('Error'));
                throw (error);
            });
    };
}

export const getOnboardingDetails = (data) => {
    return (dispatch) => {
        return api.getOnboardingScript(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(loadOnboardingEvent(res))
            })
            .catch(error => {
                dispatch(loadOnboardingEvent(null))
                throw (error);
            });
    };
};