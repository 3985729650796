import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Card,
    CardBody,
    Row,
    Progress,
    CardTitle
} from "reactstrap";
import ReactTable from "react-table";
import postsApi from '../../../api/posts';
import Tooltip from "../../../components/Tooltips/Tooltip";
import mainHelper from "../../../helpers/main";
import { postsActions } from "../../../actions";
import { connect } from "react-redux";
import CxLoader from "../../components/cxLoader";
import _ from "lodash";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
    return <img className="cx-no-data-svg" style={{width:'300px'}} src={noData}/>
};

let helpers = new mainHelper();

class TopPostsPageviews extends React.Component {
    constructor(props) {
        super(props);
        this.postsApi = new postsApi();
        this.state = {
            data: [],
            pageSize: 5,
            pages: null,
            loading: true,
            sorted: [{ id: 'cx_pageviews', desc: true }]
        };
        this.pagingData = this.pagingData.bind(this);

        this.cols = [
            {
                Header: "Post Title",
                accessor: "title",
                Cell: ({ original }) => {
                    return this.printArticle(original);
                }
            },
            {
                Header: (props) => <Tooltip text={"The total Pageviews achieved"} placement={"bottom"}><span>Pageviews</span></Tooltip>,
                accessor: "pageviews",
                Cell: ({ original }) => {
                    return <span className="cx-number">{original.pageviews.toLocaleString()}</span>
                }, width: 110
            },
            {
                Header: (props) => <Tooltip text={"The Pageviews delivered through CX"} placement={"bottom"}><span>CX Pageviews</span></Tooltip>, // Custom header
                accessor: "cx_pageviews",
                Cell: ({ original }) => {
                    return this.printProgress(original.cx_pageviews, original.pageviews, helpers.formatDecimal(original.cx_impact), original.title);
                },
                width: 160
            }
        ];
    }

    truncate = (text, nb) => {
        // return text;
        if (text.length > (nb * 1)) return text.substring(0, (nb * 1)) + '...';
        return text;
    }

    printProgress = (numerator, denominator, percent, title) => {
        return <div className="progress-container" title={title}>
            <div>
                <p style={{ "fontSize": "105%" }}>{numerator.toLocaleString()} <span style={{ "color": "rgb(142, 142, 142)" }}>out of {denominator.toLocaleString()}</span></p>
            </div>
            <Progress max="100" value={percent}> <span className="progress-value">{percent}%</span> </Progress>
        </div>;
    }

    printArticle = (o) => {
        function setDate(date) {
            if (!date) return '';
            let d = new Date(date);
            return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
        }
        return <div className={"d-flex align-items-center"}>
            <div className={'img-container mr-3'}>
                <img src={o.thumb + '=w50-h40-c'} width={50} alt="" />
            </div>
            <div style={{ 'fontFamily': 'Tahoma' }}>
                <a target="_blank" title={ReactHtmlParser(o.title)} className={'d-block'} href={(o.url) ? o.url : '#'} style={{ fontSize: '105%' }}>
                    {ReactHtmlParser(this.truncate(o.title, 30))}
                </a>
                {/* <Link style={{ fontSize: '105%' }} target={(o.url)? "_blank" : ''} title={o.title} className={'d-block'} to={(o.url)? o.url : '#'}>{this.truncate(o.title, 30)}</Link> */}
                <div className={"d-flex mt-1"}>
                    <span className={"mx-1"}>{setDate(o.datePublished)}</span>
                    <span className={"mx-1"}>{o.author}</span>
                    {/* <span className={"mx-1"}>{o.section}</span> */}
                </div>
            </div>
        </div>;
    }
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if ((!_.isEqual(this.props.pageFilters, prevProps.pageFilters) ||
            this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter))) {
            this.fetchData();
        }
    }
    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.getData(this.state.sorted, 5, 1);
            this.setState({ loader: false });
        }
    }


    getData = async (sort, size, page) => {
        if (this.props.dateFilter) {
            var firstSort = "desc";
            let finalOrder = "cx_pageviews";

            if (sort && sort.length) {
                firstSort = sort[0].desc ? "desc" : "asc";
                finalOrder = sort[0].id;
            }

            await this.props.fetchData({ orderBy: finalOrder, sort: firstSort, size: size, page: page },
                this.props.pageFilters, this.props.dateFilter);

            if (this.props.topPosts && this.props.topPosts.length > 0) {
                const pages = (this.props.topPosts) ? Math.ceil(this.props.topPosts[0].row_count / size) : 0;
                this.setState({ pages: pages });
            }
            this.setState({ loader: false });
        }
    }

    pagingData(state, instance) {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        this.setState({ loader: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        this.getData(state.sorted, state.pageSize, state.page ? state.page + 1 : 1);
    }

    render() {
        const { pages } = this.state;
        return (
            <Card id="postsTable">
                <CardBody style={{ "minHeight": "380px" }}>
                    <Row className={"cx-card-title justify-content-between mx-0"}>
                        <CardTitle>Recommended Articles by CX</CardTitle>
                        <Tooltip text={"This card displays the articles recommended by CogantiveX with their corresponding Pageviews and the contribution of CX to the total of pageviews."}></Tooltip>
                    </Row>
                    {this.state.loader ? <CxLoader /> : ''}
                    <ReactTable
                        NoDataComponent={NoData}
                        defaultSorting={this.state.sorted}
                        showPageSizeOptions={false}
                        manual={true}
                        minRows={0}
                        data={this.props.topPosts}
                        resizable={false}
                        columns={this.cols}
                        onFetchData={this.pagingData} // Request new data when things change
                        defaultPageSize={5}
                        showPaginationBottom={true}
                        className="-striped -highlight"
                        // loading={loading}
                        pages={pages}
                    />

                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.postsInsight && state.postsInsight.pageFilters || null,
        topPosts: (state.postsInsight && state.postsInsight.topPosts) || [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (params, filter, dateFilter) => dispatch(postsActions.fetchTopPosts(params, filter, dateFilter)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(TopPostsPageviews);
