import React from 'react';
import Filters from "./Filters";
import Table from "./Table";

const KeywordsPool = ({control, register, getValues, setValue}) => {
    return (
        <div className="pool-container">
            <Filters {...{
                control, register, getValues, setValue
            }}/>
            <Table control={control}/>
        </div>
    );
}

export default KeywordsPool;
