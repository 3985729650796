import auth from './auth';
import apiUrl from '../helpers/global';
import mainHelper from '../helpers/main';
import axios from "axios";
let helper = new mainHelper();

/* Helper Class
* To request campaign details and save other campaign details.
* */
class Onboard {

    
    

    constructor(props) {
        this.auth = new auth();
    }

    addInfoSave = async (data) => {
        return this.auth.request.post(apiUrl + '/onboarding-add-info', { data: JSON.stringify(data) });
    }

    validateMetadata = async (data) => {
        return await axios.get(this.baseUrl + '/onboarding-metadata-validate');
    }

    addUsers = async (data) => {
        return await axios.get(this.baseUrl + '/onboarding-add-users');
    }

    getOnboardingScript = async (data) => {
        return await axios.get(this.baseUrl + '/onboarding-load-integration-script');
    }

    sendOnboardingEmail = async (data) => {
        return await axios.get(this.baseUrl + '/onboarding-email-integration-script');
    }

    // getCampaign = async (id) => {
    //     return this.auth.request.post(apiUrl + '/campaign-read', { data: JSON.stringify({ id }) });
    // }

    // campaignReportTotals = async  (data) => {
    //     return this.auth.request.post(apiUrl + '/campaign-performance-totals', {
    //         data: JSON.stringify({
    //             id: data.id,
    //             fromTimestamp: data.fromTimestamp,
    //             toTimestamp: data.toTimestamp
    //         })
    //     });
    // }
}

export default new Onboard();
