import React, {Component} from "react";
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import {Col, Row} from "reactstrap";
import TableTabs from "./adReview/tableTabs";
import AdsPendingReview from "./adReview/adsPendingReview";
import AdsAccepted from "./adReview/adsAcceptedTable";
import AdsRejected from "./adReview/adsRejectedTable";
import ActionBtns from "./adReview/actionBtns";
import DateFilter from "../components/dateFilter";
import _ from "lodash";
import {mediaPlannerActions} from "../../actions";
class AdsetReview extends Component {
    componentDidUpdate(prevProps) {
        // accept
        if (this.props.ReviewAdsAcceptErr && !_.isEqual(this.props.ReviewAdsAcceptErr, prevProps.ReviewAdsAcceptErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.acceptErrAdEvent();
        }
        if (this.props.successAdAccepted && !_.isEqual(this.props.successAdAccepted, prevProps.successAdAccepted)) {
            this.alertMsg('Ad Accepted Successfully', 'fa fa-check', 'success');
            this.props.removeAcceptAdReviewEvent();
        }
        // reject
        if (this.props.ReviewAdsRejectErr && !_.isEqual(this.props.ReviewAdsRejectErr, prevProps.ReviewAdsRejectErr)) {
            this.alertMsg('An error occured, try again later');
            this.props.rejectErrAdEvent();
        }
        if (this.props.successAdRejected && !_.isEqual(this.props.successAdRejected, prevProps.successAdRejected)) {
            this.alertMsg('Ad Rejected Successfully', 'fa fa-check', 'success');
            this.props.removeRejectAdReviewEvent();
        }
    }
    renderPageFilters = () => {
        return (
            <div className={"cx-page-filters"}>
                <DateFilter />
            </div>
        );
    }
    alertMsg = (msg, icon ='tim-icons icon-simple-remove', type= 'danger') => {
        this.refs.notificationAlert.notificationAlert( {
            place: 'tc',
            message: msg,
            type: type,
            icon: icon,
            autoDismiss: 7
        });
    }
    render() {
        return (
            <>
                <div className={"cx-notification-alert"}><NotificationAlert ref="notificationAlert" /></div>
                <div className="content">
                    <div>
                        <h4 className={"mb-4"}><i className="fa fa-wrench"></i>&nbsp; Media Planner &gt; Review Ads</h4>
                    </div>
                    {/*{this.renderPageFilters()}*/}
                    <div className={"page-reactive-table"}>
                        <Row className={"mx-0"}>
                            <Col className={"px-0"}>
                                <TableTabs />
                            </Col>
                        </Row>
                        <Row className={"cx-action-buttons mx-0"}>
                            <Col className={"px-0"}>
                                <ActionBtns/>
                            </Col>
                        </Row>
                        <Row className={"mx-0"}>
                            <Col className={"px-0 cx-admediaplanner-review"}>
                                <div id={'cx-adspending-review'} className={`${(this.props.activeTabList === 'pending') ? '' : 'd-none'}`}>
                                    <AdsPendingReview />
                                </div>
                                <div id={'cx-adsaccepted'} className={`${(this.props.activeTabList === 'accepted') ? '' : 'd-none'}`}>
                                    <AdsAccepted />
                                </div>
                                <div id={'cx-adsrejected'} className={`${(this.props.activeTabList === 'rejected') ? '' : 'd-none'}`}>
                                    <AdsRejected />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        activeTabList: state.tabTable && state.tabTable.activeTab,
        successAdAccepted: (state.mediaPlanner && state.mediaPlanner.successAdAccepted) || null,
        ReviewAdsAcceptErr: (state.mediaPlanner && state.mediaPlanner.ReviewAdsAcceptErr) || null,
        successAdRejected: (state.mediaPlanner && state.mediaPlanner.successAdRejected) || null,
        ReviewAdsRejectErr: (state.mediaPlanner && state.mediaPlanner.ReviewAdsRejectErr) || null,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeAcceptAdReviewEvent: () => dispatch(mediaPlannerActions.acceptAdReviewEvent(undefined)),
        acceptErrAdEvent: () => dispatch(mediaPlannerActions.acceptErrAdEvent(undefined)),
        removeRejectAdReviewEvent: () => dispatch(mediaPlannerActions.rejectAdReviewEvent(undefined)),
        rejectErrAdEvent: () => dispatch(mediaPlannerActions.rejectErrAdEvent(undefined)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdsetReview);