import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    mediaPlannerActions,
} from "../../../actions";
import Confirm from "../../components/confirmationBox";
import mainHelper from "../../../helpers/main";
const helper = new mainHelper();
class ActionBtns extends Component {
    constructor(props) {
        super(props);
        this.notify = {
            accepted: {
                title: 'Are you sure you want to accept the selected?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x*1;
                    });
                    await this.props.acceptAds({ ids: data });
                    this.refreshData();
                    },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Accept',
                cancelBtnTxt: 'Cancel'
            },
            rejected: {
                title: 'Are you sure you want to reject the selected?',
                confirm: async (data) => {
                    this.refs.confirmRef.removeConfirmation();
                    data = data.map(function (x) {
                        return x*1;
                    });
                    await this.props.rejectAds({ ids: data });
                    this.refreshData();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: `Reject`,
                cancelBtnTxt: 'Cancel'
            }
        }
    }
    refreshData = () => {
        this.props.fetchList('pending');
        this.props.fetchList('accepted');
        this.props.fetchList('rejected');
    }
    preventdefault = (event) => {
        if (event) event.preventDefault();
    }
    confirmOptionActions = (action) => {
        if(this.refs.confirmRef) {
            const selections = this.getSelections();
            return this.refs.confirmRef.confirmOptionActions({ type: action, data: selections.ids });
        }
        return false;
    }
    getSelections = () => {
        switch (this.props.activeTabList) {
            case 'pending':
                if (this.props.pendingSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.pendingSelections
                    };
                }
                break;
            case 'accepted':
                if (this.props.acceptedSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.acceptedSelections
                    };
                }
                break;
            case 'rejected':
                if (this.props.rejectedSelections.length) {
                    return {
                        type: this.props.activeTabList,
                        ids: this.props.rejectedSelections
                    };
                }
                break;
        }
        return false;
    }
    render() {
        const disabled = !this.getSelections();
        return (
            <div className={"header-btn-container"}>
                {(this.props.activeTabList != 'accepted')?
                    <button
                        className="btn cx-focus-btn"
                        onClick={(e) => { this.preventdefault(e); this.confirmOptionActions('accepted') }}
                        disabled={disabled}
                    >
                        Accept
                    </button>
                :''}
                {(this.props.activeTabList != 'rejected')?
                    <button
                        className="btn"
                        onClick={(e) => { this.preventdefault(e); this.confirmOptionActions('rejected') }}
                        disabled={disabled}
                    >
                        Reject
                    </button>
                :''}
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        pendingSelections: (state.tabTable && state.tabTable.tableSelections && state.tabTable.tableSelections.pending)? helper.getSelectedIds(state.tabTable.tableSelections.pending) : [],
        acceptedSelections: (state.tabTable && state.tabTable.tableSelections && state.tabTable.tableSelections.accepted)? helper.getSelectedIds(state.tabTable.tableSelections.accepted) : [],
        rejectedSelections: (state.tabTable && state.tabTable.tableSelections && state.tabTable.tableSelections.rejected)? helper.getSelectedIds(state.tabTable.tableSelections.rejected) : [],
        activeTabList: state.tabTable && state.tabTable.activeTab
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchList: (type) => dispatch(mediaPlannerActions.fetchAdsReviewsList({type})),
        acceptAds: (ids) => dispatch(mediaPlannerActions.acceptAdsReview(ids)),
        rejectAds: (ids) => dispatch(mediaPlannerActions.rejectAdsReview(ids)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionBtns);