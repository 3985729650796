import {
    CLASSIFICATION_ACTIVE_TAB,
    CLASSIFICATION_TABS,
    CLASSIFICATION_SUMMARY,
    CLASSIFICATION_PERFORMANCE,
    CLASSIFICATION_PUBLISHED_ARTICLES,
    CLASSIFICATION_TABLE,
    CLASSIFICATION_PERFORMANCE_FILTER,
    CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED
} from "../constants/appConstant";
import api from '../api/classes';
import _ from "lodash";

/* For the active tab and tabs*/
export const activeTab = (data) => ({ type: CLASSIFICATION_ACTIVE_TAB , payload: data});
const tabs = (data) => ({ type: CLASSIFICATION_TABS , payload: data});
export const Fetchtabs = () => {
    return (dispatch) => {
        return api.getClassTypes()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(tabs(res));
                dispatch(activeTab(res && res.length && res[0].key));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the Summary */
const summary = (data) => ({ type: CLASSIFICATION_SUMMARY , payload: data});
export const fetchSummary = (params,filter,datefilter) => {
    return (dispatch) => {
        return api.classesSummary(params,filter,datefilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(summary(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the Performance Graph */
const performance = (data) => ({ type: CLASSIFICATION_PERFORMANCE , payload: data});
export const fetchPerformance = (params,filter, dateFilter) => {
    return (dispatch) => {
        return api.pageviewsBubbleGraph(params,filter, dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(performance(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the table */
const table = (data) => ({ type: CLASSIFICATION_TABLE , payload: data});
export const fetchTable = (params,filter,datefilter) => {
    return (dispatch) => {
        return api.classesByCXImpact(params,filter,datefilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(table(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the published articles graph */
const publishedArticles = (data) => ({ type: CLASSIFICATION_PUBLISHED_ARTICLES , payload: data});
export const fetchPublishedArticles = (params,filter,datefilter) => {
    return (dispatch) => {
        return api.classesTaxonomy(params,filter,datefilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(publishedArticles(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

const classFiltersList = (filters) => ({ type: CLASSIFICATION_PERFORMANCE_FILTER, payload: filters });
export const classFiltersSelected = (filters) => ({ type: CLASSIFICATION_PERFORMANCE_FILTERS_SELECTED, payload: filters });
export const fetchClassesFilters = (classmapping) => {
    return (dispatch) => {
        let filterClass = {
            options: [],
            value: "tag",
            label: "Class",
            type: "select",
            operator: [{
                value: "is",
                label: "is",
                symbol: ":"
            }],
        };
       if(classmapping){
        return api.getAllClasses(classmapping)
            .then(response => {
                if (response && response.data && response.data.data)
                filterClass.options = response.data.data.map(x => ({ label: x.class, value: x.class }));              
                dispatch(classFiltersList([filterClass]));
            })
            .catch(error => {
                throw (error);
            });
        }else{
            dispatch(classFiltersList([]));
        }
    };
}
export const addClassesFilter = (addFilter) => {
    return (dispatch, getState) => {
        if (addFilter) {
            let pageFilters;
            if (getState().classificationInsight && getState().classificationInsight.pageFilters
             && getState().classificationInsight.pageFilters.length) {
                const doesItExistBefore = _.findIndex(getState().classificationInsight.pageFilters, (item) => {
                    return (
                        item.value === addFilter.value &&
                        item.type === addFilter.type &&
                        _.isEqual(item.typeValue, addFilter.typeValue)
                    )
                });
                if (doesItExistBefore < 0) {
                    // added a new filter
                    pageFilters = [...getState().classificationInsight.pageFilters, addFilter];
                } else {
                    // there is a duplicate so nothing to take
                    pageFilters = [...getState().classificationInsight.pageFilters];
                }
            } else {
                // empty
                pageFilters = [addFilter];
            }
            dispatch(classFiltersSelected(pageFilters));
        }
    };
}
export const removeClassesFilter = (removeFilter) => {
    return (dispatch, getState) => {
        if (removeFilter) {
            let pageFilters = [];
            if (getState().class && getState().classificationInsight.pageFilters 
            && getState().classificationInsight.pageFilters.length) {
                // filter out the one needs to be removed
                pageFilters = getState().classificationInsight.pageFilters.filter((item) => {
                    return !(
                        item.value === removeFilter.value &&
                        item.type === removeFilter.type &&
                        _.isEqual(item.typeValue, removeFilter.typeValue)
                    )
                })
            }
            dispatch(classFiltersSelected(pageFilters));
        }
    };
}
