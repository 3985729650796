import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import { FormGroup, Input as InputStrap, Label } from "reactstrap";
class AdvancedFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            selected: props.selected,
            create: null
        };
        this.textInput = React.createRef();
        this.selectInput = React.createRef();
    }

    componentDidUpdate(prevProps) {
        // get the list and selected items and update when has changes
        if (!_.isEqual(this.props.list, this.state.list)) {
            this.setState({
                list: this.props.list
            });
        }
        if (!_.isEqual(this.props.selected, this.state.selected)) {
            this.setState({
                selected: this.props.selected
            });
        }
    }

    // @Helper renders the button filter with its filter values and options --{filter btn component}
    renderFilterBtn = () => {
        return <Dropdown>
            <Dropdown.Toggle id="dropdown-filter">{this.props.title || <i className={"cx-blue-color fa fa-plus"} />}</Dropdown.Toggle>
            <Dropdown.Menu>
                {this.state.list.map(item =>
                    <Dropdown.Item key={'filter-' + item.value} onClick={() => this.setOperatorSelected(_.cloneDeep(item))}>
                        {item.label}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>;
    }

    // @Helper for renderCreateFilter: change operator selection --{Create filter component}
    setOperatorSelected = (item, selectOperator = false) => {
        if (item.operator && item.operator.length) item.operator = item.operator.map((operator, ind) => {
            if (!selectOperator) {
                if (ind === 0)
                    operator.selected = true;
                else
                    operator.selected = false;
                return operator;
            } else {
                if (selectOperator.value === operator.value)
                    operator.selected = true;
                else
                    operator.selected = false;
                return operator;
            }

        });
        this.setState({ create: item });
    }

    // @Helper when add is clicked
    onFilterAdd = () => {
        this.props.onAdd(this.state.create);
        this.resetCreate();
    }

    // @Helper for input text focus
    _inputFocus = (input) => {
        setTimeout(() => {
            if (input && input.current) input.current.focus();
        }, 10);
    }

    // @Helper for input text enter press to trigger the add event
    _onKeyPressInput = (e, item) => {
        if (e.key === 'Enter' && item.typeValue) {
            this.onFilterAdd();
        }
    }

    // @Helper for renderCreateFilter: print text input or a selection field --{Create filter component}
    renderCreateFilterValue = (create) => {
        switch (create.type) {
            default:
            case 'text':
                this._inputFocus(this.textInput);
                return (
                    <div className="cx-advanced-filter-text-container">
                        <input
                            ref={this.textInput}
                            type={"text"}
                            className={"cx-advanced-filter-text-input"}
                            onChange={() => {
                                create.typeValue = this.textInput.current.value;
                               
                                this.setState({ create: create });
                            }}
                            onKeyPress={(e) => { this._onKeyPressInput(e, create) }}
                        />
                        <span
                            className={`${(create.typeValue) ? '' : 'd-none'} cx-advanced-filter-text-add cursor-pointer`}
                            onClick={() => this.onFilterAdd()}
                        >
                            Add
                        </span>
                    </div>
                );
            case 'select':
                this._inputFocus(this.selectInput);
                return (
                    <div className="cx-advanced-filter-text-container">
                        <select
                            ref={this.selectInput}
                            onChange={(event) => {
                                //console.log(event.nativeEvent.target)
                                var index = event.nativeEvent.target.selectedIndex;                                

                                create.typeValue = this.selectInput.current.value;
                                create.typeLabel = event.nativeEvent.target[index].text;
                                this.setState({ create: create });
                            }}
                        >
                            <option>
                                Choose
                                </option>
                            {create.options && create.options.length > 0 && create.options.map((op) => {
                                return (
                                    <option value={op.value}>
                                        {op.label}
                                    </option>
                                )
                            })}
                        </select>
                        <span
                            className={`${(create.typeValue) ? '' : 'd-none'} cx-advanced-filter-text-add cursor-pointer`}
                            onClick={() => this.onFilterAdd()}
                        >
                            Add
                            </span>
                    </div>
                );
            case 'checkboxes':
                // options: [{value: "active", label: "Active"}, ...]
                const getTitle = (create.typeValue && create.typeValue.length && create.typeValue.map((choose) => {
                    return choose.label
                }).join(", ")) || "Choose..";
                return (
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-filter-operator" title={getTitle}>{getTitle}</Dropdown.Toggle>
                        <span
                            className={`${(create.typeValue && create.typeValue.length) ? '' : 'd-none'} cx-advanced-filter-checkboxes-add cursor-pointer`}
                            onClick={() => this.onFilterAdd()}
                        >
                            Add
                        </span>
                        <Dropdown.Menu>
                            {create.options.map((op) => {
                                return (
                                    <Dropdown.Item key={'check-' + op.value}
                                        onClick={() => {
                                            if (!create.typeValue) {
                                                create.typeValue = [op];
                                            } else {
                                                // check if exists before
                                                let exist = !!_.filter(create.typeValue, function (chosen) {
                                                    return (chosen.value === op.value)
                                                }).length;
                                                if (exist) {
                                                    // remove
                                                    create.typeValue = _.filter(create.typeValue, function (chosen) {
                                                        return (chosen.value !== op.value)
                                                    })
                                                } else {
                                                    //add
                                                    create.typeValue.push(op)
                                                }
                                            }
                                            this.setState({ create: create });
                                        }}
                                    >
                                        <FormGroup check inline>
                                            <Label check className={"cx-checkbox-label"}>
                                                <InputStrap
                                                    type="checkbox"
                                                    className={`cx-checkbox-input`}
                                                    value={op.value}
                                                    checked={!!_.filter(this.state.create.typeValue, function (chosen) {
                                                        return (chosen.value === op.value)
                                                    }).length}
                                                />
                                                <span
                                                    className="form-check-sign"
                                                />
                                                <span className={"cx-checkbox-label-value"}>{op.label}</span>
                                            </Label>
                                        </FormGroup>
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                );
        }
    }

    // @Helper for renderSelectedFilters: print values for the text input or a selection field --{filter component}
    displayValues = (item) => {
        switch (item.type) {
            default:
            case 'select':
                return item.typeLabel;
            case 'text':
                return item.typeValue;
            case 'checkboxes':
                return item.typeValue.map((choice) => {
                    return choice.label
                }).join(", ");
        }
    }

    // @Helper to empty the create obj
    resetCreate = () => {
        this.setState({ create: null });
    };

    // @Helper renders the --{Create filter component} after choosing a filter
    renderCreateFilter = (create) => {
        return <div className={"cx-advanced-filter-choose"}>
            <div className={"cx-advanced-filter-title"}>
                {create.label}
            </div>
            <div className={"cx-advanced-filter-operator"}>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-filter-create">{create.operator.filter((op) => op.selected)[0].label}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {create.operator.map(op =>
                            <Dropdown.Item key={'operator-' + op.value} onClick={() => this.setOperatorSelected(create, op)}>
                                {(op.selected) ?
                                    <strong>{op.label}</strong> :
                                    op.label
                                }
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className={"cx-advanced-filter-value"}>
                {this.renderCreateFilterValue(create)}
                <a className={"cx-advanced-filter-cancel cursor-pointer"} onClick={(e) => {
                    e.preventDefault();
                    this.resetCreate();
                }}>
                    <i className={"fa fa-times"}></i>
                </a>
            </div>
        </div>;
    }

    // @Helper render all the added filters --{selected filter component}
    renderSelectedFilters = () => {
        // return  <div className={"cx-advanced-filter-selected-list"}>
        return <>
            {
                this.state.selected.map((item) => {
                    return (
                        <div
                            className={"cx-advanced-filter-selected-item-container"}
                            key={"cx-advanced-filter-selected-item-" + item.value + "-" + item.type}
                        >
                            <div className={"cx-advanced-filter-selected-item"}>
                                <span className={"cx-advanced-filter-selected-item-title"}>{item.label}</span>
                                <span className={"cx-advanced-filter-selected-item-operator"}>{item.operator.filter((op) => op.selected)[0].symbol}</span>
                                <span className={"cx-advanced-filter-selected-item-value"}>{this.displayValues(item)}</span>
                                <span className={"cursor-pointer cx-advanced-filter-selected-item-delete"} onClick={(e) => { this.props.onRemove(item); }}>
                                    <i className={"fa fa-times"} />
                                </span>
                            </div>
                        </div>
                    );
                })
            }
        </>
    }

    render() {
        return (
            <div className="cx-advanced-filter">
                <div className={"cx-advanced-filter-title"}>Filter</div>
                {!this.state.selected.length || this.renderSelectedFilters()}
                {!this.state.create || this.renderCreateFilter(this.state.create)}
                {this.renderFilterBtn()}
            </div>
        );
    }
}

// export and connect data to store
export default AdvancedFilter;