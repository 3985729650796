import React from 'react';
import ContentAnalysisTotals from './components/ContentAnalysisTotals';
const ContentAnalysis = () => {
    return (
        <div className="content">
            <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Content Analysis</h4>
            <ContentAnalysisTotals/>
        </div>
    )
}
export default ContentAnalysis;