// campaignSelections campaignSelectionActions
import {
    SET_REPORT_TAB,
    SET_REPORT_SUBTAB,
    FETCH_REPORT_LOCATION,
    FETCH_REPORT_DEVICES,
    FETCH_REPORT_PERFORMANCE,
    FETCH_REPORT_PERFORMANCE_TOTALS
} from "../constants/appConstant";
import campaignApi from '../api/campaigns';
import adsetApi from '../api/adsets';
import adApi from '../api/ads';

const campaignA = new campaignApi();
const adsetA = new adsetApi();
const adA = new adApi();

/*
* Action Creators
* */

const setFetchedLocation = (data) => ({type: FETCH_REPORT_LOCATION, payload: data});
const setFetchedDevices = (data) => ({type: FETCH_REPORT_DEVICES, payload: data});

const setFetchedPerformance = (data) => ({type: FETCH_REPORT_PERFORMANCE, payload: data});
const setFetchedPerformanceTotals = (data) => ({type: FETCH_REPORT_PERFORMANCE_TOTALS, payload: data});

export const fetchTypeTotals = () => {
    return (dispatch, getState) => {
        if(getState().slidedrawer && getState().slidedrawer.selectedType){
            let today = new Date();
            let sendData = {
                fromTimestamp: '2020/01/01',
                toTimestamp: today.getFullYear() + '/' +  (today.getMonth() + 1) + '/' +  today.getDate(),
                id: getState().slidedrawer.selectedType.typeid
            };
            switch(getState().slidedrawer.selectedType.type){
                case 'campaign':
                    return campaignA.campaignReportTotals(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformanceTotals(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'adset':
                    return adsetA.adsetReportTotals(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformanceTotals(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'ad':
                    return adA.adReportTotals(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformanceTotals(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                default: return;
            }
        }
    };
}

export const fetchTypePerformance = () => {
    return (dispatch, getState) => {
        if(getState().slidedrawer && getState().slidedrawer.selectedType){
            let today = new Date();
            let sendData = {
                fromTimestamp: '2020/01/01',
                toTimestamp: today.getFullYear() + '/' +  (today.getMonth() + 1) + '/' +  today.getDate(),
                id: getState().slidedrawer.selectedType.typeid
            };
            switch(getState().slidedrawer.selectedType.type){
                case 'campaign':
                    return campaignA.campaignReportPerformance(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformance(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'adset':
                    return adsetA.adsetReportPerformance(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformance(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'ad':
                    return adA.adReportPerformance(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedPerformance(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                default: return;
            }
        }
    };
}

export const fetchTypeLocation = () => {
    return (dispatch, getState) => {
        if(getState().slidedrawer && getState().slidedrawer.selectedType){
            let today = new Date();
            let sendData = {
                fromTimestamp: '2020/01/01',
                toTimestamp: today.getFullYear() + '/' +  (today.getMonth() + 1) + '/' +  today.getDate(),
                id: getState().slidedrawer.selectedType.typeid
            };
            switch(getState().slidedrawer.selectedType.type){
                case 'campaign':
                    return campaignA.campaignReportLocation(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data && response.data.data){
                                res = response.data.data;
                            }
                            dispatch(setFetchedLocation(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'adset':
                    return adsetA.adsetReportLocation(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedLocation(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'ad':
                    return adA.adReportLocation(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedLocation(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                default: return;
            }
        }
    };
}

export const fetchTypedevices = () => {
    return (dispatch, getState) => {
        if(getState().slidedrawer && getState().slidedrawer.selectedType){
            let today = new Date();
            let sendData = {
                fromTimestamp: '2020/01/01',
                toTimestamp: today.getFullYear() + '/' +  (today.getMonth() + 1) + '/' +  today.getDate(),
                id: getState().slidedrawer.selectedType.typeid
            };
            switch(getState().slidedrawer.selectedType.type){
                case 'campaign':
                    return campaignA.campaignReportDevices(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data && response.data.data){
                                res = response.data.data;
                            }
                            dispatch(setFetchedDevices(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'adset':
                    return adsetA.adsetReportDevices(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedDevices(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                case 'ad':
                    return adA.adReportDevices(sendData)
                        .then(response => {
                            let res = null;
                            if (response && response.data){
                                if(response.data.data) res = response.data.data;
                            }
                            dispatch(setFetchedDevices(res))
                        })
                        .catch(error => {
                            throw (error);
                        });
                default: return;
            }
        }
    };
}
