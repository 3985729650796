import React from "react";
import classNames from "classnames";
import auth from "../../api/auth";
import mainHelper from "../../helpers/main";
import ChangePass from "./changePassword";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { Modal } from "@material-ui/core";

const helpers = new mainHelper();

let organizations = helpers.getOrganizations();

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.state = {
      width: window.innerWidth > 770,
      collapseOpen: true,
      color: "navbar-transparent",
      isPopupOpened: false,
      open: false,
      organizationSearch: '',
      organizationsList: organizations,
    };
    this.auth = new auth();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  updateColor = () => {
    this.setState((prev) => ({ ...prev, width: window.innerWidth > 770 }));
  };
  // this function opens and closes the collapse on small devices

  switchdomain = (organization) => {
    helpers.setOrganization(organization);
    window.location.reload();
  };

  filterOrganizationsList(e){
    // get search value 
    let search = e.target.value.toUpperCase();

    let orgs = helpers.getOrganizations().filter(org => (org.name.toUpperCase().indexOf(search) === 0) && !org.name.includes("almayadeen"))
    if(orgs.length > 0)
      this.setState({organizationSearch: e.target.value, organizationsList: orgs})
  }

  render() {
    return (
      <>
        <ChangePass
          isPopupOpened={this.state.isPopupOpened}
          closePopup={() => {
            this.setState({ isPopupOpened: false });
          }}
        />  

        <Navbar
          className={classNames(this.state.width ? "navbar-absolute" : null, {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1,
          })}
          style={{
            display: "flex",
            padding: this.state.width ? 10 : 0,
          }}
          expand="md"
        >
          <Container fluid style={{ zIndex: 999 }}>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav style={{zIndex: 9999}}>
                  <DropdownToggle
                    onBlur={()=>{this.setState(() => ({ open: false }))}}
                    onClick={() => {
                      this.setState(() => ({open: true}), ()=>this.searchRef.current.focus());
                    }}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <i className="tim-icons icon-world" />
                    &nbsp; {helpers.getOrganizationName()}
                    
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                    <DropdownMenu
                      className="dropdown-navbar"
                      right
                      tag="ul"
                      style={{
                        zIndex: 9999,
                        backgroundColor: "#1e1e2f",
                        maxHeight: "80vh",
                        overflowY: "auto",
                      }}
                    >
                      <DropdownItem text>
                        <div className="searchOrganizationsInput">
                          <i className="fa fa-search"></i>
                          <input
                            ref={this.searchRef}
                            type="text"
                            onChange={(e) => {this.filterOrganizationsList(e)}}
                            value={this.state.organizationSearch}
                            placeholder="  Search.."
                          />
                        </div>
                      </DropdownItem>
                      
                      {this.state.organizationsList.map((organization, i) => {
                        return (
                          <NavLink tag="li" key={organization.id + "-" + i}>
                            <DropdownItem
                              className="nav-item"
                              onClick={() => {
                                this.switchdomain(organization);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {organization.name}
                            </DropdownItem>
                          </NavLink>
                        );
                      })}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    <p title={helpers.getInfo("email")}>
                      Hi, {helpers.getInfo("name")}
                    </p>
                    <b className="caret d-none d-lg-block d-xl-block" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem
                        className="nav-item"
                        onClick={(e) => {
                          this.setState({ isPopupOpened: true });
                        }}
                      >
                        Change password
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem
                        className="nav-item"
                        onClick={(e) => {
                          this.auth.logout("/");
                        }}
                      >
                        Log out
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
