import widgetAPI from 'api/widgetManageApi';
import publisherAPI from 'api/publisherManageApi';
import recommendationAPI from "api/recommendation"


export const fetchList = async () => {
    return widgetAPI.getList()
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data)
                res = response.data.data;
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const readWidget = async (id) => {
    return widgetAPI.getItem({id: id})
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data){
                res = response.data.data;
            }
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const createWidget = async (mapped) => {
    return widgetAPI.create(mapped)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            return (error);
        });
}

export const editWidget = async (data) => {
    return widgetAPI.updateItem(data)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}

export const deleteWidget = async (ids) => {
    return widgetAPI.delete({ids: ids})
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}


// utitlity
export const getPublishers = async () => {
    return publisherAPI.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}

export const getWidgetTypes = () => {
    return [
        {
            label: "Bottom",
            value: "recommendation-bottom",
        },
        {
            label: "Popup",
            value: "recommendation-popup1",
        },
        {
            label: "Sidebar",
            value: "sidebar",
        },
        {
            label: "Email Collection",
            value: "email-collection-popup",
        },
    ]
}


export const getMappingsList = () => {
    return recommendationAPI.getClassList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}