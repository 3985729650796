import { combineReducers } from 'redux';
import campaignReducer from './campaignReducer';
import adsetReducer from './adsetReducer';
import adReducer from './adReducer';
import drawerReducer from './drawerReducer';
import popupReducer from './popupReducer';
import locationReducer from './locationReducer';
import categoryReducer from './categoryReducer';
import campaignSelectionReducer from "./campaignSelectionReducer";
import filterReducer from "./filterReducer";
import tableReducer from "./tableReducer";
import campaignPerformanceReducer from "./campaignPerformanceReducer";
import mapReducer from "./mapReducer";
import loginReducer from "./loginReducer";
import mediaPlannerReducer from "./mediaPlannerReducer";
import recommendationReducer from "./recommendationReducer";
import organizationReducer from "./organizationReducer";
import userpermissionReducer from "./userpermissionReducer";
import publisherReducer from "./publisherReducer";
import roleReducer from "./roleReducer";
import userReducer from "./userReducer";
import auditReducer from "./auditReducer";
import widgetReducer from "./widgetReducer";
import {reducer as formReducer } from 'redux-form';
import advertiserReducer from "./advertiserReducer";
import uploadReducer from "./uploadReducer";
import authReducer from "./authReducer";
import campaignCategoryMappingReducer from "./campaignCategoryMappingReducer";
import widgetPerformanceReducer from "./widgetPerformanceReducer";
import postsReducer from "./postsReducer";
import authorsReducer from "./authorsReducer";
import classificationReducer from "./classificationReducer";
import onboardReducer from "./onboardReducer";

/*
 * Reducers
 */
const rootReducer = combineReducers ({
    ads: adReducer,
    adsets: adsetReducer,
    campaigns: campaignReducer,
    slidedrawer: drawerReducer,
    customPopup: popupReducer,
    location: locationReducer,
    category: categoryReducer,
    filter: filterReducer,
    tabTable: tableReducer,
    campaignSelections: campaignSelectionReducer,
    campaignPerformance: campaignPerformanceReducer,
    map: mapReducer,
    advertiser: advertiserReducer,
    recommendation: recommendationReducer,
    login: loginReducer,
    organization:organizationReducer,
    userpermission: userpermissionReducer,
    role: roleReducer,
    publisher: publisherReducer,
    upload: uploadReducer,
    user: userReducer,
    auth: authReducer,
    mediaPlanner: mediaPlannerReducer,
    form: formReducer,
    widget: widgetReducer,
    audit: auditReducer,
    campaignCategoryMapping: campaignCategoryMappingReducer,
    widgetPerformance: widgetPerformanceReducer,
    postsInsight: postsReducer,
    authorsInsight: authorsReducer,
    classificationInsight: classificationReducer,
    onboard: onboardReducer
});
export default rootReducer;