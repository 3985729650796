import {
    SET_RECOMMENDATION_ACTION_TYPE,
    FETCH_RECOMMENDATION_CONFIGS,
    FETCH_RECOMMENDATION_CONFIG_ITEM,
    UPDATE_RECOMMENDATION_CONFIG,
    UPDATE_ERR_RECOMMENDATION_CONFIG,
    CREATE_RECOMMENDATION_CONFIG,
    CREATE_ERR_RECOMMENDATION_CONFIG,
    DELETE_RECOMMENDATION_CONFIG,
    DELETE_ERR_RECOMMENDATION_CONFIG,
    FETCH_RECOMMENDATION_CLASS_LIST,
    DEFAULT_RECOMMENDATION_FRESHNESS_DAYS,
    CONFIG_RECOMMENDATION_ERROR
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_RECOMMENDATION_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_RECOMMENDATION_CLASS_LIST:
            return {...state, classList: action.payload.list, valueList: action.payload.values};
        case FETCH_RECOMMENDATION_CONFIGS:
            return { ...state, configList: action.payload };
        case FETCH_RECOMMENDATION_CONFIG_ITEM:
            return { ...state, configItem: action.payload };
        case UPDATE_RECOMMENDATION_CONFIG:
            return { ...state, updateConfig: action.payload };
        case UPDATE_ERR_RECOMMENDATION_CONFIG:
            return { ...state, updateConfigErr: action.payload };
        case CREATE_RECOMMENDATION_CONFIG:
            return { ...state, createConfig: action.payload };
        case CREATE_ERR_RECOMMENDATION_CONFIG:
            return { ...state, createConfigErr: action.payload };
        case DELETE_RECOMMENDATION_CONFIG:
            return { ...state, deleteConfig: action.payload };
        case DELETE_ERR_RECOMMENDATION_CONFIG:
            return { ...state, deleteConfigErr: action.payload };
        case DEFAULT_RECOMMENDATION_FRESHNESS_DAYS:
            return { ...state, defaultRecommendationFreshness: action.payload };
        case CONFIG_RECOMMENDATION_ERROR:
            return { ...state, configListErr: action.payload };
        default:
            return state;
    }
}