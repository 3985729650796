import React, { useState, useEffect } from "react";
import CxLoader from "../components/cxLoader";
import { useQuery } from "react-query"
import { CXConfirm, CXDrawer, CXPill } from "../../CXComponents" 
import useContentGroupingStore from "./contentGroupingStore";
import ContentGroupCard from "./components/ContentGroupCard"
import {getContentGList} from "./contentGroupingAPI"
import ContentGroupForm from "./ContentGroupingForm";

function ContentGroupsList() {
  
    const { isLoading, isFetching, data } = useQuery(["content-grouping-list"], ()=>{
        return getContentGList();
    }, { refetchOnWindowFocus: false });

    const isOpen = useContentGroupingStore(state=>state.isOpen);
    const openDrawer = useContentGroupingStore(state=>state.openDrawer);
    const closeDrawer = useContentGroupingStore(state=>state.closeDrawer);
    const setActionType = useContentGroupingStore(state=>state.setActionType);
    const actionType = useContentGroupingStore(state=>state.actionType);
    const isDirty = useContentGroupingStore(state=>state.isDirty);
    const formTitle = actionType === "create" ? "Create Content Group" : "View & Edit Content Group";

    
    return (
        <>
            {
                isOpen && 
                <CXDrawer
                    isOpen={isOpen}
                    closeDrawer={closeDrawer}
                    title={formTitle}
                    width="80vw"
                    isDirty={isDirty}
                >
                    <ContentGroupForm />
                </CXDrawer>
            }
            <div className="content">
                <div>
                    <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Content Grouping</h4>
                </div>
                {
                    (!isLoading && !isFetching) ? 
                    <div className="segmentationList">
                        <div className="segment-card-container add-segment-btn" onClick={()=>{setActionType('create'); openDrawer()}}>
                            <i className="fa fa-plus"></i>
                        </div>
                        {
                            data && 
                            data.map((group, i)=>{
                                return (
                                    <ContentGroupCard
                                        key={i}
                                        id={group.id}
                                        title={group.title}
                                        size={group.content?.length}
                                        coverage={group.coveragePercentage}
                                    />
                                )
                            })
                        }
                    </div>
                    : <CxLoader minHeight="80vh"/>
                }
                
            </div>
        </>
    );
}

export default ContentGroupsList;
