import React, { Component } from "react";
import { connect } from 'react-redux';
import {drawerActions, mediaPlannerActions} from '../../../actions';
import ReactTable from "react-table";
import mainHelper from "../../../helpers/main";
import noData from "../../../assets/img/no-data.svg"
const NoData = () => {
  return <img className="cx-no-data-svg" src={noData}/>
};
const helper = new mainHelper();

class AllocatedTable extends Component {
    constructor(props) {
        super(props);
        // table defaults (effects the UI changes)
        this.state = {
            pageSize: 10,
            pages: null,
            loading: true,
            sorted: [{ id: 'id', desc: true }],
        };
    }

    componentDidMount(page) {
        this.refreshData();
    }

    refreshData = async () =>{
        this.setState({ loading: true });

        await this.props.fetchList('allocated');

        this.setState({ loading: false });
    }

    tableCols = () => {
        return [
            {
                Header: "Adset",
                accessor: "adsetName",
                Cell: ({ original }) => {
                    return <div className={'d-flex flex-column'}>
                        <div className={"cx-table-name-styling adsetslist-review"}>
                            <a onClick={(e) => { this.rowActions('edit', original, e) }} href={'#'} title={original.name}>
                                {original.name}
                            </a>
                        </div>
                        <div className={"table-actions-icons"}>
                            <a href={'#'} onClick={(e) => { this.rowActions('manage', original, e) }}>Manage</a>&nbsp;&nbsp;
                        </div>
                    </div>;
                },
                //width: 380
            },
            // {
            //     Header: "Campaign",
            //     accessor: "campaignName",
            //     Cell: ({ original }) => {
            //         return <span>{original.campaignName}</span>
            //     }
            // },
            {
                Header: "Total Budget",
                accessor: "totalBudget",
                Cell: ({ original }) => {
                    return <span>${helper.formatCXNumber(original.totalBudget)}</span>
                },
                width: 200
            },
            {
                Header: "Schedule",
                accessor: "startDate",
                Cell: ({ original }) => {
                    return <span>
                        { (original.startDateDetails && original.startDateDetails.isEnabled? (helper.format_date_dd_MM_yyyy(original.startDateDetails.date) +
                            ' - ') : '') + (original.endDateDetails  && original.endDateDetails.isEnabled?helper.format_date_dd_MM_yyyy(original.endDateDetails.date): 'On going')}
                    </span>
                },
                width: 250
            },
        ];
    }

    /*
     * Row actions of a table
     */
    rowActions = (action = 'manage', obj, event) => {

        switch(action){
            default:
            case "manage":
                this.openDrawer(obj, event);
                break;
        }
    }

    openDrawer = (selectedData, event = null) => {
        if (event) event.preventDefault();
        this.props.setDrawerVisible(true, selectedData);
    }

    render() {
        return (
            <>
                <ReactTable
                    noDataText=''
                    NoDataComponent={NoData}
                    defaultSorting={this.state.sorted}
                    sortable={true}
                    showPageSizeOptions={false}
                    minRows={0}
                    data={this.props.tableList}
                    resizable={false}
                    columns={this.tableCols()}
                    defaultPageSize={this.state.pageSize}
                    showPaginationBottom={true}
                    className="-highlight cx-reactive-table"
                    loading={this.state.loading}
                />
            </>
        );
    }
}

// what props do i need to get from the state for this component
const mapStateToProps = (state) => {
    return {
        tableList: (state.mediaPlanner.allocatedAdsetList && state.mediaPlanner.allocatedAdsetList) ? state.mediaPlanner.allocatedAdsetList : [],
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        fetchList: (type) => dispatch(mediaPlannerActions.fetchAdsetAllocationList({type})),
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(AllocatedTable);