import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { campaignPerformanceActions } from "../../../actions";
import _ from 'lodash';
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
// import HC_exporting from 'highcharts/modules/exporting';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
const helper = new mainHelper();

class BarChart extends Component {
    constructor(props){
        super(props);
        this.state = {
            loader: false,
        }
    }

    /*
    * componentDidMount
    */
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        this.setState({loader: true});
        await this.props.devicesFetch();
        this.setState({loader: false});
    }

    getData = () => {
        // get the `this.props.activeSubtab` eg. clicks
        // and return the data in the following format [{name: device - os, y: clicks}, ..]
        if(!this.props.devicesData.length) return [];
        let res = [];

        for(let i in this.props.devicesData) {
            const devicesD = this.props.devicesData[i];
            if(devicesD[this.props.activeSubtab]){
                const deviceName = (devicesD.os)? devicesD.device + ' - ' + devicesD.os :  devicesD.device;
                res.push({name: deviceName, y: devicesD[this.props.activeSubtab]});
            }
        }
        return res;
    }

    formatNumber = (subtab, value)=> {
        if(!value) return;
        switch(subtab){
            case 'clicks':
            case 'views':
            case 'impressions':
                return helper.formatNumber(value);
            case 'amountSpent':
                return '$' + value.toLocaleString();
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-devices-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    containerProps={{ className: "highchartscss" }}
                    key={this.props.activeSubtab + '-' + this.props.selectedType.typeid + '-' + this.props.selectedType.type + '-' + this.props.selectedType.campaignId}
                    highcharts={Highcharts}
                    options={
                        {
                            exporting: {
                                "enabled": true ,
                                buttons: {
                                    contextButton: {
                                        menuItems: [
                                            'viewFullscreen',
                                            'separator',
                                            'downloadPDF'
                                        ]
                                    }
                                },
                            },
                            colors: helper.cxColors && helper.cxColors.dark,
                            credits: false,
                            chart: {
                                type: 'column',
                                backgroundColor: 'transparent',
                                style: {
                                    fontFamily: 'Poppins'
                                }
                            },
                            title: {
                                text: ''
                            },
                            subtitle: {
                                text:  _.startCase(this.props.activeSubtab) + ' By Device',
                                style: {
                                    color: '#9899A6'
                                }
                            },
                            xAxis: {
                                type: 'category',
                                labels: {
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                            },
                            yAxis: {
                                title: {
                                    text: _.startCase(this.props.activeSubtab),
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                labels: {
                                    formatter: function () {
                                        return self.formatNumber(self.props.activeSubtab, this.y);
                                    },
                                    style: {
                                        color: '#9899A6'
                                    }
                                },
                                gridLineColor: 'rgba(152,153,166, 0.2)'
                            },
                            legend: {
                                enabled: false
                            },
                            plotOptions: {
                                series: {
                                    // pointWidth: 50,
                                    borderWidth: 0,
                                    dataLabels: {
                                        enabled: true,
                                        style:{
                                            textOutline: 0,
                                            color: '#9899A6'
                                        },
                                        formatter: function () {
                                            return self.formatNumber(self.props.activeSubtab, this.y);
                                        },
                                    }
                                }
                            },

                            tooltip: {
                                pointFormatter: function() {
                                    return this.name + ': '+ self.formatNumber(self.props.activeSubtab, this.y) + ' ' + _.startCase(self.props.activeSubtab);
                                },
                                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                            },

                            series: [
                                {
                                    name: "Devices",
                                    colorByPoint: true,
                                    data: this.getData()
                                }
                            ]
                        }
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        devicesData: (state.campaignPerformance && state.campaignPerformance.devicesData)? state.campaignPerformance.devicesData: [],
        activeSubtab: state.slidedrawer && state.slidedrawer.subtab,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        devicesFetch: () => dispatch(campaignPerformanceActions.fetchTypedevices())
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(BarChart);