import React from "react";
import PublishedPageviewsGraph from "./components/PublishedPageviewsGraph";
import PageviewsSummary from "./components/PageviewsSummary";
import TopPostsPageviews from "./components/TopPostsPageviews";
import TopPostsEngagement from "./components/TopPostsEngagement";
import { Row } from "reactstrap";
import analytics from "../../helpers/analytics";
import DateFilter from "../components/dateFilter";
import { FilterBar, StartFilters } from "../../components/FilterBar/filterBar";
import AdvancedFilter from "./components/postsAdvancedFilter";

class PostsOverview extends React.Component {
  constructor(props) {
    super(props);
    analytics.logPageView("Insights - Posts");
  }

  render() {
    return (
      <>
        <div className="content">
          <div>
            <h4 style={{ marginBottom: "20px" }}>
              <i className="tim-icons icon-notes"></i>&nbsp; Insights {">"}{" "}
              Content
            </h4>
          </div>
          <div className={"cx-page-filter-container mb-3"}>
            <div className={"justify-content-end d-flex"}>
              <DateFilter className={"mr-3"} />
            </div>
            <FilterBar className={"mt-3 cx-campaign-filter-listing"}>
              <StartFilters>
                <AdvancedFilter />
              </StartFilters>
            </FilterBar>
          </div>
          <Row className={"align-items-start"}>
            <div className={"col-sm-6"}>
              <PageviewsSummary />
            </div>
            <div className={"col-sm-6"}>
              <PublishedPageviewsGraph />
            </div>
          </Row>
          <Row className={"align-items-start"}>
            <div className={"col-sm-6"}>
              <TopPostsPageviews />
            </div>
            <div className={"col-sm-6"}>
              <TopPostsEngagement />
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default PostsOverview;
