import {
    WIDGET_PERFORMANCE_FILTER,
    WIDGET_PERFORMANCE_FILTERS_SELECTED,
    WIDGET_PERFORMANCE_SUMMARY,
    WIDGET_PERFORMANCE_BY_DEVICE,
    WIDGET_PERFORMANCE_BY_TIME,
    WIDGET_PERFORMANCE_BY_LOCATION,
    WIDGET_PERFORMANCE_TOP_POSTS,
    WIDGET_PERFORMANCE_TOP_WIDGETS
} from "../constants/appConstant";
import _ from "lodash";
import api from '../api/widgetPerformance';

/* for the widget performance filters */
const widgetPerformanceFiltersList = (filters) => ({ type: WIDGET_PERFORMANCE_FILTER, payload: filters });
export const widgetPerformanceFiltersSelected = (filters) => ({ type: WIDGET_PERFORMANCE_FILTERS_SELECTED, payload: filters });
export const fetchWidgetPerformanceFilters = () => {
    return (dispatch) => {
        let widgetFilter = {    
            options:[],       
            value: "widget_id",
            label: "Widget Title",
            type: "select",
            operator: [{
                value: "is",
                label: "is",
                symbol: ":"               
            }],
        };

        return api.getAllWidgets()
            .then(response => {
                if (response && response.data && response.data.data)
                    widgetFilter.options = response.data.data.map(x=>({label:x.widget_title,value:x.widget_id}));
                
                    console.log(widgetFilter)
                dispatch(widgetPerformanceFiltersList([widgetFilter]));
            })
            .catch(error => {
                throw (error);
            });
    };
}
export const addWidgetPerformanceFilter = (addFilter) => {
    return (dispatch, getState) => {
        if (addFilter) {
            let pageFilters;
            if (getState().widgetPerformance && getState().widgetPerformance.pageFilters && getState().widgetPerformance.pageFilters.length) {
                const doesItExistBefore = _.findIndex(getState().widgetPerformance.pageFilters, (item) => {
                    return (
                        item.value === addFilter.value &&
                        item.type === addFilter.type &&
                        _.isEqual(item.typeValue, addFilter.typeValue)
                    )
                });
                if (doesItExistBefore < 0) {
                    // added a new filter
                    pageFilters = [...getState().widgetPerformance.pageFilters, addFilter];
                } else {
                    // there is a duplicate so nothing to take
                    pageFilters = [...getState().widgetPerformance.pageFilters];
                }
            } else {
                // empty
                pageFilters = [addFilter];
            }
            dispatch(widgetPerformanceFiltersSelected(pageFilters));
        }
    };
}
export const removeWidgetPerformanceFilter = (removeFilter) => {
    return (dispatch, getState) => {
        if (removeFilter) {
            let pageFilters = [];
            if (getState().widgetPerformance && getState().widgetPerformance.pageFilters && getState().widgetPerformance.pageFilters.length) {
                // filter out the one needs to be removed
                pageFilters = getState().widgetPerformance.pageFilters.filter((item) => {
                    return !(
                        item.value === removeFilter.value &&
                        item.type === removeFilter.type &&
                        _.isEqual(item.typeValue, removeFilter.typeValue)
                    )
                })
            }
            dispatch(widgetPerformanceFiltersSelected(pageFilters));
        }
    };
}

/* For the Summary */
const widgetPerformanceSummary = (summary) => ({ type: WIDGET_PERFORMANCE_SUMMARY, payload: summary });
export const fetchWidgetPerformanceSummary = (filter,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetSummary(filter,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetPerformanceSummary(res));
            })
            .catch(error => {
                throw (error);
            });
    };
};

/* For the devices graph */
const widgetDevicesGraph = (data) => ({ type: WIDGET_PERFORMANCE_BY_DEVICE, payload: data });
export const fetchDevicesGraph = (filter,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetPerformanceByDevice(filter,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetDevicesGraph(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}

/* For the Time graph */
const widgetTimeGraph = (data) => ({ type: WIDGET_PERFORMANCE_BY_TIME, payload: data });
export const fetchTimeGraph = (filters,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetPerformanceByTime(filters,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetTimeGraph(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}

/* For the location graph */
const widgetLocationGraph = (data) => ({ type: WIDGET_PERFORMANCE_BY_LOCATION, payload: data });
export const fetchLocationGraph = (filter,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetPerformanceByLocation(filter,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetLocationGraph(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}

/* For the top posts table */
const widgetTopPosts = (data) => ({ type: WIDGET_PERFORMANCE_TOP_POSTS, payload: data });
export const fetchTopPosts = (filter,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetTopPosts(filter,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetTopPosts(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}

/* For the top widgets table */
const widgetTopWidgets = (data) => ({ type: WIDGET_PERFORMANCE_TOP_WIDGETS, payload: data });
export const fetchTopWidgets = (filter,dateFilter) => {
    return (dispatch) => {
        return api.getWidgetTopWidgets(filter,dateFilter)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(widgetTopWidgets(res));
            })
            .catch(error => {
                throw (error);
            });
    };
}