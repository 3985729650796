import moment from "moment";
import CXDateRangePicker from "./CXDateRangePicker";
import React from "react";
import {connect} from "react-redux";
import {filterActions} from "../../actions";

class DateFilter extends React.Component {
    componentDidMount() {
        this.props.initFilterDate();
    }

    render() {
        return (
            <div className={`cx-filter-page-by-date ${this.props.className || ''}`}>
                <CXDateRangePicker
                    startDate={moment(this.props.start)}
                    endDate={moment(this.props.end)}
                    dateChanged={(start, end) => { this.props.setFilterDate({start, end}); }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        start: state.filter && state.filter.date && state.filter.date.start,
        end: state.filter && state.filter.date && state.filter.date.end
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setFilterDate: (date) => dispatch(filterActions.setDateFilter(date)),
        initFilterDate: () => dispatch(filterActions.initFilterDate()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);