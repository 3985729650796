import React from "react";
import classnames from "classnames";
// import { NavLink } from "react-router-dom";

// reactstrap components
import {
  // Collapse,
  NavbarBrand,
  Navbar,
  // NavItem,
  // Nav,
  Container,
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth > 770,
      collapseOpen: true,
      color: "navbar-transparent",
      isPopupOpened: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  updateColor = () => {
    this.setState((prev) => ({ ...prev, width: window.innerWidth > 770 }));
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened

  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        style={{
          display: "flex",
          padding: this.state.width ? 10 : 0,
        }}
        expand="md"
      >
        <Container fluid>
          <div
            className="navbar-wrapper"
            style={{
              paddingLeft: "30px",
              paddingTop: "12px",
            }}
          >
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {this.props.brandText}
            </NavbarBrand>
          </div>

          {/* <Collapse isOpen={this.state.collapseOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <NavLink
                  to="/admin/dashboard"
                  className="nav-link text-primary"
                >
                  <i className="tim-icons icon-minimal-left" /> Back to
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="tim-icons icon-laptop" /> Register
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="tim-icons icon-single-02" /> Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/pricing" className="nav-link">
                  <i className="tim-icons icon-coins" /> Pricing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="tim-icons icon-lock-circle" /> Lock
                </NavLink>
              </NavItem>
            </Nav>

          </Collapse> */}
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
