import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { CXPermissionsArray, CXInputField, CXCheckbox } from "../../../CXComponents";
import Permissions from './Permissions'
import CxLoader from "../../components/cxLoader";
import mainHelper from "../../../helpers/main";
import { useFieldArray, useForm } from "react-hook-form";
import useUserStore from "./UserStore";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { createUser, editUser, readUserPermissions, getOrganizations, getRoles } from "./userAPI";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


const UserForm = () => {
  const [activeTab, setActiveTab] = useState("General");

  const qc = useQueryClient();

  const schema = yup.object({
    name: yup.string("Username should be a string").max(30).required(),
    email: yup.string().email("Please make sure the email is a valid email").required("Please enter an email"),
    password: yup.string().min(3).max(30),
  }).required();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });


  const togglePopup = useUserStore((state) => state.togglePopup);
  const itemToLoad = useUserStore((state) => state.itemToLoad);
  const actionType = useUserStore((state) => state.actionType);

  const { isLoading, data, isFetching } = useQuery( ["user-read-permission", itemToLoad], () => {
      if (actionType === "edit") return readUserPermissions(itemToLoad);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
  const getOrganizationsQuery = useQuery( ["get-organizations"], () => {
    return getOrganizations();
  });
  const getRolesQuery = useQuery( ["get-roles"], () => {
    return getRoles();
  });

  const createUserQuery = useMutation((user) => {
    if (actionType === "create") return createUser(user);
  });

  const editUserQuery = useMutation((user) => {
    if (actionType === "edit") return editUser(user);
  });

  const isSaving = createUserQuery.isLoading || editUserQuery.isLoading;

  const cancelButton = () => {
    togglePopup(false);
  };

  const submitButton = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = async (formVals) => {
    const mapped = { ...formVals };
    if (actionType == "create") {
      await createUserQuery.mutateAsync(mapped);
    } else if (actionType == "edit") {
      mapped.id = itemToLoad;
      await editUserQuery.mutateAsync(mapped);
    }
    //empty the cache for this query because it changed
    qc.invalidateQueries(["user-list"]);
    qc.invalidateQueries(["user-read-permission", itemToLoad]);
    togglePopup();
  };

  
  const renderGeneralForm = (data) => {
    return (
      <>
        <CXInputField
            label="Username *"
            disabled={isLoading || isFetching}
            name="name"
            defaultValue={data?.name}
            register={register}
            errorMsg={errors?.name?.message}
        />
        <br />
        <CXInputField
            label="Email *"
            disabled={isLoading || isFetching}
            name="email"
            defaultValue={data?.email}
            register={register}
            errorMsg={errors?.email?.message}
        />
        <br />
        <CXInputField
            label="Password *"
            disabled={isLoading || isFetching}
            name="password"
            defaultValue={data?.password}
            register={register}
            errorMsg={errors?.password?.message}
        />
        <br />
        <CXCheckbox
            label="Is the user active?"
            disabled={isLoading || isFetching}
            name="isActive"
            defaultValue={data?.isActive}
            register={register}
        />
        <br />
        <CXCheckbox
            label="Is review required?"
            disabled={isLoading || isFetching}
            name="isReviewRequired"
            defaultValue={data?.isReviewRequired}
            register={register}
        />
        <br />

        <div className={"cx-action-buttons"}>
          <button
            type="button"
            disabled={isLoading || isFetching}
            className="btn cx-focus-btn"
            onClick={submitButton}
          >
            Save
          </button>
          {isSaving && <CxLoader inPlace={true} />}
          <button
            type="button"
            disabled={isLoading || isFetching}
            className="btn"
            onClick={cancelButton}
          >
            Cancel
          </button>
        </div>
      </>
    );
  };

  const renderForm = () => {
    return (
      <div id={"cx-user-config-form-container"}>
        <Row className={"mx-0 mb-4"}>
          <Col className={"px-0"}>
            <div className={"reactive-tabs widget-form-tabs"}>
              <div
                className={`reactive-tab ${
                  activeTab === "General" ? "active" : ""
                }`}
                onClick={() => setActiveTab("General")}
              >
                <i className={"tim-icons icon-bullet-list-67"}></i>{" "}
                <p>General</p>
              </div>
              <div
                className={`reactive-tab ${
                  activeTab === "Permissions" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Permissions")}
              >
                <i className={"tim-icons icon-key-25"}></i> <p>Permissions</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className={"mx-0"}>
          <Col className={"px-0"}>
            <div
              className={`${
                activeTab === "General"
                ? "cx-widget-form-general"
                : "d-none"
              }`}
            >
              <form
                className={"cx-recommendatdion-config-form-container"}
                onSubmit={handleSubmit(onSubmit)}
              >
                {renderGeneralForm((data && actionType==="edit") ? data.user : {})}
              </form>
            </div>
            <div
              className={`${
                activeTab === "Permissions"
                  ? "cx-widget-form-general"
                  : "d-none"
              }`}
            >

              {
                actionType == "edit"
                ? <Permissions {...{
                      data,
                      organizations: getOrganizationsQuery?.data,
                      roles: getRolesQuery?.data,
                  }}/>
                : <h4 className="cx-action-buttons" style={{display: 'flex', alignItems: "center", gap: "1rem"}}><span>Please create the user first by saving:</span> 
                    <button
                      type="button"
                      disabled={isLoading || isFetching}
                      className="btn cx-focus-btn"
                      onClick={()=>{setActiveTab('General'); submitButton();}}
                    >
                      Save
                    </button>
                    {isSaving && <CxLoader inPlace={true} />}
                  </h4>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  
  return <>{isLoading || isFetching ? <CxLoader minHeight="400px"/> : renderForm()}</>;
};

export default UserForm;
