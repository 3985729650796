import React from "react";
class Delivery extends React.Component {   
    getStatusColor = (dv) => {
        switch (dv.toLowerCase()) {
          case 'in draft':
            return '#9899A6';
          case 'active':
            return '#52c7ab';
          case 'inactive':
            return '#ff858b';
          case 'rejected':
            return '#B44046';
          case 'pending delivery':
            return '#F09855';
          case 'in review':
            return '#F0E655';
          case 'completed':
            return '#55C0F0';
          case 'deleted':
            return '#B44046';
          default:
            return '';
        }
      }

    render() {
      let dv = this.props.delivery;
      let color = this.getStatusColor(dv);
        return (
            <span>          
            { color&&
                <span className={"cx-table-ico-color"} style={{backgroundColor: color}}></span>
            }
            <span className={"cx-table-ico-state"}>{dv}</span>  
            </span>                     
        )
    }
}
export default Delivery
