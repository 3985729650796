import React from "react";
import { useFieldArray } from "react-hook-form";
import ChoiceField from "./ChoiceField";

const ChoicesArrayField = ({ control, name, register }) => {
    const { fields, append, remove, insert, update } = useFieldArray({
        control,
        name,
        defaultValue: [{
            text: ''
        }]
    });

    const addChoice = () => {
        append({
            text: "",
        });
    };

    const clearAll = () => {
        // will remove all fields when no index provided
        remove();
    };

    return (
        <div>
            <h4>Choices: </h4>
            <div className="choices">
                {fields.map((field, index) => {
                    return (
                        <ChoiceField
                            {...{
                                key: field.id,
                                index,
                                control,
                                register,
                                remove,
                                update,
                                field,
                            }}
                        />
                    );
                })}
            </div>
            <br />
            <button type="button" className="cx-btn" onClick={addChoice}>
                Add Choice
            </button>
        </div>
    );
};

export default ChoicesArrayField;
