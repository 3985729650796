import React from 'react';
import {useController} from "react-hook-form"


const CXControlledPill = ({ background, close, className, name, control, displayProp }) => {
    const { field: { value }} = useController({
        name,
        control,
        rules: { required: true },

    });
    
    return (
        <span className={`cx-pill ${className??''}`}  style={background&&{background}}>
            <span>{displayProp ? value[displayProp] : value}</span>
            {
                close &&
                <i className="fa fa-times" onClick={close}></i>
            }
        </span>
    );
}

export default CXControlledPill;
