import React, {Component, useEffect, useState} from "react";

import {drawerActions, mediaPlannerActions} from "../../../actions";
import { connect } from "react-redux";
import mainHelpers from "../../../helpers/main";
import AdsetAllocationTab from "./adsetAllocationTab";
import PerfectScrollbar from "perfect-scrollbar";

let helper = new mainHelpers();
var ps;
class Drawer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activationLoader: false,
            model:[]
        }

        this.promiseDrawerOpened = null;
    }

    updateDrawerData = (ref, prevProps) => {
        if (
            (ref.props.selectedData && (JSON.stringify(prevProps.selectedData) !== JSON.stringify(ref.props.selectedData)))
        ) {
            if (ref.props.selectedData) {
                ref.props.fetchDrawerData(ref.props.selectedData.id);
            }
        }
    }
    /*
    * componentDidUpdate
    */
    componentDidUpdate (prevProps) {

        if (prevProps.isDrawerOpened !== this.props.isDrawerOpened) {
            // animate the toggle drawer
            // animateDrawer
            this.props.resetDrawerForm();

            this.animateToggleDrawer();
            // disable scroll of page on open drawer
            helper.disableScrollPage(this.props.isDrawerOpened);
        }
        const ref = this;
        this.promiseDrawerOpened && this.promiseDrawerOpened.then(()=>{
            // after open drawer get the data
            ref.updateDrawerData(ref, prevProps);
        });

    }

    /*
    * Toggle Drawer
    */

    closeDrawer = (event = null) => {
        if (event) {
            event.preventDefault()

        };
        this.setState((prev)=>({...prev,model:[]}))
        this.props.setDrawerVisible(false);
        console.log(this.props)
        this.props.setDrawerForm();
        this.props.resetDrawerForm();
    }

    /*
    * animate the Toggle Drawer
    */
    animateToggleDrawer = () => {
        const elt = this.refs.drawerContainerElt;
        if (!elt) return '';
        if (this.props.isDrawerOpened) {
            this.setState((prev)=>({...prev,model:[  <AdsetAllocationTab
                    key={"adset-allocated-tabs-" + this.props.selected}/>]}))

            // if drawer opened set some time out to remove the none display
            elt.classList.remove('d-none');
            this.promiseDrawerOpened =  new Promise(function(resolve) {
                setTimeout(function () {
                    // open drawer
                    elt.classList.add('open');
                    setTimeout(function(){
                        // then fetch data
                        resolve('opened');
                    }, 500);
                }, 10);
            });
        }
        else {

            this.promiseDrawerOpened = null;
            // close effect
            elt.classList.remove('open');
            this.setState((prev)=>({...prev,model:[]}))

            setTimeout(function () {
                // then hide the drawer
                elt.classList.add('d-none');
                // transition lasts for 0.5s so set the timeout for 500
            }, 500);
        }
        return '';
    }

    renderForm = () => {
        return <div className={"cx-drawer-allocation-form"}>{this.state.model} </div>
       
    }



    render() {
        return (
            <div ref="drawerContainerElt" className={`cx-media-planner-drawer cx-drawer-container d-none`}>
                <div className={'overlay'}></div>
                <div className={"cx-drawer-float"}>
                    <div className={"cx-drawer-actions"}>
                        <a className={"cx-drawer-back"} href={"#"} onClick={(e) => { this.closeDrawer(e) }}>
                            <i className={"fa fa-arrow-circle-right"}></i>
                        </a>
                        <a className={`cx-drawer-edit active`}
                           href={"#"}
                        >
                            <i className={"fa fa-pencil-alt"}></i>
                        </a>
                    </div>
                    <div className={"cx-drawer-content"}>
                        <div className={"cx-drawer-breadcrumb d-flex justify-content-between"}>
                            <span className={"count"}>
                                Manage Adset Allocation
                            </span>
                        </div>
                        <div className={"cx-drawer-content-inner"}>
                            {this.renderForm()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        selectedData: state.slidedrawer && state.slidedrawer.selectedData,
        isDrawerOpened: state.slidedrawer && state.slidedrawer.isDrawerOpened,
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        setDrawerVisible: (action, selectedData) => dispatch(drawerActions.setDrawerVisible(action, selectedData)),
        resetDrawerForm: () => dispatch(drawerActions.resetDrawerForm()),
        setDrawerForm: (selectedType, formAction = 'edit') => dispatch(drawerActions.setDrawerForm(selectedType, formAction)),
        fetchDrawerData: (adsetId) => dispatch(mediaPlannerActions.fetchAdsetAllocationItem(adsetId)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(Drawer);