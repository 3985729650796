import _auth from "./auth";
import apiUrl from '../helpers/global';
import main from "../helpers/main"

const auth = new _auth();
const helper = new main();

const contentGroupingAnalysisData = {
    getContentGroupingPageviews: async () => {
        const url = apiUrl + `content-grouping-charts`;


        return {
            data: {
                "data": [
                    {
                        "date": "2023-01-24",
                        "visitors": 17425,
                        "pageviews": 26141,
                        "posts_published": 1635,
                        "ROA": 15.988379204892967,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 3917,
                        "pageviews": 5634,
                        "posts_published": 536,
                        "ROA": 10.511194029850746,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 12253,
                        "pageviews": 15959,
                        "posts_published": 1426,
                        "ROA": 11.191444600280505,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 4536,
                        "pageviews": 5714,
                        "posts_published": 1927,
                        "ROA": 2.9652309289050338,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 33,
                        "pageviews": 38,
                        "posts_published": 21,
                        "ROA": 1.8095238095238095,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 981,
                        "pageviews": 1155,
                        "posts_published": 151,
                        "ROA": 7.6490066225165565,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 191,
                        "pageviews": 236,
                        "posts_published": 127,
                        "ROA": 1.8582677165354331,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-24",
                        "visitors": 221,
                        "pageviews": 271,
                        "posts_published": 144,
                        "ROA": 1.8819444444444444,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 12881,
                        "pageviews": 21017,
                        "posts_published": 1684,
                        "ROA": 12.480403800475059,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 4929,
                        "pageviews": 7215,
                        "posts_published": 540,
                        "ROA": 13.361111111111111,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 8893,
                        "pageviews": 11528,
                        "posts_published": 1307,
                        "ROA": 8.8201989288446825,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 4736,
                        "pageviews": 5857,
                        "posts_published": 1878,
                        "ROA": 3.1187433439829606,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 543,
                        "pageviews": 664,
                        "posts_published": 158,
                        "ROA": 4.2025316455696204,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 197,
                        "pageviews": 240,
                        "posts_published": 137,
                        "ROA": 1.7518248175182483,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 180,
                        "pageviews": 222,
                        "posts_published": 125,
                        "ROA": 1.776,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-25",
                        "visitors": 26,
                        "pageviews": 28,
                        "posts_published": 19,
                        "ROA": 1.4736842105263157,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 5600,
                        "pageviews": 7055,
                        "posts_published": 1778,
                        "ROA": 3.9679415073115862,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 16723,
                        "pageviews": 26057,
                        "posts_published": 1636,
                        "ROA": 15.927261613691931,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 10574,
                        "pageviews": 14201,
                        "posts_published": 1294,
                        "ROA": 10.974497681607419,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 3549,
                        "pageviews": 5017,
                        "posts_published": 520,
                        "ROA": 9.648076923076923,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 754,
                        "pageviews": 830,
                        "posts_published": 136,
                        "ROA": 6.1029411764705879,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 51,
                        "pageviews": 54,
                        "posts_published": 37,
                        "ROA": 1.4594594594594594,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 185,
                        "pageviews": 224,
                        "posts_published": 135,
                        "ROA": 1.6592592592592592,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-26",
                        "visitors": 211,
                        "pageviews": 251,
                        "posts_published": 146,
                        "ROA": 1.7191780821917808,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 4270,
                        "pageviews": 5371,
                        "posts_published": 1745,
                        "ROA": 3.0779369627507163,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 22296,
                        "pageviews": 34189,
                        "posts_published": 1358,
                        "ROA": 25.1759941089838,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 3578,
                        "pageviews": 5178,
                        "posts_published": 542,
                        "ROA": 9.55350553505535,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 35,
                        "pageviews": 51,
                        "posts_published": 34,
                        "ROA": 1.5,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 16103,
                        "pageviews": 20778,
                        "posts_published": 1301,
                        "ROA": 15.970791698693313,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 208,
                        "pageviews": 273,
                        "posts_published": 154,
                        "ROA": 1.7727272727272727,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 188,
                        "pageviews": 250,
                        "posts_published": 143,
                        "ROA": 1.7482517482517483,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-27",
                        "visitors": 1010,
                        "pageviews": 1170,
                        "posts_published": 107,
                        "ROA": 10.934579439252337,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 16212,
                        "pageviews": 22683,
                        "posts_published": 1322,
                        "ROA": 17.158093797276852,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 5062,
                        "pageviews": 7375,
                        "posts_published": 537,
                        "ROA": 13.733705772811918,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 14341,
                        "pageviews": 18372,
                        "posts_published": 1326,
                        "ROA": 13.855203619909503,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 3748,
                        "pageviews": 4535,
                        "posts_published": 1829,
                        "ROA": 2.4794969928922908,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 788,
                        "pageviews": 943,
                        "posts_published": 109,
                        "ROA": 8.65137614678899,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 207,
                        "pageviews": 270,
                        "posts_published": 160,
                        "ROA": 1.6875,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 179,
                        "pageviews": 241,
                        "posts_published": 143,
                        "ROA": 1.6853146853146854,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-28",
                        "visitors": 33,
                        "pageviews": 45,
                        "posts_published": 27,
                        "ROA": 1.6666666666666667,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 886,
                        "pageviews": 1098,
                        "posts_published": 129,
                        "ROA": 8.5116279069767433,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 3282,
                        "pageviews": 3953,
                        "posts_published": 1825,
                        "ROA": 2.1660273972602742,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 15717,
                        "pageviews": 22135,
                        "posts_published": 1406,
                        "ROA": 15.743243243243244,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 3963,
                        "pageviews": 5480,
                        "posts_published": 536,
                        "ROA": 10.223880597014926,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 11906,
                        "pageviews": 15618,
                        "posts_published": 1334,
                        "ROA": 11.707646176911544,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 210,
                        "pageviews": 266,
                        "posts_published": 136,
                        "ROA": 1.9558823529411764,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 194,
                        "pageviews": 248,
                        "posts_published": 123,
                        "ROA": 2.0162601626016259,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-29",
                        "visitors": 32,
                        "pageviews": 37,
                        "posts_published": 20,
                        "ROA": 1.85,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 3701,
                        "pageviews": 4582,
                        "posts_published": 1838,
                        "ROA": 2.4929270946681177,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 12654,
                        "pageviews": 19713,
                        "posts_published": 1734,
                        "ROA": 11.368512110726643,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 5189,
                        "pageviews": 7348,
                        "posts_published": 535,
                        "ROA": 13.734579439252336,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 11555,
                        "pageviews": 15093,
                        "posts_published": 1414,
                        "ROA": 10.673974540311175,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 960,
                        "pageviews": 1181,
                        "posts_published": 165,
                        "ROA": 7.1575757575757573,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 191,
                        "pageviews": 252,
                        "posts_published": 146,
                        "ROA": 1.726027397260274,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 29,
                        "pageviews": 38,
                        "posts_published": 26,
                        "ROA": 1.4615384615384615,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-01-30",
                        "visitors": 214,
                        "pageviews": 276,
                        "posts_published": 161,
                        "ROA": 1.7142857142857142,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 2992,
                        "pageviews": 4097,
                        "posts_published": 490,
                        "ROA": 8.3612244897959176,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 4381,
                        "pageviews": 5568,
                        "posts_published": 1871,
                        "ROA": 2.9759486905398185,
                        "content": "فن"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 13443,
                        "pageviews": 20236,
                        "posts_published": 1725,
                        "ROA": 11.731014492753623,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 6764,
                        "pageviews": 9391,
                        "posts_published": 1321,
                        "ROA": 7.1090083270249806,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 1095,
                        "pageviews": 1327,
                        "posts_published": 164,
                        "ROA": 8.0914634146341466,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 171,
                        "pageviews": 230,
                        "posts_published": 126,
                        "ROA": 1.8253968253968254,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 192,
                        "pageviews": 255,
                        "posts_published": 138,
                        "ROA": 1.8478260869565217,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-01-31",
                        "visitors": 28,
                        "pageviews": 30,
                        "posts_published": 17,
                        "ROA": 1.7647058823529411,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 20707,
                        "pageviews": 25656,
                        "posts_published": 1366,
                        "ROA": 18.781844802342608,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 4703,
                        "pageviews": 6593,
                        "posts_published": 501,
                        "ROA": 13.159680638722556,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 4154,
                        "pageviews": 5116,
                        "posts_published": 1957,
                        "ROA": 2.6142054164537556,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 11578,
                        "pageviews": 17963,
                        "posts_published": 1706,
                        "ROA": 10.529308323563892,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 829,
                        "pageviews": 918,
                        "posts_published": 143,
                        "ROA": 6.4195804195804191,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 184,
                        "pageviews": 266,
                        "posts_published": 139,
                        "ROA": 1.9136690647482015,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 210,
                        "pageviews": 297,
                        "posts_published": 154,
                        "ROA": 1.9285714285714286,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-01",
                        "visitors": 35,
                        "pageviews": 47,
                        "posts_published": 26,
                        "ROA": 1.8076923076923077,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 5050,
                        "pageviews": 6264,
                        "posts_published": 1877,
                        "ROA": 3.3372402770378264,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 4627,
                        "pageviews": 6552,
                        "posts_published": 498,
                        "ROA": 13.156626506024097,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 16273,
                        "pageviews": 25683,
                        "posts_published": 1707,
                        "ROA": 15.045694200351495,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 656,
                        "pageviews": 769,
                        "posts_published": 121,
                        "ROA": 6.3553719008264462,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 15457,
                        "pageviews": 19528,
                        "posts_published": 1287,
                        "ROA": 15.173271173271173,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 39,
                        "pageviews": 42,
                        "posts_published": 29,
                        "ROA": 1.4482758620689655,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 179,
                        "pageviews": 227,
                        "posts_published": 126,
                        "ROA": 1.8015873015873016,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-02",
                        "visitors": 166,
                        "pageviews": 214,
                        "posts_published": 118,
                        "ROA": 1.8135593220338984,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 4438,
                        "pageviews": 5374,
                        "posts_published": 1770,
                        "ROA": 3.0361581920903955,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 13481,
                        "pageviews": 20974,
                        "posts_published": 1536,
                        "ROA": 13.654947916666666,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 6691,
                        "pageviews": 9201,
                        "posts_published": 553,
                        "ROA": 16.638336347197107,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 13141,
                        "pageviews": 16595,
                        "posts_published": 1324,
                        "ROA": 12.533987915407854,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 345,
                        "pageviews": 406,
                        "posts_published": 122,
                        "ROA": 3.3278688524590163,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 164,
                        "pageviews": 201,
                        "posts_published": 121,
                        "ROA": 1.6611570247933884,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 192,
                        "pageviews": 229,
                        "posts_published": 134,
                        "ROA": 1.708955223880597,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-03",
                        "visitors": 33,
                        "pageviews": 35,
                        "posts_published": 27,
                        "ROA": 1.2962962962962963,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 4841,
                        "pageviews": 5961,
                        "posts_published": 1695,
                        "ROA": 3.5168141592920352,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 14274,
                        "pageviews": 18397,
                        "posts_published": 1284,
                        "ROA": 14.327881619937695,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 12339,
                        "pageviews": 17820,
                        "posts_published": 1329,
                        "ROA": 13.408577878103838,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 7570,
                        "pageviews": 10666,
                        "posts_published": 564,
                        "ROA": 18.911347517730498,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 142,
                        "pageviews": 165,
                        "posts_published": 115,
                        "ROA": 1.4347826086956521,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 376,
                        "pageviews": 458,
                        "posts_published": 114,
                        "ROA": 4.0175438596491224,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 36,
                        "pageviews": 38,
                        "posts_published": 23,
                        "ROA": 1.6521739130434783,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-04",
                        "visitors": 169,
                        "pageviews": 198,
                        "posts_published": 126,
                        "ROA": 1.5714285714285714,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 9380,
                        "pageviews": 14215,
                        "posts_published": 1423,
                        "ROA": 9.9894588896697112,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 9650,
                        "pageviews": 13475,
                        "posts_published": 1344,
                        "ROA": 10.026041666666666,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 6106,
                        "pageviews": 9212,
                        "posts_published": 546,
                        "ROA": 16.871794871794872,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 4578,
                        "pageviews": 5631,
                        "posts_published": 1927,
                        "ROA": 2.9221587960560456,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 151,
                        "pageviews": 183,
                        "posts_published": 125,
                        "ROA": 1.464,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 588,
                        "pageviews": 707,
                        "posts_published": 134,
                        "ROA": 5.2761194029850742,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 202,
                        "pageviews": 236,
                        "posts_published": 138,
                        "ROA": 1.7101449275362319,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-05",
                        "visitors": 35,
                        "pageviews": 38,
                        "posts_published": 23,
                        "ROA": 1.6521739130434783,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 16423,
                        "pageviews": 28851,
                        "posts_published": 626,
                        "ROA": 46.087859424920126,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 12092,
                        "pageviews": 19186,
                        "posts_published": 1797,
                        "ROA": 10.676683361157485,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 3891,
                        "pageviews": 4975,
                        "posts_published": 1929,
                        "ROA": 2.5790565059616379,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 10662,
                        "pageviews": 15456,
                        "posts_published": 1396,
                        "ROA": 11.071633237822349,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 211,
                        "pageviews": 274,
                        "posts_published": 140,
                        "ROA": 1.9571428571428571,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 491,
                        "pageviews": 610,
                        "posts_published": 154,
                        "ROA": 3.9610389610389611,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 173,
                        "pageviews": 232,
                        "posts_published": 131,
                        "ROA": 1.7709923664122138,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-06",
                        "visitors": 74,
                        "pageviews": 90,
                        "posts_published": 36,
                        "ROA": 2.5,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 3383,
                        "pageviews": 4224,
                        "posts_published": 1825,
                        "ROA": 2.3145205479452056,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 28238,
                        "pageviews": 39585,
                        "posts_published": 1675,
                        "ROA": 23.632835820895522,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 8171,
                        "pageviews": 11769,
                        "posts_published": 1283,
                        "ROA": 9.1730319563522986,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 8227,
                        "pageviews": 12298,
                        "posts_published": 525,
                        "ROA": 23.424761904761905,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 161,
                        "pageviews": 235,
                        "posts_published": 127,
                        "ROA": 1.8503937007874016,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 474,
                        "pageviews": 568,
                        "posts_published": 132,
                        "ROA": 4.3030303030303028,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 199,
                        "pageviews": 276,
                        "posts_published": 144,
                        "ROA": 1.9166666666666667,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-07",
                        "visitors": 38,
                        "pageviews": 47,
                        "posts_published": 26,
                        "ROA": 1.8076923076923077,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 18398,
                        "pageviews": 27648,
                        "posts_published": 1705,
                        "ROA": 16.2158357771261,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 9465,
                        "pageviews": 14257,
                        "posts_published": 532,
                        "ROA": 26.798872180451127,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 3318,
                        "pageviews": 4042,
                        "posts_published": 1786,
                        "ROA": 2.263157894736842,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 415,
                        "pageviews": 499,
                        "posts_published": 146,
                        "ROA": 3.4178082191780823,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 9656,
                        "pageviews": 13792,
                        "posts_published": 1253,
                        "ROA": 11.007182761372706,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 188,
                        "pageviews": 261,
                        "posts_published": 140,
                        "ROA": 1.8642857142857143,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 159,
                        "pageviews": 226,
                        "posts_published": 129,
                        "ROA": 1.751937984496124,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-08",
                        "visitors": 40,
                        "pageviews": 43,
                        "posts_published": 23,
                        "ROA": 1.8695652173913044,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 7530,
                        "pageviews": 11504,
                        "posts_published": 540,
                        "ROA": 21.303703703703704,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 17855,
                        "pageviews": 29212,
                        "posts_published": 1632,
                        "ROA": 17.899509803921568,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 4629,
                        "pageviews": 5676,
                        "posts_published": 1697,
                        "ROA": 3.3447259870359458,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 343,
                        "pageviews": 413,
                        "posts_published": 126,
                        "ROA": 3.2777777777777777,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 11145,
                        "pageviews": 15545,
                        "posts_published": 1244,
                        "ROA": 12.495980707395498,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 30,
                        "pageviews": 39,
                        "posts_published": 26,
                        "ROA": 1.5,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 137,
                        "pageviews": 185,
                        "posts_published": 109,
                        "ROA": 1.6972477064220184,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-09",
                        "visitors": 164,
                        "pageviews": 214,
                        "posts_published": 121,
                        "ROA": 1.7685950413223142,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 12507,
                        "pageviews": 20628,
                        "posts_published": 1358,
                        "ROA": 15.189985272459499,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 3922,
                        "pageviews": 4696,
                        "posts_published": 1663,
                        "ROA": 2.8238123872519543,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 317,
                        "pageviews": 382,
                        "posts_published": 102,
                        "ROA": 3.7450980392156863,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 14179,
                        "pageviews": 19318,
                        "posts_published": 535,
                        "ROA": 36.108411214953271,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 45,
                        "pageviews": 53,
                        "posts_published": 35,
                        "ROA": 1.5142857142857142,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 18297,
                        "pageviews": 23057,
                        "posts_published": 1168,
                        "ROA": 19.74058219178082,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 131,
                        "pageviews": 173,
                        "posts_published": 108,
                        "ROA": 1.6018518518518519,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-10",
                        "visitors": 154,
                        "pageviews": 198,
                        "posts_published": 119,
                        "ROA": 1.6638655462184875,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 11158,
                        "pageviews": 16063,
                        "posts_published": 537,
                        "ROA": 29.912476722532588,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 4219,
                        "pageviews": 5100,
                        "posts_published": 1663,
                        "ROA": 3.0667468430547205,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 16801,
                        "pageviews": 23873,
                        "posts_published": 1272,
                        "ROA": 18.76808176100629,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 9967,
                        "pageviews": 12791,
                        "posts_published": 1232,
                        "ROA": 10.382305194805195,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 25,
                        "pageviews": 31,
                        "posts_published": 19,
                        "ROA": 1.631578947368421,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 182,
                        "pageviews": 217,
                        "posts_published": 104,
                        "ROA": 2.0865384615384617,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 169,
                        "pageviews": 210,
                        "posts_published": 119,
                        "ROA": 1.7647058823529411,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-11",
                        "visitors": 144,
                        "pageviews": 183,
                        "posts_published": 107,
                        "ROA": 1.7102803738317758,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 7802,
                        "pageviews": 10061,
                        "posts_published": 1181,
                        "ROA": 8.5190516511431,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 9514,
                        "pageviews": 13109,
                        "posts_published": 525,
                        "ROA": 24.96952380952381,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 14080,
                        "pageviews": 20298,
                        "posts_published": 1497,
                        "ROA": 13.559118236472946,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 3625,
                        "pageviews": 4482,
                        "posts_published": 1690,
                        "ROA": 2.65207100591716,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 142,
                        "pageviews": 174,
                        "posts_published": 108,
                        "ROA": 1.6111111111111112,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 173,
                        "pageviews": 209,
                        "posts_published": 122,
                        "ROA": 1.7131147540983607,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 207,
                        "pageviews": 254,
                        "posts_published": 134,
                        "ROA": 1.8955223880597014,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-12",
                        "visitors": 37,
                        "pageviews": 45,
                        "posts_published": 26,
                        "ROA": 1.7307692307692308,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 9369,
                        "pageviews": 14190,
                        "posts_published": 1667,
                        "ROA": 8.5122975404919021,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 8752,
                        "pageviews": 12957,
                        "posts_published": 531,
                        "ROA": 24.401129943502823,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 3471,
                        "pageviews": 4291,
                        "posts_published": 1922,
                        "ROA": 2.2325702393340272,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 11657,
                        "pageviews": 15762,
                        "posts_published": 1211,
                        "ROA": 13.015689512799339,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 37,
                        "pageviews": 38,
                        "posts_published": 29,
                        "ROA": 1.3103448275862069,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 462,
                        "pageviews": 582,
                        "posts_published": 133,
                        "ROA": 4.37593984962406,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 141,
                        "pageviews": 169,
                        "posts_published": 112,
                        "ROA": 1.5089285714285714,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-13",
                        "visitors": 116,
                        "pageviews": 141,
                        "posts_published": 100,
                        "ROA": 1.41,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 7242,
                        "pageviews": 10556,
                        "posts_published": 565,
                        "ROA": 18.683185840707964,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 3994,
                        "pageviews": 4848,
                        "posts_published": 1995,
                        "ROA": 2.4300751879699249,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 9080,
                        "pageviews": 14068,
                        "posts_published": 1656,
                        "ROA": 8.4951690821256047,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 14525,
                        "pageviews": 19246,
                        "posts_published": 1307,
                        "ROA": 14.725325172149962,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 1692,
                        "pageviews": 2020,
                        "posts_published": 146,
                        "ROA": 13.835616438356164,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 35,
                        "pageviews": 36,
                        "posts_published": 28,
                        "ROA": 1.2857142857142858,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 147,
                        "pageviews": 179,
                        "posts_published": 113,
                        "ROA": 1.584070796460177,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-14",
                        "visitors": 173,
                        "pageviews": 208,
                        "posts_published": 125,
                        "ROA": 1.664,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 3154,
                        "pageviews": 3919,
                        "posts_published": 1730,
                        "ROA": 2.2653179190751445,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 10129,
                        "pageviews": 17055,
                        "posts_published": 1689,
                        "ROA": 10.097690941385435,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 7721,
                        "pageviews": 11282,
                        "posts_published": 485,
                        "ROA": 23.261855670103092,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 14807,
                        "pageviews": 20265,
                        "posts_published": 1252,
                        "ROA": 16.186102236421725,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 1081,
                        "pageviews": 1272,
                        "posts_published": 119,
                        "ROA": 10.6890756302521,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 137,
                        "pageviews": 181,
                        "posts_published": 114,
                        "ROA": 1.5877192982456141,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 117,
                        "pageviews": 159,
                        "posts_published": 103,
                        "ROA": 1.5436893203883495,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-15",
                        "visitors": 39,
                        "pageviews": 50,
                        "posts_published": 32,
                        "ROA": 1.5625,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 3231,
                        "pageviews": 4059,
                        "posts_published": 1727,
                        "ROA": 2.3503184713375798,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 7047,
                        "pageviews": 10080,
                        "posts_published": 552,
                        "ROA": 18.260869565217391,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 12515,
                        "pageviews": 20168,
                        "posts_published": 1604,
                        "ROA": 12.57356608478803,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 21782,
                        "pageviews": 27464,
                        "posts_published": 1259,
                        "ROA": 21.814138204924543,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 2418,
                        "pageviews": 2729,
                        "posts_published": 152,
                        "ROA": 17.953947368421051,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 157,
                        "pageviews": 202,
                        "posts_published": 113,
                        "ROA": 1.7876106194690264,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 129,
                        "pageviews": 174,
                        "posts_published": 103,
                        "ROA": 1.6893203883495145,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-16",
                        "visitors": 34,
                        "pageviews": 39,
                        "posts_published": 24,
                        "ROA": 1.625,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 9779,
                        "pageviews": 14481,
                        "posts_published": 549,
                        "ROA": 26.377049180327869,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 4434,
                        "pageviews": 5545,
                        "posts_published": 1565,
                        "ROA": 3.5431309904153356,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 3371,
                        "pageviews": 3810,
                        "posts_published": 103,
                        "ROA": 36.990291262135919,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 19888,
                        "pageviews": 25926,
                        "posts_published": 1190,
                        "ROA": 21.78655462184874,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 12761,
                        "pageviews": 20602,
                        "posts_published": 1332,
                        "ROA": 15.466966966966966,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 144,
                        "pageviews": 178,
                        "posts_published": 114,
                        "ROA": 1.5614035087719298,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 33,
                        "pageviews": 36,
                        "posts_published": 21,
                        "ROA": 1.7142857142857142,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-17",
                        "visitors": 116,
                        "pageviews": 149,
                        "posts_published": 102,
                        "ROA": 1.4607843137254901,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 3339,
                        "pageviews": 4082,
                        "posts_published": 1591,
                        "ROA": 2.5656819610307982,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 10992,
                        "pageviews": 17226,
                        "posts_published": 1300,
                        "ROA": 13.250769230769231,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 15296,
                        "pageviews": 19864,
                        "posts_published": 1184,
                        "ROA": 16.777027027027028,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 1307,
                        "pageviews": 1498,
                        "posts_published": 102,
                        "ROA": 14.686274509803921,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 18387,
                        "pageviews": 24907,
                        "posts_published": 555,
                        "ROA": 44.877477477477477,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 113,
                        "pageviews": 134,
                        "posts_published": 93,
                        "ROA": 1.4408602150537635,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 134,
                        "pageviews": 158,
                        "posts_published": 105,
                        "ROA": 1.5047619047619047,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-18",
                        "visitors": 35,
                        "pageviews": 41,
                        "posts_published": 18,
                        "ROA": 2.2777777777777777,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 4816,
                        "pageviews": 5852,
                        "posts_published": 1770,
                        "ROA": 3.3062146892655369,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 12068,
                        "pageviews": 18080,
                        "posts_published": 1445,
                        "ROA": 12.512110726643598,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 17655,
                        "pageviews": 22499,
                        "posts_published": 566,
                        "ROA": 39.75088339222615,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 11155,
                        "pageviews": 14379,
                        "posts_published": 1255,
                        "ROA": 11.457370517928286,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 136,
                        "pageviews": 175,
                        "posts_published": 98,
                        "ROA": 1.7857142857142858,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 594,
                        "pageviews": 690,
                        "posts_published": 110,
                        "ROA": 6.2727272727272725,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 110,
                        "pageviews": 146,
                        "posts_published": 88,
                        "ROA": 1.6590909090909092,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-19",
                        "visitors": 37,
                        "pageviews": 44,
                        "posts_published": 33,
                        "ROA": 1.3333333333333333,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 4370,
                        "pageviews": 5418,
                        "posts_published": 1776,
                        "ROA": 3.0506756756756759,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 11506,
                        "pageviews": 18247,
                        "posts_published": 1671,
                        "ROA": 10.919808497905446,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 11256,
                        "pageviews": 15590,
                        "posts_published": 1296,
                        "ROA": 12.029320987654321,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 15477,
                        "pageviews": 19807,
                        "posts_published": 559,
                        "ROA": 35.432915921288014,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 1006,
                        "pageviews": 1173,
                        "posts_published": 129,
                        "ROA": 9.0930232558139537,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 114,
                        "pageviews": 146,
                        "posts_published": 97,
                        "ROA": 1.5051546391752577,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 146,
                        "pageviews": 184,
                        "posts_published": 114,
                        "ROA": 1.6140350877192982,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-20",
                        "visitors": 28,
                        "pageviews": 33,
                        "posts_published": 22,
                        "ROA": 1.5,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 13650,
                        "pageviews": 18507,
                        "posts_published": 1236,
                        "ROA": 14.973300970873787,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 8319,
                        "pageviews": 11857,
                        "posts_published": 578,
                        "ROA": 20.513840830449826,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 4365,
                        "pageviews": 5430,
                        "posts_published": 1781,
                        "ROA": 3.0488489612577205,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 16536,
                        "pageviews": 25899,
                        "posts_published": 1824,
                        "ROA": 14.199013157894736,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 1617,
                        "pageviews": 1909,
                        "posts_published": 140,
                        "ROA": 13.635714285714286,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 152,
                        "pageviews": 200,
                        "posts_published": 114,
                        "ROA": 1.7543859649122806,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 123,
                        "pageviews": 170,
                        "posts_published": 105,
                        "ROA": 1.6190476190476191,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-21",
                        "visitors": 27,
                        "pageviews": 31,
                        "posts_published": 20,
                        "ROA": 1.55,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 5267,
                        "pageviews": 7638,
                        "posts_published": 732,
                        "ROA": 10.434426229508198,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 3514,
                        "pageviews": 4378,
                        "posts_published": 1856,
                        "ROA": 2.3588362068965516,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 15111,
                        "pageviews": 23548,
                        "posts_published": 1965,
                        "ROA": 11.983715012722646,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 16386,
                        "pageviews": 22993,
                        "posts_published": 1453,
                        "ROA": 15.824501032346868,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 44,
                        "pageviews": 56,
                        "posts_published": 39,
                        "ROA": 1.4358974358974359,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 910,
                        "pageviews": 1063,
                        "posts_published": 134,
                        "ROA": 7.9328358208955221,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 129,
                        "pageviews": 158,
                        "posts_published": 102,
                        "ROA": 1.5490196078431373,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-22",
                        "visitors": 156,
                        "pageviews": 188,
                        "posts_published": 115,
                        "ROA": 1.6347826086956523,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 8349,
                        "pageviews": 12341,
                        "posts_published": 515,
                        "ROA": 23.963106796116506,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 15042,
                        "pageviews": 20127,
                        "posts_published": 1162,
                        "ROA": 17.320998278829602,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 3204,
                        "pageviews": 3988,
                        "posts_published": 1625,
                        "ROA": 2.4541538461538464,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 13723,
                        "pageviews": 21714,
                        "posts_published": 1719,
                        "ROA": 12.631762652705062,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 108,
                        "pageviews": 142,
                        "posts_published": 92,
                        "ROA": 1.5434782608695652,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 136,
                        "pageviews": 173,
                        "posts_published": 104,
                        "ROA": 1.6634615384615385,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 811,
                        "pageviews": 970,
                        "posts_published": 119,
                        "ROA": 8.15126050420168,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-23",
                        "visitors": 40,
                        "pageviews": 47,
                        "posts_published": 32,
                        "ROA": 1.46875,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 5892,
                        "pageviews": 7199,
                        "posts_published": 1619,
                        "ROA": 4.4465719579987644,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 14552,
                        "pageviews": 23434,
                        "posts_published": 1484,
                        "ROA": 15.7911051212938,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 8757,
                        "pageviews": 11522,
                        "posts_published": 593,
                        "ROA": 19.430016863406408,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 14666,
                        "pageviews": 19536,
                        "posts_published": 1165,
                        "ROA": 16.76909871244635,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 460,
                        "pageviews": 554,
                        "posts_published": 103,
                        "ROA": 5.3786407766990294,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 102,
                        "pageviews": 149,
                        "posts_published": 86,
                        "ROA": 1.7325581395348837,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 126,
                        "pageviews": 174,
                        "posts_published": 101,
                        "ROA": 1.7227722772277227,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-24",
                        "visitors": 31,
                        "pageviews": 39,
                        "posts_published": 23,
                        "ROA": 1.6956521739130435,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 12779,
                        "pageviews": 18437,
                        "posts_published": 547,
                        "ROA": 33.705667276051187,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 14023,
                        "pageviews": 18695,
                        "posts_published": 1151,
                        "ROA": 16.242397914856646,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 4005,
                        "pageviews": 4861,
                        "posts_published": 1672,
                        "ROA": 2.9072966507177034,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 118,
                        "pageviews": 149,
                        "posts_published": 90,
                        "ROA": 1.6555555555555554,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 8304,
                        "pageviews": 13367,
                        "posts_published": 1337,
                        "ROA": 9.9977561705310389,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 179,
                        "pageviews": 216,
                        "posts_published": 95,
                        "ROA": 2.2736842105263158,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 95,
                        "pageviews": 125,
                        "posts_published": 81,
                        "ROA": 1.5432098765432098,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-25",
                        "visitors": 35,
                        "pageviews": 43,
                        "posts_published": 28,
                        "ROA": 1.5357142857142858,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 8258,
                        "pageviews": 11756,
                        "posts_published": 596,
                        "ROA": 19.7248322147651,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 3058,
                        "pageviews": 3764,
                        "posts_published": 1675,
                        "ROA": 2.2471641791044776,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 12474,
                        "pageviews": 18930,
                        "posts_published": 1516,
                        "ROA": 12.486807387862797,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 12760,
                        "pageviews": 16771,
                        "posts_published": 1165,
                        "ROA": 14.395708154506437,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 101,
                        "pageviews": 136,
                        "posts_published": 94,
                        "ROA": 1.446808510638298,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 267,
                        "pageviews": 316,
                        "posts_published": 111,
                        "ROA": 2.8468468468468466,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 135,
                        "pageviews": 174,
                        "posts_published": 102,
                        "ROA": 1.7058823529411764,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-26",
                        "visitors": 29,
                        "pageviews": 29,
                        "posts_published": 19,
                        "ROA": 1.5263157894736843,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 19205,
                        "pageviews": 30710,
                        "posts_published": 1851,
                        "ROA": 16.591031874662345,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 7215,
                        "pageviews": 9943,
                        "posts_published": 545,
                        "ROA": 18.244036697247708,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 10522,
                        "pageviews": 14339,
                        "posts_published": 1264,
                        "ROA": 11.344145569620252,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 3307,
                        "pageviews": 4167,
                        "posts_published": 1841,
                        "ROA": 2.2634437805540468,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 309,
                        "pageviews": 359,
                        "posts_published": 131,
                        "ROA": 2.7404580152671754,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 91,
                        "pageviews": 117,
                        "posts_published": 81,
                        "ROA": 1.4444444444444444,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 118,
                        "pageviews": 146,
                        "posts_published": 95,
                        "ROA": 1.5368421052631578,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-27",
                        "visitors": 31,
                        "pageviews": 42,
                        "posts_published": 32,
                        "ROA": 1.3125,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 3761,
                        "pageviews": 4632,
                        "posts_published": 1827,
                        "ROA": 2.535303776683087,
                        "content": "فن"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 19053,
                        "pageviews": 30350,
                        "posts_published": 1978,
                        "ROA": 15.343781597573306,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 6909,
                        "pageviews": 9516,
                        "posts_published": 569,
                        "ROA": 16.72407732864675,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 14637,
                        "pageviews": 20961,
                        "posts_published": 1183,
                        "ROA": 17.718512256973796,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 359,
                        "pageviews": 449,
                        "posts_published": 114,
                        "ROA": 3.93859649122807,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 127,
                        "pageviews": 152,
                        "posts_published": 92,
                        "ROA": 1.6521739130434783,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 102,
                        "pageviews": 126,
                        "posts_published": 81,
                        "ROA": 1.5555555555555556,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-02-28",
                        "visitors": 36,
                        "pageviews": 39,
                        "posts_published": 32,
                        "ROA": 1.21875,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 895,
                        "pageviews": 1024,
                        "posts_published": 113,
                        "ROA": 9.0619469026548671,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 20440,
                        "pageviews": 31544,
                        "posts_published": 1736,
                        "ROA": 18.170506912442395,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 12934,
                        "pageviews": 18058,
                        "posts_published": 1174,
                        "ROA": 15.381601362862011,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 3803,
                        "pageviews": 4595,
                        "posts_published": 1829,
                        "ROA": 2.5123018042646255,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 8268,
                        "pageviews": 11637,
                        "posts_published": 558,
                        "ROA": 20.85483870967742,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 107,
                        "pageviews": 138,
                        "posts_published": 92,
                        "ROA": 1.5,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 127,
                        "pageviews": 158,
                        "posts_published": 102,
                        "ROA": 1.5490196078431373,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-01",
                        "visitors": 26,
                        "pageviews": 28,
                        "posts_published": 23,
                        "ROA": 1.2173913043478262,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 3486,
                        "pageviews": 4252,
                        "posts_published": 1723,
                        "ROA": 2.4677887405687753,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 23861,
                        "pageviews": 37078,
                        "posts_published": 1763,
                        "ROA": 21.031196823596144,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 8692,
                        "pageviews": 12238,
                        "posts_published": 483,
                        "ROA": 25.337474120082817,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 289,
                        "pageviews": 367,
                        "posts_published": 99,
                        "ROA": 3.7070707070707072,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 36,
                        "pageviews": 46,
                        "posts_published": 36,
                        "ROA": 1.2777777777777777,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 11167,
                        "pageviews": 15198,
                        "posts_published": 1179,
                        "ROA": 12.89058524173028,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 87,
                        "pageviews": 106,
                        "posts_published": 75,
                        "ROA": 1.4133333333333333,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-02",
                        "visitors": 104,
                        "pageviews": 126,
                        "posts_published": 82,
                        "ROA": 1.5365853658536586,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 6106,
                        "pageviews": 8296,
                        "posts_published": 541,
                        "ROA": 15.33456561922366,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 18324,
                        "pageviews": 29359,
                        "posts_published": 1555,
                        "ROA": 18.880385852090033,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 9334,
                        "pageviews": 13804,
                        "posts_published": 1264,
                        "ROA": 10.920886075949367,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 3624,
                        "pageviews": 4434,
                        "posts_published": 1749,
                        "ROA": 2.53516295025729,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 283,
                        "pageviews": 330,
                        "posts_published": 119,
                        "ROA": 2.7731092436974789,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 114,
                        "pageviews": 147,
                        "posts_published": 96,
                        "ROA": 1.53125,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 133,
                        "pageviews": 172,
                        "posts_published": 106,
                        "ROA": 1.6226415094339623,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-03",
                        "visitors": 31,
                        "pageviews": 35,
                        "posts_published": 17,
                        "ROA": 2.0588235294117645,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 4807,
                        "pageviews": 6996,
                        "posts_published": 501,
                        "ROA": 13.964071856287426,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 3802,
                        "pageviews": 4545,
                        "posts_published": 1664,
                        "ROA": 2.7313701923076925,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 10783,
                        "pageviews": 17080,
                        "posts_published": 1284,
                        "ROA": 13.302180685358255,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 7813,
                        "pageviews": 10842,
                        "posts_published": 1189,
                        "ROA": 9.1185870479394442,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 602,
                        "pageviews": 687,
                        "posts_published": 108,
                        "ROA": 6.3611111111111107,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 124,
                        "pageviews": 148,
                        "posts_published": 89,
                        "ROA": 1.6629213483146068,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 92,
                        "pageviews": 112,
                        "posts_published": 77,
                        "ROA": 1.4545454545454546,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-04",
                        "visitors": 29,
                        "pageviews": 31,
                        "posts_published": 21,
                        "ROA": 1.4761904761904763,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 6861,
                        "pageviews": 10172,
                        "posts_published": 560,
                        "ROA": 18.164285714285715,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 7294,
                        "pageviews": 8796,
                        "posts_published": 1762,
                        "ROA": 4.99205448354143,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 10022,
                        "pageviews": 14068,
                        "posts_published": 1233,
                        "ROA": 11.409570154095702,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 9638,
                        "pageviews": 14571,
                        "posts_published": 1506,
                        "ROA": 9.6752988047808763,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 34,
                        "pageviews": 36,
                        "posts_published": 28,
                        "ROA": 1.2857142857142858,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 314,
                        "pageviews": 379,
                        "posts_published": 94,
                        "ROA": 4.0319148936170217,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 104,
                        "pageviews": 155,
                        "posts_published": 101,
                        "ROA": 1.5346534653465347,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-05",
                        "visitors": 124,
                        "pageviews": 180,
                        "posts_published": 112,
                        "ROA": 1.6071428571428572,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 5407,
                        "pageviews": 6647,
                        "posts_published": 1649,
                        "ROA": 4.0309278350515463,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 5221,
                        "pageviews": 7611,
                        "posts_published": 547,
                        "ROA": 13.914076782449726,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 15678,
                        "pageviews": 22365,
                        "posts_published": 1265,
                        "ROA": 17.679841897233203,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 11103,
                        "pageviews": 18985,
                        "posts_published": 1737,
                        "ROA": 10.929763960852044,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 229,
                        "pageviews": 284,
                        "posts_published": 111,
                        "ROA": 2.5585585585585586,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 85,
                        "pageviews": 116,
                        "posts_published": 75,
                        "ROA": 1.5466666666666666,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 37,
                        "pageviews": 39,
                        "posts_published": 28,
                        "ROA": 1.3928571428571428,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-06",
                        "visitors": 119,
                        "pageviews": 151,
                        "posts_published": 89,
                        "ROA": 1.696629213483146,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 3477,
                        "pageviews": 4314,
                        "posts_published": 1712,
                        "ROA": 2.5198598130841123,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 15575,
                        "pageviews": 21306,
                        "posts_published": 1258,
                        "ROA": 16.936406995230524,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 15156,
                        "pageviews": 24273,
                        "posts_published": 1779,
                        "ROA": 13.644182124789207,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 5568,
                        "pageviews": 7830,
                        "posts_published": 586,
                        "ROA": 13.361774744027304,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 435,
                        "pageviews": 490,
                        "posts_published": 125,
                        "ROA": 3.92,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 63,
                        "pageviews": 84,
                        "posts_published": 64,
                        "ROA": 1.3125,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 110,
                        "pageviews": 136,
                        "posts_published": 77,
                        "ROA": 1.7662337662337662,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-07",
                        "visitors": 25,
                        "pageviews": 31,
                        "posts_published": 22,
                        "ROA": 1.4090909090909092,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 8757,
                        "pageviews": 13207,
                        "posts_published": 502,
                        "ROA": 26.308764940239044,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 5243,
                        "pageviews": 6288,
                        "posts_published": 1773,
                        "ROA": 3.5465313028764807,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 14336,
                        "pageviews": 22283,
                        "posts_published": 1810,
                        "ROA": 12.311049723756906,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 13516,
                        "pageviews": 18379,
                        "posts_published": 1197,
                        "ROA": 15.35421888053467,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 559,
                        "pageviews": 644,
                        "posts_published": 114,
                        "ROA": 5.6491228070175437,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 109,
                        "pageviews": 159,
                        "posts_published": 79,
                        "ROA": 2.0126582278481013,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 78,
                        "pageviews": 124,
                        "posts_published": 75,
                        "ROA": 1.6533333333333333,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-08",
                        "visitors": 21,
                        "pageviews": 21,
                        "posts_published": 16,
                        "ROA": 1.3125,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 4114,
                        "pageviews": 5113,
                        "posts_published": 1563,
                        "ROA": 3.2712731925783749,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 12124,
                        "pageviews": 18829,
                        "posts_published": 1692,
                        "ROA": 11.128250591016549,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 6135,
                        "pageviews": 8789,
                        "posts_published": 563,
                        "ROA": 15.61101243339254,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 38,
                        "pageviews": 41,
                        "posts_published": 24,
                        "ROA": 1.7083333333333333,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 12571,
                        "pageviews": 17488,
                        "posts_published": 1206,
                        "ROA": 14.500829187396352,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 102,
                        "pageviews": 134,
                        "posts_published": 76,
                        "ROA": 1.763157894736842,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 74,
                        "pageviews": 102,
                        "posts_published": 66,
                        "ROA": 1.5454545454545454,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-09",
                        "visitors": 932,
                        "pageviews": 1191,
                        "posts_published": 112,
                        "ROA": 10.633928571428571,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 20254,
                        "pageviews": 31682,
                        "posts_published": 1501,
                        "ROA": 21.1072618254497,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 3114,
                        "pageviews": 3848,
                        "posts_published": 1538,
                        "ROA": 2.5019505851755528,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 5140,
                        "pageviews": 7212,
                        "posts_published": 567,
                        "ROA": 12.71957671957672,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 13866,
                        "pageviews": 18663,
                        "posts_published": 1265,
                        "ROA": 14.753359683794466,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 26,
                        "pageviews": 28,
                        "posts_published": 18,
                        "ROA": 1.5555555555555556,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 3376,
                        "pageviews": 3891,
                        "posts_published": 102,
                        "ROA": 38.147058823529413,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 124,
                        "pageviews": 139,
                        "posts_published": 106,
                        "ROA": 1.3113207547169812,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-10",
                        "visitors": 150,
                        "pageviews": 170,
                        "posts_published": 116,
                        "ROA": 1.4655172413793103,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 3223,
                        "pageviews": 4293,
                        "posts_published": 576,
                        "ROA": 7.453125,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 1134,
                        "pageviews": 1297,
                        "posts_published": 118,
                        "ROA": 10.991525423728813,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 11386,
                        "pageviews": 15718,
                        "posts_published": 1327,
                        "ROA": 11.844762622456669,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 3268,
                        "pageviews": 3850,
                        "posts_published": 1704,
                        "ROA": 2.2593896713615025,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 15063,
                        "pageviews": 22168,
                        "posts_published": 1448,
                        "ROA": 15.30939226519337,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 128,
                        "pageviews": 166,
                        "posts_published": 125,
                        "ROA": 1.328,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 149,
                        "pageviews": 191,
                        "posts_published": 134,
                        "ROA": 1.4253731343283582,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-11",
                        "visitors": 31,
                        "pageviews": 34,
                        "posts_published": 21,
                        "ROA": 1.6190476190476191,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 12189,
                        "pageviews": 18183,
                        "posts_published": 1332,
                        "ROA": 13.6509009009009,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 9438,
                        "pageviews": 13390,
                        "posts_published": 1187,
                        "ROA": 11.280539174389217,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2487,
                        "pageviews": 3513,
                        "posts_published": 540,
                        "ROA": 6.5055555555555555,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 5426,
                        "pageviews": 6686,
                        "posts_published": 1626,
                        "ROA": 4.1119311193111932,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 106,
                        "pageviews": 140,
                        "posts_published": 87,
                        "ROA": 1.6091954022988506,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 2743,
                        "pageviews": 3210,
                        "posts_published": 109,
                        "ROA": 29.449541284403669,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 83,
                        "pageviews": 115,
                        "posts_published": 75,
                        "ROA": 1.5333333333333334,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-12",
                        "visitors": 33,
                        "pageviews": 36,
                        "posts_published": 23,
                        "ROA": 1.5652173913043479,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 4544,
                        "pageviews": 5881,
                        "posts_published": 1728,
                        "ROA": 3.4033564814814814,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 15336,
                        "pageviews": 24431,
                        "posts_published": 1785,
                        "ROA": 13.686834733893557,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 2940,
                        "pageviews": 4134,
                        "posts_published": 517,
                        "ROA": 7.9961315280464218,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 10078,
                        "pageviews": 14160,
                        "posts_published": 1353,
                        "ROA": 10.465631929046562,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 993,
                        "pageviews": 1160,
                        "posts_published": 140,
                        "ROA": 8.2857142857142865,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 118,
                        "pageviews": 144,
                        "posts_published": 93,
                        "ROA": 1.5483870967741935,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 86,
                        "pageviews": 112,
                        "posts_published": 77,
                        "ROA": 1.4545454545454546,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-13",
                        "visitors": 29,
                        "pageviews": 38,
                        "posts_published": 26,
                        "ROA": 1.4615384615384615,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 4004,
                        "pageviews": 5700,
                        "posts_published": 568,
                        "ROA": 10.035211267605634,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 3105,
                        "pageviews": 3843,
                        "posts_published": 1799,
                        "ROA": 2.1361867704280155,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 17051,
                        "pageviews": 26260,
                        "posts_published": 1837,
                        "ROA": 14.295046271094176,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 10197,
                        "pageviews": 14871,
                        "posts_published": 1291,
                        "ROA": 11.518977536793184,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 23,
                        "pageviews": 28,
                        "posts_published": 20,
                        "ROA": 1.4,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 1372,
                        "pageviews": 1691,
                        "posts_published": 129,
                        "ROA": 13.108527131782946,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 107,
                        "pageviews": 133,
                        "posts_published": 74,
                        "ROA": 1.7972972972972974,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-14",
                        "visitors": 76,
                        "pageviews": 101,
                        "posts_published": 61,
                        "ROA": 1.6557377049180328,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 484,
                        "pageviews": 596,
                        "posts_published": 136,
                        "ROA": 4.382352941176471,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4375,
                        "pageviews": 6224,
                        "posts_published": 519,
                        "ROA": 11.992292870905588,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 4359,
                        "pageviews": 5333,
                        "posts_published": 1809,
                        "ROA": 2.9480375898286346,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 17516,
                        "pageviews": 27432,
                        "posts_published": 1792,
                        "ROA": 15.308035714285714,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 14384,
                        "pageviews": 19409,
                        "posts_published": 1315,
                        "ROA": 14.759695817490494,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 72,
                        "pageviews": 93,
                        "posts_published": 62,
                        "ROA": 1.5,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 90,
                        "pageviews": 112,
                        "posts_published": 74,
                        "ROA": 1.5135135135135136,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-15",
                        "visitors": 39,
                        "pageviews": 48,
                        "posts_published": 34,
                        "ROA": 1.411764705882353,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 6026,
                        "pageviews": 8644,
                        "posts_published": 528,
                        "ROA": 16.371212121212121,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 3204,
                        "pageviews": 4026,
                        "posts_published": 1668,
                        "ROA": 2.4136690647482015,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 12741,
                        "pageviews": 20459,
                        "posts_published": 1699,
                        "ROA": 12.041789287816362,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 22313,
                        "pageviews": 29287,
                        "posts_published": 1187,
                        "ROA": 24.67312552653749,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 2906,
                        "pageviews": 3484,
                        "posts_published": 134,
                        "ROA": 26,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 97,
                        "pageviews": 124,
                        "posts_published": 74,
                        "ROA": 1.6756756756756757,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 72,
                        "pageviews": 97,
                        "posts_published": 61,
                        "ROA": 1.5901639344262295,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-16",
                        "visitors": 32,
                        "pageviews": 47,
                        "posts_published": 27,
                        "ROA": 1.7407407407407407,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 3083,
                        "pageviews": 3805,
                        "posts_published": 1578,
                        "ROA": 2.41128010139417,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 10973,
                        "pageviews": 16946,
                        "posts_published": 1454,
                        "ROA": 11.65474552957359,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 6190,
                        "pageviews": 8588,
                        "posts_published": 514,
                        "ROA": 16.708171206225682,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 20247,
                        "pageviews": 26624,
                        "posts_published": 1367,
                        "ROA": 19.476225310899782,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 90,
                        "pageviews": 112,
                        "posts_published": 70,
                        "ROA": 1.6,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 1357,
                        "pageviews": 1588,
                        "posts_published": 115,
                        "ROA": 13.808695652173913,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 72,
                        "pageviews": 86,
                        "posts_published": 60,
                        "ROA": 1.4333333333333333,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-17",
                        "visitors": 24,
                        "pageviews": 27,
                        "posts_published": 15,
                        "ROA": 1.8,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 8201,
                        "pageviews": 13118,
                        "posts_published": 1328,
                        "ROA": 9.87801204819277,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 3128,
                        "pageviews": 3840,
                        "posts_published": 1665,
                        "ROA": 2.3063063063063063,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 12911,
                        "pageviews": 16961,
                        "posts_published": 1249,
                        "ROA": 13.579663730984787,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 5379,
                        "pageviews": 7447,
                        "posts_published": 508,
                        "ROA": 14.659448818897637,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 104,
                        "pageviews": 124,
                        "posts_published": 89,
                        "ROA": 1.3932584269662922,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 29,
                        "pageviews": 31,
                        "posts_published": 24,
                        "ROA": 1.2916666666666667,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 572,
                        "pageviews": 662,
                        "posts_published": 97,
                        "ROA": 6.8247422680412368,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-18",
                        "visitors": 77,
                        "pageviews": 93,
                        "posts_published": 74,
                        "ROA": 1.2567567567567568,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 5251,
                        "pageviews": 6271,
                        "posts_published": 1659,
                        "ROA": 3.7799879445449065,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 8271,
                        "pageviews": 11519,
                        "posts_published": 1274,
                        "ROA": 9.04160125588697,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 945,
                        "pageviews": 1087,
                        "posts_published": 111,
                        "ROA": 9.7927927927927936,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 12622,
                        "pageviews": 20561,
                        "posts_published": 1468,
                        "ROA": 14.006130790190735,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 77,
                        "pageviews": 98,
                        "posts_published": 67,
                        "ROA": 1.4626865671641791,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 4354,
                        "pageviews": 6027,
                        "posts_published": 512,
                        "ROA": 11.771484375,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 100,
                        "pageviews": 127,
                        "posts_published": 78,
                        "ROA": 1.6282051282051282,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-19",
                        "visitors": 35,
                        "pageviews": 46,
                        "posts_published": 24,
                        "ROA": 1.9166666666666667,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 10759,
                        "pageviews": 17843,
                        "posts_published": 1684,
                        "ROA": 10.595605700712589,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 4169,
                        "pageviews": 5945,
                        "posts_published": 482,
                        "ROA": 12.334024896265561,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 409,
                        "pageviews": 516,
                        "posts_published": 132,
                        "ROA": 3.9090909090909092,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 13918,
                        "pageviews": 17962,
                        "posts_published": 1308,
                        "ROA": 13.732415902140673,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 5641,
                        "pageviews": 7050,
                        "posts_published": 1694,
                        "ROA": 4.1617473435655254,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 97,
                        "pageviews": 127,
                        "posts_published": 73,
                        "ROA": 1.7397260273972603,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 67,
                        "pageviews": 95,
                        "posts_published": 61,
                        "ROA": 1.5573770491803278,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-20",
                        "visitors": 31,
                        "pageviews": 38,
                        "posts_published": 29,
                        "ROA": 1.3103448275862069,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 4262,
                        "pageviews": 5275,
                        "posts_published": 1546,
                        "ROA": 3.4120310478654594,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 14998,
                        "pageviews": 24423,
                        "posts_published": 1667,
                        "ROA": 14.650869826034793,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 2863,
                        "pageviews": 4016,
                        "posts_published": 486,
                        "ROA": 8.2633744855967084,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 25758,
                        "pageviews": 31202,
                        "posts_published": 1160,
                        "ROA": 26.898275862068967,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 25,
                        "pageviews": 44,
                        "posts_published": 29,
                        "ROA": 1.5172413793103448,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 95,
                        "pageviews": 123,
                        "posts_published": 79,
                        "ROA": 1.5569620253164558,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 1037,
                        "pageviews": 1262,
                        "posts_published": 120,
                        "ROA": 10.516666666666667,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-21",
                        "visitors": 68,
                        "pageviews": 94,
                        "posts_published": 67,
                        "ROA": 1.4029850746268657,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 13581,
                        "pageviews": 21344,
                        "posts_published": 1622,
                        "ROA": 13.159062885326756,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 14426,
                        "pageviews": 18815,
                        "posts_published": 1147,
                        "ROA": 16.403661726242373,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 7710,
                        "pageviews": 8985,
                        "posts_published": 1344,
                        "ROA": 6.6852678571428568,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 3057,
                        "pageviews": 4359,
                        "posts_published": 461,
                        "ROA": 9.4555314533622568,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 752,
                        "pageviews": 886,
                        "posts_published": 118,
                        "ROA": 7.5084745762711869,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 54,
                        "pageviews": 74,
                        "posts_published": 48,
                        "ROA": 1.5416666666666667,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 29,
                        "pageviews": 37,
                        "posts_published": 28,
                        "ROA": 1.3214285714285714,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-22",
                        "visitors": 60,
                        "pageviews": 81,
                        "posts_published": 52,
                        "ROA": 1.5576923076923077,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 5857,
                        "pageviews": 6991,
                        "posts_published": 1332,
                        "ROA": 5.2484984984984981,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 32582,
                        "pageviews": 48034,
                        "posts_published": 1582,
                        "ROA": 30.36283185840708,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 13199,
                        "pageviews": 16896,
                        "posts_published": 1095,
                        "ROA": 15.43013698630137,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 616,
                        "pageviews": 763,
                        "posts_published": 117,
                        "ROA": 6.5213675213675213,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 4875,
                        "pageviews": 6722,
                        "posts_published": 472,
                        "ROA": 14.241525423728813,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 87,
                        "pageviews": 111,
                        "posts_published": 73,
                        "ROA": 1.5205479452054795,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 70,
                        "pageviews": 91,
                        "posts_published": 66,
                        "ROA": 1.3787878787878789,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-23",
                        "visitors": 25,
                        "pageviews": 31,
                        "posts_published": 20,
                        "ROA": 1.55,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 3287,
                        "pageviews": 4489,
                        "posts_published": 437,
                        "ROA": 10.272311212814646,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 16822,
                        "pageviews": 26753,
                        "posts_published": 1248,
                        "ROA": 21.436698717948719,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 10969,
                        "pageviews": 14641,
                        "posts_published": 1086,
                        "ROA": 13.481583793738491,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 4002,
                        "pageviews": 4880,
                        "posts_published": 1276,
                        "ROA": 3.8244514106583072,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 965,
                        "pageviews": 1145,
                        "posts_published": 96,
                        "ROA": 11.927083333333334,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 61,
                        "pageviews": 82,
                        "posts_published": 58,
                        "ROA": 1.4137931034482758,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 34,
                        "pageviews": 44,
                        "posts_published": 27,
                        "ROA": 1.6296296296296295,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-24",
                        "visitors": 75,
                        "pageviews": 99,
                        "posts_published": 64,
                        "ROA": 1.546875,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 3728,
                        "pageviews": 5226,
                        "posts_published": 399,
                        "ROA": 13.097744360902256,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 6528,
                        "pageviews": 9628,
                        "posts_published": 873,
                        "ROA": 11.028636884306987,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 8022,
                        "pageviews": 11374,
                        "posts_published": 870,
                        "ROA": 13.073563218390804,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 2160,
                        "pageviews": 2647,
                        "posts_published": 877,
                        "ROA": 3.0182440136830104,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 47,
                        "pageviews": 70,
                        "posts_published": 46,
                        "ROA": 1.5217391304347827,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 912,
                        "pageviews": 1015,
                        "posts_published": 62,
                        "ROA": 16.370967741935484,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 59,
                        "pageviews": 82,
                        "posts_published": 52,
                        "ROA": 1.5769230769230769,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-25",
                        "visitors": 18,
                        "pageviews": 25,
                        "posts_published": 18,
                        "ROA": 1.3888888888888888,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 16417,
                        "pageviews": 20459,
                        "posts_published": 1146,
                        "ROA": 17.852530541012218,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 4096,
                        "pageviews": 4969,
                        "posts_published": 1522,
                        "ROA": 3.2647831800262814,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 8502,
                        "pageviews": 14186,
                        "posts_published": 1218,
                        "ROA": 11.64696223316913,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 4122,
                        "pageviews": 5744,
                        "posts_published": 484,
                        "ROA": 11.867768595041323,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 41,
                        "pageviews": 46,
                        "posts_published": 32,
                        "ROA": 1.4375,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 346,
                        "pageviews": 411,
                        "posts_published": 93,
                        "ROA": 4.419354838709677,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 76,
                        "pageviews": 97,
                        "posts_published": 66,
                        "ROA": 1.4696969696969697,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-26",
                        "visitors": 56,
                        "pageviews": 75,
                        "posts_published": 54,
                        "ROA": 1.3888888888888888,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 5525,
                        "pageviews": 6962,
                        "posts_published": 1493,
                        "ROA": 4.6630944407233761,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 8375,
                        "pageviews": 14118,
                        "posts_published": 1450,
                        "ROA": 9.73655172413793,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 17923,
                        "pageviews": 21810,
                        "posts_published": 1165,
                        "ROA": 18.721030042918454,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 3905,
                        "pageviews": 5372,
                        "posts_published": 501,
                        "ROA": 10.722554890219561,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 97,
                        "pageviews": 108,
                        "posts_published": 78,
                        "ROA": 1.3846153846153846,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 2345,
                        "pageviews": 2815,
                        "posts_published": 148,
                        "ROA": 19.02027027027027,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 70,
                        "pageviews": 80,
                        "posts_published": 64,
                        "ROA": 1.25,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-27",
                        "visitors": 36,
                        "pageviews": 42,
                        "posts_published": 25,
                        "ROA": 1.68,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 10678,
                        "pageviews": 16612,
                        "posts_published": 1477,
                        "ROA": 11.247122545700744,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 42,
                        "pageviews": 58,
                        "posts_published": 41,
                        "ROA": 1.4146341463414633,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 4809,
                        "pageviews": 5897,
                        "posts_published": 1440,
                        "ROA": 4.0951388888888891,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 3073,
                        "pageviews": 4504,
                        "posts_published": 460,
                        "ROA": 9.7913043478260864,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 15846,
                        "pageviews": 19448,
                        "posts_published": 1112,
                        "ROA": 17.489208633093526,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 76,
                        "pageviews": 100,
                        "posts_published": 65,
                        "ROA": 1.5384615384615385,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 58,
                        "pageviews": 80,
                        "posts_published": 57,
                        "ROA": 1.4035087719298245,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-28",
                        "visitors": 1951,
                        "pageviews": 2315,
                        "posts_published": 146,
                        "ROA": 15.856164383561644,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 19432,
                        "pageviews": 23902,
                        "posts_published": 1220,
                        "ROA": 19.591803278688523,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 4097,
                        "pageviews": 5048,
                        "posts_published": 1480,
                        "ROA": 3.4108108108108106,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 13914,
                        "pageviews": 25626,
                        "posts_published": 1482,
                        "ROA": 17.291497975708502,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 5608,
                        "pageviews": 8035,
                        "posts_published": 451,
                        "ROA": 17.815964523281597,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 913,
                        "pageviews": 1105,
                        "posts_published": 145,
                        "ROA": 7.6206896551724137,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 81,
                        "pageviews": 114,
                        "posts_published": 76,
                        "ROA": 1.5,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 60,
                        "pageviews": 88,
                        "posts_published": 63,
                        "ROA": 1.3968253968253967,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-29",
                        "visitors": 29,
                        "pageviews": 35,
                        "posts_published": 25,
                        "ROA": 1.4,
                        "content": "منوعات"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 15379,
                        "pageviews": 25181,
                        "posts_published": 1470,
                        "ROA": 17.129931972789116,
                        "content": "الإمارات"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 3804,
                        "pageviews": 5495,
                        "posts_published": 475,
                        "ROA": 11.56842105263158,
                        "content": "أخبار"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 4656,
                        "pageviews": 5683,
                        "posts_published": 1430,
                        "ROA": 3.9741258741258743,
                        "content": "فن"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 27650,
                        "pageviews": 32960,
                        "posts_published": 1088,
                        "ROA": 30.294117647058822,
                        "content": "رياضة"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 1079,
                        "pageviews": 1301,
                        "posts_published": 132,
                        "ROA": 9.8560606060606055,
                        "content": "اقتصاد"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 87,
                        "pageviews": 103,
                        "posts_published": 72,
                        "ROA": 1.4305555555555556,
                        "content": "مشاهير"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 64,
                        "pageviews": 74,
                        "posts_published": 61,
                        "ROA": 1.2131147540983607,
                        "content": "سياسة"
                    },
                    {
                        "date": "2023-03-30",
                        "visitors": 34,
                        "pageviews": 36,
                        "posts_published": 27,
                        "ROA": 1.3333333333333333,
                        "content": "منوعات"
                    }
                ],
                "api": "/content-grouping-charts"
            }
        }
    },

    getContentGroupsTimeSpent: async () => {
        const url = apiUrl + `newsletter-posts-performance`;

        return {
            data: {
                "data": [
                    {
                        "contentGroup": "الإمارات",
                        "timeSpent":4200 ,
                        "cxImpact":13.4

                    },
                    {
                        "contentGroup": "أخبار",
                        "timeSpent": 5100,
                        "cxImpact":21.2

                    },
                    {
                        "contentGroup": "رياضة",
                        "timeSpent": 4400,
                        "cxImpact":16.7

                    },
                    {
                        "contentGroup": "فن",
                        "timeSpent":3900 ,
                        "cxImpact":23.3

                    },
                    {
                        "contentGroup": "منوعات",
                        "timeSpent": 2300,
                        "cxImpact":9.8

                    },
                    {
                        "contentGroup": "اقتصاد",
                        "timeSpent": 3200,
                        "cxImpact":14.2

                    },
                    {
                        "contentGroup": "سياسة",
                        "timeSpent": 2900,
                        "cxImpact":15.9
                    },
                    {
                        "contentGroup": "مشاهير",
                        "timeSpent": 3600,
                        "cxImpact":19.4
                    },
                ],
                "api": "/content-grouping-time-spent"
            }
        }
    }
}
export const utitlityApi = {

}

export default contentGroupingAnalysisData;