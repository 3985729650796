import {
    SET_USER_ACTION_TYPE,
    FETCH_USERS,
    FETCH_USER_ITEM,
    UPDATE_USER,
    UPDATE_ERR_USER,
    CREATE_USER,
    CREATE_ERR_USER,
    DELETE_USER,
    DELETE_ERR_USER,
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_USER_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_USERS:
            return { ...state, List: action.payload };
        case FETCH_USER_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_USER:
            return { ...state, update: action.payload };
        case UPDATE_ERR_USER:
            return { ...state, updateErr: action.payload };
        case CREATE_USER:
            return { ...state, create: action.payload };
        case CREATE_ERR_USER:
            return { ...state, createErr: action.payload };
        case DELETE_USER:
            return { ...state, delete: action.payload };
        case DELETE_ERR_USER:
            return { ...state, deleteErr: action.payload };     
        default:
            return state;
    }
}