import React from "react";

const CXCardStatus = ({isActive}) => {
    return (
        <div
            className={`cx-card-status cx-card-status-${
                isActive ? "active" : "not-active"
            }`}
        >
            {isActive ? "Active" : "Inactive"}
        </div>
    );
};

export default CXCardStatus;
