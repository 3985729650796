import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import { getNewsletterPostsPerformance } from "../newletterAnalysisAPI"
import '../newsletterAnalysis.scss'

const NewsletterWidgetPostsPerformance = () => {
    const { data, isLoading } = useQuery(['get-newsletter-posts-performance'], getNewsletterPostsPerformance, {
        refetchOnWindowFocus: false
    });
    
    const getData = () => {
        if (!data) return [];
        return data;
    }
    let row = getData()
    return (
        <TableContainer sx= {{width:"700px", backgroundColor: '#32334d', border:1}} component={Paper}>
          <Table sx={{ minWidth: 650, color:"#ffffff"}} aria-label="simple table">
            <TableHead>
              <TableRow > 
                <TableCell sx={{color:"#ffffff"}} >Post Title</TableCell>
                <TableCell sx={{color:"#ffffff"}} align="right">Email Impressions</TableCell>
                <TableCell sx={{color:"#ffffff"}} align="right">Email Clicks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.map((row) => (
                <TableRow 
                  key={row.title}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <a href={row.url} style={{ display: 'flex', alignItems: 'center'}}>
                        <img height="40px" style={{ paddingRight: '10px' }} src={row.thumb} />
                        {row.title}
                    </a>
                  </TableCell>
                  <TableCell sx={{color:"#ffffff"}} align="right">{row.impressions}</TableCell>
                  <TableCell sx={{color:"#ffffff"}} align="right">{row.clicks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

export default NewsletterWidgetPostsPerformance;