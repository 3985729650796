import _auth from "./auth";
import apiUrl from '../helpers/global';

const auth = new _auth();

const segmentApi = {


}

export default segmentApi;