import React from 'react';
import {CXInputField, CXSelectField} from "../CXComponents"
import {useQuery} from "react-query";
import {getSegments} from "../views/Segmentation/segmentAPI";



const TargetingForm = ({control, register,segments, contentGList}) => {
console.log(contentGList)
    return (
        <div className='targeting-form'>
            <section>
                <div className="info">
                    <div className="title"> Behavioral </div>
                    <div className="desc"> What behavior will trigger this item to appear? </div>
                </div>
                <div className="fields">
                    <CXInputField 
                        register={register}
                        label="Scroll Depth"
                        name="targeting.behavioral.scrollDepth"
                        placeholder={"How far should the user scroll down for the item to appear? (0 - 100)"}
                        type="number"
                        className={"targeting-field-column"}
                        />
                </div>
            </section>
            <section>
                <div className="info">
                    <div className="title"> Contextual targeting </div>
                    <div className="desc"> In what context you want this item to appear? </div>
                </div>
                <div className="fields">
                    <CXInputField 
                        register={register}
                        label="Article URL"
                        name="targeting.contextual.articleUrl"
                        placeholder={"In what article page you want this item to appear?"}
                        className={"targeting-field-column"}
                    />
                    <CXInputField 
                        register={register}
                        label="Category"
                        name="targeting.contextual.category"
                        placeholder={"In what category(article pages in this category) you want this item to appear?"}
                        className={"targeting-field-column"}
                    />
                    <CXSelectField
                    {...{
                        control,
                        label: "Content Grouping (In what content group you want this item to appear)",
                        required: true,
                        name: "contentGrouping",
                        options: contentGList.map((c) => ({
                            value: c.id,
                            label: c.title,
                        })),
                    }}

                    />
                </div>
            </section>
            <section>
                <div className="info">
                    <div className="title"> Demographics </div>
                    <div className="desc"> What are the required demographics of the user for whom you want this item to appear? </div>
                </div>
                <div className="fields">
                    <CXInputField 
                        register={register}
                        label="Country"
                        name="targeting.demographics.location"
                        placeholder={"Where are the users that you want this item to appear to"}
                        className={"targeting-field-column"}
                    />
                    <CXInputField 
                        register={register}
                        label="Device Type"
                        name="targeting.demographics.device"
                        placeholder={"What is the device that you want this item to appear on"}
                        className={"targeting-field-column"}
                    />
                </div>
            </section>
            <section>
                <div className="info">
                    <div className="title"> Segmentation </div>
                    <div className="desc"> What segment of users you want this item to appear? </div>
                </div>
                <div className="fields">
                <CXSelectField
                    {...{
                        control,
                        label: "Segment",
                        required: true,
                        name: "segmentation",
                        options: segments.map((seg) => ({
                            value: seg.id,
                            label: seg.name,
                        })),
                    }}
                    />
                </div>
            </section>
        </div>
    );
}

export default TargetingForm;
