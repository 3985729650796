import {
    SET_AUDIT_ACTION_TYPE,
    FETCH_AUDITS,
    FETCH_AUDIT_ITEM,
    UPDATE_AUDIT,
    UPDATE_ERR_AUDIT,
    CREATE_AUDIT,
    CREATE_ERR_AUDIT,
    DELETE_AUDIT,
    DELETE_ERR_AUDIT
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_AUDIT_ACTION_TYPE:
            return { ...state, actionType: action.payload };
        case FETCH_AUDITS:
            return { ...state, List: action.payload };      
        case FETCH_AUDIT_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_AUDIT:
            return { ...state, update: action.payload };
        case UPDATE_ERR_AUDIT:
            return { ...state, updateErr: action.payload };
        case CREATE_AUDIT:
            return { ...state, create: action.payload };
        case CREATE_ERR_AUDIT:
            return { ...state, createErr: action.payload };
        case DELETE_AUDIT:
            return { ...state, delete: action.payload };
        case DELETE_ERR_AUDIT:
            return { ...state, deleteErr: action.payload };
        default:
            return state;
    }
}