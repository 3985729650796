import React from "react";
import { Route, Switch } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import routesConf from "../../routes";
import logo from "assets/img/logo.png";
import mainHelper from "../../helpers/main";
import { CheckExist } from "../Auth/Auth";

const helper = new mainHelper();
let routes = routesConf["routes"];

// REFACTOR NEEDED mini sidebar not functional - website not responsive

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeColor: "default",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }

  componentDidMount() {
    helper.initScroller("mainPanel", this.refs.mainPanel);
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    window.addEventListener("scroll", this.showNavbarButton);

    // set menu state
    // if(localStorage.menuOpen && localStorage.menuOpen !== 'false'){
    document.body.classList.toggle("sidebar-mini");
    // }
  }

  componentWillUnmount() {
    helper.destroyScroller("mainPanel");
    window.removeEventListener("scroll", this.showNavbarButton);
  }

  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ){
      this.setState({ opacity: 1 });
    }
    else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ){
      this.setState({ opacity: 0 });
    }
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        let Comp = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => (
              <Comp {...props} {...prop.componentProp} isAuthed={true} />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        let cond1 =
            routes[i].withParams &&
            routes[i].withParams === true &&
            window.location.pathname.indexOf(
              routes[i].layout + routes[i].path.split("/:")[0]
            ) > -1,
          cond2 =
            window.location.pathname.indexOf(
              routes[i].layout + routes[i].path
            ) !== -1;
        if (cond1 || cond2) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
    if (!localStorage.menuOpen) localStorage.menuOpen = false;
    localStorage.menuOpen = !JSON.parse(localStorage.menuOpen);
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };

  render() {
    console.log(this.props)
    return (
      <div className="wrapper">
        <CheckExist />
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          activeColor={this.state.activeColor}
          logo={{
            outterLink: "https://cognativex.com/",
            text: "CognativeX",
            imgSrc: logo,
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          id={"cx-main-body-panel"}
          ref="mainPanel"
          data={this.state.activeColor}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}     // RAFECTOR NEEDED ? for what is this?
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

export default Admin;
