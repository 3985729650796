import React from "react";
import {Popover, OverlayTrigger } from 'react-bootstrap';

class Tooltip extends React.Component {

  constructor(props) {
    super(props);
  }

  renderTooltip = () => {
    return (
      <Popover className={"cx-tooltip-content"}>
        {(this.props.title)?
          <Popover.Title as="h3">{this.props.title}</Popover.Title>
          : ''}
        <Popover.Content>
          {this.props.text || 'Please enter tooltip text'}
        </Popover.Content>
      </Popover>
    );
  }

  render() {
    return (
      <div className={'cx-tooltip'}>
          <OverlayTrigger
            trigger={this.props.action || 'hover'}
            placement={this.props.placement || 'top'}
            overlay={this.renderTooltip(this.props.placement || 'top', this.props.text)}
            ref={"reactBootstrapTooltip"}
          >
            {this.props.children?
              this.props.children
              :
              <i className={'cx-info fa fa-info-circle cx-bluegray-color cursor-pointer'}/>
            }
          </OverlayTrigger>
       </div>
    );
  }
}



export default Tooltip;
