import api from "api/contentGroupingAnalysisCharts.js";

export const getContentGroupingPageviews = async () => {
    return api.getContentGroupingPageviews()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}

export const getContentGroupingTimeSpent = async() => {
    return api.getContentGroupsTimeSpent()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            throw (error);
        });
}