import React, { useState, useEffect } from "react";
import CxLoader from "../components/cxLoader";
import { useQuery } from "react-query";
import PopupNew from "../components/popup_new";
import useDigestReportSettingStore from "./DigestReportSettingStore";

import {
    getDigestReportSettingList,
    getPublishers,
    getDigestReportCharts,
    loadPublisher
} from "./DigestReportSettingAPI";
import DigestReportingCard from "./Components/DigestReportingCard";
import DigestReportSettingForm from "./Components/DigestReportForm"



function DigestReportsList(){
    const { isLoading, isFetching, data } = useQuery(["digest-report-setting-list"], ()=>{
        return getDigestReportSettingList();
    }, { refetchOnWindowFocus: false });

    const getPublishersQuery = useQuery(["publisher-list"], ()=>{
        return getPublishers();
    }, { refetchOnWindowFocus: false });

    const getPublisherListByOrganization = useQuery(["publisher"], ()=>{
        return loadPublisher();
    },{ refetchOnWindowFocus: false })

    const getChartsQuery = useQuery(["digest-report-charts"], ()=>{
        return getDigestReportCharts();
    }, { refetchOnWindowFocus: false });

    const isOpen = useDigestReportSettingStore(state=>state.isOpen);
    const setIsOpen = useDigestReportSettingStore(state=>state.setIsOpen);
    const setActionType = useDigestReportSettingStore(state=>state.setActionType);
    const actionType = useDigestReportSettingStore(state=>state.actionType);

    return(
        <>
            <div className="content">
                <div>
                    <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Publisher Report Setting</h4>
                    <br/>
                </div>
                <br />
                {
                    (!isLoading && !isFetching && !getPublishersQuery.isLoading && !getChartsQuery.isLoading)
                        ? <>
                        <div className="cx-btn btn-blue-gradient" onClick={()=>{setActionType('create'); setIsOpen(true)}}>
                        <i className="fa fa-plus"></i> Schedule A New Report
                    </div>
                    <br />
                            <a style={{marginLeft:"10px"}} href="https://reporting.cognativex.com/reports/5133271398612992" target="_blank">Digest Report Demo</a>
                            <br/>
                        <br/>
                        <div className="DigestReportsList" style={{display:"flex", gap:"15px", flexWrap: 'wrap'}}>
                            {
                                data &&
                                data.map((reportSetting, i)=>{
                                    return (
                                        <DigestReportingCard
                                            key={i}
                                            id={reportSetting.id}
                                            title={reportSetting.title}
                                            schedule={reportSetting.schedule}
                                            status={reportSetting.isActive ? 'success' : 'failure'}
                                        />
                                    )
                                })
                            }
                            <PopupNew title={(actionType === 'create' ? "Create" : "Edit") + " Digest Report"} width={'40%'} isPopupOpen={isOpen} togglePopup={()=>setIsOpen(false)}>
                                <DigestReportSettingForm publisherList={[getPublisherListByOrganization.data]} chartsList = {getChartsQuery.data}/>
                            </PopupNew>
                        </div></>
                        : <CxLoader minHeight="80vh"/>
                }
            </div>
        </>
    )
}

export default DigestReportsList;