import React from 'react';
import EvergreenTopPosts from './components/EvergreenTopPosts';
import {Grid} from "@mui/material";
import EvergreenTotals from './components/EvergreenTotals'
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useQuery} from "react-query";
import {sectionEvergreenQuery} from "./evergreenAnalysisAPI";

const EvergreenAnalysis = () => {
    const data = useQuery(['section-ever-green'], sectionEvergreenQuery,{
        refetchOnWindowFocus: false
    })
    const getData = () => {
        if (!data.data) return [];
        return data.data;
    }
    let row = getData();

    const SectionTable = () => {
        return (
            <TableContainer sx={{width: "700px", margin: 'auto', backgroundColor: '#32334d', border: 1}}
                            component={Paper}>
                <Table sx={{minWidth: 650, color: "#ffffff"}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color: "#ffffff"}}>Section</TableCell>
                            <TableCell sx={{color: "#ffffff"}} align="right">Evergreen Page Views </TableCell>
                            <TableCell sx={{color: "#ffffff"}} align="right">Evergreen Page Views percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {row.map((row) => (
                            <TableRow
                                key={row.title}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell sx={{color: "#ffffff"}} align="left">{row.section}</TableCell>
                                <TableCell sx={{color: "#ffffff"}} align="center">{row.pageview}</TableCell>
                                <TableCell sx={{color: "#ffffff"}} align="center">{row.evergreenp}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
    return (
        <div className="content">
            <h4 style={{ "marginBottom": "20px" }}><i className="fa layer-group"></i>&nbsp; Evergreen Analysis</h4>
            <Grid container>
                <Grid item md={12}>
                    <EvergreenTotals/>
                </Grid>
                <Grid md={12} mt={5}>
                    <h4>Top evergreen posts</h4>
                </Grid>
                <Grid item md={12}>
                    <EvergreenTopPosts/>
                </Grid>
                <Grid item md={12} ml={13} mt={5}>
                    <h4>Section Evergreen Analysis</h4>
                </Grid>
                <Grid item md={12}>
                    <SectionTable/>
                </Grid>
            </Grid>
        </div>
        
    );
}

export default EvergreenAnalysis;
