import React, { useEffect, useState } from 'react';
import {useWatch, useController} from 'react-hook-form';
import {useQuery} from "react-query";
import CxLoader from '../../components/cxLoader';
import {getUserCount} from "../segmentAPI";
import useSegmentStore from '../segmentStore';

const UsersMeter = ({control, name}) => {

    const itemToLoad = useSegmentStore(state=>state.itemToLoad);
    const { field: { value, onChange } } = useController({
        name,
        control,
        rules: { required: true },
    });
    const dimensionsValue = useWatch({
        control,
        name: 'dimensions'
    });

    const usersCountQuery = useQuery(['users-count', itemToLoad, dimensionsValue], ({queryKey})=>{
        const dimensionsValue = queryKey[2];
        const mapped = dimensionsValue?.map(d=>{
            if(!d.operator.value) return d;
            d.operator=d.operator.value;
            if(!Array.isArray(d.value)) d.value = [d.value];
            return d
        })
        return getUserCount(mapped);
    }, {refetchOnWindowFocus: false, enabled: !!dimensionsValue?.length})

    useEffect(() => {
        onChange(usersCountQuery?.data?.percentage === undefined ? 100 : parseFloat(usersCountQuery?.data?.percentage));
    }, [usersCountQuery?.data?.percentage]);

    return (
        <div className="user-meter-wrapper">
            <label>User Meter: &nbsp;&nbsp;</label>
            {
                usersCountQuery.isLoading || usersCountQuery.isFetching
                ? <CxLoader inPlace="true"/> 
                : <div className="user-meter-bar">
                        <span className="user-meter-bar__progress" style={{width: value+"%"}}></span>
                        <span className="user-meter-bar__percentage">{value}%</span>
                  </div>
            }
        </div>
    );
}

export default UsersMeter;

