import axios from 'axios';
import mainHelper from '../helpers/main';

let helper = new mainHelper();
const CRAWLER_URL = 'https://crawler.cognativex.com/api/';

class IntegrationApi { 


    crawlJsonLd = async (url) => {
        return await axios.get(`${CRAWLER_URL}crawljsonld?url=${url}`).catch(function (error) {
            console.log(error.toJSON());
          });;
    } 

    crawlMetadata = async (url) => {
        return await axios.get(`${CRAWLER_URL}crawlmetadata?url=${url}`).catch(function (error) {
            console.log(error.toJSON());
          });;
    } 

    checkTracker = async () => {
        return await axios.get(`${CRAWLER_URL}checktracker?appdomain=${helper.getOrganizationName()}`).catch(function (error) {
            console.log(error.toJSON());
          });;
    }      
}

export default new IntegrationApi();
