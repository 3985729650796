import React, {useRef, useEffect, useState} from 'react';
import main from '../helpers/main';
import {CXConfirm} from "../CXComponents"
const helper = new main();

const CXDrawer = ({ isOpen, closeDrawer, title, children, width, className, isDirty }) => {

    const [isDirtyFormOpen, setIsDirtyFormOpen] = useState(false);

    // utility
    function closeDrawerFromPopup(){
        setIsDirtyFormOpen(false);
        animateClosing();
    }
    const drawerRef = useRef();

    const animateClosing = () => {
        drawerRef.current.style.animation='slide-drawer-out 500ms forwards';
        setTimeout(closeDrawer, 500);
    }

    useEffect(() => {
        helper.disableScrollPage(true);
        return ()=>{helper.disableScrollPage(false)}
    }, []);

    return (
        <>
        <div className={`cx-drawer-wrapper ${!isOpen?'d-none':''}`}>
            <div className="cx-overlay"></div>
            <div ref={drawerRef} className={`cx-drawer ${className??''}`} style={{width}}>
                {
                    title &&
                    <div className="cx-drawer__header">{title}</div>
                }
                <div className="cx-drawer__body">
                    {children}
                </div>
                <div onClick={()=>isDirty ? setIsDirtyFormOpen(true) : animateClosing()} className={"cx-drawer-close-btn"}>
                    <i className="fa fa-arrow-right"></i>
                </div>
            </div>
        </div>
        {
            isDirtyFormOpen && isDirty &&
            <CXConfirm
                {...{
                    title: "Discard Changes?",
                    subtitle: "You will lose your changes! Are you sure you want to close the form?",
                    confirmAction: closeDrawerFromPopup,
                    cancelAction: ()=>setIsDirtyFormOpen(false)
                }}
            />
        }
        </>
    );
}

export default CXDrawer;
