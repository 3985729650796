import {
    SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE,
    FETCH_CAMPAIGN_CATEGORY_MAPPINGS,
    FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM,
    UPDATE_CAMPAIGN_CATEGORY_MAPPING,
    UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING,
    CREATE_CAMPAIGN_CATEGORY_MAPPING,
    CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING,
    DELETE_CAMPAIGN_CATEGORY_MAPPING,
    DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING,
} from "../constants/appConstant";
import api from '../api/campaignCategoryMapping';

/*
 * action creators
 */
export const setCampaignCategoryMappingActionType  = (data) => ({ type: SET_CAMPAIGN_CATEGORY_MAPPING_ACTION_TYPE, payload: data});
const getListEvent = (data) => ({ type: FETCH_CAMPAIGN_CATEGORY_MAPPINGS, payload: data});
export const getItemEvent = (data) => ({ type: FETCH_CAMPAIGN_CATEGORY_MAPPING_ITEM, payload: data });
export const updateEvent = (updated) => ({ type: UPDATE_CAMPAIGN_CATEGORY_MAPPING, payload: updated });
export const updateErrEvent = (err) => ({ type: UPDATE_ERR_CAMPAIGN_CATEGORY_MAPPING, payload: err });
export const createEvent = (created) => ({ type: CREATE_CAMPAIGN_CATEGORY_MAPPING, payload: created });
export const createErrEvent = (err) => ({ type: CREATE_ERR_CAMPAIGN_CATEGORY_MAPPING, payload: err });
export const deleteEvent = (deleted) => ({ type: DELETE_CAMPAIGN_CATEGORY_MAPPING, payload: deleted });
export const deleteErrEvent = (err) => ({ type: DELETE_ERR_CAMPAIGN_CATEGORY_MAPPING, payload: err });

export const getList = () => {
    return (dispatch) => {
        return api.getList()
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getListEvent(res))
            })
            .catch(error => {
                dispatch(getListEvent(null))
                throw (error);
            });
    };
};

export const getItem = (data) => {
    return (dispatch) => {
        return api.getItem(data)
            .then(response => {
                let res = null;
                if (response && response.data && response.data.data)
                    res = response.data.data;
                dispatch(getItemEvent(res))
            })
            .catch(error => {
                throw (error);
            });
    };
};


export const update = (data) => {
    return (dispatch) => {
        return api.updateItem(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(updateEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(updateErrEvent('Error'));
                throw (error);
            });
    };
}

export const create = (data) => {
    return (dispatch) => {
        return api.create(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(createEvent(res))
                dispatch(updateErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(createErrEvent('Error'));
                throw (error);
            });
    };
}

export const deleteItem = (data) => {
    return (dispatch) => {
        return api.deleteItem(data)
            .then(response => {
                let res = null;
                if (response && response.data)
                    res = response.data;
                dispatch(deleteEvent(res))
                dispatch(deleteErrEvent(undefined));
            })
            .catch(error => {
                if(error && error.response && error.response.data)
                    dispatch(deleteErrEvent('Error'));
                throw (error);
            });
    };
}
