import React, { Component } from "react";

import { connect } from "react-redux";
import { Card, CardHeader, CardBody } from "../../components/Card/card";
import { popupActions } from "../../actions";
import Dialog from "@material-ui/core/Dialog";
class Popup extends Component {
    componentDidMount() {
        this.props.togglePopup(false);
        if (this.props.isPopupOpened) {
            document.getElementsByClassName("ps")[0].style.overflow = "";
        }
    }
    componentWillUnmount() {}

    render() {
        return (
            <Dialog
                open={this.props.isPopupOpened}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div
                    style={{
                        maxWidth: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    className={`cx-popup${this.props.isPopupOpened ? " open" : ""}`}
                >
                    <div style={{ maxWidth: "820px", zIndex: 1000, maxHeight: "100vh", width: this.props.width }}>
                        <Card>
                            <CardHeader>
                                {this.props.title}
                                <span className={"close-btn"} onClick={()=>this.props.togglePopup(false)}>
                                   <i className={"fa fa-times"}></i>
                                </span>
                            </CardHeader>
                            <CardBody>{this.props.children}</CardBody>
                        </Card>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    // get the props needed for this component from the state.
    return {
        isPopupOpened:
            state.customPopup && state.customPopup.isPopupOpened
                ? state.customPopup.isPopupOpened
                : false,
    };
};

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        togglePopup: (action) => dispatch(popupActions.togglePopup(action)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
