import {FETCH_CATEGORIES_LIST, FETCH_CATEGORIES_OPTIONS} from '../constants/appConstant';
export default function (state = {} , action) {
    switch(action.type) {
        case FETCH_CATEGORIES_LIST:
            return { ...state, categories: action.payload };
        case FETCH_CATEGORIES_OPTIONS:
            return { ...state, categoriesSelect: action.payload };
        default:
            return state;
    }
}