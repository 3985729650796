import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    classificationActions,
} from '../../../actions';
import _ from "lodash";

import AdvancedFilter from "../../components/advancedFilter";

class PostsAdvancedFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount(page) {
        if(this.props.classMapping)
            this.props.getFilters(this.props.classMapping);
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.classMapping, prevProps.classMapping)) {
            this.props.getFilters(this.props.classMapping);
        }
      }
    filterAdd = (action) => {
        this.props.addToFilter(action);
    }

    filterRemove = (action) => {
        this.props.removeFromFilter(action)
    }

    render() {
        return (
            <AdvancedFilter
                list={this.props.filters}
                selected={this.props.selectedFilter}
                onRemove={this.filterRemove}
                onAdd={this.filterAdd}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: (state.classificationInsight && state.classificationInsight.pageFilterList)? state.classificationInsight.pageFilterList : [],
        selectedFilter: (state.classificationInsight && state.classificationInsight.pageFilters)? state.classificationInsight.pageFilters : []
    }
}

// what events will occur that will change the state in this component
const mapDispatchToProps = (dispatch) => {
    return {
        getFilters: (classMapping) => dispatch(classificationActions.fetchClassesFilters(classMapping)),
        addToFilter: (newFilter) => dispatch(classificationActions.addClassesFilter(newFilter)),
        removeFromFilter: (filter) => dispatch(classificationActions.removeClassesFilter(filter)),
    };
};

// export and connect data to store
export default connect(mapStateToProps, mapDispatchToProps)(PostsAdvancedFilter);