import {
    SET_PUBLISHER_ACTION_TYPE,
    FETCH_PUBLISHERS,
    FETCH_PUBLISHER_ITEM,
    UPDATE_PUBLISHER,
    UPDATE_ERR_PUBLISHER,
    CREATE_PUBLISHER,
    CREATE_ERR_PUBLISHER,
    DELETE_PUBLISHER,
    DELETE_ERR_PUBLISHER,
} from '../constants/appConstant';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_PUBLISHER_ACTION_TYPE:
            return {...state, actionType: action.payload};
        case FETCH_PUBLISHERS:
            return { ...state, List: action.payload };
        case FETCH_PUBLISHER_ITEM:
            return { ...state, Item: action.payload };
        case UPDATE_PUBLISHER:
            return { ...state, update: action.payload };
        case UPDATE_ERR_PUBLISHER:
            return { ...state, updateErr: action.payload };
        case CREATE_PUBLISHER:
            return { ...state, create: action.payload };
        case CREATE_ERR_PUBLISHER:
            return { ...state, createErr: action.payload };
        case DELETE_PUBLISHER:
            return { ...state, delete: action.payload };
        case DELETE_ERR_PUBLISHER:
            return { ...state, deleteErr: action.payload };     
        default:
            return state;
    }
}