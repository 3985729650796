import React  ,{ useState }from 'react';
import CXPill from '../../../CXComponents/CXPill';
import { useMutation, useQueryClient } from "react-query";
import useUserStore from './UserStore';
import {useForm} from 'react-hook-form';
import {CXSelect, CXMultiSelect, CXConfirm, CXAdvancedSelect} from '../../../CXComponents';
import { createPermission, updatePermission, deletePermissions, refetchList } from "./userAPI";
import CxLoader from '../../components/cxLoader';

const PermissionTableRow = ({row, organizations, roles}) => {
    const [isEditingRow, setIsEditingRow] = useState(false);
    const [isDeletePermissionPopupOpen, setIsDeletePermissionPopupOpen] = useState(false);

    const {control, handleSubmit} = useForm({
        defaultValues: {
            organization: {id: row?.organizationId, name: row?.organizationName},
            roleList: row?.roleList
        }
    });
    const editPermissionQuery = useMutation((data)=>{
        return updatePermission(data)
    });
    const deletePermissionQuery = useMutation((ids) => {
        return deletePermissions(ids);
    })

    const {organizationName, rolesNames, id} = row;
    const itemToLoad = useUserStore((state) => state.itemToLoad);
    const qc = useQueryClient();

    const handleDeletePermission = async (ids) => {
        setIsDeletePermissionPopupOpen(true);
    }

    const onSubmit = async (values) => {
        const mapped = {...values};
        mapped.organizationId = mapped.organization.id;
        mapped.organization = null;
        mapped.user = null;
        mapped.userId = itemToLoad;
        mapped.id = id

        await editPermissionQuery.mutateAsync(mapped);
        setIsEditingRow(false);
        await refetchList(qc, itemToLoad);
    }

    const renderEditingForm = () => {
        return ( 
            <form className='editPermissionForm' onSubmit={handleSubmit(onSubmit)}>
                <div className="permissionsTable__permissionRow">
                    <CXSelect 
                        {...{
                            control,
                            name: "organization",
                            dislayProp: "name",
                            defaultDisplay: "Select Organization",
                            options: organizations.map(org => ({label: org.name, value: org}))
                        }}
                    />
                    <CXMultiSelect 
                        {...{
                            control,
                            name: "roleList",
                            defaultDisplay: "Select Roles",
                            options: roles.map(role => ({label: role.name, value: role.id}))
                        }}
                    />
                    
                    {
                        (deletePermissionQuery.isLoading || editPermissionQuery.isLoading)
                        ? <CxLoader inPlace={true}/>
                        : <div className='editPermissionForm__actions'>
                            <button type="button" className='addPermissionForm__submitBtn cx-btn' onClick={handleSubmit(onSubmit)}><i className="fa fa-check"></i></button>
                            <button type='button' className='addPermissionForm__cancleBtn cx-btn' onClick={()=>setIsEditingRow(false)}> <i className="fa fa-times"></i> </button>
                        </div>
                    }
                    </div>
            </form>
    )}
    
    const renderRowDisplay = () => {
        return (
            <div className="permissionsTable__permissionRow">
                <div className="permissionsTable__permissionRow__organization">
                    {organizationName}
                </div>
                <div className="permissionsTable__permissionRow__roles">
                    {
                        rolesNames.map((role, i)=>{
                            return (
                                <span key={i}>
                                    <CXPill
                                        text={role}
                                    />
                                </span>
                            )
                        })
                    }
                </div>
                <div className="PermissionRow__actions">
                    <button type="button" className='permissionsRow__editBtn cx-icon-btn' onClick={()=>setIsEditingRow(true)}><i className="fa fa-pen"></i></button>
                    <button type='button' className='permissionsRow__deleteBtn cx-icon-btn' onClick={()=>handleDeletePermission([id])}> <i className="fa fa-trash"></i> </button>
                </div>
            </div>
    )}


    return (
        <>
        {
            isDeletePermissionPopupOpen &&
            <CXConfirm 
                {...{
                    width: '400px',
                    title: "Delete Permission",
                    subtitle: "Are you sure you want to permanently delete this permission?",
                    confirmAction: async ()=>{
                        await deletePermissionQuery.mutateAsync([id]);
                        setIsDeletePermissionPopupOpen(false);
                        await refetchList(qc, itemToLoad);
                    },
                    cancelAction: ()=>{
                        setIsDeletePermissionPopupOpen(false);
                    }
                }}
            />
        }
        {
            isEditingRow
            ? renderEditingForm()
            : renderRowDisplay()
            
        }
            
        </>
        
    )
}


const PermissionsTable = ({permissionsList, organizations, roles}) => {

    return (
        <>
            {
                (permissionsList.length > 0) && 
                <div className="permissionsTable">
                    <div className="permissionsTable__permissionRow">
                        <div className="permissionsTable__permissionRow__organization__header">
                            Organization
                        </div>
                    
                        <div className="permissionsTable__permissionRow__roles__header">
                            Roles                    
                        </div>
                        <div className="permissionsTable__permissionRow__roles__header">
                            Actions                   
                        </div>
                    </div>
                    {permissionsList.map((permissions, i)=>{
                        return (
                            <PermissionTableRow
                                key={i}
                                row={permissions}
                                organizations={organizations}
                                roles={roles}
                            />
                        )
                    })}
                </div>
            }
        </>
    )

}


const AddPermissionForm = ({organizations, roles, setIsAddingPermission}) => {

    const {control, handleSubmit } = useForm();
    const itemToLoad = useUserStore((state) => state.itemToLoad);
    const qc = useQueryClient();


    const createPermissionQuery = useMutation((permission) => {
        return createPermission(permission);
      });

    const onSubmit = async (values) => {
        let mapped = {...values};
        mapped.organizationId = mapped.organization.value.id;
        mapped.organization = null;
        mapped.user = null;
        mapped.userId = itemToLoad;
        await createPermissionQuery.mutateAsync(mapped);
        setIsAddingPermission(false);
        await refetchList(qc, itemToLoad);
    }
    return (
        <div className='addPermissionArea'>
            <h4>Add Permission</h4>
            <form className='addPermissionForm' onSubmit={handleSubmit(onSubmit)}>
                <div className="CXFormGroup">
                    <label>Organization </label>
                    <CXAdvancedSelect
                        {...{
                            control,
                            name: "organization",
                            dislayProp: "label",
                            defaultDisplay: "Select Organization",
                            options: organizations.map(org => ({label: org.name, value: org})),
                        }}
                    />
                </div>
                <div className="CXFormGroup">
                    <label>Roles</label>
                    <CXMultiSelect 
                        {...{
                            control,
                            name: "roleList",
                            defaultDisplay: "Select Roles",
                            options: roles.map(role => ({label: role.name, value: role.id})),
                        }}
                    />
                </div>
                
                <div className="addPermissionForm__actions">
                    {
                        createPermissionQuery.isLoading
                        ? <CxLoader inPlace={true}/>
                        : <>
                            <button type="button" className='addPermissionForm__submitBtn cx-btn' onClick={handleSubmit(onSubmit)}>Save</button>
                            <button type='button' className='addPermissionForm__cancleBtn cx-btn' onClick={()=>{setIsAddingPermission(false)}}>cancel</button>
                        </>
                    }
                </div>
            </form>
        </div>
    )
}


const Permissions = ({data, organizations, roles}) => {
    const [isAddingPermission, setIsAddingPermission] = useState(false);
    
    const addPermission = () => {
        setIsAddingPermission(true);
    }

    return (
        <>

        {
            (isAddingPermission)
            ? <AddPermissionForm 
                organizations={organizations}
                roles={roles}
                setIsAddingPermission={setIsAddingPermission}
            />
            : <button className='cx-add-permission-btn cx-btn' onClick={addPermission}>Add Permission</button>
        }
        {
            data && data.userPermission && 
            <PermissionsTable 
                {...{
                    permissionsList: data.userPermission || [],
                    organizations: organizations || [],
                    roles: roles || [],
                }}
            />
        }
        <br />
        <br />

        </>
    );
}


export default Permissions;