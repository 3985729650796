import React from 'react'
import Highcharts from 'highcharts'
import mainHelper from "../../../../helpers/main";
import HighchartsReact from 'highcharts-react-official'
import { useQuery } from 'react-query';
import { getNewsletterPerformance } from "../newletterAnalysisAPI"
import '../newsletterAnalysis.scss'

let helpers = new mainHelper();

const NewsletterWidgetPerformance = () => {

    const { data, isLoading } = useQuery(['get-newsletter-performance'], getNewsletterPerformance, {
        refetchOnWindowFocus: false
    });

    const getData = () => {
        if (!data) return [];
        let clicks = [],
            impressions = []

        for (let i in data) {
            let item = data[i],
                date = Date.parse(item.date),
                item_clicks = item.clicks,
                item_impressions = item.impressions;
            clicks.push([date, item_clicks]);
            impressions.push([date, item_impressions]);
        }

        return [
            {
                yAxis: 0, dataLabels: { enabled: false },
                type: 'areaspline', name: "Email Clicks", color: '#ff8d72', data: clicks, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                yAxis: 0, fillOpacity: 0.3, "dataLabels": { "enabled": false },
                type: 'areaspline', name: "Email Impressions", color: '#d4d4d8', data: impressions, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            }
        ];
    }
    const options = {
        "title": { "text": "" },
        "subtitle": { "text": "" },
        "exporting": { "enabled": false },
        "chart": {
            "type": "spline", "polar": false, "backgroundColor": "#32334d", "height": 318, "width": 1000,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                style: {
                    color: '#aeaeae'
                }
            },
            'minPadding': 0
        },
        credits: {
            enabled: false
        },

        "yAxis": [
            {
                "title": { "text": "Clicks" },
                "labels": {
                    "formatter": function () {
                        return helpers.formatNumber(this.value);
                    },
                    "style": {
                        "color": '#aeaeae'
                    }
                },
                "gridLineColor": 'rgba(255,255,255,0.1)'
            },
            {
                "title": { "text": "Impressions" }, opposite: true,
                "labels": {
                    "formatter": function () {
                        return helpers.formatNumber(this.value);
                    },
                    "style": {
                        "color": '#aeaeae'
                    }
                },
                "gridLineColor": 'rgba(255,255,255,0.1)'
            }
        ],

        "tooltip": {
            "shared": true,
            "borderWidth": 1,
            "formatter": function () {
                return this.points.reduce(function (s, point) {
                    if (point.series.name === "Clicks") {
                        return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',') + ' <b>' + point.point.cx_impact + '%</b>';
                    }
                    return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
                }, '<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) + '</div>');
            }
        },

        "plotOptions": {
            areaspline: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#666666'
                }
            },
            
        },
        "legend": {
            "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
                "color": '#fff'
            }
        },

        series: getData()
    }


    return (
        <div className='custom-highcharts-patch '>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}


export default NewsletterWidgetPerformance;