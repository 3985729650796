import {
    FETCH_REGION_LIST,
    FETCH_REGION_SELECT_OPTIONS,
    FETCH_LOCATION_LIST,
    FETCH_LOCATION_SELECT_OPTIONS,
    FETCH_LANG_LIST
} from '../constants/appConstant';

export default function (state = {} , action) {
    switch(action.type) {
        case FETCH_REGION_LIST:
            return { ...state, regions: action.payload };
        case FETCH_REGION_SELECT_OPTIONS:
            return { ...state, regionsSelect: action.payload };
        case FETCH_LOCATION_LIST:
            return { ...state, locations: action.payload };
        case FETCH_LOCATION_SELECT_OPTIONS:
            return { ...state, locationsSelect: action.payload };
        case FETCH_LANG_LIST:
            return { ...state, languageSelect: action.payload };
        default:
            return state;
    }
}