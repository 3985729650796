import auth from './auth';
import apiUrl from '../helpers/global';
import mainHelper from '../helpers/main';
import axios from "axios";
let helper = new mainHelper();

/* Helper Class
* To request campaign details and save other campaign details.
* */
class campaignDetails {

  
  
  details = {};

  constructor(props) {
    this.details = props;
    this.auth = new auth();
  }

  getCategories = async (id) => {
    return this.auth.request.post(apiUrl + `/static`, { data: JSON.stringify({ name: "categories" }) });
  }

  getIABCategories = async (id) => {
    return this.auth.request.post(apiUrl + `/static`, { data: JSON.stringify({ name: "IABCategories" }) });
  }


  imageUpload = async (data, file, progress=()=>{}) => {
    const url = apiUrl + `/image-upload`,
      formData = new FormData(),
      config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          progress(percentCompleted);
        },
      };
    formData.append('data', JSON.stringify({ mimeType: data.mimeType, adId: data.adId, folderName: "adsImages" }));
    formData.append('organization', helper.getOrganization());
    formData.append('file', file);
    return this.auth.request.upload(url, formData, config);
  }

  getCampaign = async (id) => {
    return this.auth.request.post(apiUrl + '/campaign-read', { data: JSON.stringify({ id }) });
  }

  deleteCampaign = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-delete', { data: JSON.stringify(data) });
  }

  duplicateCampaign = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-duplicate', { data: JSON.stringify(data) });
  }

  updateCampaign = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-update', { data: JSON.stringify(data) });
  }

  publishCampaign = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-publish', { data: JSON.stringify(data) });
  }

  updateCampaignStatus = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-turn-on-off', { data: JSON.stringify({ id: data.id, isTurnedOn: !data.isTurnedOn }) });
  }

  getCampaigns = async (queryStr) => {
    return this.auth.request.post(apiUrl + '/campaign-list');
  }

  quickCreateCampaign = async (data) => {
    return this.auth.request.post(apiUrl + `/campaign-quick-create`, { data: JSON.stringify(data) });
  }

  fetchAllToPublish = async () => {
    // return this.auth.request.post(apiUrl + `/campaign-quick-create`, { data: JSON.stringify(data) });
    return this.auth.request.post(`${apiUrl}/campaign-publish-list`);
  }

  publishAll = async (data) => {
    return this.auth.request.post(apiUrl + `/campaign-publish-list-save`, { data: JSON.stringify(data) });
  }

  getCampTree = async (campaignIds) => {
    return this.auth.request.post(apiUrl + '/campaign-tree',
      { data: JSON.stringify({ campaignIds: campaignIds || [] }) });
  }

  campaignReportTotals = async  (data) => {
    return this.auth.request.post(apiUrl + '/campaign-performance-totals', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  campaignReportPerformance = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-performance-by-day', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  campaignReportLocation = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-performance-by-location', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  campaignReportDevices = async (data) => {
    return this.auth.request.post(apiUrl + '/campaign-performance-by-device', {
      data: JSON.stringify({
        id: data.id,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp
      })
    });
  }

  getCampaignFiltersList = async (data) => {
    // return this.auth.request.post(apiUrl + '/filter-campaign');
    return await axios.get(this.baseUrl + '/filter-campaign');
  }
}

export default campaignDetails;
