import userAPI from '../../../api/usersManageApi';
import permissionsAPI from '../../../api/userpermission';
import organizationAPI from '../../../api/organization'
import roleAPI from '../../../api/role'
import { useQueryClient } from 'react-query';


export const fetchList = async () => {
    return userAPI.getList()
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data)
                res = response.data.data;
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const loadUser = async (id) => {
    return userAPI.getItem({id: id})
        .then(response => {
            let res = null;
            if (response && response.data && response.data.data){
                res = response.data.data;
            }
            return res;
        })
        .catch(error => {
            throw (error);
        });
}

export const createUser = async (mapped) => {
    return userAPI.create(mapped)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            return (error);
        });
}

export const editUser = async (data) => {
    console.log(data)
    return userAPI.updateItem(data)
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}

export const deleteUser = async (ids) => {
    return userAPI.delete({ids: ids})
        .then(response => {
            if (response && response.data)
                return response;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}

export const readUserPermissions = async (id) => {
    return userAPI.readUserPermissions({id: id})
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
                console.log(error.response.data)
            throw (error);
        });
}

export const createPermission = async (data) => {
    return permissionsAPI.create(data)
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error.response.data);
        });
}

export const updatePermission = async (data) => {
    return permissionsAPI.updateItem(data)
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}

export const deletePermissions = async (ids) => {
    return permissionsAPI.delete({ids: ids})
        .then(response => {
            if (response && response.data)
                return response.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}



// utitlity


export const getOrganizations = async () => {
    return organizationAPI.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}

export const getRoles = async () => {
    return roleAPI.getList()
        .then(response => {
            if (response && response.data && response.data.data)
                return response.data.data;
        })
        .catch(error => {
            if(error && error.response && error.response.data)
            throw (error);
        });
}



export const refetchList = async (qc, itemToRefetchListFor) => {
    qc.invalidateQueries(["user-read-permission", itemToRefetchListFor]);
}
