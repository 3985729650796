import React from 'react';
import filtersIcon from "../../../assets/img/filters-icon.svg"
import {CXAdvancedSelect, CXInputField} from "../../../CXComponents"
import {useQuery} from "react-query"
import {getAppDomains, getAllClassesMapping} from "../contentGroupingAPI";
import CxLoader from '../../components/cxLoader';
import useContentGroupingStore from '../contentGroupingStore';


const Filters = ({control, register, getValues, setValue}) => {

    const ES_AppDomainQuery = useQuery(['getappdomains'], () => {
        return getAppDomains();
    }, {refetchOnWindowFocus: false});
    const ES_GetAllClassesMappingQuery = useQuery(['getallclassesmapping'], () => {
        return getAllClassesMapping();
    }, {refetchOnWindowFocus: false})

    const setFilter = useContentGroupingStore(state=>state.setFilter);



    const applyFilters = () => {
      const [domainFilter, classFilter, textFilter] = getValues(['domainFilter', 'classFilter', 'textFilter']);
      setFilter({domainFilter, classFilter, textFilter});
    }

    const resetFilters = () => {
      setFilter({
        domainFilter: '',
        classFilter: '',
        textFilter: '',
      });
      setValue("domainFilter", "");
      setValue("classFilter", "");
      setValue("textFilter", "");
    }


    return (
        <>
        {
            ES_AppDomainQuery.isLoading || ES_GetAllClassesMappingQuery.isLoading
            ? <CxLoader />
            :<div className='pool-filters'>
                <div className="filters-title">
                    <img src={filtersIcon} alt="icon" />
                    Filters
                </div>
                <div className="filters-body">
                    <div className="elastic-search-filters">
                        <div className='es-filter-group'>
                            <label>Domain</label>
                            <CXAdvancedSelect
                                {...{
                                    control,
                                    name: "domainFilter",
                                    options: ES_AppDomainQuery.data?.map(d=>({label: d.label, value: d})),
                                    className: "contentG-filter-es"
                                }}
                            />
                        </div>
                        <div className='es-filter-group'>
                            <label>Class</label>
                            <CXAdvancedSelect
                                {...{
                                    control,
                                    name: "classFilter",
                                    options: ES_GetAllClassesMappingQuery.data?.map(d=>({label: d.label, value: d})),
                                    className: "contentG-filter-es"
                                }}
                            />
                        </div>
                        <div className='es-filter-group'>
                            <label>Search For</label>
                            <CXInputField
                                {...{
                                    register,
                                    name: "textFilter",
                                    className: "contentG-filter-es-text",
                                    placeholder: "Type some keywords"
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="filters-action-btns">
                    <button
                        type="button"
                        onClick={resetFilters}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        onClick={applyFilters}
                        >
                        Apply
                    </button>
                </div>
            </div>
        }
        
        </>
    );
}

export default Filters;
