import React, { Component } from "react";
import { connect } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { widgetPerformanceActions } from "../../../actions";
import mainHelper from "../../../helpers/main";
import CxLoader from "../../components/cxLoader";
import _ from "lodash";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
const helpers = new mainHelper();

class timeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.pageFilters, prevProps.pageFilters) || this.props.dateFilter && !_.isEqual(this.props.dateFilter, prevProps.dateFilter)) {
            this.fetchData();
        }
    }
    /*
    * componentDidMount
    */
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if (this.props.dateFilter) {
            this.setState({ loader: true });
            await this.props.dataFetch(this.props.pageFilters, this.props.dateFilter);
            this.setState({ loader: false });
        }
    }

    getData = () => {
        if (!this.props.data.length) return [];

        let viewsData = [],
            clickData = [],
            impressionData = [];

        for (let i in this.props.data) {
            let item = this.props.data[i];
            let date = Date.parse(helpers.cxDate(item.timestamp));
            viewsData.push([date, item.views]);
            clickData.push([date, item.clicks]);
            impressionData.push({ x: date, y: item.impressions });
        }

        return [
            {
                yAxis: 0, fillOpacity: 0.3, dataLabels: { enabled: false },
                type: 'areaspline', name: "Impressions", color: '#D6BBF9', data: impressionData, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                yAxis: 0, fillOpacity: 0.3, "dataLabels": { "enabled": false },
                type: 'areaspline', name: "Views", color: '#F9F5BB', data: viewsData, dashStyle: "ShortDash", marker: { enabled: false, "symbol": "circle" }
            },
            {
                yAxis: 0, "dataLabels": { "enabled": false },
                type: 'areaspline', name: "Clicks", color: '#55C0F0', data: clickData, marker: { enabled: false, "symbol": "circle" }
            },
            // {
            //     dataLabels: { enabled: true }, pointWidth: 20, borderRadius: 2, dashStyle: "ShortDash",
            //     pointPlacement: 0, "pointInterval": 1,
            //     type: 'line', name: "Clicks", color: '#29b3e8', data: clickData, yAxis: 0
            // }
        ];
    }

    formatNumber = (subtab, value) => {
        if (!value) return;
        switch (subtab) {
            case 'clicks':
            case 'views':
            case 'impressions':
                return helpers.formatNumber(value);
            case 'ctr':
                return value.toFixed(2).toLocaleString() + '%';
            default:
                return value;
        }
    }

    render() {
        const self = this;
        return (
            <div className={'cx-devices-line-chart'}>
                {this.state.loader ? <CxLoader /> : ''}
                <HighchartsReact
                    containerProps={{ className: "highchartscss" }}
                    highcharts={Highcharts}
                    options={{
                        title: { "text": "" },
                        subtitle: {
                            text: 'Clicks By Time',
                            style: {
                                color: '#9899A6'
                            }
                        },
                        exporting: {
                            "enabled": false,
                            buttons: {
                                contextButton: {
                                    menuItems: [
                                        'viewFullscreen',
                                        'separator',
                                        'downloadPDF'
                                    ]
                                }
                            },
                        },
                        chart: { "type": "spline", "polar": false, "backgroundColor": "transparent", style: { fontFamily: 'Poppins' } },
                        series: this.getData(),
                        plotOptions: {
                            areaspline: {
                                stacking: 'normal',
                                lineColor: '#666666',
                                lineWidth: 1,
                                marker: {
                                    lineWidth: 1,
                                    lineColor: '#666666'
                                }
                            }
                        },
                        legend: {
                            "layout": "horizontal", "enabled": true, "floating": false, "itemStyle": {
                                "color": '#fff'
                            }
                        },
                        tooltip: {
                            "shared": true,
                            "borderWidth": 1,
                            "formatter": function () {
                                return this.points.reduce(function (s, point) {
                                    if (point.series.name === "CX Pageviews") {
                                        return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',') + ' <b>' + point.point.cx_impact + '%</b>';
                                    }
                                    return s + '<br/><b>' + point.series.name + ':</b> ' + Highcharts.numberFormat(point.y, 0, '.', ',');
                                }, '<div style="font-size:85%;margin-bottom:10px!important;">' + Highcharts.dateFormat('%A, %b %d, %Y', this.x) + '</div>');
                            }
                        },
                        credits: { "enabled": false },
                        annotations: [{}],
                        pane: { "background": [] },
                        responsive: { "rules": [] },
                        xAxis: {
                            "type": 'datetime',
                            "labels": {
                                "style": {
                                    "color": '#aeaeae'
                                }
                            },
                            'minPadding': 0
                        },
                        yAxis: [
                            {
                                "title": {
                                    "text": "Count", style: {
                                        color: '#9899A6'
                                    }
                                },
                                "labels": {
                                    "formatter": function () {
                                        return helpers.formatNumber(this.value);
                                    },
                                    "style": {
                                        "color": '#aeaeae'
                                    }
                                },
                                "gridLineColor": 'rgba(255,255,255,0.1)'
                            }
                        ]
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dateFilter: state.filter && state.filter.date || null,
        pageFilters: state.widgetPerformance && state.widgetPerformance.pageFilters || null,
        data: (state.widgetPerformance && state.widgetPerformance.timeGraphData) ? state.widgetPerformance.timeGraphData : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dataFetch: (filter, dateFilter) => dispatch(widgetPerformanceActions.fetchTimeGraph(filter, dateFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(timeChart);