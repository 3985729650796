import {
  POSTS_SUMMARY,
  POSTS_PAGEVIEWS_GRAPH,
  POSTS_TOP_POSTS,
  POSTS_ENGAGEMENT,
  POSTS_PERFORMANCE_FILTER,
  POSTS_PERFORMANCE_FILTERS_SELECTED,
} from "../constants/appConstant";
import postsapi from "../api/posts";
import _ from "lodash";

const api = new postsapi();

/* For the Summary */
const postsSummary = (summary) => ({ type: POSTS_SUMMARY, payload: summary });
export const fetchSummary = (filter, dateFilter) => {
  return (dispatch) => {
    return api
      .postsSummaryByCXImpact(filter, dateFilter)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(postsSummary(res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

/* For the Pageviews Graph */
const pageviewsGraph = (summary) => ({
  type: POSTS_PAGEVIEWS_GRAPH,
  payload: summary,
});
export const fetchPageviewsGraph = (filters, dateFilter) => {
  return (dispatch) => {
    return api
      .postsPublishedByDay(filters, dateFilter)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(pageviewsGraph(res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

/* For the top posts table */
const topPosts = (summary) => ({ type: POSTS_TOP_POSTS, payload: summary });
export const fetchTopPosts = (params, filter, dateFilter) => {
  return (dispatch) => {
    return api
      .postsByCXPageviewsImpact(params, filter, dateFilter)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(topPosts(res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

/* For the engagement table */
const engagementByCX = (summary) => ({
  type: POSTS_ENGAGEMENT,
  payload: summary,
});
export const fetchEngagement = (params, filter, dateFilter) => {
  return (dispatch) => {
    return api
      .postsByCXTimeSpentImpact(params, filter, dateFilter)
      .then((response) => {
        let res = null;
        if (response && response.data && response.data.data)
          res = response.data.data;
        dispatch(engagementByCX(res));
      })
      .catch((error) => {
        throw error;
      });
  };
};

const postFiltersList = (filters) => ({
  type: POSTS_PERFORMANCE_FILTER,
  payload: filters,
});
export const postFiltersSelected = (filters) => ({
  type: POSTS_PERFORMANCE_FILTERS_SELECTED,
  payload: filters,
});
export const fetchPostFilters = () => {
  return (dispatch) => {
    let filterAuthor = {
      options: [],
      value: "author",
      label: "Author",
      type: "select",
      operator: [
        {
          value: "is",
          label: "is",
          symbol: ":",
        },
      ],
    };

    return api
      .getAllAuthors({})
      .then((response) => {
        if (response && response.data && response.data.data)
          filterAuthor.options = response.data.data.map((x) => ({
            label: x.author,
            value: x.author,
          }));
        // api.getAllSections({})
        //     .then(response => {
        //         if (response && response.data && response.data.data)
        //             filterSection.options = response.data.data.map(x => ({ label: x.author, value: x.author }));
        //     })
        //     .catch(error => {
        //         throw (error);
        //     });
        dispatch(postFiltersList([filterAuthor]));
      })
      .catch((error) => {
        throw error;
      });
  };
};
export const addPostFilter = (addFilter) => {
  return (dispatch, getState) => {
    if (addFilter) {
      let pageFilters;
      if (
        getState().postsInsight &&
        getState().postsInsight.pageFilters &&
        getState().postsInsight.pageFilters.length
      ) {
        const doesItExistBefore = _.findIndex(
          getState().postsInsight.pageFilters,
          (item) => {
            return (
              item.value === addFilter.value &&
              item.type === addFilter.type &&
              _.isEqual(item.typeValue, addFilter.typeValue)
            );
          }
        );
        if (doesItExistBefore < 0) {
          // added a new filter
          pageFilters = [...getState().postsInsight.pageFilters, addFilter];
        } else {
          // there is a duplicate so nothing to take
          pageFilters = [...getState().postsInsight.pageFilters];
        }
      } else {
        // empty
        pageFilters = [addFilter];
      }
      console.log(pageFilters);
      dispatch(postFiltersSelected(pageFilters));
    }
  };
};
export const removePostFilter = (removeFilter) => {
  return (dispatch, getState) => {
    if (removeFilter) {
      let pageFilters = [];
      if (
        getState().postsInsight &&
        getState().postsInsight.pageFilters &&
        getState().postsInsight.pageFilters.length
      ) {
        // filter out the one needs to be removed
        pageFilters = getState().postsInsight.pageFilters.filter((item) => {
          return !(
            item.value === removeFilter.value &&
            item.type === removeFilter.type &&
            _.isEqual(item.typeValue, removeFilter.typeValue)
          );
        });
      }
      dispatch(postFiltersSelected(pageFilters));
    }
  };
};
