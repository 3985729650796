import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Field, reduxForm, reset, isDirty } from "redux-form";
import { Card, CardHeader, CardBody } from '../../../components/Card/card';
import { Input } from '../../components/formElts';
import { drawerActions, campaignActions, adsetActions, adActions } from "../../../actions";
import PerfectScrollbar from "perfect-scrollbar";
import CxLoader from '../../components/cxLoader';
import Confirm from "../../components/confirmationBox";
const formName = "EditCampaign";
var ps;

class EditCampaign extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.props.handleSubmit;
        this.state = {
            loader: this.props.loading,
            msg: null,
            msgAnimate: false
        }
        this.notify = {
            cancelWarning: {
                title: 'Are you sure you want to leave before saving?',
                confirm: async (data) => {
                    this.cancelButton(data, true);
                    this.refs.confirmRef.removeConfirmation();
                },
                cancel: () => {
                    this.refs.confirmRef.removeConfirmation();
                },
                successBtnTxt: 'Yes',
                cancelBtnTxt: 'Cancel'
            }
        }
    }

    componentDidMount() {
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.elementRef);
        }else{
            this.refs.elementRef.style.overflow = 'auto';
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.loading != prevProps.loading) {
            // if it is in edit form and the data has been loaded then turn off the loader
            this.setState({ loader: this.props.loading });
        }

        if(this.props.errorUpdate && JSON.stringify(this.props.errorUpdate) != JSON.stringify(prevProps.errorUpdate)){
            this.printMessage(false, 'Error! Campaign Not updated.', 'error', () => {
                this.props.campaignUpdateError(undefined);
            });
        }

        if(this.props.errorPublish && JSON.stringify(this.props.errorPublish) != JSON.stringify(prevProps.errorPublish)){
            this.printMessage(false, 'Error! Campaign Not Published.', 'error', ()=> {
                this.props.campaignPublishError(undefined);
            });
        }

    }

    confirmOptionActions = (type, data) => {
        if(this.refs.confirmRef) {
            return this.refs.confirmRef.confirmOptionActions({ type: type, data: data });
        }
        return false;
    }

    cancelButton = (event, passToEnd = false) => {
        event.preventDefault();
        if(!passToEnd &&
            this.props.drawerMode === 'edit' &&
            this.props.selectedType.type === 'campaign' &&
            this.props.isDirtyCampaign
        ){
            this.confirmOptionActions('cancelWarning', event);
            return;
        }
        this.props.reset();
        this.props.toggleDrawer(false);
    }

    publishButton = async (event) => {
        event.preventDefault();
        this.handleSubmit(this.onPublish)();
    }

    onPublish = async (formVals) => {
        await this.onSave(formVals);
        this.setState({ loader: true });
        await this.props.publishCampaign({ ids: [this.props.campaign.id] });
        this.printMessage(false, 'Campaign Published!');
        this.props.reloadCampaignList();
        this.props.reloadAdsetList();
        this.props.reloadAdList();
    }

    saveButton = (event) => {
        event.preventDefault();
        this.handleSubmit(this.onSave)();
    }

    onSave = async (formVals) => {
        this.setState({ loader: true });
        await this.props.updateCampaign(formVals);
        // notify user that his changes were updated
        this.printMessage(false, 'Changes Saved!');
        // on success reload the tree
        this.props.reloadTree();
        // and reload the three tables
        this.props.reloadCampaignList();
        this.props.reloadAdsetList();
        this.props.reloadAdList();
    }

    printMessage = (loader, msg, type='success', callback = ()=>{}) => {
        const ref = this;
        if(type == "error") msg = <span style={{color: '#F0555D'}}>{msg}</span>;
        // Update user with the message after save
        this.setState({ loader: loader, msg: msg, msgAnimate: true }, () => {
            setTimeout(() => {
                ref.setState({ msgAnimate: false }, () => {
                    setTimeout(() => {
                        // opacity animation to finish, it needs 0.2s in css transition and thus setting time out to 200ms
                        ref.setState({ msg: null });
                        (callback)();
                    }, 200);
                });
            }, 2000);
        });
    }

    render() {
        return (
            <Form key={`campaignform-${(this.props.selectedType)? this.props.selectedType.type + '-' + this.props.selectedType.typeid : ''}`} className={`cx-edit-drawer-form cx-edit-campaign-form active`} onSubmit={() => (false)}>
                {this.state.loader? <CxLoader /> : ''}
                <div className={"cx-edit-drawer-container"} ref="elementRef">
                    <Card>
                        <CardHeader>
                            Campaign Info
                        </CardHeader>
                        <CardBody>
                            <Field showMsg={true} className={"cx-edit-campaign-input"} label={"Campaign name"} name={"name"} component={Input} />
                            {/* <Field showMsg={true} className={"cx-edit-campaign-input"} label={"Advertiser name"} name={"advertiserName"} component={Input} /> */}
                        </CardBody>
                    </Card>
                    <div className={"cx-action-buttons"}>
                        <button className={"btn cx-cancel-btn pull-left"} onClick={(e) => { this.cancelButton(e) }}>Cancel</button>

                        {this.props.campaign && this.props.campaign.isPublishable &&
                            <button className={"btn cx-focus-btn pull-right"} onClick={(e) => { this.publishButton(e) }}>Save & Publish</button>
                        }
                        <button className={"btn cx-discard-btn pull-right"} onClick={(e) => { this.saveButton(e) }}>Save Draft</button>
                        <span className={`cx-form-msgs pull-right ${this.state.msgAnimate ? 'show' : ''}`}>{this.state.msg}</span>
                   
                    </div>
                </div>
                <Confirm ref={"confirmRef"} notify={this.notify} />
            </Form>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        drawerMode: state.slidedrawer && state.slidedrawer.drawerMode,
        errorUpdate: (state.campaigns && state.campaigns.campaignUpdateError) || null,
        errorPublish: (state.campaigns && state.campaigns.campaignPublishError) || null,
        campaign: state.campaigns.campaign,
        selectedType: state.slidedrawer && state.slidedrawer.selectedType,
        initialValues: state.campaigns.campaign,
        formAction: state.slidedrawer && state.slidedrawer.formAction,
        isDirtyCampaign: isDirty(formName)(state),
    }
}


const mapDispatchToProps = (dispatch) => ({
    reloadTree: () => dispatch(campaignActions.fetchCampaignsTree()),
    reloadCampaignList: (queryStr) => dispatch(campaignActions.fetchAllCampaigns(queryStr)),
    reloadAdsetList: (queryStr) => dispatch(adsetActions.fetchAllAdsets(queryStr)),
    reloadAdList: (queryStr) => dispatch(adActions.fetchAllAds(queryStr)),
    toggleDrawer: (action) => dispatch(drawerActions.setDrawerVisible(action)),
    updateCampaign: (data) => dispatch(campaignActions.updateCampaign(data)),
    publishCampaign: (data) => dispatch(campaignActions.publishCampaign(data)),
    campaignPublishError: (data) => dispatch(campaignActions.campaignPublishError(data)),
    campaignUpdateError: (data) => dispatch(campaignActions.campaignUpdateError(data))
});

function validate(form) {
    const errors = {};
    if (!form.name) {
        errors.name = "Campaign name is required";
    }
    
    return errors;
}



EditCampaign = reduxForm({
    form: formName,
    enableReinitialize: true,
    validate
})(EditCampaign)

EditCampaign = connect(
    mapStateToProps, mapDispatchToProps
)(EditCampaign)

export default EditCampaign
