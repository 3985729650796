import React, {useState} from 'react';
import useContentGroupingStore from "../contentGroupingStore";
import {useMutation, useQueryClient} from 'react-query';
import {deleteContentG, refetchList} from "../contentGroupingAPI";
import {CXConfirm} from "../../../CXComponents";

const ContentGroupCard = ({title, size, coverage, id}) => {
    const [isDeleteContentGOpen, setIsDeleteContentGOpen] = useState(false);
    const setItemToLoad = useContentGroupingStore((state)=>state.setItemToLoad);
    const openDrawer = useContentGroupingStore((state)=>state.openDrawer);
    const setActionType = useContentGroupingStore((state)=>state.setActionType);

    const deleteContentGQuery = useMutation(()=>{
        return deleteContentG(id);
    })

    const qc = useQueryClient();

    const openContentGForm = (e) => {
        setActionType('edit');
        openDrawer();
        setItemToLoad(id);
    }

    const confirmContentGDelete = () => {
        setIsDeleteContentGOpen(true);
    }

    return (
        <>
        <div className="contentG-card-container">
            <div className="contentG-card-container__topRight" onClick={confirmContentGDelete}>
                <i className={`contentG-card-container__topRight__delete-btn fa fa-trash`}></i>
            </div>
            <div onClick={openContentGForm}>
                <div className="contentG-card-container__title">
                    {title}
                </div>
                <div className="contentG-card-container__nbOfKeywords">
                    {`${size} keyword${size === 1 ? '' : 's'}`}
                </div>
                <div className="contentG-card-container__coverage" title='Coverage Percentage(How much does this group cover from all posts)'>
                    % <span>{coverage}</span>
                </div>
                <div className="contentG-card-container__action">
                    Click to view
                </div>
            </div>
        </div>
        {
            isDeleteContentGOpen && 
            <CXConfirm
                {...{
                    title: "Delete This Content Group",
                    subtitle: "Are you sure you want to permanently delete this content group?",
                    confirmAction: async ()=>{
                        await deleteContentGQuery.mutateAsync();
                        setIsDeleteContentGOpen(false);
                        await refetchList(qc)
                    },
                    cancelAction: ()=>{
                        setIsDeleteContentGOpen(false);
                    },
                    loadingState: deleteContentGQuery.isLoading
                }}
            />
        }
        </>
    );
}

export default ContentGroupCard;
